import _ from 'lodash';
import { Platform, NativeModules } from 'react-native';
import { takeLatest, select, takeEvery, call, put } from 'redux-saga/effects';
import {
  GET_CAMPAIGN_DATA,
  MY_PROFILE_DETAILS,
  GET_LATEST_MEDIA,
  GET_POPULAR_MEDIA,
  MY_REFERRALS,
  GET_USER_UPLOAD_FOR_CAMPAIGN,
  UPDATE_CAMPAIGN_DATA,
  GET_SUBSCRIBER_COUNT,
  getSelectedYoutubeChannelId,
  getArtistInstagramProfile,
  YOUTUBE_CHANNEL_ANALYSIS,
  REGISTER_CAMPAIGN,
  EDIT_PROFILE,
  registerCampaignSuccess,
  updateArtistProfile,
  editProfileSuccess,
  MERGE_LIST_DATA,
  GET_CONTENT_FOR_ARTIST,
  REFRESH_MY_PROFILE,
  VERIFY_HANDLE_CALLBACK,
  SAVE_BRANDS,
  GET_ARTIST_GIFT,
  SAVE_BRANDS_SUCCESS,
  SAVE_OFFERINGS,
  SAVE_OFFERINGS_SUCCESS,
  SAVE_TRAININGS,
  SAVE_TRAININGS_SUCCESS,
  GET_VIDEOS_IN_REVIEW,
  GET_INSTA_HANDLE_HINT,
  COLLECT_CASH,
  SAVE_ARTIST_GIFT,
  GET_STATUS_MESSAGE,
  SAVE_STATUS_MESSAGE,
  ADD_INVITE_CODE,
  ADD_INVITE_CODE_SUCCESS,
  GET_CAMPAIGN_DETAIL,
  CANCEL_CAMPAIGN,
  cancelCampaignSuccess,
  UPDATE_USER_DATA,
  USER_TODAY_DEALS,
  USER_TODAY_DEALS_WITH_CALLBACK,
  addTodayDealsToRedux,
  GET_BOOSTED_OFFERS,
  saveBoostedOffers,
  UPDATE_APP_RATING,
} from '../actions/ActionTypes';

import { logoutUser, updateUserData } from '../actions/LoginActions';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';

import {
  URL,
  API_DOMAIN,
  BUILD_NO,
  REMOTE_CONFIG_KEYS,
} from '../config/Constants';
import RemoteConfig from '../utils/RemoteConfig';
import Utility from '../utils/Utility';

import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import { checkLastOrderStatus } from '../utils/PersonalizationUtility';

export function* getCampaignDataAsync(action) {
  const { pageNum = 0, showMyCollabs } = action.data;
  let url = URL.CAMPAIGN_URL;
  if (showMyCollabs) {
    url = URL.MY_CAMPAINGS_ONLY;
  } else {
    url = `${url}${pageNum}`;
  }
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      action.callback(json, '');
      yield put({ type: UPDATE_CAMPAIGN_DATA, data: json });
    }
  } catch (error) {
    action.callback([], 'error');
  }
}

export function* getCampaignDetailAsync(action) {
  const { slug } = action.data;
  const url = `${API_DOMAIN}${slug}`;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300 && Utility.isPresent(json)) {
      action.callback(json, '');
    }
  } catch (error) {
    action.callback({}, 'error');
  }
}

export function* getMyProfileDetails(action) {
  const { callback = () => {} } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);

    const response = yield fetch(URL.MY_PROFILE, {
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      yield put(updateArtistProfile(json));
      if (authToken) {
        checkLastOrderStatus(json?.user?.last_order_date);
      }
      callback(true);
    } else if (response.status === 401) {
      callback(false);
      // yield put(logoutUser());
    } else {
      callback(false);
      return;
    }
  } catch (error) {}
}

export function* refreshMyProfileAsync() {
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(URL.MY_PROFILE, {
      headers: yield getApiHeaders(),
    });
    console.log('refresh my profile called', response.status);
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put(updateArtistProfile(json));
      }
    } else if (response.status === 401) {
      yield put(logoutUser());
    } else {
      console.log('error in refresh artist profile', response.status);
    }
  } catch (error) {
    console.log('error in refresh artist profile', error);
  }
}

export function* getLatestMedia(action) {
  const { page, callback } = action;
  try {
    const url = `${URL.GET_LATEST_MEDIA}${page}`;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);

    const response = yield fetch(url, {
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* getPopularMedia(action) {
  const { page, callback } = action;
  try {
    const url = `${URL.GET_POPULAR_MEDIA}${page}`;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);

    const response = yield fetch(url, {
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* getMyReferrals(action) {
  const { callback } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);

    const response = yield fetch(`${URL.MY_REFERRALS}`, {
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (response.status >= 200 && response.status < 300) {
      if (Utility.isPresent(json)) {
        callback(json);
      }
    } else if (Utility.isPresent(json)) {
      callback(json.errors[0]);
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getUserUploadAsync(action) {
  const { campaignId, callback } = action;
  try {
    const url = `${URL.GET_USER_SUBMISSION_FOR_CAMPAIGN}${campaignId}`;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback({}, false);
        return;
      }
      callback(json, true);
    } else {
      callback({}, false);
    }
  } catch (error) {
    callback({}, false);
  }
}

export function* registerCampaignAsync(action) {
  const { campaignId, callback } = action;
  try {
    const url = URL.REGISTER_CAMPAIGN;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    //  console.log('body is', JSON.stringify({ auth_token: authToken, campaign_id: campaignId }));
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken, campaign_id: campaignId }),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      yield put(registerCampaignSuccess(campaignId));
      if (Utility.isBlank(json)) {
        callback({}, false);
        return;
      }
      callback(json, true);
    } else {
      callback({}, false);
    }
  } catch (error) {
    console.log('register campaign error is', error);
    callback({}, false);
  }
}

export function* cancelCampaignAsync(action) {
  const { campaignId } = action;
  try {
    const url = URL.CANCEL_CAMPAIGN;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken, campaign_id: campaignId }),
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(cancelCampaignSuccess(campaignId));
    }
  } catch (error) {}
}

export function* getSubscriberCount(action) {
  try {
    const instagram_profile = yield select(
      (state) => state.UserAccountInfo.instagram_profile,
    );
    const youtube_profile = yield select(
      (state) => state.UserAccountInfo.youtube_profile,
    );

    if (instagram_profile && instagram_profile.insta_user_name) {
      const getInstagramUserProfile = yield put(
        getArtistInstagramProfile(instagram_profile.insta_user_name, null),
      );
    }
    if (youtube_profile && youtube_profile.refreshToken) {
      const getYoutubeTokenObject = yield Utility.refreshAccessToken(
        youtube_profile.refreshToken,
      );
      yield put(
        getSelectedYoutubeChannelId(
          getYoutubeTokenObject.tokenType,
          getYoutubeTokenObject.accessToken,
          null,
        ),
      );
    }
  } catch (error) {
    console.log('Error is here', error);
  }
}
export function* analyzeYoutube(action) {
  const { callback } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const headers = {
      method: 'GET',
      headers: yield getApiHeaders(),
    };
    const response = yield fetch(URL.YOUTUBE_ANALYZE, headers);
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(response);
    }
  } catch (error) {
    callback(false);
  }
}

export function* editProfileAsync(action) {
  const { user, artist, callback } = action;
  try {
    const url = URL.ADD_PROFILE_DETAILS;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    // console.log('body is', JSON.stringify({ auth_token: authToken, user: user }));
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken, user, artist }),
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(editProfileSuccess(user, artist));
      callback(user, true);
    } else {
      const json = yield convertJsonFromResponse(response);
      callback(json, false);
    }
  } catch (error) {
    console.log('profile update is', error);
    callback({}, false);
  }
}

export function* fetchContentForArtistAsync(action) {
  const slug = action.payload;
  const url = `${API_DOMAIN}${slug}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      Utility.isPresent(json)
    ) {
      yield put({ type: MERGE_LIST_DATA, data: json });
    }
  } catch (error) {
    console.log('error in artist content', error);
  }
}

export function* verifyHandleAsync(action) {
  const { handle, callback } = action;
  try {
    let url = URL.VERIFY_HANDLE + handle;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const countryCode = yield select(
      (state) => state.UserAccountInfo.callingCode,
    );
    const phoneNumber = yield select(
      (state) => state.UserAccountInfo.mobileNumber,
    );
    url = `${url}?user[country_code]=${countryCode}&user[phone_number]=${phoneNumber}`;
    console.log('verify handle url', url);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    console.log('profile edit error is', error);
    callback(false);
  }
}

export function* saveBrandsAsync(action) {
  const { data } = action;
  try {
    const url = URL.SUBMIT_ARTIST_RESPONSE;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    console.log(
      'url is ',
      url,
      JSON.stringify({ auth_token: authToken, artist: data }),
    );
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken, artist: data }),
    });
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: SAVE_BRANDS_SUCCESS, data });
    } else {
      console.log('save brands error');
    }
  } catch (error) {
    console.log('save brands error is', error);
  }
}

export function* getArtistGift(action) {
  const { callback } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(URL.GET_ARTIST_GIFT, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      callback(json);
      yield put({ type: SAVE_ARTIST_GIFT, data: json });
    } else {
      callback(false);
    }
  } catch (error) {
    console.log('GOing into error');
    callback(false);
  }
}

export function* saveOfferingsAsync(action) {
  const { data, offeringObject } = action;
  try {
    const url = URL.SAVE_ARTIST_OFFERINGS;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken, artist: data }),
    });
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: SAVE_OFFERINGS_SUCCESS, data: offeringObject });
    }
    if (response.status === 401) {
      yield put(logoutUser());
    } else {
      console.log('save offerings error');
    }
  } catch (error) {
    console.log('save offerings error is', error);
  }
}

export function* saveTrainingsAsync(action) {
  const { data } = action;
  try {
    const url = URL.SAVE_ARTIST_TRAININGS;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    console.log(
      'url is ',
      url,
      JSON.stringify({ auth_token: authToken, artist: data }),
    );
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken, artist: data }),
    });
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: SAVE_TRAININGS_SUCCESS, data });
    } else {
      console.log('save trainings error');
    }
  } catch (error) {
    console.log('save trainings error is', error);
  }
}

export function* getVideosInReviewAsync(action) {
  const { callback } = action;
  try {
    const url = URL.VIDEOS_IN_REVIEW;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      Utility.isPresent(json)
    ) {
      callback(true, json);
    } else {
      callback(false, []);
      console.log('get videos in review error');
    }
  } catch (error) {
    callback(false, []);
    console.log('get in review video error is', error);
  }
}

export function* collectCashAsync(action) {
  const { campaignId, callback } = action;
  try {
    const url = URL.COLLECT_CASH;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken, campaign_id: campaignId }),
    });
    console.log('response in redeem cash', response);
    if (response.status >= 200 && response.status < 300) {
      console.log('redeem cash success');
      callback(true);
    } else {
      callback(false);
      console.log('redeem cash error');
    }
  } catch (error) {
    callback(false);
    console.log('error in redeem cash is', error);
  }
}

export function* getInstaHandleHintAsync(action) {
  const { callback } = action;
  try {
    const url = URL.INSTA_HANDLE_HINT;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      Utility.isPresent(json)
    ) {
      callback(true, json);
    } else {
      callback(false, {});
      console.log('get probable insta handle error');
    }
  } catch (error) {
    callback(false, {});
    console.log('get probable insta handle error', error);
  }
}

export function* getStatusMessageAsync() {
  const url = URL.STATUS_MESSAGE;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put({ type: SAVE_STATUS_MESSAGE, data: json });
      }
    } else {
      console.log('get probable insta handle error');
    }
  } catch (error) {
    console.log('get probable insta handle error', error);
  }
}

export function* updateUserDataAsync(action) {
  const { userData } = action;
  try {
    const url = `${URL.UPDATE_USER_DATA}?os=${Platform.OS}`;
    const userAccountInfo = yield select((state) => state.UserAccountInfo);
    const { authToken, app_installed_at } = userAccountInfo;
    // console.log("invite code body is", JSON.stringify({ auth_token: authToken, invite_code: inviteCode }))
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        ...userData,
        app_installed_at,
        auth_token: authToken,
        build_number: BUILD_NO,
      }),
    });
    const statusCode = response.status;

    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      if (Utility.isAndroid()) {
        NativeModules.UserPreferences.saveUserId(`${json?.id}`);
      }
      yield put(updateUserData(json));
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: 'ProfileSaga.js: updateUserDataAsync',
      [EventParameterKey.ERROR]: `${error}`,
    });
  }
}

export function* updateAppRatingAsync(action) {
  const { userData } = action;
  try {
    if (Utility.isAndroid()) {
      const url = `${URL.UPDATE_APP_RATING}?os=${Platform.OS}`;
      const userAccountInfo = yield select(
        (state) => state.UserAccountInfo.authToken,
      );
      const { authToken, app_installed_at } = userAccountInfo;
      // console.log("invite code body is", JSON.stringify({ auth_token: authToken, invite_code: inviteCode }))
      const response = yield fetch(url, {
        method: 'POST',
        headers: yield getApiHeaders(),
        body: JSON.stringify({
          ...userData,
          app_installed_at,
          auth_token: authToken,
          build_number: BUILD_NO,
        }),
      });
      const statusCode = response.status;

      if (statusCode >= 200 && statusCode < 300) {
        const json = yield convertJsonFromResponse(response);
        if (Utility.isBlank(json)) {
          return;
        }
        if (Utility.isAndroid()) {
          NativeModules.UserPreferences.saveUserId(`${json?.id}`);
        }
        yield put(updateUserData(json));
      }
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: 'ProfileSaga.js: updateUserDataAsync',
      [EventParameterKey.ERROR]: `${error}`,
    });
  }
}

export function* getUserTodayDealsWithCallbackAsync(action) {
  const { callback } = action;
  try {
    const url = `${URL.TODAY_DEALS}`;
    const myBrowsing = yield select(
      (state) => state.userInteractions.myBrowsing,
    );

    const postData = myBrowsing.map((browsingItem) => {
      const capitalizedBrowsingItem = _.capitalize(browsingItem?.type || '');
      return {
        item_type: _.startCase(capitalizedBrowsingItem).replace(' ', ''),
        item_id: browsingItem?.id,
      };
    });

    // console.log("invite code body is", JSON.stringify({ auth_token: authToken, invite_code: inviteCode }))
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        flash_deals: postData,
      }),
    });
    const statusCode = response.status;

    const disableBtl = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.disable_btl);
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      if (disableBtl === 'true') {
        yield put(addTodayDealsToRedux({}));
      } else {
        AnalyticsManager.setUserProperty(
          'membership_cohort',
          json?.membership_cohort || 'error',
        );
        AnalyticsManager.setUserProperty(
          'membership_id',
          json?.membership_plan_id || 'error',
        );
        AnalyticsManager.logEvent('membership_status', {
          membership_cohort: json?.membership_cohort || 'error',
          membership_id: json?.membership_plan_id || 'error',
        });
        yield put(addTodayDealsToRedux(json));
        callback(true, json);
        return;
      }
      yield put(addTodayDealsToRedux(json[0]));
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {}
}

export function* getUserTodayDealsAsync(action) {
  const { callback } = action;
  try {
    const url = `${URL.TODAY_DEALS}`;
    const myBrowsing = yield select(
      (state) => state.userInteractions.myBrowsing,
    );

    const postData = myBrowsing.map((browsingItem) => {
      const capitalizedBrowsingItem = _.capitalize(browsingItem?.type || '');
      return {
        item_type: _.startCase(capitalizedBrowsingItem).replace(' ', ''),
        item_id: browsingItem?.id,
      };
    });

    // console.log("invite code body is", JSON.stringify({ auth_token: authToken, invite_code: inviteCode }))
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        flash_deals: postData,
      }),
    });
    const statusCode = response.status;

    const disableBtl = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.disable_btl);
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      if (disableBtl === 'true') {
        yield put(addTodayDealsToRedux({}));
      } else {
        AnalyticsManager.setUserProperty(
          'membership_cohort',
          json?.membership_cohort || 'error',
        );
        AnalyticsManager.setUserProperty(
          'membership_id',
          json?.membership_plan_id || 'error',
        );
        AnalyticsManager.logEvent('membership_status', {
          membership_cohort: json?.membership_cohort || 'error',
          membership_id: json?.membership_plan_id || 'error',
        });
        yield put(addTodayDealsToRedux(json));
        callback(true, json);
        return;
      }
      yield put(addTodayDealsToRedux(json[0]));
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {}
}

export function* addInviteCodeAsync(action) {
  const { inviteCode, callback } = action;
  try {
    const url = URL.INVITE_CODE;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    // console.log("invite code body is", JSON.stringify({ auth_token: authToken, invite_code: inviteCode }))
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken, invite_code: inviteCode }),
    });
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      callback(true, {});
      yield put({ type: ADD_INVITE_CODE_SUCCESS });
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        callback(false, json);
        return;
      }
      callback(false, {});
    }
  } catch (error) {
    console.log('invitecode error', error);
    callback(false, {});
  }
}

export function* getBoostedOffers() {
  try {
    const response = yield fetch(`${URL.BOOSTED_OFFERS}`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const jsonResponse = yield response.json();
      yield put(saveBoostedOffers(jsonResponse));
      console.log('Json Response', jsonResponse);
    }
  } catch (error) {}
}

export default function* watchFetchProfileSaga() {
  yield takeLatest(GET_CAMPAIGN_DATA, getCampaignDataAsync);
  yield takeLatest(GET_CAMPAIGN_DETAIL, getCampaignDetailAsync);
  yield takeLatest(MY_PROFILE_DETAILS, getMyProfileDetails);
  yield takeLatest(GET_USER_UPLOAD_FOR_CAMPAIGN, getUserUploadAsync);
  yield takeLatest(GET_LATEST_MEDIA, getLatestMedia);
  yield takeLatest(GET_POPULAR_MEDIA, getPopularMedia);
  yield takeLatest(MY_REFERRALS, getMyReferrals);
  yield takeEvery(GET_SUBSCRIBER_COUNT, getSubscriberCount);
  yield takeEvery(YOUTUBE_CHANNEL_ANALYSIS, analyzeYoutube);
  yield takeLatest(REGISTER_CAMPAIGN, registerCampaignAsync);
  yield takeLatest(EDIT_PROFILE, editProfileAsync);
  yield takeLatest(GET_CONTENT_FOR_ARTIST, fetchContentForArtistAsync);
  yield takeLatest(REFRESH_MY_PROFILE, refreshMyProfileAsync);
  yield takeLatest(VERIFY_HANDLE_CALLBACK, verifyHandleAsync);
  yield takeLatest(SAVE_BRANDS, saveBrandsAsync);
  yield takeLatest(GET_ARTIST_GIFT, getArtistGift);
  yield takeLatest(SAVE_OFFERINGS, saveOfferingsAsync);
  yield takeLatest(SAVE_TRAININGS, saveTrainingsAsync);
  yield takeLatest(GET_VIDEOS_IN_REVIEW, getVideosInReviewAsync);
  yield takeLatest(GET_INSTA_HANDLE_HINT, getInstaHandleHintAsync);
  yield takeLatest(COLLECT_CASH, collectCashAsync);
  yield takeLatest(GET_STATUS_MESSAGE, getStatusMessageAsync);
  yield takeLatest(ADD_INVITE_CODE, addInviteCodeAsync);
  yield takeLatest(CANCEL_CAMPAIGN, cancelCampaignAsync);
  yield takeLatest(UPDATE_USER_DATA, updateUserDataAsync);
  yield takeLatest(USER_TODAY_DEALS, getUserTodayDealsAsync);
  yield takeLatest(
    USER_TODAY_DEALS_WITH_CALLBACK,
    getUserTodayDealsWithCallbackAsync,
  );
  yield takeLatest(GET_BOOSTED_OFFERS, getBoostedOffers);
  yield takeLatest(UPDATE_APP_RATING, updateAppRatingAsync);
}
