import { View, StyleSheet } from 'react-native';
import React from 'react';
import TopBrands from './TopBrands';
import FoxyPromise from './FoxyPromise';
import FoxyPolicies from './FoxyPolicies';
import AppContactDetails from './AppContactDetails';
import Utility from '../../utils/Utility';
import { isNative } from '../../utils/BooleanUtility';

export default function WebFooter() {
  if (isNative()) return null;
  return (
    <View style={styles.container}>
      <TopBrands />
      <FoxyPromise />
      <FoxyPolicies />
      <AppContactDetails />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: Utility.screenWidth,
  },
});
