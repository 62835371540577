// Dependencies
import React, { PureComponent } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  FlatList,
} from 'react-native';
import Modal from 'react-native-modal';
import AnimatedLottieView from '../../libraries/LottieView';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import animations from '../../theme/Animations';
import ScanFrameStyles from '../camera/shared/styles/AlertBoxStyles';
import { SIZE } from '../../config/Constants';
import size from '../../theme/Fonts';
import Config from '../../libraries/ReactNativeConfig';
import { navigateToScreen } from '../../utils/NavigationUtility';

class AddedToWishlistDeals extends PureComponent {
  cartProducts = ({ item }) => {
    return (
      <View style={unfulfilledCouponModalStyles.cartProductsContainer}>
        <FastImageView
          source={item}
          style={unfulfilledCouponModalStyles.cartProducts}
        />
      </View>
    );
  };

  crossButton = () => {
    const { onModalClose } = this.props;

    return (
      <TouchableOpacity
        style={unfulfilledCouponModalStyles.crossButtonContainer}
        onPress={onModalClose}
        hitSlop={Utility.getHitSlop('likeButton')}
      >
        <Image
          source={images.cross}
          style={unfulfilledCouponModalStyles.crossButton}
        />
      </TouchableOpacity>
    );
  };

  navigateToWishlistDeals = () => {
    const {
      navigation,
      onModalClose,
      isLoginRequired,
      onInitiateLoginBeforeWhishlist,
    } = this.props;
    onModalClose();
    if (isLoginRequired) {
      onInitiateLoginBeforeWhishlist();
      return;
    }
    setTimeout(() => {
      if (Config.TABS.includes('Wishlist')) {
        navigation.navigate('Wishlist');
        return;
      }
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'TodayDeals',
      });
    }, 100);
  };

  RenderPoint = ({ index }) => {
    const style = this.isLastStep(index)
      ? unfulfilledCouponModalStyles.currentPointStyle
      : unfulfilledCouponModalStyles.pointStyle;
    return (
      <View style={style}>
        <View style={unfulfilledCouponModalStyles.point} />
      </View>
    );
  };

  isLastStep = (index) => index + 1 === this.props.stepsToShow?.length;

  RenderDashedLine = ({ index }) => {
    if (this.isLastStep(index)) {
      return null;
    }
    return (
      <Image
        style={unfulfilledCouponModalStyles.horizontalLine}
        source={images.dashedLine}
      />
    );
  };

  renderSteps = ({ item, index }) => {
    const textStyle = this.isLastStep(index)
      ? unfulfilledCouponModalStyles.listItemTextBold
      : unfulfilledCouponModalStyles.listItemText;
    return (
      <View>
        <View style={unfulfilledCouponModalStyles.listItemContainer}>
          <this.RenderPoint index={index} />
          <Text style={textStyle}>{item}</Text>
        </View>
        <this.RenderDashedLine index={index} />
      </View>
    );
  };

  keyExtractor = (item, index) => index;

  StepsList = ({ steps = [] }) => {
    if (Utility.isBlank(steps)) return null;
    return (
      <FlatList
        style={unfulfilledCouponModalStyles.stepsListContainer}
        data={steps}
        renderItem={this.renderSteps}
        keyExtractor={this.keyExtractor}
      />
    );
  };

  render() {
    const {
      isClubbableModalVisible,
      onModalClose,
      heading = '',
      subHeading = '',
      buttonText = '',
      animation = animations.paymentSuccess,
      stepsToShow = [],
    } = this.props;

    return (
      <Modal
        isVisible={isClubbableModalVisible}
        onBackdropPress={onModalClose}
        style={unfulfilledCouponModalStyles.modalStyle}
        swipeDirection='down'
        scrollOffsetMax={100}
        onRequestClose={onModalClose}
      >
        <View style={unfulfilledCouponModalStyles.modalContainer}>
          <View style={unfulfilledCouponModalStyles.headingContainer}>
            <this.crossButton />
          </View>
          <AnimatedLottieView
            source={animation}
            style={ScanFrameStyles.alertBoxAnimation}
            autoPlay
            loop={false}
          />

          <Text style={unfulfilledCouponModalStyles.heading}>{heading}</Text>
          <Text style={unfulfilledCouponModalStyles.subHeading}>
            {subHeading}
          </Text>

          <this.StepsList steps={stepsToShow} />

          <View style={unfulfilledCouponModalStyles.actionButtonsContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 24}
              title={buttonText}
              onPress={this.navigateToWishlistDeals}
              backgroundColor={colors.green}
              textStyle={unfulfilledCouponModalStyles.secondaryButtonTextStyle}
              firstColor={colors.black}
              secondColor={colors.black}
              underlayColor={colors.black}
            />
          </View>
        </View>
      </Modal>
    );
  }
}

const unfulfilledCouponModalStyles = StyleSheet.create({
  modalStyle: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    height: 20,
  },
  modalContainer: {
    width: Utility.getScreenWidth(),
    borderRadius: 6,
    backgroundColor: colors.white,
    marginBottom: -20,
    paddingBottom: 20,
    paddingHorizontal: 12,
    paddingTop: 16,
  },
  offersContainer: { marginVertical: 8 },
  actionButtonsContainer: {
    marginTop: 10,
    paddingBottom: Utility.isIOS() ? Utility.topInset : 0,
  },
  firstButtonStyle: {
    marginBottom: 8,
    borderColor: colors.green,
    borderWidth: 1,
  },
  firstButtonTextStyle: {
    color: colors.green,
    fontSize: size.h3,
  },
  secondaryButtonTextStyle: {
    fontSize: size.h3,
  },
  offersInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 4,
    marginHorizontal: 16,
  },
  couponCodePillStyle: {
    flex: 1,
    height: 24,
    marginRight: 4,
  },
  offersDescription: {
    alignSelf: 'flex-start',
    flex: 4,
    paddingHorizontal: 4,
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
  },
  heading: {
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginBottom: 4,
    textAlign: 'center',
  },
  subHeading: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginBottom: 16,
    textAlign: 'center',
  },
  additional_message: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.subText,
    marginTop: 4,
  },
  cartProductsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 4,
    borderColor: colors.background,
    marginTop: 14,
  },
  cartProducts: {
    height: 40,
    width: 40,
  },
  barContainer: {
    width: '100%',
    height: 4,
    backgroundColor: colors.borderDark,
    marginVertical: 12,
    borderRadius: 100,
  },
  bar: { height: 4, backgroundColor: colors.green, borderRadius: 100 },
  crossButtonContainer: {
    backgroundColor: colors.background,
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 11,
    padding: 4,
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  crossButton: { tintColor: colors.black },

  stepsListContainer: {
    marginBottom: 24,
    paddingRight: 24,
  },
  listItemText: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    marginLeft: 15,
  },
  listItemTextBold: {
    fontSize: size.h3,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    marginLeft: 15,
  },

  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  point: {
    width: 6,
    height: 6,
    backgroundColor: colors.black,
    borderRadius: 3,
  },
  pointStyle: {
    borderWidth: 1,
    height: 12,
    width: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.white,
  },
  currentPointStyle: {
    borderWidth: 1,
    height: 12,
    width: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.black,
  },
  horizontalLine: {
    height: 40,
    width: 2,
    left: 4,
    marginTop: -12,
    marginBottom: -12,
  },
});

export default AddedToWishlistDeals;
