import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TouchableWithoutFeedback,
  NativeModules,
  NativeEventEmitter,
  Linking,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { createNewOrder, createNewOrderV2 } from '../../actions/ActionTypes';
import { RNPayuPayment } from '../../lib/RNPayuPayment';
import { addMoneyToPaytm } from '../../actions/PaytmActions';
import {
  LOGIN_MODAL_MESSAGES,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import { setCurrentPaymentFlow } from '../../actions/PaymentActions';

import { cartIds, CartStyles } from '../../components/cart/styles';
import images from '../../theme/Images';
import FullWidthDivider from '../../utils/FullWidthDivider';
import colors from '../../theme/Colors';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import {
  AnalyticsUtilty,
  AnalyticsManager,
  EventParameterKey,
} from '../../analytics';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import NetworkStats from '../../utils/NetworkStat';
import { setCongratsModalForEdge } from '../../actions/FoxyEdgeAction';
import { getPriceText } from '../../utils/NumberUtility';
import { isDesktop } from '../../utils/BooleanUtility';

export class CartCheckout extends Component {
  constructor(props) {
    super(props);
    this.debouncedCreateOrder = debounce(this.createOrder, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceNavigateToPayment = debounce(this.viewPaymentScreen, 1000, {
      leading: true,
      trailing: false,
    });
    if (Utility.isAndroid()) {
      this.payuSuccess = new NativeEventEmitter(NativeModules.payment);
    }
    this.source = this.props.source || '';
  }

  componentDidMount() {
    if (Utility.isAndroid()) {
      this.payuSuccess.addListener('payu_payment_success', (response) => {
        let parsedResponse = {};
        try {
          parsedResponse = Utility.jsonParser(response);
        } catch {}
        const { result: { txnid = '' } = {} } = parsedResponse;
        AnalyticsManager.logEvent('order_confirm_cross_check', {
          [EventParameterKey.TXN_ID]: Utility.isPresent(txnid) ? txnid : '',
        });
      });
      this.payuSuccess.addListener('payu_upi_error', (response) => {
        try {
        } catch (e) {}
      });
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.payuSuccess.removeListener('payu_payment_success');
      this.payuSuccess.removeListener('payu_upi_error');
    }
  }

  createOrderV2Callback = (
    response,
    orderPayload,
    payuPayload,
    extraData,
    retryPayload,
  ) => {
    const { navigation, renderLoader } = this.props;
    renderLoader(false);
    setTimeout(() => {
      if (orderPayload.paymentMethod === 'intent') {
        const payuParams = response['payu_params'];
        this.setupPayu(payuParams, extraData, () =>
          this.setupPayuCallback(
            payuPayload,
            extraData,
            retryPayload,
            payuParams,
          ),
        );
      } else {
        if (
          Utility.isPresent(extraData.details?.intent_url) &&
          Linking.canOpenURL(extraData.details?.intent_url)
        ) {
          Linking.openURL(extraData.details?.intent_url);
        }
      }
    }, 0);
    navigation.navigate('PaymentsWaiting', {
      paymentStatus: response,
      orderPayload,
      extraData,
    });
  };

  createOrder = ({
    paymentGateway,
    paymentMethod,
    isSavedPaymentMethod,
    paymentMethodPayload,
    payuPayload,
    extraData,
    retry = false,
  }) => {
    const {
      address,
      createNewOrder,
      renderLoader,
      createNewOrderV2,
      isNewPaymentJourney,
    } = this.props;
    renderLoader(true);

    let addressArray = [];
    if (!Utility.isBlank(address)) {
      addressArray = Object.keys(address).map((key) => ({
        id: key,
        name: address[key].name,
        pincode: address[key].pincode,
        line1: address[key].line1,
        line2: address[key].line2,
        contact_name: address[key].contact_name,
        default: address[key].default,
      }));
    }
    let defaultAddress = addressArray.filter((item) => item.default);
    if (Utility.isBlank(defaultAddress)) {
      defaultAddress = addressArray.splice(0, 1);
    }
    const addressId = defaultAddress.length > 0 ? defaultAddress[0].id : '';
    const orderPayload = {
      addressId,
      paymentGateway,
      paymentMethod,
      isSavedPaymentMethod,
      paymentMethodPayload,
      orderId: '',
      upgradableToPrepaid: false,
      isContactLessDelivery: false,
    };

    const retryPayload = {
      paymentGateway,
      paymentMethod,
      isSavedPaymentMethod,
      paymentMethodPayload,
      payuPayload,
      extraData,
      retry: true,
    };

    if (retry) {
      try {
        AnalyticsUtilty.fireRetryPayment({
          payuPayload,
        });
      } catch (e) {}
    }
    if (
      (isNewPaymentJourney && paymentMethod === 'upi') ||
      paymentMethod === 'intent'
    ) {
      createNewOrderV2(orderPayload, (success, response) => {
        if (!success) {
          renderLoader(false);
          return;
        }
        this.createOrderV2Callback(
          response,
          orderPayload,
          payuPayload,
          extraData,
          retryPayload,
        );
      });
      return;
    }
    createNewOrder(orderPayload, (success, payuParams, codData, status) => {
      this.createOrderCallback(
        success,
        payuParams,
        codData,
        status,
        payuPayload,
        extraData,
        retryPayload,
      );
    });
  };

  createOrderCallback = (
    success,
    payuParams,
    codData,
    status,
    payuPayload = {},
    extraData,
    retryPayload,
  ) => {
    const { method } = extraData;
    const {
      navigation,
      renderLoader,
      showToast,
      cartPricing,
      isContactLess,
      optForPlasticFreePackaging,
      cartItems,
    } = this.props;

    renderLoader(false);
    if (success) {
      const meta = {
        payuParams,
        codData,
        extraData,
        cartPricing,
        isContactLess,
        optForPlasticFreePackaging,
        cartItems,
      };

      try {
        AnalyticsUtilty.fireOrderCreateEvent(meta);
      } catch (e) {}
      if (status === 'success') {
        this.openOrderSuccessPage(codData, retryPayload);
      } else if (method === 'TEZOMNI') {
        navigation.navigate('GooglePayProcessing', {
          payuPayload,
          payuParams,
          createOrder: this.debouncedCreateOrder,
          retryPayment: () => this.debouncedCreateOrder(retryPayload),
          method,
        });
      } else {
        this.setupPayu(payuParams, extraData, () =>
          this.setupPayuCallback(
            payuPayload,
            extraData,
            retryPayload,
            payuParams,
          ),
        );
      }
    } else if (
      Utility.isPresent(payuParams) &&
      Utility.isPresent(payuParams.errors)
    ) {
      if (payuParams.errors.length > 0) {
        showToast(payuParams.errors[0]);
      }
    } else {
      showToast('Something went wrong');
    }
  };

  setupPayu = (payuParams, extraData, callback) => {
    const {
      key,
      txnId,
      amount,
      firstName,
      email,
      environment,
      productInfo,
      surl,
      furl,
      userCredentials,
      phoneNumber,
      hashes: {
        paymentHash,
        saveUserCardHash,
        deleteUserCardHash,
        paymentRelatedDetailsHash,
        VASForMobileSDKHash,
      },
    } = payuParams;
    const payParams = {
      key,
      txnId,
      amount,
      productInfo,
      firstName,
      email,
      environment,
      surl,
      furl,
      userCredentials,
      phoneNumber,
      paymentHash,
      saveUserCardHash,
      deleteUserCardHash,
      paymentRelatedDetailsHash,
      VASForMobileSDKHash,
    };
    console.log('payment params is', amount, typeof amount, payParams);

    const { method } = extraData;

    if (Utility.isAndroid()) {
      RNPayuPayment.setPayuParamsForIntent(payParams)
        .then((res) => {
          console.log('success payu intent init');
        })
        .catch((e) => {
          console.log('setup payu param error for upi intent');
        });

      RNPayuPayment.setPaymentParams(payParams)
        .then((res) => {
          callback(true);
        })
        .catch((e) => {
          callback(false);
        });
    } else {
      if (method === 'intent') {
        this.setPaymentParamsForIOSIntent(payParams, callback);
      } else {
        RNPayuPayment.setPaymentParams(payParams)
          .then((res) => {
            callback(true);
          })
          .catch((e) => {
            callback(false);
          });
      }
    }
  };

  setPaymentParamsForIOSIntent = (payParams, callback) => {
    RNPayuPayment.setPaymentParamsForIOSIntent(payParams)
      .then((res) => {
        const { supportedIntents } = res;

        if (Utility.isPresent(supportedIntents)) {
          this.setState({
            supportedIOSIntents: supportedIntents,
          });
        }

        callback(true);
      })
      .catch((e) => {
        console.log('ios intent param setup error', e);
        // callback(false);
      });
  };

  openOrderSuccessPage = (orderResponse, orderPayload, payuParams) => {
    const {
      navigation,
      cartPricing,
      isContactLess,
      optForPlasticFreePackaging,
      cartItems,
      UtoP,
      authToken,
      setCongratsModalForEdge,
      membership_cohort = '',
    } = this.props;
    const meta = {
      orderResponse,
      cartPricing,
      isContactLess,
      optForPlasticFreePackaging,
      cartItems,
      orderPayload,
      payuParams,
      UtoP,
      networkInfo: NetworkStats.networkDetails,
      authToken,
      membershipCohort: membership_cohort,
    };

    if (orderResponse && orderResponse.status === 'success') {
      try {
        const loyaltyPlan = cartItems.filter(
          (cartItem) => cartItem?.loyalty_plan?.type === 'loyalty_plan',
        );
        if (Utility.isPresent(loyaltyPlan)) {
          setCongratsModalForEdge(true);
        }
        AnalyticsUtilty.fireOrderConfirm(meta);
      } catch (e) {
        AnalyticsManager.logEvent('exception_captured', {
          [EventParameterKey.SOURCE]: 'cartCheckout.js: fireOrderConfirm',
        });
      }
    }
    try {
      AnalyticsUtilty.firePaymentStatus(meta);
    } catch (e) {
      AnalyticsManager.logEvent('exception_captured', {
        [EventParameterKey.SOURCE]: 'cartCheckout.js: firePaymentStatus',
      });
    }

    navigation.navigate('OrderStatus', {
      orderResponse,
      retryPayment: () => this.debouncedCreateOrder(orderPayload),
      source: this.source,
    });
  };

  setupPayuCallback = (payuPayload, extraData, orderPayload, payuParams) => {
    const { method, save = true } = extraData;
    const { cartPricing, UtoP, cartItems } = this.props;
    const meta = {
      payuPayload,
      orderPayload,
      extraData,
      cartItems,
      UtoP,
      payuParams,
      cartPricing,
      networkInfo: NetworkStats.networkDetails,
    };

    AnalyticsUtility.firePaymentInitiate(meta);

    if (method === 'upi') {
      RNPayuPayment.payWithUPI(payuPayload)
        .then((res) => {
          this.openOrderSuccessPage(res, orderPayload, payuParams);
        })
        .catch((e) => {
          //     this.openOrderSuccessPage(e, orderPayload, payuParams);
        });
    } else if (method === 'intent') {
      RNPayuPayment.payWithIntent(payuPayload.package_name)
        .then((res) => {
          this.openOrderSuccessPage(res, orderPayload, payuParams);
        })
        .catch((e) => {
          //     this.openOrderSuccessPage(e, orderPayload, payuParams);
        });
    } else if (method === 'card') {
      RNPayuPayment.payWithCard(payuPayload, save)
        .then((res) => {
          this.openOrderSuccessPage(res, orderPayload, payuParams);
        })
        .catch((e) => {
          //     this.openOrderSuccessPage(e, orderPayload, payuParams);
        });
    } else if (method === 'netbanking') {
      RNPayuPayment.payWithNetBanking(payuPayload)
        .then((res) => {
          this.openOrderSuccessPage(res, orderPayload, payuParams);
        })
        .catch((e) => {
          //     this.openOrderSuccessPage(e, orderPayload, payuParams);
        });
    } else if (method === 'saved_card') {
      RNPayuPayment.payWithSavedCard(payuPayload)
        .then((res) => {
          this.openOrderSuccessPage(res, orderPayload, payuParams);
        })
        .catch((e) => {
          //     this.openOrderSuccessPage(e, orderPayload, payuParams);
        });
    }
  };

  createOrderAndPayWithLastPaymentMode = () => {
    const {
      pricingData: { last_payment_method = {}, total = '' } = {},
      digital_cart = false,
    } = this.props;

    if (digital_cart && parseInt(total) === 0) {
      const paymentPayload = {
        paymentGateway: '',
        paymentMethod: 'cod',
        isSavedPaymentMethod: true,
        paymentMethodPayload: {},
        payuPayload: {},
        extraData: {
          method: 'cod',
        },
      };
      this.debouncedCreateOrder(paymentPayload);
      return;
    }

    if (Utility.isBlank(last_payment_method?.method)) {
      return;
    }
    const { method = '' } = last_payment_method;

    switch (method) {
      case 'credit_card':
        this.oneTapPayWithSavedCard(last_payment_method);
        break;
      case 'netbanking':
        this.onTapPayWithNetbanking({
          bankCode: last_payment_method.bankcode,
          title: last_payment_method.display_name,
        });
        break;
      case 'upi':
        this.oneTapPayWithUPI(last_payment_method.vpa);
        break;
      case 'intent':
        this.createOrderAndPayWithIntent(last_payment_method);
        break;
      case 'paytm':
        this.payWithPaytm();
        break;

      default:
        this.debounceNavigateToPayment();
    }
  };

  viewPaymentScreen = () => {
    const { navigation } = this.props;
    navigation.navigate('Payments', {
      source: this.source,
    });
  };

  payWithPaytm = () => {
    const {
      pricingData: { last_payment_method: lastPaymentMethod = {} },
    } = this.props;
    if (lastPaymentMethod.sufficient_balance) {
      this.createOrderAndPayWithPaytm();
    } else {
      this.createOrderAndAddMoney();
    }
  };

  createOrderAndAddMoney = () => {
    const { addMoneyToPaytm, showToast, navigation, renderLoader } = this.props;
    renderLoader(true);
    addMoneyToPaytm((success, response) => {
      renderLoader(false);
      if (success) {
        navigation.navigate('PaytmWebView', {
          paytmPayload: response,
          createOrderAndPayWithPaytm: this.createOrderAndPayWithPaytm,
        });
      } else {
        showToast('Something went wrong');
      }
    });
  };

  createOrderAndPayWithPaytm = () => {
    const paymentPayload = {
      paymentGateway: 'paytm',
      paymentMethod: 'paytm',
      isSavedPaymentMethod: true,
      paymentMethodPayload: {},
      payuPayload: {},
      extraData: {
        method: 'paytm',
      },
    };
    this.debouncedCreateOrder(paymentPayload);
  };

  createOrderAndPayWithIntent = (intentPackage) => {
    const { name = '', package_name = '' } = intentPackage;
    if (Utility.isBlank(name) || Utility.isBlank(package_name)) {
      this.debounceNavigateToPayment();
      return;
    }
    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: 'intent',
      isSavedPaymentMethod: true,
      paymentMethodPayload: intentPackage,
      payuPayload: intentPackage,
      extraData: {
        method: 'intent',
        name,
        package_name,
      },
    };

    this.debouncedCreateOrder(paymentPayload);
  };

  oneTapPayWithSavedCard = (lastPaymentMethod) => {
    const { navigation } = this.props;
    const params = {
      selectedPaymentOption: lastPaymentMethod,
    };

    navigation.navigate('SavedCardScreen', {
      params,
      createOrder: this.debouncedCreateOrder,
    });
  };

  onTapPayWithNetbanking = (bank) => {
    const {
      pricingData: { last_payment_method: lastPaymentMethod = {} },
    } = this.props;
    const netbankingParams = {
      bankcode: bank.bankCode,
      name: bank.bankTitle,
    };

    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: lastPaymentMethod.method,
      isSavedPaymentMethod: true,
      paymentMethodPayload: netbankingParams,
      payuPayload: bank,
      extraData: {
        method: 'netbanking',
      },
    };

    this.debouncedCreateOrder(paymentPayload);
  };

  createOrderAndPayWithOmniFlow = () => {
    const {
      pricingData: { last_payment_method: lastPaymentMethod = {} },
    } = this.props;
    const upiParams = {
      bankcode: lastPaymentMethod.bankcode,
      phone_number: lastPaymentMethod.phone_number,
    };

    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: lastPaymentMethod.method,
      isSavedPaymentMethod: true,
      paymentMethodPayload: upiParams,
      payuPayload: upiParams,
      extraData: {
        method: 'TEZOMNI',
      },
    };
    this.debouncedCreateOrder(paymentPayload);
  };

  oneTapPayWithUPI = (vpa) => {
    const {
      pricingData: { last_payment_method: lastPaymentMethod = {} },
    } = this.props;
    const upiParams = {
      virtual_bank_account_id: vpa,
      bankcode: 'UPI',
    };

    if (lastPaymentMethod.bankcode === 'TEZOMNI') {
      this.createOrderAndPayWithOmniFlow();
      return;
    }

    const payuPayload = {
      vpa,
    };
    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: lastPaymentMethod.method,
      isSavedPaymentMethod: true,
      paymentMethodPayload: upiParams,
      payuPayload,
      extraData: {
        method: 'upi',
        details: lastPaymentMethod?.details,
      },
    };

    this.debouncedCreateOrder(paymentPayload);
  };

  onCheckoutWithLastPaymentModeTapped = () => {
    const {
      authToken,
      address,
      navigation,
      allowWithOutContactLessDelivery,
      modalVisibility = () => {},
      isContactLess,
      cartItems,
      optForPlasticFreePackaging,
      pricingData: { last_payment_method: lastPaymentMethod = {} } = {},
      pricingData,
      onRefresh,
      userMobileNumber,
      addressMobileNumber,
    } = this.props;

    const meta = {
      isContactLess,
      authToken,
      address,
      lastPaymentMethod,
      cartItems,
      optForPlasticFreePackaging,
      pricingData,
      networkInfo: NetworkStats.networkDetails,
      userMobileNumber,
      addressMobileNumber,
    };

    try {
      AnalyticsUtility.fireCheckoutInitiate(meta);
    } catch (e) {}

    if (allowWithOutContactLessDelivery === 'no' && !isContactLess) {
      modalVisibility(true);
      return;
    }

    if (Utility.isBlank(address)) {
      navigation.push('Address', {
        onRefresh,
      });
      return;
    }
    this.debounceNavigateToPayment();
    // return null;
  };

  onCheckoutTapped = () => {
    const {
      authToken,
      address,
      navigation,
      pricingData: {
        last_payment_method: lastPaymentMethod = {},
        coupon_codes: couponCodes = [],
        total = '',
      } = {},
      modalVisibility = () => {},
      allowWithOutContactLessDelivery,
      isContactLess,
      cartItems,
      optForPlasticFreePackaging,
      pricingData,
      networkInfo,
      onRefresh,
      userMobileNumber,
      addressMobileNumber,
      digital_cart,
    } = this.props;

    if (digital_cart) {
      if (parseInt(total) === 0) {
        this.createOrderAndPayWithLastPaymentMode();
        return;
      }
      navigation.navigate('Payments', {
        source: this.source,
      });
      return;
    }

    const meta = {
      isContactLess,
      authToken,
      address,
      lastPaymentMethod,
      cartItems,
      optForPlasticFreePackaging,
      couponCodes,
      pricingData,
      networkInfo: NetworkStats.networkDetails,
      userMobileNumber,
      addressMobileNumber,
    };

    try {
      AnalyticsUtility.fireCheckoutInitiate(meta);
    } catch (e) {}

    if (allowWithOutContactLessDelivery === 'no' && !isContactLess) {
      modalVisibility(true);
      return;
    }

    if (Utility.isBlank(address)) {
      navigation.navigate('Address', {
        previousScreen: 'cart_checkout',
        onRefresh,
      });
      return;
    }

    if (Utility.isBlank(lastPaymentMethod)) {
      navigation.navigate('Payments', {
        source: this.source,
      });
      return;
    }

    this.createOrderAndPayWithLastPaymentMode();
  };

  selectedPayment = () => {
    const {
      pricingData: { last_payment_method: lastPaymentMethod = {} },
    } = this.props;
    if (Utility.isBlank(lastPaymentMethod)) {
      return null;
    }

    let upi_apps = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.UPI_APPS);

    const upiAppsObject = Utility.jsonParser(upi_apps || '{}');
    const upi_image =
      Utility.isPresent(lastPaymentMethod) &&
      Utility.isPresent(upiAppsObject[lastPaymentMethod.package_name]) &&
      Utility.isPresent(upiAppsObject[lastPaymentMethod.package_name].image_url)
        ? upiAppsObject[lastPaymentMethod.package_name].image_url
        : '';

    let paymentModetext = '';
    let paymentModeImage = '';

    switch (lastPaymentMethod.method) {
      case 'credit_card':
        paymentModetext = `${lastPaymentMethod.display_name}`;
        paymentModeImage =
          images.cardBrand[`${lastPaymentMethod.card_brand.toLowerCase()}`];
        break;
      case 'netbanking':
        paymentModetext = `${lastPaymentMethod.display_name}`;
        paymentModeImage = Utility.isPresent(
          images.bankImage[`BANK_${lastPaymentMethod.bankcode}`],
        )
          ? `${images.bankImage[`BANK_${lastPaymentMethod.bankcode}`]}`
          : images.payment.bank;
        break;
      case 'upi':
        paymentModetext = `${lastPaymentMethod.display_name}`;
        paymentModeImage =
          Utility.isPresent(lastPaymentMethod) &&
          Utility.isPresent(lastPaymentMethod.bankcode) &&
          lastPaymentMethod.bankcode === 'TEZOMNI'
            ? images.gpay
            : images.payment.colored_upi;

        break;
      case 'intent':
        paymentModetext = lastPaymentMethod.name;
        paymentModeImage = upi_image;
        break;

      case 'paytm':
        paymentModetext = `${lastPaymentMethod.display_name}`;
        paymentModeImage = images.paytm;

        break;
      default:
        return <FullWidthDivider />;
    }
    const styles = CartStyles;
    if (lastPaymentMethod.method === 'intent') {
      if (
        Utility.isBlank(lastPaymentMethod?.name) ||
        Utility.isBlank(lastPaymentMethod?.package_name)
      ) {
        return null;
      }
      return (
        <TouchableWithoutFeedback
          onPress={this.onCheckoutWithLastPaymentModeTapped}
        >
          <View style={styles.paymentDock}>
            <Image
              source={{ uri: paymentModeImage }}
              style={styles.selectedPaymentLogo}
            />
            <Text style={styles.selectedPaymentText}>{paymentModetext}</Text>
            <TouchableOpacity
              onPress={this.onCheckoutWithLastPaymentModeTapped}
              style={{ position: 'absolute', right: Utility.largePadding }}
              hitSlop={Utility.getHitSlop()}
            >
              <Text
                style={{
                  color: '#4285F4',
                  fontSize: 12,
                  fontFamily: 'Roboto-Regular',
                }}
              >
                CHANGE
              </Text>
            </TouchableOpacity>
          </View>
        </TouchableWithoutFeedback>
      );
    }
    return (
      <TouchableWithoutFeedback
        onPress={this.onCheckoutWithLastPaymentModeTapped}
      >
        <View style={styles.paymentDock}>
          <Image source={paymentModeImage} style={styles.selectedPaymentLogo} />
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={styles.selectedPaymentText}
            allowFontScaling={false}
          >
            {paymentModetext}
          </Text>
          <TouchableOpacity
            onPress={this.onCheckoutWithLastPaymentModeTapped}
            style={{ position: 'absolute', right: Utility.largePadding }}
            hitSlop={Utility.getHitSlop()}
          >
            <Text
              style={{
                color: '#4285F4',
                fontSize: 12,
                fontFamily: 'Roboto-Regular',
              }}
            >
              CHANGE
            </Text>
          </TouchableOpacity>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  CheckoutInfo = () => {
    const {
      cartItems,
      pricingData: { total = '', last_payment_method: lastPaymentMethod = {} },
      navigation,
      isOfferShimmerVisible,
      showToast,
      digital_cart = false,
    } = this.props;
    if (cartItems.length === 0) {
      return null;
    }

    let checkoutOrPayButton = 'Checkout';

    if (
      Utility.isPresent(lastPaymentMethod) &&
      lastPaymentMethod.method === 'paytm'
    ) {
      checkoutOrPayButton = 'Pay';
    }

    const styles = CartStyles;
    let marginBottom = 0;
    if (Utility.isIOS()) {
      marginBottom += 12;
    }
    let disabled = false;

    // let backgroundColor = colors.green;
    let error = 'Something went wrong';

    cartItems.forEach((item) => {
      if (Utility.isPresent(item.error)) {
        disabled = true;
        error = item.error;
      }
    });

    if (digital_cart && parseInt(total) === 0) {
      checkoutOrPayButton = 'Confirm Order';
    }

    return (
      <View style={styles.checkoutInfoDockContainer} dataSet={{ media: cartIds.checkoutInfoDockContainer }}>
        <this.selectedPayment />
        <View style={[styles.priceInfoDockContainer, { marginBottom }]}>
          { !isDesktop() && (
            <View style={styles.totalPriceContainer}>
              <Text style={styles.totalText}>Total</Text>
              {Utility.isBlank(total) || isOfferShimmerVisible ? (
                <ShimmerPlaceHolder
                  autoRun
                  style={styles.priceShimmer}
                  colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
                />
              ) : (
                <Text style={styles.totalPrice}>{getPriceText(total)}</Text>
              )}
            </View>
          )}
          <FoxyShadowButton
            width={isDesktop() ? 400 : 220}
            title={checkoutOrPayButton}
            onPress={disabled ? () => showToast(error) : this.onCheckoutTapped}
            backgroundColor={colors.primaryActionBackgroundColor}
            style={styles.checkoutButton}
            firstColor={colors.linearGradientGreenFirst}
            secondColor={colors.linearGradientGreenSecond}
          />
        </View>
      </View>
    );
  };

  render() {
    return <this.CheckoutInfo />;
  }
}

const mapStateToProps = (state) => ({
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
  cartPricing: state.bag.cartPricing,
  cartItems: state.bag.cartItems,
  UtoP: state.bag.UtoP,
  isContactLess: state.bag.isContactLess,
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  isNewPaymentJourney: state.bag.isNewPaymentJourney,
  userMobileNumber: state.UserAccountInfo.mobileNumber,
  addressMobileNumber: state.bag.defaultAddress.phone_number,
  membership_cohort: state.todayDeals.membership_cohort,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      createNewOrder,
      addMoneyToPaytm,
      setCurrentPaymentFlow,
      createNewOrderV2,
      setCongratsModalForEdge,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CartCheckout),
);
