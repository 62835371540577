import React, { useEffect, useRef, useState } from 'react';
import {
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  FlatList,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { useSelector } from 'react-redux';
import colors from '../../theme/Colors';
import { findIngredients } from '../../utils/Utility';
import FoxyMatchItem from './FoxyMatchItem';
import TakeSelfieForPersonalization from '../camera/shared/TakeSelfieForPersonalization';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import AppConfig from '../../config/AppConfig';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { getHitSlop, getScreenWidth } from '../../utils/LayoutUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

const FoxyMatch = (props) => {
  const {
    itemData: {
      foxy_match: { title = '', subtitle = '', objects = {} } = {},
      star_ingredients = [],
      rating = '',
      id = '',
      product_category_name = '',
      product_category: {
        category_user_attributes: { goal = [] } = {},
        slug: productCategorySlug,
      } = {},
      trusted,
      sku_id = '',
    } = {},
    navigation,
    scrollToRatings = () => {},
    renderOnboardingForFoxyMatch = false,
    itemData,
    isLoading,
    scrollToRatingSection,
  } = props;

  if (isLoading) {
    return null;
  }

  // Extract facial analysis data from the store
  const facialAnalysis =
    useSelector((state) => state.UserAccountInfo.facialAnalysis) || {};
  const userAttributes = facialAnalysis.my_attributes_values || [];

  const { productPositiveIngredients, productNegativeIngredients } =
    useIngredientProcessing(itemData, userAttributes);

  const isSelfiePresent =
    isPresent(
      useSelector((state) => state.UserAccountInfo.profile.selfie_image_url),
    ) || false;

  if (
    isBlank(itemData) ||
    isBlank(objects) ||
    AppConfig.getBooleanValue(Config.PROFILE_HIDE_FACIAL_ANALYSIS)
  ) {
    return null;
  }

  const openList = (slug) => {
    AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
      [EventParameterKey.SKU_ID]: sku_id,
      [EventParameterKey.PRODUCT_ID]: id,
      [EventParameterKey.ELEMENT_NAME]: 'foxy_match',
      [EventParameterKey.ELEMENT_TYPE]: 'ranking',
    });
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'MoreItems',
      params: {
        slug: `/${slug}`,
      },
    });
  };

  const navigateToSelfieReview = () => {
    navigation.navigate('AttributeSelector', {
      notShowReviewModal: true,
    });
  };

  return (
    <View>
      <View style={styles.upperContainer}>
        <View style={styles.container}>
          <Text style={styles.headerText}>{title}</Text>
          <Text
            style={styles.subtitle}
            allowFontScaling={false}
            numberOfLines={2}
            ellipsizeMode='tail'
          >
            {subtitle}
          </Text>
        </View>
        {isSelfiePresent && (
          <TouchableOpacity
            onPress={navigateToSelfieReview}
            hitSlop={getHitSlop()}
          >
            <Text style={styles.updateSelfieText}>Update Selfie</Text>
          </TouchableOpacity>
        )}
      </View>

      <View style={styles.foxyMatch}>
        <FlatList
          data={objects}
          keyExtractor={(_, index) => index.toString}
          scrollEnabled={false}
          renderItem={({ item }) => {
            return (
              <FoxyMatchItem
                item={item}
                myAttributesValues={userAttributes}
                heroIngredients={star_ingredients}
                rating={rating}
                productCategoryName={product_category_name}
                productPositiveIngredients={productPositiveIngredients}
                productNegativeIngredients={productNegativeIngredients}
                openList={openList}
                scrollToRatings={scrollToRatingSection}
                goal={goal}
                navigation={navigation}
                productCategorySlug={productCategorySlug}
                trusted={trusted}
                sku_id={sku_id}
                id={id}
              />
            );
          }}
          extraData={props}
        />
        {!isSelfiePresent && (
          <View>
            <View style={styles.selfieButtonContainer} />
            <TakeSelfieForPersonalization
              navigation={navigation}
              renderOnboardingForFoxyMatch={renderOnboardingForFoxyMatch}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingLeft: 12,
    paddingBottom: 12,
    paddingTop: 12,
    zIndex: 1,
  },
  headerText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  foxyMatch: {
    width: getScreenWidth() - 24,
    paddingVertical: 8,
    marginHorizontal: 12,
    paddingHorizontal: 12,
    backgroundColor: colors.white,
    borderRadius: 8,
  },
  upperContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#979BAA',
    marginTop: 4,
  },
  updateSelfieText: {
    fontSize: 14,
    color: colors.cta.lightBlue,
    alignContent: 'flex-end',
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'right',
    marginRight: 12,
  },
  selfieButtonContainer: {
    width: getScreenWidth() - 48,
    height: 1,
    backgroundColor: '#DADADA',
    alignSelf: 'center',
    marginHorizontal: 12,
    marginVertical: 16,
  },
});

export default FoxyMatch;

const useIngredientProcessing = (productData, userAttributes) => {
  // State for storing processed ingredients
  const [positiveIngredients, setPositiveIngredients] = useState({});
  const [negativeIngredients, setNegativeIngredients] = useState({});

  // References for counting processed ingredients
  const positiveIngredientCount = useRef(0);
  const negativeIngredientCount = useRef(0);

  // Extract necessary data from productData prop
  const heroIngredients = productData.star_ingredients || [];
  const userCategoryAttributes =
    productData.product_category?.category_user_attributes?.principal || [];

  // Utility function to process individual ingredients
  const processSingleIngredient = (
    ingredient,
    key,
    value,
    targetObj,
    counter,
  ) => {
    if (
      isPresent(userCategoryAttributes) &&
      isPresent(userCategoryAttributes[value])
    ) {
      const processedIngredient = {
        name: ingredient.name,
        slug: ingredient.slug,
        id: ingredient.id,
      };
      targetObj[key] = targetObj[key] || [];
      targetObj[key].push(processedIngredient);
      counter.current++;
    }
  };

  // Identify positive and negative ingredients using a utility function
  const { newGood = [], newBad = [] } = findIngredients(
    heroIngredients,
    userAttributes,
  );

  // Effect hook for processing and storing ingredients
  useEffect(() => {
    const newPositiveIngredients = { ...positiveIngredients };
    const newNegativeIngredients = { ...negativeIngredients };

    newGood.forEach((ingredient) => {
      const key = userCategoryAttributes[ingredient.indication];
      processSingleIngredient(
        ingredient,
        key,
        ingredient.indication,
        newPositiveIngredients,
        positiveIngredientCount,
      );
    });

    newBad.forEach((ingredient) => {
      const key = userCategoryAttributes[ingredient.contraindication];
      processSingleIngredient(
        ingredient,
        key,
        ingredient.contraindication,
        newNegativeIngredients,
        negativeIngredientCount,
      );
    });

    setPositiveIngredients(newPositiveIngredients);
    setNegativeIngredients(newNegativeIngredients);
  }, []);

  // Return processed ingredients and their counts
  return {
    productPositiveIngredients: positiveIngredients,
    productNegativeIngredients: negativeIngredients,
  };
};
