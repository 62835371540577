import React, { useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import FastImageView from '../../components/FastImageView';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../libraries/ReactNativeConfig';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { addContinueAction } from '../../actions/FoxyEdgeAction';

const EdgeExtensionCard = (props) => {
  const { position = '', todayDeals: { membership_cohort } = {} } = props;
  const edgeExtensionCardData = JSON.parse(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.edge_extension_card_text),
  );
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state?.bag?.cartItems) || [];
  const {
    member: {
      cta_text: memberCTAText = '',
      description: memberDescription = '',
    } = {},
    non_member: {
      cta_text: nonMemberCTAText = '',
      description: nonMemberDescription = '',
    } = {},
  } = edgeExtensionCardData;

  const buttonText =
    membership_cohort === 'non_member' ? nonMemberCTAText : memberCTAText;
  const description =
    membership_cohort === 'non_member'
      ? nonMemberDescription
      : memberDescription;

  useEffect(() => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_VIEW, {
      [EventParameterKey.TYPE]: 'edge_card',
      [EventParameterKey.POSITION]: position,
      [EventParameterKey.SCREEN_NAME]: 'cart',
    });
  }, [position]);

  const handleRouteFromLink = (route, slug, path, extra, params) => {
    const { navigation } = props;
    setTimeout(() => {
      navigation.navigate(route, { slug, extra, params });
    }, 300);
  };

  const onTapButton = () => {
    const {
      member: { cta_url: memberCTAUrl = '' } = {},
      non_member: { cta_url: nonMemberCTAUrl = '' } = {},
    } = edgeExtensionCardData;

    const url =
      membership_cohort === 'non_member' ? nonMemberCTAUrl : memberCTAUrl;

    DynamicLinkManager.handleDynamicLink(url, handleRouteFromLink);

    AddContinueActionForEdgeActivation();

    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.TYPE]: 'edge_extension_card_in_cart',
      [EventParameterKey.POSITION]: position,
      [EventParameterKey.CTA]: buttonText,
    });
  };

  const AddContinueActionForEdgeActivation = () => {
    const appDomain = Config.WEB_URL;
    if (Utility.isPresent(cartItems[0].product?.image_url)) {
      dispatch(
        addContinueAction(
          `${appDomain}/cart`,
          cartItems[0].product?.image_url,
          SCREEN_CONSTANTS.CART,
        ),
      );
    }
  };

  return (
    <View style={styles.container}>
      <FastImageView style={styles.image} source={images.edge.offer} />
      <Text style={styles.text}>{description}</Text>
      <DebouncedTouchableOpacity onPress={onTapButton} style={styles.button}>
        <Text style={styles.buttonText}>{buttonText}</Text>
      </DebouncedTouchableOpacity>
    </View>
  );
};

export default EdgeExtensionCard;

const styles = StyleSheet.create({
  container: {
    height: 64,
    width: Utility.getScreenWidth(),
    paddingHorizontal: 12,
    flexDirection: 'row',
    backgroundColor: '#FEEED7',
    alignItems: 'center',
    marginTop: 12,
  },
  image: {
    height: 32,
    width: 32,
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontWeight: '700',
    fontSize: 11,
    color: colors.foxyBlack,
    marginLeft: 8,
    width: Utility.getScreenWidth() - 150,
  },
  button: {
    height: 32,
    width: 74,
    borderRadius: 32,
    backgroundColor: colors.black,
    alignItems: 'center',
    justifyContent: 'center',
    right: 12,
    position: 'absolute',
  },
  buttonText: {
    fontFamily: 'Roboto-Medium',
    fontWeight: '500',
    fontSize: 12,
    color: colors.white,
    alignSelf: 'center',
  },
});
