import {
  select,
  call,
  put,
  delay,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import RNFetchBlob from '../libraries/RNFetchBlob';
import _ from 'lodash';
import {
  URL,
  SELFIE_STATE,
  SELFIE_STATE_ON_DELAY,
  SELFIE_ANALYSIS_DELAYED_TIME,
  UPLOAD_NOTIFICATION_TEXT,
} from '../config/Constants';
import {
  markProfilePicUploadSuccess,
  saveCDNlinktoRedux,
  START_FACIAL_ANALYSIS,
  UPDATE_USER_ATTRIBUTE,
  userFacialProperties,
  saveFaceAnalysisData,
  RESUME_SELFIE_ATTRIBUTE_FETCH,
  RETRY_SELFIE_ANALYSIS_PROCESS,
  RESUME_SELFIE_ATTRIBUTE_MAPPING,
  retrySelfieAnalysis,
} from '../actions/LoginActions';
import Utility from '../utils/Utility';
import {
  saveAllowedValues,
  UPLOAD_IMAGE,
  GET_MASTER_ATTRIBUTES_LIST,
  REFRESH_MY_ATTRIBUTES,
  masterlistLastUpdatedAt,
  UPLOAD_FND_IMAGE,
  UPLOAD_IMAGE_REVIEW,
  UPLOAD_ORDER_REQUEST_IMAGE,
} from '../actions/FacialAnalysisActions';
import SelfieUtilities from '../utils/SelfieUtilities';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../analytics';

import {
  userS3EventTriggered,
  uacGenderEventTriggered,
  uacSelfieEventTriggered,
  saveAndyWarholImages,
} from '../actions/ActionTypes';

import { getApiHeaders } from './GeneratorUtil';
import AnalyticsUtility from '../analytics/AnalyticsUtility';
import NotificationManager from '../utils/NotificationsManager';
import { NativeModules } from 'react-native';

const uploadSelfieImageToFoxy = async (base64Data, headers) => {
  const { respInfo: { status = '' } = {} } = await RNFetchBlob.fetch(
    'POST',
    URL.UPLOAD_PICTURE,
    { ...headers },
    JSON.stringify(base64Data),
  );

  if (status === 200) {
    return true;
  }
  return false;
};

export function* uploadBase64Data(data) {
  const headers = yield getApiHeaders();
  try {
    const response = yield uploadSelfieImageToFoxy(data, headers);
    return response;
  } catch (error) {
    return false;
  }
}

export function* uploadImageReview(action) {
  const { metaData, callback = () => {} } = action;
  const { base64Data, id, type } = metaData;

  try {
    const postData = {
      picture: {
        picturable_type: type, // RoutineParticipation
        picturable_id: id, // routine activity id
        image: base64Data,
      },
    };
    const response = yield uploadBase64Data(postData);
    callback(response);
  } catch (error) {
    callback(false);
  }
}

export function* addOrderRequestImage(action) {
  const { metaData } = action;
  const { base64Data, id, type } = metaData;

  try {
    const postData = {
      picture: {
        picturable_type: type,
        picturable_id: id,
        image: base64Data,
      },
    };

    const response = yield uploadBase64Data(postData);

    if (response) {
    }
  } catch (error) {}
}

export default function* watchUploadReviewSaga() {
  yield takeEvery(UPLOAD_IMAGE_REVIEW, uploadImageReview);
  yield takeEvery(UPLOAD_ORDER_REQUEST_IMAGE, addOrderRequestImage);
}
