import { getStateFromPath } from '@react-navigation/native';
import { isDesktop } from '../utils/BooleanUtility';

const linking = {
  prefixes: [
    'http://localhost:8082/',
  ],
  config: {
    /* configuration for matching screens with paths */
    screens: {
      DetailStackNavigator: {
        screens: {
          ScreenNavigator: '',
          TabNavigator: {
            screens: {
              Home: {
                screens: {
                  Feed: 'feed',
                },
              },
              Store: {
                screens: {
                  Store: 'shop',
                },
              },
              ForYou: 'for-you',
              Reviews: {
                screens: {
                  Reviews: 'reviews',
                },
              },
              Payoff: {
                screens: {
                  Payoff: 'personalised-beauty',
                },
              },
              Brands: {
                screens: {
                  Brands: 'brands',
                },
              },
              Wishlist: {
                screens: {
                  Wishlist: 'wishlist',
                },
              },
              Categories: {
                screens: {
                  Categories: 'categories',
                },
              },
            },
          },
          MyProfile: 'famous',
          Tag: 'tags',
          CategoryDetail: 'category-details',
          ContentModal: 'youtube_videos',
          UploadVideo: 'upload-video',
          NotificationHistory: 'notification-history',
          MyUploads: 'my-uploads',
          PaginatedList: 'paginated-list',
          ProfileEdit: 'profile-edit',
          Address: 'addresses',
          ConsumerProfile: 'consumer-profile',
          Interests: 'interests',
          PaymentMethods: 'payment-methods',
          ImageReview: 'image-review',
          StoryDetails: 'story-details',
          RecommendationPrompt: 'recommendation-prompt',
          FollowRecommendations: 'follow-recommendations',
          RecycleWithFoxyWebView: 'recycle-with-foxy',
          Cart: 'cart_items',
          MediaPlaylist: 'media-playlist',
          CartOffers: 'cart-offers',
          MyCollection: 'my-collection',
          OrderStatus: 'order-status',
          Payments: 'payment_methods',
          SavedCardScreen: 'saved-card-screen',
          FaqSection: 'Faq-section',
          FaqQuestionSection: 'Faq-question-section',
          FaqAnswerSection: 'Faq-answer-section',
          SwipableCards: 'swipable-cards',
          FullPageProductDescription: 'product-description',
          AccountSettings: 'account-settings',
          AboutUs: 'about-us',
          DeleteSelfie: 'delete-selfie',
          WebUrlView: 'view',
          FoxyClub: 'foxy-club',
          UpdateCart: 'update-cart',
          LoyaltyPlans: 'loyalty-plans',
          DateOfBirth: 'date-of-birth',
          OrdersHistory: 'order-history',
          Onboarding: 'onboarding',
          SelfiePrompt: 'selfie-prompt',
          ProfileDetails: 'profile-details',
          OrderDetails: 'order-details',
          TermsOfUse: 'terms-of-use',
          PrivacyPolicy: 'privacy-policy',
          ShippingPolicy: 'shipping-policy',
          PersonalisedOffers: 'personalised-offers',
          PrivacyConcerns: 'privacy-concerns',
          RequestCallBackPage: 'request-callback',
          ContactUsReasonPage: 'contact-us-reason',
        },
      },
    },
  },
  getStateFromPath: (path, options) => {
    const [url, params] = path.split('?');
    let newPath = path;
    if (url.includes('/orders') && url.includes('/confirmation')) {
      const [,,orderId] = url.split('/');
      newPath = `/order-details?id=order-${orderId}&${params}`;
    }
    if (url.includes('/addresses')) {
      newPath = params ? `/addresses?${params}` : '/addresses';
    }
    return getStateFromPath(newPath, options);
  },
};

if (isDesktop()) {
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.Product = 'products/:shortSlug';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.OfferDetail = 'offers/:shortSlug';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.MoreItems = 'lists/:shortSlug';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.TheEdge = 'foxy_edge';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.Category = 'categories/:first/:second?/:third?';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.Brand = 'brands/:shortSlug/:isCategory?/:first?/:second?/:third?';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.Search = 'search';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.Ingredients = 'ingredients/:shortSlug?';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.PostReview = 'products/:shortSlug/add_review';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.AllReviews = 'products/:shortSlug/reviews';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.Artist = 'artists/:bioHandle';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.TodayDeals = 'today-deals';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.ArtistLists = 'lists/artists/:handle/:listType';
  linking.config.screens.DetailStackNavigator.screens.TabNavigator.screens.ProductLists = 'products/:name/:listType';
} else {
  linking.config.screens.DetailStackNavigator.screens.Product = 'products/:shortSlug';
  linking.config.screens.DetailStackNavigator.screens.OfferDetail = 'offers/:shortSlug';
  linking.config.screens.DetailStackNavigator.screens.MoreItems = 'lists/:shortSlug';
  linking.config.screens.DetailStackNavigator.screens.TheEdge = 'foxy_edge';
  linking.config.screens.DetailStackNavigator.screens.Category = 'categories/:first/:second?/:third?';
  linking.config.screens.DetailStackNavigator.screens.Brand = 'brands/:shortSlug/:isCategory?/:first?/:second?/:third?';
  linking.config.screens.DetailStackNavigator.screens.Search = 'search';
  linking.config.screens.DetailStackNavigator.screens.Ingredients = 'ingredients/:shortSlug?';
  linking.config.screens.DetailStackNavigator.screens.PostReview = 'products/:shortSlug/add_review';
  linking.config.screens.DetailStackNavigator.screens.AllReviews = 'products/:shortSlug/reviews';
  linking.config.screens.DetailStackNavigator.screens.Artist = 'artists/:bioHandle';
  linking.config.screens.DetailStackNavigator.screens.TodayDeals = 'today-deals';
  linking.config.screens.DetailStackNavigator.screens.ArtistLists = 'lists/artists/:handle/:listType';
  linking.config.screens.DetailStackNavigator.screens.ProductLists = 'products/:name/:listType';
}

export default linking;
