import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  BackHandler,
  Alert,
  Linking,
  SafeAreaView,
  Image,
  TouchableOpacity,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { WebView } from 'react-native-webview';
import { connect } from 'react-redux';
import Share from '../../libraries/ReactNativeShare';
import Utility from '../../utils/Utility';
import {
  URL,
  LAYOUT,
  PERMISSION_STORE,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import { AnalyticsManager } from '../../analytics';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import RemoteConfig from '../../utils/RemoteConfig';
import { navigateToScreen } from '../../utils/NavigationUtility';

const styles = StyleSheet.create({
  container: { flex: 1 },
  webView: {
    flex: 1.7,
  },
  imageViewContainer: {
    height: 20,
    width: 20,
    padding: 2,
    position: 'absolute',
    right: 16,
    top: 32,
    tintColor: colors.background,
  },
  imageView: { height: 16, width: 16, tintColor: '#fff' },
  imageViewBlack: { height: 16, width: 16, tintColor: '#000' },
});

class RecycleWithFoxyWebView extends PureComponent {
  state = {
    showCrossButton: true,
    showBlackCross: true,
  };

  constructor(props) {
    super(props);

    this.isPageLoaded = false;
    this.currentActivePage = '';

    this.backHandler = null;
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler.remove();
    }
    AnalyticsManager.logEvent('exit_recycling', {
      source: this.currentActivePage,
    });
  }

  onHardwareBackKeyPress = () => {
    this.goBack();
    return true;
  };

  handleMessage = (wvMessage) => {
    try {
      this.setState({
        showBlackCross: false,
      });
      const { event, params, slug, type } = Utility.jsonParser(
        wvMessage.nativeEvent.data,
      );
      const { navigation } = this.props;

      const recycle_with_foxy_message = RemoteConfig.getValue(
        REMOTE_CONFIG_KEYS.recycle_with_foxy_message,
      );
      AnalyticsManager.logEvent(event, params);
      if (event === 'recycle_currently_active_page') {
        this.currentActivePage = params.name;
      }
      if (
        event === 'recycle_address_share_now' ||
        event === 'recycle_share_and_activate_click'
      ) {
        navigation.navigate('Contacts', {
          whatsappMessage: `${recycle_with_foxy_message}`,
        });
      }
      if (event === 'exit_recycling') {
        this.goBack();
      }
      if (event === 'recycle_shop_now_click') {
        navigation.navigate('Store');
      }
      if (event === 'empties_submit_success') {
        setTimeout(() => {
          this.checkCameraPermission();
        }, 500);
      }
      if (type === 'brand') {
        navigateToScreen({
          navigation,
          type: 'push',
          screen: 'Brand',
          params: {
            slug: `/api/v1/brands/${slug}.json`,
            display: LAYOUT.SCREEN,
          },
        });
      }
      if (params.name === 'active_search' || params.name === 'image_upload') {
        this.setState({
          showCrossButton: false,
        });
      } else {
        this.setState({
          showCrossButton: true,
        });
      }
    } catch (err) {
      console.log('RecycleWithFoxyWebView', err);
    }
  };

  checkCameraPermission = () => {
    Utility.checkPermission(
      PERMISSION_STORE.camera,
      true,
      (isAllowed) => {
        if (!isAllowed) {
          if (Utility.isIOS()) {
            this.openAlert();
          }
        }
      },
      {},
    );
  };

  openAlert = (message) => {
    Alert.alert(
      'Allow access to camera to enable facial analysis, post videos/reviews & scan products',
      message,
      [
        {
          text: 'Settings',
          onPress: () => {
            Linking.openURL('app-settings:');
          },
        },
        {
          text: 'Cancel',
          onPress: () => {},
          style: 'cancel',
        },
      ],
    );
  };

  shareFromWebView = () => {
    const { navigation } = this.props;

    const recycle_with_foxy_message = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.recycle_with_foxy_message,
    );

    // let shareMedium = shareOn==='sms'? 'sms':Share.Soc
    const shareOptions = {
      title: `Share ${Config.APP_NAME} App Using`,
      message: `${recycle_with_foxy_message}`,
    };

    Share.open(shareOptions)
      .then((res) => {})
      .catch((err) => {
        console.log('Seems like this app is not installed on your system');
      });
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  onLoadEnd = () => {
    this.isPageLoaded = true;
  };

  onPageLoadStart = () => {
    this.isPageLoaded = false;
  };

  render() {
    const {
      authToken,
      navigation,
      route,
      guestProfile: { guestAuthToken },
    } = this.props;
    const { showCrossButton, showBlackCross } = this.state;
    const destination = route.params?.newDestination;
    const injectedJavaScript = `setTimeout(() => {
      const meta = document.createElement('meta');
      meta.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
      );
      meta.setAttribute('name', 'viewport');
      document.getElementsByTagName('head')[0].appendChild(meta);
      window.ReactNativeWebView.postMessage(document.title);
      true;
    }, 100)`;

    let url = destination || `${URL.RECYCLE_WITH_FOXY}`;
    url = `${url}?platform=app`;
    return (
      <SafeAreaView style={styles.container}>
        <Image
          source={images.recycle_safe_area}
          style={{ position: 'absolute' }}
        />
        <View style={styles.webView}>
          <WebView
            style={styles.webViewHeight}
            ref={(ref) => {
              this.webview = ref;
            }}
            source={{
              uri: url,
              headers: {
                'x-auth-token': `${authToken}`,
                'x-guest-token': `${guestAuthToken}`,
              },
            }}
            onPageLoadStart={this.onPageLoadStart}
            onLoadEnd={this.onLoadEnd}
            // nativeConfig={{ props: { webContentsDebuggingEnabled: true } }}
            injectedJavaScript={injectedJavaScript} // to remove zoom and add header title
            onMessage={this.handleMessage}
            javaScriptEnabled
            onError={(error) => console.log('Error is co', error)}
            scalesPageToFit={true}
            bounces={false}
          />
          {showCrossButton && (
            <TouchableOpacity
              onPress={this.goBack}
              style={styles.imageViewContainer}
            >
              <Image
                source={images.cross}
                style={
                  showBlackCross ? styles.imageViewBlack : styles.imageView
                }
              />
            </TouchableOpacity>
          )}
        </View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => ({
  guestProfile: state.UserAccountInfo.guestProfile,
  authToken: state.UserAccountInfo.authToken,
});

export default connect(mapStateToProps, null)(RecycleWithFoxyWebView);
