import React from 'react';
import { View, Text, FlatList, TouchableOpacity, Image } from 'react-native';
import _ from 'lodash';
import images from '../../theme/Images';

import { cartIds, CartStyles } from './styles';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import DeliveryInformation from './DeliveryInfo';

export const Address = ({ address, debounceNavigateToAddress }) => {
  const styles = CartStyles;
  if (Utility.isBlank(address)) {
    return null;
  }
  let {
    name = 'Others',
    addressLine1 = '',
    addressLine2 = '',
    pincode = '',
    contact_name = '',
  } = address;
  const locationIcon = Utility.getAddressIcon(name);

  if (addressLine1) {
    addressLine1 += ', ';
  }
  if (addressLine2) {
    addressLine2 += ' - ';
    addressLine2 = addressLine2?.replace(', India', '');
  }
  return (
    <View style={styles.individualAddressContainer}>
      <View style={styles.userAddressTextContainer}>
        <Image
          style={styles.locationIcon}
          source={locationIcon}
          dataSet={{ media: cartIds.locationIcon }}
        />
        <View style={styles.cartAddressTextContainer}>
          <Text numberOfLines={3} style={styles.contactNameText} dataSet={{ media: cartIds.contactNameText }}>
            {`${contact_name ?? ''} : `}
            <Text ellipsizeMode='tail' style={styles.addressTextName} dataSet={{ media: cartIds.addressTextName }}>
              {`${addressLine1 ?? ''}${addressLine2 ?? ''}${pincode ?? ''}`}
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );
};

const AddAddressButton = ({ debounceNavigateToAddress }) => {
  const styles = CartStyles;

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: 16,
      }}
    >
      <Image
        source={images.foxyPromise.fastDelivery}
        style={{
          height: 14,
          width: 18,
          marginRight: 8,
        }}
      />
      <Text
        style={{
          fontFamily: 'Roboto-Regular',
          fontSize: 12,
          color: colors.foxyBlack,
        }}
        allowFontScaling={false}
      >
        Add address to see delivery timelines
      </Text>
    </View>
  );
};

const DeliveryInfo = ({ defaultAddress }) => {
  const {
    delivery_message: {
      promise_delivery = false,
      heading = '',
      promise_delivery_title = '',
      promise_delivery_sub_title = '',
    } = {},
  } = defaultAddress;

  if (Utility.isBlank(heading)) {
    return null;
  }

  const tintColor = promise_delivery ? colors.foxyPink : colors.black;

  const style = promise_delivery
    ? {
        backgroundColor: '#FFEEF2',
        padding: 12,
        borderRadius: 8,
        marginHorizontal: 16,
        marginBottom: 8,
      }
    : {
        paddingHorizontal: 16,
        paddingVertical: 8,
      };

  return (
    <View style={style}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Image
          source={images.foxyPromise.fastDelivery}
          style={{
            height: 14,
            width: 18,
            marginRight: 8,
            tintColor: tintColor,
          }}
        />
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: 12,
            fontStyle: 'normal',
            color: colors.foxyBlack,
          }}
          allowFontScaling={false}
        >
          {heading}
        </Text>
      </View>
      {promise_delivery && (
        <View style={{ marginTop: 8 }}>
          <Text
            style={{
              fontFamily: 'Roboto-Regular',
              fontSize: 12,
              fontStyle: 'normal',
              color: colors.foxyBlack,
            }}
            allowFontScaling={false}
          >
            {promise_delivery_title}
          </Text>
          <Text
            style={{
              fontFamily: 'Roboto-Regular',
              fontSize: 12,
              fontStyle: 'normal',
              color: colors.silver,
            }}
            allowFontScaling={false}
          >
            {promise_delivery_sub_title}
          </Text>
        </View>
      )}
    </View>
  );
};

const DelayedDelivery = ({ defaultAddress }) => {
  const { delayed_delivery: { is_delayed = false, message = '' } = {} } =
    defaultAddress;

  if (!is_delayed || Utility.isBlank(message)) {
    return null;
  }
  return (
    <Text
      style={{
        fontSize: 12,
        fontFamily: 'Roboto-Regular',
        color: '#979BAA',
        paddingHorizontal: 16,
        paddingBottom: 8,
      }}
      allowFontScaling={false}
    >
      {message}
    </Text>
  );
};

// const DelayInfo = ({
//   numberOfDelayItems = 0,
//   orderCampaign,
//   orderCampaign: {
//     expected_instock_date = '',
//     order_campaings_type = 'preorder',
//   } = '',
// }) => {
//   if (Utility.isBlank(expected_instock_date)) return null;
//   const preOrder = true;
//   const preOrderText =
//     order_campaings_type === 'preorder' ? 'pre-ordered' : 'back-ordered';
//   const text =
//     numberOfDelayItems > 1
//       ? `${numberOfDelayItems} items will be ${preOrderText} and are expected to dispatch by `
//       : `1 item will be ${preOrderText} and is expected to dispatch by `;
//   return (
//     <View
//       style={{
//         flexDirection: 'row',
//         backgroundColor: '#FEF7EF',
//         height: 42,
//         marginHorizontal: 8,
//         marginBottom: 12,
//         paddingHorizontal: 10,
//       }}
//     >
//       <FastImageView
//         source={images.rotate_cw}
//         style={{ height: 14, width: 14, top: 10 }}
//       />
//       <View
//         style={{
//           margin: 6,
//           flexDirection: 'row',
//           alignItems: 'flex-start',
//           flexWrap: 'wrap',
//         }}
//       >
//         <Text
//           style={{
//             fontFamily: 'Roboto-Regular',
//             fontSize: size.h4,
//             fontWeight: '400',
//             color: colors.foxyBlack,
//             // marginLeft: 4,
//           }}
//           numberOfLines={MAX_LINES.two}
//         >
//           {text}
//         </Text>
//         <Text
//           style={{
//             fontFamily: 'Roboto-Bold',
//             fontSize: size.h4,
//             fontWeight: '700',
//             color: colors.foxyBlack,
//             alignSelf: 'center',
//           }}
//         >
//           {TimeUtility.getMonthDayDate(expected_instock_date)}
//         </Text>
//       </View>
//     </View>
//   );
// };

const CartAddresses = ({
  address = [],
  debounceNavigateToAddress,
  getShipmentText = '',
}) => {
  const styles = CartStyles;

  let addressArray = [];
  let deliveryMessage = [];
  if (!Utility.isBlank(address)) {
    addressArray = Object.keys(address).map((key) => ({
      id: key,
      name: address[key].name ?? '',
      pincode: address[key].pincode ?? '',
      addressLine1: address[key].line1 ?? '',
      addressLine2: address[key].line2 ?? '',
      contact_name: address[key].contact_name ?? '',
      default: address[key].default ?? false,
      delayed_delivery: address[key].delayed_delivery,
      delivery_message: address[key].delivery_message,
    }));
  }

  let defaultAddress = addressArray.filter((item) => item.default)[0];
  if (Utility.isBlank(defaultAddress)) {
    defaultAddress = addressArray[0];
  }
  let cta = Utility.isBlank(defaultAddress) ? 'ADD ADDRESS' : 'CHANGE';

  return (
    <TouchableOpacity
      onPress={debounceNavigateToAddress}
      hitSlop={Utility.getHitSlop()}
    >
      <View style={styles.addressContainerForCartPage} dataSet={{ media: cartIds.addressContainerForCartPage }}>
        <View style={styles.addressTextContainer}>
          <Text style={styles.addressHeaderText} dataSet={{ media: cartIds.addressHeaderText }}>Deliver to</Text>

          <Image
            source={images.chevronRight}
            style={styles.rightArrowAddress}
          />
        </View>

        {Utility.isPresent(defaultAddress) && (
          <Address
            address={defaultAddress}
            debounceNavigateToAddress={debounceNavigateToAddress}
          />
        )}

        <DeliveryInformation
          address={address}
          getShipmentText={getShipmentText}
        />
        {/* <DelayInfo
          numberOfDelayItems={numberOfDelayItems}
          orderCampaign={orderCampaign}
        /> */}
      </View>
    </TouchableOpacity>
  );
};

export default CartAddresses;
