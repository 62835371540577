import React, { Fragment, PureComponent } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';

import { List } from '.';
import ListsFilter from '../../components/layout/ListsFilter';
import Utility from '../../utils/Utility';
import GridScreenPlaceHolder from '../../components/shared/GridScreenPlaceHolder';
import WithNavigation from '../../utils/WithNavigation';
import FilterResultsEmptyState from '../../components/sort-filter-options/FilterResultsEmptyState';
import { isBlank, isPresent, isDesktop, isWeb } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import WebListTitle from './WebListTitle';
import WebFooter from '../../components/webFooter/WebFooter';

class ListWithFilterComponent extends PureComponent {
  quickFiltersRow = () => {
    const {
      stickyPosition,
      selectedFilters,
      itemData,
      getQuickFiltersListRef,
      onFiltersPress,
      filtersFromDeepLink,
    } = this.props;

    if (Utility.isBlank(itemData.quick_filters)) return null;

    return (
      <View style={styles.wrapper}>
        <ListsFilter
          getQuickFiltersListRef={getQuickFiltersListRef}
          stickyPosition={stickyPosition}
          selectedFilters={selectedFilters}
          itemData={itemData}
          onFiltersPress={onFiltersPress}
          index={1}
          filtersFromDeepLink={filtersFromDeepLink}
        />
      </View>
    );
  };

  listRow = () => {
    if (isDesktop()) {
      return (
        <View style={styles.listContainer}>
          <List {...this.props} />
        </View>
      );
    }
    return <List {...this.props} />;
  }

  render() {
    const {
      showProductShimmer,
      itemData: { objects = [] } = {},
      onPressResetButton = () => {},
      selectedFilters = {},
      webListTitle = '',
    } = this.props;
    const ContainerComponent = isWeb() ? ScrollView : Fragment;
    return (
      <>
        {isWeb() && webListTitle && <WebListTitle title={webListTitle} background="#F2F2F2" />}
        <this.quickFiltersRow />
        {showProductShimmer && <GridScreenPlaceHolder />}

        {!showProductShimmer && (
          <FilterResultsEmptyState
            showFilterEmptyState={isBlank(objects)}
            onPressResetButton={onPressResetButton}
            areFiltersEnabled={isPresent(selectedFilters)}
          >
            <ContainerComponent>
              <View style={styles.container}>
                <List {...this.props} />
              </View>
              <WebFooter />
            </ContainerComponent>
          </FilterResultsEmptyState>
        )}
      </>
    );
  }
}

export default ListWithFilterComponent;

const styles = StyleSheet.create({
  wrapper: {
    zIndex: 1,
    height: 55,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  listContainer: {
    flex: 1,
    width: Utility.getScreenWidth() + 20,
    alignSelf: 'center',
  },
  container: {
    flex: 1,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
});
