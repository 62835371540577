import { StyleSheet, Dimensions, Platform } from 'react-native';

import colors, { Theme } from '../../../theme/Colors';
import size from '../../../theme/Fonts';

// const topInset = SafeArea.getSafeAreaInsetsForRootView().then(result => result.safeAreaInsets);

// console.log('In StyleSheet', topInset);

const { width, height } = Dimensions.get('window');

const modalInsetHeight = height - 18.0;

const SelectYourInterestStyles = StyleSheet.create({
  modal: Platform.select({
    android: { width, height },
    ios: { flex: 1 },
    web: { flex: 1 },
  }),

  modalContainer: {
    height: '100%',
    width,
    backgroundColor: Theme.light.backgroundColor,
    justifyContent: 'space-between',
  },

  modalInset: {
    backgroundColor: '#ffffff',
    flexDirection: 'column',
  },

  titleContainer: {
    // backgroundColor: 'green',
    paddingVertical: 16.0,
    paddingHorizontal: 16,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

  mainTitle: {
    fontSize: 20,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
  },

  subTitle: {
    fontSize: 14,
    color: '#979baa',
    fontFamily: 'Roboto-Regular',
  },

  skipTitle: {
    fontSize: 16,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    marginTop: 6,
  },

  interestImageContainer: {
    height: height / 2.5,
    marginTop: height / 42,
  },

  interestImageStyle: {
    width: width / 3.6,
    height: width / 3.6,
    marginRight: 10,
    marginBottom: 10,
  },

  continueButtonContainer: {
    width,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const InterestStyles = StyleSheet.create({
  interestImage: {
    marginRight: 12,
    marginTop: 20,
    borderRadius: 4,
    overflow: 'hidden',
  },

  GradientStyles: {
    flex: 1,
    padding: 6,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  iconFrame: {
    width: 19,
    height: 19,
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default SelectYourInterestStyles;
