import React, { PureComponent } from 'react';
import { View, StyleSheet, BackHandler, Linking } from 'react-native';
import { WebView } from 'react-native-webview';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StaticNavigationHeader } from '../shared';
import Utility from '../../utils/Utility';
import { URL, LAYOUT, WEB_URL } from '../../config/Constants';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { AnalyticsManager } from '../../analytics';
import { navigateToScreen } from '../../utils/NavigationUtility';

const styles = StyleSheet.create({
  container: { flex: 1 },
  webView: {
    flex: 1.7,
  },
});

class OrdersWebView extends PureComponent {
  constructor(props) {
    super(props);
    const { navigation, route } = props;
    this.orderId = route.params?.id;

    this.state = {
      webviewTitle: Utility.isBlank(this.orderId)
        ? 'Order History'
        : 'Order Details',
      url: Utility.isPresent(this.orderId)
        ? `${WEB_URL}/orders/${this.orderId}?platform=app`
        : '',
      canGoBack: false,
    };
    this.isPageLoaded = false;

    this.backHandler = null;
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }

    const { url } = this.state;
    const routeUrl = route.params?.url;
    let externalUrl = routeUrl ?? `${URL.WEBVIEW_ORDER_LIST}?platform=app`;

    if (Utility.isPresent(navigation) && !Utility.isBlank(routeUrl)) {
      if (!routeUrl.includes('platform=app')) {
        externalUrl = `${routeUrl}?platform=app`;
      }
    }

    this.newUrl =
      url || externalUrl || `${URL.WEBVIEW_ORDER_LIST}?platform=app`;
    console.log('current url: ', this.newUrl, route.params?.url);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler.remove();
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { webviewTitle } = this.state;
    if (
      nextState.webviewTitle.includes('whatsapp') &&
      webviewTitle.includes('Order')
    ) {
      Utility.openWhatsAppWithNumber(nextState.webviewTitle);
      this.webview.goBack();
      return true;
    }
    if (nextState.webviewTitle.includes('orders')) {
      this.updateTitle();
    }
  }

  updateTitle = () => {
    this.setState({
      webviewTitle: Utility.isPresent(this.orderId)
        ? 'Order Details'
        : 'Order History',
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    this.goBack();
    return true;
  };

  handleMessage = (wvMessage) => {
    const { data, title } = wvMessage.nativeEvent;
    console.log('$$$$', wvMessage);
    const { navigation } = this.props;
    try {
      const { type, slug, event, params } = Utility.jsonParser(
        decodeURIComponent(decodeURIComponent(data)),
      );
      if (type === 'product') {
        navigateToScreen({
          navigation,
          type: 'navigate',
          screen: 'Product',
          params: {
            slug: `/api/v1/products/${slug}.json`,
            display: LAYOUT.SCREEN,
          },
        });
      } else if (type === 'brand') {
        navigateToScreen({
          navigation,
          type: 'navigate',
          screen: 'Brand',
          params: {
            slug: `/api/v1/brands/${slug}.json`,
            display: LAYOUT.SCREEN,
          },
        });
      } else if (type === 'empty_state_order_button_click') {
        navigation.navigate('Store');
      }
      AnalyticsManager.logEvent(event, params);
    } catch (er) {
      console.log(er, 'error');
    }

    if (Utility.isPresent(data)) {
      const { type, message } = data;
      if (type === 'header_title') {
        this.setState({ webviewTitle: message.text });
      }
    }
  };

  goBack = () => {
    const { webviewTitle, canGoBack } = this.state;
    const { navigation } = this.props;
    if (!canGoBack) {
      navigation.goBack();
    } else {
      this.webview.goBack();
    }
  };

  onNavigationStateChange = (wvMessage) => {
    const { title } = wvMessage;
    if (Utility.isPresent(title) && !title.startsWith(`${WEB_URL}`)) {
      this.setState({ webviewTitle: title, canGoBack: wvMessage.canGoBack });
    }
  };

  onLoadEnd = () => {
    this.isPageLoaded = true;
  };

  onPageLoadStart = () => {
    this.isPageLoaded = false;
  };

  render() {
    const { webviewTitle } = this.state;
    const { authToken } = this.props;

    const injectedJavaScript = `setTimeout(() => {
      const meta = document.createElement('meta');
      meta.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
      );
      meta.setAttribute('name', 'viewport');
      document.getElementsByTagName('head')[0].appendChild(meta);
      window.ReactNativeWebView.postMessage(document.title);
      true;
    }, 100)`;

    console.log(this.newUrl, 'newUrlnewUrl');

    return (
      <View style={styles.container}>
        <StaticNavigationHeader
          title={`${webviewTitle}`}
          onBackPress={this.goBack}
        />
        <View style={styles.webView}>
          <WebView
            style={styles.webViewHeight}
            ref={(ref) => {
              this.webview = ref;
            }}
            source={{
              uri: this.newUrl,
              headers: { 'x-auth-token': `${authToken}` },
              // body: JSON.stringify({ show_header: false }),
            }}
            onPageLoadStart={this.onPageLoadStart}
            onLoadEnd={this.onLoadEnd}
            // nativeConfig={{ props: { webContentsDebuggingEnabled: true } }}
            injectedJavaScript={injectedJavaScript} // to remove zoom and add header title
            onMessage={this.handleMessage}
            javaScriptEnabled
            onError={(error) => console.log('Error is co', error)}
            onNavigationStateChange={this.onNavigationStateChange}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    authToken: state.UserAccountInfo.authToken,
  };
};

export default connect(mapStateToProps, null)(OrdersWebView);
