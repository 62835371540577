import {
  View,
  TouchableOpacity,
  Linking,
  Image,
  StyleSheet,
} from 'react-native';
import React from 'react';

export default function Buttons() {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => Linking.openURL('https://apps.apple.com/in/app/foxy-personalised-beauty-app/id1476031752')}
        style={styles.leftButton}
      >
        <Image
          source={{ uri: 'https://www.foxy.in/assets/icons/apple-store-big-c6b4d4dd4e3bebd299419162f22b99bb98d21ea9b13e4f878760e567ccc4fd36.png' }}
          style={styles.image}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=in.foxy&hl=en_IN&gl=US')}>
        <Image
          source={{ uri: 'https://www.foxy.in/assets/icons/google-download-rounded-d98f4c09182c4d918b101c906172dce915f51b3b5cd19f2bbc0e875587d3c94e.png' }}
          style={styles.image}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: '10%',
    flexDirection: 'row',
  },
  leftButton: {
    marginRight: 5,
  },
  image: {
    width: '18vw',
    height: 44.69,
  },
});
