import {
  View,
  Text,
  StyleSheet,
  Image,
} from 'react-native';
import React from 'react';
import Buttons from './Buttons';

export default function TopLayer() {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <View>
          <Text style={styles.heading}>Personalized Beauty.</Text>
          <Text style={styles.subHeading}>Your Hero Ingredient, Delivered.</Text>
          <Text style={styles.description}>
            Get selfie-based personal recommendations, exclusive deals, ingredients helpful to reach your skincare and haircare goals.
          </Text>
        </View>
        <Buttons />
      </View>
      <View style={styles.topRight}>
        <Image
          source={{ uri: 'https://www.foxy.in/assets/icons/for-you-app-pic-1-26f7ec1ecc5bb7b74401614de49002a38d8b85c6c782f41e84f2652bd3a1d901.png' }}
          style={styles.rightImage}
        />
        <View style={styles.dottedCircle} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 'calc(0.6004691165 * 100vw)',
    backgroundColor: '#F5F6F8',
    overflow: 'hidden',
    display: 'block',
  },
  textContainer: {
    width: 'fit-content',
    height: 'fit-content',
    display: 'inline-block',
    marginTop: '14%',
    marginLeft: '13%',
  },
  buttonContainer: {
    marginTop: '10%',
  },
  heading: {
    margin: 0,
    color: '#F45A44',
    fontWeight: 900,
    fontSize: '4vw',
    fontFamily: 'Roboto-Bold',
    lineHeight: 96.4,
  },
  subHeading: {
    color: '#173143',
    margin: 0,
    fontSize: '2.8vw',
    fontFamily: 'Roboto-Regular',
    lineHeight: 60.4,
  },
  description: {
    marginTop: '4%',
    width: '41vw',
    fontSize: '1.5vw',
    fontFamily: 'Roboto-Regular',
    lineHeight: 32.4,
  },
  topRight: {
    height: '38vw',
    width: '38vw',
    position: 'relative',
    left: '10%',
    float: 'right',
    borderRadius: '50%',
    backgroundImage: 'linear-gradient(184.48deg, #fcd0a3 3.63%, #e0effd 80.42%)',
    top: '12%',
  },
  rightImage: {
    width: 302,
    height: 591,
    zIndex: 1,
    right: 20,
  },
  dottedCircle: {
    width: '41%',
    height: '41%',
    borderRadius: '50%',
    border: '1px dashed black',
    position: 'absolute',
    top: '-15%',
    right: '36%',
  },
});
