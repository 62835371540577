import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { View, FlatList } from 'react-native';
import { useDispatch } from 'react-redux';
import { fetchList } from '../../actions/ActionTypes';
import { getTabGroupCardComponent, getTabGroupComponent } from './TabHelper';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import ListPlaceHolder from '../../components/shared/GridScreenPlaceHolder';
import { AnalyticsUtilty } from '../../analytics';


const GetComponent = (props) => {
  const { itemData, lastLevelTab } = props;
  if (isPresent(itemData) && !lastLevelTab) {
    return <TabGroup {...props} />;
  }
  return null;
};

const RenderSubTab = (props) => {
  const {
    selectedTabData: {
      objects: { objects: subTabData = [], display = '' } = {},
      last_level_tab: lastLevelTab,
    },
    selectedIndex,
    listData = {},
    previousScreen = '',
    setSelectedTabList = () => {},
    defaultList = {},
    setIsLoading,
  } = props;

  return (
    <GetComponent
      itemData={subTabData}
      lastLevelTab={lastLevelTab}
      index={selectedIndex}
      listData={listData}
      previousScreen={previousScreen}
      setSelectedTabList={setSelectedTabList}
      defaultList={defaultList}
      display={display}
      setIsLoading={setIsLoading}
    />
  );
};


const RenderTab = (props) => {
  const {
    itemData = {},
    index = 0,
    setSelectedTabList,
    setSelectedTabData,
    selectedIndex,
    setSelectedIndex,
    defaultList,
    setTabsData,
    tabsData,
    display,
    listData: {
      name: listName = '',
      display: listDisplay = '',
      content: listContent = '',
      slug: listSlug = '',
      id: listId = '',
      display_order: listIndex = '',
    },
    previousScreen,
    setIsLoading,
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    updateStateFromProps();

  }, [itemData]);

  const updateStateFromProps = () => {
    const { last_level_tab: lastLevelTab } = itemData[0];
    setSelectedIndex(0);
    setSelectedTabList({});
    setSelectedTabData(itemData[0] || {});
    setTabsData(itemData);
    if (lastLevelTab) {
      if (isPresent(itemData[0].listData)) {
        setSelectedTabList(itemData[0].listData);
        return;
      }
      if (isPresent(itemData[0].list_slug)) {
        hitApiForList(itemData[0].list_slug, 0);
      }
    }
  };

  const isLinearTab = display === 'linear-tab';
  const flatListStyle = isLinearTab
    ? styles.headerTabs
    : styles.circularTabContainer;
  const tabStyle =
    itemData.length === 3 ? styles.tabStyleForThree : styles.tabStyle;
  const indicatorStyle =
    itemData.length === 3
      ? styles.indicatorStyleForThree
      : styles.indicatorStyle;

  const hitApiForList = (slug = '', index) => {
    if (isBlank(slug)) return;
    setIsLoading(true);
    dispatch(
      fetchList({ slug }, 0, (success, data) => {
        if (!success || isBlank(data) || isBlank(data.objects)) {
          return;
        }
        itemData[index].listData = data;
        setSelectedTabList(data);
      }),
    );
  };

  const fireEvent = (item, index) => {
    const {
      id = '',
      name = '',
      display: itemDisplay = '',
      list_slug: slug = '',
    } = item;
    AnalyticsUtilty.fireItemClickEvent(
      previousScreen,
      id,
      itemDisplay,
      name,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
    );
  };

  const onTabPress = (item = {}, index) => {
    const { last_level_tab: lastLevelTab = false, list_slug: listSlug = '' } =
      item;
    setSelectedIndex(index);
    setSelectedTabData(tabsData[index]);
    setSelectedTabList({});
    fireEvent(item, index);
    if (lastLevelTab) {
      if (isPresent(itemData[index]?.listData)) {
        setSelectedTabList(itemData[index]?.listData);
        return;
      }
      hitApiForList(listSlug, index);
    }
  };

  const keyExtractor = (item, index) => `${index}_${item.id}tab`;
  const renderItem = ({ item, index }) => {
    const TabCardComponent = getTabGroupCardComponent(item.display);
    if (TabCardComponent === undefined) return null;
    return (
      <TabCardComponent
        selectedIndex={selectedIndex}
        index={index}
        tabStyle={tabStyle}
        indicatorStyle={indicatorStyle}
        item={item}
        onTabPress={onTabPress}
      />
    );
  };
  return (
    <View style={styles.container}>
      <FlatList
        horizontal
        data={itemData}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        style={flatListStyle}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
      />
    </View>
  );
};
const TabGroup = (props) => {
  const {
    itemData = {},
    index = 0,
    linearTab,
    listData = {},
    previousScreen = '',
    setSelectedTabList = () => {},
    defaultList = {},
    display = '',
    setIsLoading,
  } = props;
  const [selectedTabData, setSelectedTabData] = useState({});
  const [tabsData, setTabsData] = useState(itemData);
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <>
      <RenderTab
        itemData={itemData}
        index={index}
        linearTab={linearTab}
        selectedTabData={selectedTabData}
        setSelectedTabData={setSelectedTabData}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        setSelectedTabList={setSelectedTabList}
        defaultList={defaultList}
        tabsData={tabsData}
        setTabsData={setTabsData}
        display={display}
        listData={listData}
        previousScreen={previousScreen}
        setIsLoading={setIsLoading}
      />
      <RenderSubTab
        selectedTabData={selectedTabData}
        setSelectedTabData={setSelectedTabData}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        listData={listData}
        previousScreen={previousScreen}
        setSelectedTabList={setSelectedTabList}
        defaultList={defaultList}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default TabGroup;
