import React, { Component } from 'react';
import { Text, View, BackHandler } from 'react-native';
import _ from 'lodash';
import { WebView } from 'react-native-webview';
import { CartStyles } from './styles';
import StaticNavigationHeader from '../shared/StaticNavigationHeader';
import Utility from '../../utils/Utility';
import { AnalyticsManager } from '../../analytics';

export class PaytmWebView extends Component {
  constructor(props) {
    super(props);
    const { route } = props;
    this.paytmPayload = route.params?.paytmPayload;
    this.createOrderAndPayWithPaytm = route.params?.createOrderAndPayWithPaytm;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.viewPaytmWebViewScreen();
    }, 1000);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  handleMessage = (wvMessage) => {
    const { data, title, event, params } = wvMessage.nativeEvent;
    const { navigation } = this.props;
    try {
      const { status } = Utility.jsonParser(
        decodeURIComponent(decodeURIComponent(data)),
      );

      if (status === 'TXN_SUCCESS') {
        navigation.navigate('Payments');
        this.createOrderAndPayWithPaytm();
        AnalyticsManager.logEvent('paytm_add_money_complete');
      } else if (status === 'TXN_FAILURE') {
        navigation.replace('OrderStatus', {
          orderResponse: {
            status: 'failure',
          },
        });
        AnalyticsManager.logEvent('paytm_add_money_fail');
      }
    } catch (er) {
      console.log(er, 'error');
    }
  };

  viewPaytmWebViewScreen = () => {
    const { action, paytm_params } = this.paytmPayload;

    AnalyticsManager.logEvent('paytm_add_money_initiate');

    const inputsStr = _.reduce(
      paytm_params,
      (result, value, name) => {
        return `${result} <input type='hidden' name='${name}' value='${value}'>`;
      },
      '',
    );

    const formString = `<form method='post' action='${action}' name='paytm'><div><center><p>Please do not refresh this page...</p></center>${inputsStr}</div></form>`;

    const jsString = `setTimeout(() => {
      document
      .getElementsByTagName('p')[0]
      .insertAdjacentHTML(
        'afterend',
        "${formString}"
      );
      document.getElementsByTagName('form')[0].submit()
      true;
    }, 100)`;

    this.webview.injectJavaScript(jsString);
  };

  render() {
    const injectedJavaScript = `setTimeout(() => {
        const meta = document.createElement('meta');
        meta.setAttribute(
          'content',
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
        );
        meta.setAttribute('name', 'viewport');
        document.getElementsByTagName('head')[0].appendChild(meta);
        window.ReactNativeWebView.postMessage(document.title);
        true;
      }, 100)`;

    return (
      <>
        <StaticNavigationHeader title="Paytm" />
        <WebView
          style={CartStyles.webViewHeight}
          ref={(ref) => {
            this.webview = ref;
          }}
          source={{
            html: `
          <div>
           <p></p>  
           </div>`,
          }}
          injectedJavaScript={injectedJavaScript} // to remove zoom and add header title
          onMessage={this.handleMessage}
          javaScriptEnabled
          onError={(error) => console.log('Error is co', error)}
        />
      </>
    );
  }
}

export default PaytmWebView;
