import Config from '../libraries/ReactNativeConfig';

const colors = {
  primaryActionBackgroundColor: Config.PRIMARY_ACTION_COLOR_BACKGROUND_COLOR,
  secondActionBorderColor: Config.SECONDARY_ACTION_BORDER_COLOR,
  background: '#F4f6f8',
  darkGreyBackground: '#F5F6F8',
  darkBackground: '#121212',
  white: '#FFFFFF',
  black: '#000000',
  translucentgray: '#1a1a1acc',
  alternateBackground: '#000000',
  green: '#01b460',
  border: '#eaeaea',
  borderLight: '#f5f5f5',
  foxyPink: Config.WISHLIST_HEADER_TINT || '#fd5c63',
  foxyBlack: '#173143',
  silverWicked: '#808080',
  cardBackGroundColor: 'rgba(151, 155, 170, 0.2)',
  silver: '#9d9d9d',
  silverLit: '#bcbcbc',
  silverDark: '#313131',
  codGray: '#0F0F0F',
  dullnessGray: '#171717',
  enabled: Config.PRIMARY_ACTION_COLOR_BACKGROUND_COLOR,
  enabledPress: Config.PRIMARY_ACTION_COLOR_BACKGROUND_COLOR,
  disabled: '#9d9d9d',
  dodgerBlue: '#147BFF',
  dodgerBluePressed: '#1467ff',
  havelockBlue: '#4990e2',
  powderBlue: '#ecf4fd',
  powderPink: '#FFF8F8',
  maximumTrackTintColor: '#ffffff5b',
  minimumTrackTintColor: '#ffffff',
  contentPageSeparatorColor: '#474747',
  inactiveDot: 'rgba(0,0,0,.3)',
  borderColor: '#efefef',
  foxyRed: '#da4600',
  notificationCountPink: '#EA6768',
  foxyGreen: '#009688',
  foxyBlue: '#4990e2',
  lightestBlue: '#F1F8FF',
  borderDark: '#e4e4e4',
  transparent: 'transparent',
  translucent: 'rgba(0,0,0,0.3)',
  darkTranslucent: 'rgba(0,0,0,0.5)',
  extraDarkTranslucent: 'rgba(0,0,0,0.8)',
  ultraSilverLite: '#808080',
  subtitle: '#979BAA',
  badgeBackground: '#F9F3F0',
  darkBorder: '#979baa',
  youtube_red: '#e52d28',
  light_red: '#ffe6e2',
  light_orange: '#ec6752',
  foxyYellow: '#f59d0f',
  cyan: '#e7f2ff',
  lightPink: '#ff858b',
  darkPink: '#f73942',
  instagram: '#4990E2',
  youtube: '#FF0000',
  ultraLightFoxyPink: '#fcdee2',
  ultraLightFoxyGreen: '#e7f2e7',
  ultraDarkBlue: '#253F51',
  ultraLightGray: '#F4F6F8',
  lightBackgroundGreen: '#CCF0DF',
  linearGradientGreenFirst: Config.LINEAR_GRADIENT_PRIMARY,
  linearGradientGreenSecond: Config.LINEAR_GRADIENT_SECONDARY,
  ultraLightGreen: '#E5F7EF',
  ultraLightGrey: '#E9EAEC',
  darkGrey: '#818D98',
  lightGrey: '#EAEBEE',
  darkGreen: Config.PRODUCT_CARD_SPECIAL_PRICE_COLOR || '#03B460',
  grey: '#C4C4C4',
  backgroundGray: '#E5E5E5',
  white_background: '#ffefef',
  gold: '#FB9927',
  silverBlue: '#90BAC4',
  bronze: '#BA5D4F',
  darkPurpleText: '#2B478B',
  transparentOpaque: 'rgba(255,255,255,0.001)',
  crossBackground: '#E4E4E4',
  lightestGrey: '#F2F4F5',
  selfieCardBackground: '#FFF7EE',
  couponCodePill: {
    border: {
      active: '#FFD5D7',
      inActive: '#979BAA',
    },
    background: {
      active: '#FBF8F8',
      inActive: '#DDDDDD',
    },
  },
  cta: {
    lightBlue: '#4285F4',
  },
  prompt: {
    lightBlue: '#4285F4',
    ultraLightBlue: '#ECF3FE',
    borderGreen: '#C4E9DA',
    borderBlue: '#D0DFF7',
  },
  addToCart: {
    disable: '#2d343666',
    whileAddingToCart: Config.WISHLIST_HEADER_TINT || '#fd5c6366',
  },
  subText: '#B1B1B1',
  pink: {
    light: '#F9ECED',
    ultraLight: '#FFF1FB',
  },

  button: {
    disabled: '#A8A8A8',
  },

  shadowGrey: '#555',

  shimmer: {
    darkGrey: '#f7F7f7',
    lightGrey: '#eeeeee',
    extraLightGrey: '#ddddddff',
  },

  foxyHeader: Config.WISHLIST_HEADER_TINT,

  contactsInitialsColor: [
    { bgColor: '#FFF0F5', textColor: '#ED9EB3' },
    { bgColor: '#E6FAFA', textColor: '#A2D5D0' },
    { bgColor: '#FFEBE6', textColor: '#F3B0A5' },
    { bgColor: '#FFFCDB', textColor: '#E9D4A8' },
    { bgColor: '#FFE4F1', textColor: '#F1A2BA' },
    { bgColor: '#E6E6FA', textColor: '#B6B4E2' },
    { bgColor: '#E6E0FA', textColor: '#BAB8EF' },
    { bgColor: '#FFF8DC', textColor: '#EADCC3' },
    // { bgColor: '#FAFAD2', textColor: '#DBD7B1' },
    { bgColor: '#FAEBE6', textColor: '#E5C5BB' },
    // { bgColor: '#FFF5EE', textColor: '#F3C2BF' },
    { bgColor: '#F0F8FF', textColor: '#B4C7DD' },
    // { bgColor: '#FFFACD', textColor: '#EAE3B7' },
    { bgColor: '#FAF0E6', textColor: '#F3CCBD' },
    // { bgColor: '#F5F5F5', textColor: '#D2D3D9' },
    { bgColor: '#FAFFE6', textColor: '#CDDFA4' },
    // { bgColor: '#FFF0E6', textColor: '#F2B5CB' },
    { bgColor: '#F5F5FF', textColor: '#BEBDF4' },
    { bgColor: '#FFF5F0', textColor: '#F3BAAA' },
    { bgColor: '#FFEBF5', textColor: '#F5BBF6' },
    { bgColor: '#FAFFF0', textColor: '#CCF0A2' },
    { bgColor: '#FFF8E6', textColor: '#F2D7B8' },
    // { bgColor: '#FAF0E1', textColor: '#F3C3B6' },
    { bgColor: '#F0FFF0', textColor: '#A8E6BF' },
  ],
  shimmerColorArray: ['#eeeeee', '#ddddddff', '#eeeeee'],
  alternateShimmerColorArray: ['#fafafa', '#eeeeee', '#fafafa'],
  slimCouponBackgroundColor: '#E5F8EF',
  slimCouponAlternateBackgroundColor: '#FF5A6014',
  grayscale: { background: '#DADADA40', borderColor: '#aaaaaa' },
  cartPrompt: {
    borderColor: '#01B560',
    background: '#E6F8EF',
    background2: '#CFF1E1',
  },
  orderPage: {
    lost_in_transit_status: '#F5AB5B',
    third_attempt_failed_status: '#F86F03',
  },
  background2: '#E5E5E5',
  charcoalBlack: '#272727',
  influencerSocialMediaAccountBg: '#4A99E933',
  pay_on_foxy_subText: '#818181',
  salon_qr_scan_screen: {
    invalid_upi_bg: '#DF3F46',
  },
  salon_amount_screen: {
    instant_reward_container: 'rgba(1, 180, 96, 0.05)',
    instant_reward_container_error: 'rgba(253, 92, 99, 0.05)',
    instant_reward_text: 'rgba(151, 155, 170, 1)',
    instant_reward_text_error: 'rgba(253, 92, 99, 1)',
    save_card_text: '#606481',
    text_input_placeholder: 'rgba(248, 111, 3, 0.1)',
    text_input_selection: 'rgb(0, 145, 234, 0.5)',
  },
  salon_wheel_gradient: ['rgba(245, 171, 91, 1)', 'rgba(244, 216, 152, 0)'],
  salon_wheel_sub_heading: '#3C5F82',
  salon_wheel_colors: {
    wheel_colors: [
      '#FCA239',
      '#D57880',
      '#F3B8B5',
      '#436989',
      '#BFDBF4',
      '#F4D898',
    ],
    text_colors: [
      '#A1631A',
      '#94242D',
      '#D57880',
      '#DADADA',
      '#436989',
      '#436989',
    ],
  },
  salon_wheel_statusbar: '#F5AE61',
  salon_payment_status: {
    sub_title: '#979797',
    retry_bg: 'rgba(1, 180, 96, 1)',
    order_border: '#EFEFEF',
    contact_text: '#4F83F3',
    counter_color: '#FE8B4B',
    claim_reward_bg: '#E2ECFA',
  },
  pay_on_foxy: {
    bg: 'rgba(245, 171, 91, 0.2)',
  },
  pay_via_foxy_info_card: {
    bg: 'rgba(248, 111, 3, 0.1)',
  },
  get_rewards_modal: {
    vertical_line: '#909090',
    coupon_bg: '#FFF5D2',
    coupon_gradient_colors: ['#1691FF', '#DAC5C8', '#F86B54', '#F5AB5B'],
  },
  salon_coming_soon_detail_tile_bg: 'rgba(255, 126, 126, 0.1)',
  salon_start_scanning_pink: '#FF7E7E',
  scan_black_opacity: 'rgba(0, 0, 0, 0.5)',
  light_pink_opaque: '#FF5A6033',
  sasquatch_socks_pink: '#FE4C83',
  dim_green: 'rgba(1, 180, 96, 0.5)',
  delayOffers: {
    clipperColorLeft: '#FFF1D8',
    clipperColorRight: '#FFF1D8',
  },
  routine_card_pink: '#FA7278',
  dark_translucent: 'rgba(0, 0, 0, 0.05)',
  routine_about_card: {
    light_green: '#EAFFF3',
    light_blue: '#DEEEFC',
    light_yellow: '#FFF6D7',
  },
  divider: '#DADADA',
  routine_card_bg: 'rgba(203, 227, 250, 0.1)',
  routine_green_bg: 'rgba(106, 153, 151, 0.1)',
  routine_light_orange: '#FFDACE',
  darkRed: '#981409',
  darkerRed: '#8E160C',
  routine_invite_banner_gradient: [
    'rgba(255, 206, 198, 1)',
    'rgba(255, 219, 181, 0.47)',
  ],
  ultraDarkGreen: '#6A9997',
  disabled_green: '#469B73',
  referrals: {
    golden: '#FFE27A',
    bright_gold: '#FFD136',
  },
  pointsHistory: {
    inviteImageBackground: 'rgba(1, 180, 96, 0.1)',
    redeemedImageBackground: '#EBF6FF',
  },
  retails: {
    discountCouponCard: '#2C4353',
  },
  routine_details_bg: '#f4f6f8',
  dark_grey: '#434343',
  light_green: '#52CB8C',
  bright_pink: '#FF4F7E',
  light_grey: '#929BB7',
  peach_with_opacity: 'rgba(253, 233, 216, 0.4)',
  light_blue: '#BFDBF4',
  light_yellow: '#FDEAAC',
  mustard: '#FFB111',
  yellow: '#FFF3C8',
  purple: '#8641BC',
  dark_purple: '#6248AA',
  dark_pink: '#FD5C63',
  peach: '#FFD7B2',
  light_peach: '#FFE3C9',
  light_purple: '#8649FB',
  routine_golden: '#FFD95B',
  routine_rank: {
    rank_1: '#FB9927',
    rank_2: '#90BAC4',
    rank_3: '#BA5D4F',
  },
  customerSupport: {
    customerOrderStatus: {
      borderColor: '#DADADA',
    },
  },
  matterhornGrey: '#4E4E4E',
  edgeOfferCard: {
    background: '#576A88',
    offer_blue: '#8EB6FE',
    offer_green: '#6FB996',
    offer_yellow: '#FFB030',
    offer_blue_light: '#F0F6FF',
    offer_blue_dark: '#C8DEFD',
  },
  take_selfie_yellow: '#FFE797',
  take_selfie_red: '#B64634',
  light_grey: '#D9D9D9',
  edge_logo_grey: '#525252',
  edge_upgrade: {
    blue: '#5383EC',
    yellow: '#FEF7EF',
    bright_yelllow: '#FFE384',
    bright_yelllow_border: '#FFA62F',
  },

  foxyShadowButtonUnderlayColor: '#295675',
  foxyShadowStrokeButtonUnderlay: '#ebebeb',
};

export const colorCode = {
  ratings: {
    five: '#018346',
    four: '#01B460',
    three: '#05DE78',
    two: '#F3C52D',
    one: '#E4454C',
    grey: '#979BAA',
  },
};

export const Theme = {
  light: {
    backgroundColor: colors.white,
    textColor: colors.foxyBlack,
    errorColor: colors.foxyRed,
    verifiedColor: colors.foxyGreen,
    borderColor: colors.border,
    highlight: colors.foxyBlue,
    lightBackgroundColor: colors.background,
    subTextColor: colors.foxyBlack,
  },
  dark: {
    backgroundColor: colors.black,
    darkBackgroundColor: colors.darkBackground,
    textColor: colors.white,
    subTextColor: colors.subText,
  },
  camera: {
    backgroundColor: colors.black,
    overlay: {
      topSection: {
        background: colors.transparent,
        iconBackground: colors.transparent,
        iconTintColor: colors.white,
        borderColor: colors.white,
        text: colors.white,
        zoomIndicatorBackground: colors.translucent,
      },
      middleSection: {
        text: colors.white,
        iconsBackground: colors.transparent,
        iconTintColor: colors.white,
        progressTintColor: colors.white,
        trackTintColor: colors.translucent,
        borderColor: colors.white,
        miniMediaBackground: colors.transparent,
      },
      bottomSection: {
        background: colors.translucent,
        iconsBackground: colors.transparent,
        iconTintColor: colors.black,
        text: colors.white,
      },
      featureBox: {
        background: colors.darkTranslucent,
        iconBackground: colors.transparent,
        activeIconTintColor: colors.white,
        inactiveIconTintColor: colors.silverLit,
      },
    },
  },

  preview: {
    background: colors.black,
    textColor: colors.white,
    imagePreview: {
      iconBackground: colors.transparent,
      iconTintColor: colors.white,
      textColor: colors.white,
    },
    topSection: {
      background: colors.transparent,
      iconBackground: colors.transparent,
      iconTintColor: colors.white,
    },
    bottomSection: {
      background: colors.white,
      iconBackground: colors.transparent,
      iconTintColor: colors.white,
      border: colors.border,
      text: colors.black,
      placeholder: colors.silver,
    },
  },

  addProducts: {
    background: colors.black,
    topSection: {
      iconBackground: colors.transparent,
      iconTintColor: colors.white,
      textColor: colors.white,
    },
    bottomSection: {
      iconBackground: colors.transparent,
      iconTintColor: colors.white,
      textColor: colors.white,
    },
    searchProductContainer: {
      textColor: colors.white,
      iconBackground: colors.transparent,
      iconTintColor: colors.white,
    },
  },

  uploadPost: {
    background: colors.white,
    textColor: colors.black,
    iconBackground: colors.transparent,
    iconTintColor: colors.black,
  },

  gallery: {
    // backgroundColor: colors.background,
    backgroundColor: colors.white,
    // headerColor: colors.white,
    // borderColor: colors.border,
    // barColor: colors.silver,
    // mediaItemBackgroundColor: colors.border,
    // swipeDownHiddenBarColor: colors.transparent,
    // iconBackground: colors.transparent,
    // iconTintColor: colors.foxyBlack,
    // textColor: colors.white,
    // tabBackgroundColor: colors.white,
    defaultGalleryItemColor: colors.border,
  },

  influencerHaloGradient: [
    '#BFDBF4',
    '#BFDBF4',
    '#DAC5C8',
    '#F86B54',
    '#F5AB5B',
  ],

  foxyGreenButton: {
    firstColor: '#51CB8C',
    secondColor: '#03B460',
  },
  zoomOverlay: '#00000085',
  lightGreenBackGround: 'E6FDEA',
};

export default colors;
