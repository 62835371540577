import { takeEvery, select, call } from 'redux-saga/effects';
import { POST_VIDEO_VIEW } from '../actions/LoginActions';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';

import { getApiHeaders } from './GeneratorUtil';

const fetchPostVideoViews = (authToken, guestAuthToken, headers, videoId) => {
  const postData = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      video_view: {
        video_id: videoId,
      },
    }),
  };
  const response = fetch(URL.POST_VIDEO_VIEW, postData);
  return response;
};

function* postView(action) {
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const guestAuthToken = yield select(
      (state) => state.UserAccountInfo.guestProfile.guestAuthToken,
    );
    const { id } = action;
    let headers = yield getApiHeaders();
    const response = yield call(
      fetchPostVideoViews,
      authToken,
      guestAuthToken,
      headers,
      id,
    );
    if (response.status === 200) {
      console.log('Sucess', response);
    } else {
      console.log(
        `Error in posting Selected Interests: ${JSON.stringify(response)}`,
      );
    }
  } catch (error) {
    console.log(`Error in posting Selected Interests ${error}`);
  }
}

export default function* watchPostVideoViewSaga() {
  yield takeEvery(POST_VIDEO_VIEW, postView);
}
