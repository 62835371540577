import { View, StyleSheet, FlatList } from 'react-native';
import React from 'react';
import { useSelector } from 'react-redux';
import colors from '../../theme/Colors';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import SearchSuggest from './SearchSuggest';

export default function SearchSuggestionContainer({
  suggestions,
  showSearchSuggest,
  searchQuery,
  navigation,
  hideSearchSuggest,
}) {
  const { topSearches = [] } = useSelector(state => state.searchDetails);
  if (!showSearchSuggest) return null;
  const options = isBlank(suggestions) ? topSearches : suggestions;
  return (
    <View style={styles.container}>
      <FlatList
        data={options.slice(0, 10)}
        renderItem={({ item }) => <SearchSuggest item={item} navigation={navigation} hideSearchSuggest={hideSearchSuggest} />}
        ListHeaderComponent={
          isPresent(searchQuery) && (searchQuery.length > 2) ? (
            <SearchSuggest item={{ name: `Search ${searchQuery}` }} navigation={navigation} hideSearchSuggest={hideSearchSuggest} searchQuery={searchQuery} />
          ) : null}
        initialNumToRender={10}
        style={styles.list}
        keyExtractor={item => item.name || item.text}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  list: {
    width: 360,
    height: '100%',
    backgroundColor: colors.white,
  },
  container: {
    position: 'absolute',
    top: 32,
  },
});
