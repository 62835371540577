import React from 'react';
import { Text, View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { StarRating } from '../../lib/StarRating';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';
import { getScreenWidth } from '../../utils/LayoutUtility';

const AppReviewModal = ({
  isVisible,
  onCancel,
  onFinishRating = () => {},
  onSubmitRating,
}) => {
  const modalTitle = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.review_modal_title,
  );
  return (
    <Modal isVisible={isVisible}>
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.crossButtonContainer}
          onPress={onCancel}
        >
          <Image style={styles.crossButton} source={images.popupClose} />
        </TouchableOpacity>
        <View style={styles.contentWrapper}>
          <Text style={styles.titleText}>{`${modalTitle}`}</Text>

          <View style={styles.ratingContainer}>
            <StarRating
              count={5}
              defaultRating={0}
              size={40}
              onFinishRating={onFinishRating}
            />
          </View>
        </View>

        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={onSubmitRating}
        >
          <Text style={styles.buttonText}>Submit</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: 270,
    borderRadius: 4,
    padding: 12,
    flexDirection: 'column',
    alignItems: 'center',
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  contentWrapper: {
    marginTop: 32,
    alignItems: 'center',
  },
  titleText: {
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    fontSize: size.h1,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  subText: {
    fontFamily: 'Roboto-Regular',
    color: colors.subText,
    fontSize: size.h3,
    marginTop: 12,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },

  crossButtonContainer: {
    position: 'absolute',
    height: 23,
    width: 23,
    right: 12,
    top: 12,
  },

  crossButton: {
    height: 23,
    width: 23,
    position: 'absolute',
  },

  ratingContainer: {
    marginTop: 32,
  },

  buttonContainer: {
    width: Utility.getScreenWidth() - 80,
    backgroundColor: colors.black,
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h2,
    color: colors.white,
  },
});

export default AppReviewModal;
