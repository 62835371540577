// This login card will be shown on Consumer profile in case of user is not logged in
import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, Text, Image, TouchableWithoutFeedback, Animated,
} from 'react-native';
import LottieView from '../../libraries/LottieView';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import animations from '../../theme/Animations';

const styles = StyleSheet.create({
  wrapper : {
    height:400,
    marginTop: 24,
    marginBottom: 15,
  },
  container: {
    height: 351,
    marginTop: 15,
    marginBottom: 15,
    flexDirection: 'column',
    padding: 12,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth() - 36,
    justifyContent: 'center',
    alignItems: 'center',

  },

  title: {
    fontFamily:'Roboto-Bold',
    color:colors.foxyBlack,
    fontSize:size.h1

  }, 


  description: {
 fontFamily:'Roboto-Regular',
    color:colors.subtitle,
    fontSize:size.h3
  }, 
  takeSelfieGifStyle: {
    height: 250,
  },

  button: {
    width: 192,
    height: 36,
    backgroundColor: colors.black,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },

  loginButtonText: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
});



const FacialAnalysisCard = (props) => {
  const { onPressTakeSelfieButton } = props;
  return (
    <View style={styles.wrapper}>

    <Text style={styles.title}>Facial Analysis</Text>
    <Text style={styles.description}>Take a selfie to get personalised feed of content </Text>
      <View style={styles.container}>
        <LottieView
          source={animations.takeSelfie}
          style={styles.takeSelfieGifStyle}
          autoPlay
          loop
        />

        <TouchableWithoutFeedback onPress={onPressTakeSelfieButton}>
          <View style={styles.button}>
            <Text style={styles.loginButtonText}>Take a Smart Selfie</Text>
          </View>

        </TouchableWithoutFeedback>
      </View>
    </View>
  );
};


export default FacialAnalysisCard;
