import {
  race,
  takeEvery,
  call,
  select,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_USER_INSTAGRAM_PROFILE,
  verifyHandleFailure,
} from '../actions/LoginActions';
import {
  GET_INSTAGRAM_PROFILE,
  INSTAGRAM_PROFILE_VERIFY,
  storeInstagramFollowersCount,
  GET_INSTAGRAM_POST,
  SEND_INSTAGRAMDATA_FOR_ACCOUNTVERIFICATION,
  UPDATE_ARTIST_INSTAGRAM_PROFILE,
} from '../actions/ActionTypes';

import Utility from '../utils/Utility';
import { URL } from '../config/Constants';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

// Get instagram profile from foxy server (webview flow)
const fetchInstaProfile = (
  countryCode,
  phoneNumber,
  authToken,
  instagramCode,
  headers,
) => {
  const response = fetch(URL.GET_INSTAGRAM_PROFILE, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      auth_token: `${authToken}`,
      user: {
        country_code: `${countryCode}`,
        phone_number: `${phoneNumber}`,
      },
      instagram_profile: {
        code: `${instagramCode}`,
      },
    }),
  });
  return response;
};

// Get profile from public url  : prefix ___a?
const getProfile = (handler) => {
  const url = `${URL.INSTAGRAM_PUBLIC_API + handler}/?__a=1`;

  const response = fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return response;
};

function* getInstagramProfile(action) {
  try {
    const mobileNumber = yield select(
      (state) => state.UserAccountInfo.mobileNumber,
    );
    const countryCode = yield select(
      (state) => state.UserAccountInfo.callingCode,
    );
    let authToken = yield select((state) => state.UserAccountInfo.authToken);
    if (Utility.isBlank(authToken)) {
      authToken = '';
    }
    const headers = yield getApiHeaders();
    const response = yield call(
      fetchInstaProfile,
      countryCode,
      mobileNumber,
      authToken,
      action.code,
      headers,
    );
    const json = yield convertJsonFromResponse(response);
    if (response.status === 200) {
      if (Utility.isBlank(json)) {
        action.callback('Failure');
        return;
      }
      action.callback(json);
    } else {
      action.callback(null);
      yield put(verifyHandleFailure(json.errors[0]));
    }
  } catch (error) {
    action.callback('Failure');
  }
}
function* sendInstagramFollowersCountToServer(instagramUserObject) {
  try {
    // save instagram follower count to redux

    const object = {
      insta_user_id: instagramUserObject.id,
      insta_user_name: instagramUserObject.username,
      insta_full_name: instagramUserObject.full_name,
      insta_profile_picture_url: instagramUserObject.profile_pic_url_hd,
      follower_count: instagramUserObject.edge_followed_by.count,
      post_count: instagramUserObject.edge_owner_to_timeline_media.count,
    };
    yield put(storeInstagramFollowersCount(object));
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    // TODO: Need to ask chandranshu what is required in post_count

    const url = `${URL.SAVE_INSTAGRAM_FOLLOWERS_COUNT}${instagramUserObject.username}`;
    const postObject = {
      method: 'PATCH',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        instagram_profile: object,
      }),
    };

    const response = yield fetch(url, postObject);

    if (response.status >= 200 && response.status < 300) {
      // TODO:Successfull
    }
  } catch (error) {
    console.log('Error while sending instagram follower count to server');
  }
}

function* getInstaProfileDetails(action) {
  const { handler, callback } = action;
  try {
    const result = yield call(getProfile, handler);
    if (result.status === 200) {
      const json = yield convertJsonFromResponse(result);
      if (Utility.isBlank(json)) {
        return;
      }
      if (callback) {
        const isAvailableOnFoxyServer = yield call(
          instagramProfileCheck,
          json.graphql.user.username,
        );

        console.log('Level 1 status ', isAvailableOnFoxyServer);
        if (isAvailableOnFoxyServer.status) {
          callback({
            status: true,
            isFoxyError: false,
            message: '',
            user: json.graphql.user,
            statusCode: 200,
          });
        } else {
          callback({
            status: false,
            isFoxyError: true,
            message: isAvailableOnFoxyServer.error,
            user: json.graphql.user,
            statusCode: isAvailableOnFoxyServer.code,
          });
        }
      }

      // if (callback) {
      //   callback(json.graphql.user);
      // }
      // // Send Instagram Followers count to backend

      if (!callback) {
        yield call(sendInstagramFollowersCountToServer, json.graphql.user);
      }
    } else if (callback) {
      callback({
        status: false,
        isFoxyError: false,
        message: 'Instagram account does not exist',
        user: {},
        statusCode: 0,
      });
    }
  } catch (error) {
    if (callback) {
      callback({
        status: false,
        isFoxyError: false,
        message: 'Account does not exist',
        user: {},
      });
    }
  }
}

function* instagramProfileCheck(handle) {
  let result = false;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  const phoneNumber = yield select(
    (state) => state.UserAccountInfo.mobileNumber,
  );
  const countryCode = yield select(
    (state) => state.UserAccountInfo.callingCode,
  );

  let url = URL.INSTAGRAM_PROFILE_CHECK;
  if (Utility.isBlank(authToken)) {
    url = `${URL.INSTAGRAM_PROFILE_CHECK}${handle}&user[phone_number]=${phoneNumber}&user[country_code]=${countryCode}`;
  } else {
    url = `${URL.INSTAGRAM_PROFILE_CHECK}${handle}`;
  }
  if (handle.length > 2) {
    try {
      const headers = {
        method: 'GET',
        headers: yield getApiHeaders(),
      };

      const response = yield fetch(url, headers);
      console.log('level 1 code', response.status);
      if (response.status >= 200 && response.status < 300) {
        result = { status: true, code: 200, error: '' };
      } else if (response.status === 401) {
        const json = yield convertJsonFromResponse(response);
        if (Utility.isBlank(json)) {
          return;
        }
        result = {
          status: false,
          code: response.status,
          error: json.errors[0],
        };
      } else if (response.status === 422) {
        result = { status: false, code: 422, error: 'Handle already in use' };
      }
    } catch (error) {
      console.log('Error occured', error);
      result = false;
    }
  }
  return result;
}

function* getVerificationPost(action) {
  const { callback } = action;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(URL.INSTAGRAM_VERIFICATION_POST, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* sendInstagramDataForAccountVerification(action) {
  const { instagramProfile, callback } = action;

  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const payload = {
      auth_token: authToken,
      artist: {
        insta_profile: instagramProfile,
      },
    };

    const response = yield fetch(URL.INSTAGRAM_PROFILE, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payload),
    });
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {}
}

function* updateArtistInstagramProfile(action) {
  const { handle, callback } = action;
  try {
    const response = yield call(getProfile, handle);
    if (response.status === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(response)) {
        callback(false);
        return;
      }
      yield call(sendInstagramFollowersCountToServer, json.graphql.user);
      callback(json.graphql.user);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export default function* watchInstagramProfileSaga() {
  yield takeEvery(GET_USER_INSTAGRAM_PROFILE, getInstagramProfile);
  yield takeLatest(GET_INSTAGRAM_PROFILE, getInstaProfileDetails);
  yield takeLatest(INSTAGRAM_PROFILE_VERIFY, instagramProfileCheck);
  yield takeLatest(GET_INSTAGRAM_POST, getVerificationPost);
  yield takeLatest(
    SEND_INSTAGRAMDATA_FOR_ACCOUNTVERIFICATION,
    sendInstagramDataForAccountVerification,
  );

  yield takeLatest(
    UPDATE_ARTIST_INSTAGRAM_PROFILE,
    updateArtistInstagramProfile,
  );
}
