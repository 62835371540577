import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Linking, Text } from 'react-native';
import CardView from '../../libraries/ReactNativeCardView';
import { RGBA } from '../../libraries/ReactNativeColorMatrixImageFilters';
import ScaleAnimate from '../shared/ScaleAnimate';

import { COLLECTION } from '../../config/LayoutConstants/CollectionConfig';
import CollectionCardStyle from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { APP_DOWNLOAD_LINK, MORE_ITEMS } from '../../config/Constants';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isWeb } from '../../utils/BooleanUtility';

class PersonalisedCollectionCard extends Component {
  static cardLayoutStyle = {
    vertical: {
      cardElevation: 0,
      cardMaxElevation: 0,
      cornerRadius: 4,
    },
  };

  constructor(props) {
    super(props);
    this.item = this.props.itemData.objects;
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });

    this.cardImage = '';
    this.filteredImageApplied = false;
  }

  navigate = () => {
    if (isWeb()) {
      Linking.openURL(APP_DOWNLOAD_LINK);
      return;
    }
    const {
      navigation,
      itemData,
      index,
      id,
      layout,
      listName,
      listIndex,
      listData: { slug: listSlug = '' } = {},
      previousScreen,
      listData,
      listContent,
    } = this.props;
    if (Utility.isPresent(itemData)) {
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        itemData.id,
        itemData.content,
        itemData.name,
        index,
        id,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        itemData.slug,
        listSlug,
      );
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: MORE_ITEMS,
      params: {
        id: itemData.id,
        displayCount: itemData.objects.length,
        heading: itemData.name,
        item: itemData,
        navigation,
        title: itemData.name,
        listData,
        fromPersonalisedCard: true,
        filteredImage: this.cardImage,
        filteredImageApplied: this.filteredImageApplied,
        slug: itemData.slug,
      },
    });
  };

  getStyles = ({ layout, size = 'defaultSize' }) => {
    let layoutSize = size;
    if (Utility.isBlank(layoutSize)) {
      layoutSize = 'defaultSize';
    }
    const { viewwidth, viewheight, imagewidth, imageheight } =
      COLLECTION.CARD[layout][layoutSize];
    const cardDimensions = {
      height: viewheight,
      width: viewwidth,
    };
    let imageStyle = {
      height: imageheight,
      width: imagewidth,
      borderRadius: 4,
      backgroundColor: colors.white,
    };
    let cardStyle = [CollectionCardStyle.cardContainer, cardDimensions];
    if (layout === 'list') {
      cardStyle = [CollectionCardStyle.cardContainerList, cardDimensions];
      imageStyle = { ...imageStyle, marginRight: 10, marginLeft: 10 };
    }

    return {
      cardStyle,
      imageStyle,
    };
  };

  imageContainer = (props) => {
    const { backgroundColor, imageStyle, CardName } = props;
    return (
      <FastImageView
        source={this.cardImage}
        style={[imageStyle, { backgroundColor }]}
      >
        <Text style={CollectionCardStyle.personalisedCollectionCartText}>
          {CardName}
        </Text>
      </FastImageView>
    );
  };

  imageContainerWithRGA = (props) => {
    const { r, g, b, backgroundColor, imageStyle, CardName } = props;
    return (
      <RGBA red={r} green={g} blue={b} alpha={0.5}>
        <this.imageContainer
          backgroundColor={backgroundColor}
          imageStyle={imageStyle}
          CardName={CardName}
        />
      </RGBA>
    );
  };

  render() {
    const {
      orientation,
      itemData: collectionData,
      layout,
      index,
      andyWarholImages,
      imageUrl,
    } = this.props;
    const { itemData } = this.props;
    const { cardStyle, imageStyle } = this.getStyles(this.props);
    const LayoutComponent = CardView;
    if (collectionData === undefined) {
      return null;
    }
    if (
      Utility.isPresent(andyWarholImages) &&
      Utility.isPresent(andyWarholImages[index])
    ) {
      this.cardImage = andyWarholImages[index];
      this.filteredImageApplied = true;
    } else if (Utility.isPresent(imageUrl)) {
      this.cardImage = Utility.getMinifiedImage(imageUrl);
      this.filteredImageApplied = false;
    } else {
      this.cardImage = Utility.getMinifiedImage(
        collectionData.image_url,
        imageStyle.width,
        imageStyle.height,
      );
    }

    let backgroundColor = colors.white;
    if (Utility.isBlank(this.cardImage) && layout !== 'list') {
      backgroundColor = 'black';
    }
    const CardName = collectionData?.name?.toUpperCase().split('-').join('\n');
    const { r, g, b } = Utility.hexToRgb(itemData.overlay_color);
    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <LayoutComponent
          {...PersonalisedCollectionCard.cardLayoutStyle[orientation]}
          style={[cardStyle, { backgroundColor }]}
        >
          {this.filteredImageApplied && (
            <this.imageContainer
              backgroundColor={backgroundColor}
              imageStyle={imageStyle}
              CardName={CardName}
            />
          )}
          {!this.filteredImageApplied && (
            <this.imageContainerWithRGA
              r={r}
              g={b}
              b={b}
              imageStyle={imageStyle}
              CardName={CardName}
            />
          )}
        </LayoutComponent>
      </ScaleAnimate>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  imageUrl: state.UserAccountInfo.profile.selfie_image_url,
  andyWarholImages: state.UserAccountInfo.profile.andy_warhol_filters || [],
});

export default withNavigation(
  connect(mapStateToProps, null)(PersonalisedCollectionCard),
);
