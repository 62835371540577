import { select, takeEvery } from 'redux-saga/effects';

import { GET_OFFERS_PROMPTS } from '../actions/PromptsActions';
import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

function* getOffersPrompt(action) {
  const { callback } = action;
  const url = `${URL.OFFERS_PROMPTS}`;

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode === 401) {
      callback(false, {});
    } else if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.log(`PromptsSaga.getOffersPrompt: ${JSON.stringify(error)}`);
  }
}

export default function* watchPromptsSaga() {
  yield takeEvery(GET_OFFERS_PROMPTS, getOffersPrompt);
}
