import React, { PureComponent } from 'react';
import {
  View,
  SafeAreaView,
  ActivityIndicator,
  Animated,
  BackHandler,
  Platform,
  Alert,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import { WebView } from 'react-native-webview';
import { styles } from './StoryDetailsStyles';
import colors from '../../theme/Colors';
import StoryHeader from '../contentPageHeader/StoryHeader';
import CrossButtons from '../progressBarView/CrossButton';
import { setStoryViewed } from '../../actions/ActionTypes';
import { removeBlueCircle } from '../../actions/StoriesAction';
import Utility from '../../utils/Utility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { AnalyticsManager } from '../../analytics';

class StoryDetails extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      storyWebUrl: route.params?.item?.stories?.url,
      item: route.params?.item,
      itemData: route.params?.itemData,
      index: 0,
      storyFirstPageId: route.params?.item?.stories?.first_story_page_id,
      showElements: false,
    };

    this.sortStories = route.params?.sortStories;
    this.isAlreadySeen = route.params?.isAlreadySeen;
  }

  componentDidMount() {
    const { itemData, item } = this.state;
    const { navigation } = this.props;

    this.backHandler = null;
    if (Platform.OS === 'android') {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }

    this.unsubscribeFocus = navigation.addListener('focus', () => {
      this.onFocus();
    });

    this.unsubscribeBlur = navigation.addListener('blur', () => {
      this.onblur();
    });

    AnalyticsManager.setCurrentScreen('stories');

    this.setState({
      index: itemData.indexOf(item),
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { itemData, index } = this.state;

    if (Utility.isBlank(itemData)) {
      return null;
    }

    if (prevState.index !== index) {
      this.setState({
        item: itemData[index],
        storyWebUrl:
          Utility.isPresent(itemData[index]) &&
          Utility.isPresent(itemData[index].stories) &&
          Utility.isPresent(itemData[index].stories.url) &&
          itemData[index].stories.url,
      });
    }
  };

  componentWillUnmount() {
    if (Platform.OS === 'android') {
      this.backHandler.remove();
    }
    this.unsubscribeFocus();
    this.unsubscribeBlur();
  }

  onHardwareBackKeyPress = () => {
    this.onCrossPress();
    return true;
  };

  handleRouteFromLink = (route, slug, path, extra) => {
    const { navigation } = this.props;
    navigation.navigate(route, { slug, extra });
  };

  // For navigation within app if url includes foxy.in
  onNavigationStateChange = (navState) => {
    if (navState.url.includes('foxy.in')) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        navState.url,
        this.handleRouteFromLink,
      );
      return false;
    }
    return true;
  };

  webviewLoader = () => {
    return (
      <View
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'black',
          opacity: 0.5,
        }}
      >
        <ActivityIndicator
          color={colors.white}
          size="large"
          style={styles.webviewLoadContainer}
        />
      </View>
    );
  };

  goBack = () => {
    this.webref.goBack();
  };

  onCrossPress = () => {
    const { navigation } = this.props;
    this.sortStories();
    navigation.popToTop();
  };

  // to pause story if story screen is not in focus
  onblur = () => {
    const runFirst = `
    setTimeout(function() {
      let currentlyActiveStoryPage = document.querySelectorAll('[distance="0"]')[0];
      let mediaEl = currentlyActiveStoryPage.getElementsByTagName('video')[0];
      if (mediaEl != undefined){
        mediaEl.pause();
        Promise.resolve();
      }
    }, 50);
    true; // note: this is required, or you'll sometimes get silent failures
  `;

    this.webref.injectJavaScript(runFirst);
  };

  // to un-pause if story screen is in focus
  onFocus = () => {
    const runFirst = `
    setTimeout(function() {
      let currentlyActiveStoryPage = document.querySelectorAll('[distance="0"]')[0];
      let mediaEl = currentlyActiveStoryPage.getElementsByTagName('video')[0];
      if (mediaEl != undefined){
        mediaEl.play();
        Promise.resolve();
      }
    }, 50);
    true; // note: this is required, or you'll sometimes get silent failures
  `;

    this.webref.injectJavaScript(runFirst);
  };

  // to handle webview events
  handleMessage = (wv) => {
    const { removeBlueCircle, navigation } = this.props;
    const { itemData, index, item } = this.state;
    try {
      const { event, params } = Utility.jsonParser(wv.nativeEvent.data);
      AnalyticsManager.logEvent(event, params);
      if (event === 'last_page_active') {
        removeBlueCircle(item.stories.id);
        if (itemData.length - 1 === index) {
          navigation.goBack();
        } else {
          this.setState(
            {
              index: index + 1,
              showElements: false,
            },
            () => {
              AnalyticsManager.logEvent('publisher_next', {
                publisher_type: item.type,
                id: item.id,
                next_publisher_type: itemData[index + 1].type,
                next_publisher_id: itemData[index + 1].id,
              });
            },
          );
        }
      }
      if (
        event === 'show_frontend_buttons' ||
        event === 'story_media_play_error'
      ) {
        this.setState({
          showElements: true,
        });
      }
    } catch (er) {
      AnalyticsManager.logEvent('story_webview_error');
    }
  };

  onFollow = () => {
    const { navigation } = this.props;
    navigation.navigate('FollowRecommendations', {
      fromStories: true,
    });
  };

  render() {
    const { imageUrl } = this.props;
    const gradientArray = ['#000000', 'transparent'];
    const {
      storyWebUrl,
      item = {},
      storyFirstPageId = '',
      showElements,
      index,
    } = this.state;

    const storiesUrl = !this.isAlreadySeen
      ? storyWebUrl
      : `${storyWebUrl}#page=${storyFirstPageId}`;

    const injectedJavaScript =
      "const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); window.ReactNativeWebView.postMessage(document.title)";
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <View style={{ flex: 1.7 }}>
          <Animated.View
            style={{
              width: '100%',
              height: 100,
              position: 'absolute',
              top: 0,
              zIndex: 10,
            }}
          >
            <LinearGradient
              colors={gradientArray}
              style={{
                width: '100%',
                height: '100%',
                zIndex: 10,
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 0, y: 1 }}
              opacity={0.5}
            />
          </Animated.View>
          <StoryHeader
            style={{
              paddingLeft: 12.0,
              paddingRight: 12.0,
              justifyContent: 'center',
              flexDirection: 'row',
              width: Utility.getScreenWidth(),
            }}
            screenWidth={Utility.getScreenWidth()}
            availableWidth={Utility.getScreenWidth() - 10}
            publisherId={item?.id}
            publisherSlug={item?.slug}
            publisherType={item?.type}
            publisherThumbnail={item?.image_url}
            personalised_story={item?.personalised_story}
            destination={item?.destination_url}
            displayName={item?.display_name}
            publisherName={item?.name}
            publish_at={item?.stories?.last_modified_at}
            onFollowTapped={this.onFollow}
            headerContainerStyle={{
              zIndex: 60,
              position: 'absolute',
              overflow: 'visible',
              top: 25,
            }}
            goBack={this.goBack}
            sortStories={this.sortStories}
            showElements={showElements}
            imageUrl={imageUrl}
            index={index}
          />
          <WebView
            ref={(r) => (this.webref = r)}
            source={{
              uri: storiesUrl,
            }}
            scrollEnabled={false}
            renderLoading={this.webviewLoader}
            scalesPageToFit
            startInLoadingState
            injectJavaScript={injectedJavaScript} // to remove zoom and add header title
            onMessage={this.handleMessage}
            javaScriptEnabled
            onError={(error) => console.log('Error is co', error)}
            onLoadProgress={this.onLoadEnd}
            cacheEnabled
            onShouldStartLoadWithRequest={this.onNavigationStateChange}
          />
          {showElements && (
            <CrossButtons
              crossButtonWidth={40}
              onCancelTap={this.onCrossPress}
              previousScreen="story_details"
              index={index}
              publisher_id={item?.id}
            />
          )}
        </View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    imageUrl: state.UserAccountInfo.profile.selfie_image_url,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setStoryViewed,
      removeBlueCircle,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoryDetails);
