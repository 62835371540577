import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  ScrollView,
  RefreshControl,
  StatusBar,
  StyleSheet,
  ActivityIndicator,
  SafeAreaView,
} from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPayoff } from '../../actions/PayoffActions';
import { setNotificationModalDisplayTime } from '../../actions/ActionTypes';
import { Greetings } from '../../components/payoff';
import { List } from '../List';
import images from '../../theme/Images';
import { ScreenPlaceholder } from '../../components/shared';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import PayoffPlaceHolder from '../../components/shared/PayoffPlaceHolder';
import PayoffEmptyState from '../feed/PayoffEmptyState';
import SelfieUtilities from '../../utils/SelfieUtilities';
import { AnalyticsManager, EventType } from '../../analytics';
import { CartTray } from '../feed';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import NotificationModal from '../../utils/Notification/NotificationModal';
import { NOTIFICATION_REQUEST_MODAL_TYPE } from '../../config/Constants';

export class Payoff extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  constructor(props) {
    super(props);
    this.state = {
      payoff: {},
      refreshing: false,
      loading: false,
      paginating: false,
      canDisplayNotificationRequestModal: false,
      showNotificationPrompt: false,
    };
    const { route } = props;
    this.forceRefresh = route.params?.forceRefresh;
    this.listRef = null;
    this.page = 0;
    Utility.setPageLoadStart('payoff');
    this.isCurrentScreen = false;
  }

  componentDidMount() {
    const { getPayoff, navigation } = this.props;
    this.setLoading(true);
    if (this.forceRefresh) {
      this.getPayoffPageWithShimmers();
    } else {
      getPayoff('/api/v6/lists/payoff', this.fetchPayoffCallback, this.page);
    }

    this.didFocusListener = navigation.addListener('focus', () => {
      // Update your data
      this.onDidFocus();
      this.isCurrentScreen = true;
    });
    this.blurListener = navigation.addListener('blur', () => {
      this.isCurrentScreen = false;
    });
    this.tabPressListener = navigation
      .getParent()
      .addListener('tabPress', () => {
        if (this.isCurrentScreen) {
          this.scrollPageToTop();
        }
      });
    setTimeout(this.displayNotificationRequestModal, 4000);
  }

  componentDidUpdate(prevProps) {
    const { facialProperties = [], selfieImageUrl } = this.props;
    // const currentAttributes = [...facialProperties];
    // const prevAttributes = [...(prevProps?.facialProperties || [])];
    // if (JSON.stringify(currentAttributes) !== JSON.stringify(prevAttributes)) {
    //   if (
    //     !SelfieUtilities.detectIfSelfieIsReviewed(
    //       prevProps?.facialProperties,
    //     ) &&
    //     SelfieUtilities.detectIfSelfieIsReviewed(facialProperties)
    //   ) {
    //     this.getPayoffPageWithShimmers();
    //   }
    // }

    if (
      Utility.isPresent(prevProps?.selfieImageUrl) &&
      Utility.isBlank(selfieImageUrl)
    ) {
      Utility.setPageLoadStart('payoff');
      this.onRefresh();
      return;
    }

    if (
      !!this.props.route.params?.forceRefresh &&
      !prevProps.route.params?.forceRefresh
    ) {
      this.onRefresh();
    }
  }

  componentWillUnmount() {
    this.didFocusListener();
    this.blurListener();
    this.tabPressListener();
  }

  onDidFocus = () => {
    Utility.setStatusBarColor(colors.ultraLightGray);
    AnalyticsManager.logEvent(EventType.discoveryEvents.TAB_VIEW, {
      tab: 'payoff',
    });
    AnalyticsManager.setCurrentScreen('payoff');
  };

  getPayoffPage = () => {
    const { getPayoff } = this.props;
    getPayoff('/api/v6/lists/payoff', this.fetchPayoffCallback, this.page);
  };

  getPayoffPagePaginated = () => {
    const { getPayoff } = this.props;

    this.setPaginated(true);

    getPayoff(
      '/api/v6/lists/payoff',
      this.fetchPayoffCallbackPaginated,
      ++this.page,
    );
  };

  setPaginated = (paginating) => {
    this.setState({
      paginating,
    });
  };

  getPayoffPageWithShimmers = () => {
    const { getPayoff } = this.props;
    this.setLoading(true);
    getPayoff('/api/v6/lists/payoff', this.fetchPayoffCallback, this.page);
  };

  fetchPayoffCallbackPaginated = (success, response) => {
    this.setLoading(false);

    this.setPaginated(false);
    let payoffObjects = this.state.payoff;

    if (this.page > 0) {
      payoffObjects = {
        ...payoffObjects,
        objects: [...payoffObjects.objects, ...response?.objects],
      };
    }

    if (success && response) {
      this.setState(
        {
          payoff: payoffObjects,
        },
        () => {
          Utility.setPageLoadEnd('payoff', this.page);
          if (response?.objects.length === 0 && this.page > 2) {
            this.page = 0;
            return;
          }
          this.getPayoffPagePaginated();
        },
      );
    }
  };

  fetchPayoffCallback = (success, response) => {
    this.setLoading(false);
    this.setState({
      refreshing: false,
    });
    Utility.setPageLoadEnd('payoff', this.page);
    if (success && response) {
      this.setState(
        {
          payoff: response,
        },
        this.getPayoffPagePaginated,
      );
    }
  };

  onRefresh = () => {
    if (this.state.paginating) {
      return;
    }
    this.setState({
      refreshing: true,
    });
    this.getPayoffPageWithShimmers();
  };

  setLoading = (loading) => {
    this.setState({
      loading,
    });
  };

  scrollPageToTop = () => {
    if (this.listRef) {
      this.listRef.scrollTo({ y: 0, animated: true });
    }
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  showNotificationModal = (show, showNotification) => {
    this.setState({
      canDisplayNotificationRequestModal: show,
      showNotificationPrompt: showNotification,
    });
  };

  displayNotificationRequestModal = () => {
    const {
      lastNotificationModalDisplayTime,
      setNotificationModalDisplayTime,
      initial_app_opened_at,
    } = this.props;
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      'mePage',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          const currentDate = new Date();
          setNotificationModalDisplayTime(currentDate.getTime());
        }
      },
      initial_app_opened_at,
    );
  };

  render() {
    const {
      payoff,
      loading,
      refreshing,
      canDisplayNotificationRequestModal,
      showNotificationPrompt,
    } = this.state;
    const { navigation, facialProperties } = this.props;
    if (!SelfieUtilities.detectIfSelfieIsReviewed(facialProperties)) {
      return <PayoffEmptyState />;
    }
    if (loading) {
      return <PayoffPlaceHolder />;
    }

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: '#f4f6f8' }}>
        <ScrollView
          ref={this.setListRef}
          style={{ flex: 1, backgroundColor: '#f4f6f8' }}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={this.onRefresh}
            />
          }
        >
          <List
            itemData={payoff}
            navigation={navigation}
            previousScreen={SCREEN_CONSTANTS.PAYOFF}
          />
          {this.state.paginating && (
            <>
              <View style={styles.loader}>
                <ActivityIndicator
                  animating
                  color={colors.green}
                  size='large'
                />
                <View style={styles.emptySpace} />
              </View>
            </>
          )}
        </ScrollView>
        <CartTray />
        <NotificationModal
          isNotificationModalVisible={canDisplayNotificationRequestModal}
          showNotificationModal={this.showNotificationModal}
          showNotificationPrompt={showNotificationPrompt}
          type={NOTIFICATION_REQUEST_MODAL_TYPE.ME_PAGE}
          showAnimation
        />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  loader: {
    marginVertical: 8,
  },
  emptySpace: {
    height: 60,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
  },
});

const mapStateToProps = function (state) {
  return {
    isManuallyReviewed: state.facialAnalysis.isManuallyReviewed,
    facialProperties: state.UserAccountInfo?.facialAnalysis?.facialProperties,
    selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
    lastNotificationModalDisplayTime:
      state.UserAccountInfo.lastNotificationModalDisplayTime,
    initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { getPayoff, setNotificationModalDisplayTime },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Payoff),
);

export const PayoffHeaderOptions = {
  headerShadowVisible: false,
};
