import TagManager from 'react-gtm-module';
import { getStoreRef } from '../store/StoreUtility';
import { isWeb } from '../utils/BooleanUtility';
import EventType from './AnalyticsEventType';
import Config from '../libraries/ReactNativeConfig';
import { getSkuId } from '../utils/ProductUtility';

export const pushInitiateCheckoutToGtm = (step, option) => {
  if (!isWeb()) return;
  const cartItems = getStoreRef().getState().bag.cartItems || [];
  const products = cartItems.map(({
    variant,
    product,
    sku_id: skuId,
    quantity,
  }) => {
    const item = variant || product;
    const variantPropertyName = variant ? variant.display_name : '';
    return {
      name: product?.name || '',
      id: skuId,
      price: item ? item.sp : '',
      brand: product ? product.brand.name : '',
      category: product ? product.product_category_name : '',
      variant: variantPropertyName,
      quantity,
    };
  });

  gtmDataLayer({
    event: EventType.GTM.CHECKOUT,
    ecommerce: {
      checkout: {
        actionField: { step, option },
        products,
      },
    },
  });
};

export const gtmDataLayer = (params = {}) => {
  TagManager.dataLayer({ dataLayer: params, dataLayerName: 'webDataLayer' });
};

export const pushProductsAndOffersFromListToGTM = (data = {}) => {
  const { products, offers } = recursiveProductsAndOffersFormer({ data });
  if (products.length) {
    gtmDataLayer({
      ecommerce: {
        currencyCode: Config.ISO_CURRENCY_CODE,
        impressions: products,
      },
    });
  }
  if (offers.length) {
    gtmDataLayer({
      ecommerce: {
        promoView: {
          promotions: offers,
        },
      },
    });
  }
};

const recursiveProductsAndOffersFormer = ({ data = {}, products = [], offers = [] }) => {
  const { content, type, objects = [] } = data;
  if (content === 'product') {
    const lastIndex = products.length;
    const newProducts = objects.map((objectData, index) => ({
      name: objectData.name,
      id: getSkuId(objectData),
      price: objectData.sp,
      brand: objectData.brand?.name,
      category: objectData.product_category_name,
      position: lastIndex + index + 1,
    }));
    products.push(...newProducts);
    return { offers, products };
  }
  if (type === 'feature' || type === 'banner') {
    const { id, title, body, label } = data;
    const lastIndex = offers.length;
    offers.push({ id, name: title || body || label, position: lastIndex + 1 });
    return { products, offers };
  }
  if (content === 'list' || content === 'mixed') {
    return objects.reduce((acc, object) => {
      recursiveProductsAndOffersFormer({ data: object, ...acc });
      return acc;
    }, ({ products, offers }));
  }

  return { products, offers };
};
