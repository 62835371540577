// Dependencies
import React, { PureComponent } from 'react';
import {
  View,
  StatusBar,
  Linking,
  NativeModules,
  BackHandler,
  NativeEventEmitter,
  Text,
  TouchableWithoutFeedback,
  Image,
  ScrollView,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import PropTypes from 'prop-types';
import Orientation from 'react-native-orientation';
// Components
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Toast from 'react-native-easy-toast';
import _, { isNull } from 'lodash';
import { List } from '../List';
import styles from './styles';
import {
  BagIcon,
  TabNavigationRightHeader,
  TabNavigationLeftHeader,
} from '../../components/header';
import {
  HOME_PAGE_SLUG,
  TABBAR_CONSTANT,
  SELFIE_STATE,
  APP_CONSTANTS,
  AnalyticsScreenNames,
  CURRENT_GUEST_STATE,
  REMOTE_CONFIG_KEYS,
  LOGIN_MODAL_MESSAGES,
  APP_LAUNCH_STATES,
} from '../../config/Constants';
import CartTray from './CartTray';
import UploadStatusTray from './UploadStatusTray';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AppTrackingTransparencyManager from '../../utils/AppTrackingTransparency/AppTrackingTransparencyManager';
import {
  addToCart,
  getListDetails,
  getFreeItemForOffer,
  uacSelfieEventTriggered,
  updateUserData,
  getCartPricing,
  updateLastVisitedProductId,
  toggleDynamicListTitleVisibility,
  saveBagModalOpenTimestampAndAppLaunchState,
} from '../../actions/ActionTypes';
import {
  setPhoneNumberModalInfo,
  showImageProgressBar,
  showPopover,
  imageUploadPercentage,
  saveFaceAnalysisData,
  retrySelfieAnalysis,
  restorePreviousAccount,
  setPreviousAssociatedAccount,
} from '../../actions/LoginActions';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import { AnalyticsManager, EventType, EventParameterKey, EventParameterValue } from '../../analytics';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import DynamicLinkUtilities from '../../utils/DynamicLinkUtilities';
import { getMasterAttributesList } from '../../actions/FacialAnalysisActions';
import SelfieReviewModalWrapper from '../../utils/SelfieReviewModalWrapper';
import LoggedOutUserHomePage from '../../components/homepage/LoggedOutUserHomePage';
import OfferTray from './OfferTray';
import RemoteConfig from '../../utils/RemoteConfig';
import { firebase } from '../../libraries/Firebase';
import { getAllReviews } from '../../actions/PayoffActions';
import CacheMedia from '../../utils/CacheMedia';
import Session from '../../utils/Sessions';
import AutoLoginModal from '../../components/modals/AutologinModal';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import AppConfig from '../../config/AppConfig';
import NavigationService from '../../navigator/NavigationService';
import NotificationUtils from '../../utils/NotificationUtils';
import {
  isDesktop,
  isNative,
  isWeb,
  isBlank,
  isPresent,
} from '../../utils/BooleanUtility';
import { getCookie } from '../../utils/webUtility';

class Feed extends PureComponent {
  // static whyDidYouRender = true;

  static navigationOptions = ({ navigation }) => {
    let { params = {} } = navigation.state;
    params = {
      title: Config.APP_NAME,
      headerRight: <TabNavigationRightHeader onSearchTap={() => params.onSearchTap()} />,
    };
    return params;
  };

  constructor(props) {
    super(props);
    this.trace = firebase.perf().newTrace(SCREEN_CONSTANTS.FEED);
    this.trace.start();
    const {} = this.props;
    this.state = {
      isCartVisible: false,
      isCartTrayVisible: false,
      currentlyPlayingIndex: null,
      showHardUpdateDialog: false,
      promoCodeThroughDynamicLink: '',
      isScreenActive: false,
      loggedOutUserState: 'default',
      orderIdThroughDynamicLink: '',
      isUpgradeToPrepaid: false,
      isCartOfferTrayVisible: false,
      freeProductList: {},
      showFreeProductScreen: false,
      showAssociatedAccountModal: false, //TODO: To show restore account modal
      hideOosProduct: false,
    };
    if (Utility.isAndroid()) {
      Utility.setStatusBarWhite();
      this.appDetectEventListener = new NativeEventEmitter(NativeModules.AppDetect);
    }

    this.isCurrentScreen = false;
    this.initiallyRedirectToOffersPage = false;
    this.personalisedHomePageAutoReloadAfterSelfie = undefined;

    this.manageAppLaunchFromOnboarding();
    this.errorWhileTakingSelfie = false;
    this.isBackKeyPressed = false;

    this.debounceFireSelfieEvent = _.debounce(this.fireUacSelfieEvent, 5000, {
      leading: true,
      trailing: false,
    });
    this.didFocusListener = null;
    this.willBlurListener = null;
    this.feedLoadStart();
    this.blockedChannelId = null;
    this.listRef = null;
    this.guestUserScrollViewRef = null;
    this.forceRefreshHomePage = null;
    const {
      facialAnalysis: { my_attributes_values },
    } = this.props;
    Utility.genderFromSelfie = Utility.getGender(my_attributes_values);
    Utility.setPageLoadStart('feed', 0, '', Utility.isGenderAttributeExists(my_attributes_values));
    this.debouncedHandleBagIconPress = _.debounce(this.handleBagIconPress, 1000, {
      leading: true,
      trailing: false,
    });
    this.debouncedOnSearchHeaderTap = _.debounce(this.onSearchHeaderTap, 1000, {
      leading: true,
      trailing: false,
    });
  }

  componentDidMount() {
    const {
      navigation,
      getCartPricing,
      imageUrl,
      getAllReviews,
      initial_app_opened_at,
      authToken,
      authorized = false,
    } = this.props;
    if (isNative()) {
      Orientation.lockToPortrait();
    }
    if (isWeb() && !getCookie('app_installed_prompt_closed')) {
      navigation.navigate('AppInstallPrompt');
    }

    Utility.isOpenedFromOnboardingDeeplink = false;
    this.props.navigation.setParams({
      onSearchTap: this.onSearchTap,
    });

    this.fireUserUpdateInfoAPi();

    this.fireUacEvent();

    try {
      const currentAppVersion = parseFloat(Utility.getAppVersion().toString().replaceAll('.', ''));

      const min_allowed_ios_version = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.min_allowed_ios_version_v2);

      const min_allowed_android_version = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.min_allowed_android_version_v2);

      const hardUpgradeVersionAndroid = JSON.parse(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.versions_to_be_hard_updated_v2));

      const hardUpgradeVersionIos = JSON.parse(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.versions_to_be_hard_updated_ios_v2));

      if (!!hardUpgradeVersionAndroid[`${Utility.getAppVersion()}`] && Utility.isAndroid()) {
        this.setState({ showHardUpdateDialog: true });
      }

      if (!!hardUpgradeVersionIos[`${Utility.getAppVersion()}`] && Utility.isIOS()) {
        this.setState({ showHardUpdateDialog: true });
      }

      if (Utility.isAndroid() && min_allowed_android_version && parseFloat(min_allowed_android_version) > currentAppVersion) {
        this.setState({ showHardUpdateDialog: true });
        const meta = {
          [EventParameterKey.APP_VERSION]: currentAppVersion,
          [EventParameterKey.MIN_ALLOWED_IOS_VERSION]: min_allowed_android_version,
        };
        this.forceUpdatePopupViewEvent(meta);
      } else if (Utility.isIOS() && min_allowed_ios_version && parseFloat(min_allowed_ios_version) > currentAppVersion) {
        this.setState({ showHardUpdateDialog: true });
        const meta = {
          [EventParameterKey.APP_VERSION]: currentAppVersion,
          [EventParameterKey.MIN_ALLOWED_IOS_VERSION]: min_allowed_ios_version,
        };
        this.forceUpdatePopupViewEvent(meta);
      }
    } catch (error) {}
    this.getMasterAttributes();

    this.didFocusListener = navigation.addListener('focus', () => {
      const {
        facialAnalysis: { my_attributes_values },
        getFreeItemForOffer,
      } = this.props;

      const offerActive = RemoteConfig.getIntValue(REMOTE_CONFIG_KEYS.active_offer);
      Utility.isFeedTabActive = true;
      const genderExists = Utility.isGenderAttributeExists(my_attributes_values);
      if (!genderExists && !this.initiallyRedirectToOffersPage) {
        AnalyticsManager.logEvent(EventType.discoveryEvents.FEED_VIEW, {
          [EventParameterKey.PERSONALISED]: false,
          [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.NON_PERSONALISED_HOMEPAGE,
        });
      }
      getFreeItemForOffer(offerActive);
      this.setState({
        isScreenActive: true,
      });
      this.onDidFocus();
      this.isCurrentScreen = true;
    });
    this.willBlurListener = navigation.addListener('blur', () => {
      this.onWillBlur();
      Utility.isFeedTabActive = false;
      if (this.initiallyRedirectToOffersPage) {
        this.initiallyRedirectToOffersPage = false;
      }
      this.setState({
        isScreenActive: false,
      });
      this.isCurrentScreen = false;
    });
    this.tabPressListener = navigation.getParent().addListener('tabPress', () => {
      if (this.isCurrentScreen) {
        this.scrollPageToTop();
      }
    });
    setTimeout(getCartPricing, 5000);
    setTimeout(this.showCartSummary, 3000);
    /**
     * Use Case:
     *  1 - For existing user: Notification channels are already enabled
     *  2 - User manually enables notification permission from settings and come back to app.
     *
     *  This function call ReactMoE.pushPermissionResponseAndroid() which create a "default" notification channel for moengage"
     *  Without this function call there will be significate drop in the notifications.
     *
     * FIXME: This is not a  proper way to handle it now. Need to think about proper solution
     * which includes the toggling this functionality as soon user turn on/off notifications from settings.
     */
    NotificationUtils.updateMoEngageChannelIfNotificationPermissionIsAllowed();

    if (Utility.isAndroid()) {
      const isGoogleTrackingEnabled = RemoteConfig.getBooleanValue(REMOTE_CONFIG_KEYS.enabled_google_adv_tracking);

      if (isGoogleTrackingEnabled) {
        NativeModules.DeviceDetails.enabledGoogleTracking();
      } else {
        NativeModules.DeviceDetails.disabledGoogleTracking();
      }
    }

    if (Utility.isBlank(authToken) && AppConfig.getBooleanValue(Config.ENABLE_MANDATORY_LOGIN)) {
      setTimeout(NavigationService.renderOnboarding, 500);
    }

    if (Utility.isPresent(authToken) && !authorized) {
      const inviteOnlyAccessEnabled = AppConfig.getBooleanValue(Config.ENABLE_INVITE_ONLY_ACCESS);
      const destination = inviteOnlyAccessEnabled ? 'InviteCenter' : 'ConsumerProfile';
      const params = inviteOnlyAccessEnabled ? { replaceScreen: 'ConsumerProfile' } : {};
      navigation.navigate(destination, params);
    }
    // getAllReviews(0, 0, this.fetchReviewsCallback);
  }

  componentWillUnmount() {
    if (Utility.isPresent(DynamicLinkManager.unsubscribeDynamicLinks)) {
      DynamicLinkManager.unsubscribeDynamicLinks();
    }

    this.didFocusListener();
    this.willBlurListener();
    this.tabPressListener();
  }

  fetchReviewsCallback = (success, response) => {
    if (success && response) {
      const videos = [...response.objects];
      this.prefetchVideos(videos);
    }
  };

  fireUserUpdateInfoAPi = () => {
    const { updateUserData } = this.props;
    const fireUserUpdateInfo = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.fire_user_information_update);

    if (!fireUserUpdateInfo) {
      return;
    }

    updateUserData();
  };

  prefetchVideos = videos => {
    let videoUrls = [];
    const androidMaxCacheLimit = RemoteConfig.getIntValue(REMOTE_CONFIG_KEYS.android_max_cache_limit);
    const iosMaxCacheLimit = RemoteConfig.getIntValue(REMOTE_CONFIG_KEYS.maxVideoDownloadLimit);
    videos.every(video => {
      if (Utility.isAndroid()) {
        if (videoUrls.length === ~~(androidMaxCacheLimit / 4)) return false;
      } else if (Utility.isIOS()) {
        if (videoUrls.length === ~~(iosMaxCacheLimit / 4)) return false;
      }

      if (!Utility.isBlank(video.metadata) && !Utility.isBlank(video.metadata.videoUrl)) {
        const extension = video.metadata.videoUrl.split('.').pop();
        if (extension !== 'mp4') {
          videoUrls.push(video.metadata.videoUrl);
        }
      }
      return true;
    });
    CacheMedia.saveVideoUrlsForCaching(videoUrls);
  };

  getMasterAttributes = () => {
    const {
      facial_master_attribute_list: { master_attribute_list = {}, master_list_last_refreshed_at },
      getMasterAttributesList,
    } = this.props;

    if (Utility.isBlank(master_attribute_list) || Utility.isBlank(master_list_last_refreshed_at) || Utility.getTimeDifferenceBetweenFirstAppOpen(master_list_last_refreshed_at) > 0) {
      getMasterAttributesList();
    }
  };

  handleDeepLinkUrl = urlObj => {
    if (!Utility.isBlank(urlObj)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(urlObj.url, this.navigateToScreen);
    }
  };

  handleBackPress = () => {
    if (!this.isBackKeyPressed) {
      const timer = setTimeout(() => {
        this.isBackKeyPressed = false;
        clearTimeout(timer);
      }, 3000);
      this.isBackKeyPressed = true;
      this.showToast();
      return true;
    }
    BackHandler.exitApp();
    return false;
  };

  showFreeProductScreen = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent('VIEW_PRODUCTS_CLICKED', {
      screen: 'feed',
    });

    navigation.navigate('FreeGiftScreen');
  };

  handleRouteFromLink = (route, slug, path, extra) => {
    const { navigation } = this.props;
    navigation.navigate(route, { slug, extra, source: 'app_landing' });
  };

  feedLoadStart = () => {
    try {
      Utility.feedLoadStart = new Date();
      AnalyticsManager.logEvent(EventType.appLifeCycleEvents.IMPRESSION, {
        [EventParameterKey.APP_STATE]: 'feed_load_start',
        [EventParameterKey.TIME_STAMP]: Utility.feedLoadStart,
      });
    } catch (e) {
      AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
        [EventParameterKey.SOURCE]: 'Feed.js: feedLoadStart',
      });
    }
  };

  manageAppLaunchFromOnboarding = () => {
    const {
      navigation,
      new_user,
      facialAnalysis,
      guestProfile,
      facialAnalysis: { current_stage, popover_state },
      appInstalledSource,
      cartItems,
      imageUrl,
    } = this.props;

    try {
      const currentAppLaunchState = `s${Utility.isPresent(imageUrl) ? 1 : 0}c${Utility.isPresent(cartItems) ? 1 : 0}`;

      const app_landing = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.app_landing);

      const appLandingParams = Utility.jsonParser(app_landing);
      AnalyticsManager.logEvent('decision_taken', {
        type: 'page_load',
        input_value: currentAppLaunchState,
        output_value: appLandingParams[`${currentAppLaunchState}`],
      });

      const date = new Date();
      const dayOfWeek = date.getDay();
      if (
        Utility.isPresent(appLandingParams[`${currentAppLaunchState}`]) &&
        !appLandingParams[`${currentAppLaunchState}`].includes('feed') &&
        appLandingParams['onDay'] === dayOfWeek &&
        Utility.isAppLaunchedFromOnboarding
      ) {
        Utility.isAppLaunchedFromOnboarding = false;
        DynamicLinkManager.handleDynamicLinkWithoutDelay(appLandingParams[`${currentAppLaunchState}`], this.handleRouteFromLink);
        return;
      }
    } catch (e) {
      Utility.isAppLaunchedFromOnboarding = false;
    }

    if (Utility.isPresent(Utility.navigationRoute)) {
      navigation.navigate(Utility.navigationRoute, {
        slug: Utility.navigationSlug,
        extra: Utility.navigationExtra,
      });
    }

    if (!Utility.haltOffersLandingFlow) {
      if (
        (new_user && (Utility.isBlank(facialAnalysis) || (current_stage !== SELFIE_STATE.COMPLETED && popover_state !== 'hidden'))) ||
        guestProfile.current_state === CURRENT_GUEST_STATE.SKIPPED ||
        Utility.isOpenedFromOnboardingDeeplink
      ) {
        if (appInstalledSource === 'organic' || Utility.isOpenedFromOnboardingDeeplink) {
          // navigation.navigate('Store');
          Utility.isOpenedFromOnboardingDeeplink = false;
        }
        this.initiallyRedirectToOffersPage = true;
      }
    }

    // Reset Utility.haltOffersLandingFlow if its true, means now reset it. Process is already halted by user
    if (Utility.haltOffersLandingFlow) {
      Utility.haltOffersLandingFlow = false;
    }
  };

  firePageLoadStart = () => {
    Utility.setPageLoadStart('feed', 0, 'logged_out_user');
  };

  checkVisible = isVisible => {
    if (isVisible) {
      if (!this.state.isCartOfferTrayVisible) {
        this.setState({ isCartOfferTrayVisible: true });
      }
    } else if (this.state.isCartOfferTrayVisible) {
      this.setState({ isCartOfferTrayVisible: false });
    }
  };

  getAppVersionCode = min_allowed_android_version => {
    NativeModules.DeviceDetails.getAppVersionCode((err, value) => {
      try {
        if (parseInt(min_allowed_android_version) > parseInt(value)) {
          this.setState({ showHardUpdateDialog: true });
          const meta = {
            [EventParameterKey.APP_VERSION]: parseInt(value),
            [EventParameterKey.MIN_ALLOWED_ANDROID_VERSION]: min_allowed_android_version,
          };
          this.fireForceUpdatePopupViewEvent(meta);
        }
      } catch (error) {}
    });
  };

  componentDidUpdate(prevProps) {
    const { authToken: prevAuthToken = '' } = prevProps;
    const { authenticated, navigation, authToken, hasPreviousAssociatedAccount, authorized = false } = this.props;

    if (isPresent(prevAuthToken) && isBlank(authToken) && AppConfig.getBooleanValue(Config.ENABLE_MANDATORY_LOGIN)) {
      setTimeout(NavigationService.renderOnboarding, 500);
    }

    if (prevProps.navigation !== navigation) {
      this.upgradeToPrepaid();
    }

    if (
      // otpRequestedThroughHintPrompt &&
      !prevProps.authenticated &&
      authenticated
    ) {
      if (this.forceRefreshHomePage) {
        this.forceRefreshHomePage();
      }
    }

    if (Utility.isBlank(prevProps.has_selfie) && this.props.has_selfie === true) {
      this.debounceFireSelfieEvent();
    }

    if (JSON.stringify(prevProps.uac_events) !== JSON.stringify(this.props.uac_events)) {
      this.fireUacEvent();
    }

    /**
     * This block is getting use to show layout for existing account restore.
     *
     */
    if (!prevProps.hasPreviousAssociatedAccount && hasPreviousAssociatedAccount) {
      this.setState({
        showAssociatedAccountModal: true,
      });
    }
  }

  showCartSummary = () => {
    const { bagModalOpenTimeStamp, cartItems = [], saveBagModalOpenTimestampAndAppLaunchState, appLaunchState, navigation } = this.props;

    if (
      cartItems.length === 0 ||
      (cartItems.length === 1 && cartItems[0]?.is_free) ||
      appLaunchState === APP_LAUNCH_STATES.app_opened_with_deep_link ||
      appLaunchState === APP_LAUNCH_STATES.organic ||
      Utility.isSomethingSeenToday(bagModalOpenTimeStamp)
    ) {
      return;
    }
    const data = {
      timeStamp: new Date(),
    };

    saveBagModalOpenTimestampAndAppLaunchState(data);

    navigation.navigate('MyCartModal');
  };

  fireUacSelfieEvent = () => {
    const { uacSelfieEventTriggered } = this.props;
    const jsonUacSelfie = Utility.jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.uac_selfie));

    if (Utility.isBlank(jsonUacSelfie)) {
      return null;
    }
    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_SELFIE_SCORE, {
      [EventParameterKey.SCORE]: Utility.isPresent(jsonUacSelfie) && Utility.isPresent(jsonUacSelfie.yes) ? jsonUacSelfie.yes : 0,
    });
    uacSelfieEventTriggered(Utility.isPresent(jsonUacSelfie) && Utility.isPresent(jsonUacSelfie.yes) ? Utility.isPresent(jsonUacSelfie.yes) : 0);
  };

  fireUacEvent = () => {
    const { uac_events, updateUserData } = this.props;

    const genderThreshold = RemoteConfig.getIntValue(REMOTE_CONFIG_KEYS.uac_gender_threshold);

    const uacThreshold = RemoteConfig.getIntValue(REMOTE_CONFIG_KEYS.uac_new_threshold);

    let sumOfAllUac = 0;
    for (const prop in uac_events) {
      if (Utility.isPresent(uac_events[prop])) {
        sumOfAllUac += uac_events[prop];
      }
    }

    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_NEW_SCORE, {
      [EventParameterKey.SCORE]: sumOfAllUac || 0,
    });

    if (sumOfAllUac >= uacThreshold && uac_events.uac_gender_score >= genderThreshold) {
      AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_NEW_SCORE_T, {
        [EventParameterKey.SCORE]: sumOfAllUac || 0,
      });
    }
    const userData = {
      cohort_score: sumOfAllUac,
    };
    setTimeout(() => {
      updateUserData(userData);
    }, 15000);
  };

  updateCurrentlyPlayingIndex = index => {
    this.setState({
      currentlyPlayingIndex: index,
    });
  };

  forceUpdatePopupViewEvent = meta => {
    AnalyticsManager.logEvent(EventType.forceUpdate.FORCE_UPDATE_POPUP_VIEW, meta);
  };

  upgradeToPrepaid = () => {
    const { navigation, route } = this.props;
    if (route.params?.extra?.upgrade_to_prepaid) {
      this.setState({
        isUpgradeToPrepaid: true,
        orderIdThroughDynamicLink: route.params?.extra?.order_id ?? '',
      });
      setTimeout(() => {
        this.toggleCartVisibility();
      }, 500);
    }
  };

  // TODO:Extract this function into DynamicLinks Utility, so that, this mess will be removed from here. Add this to screen navigator once Deeplinking things are started.
  navigateToScreen = (route, slug, path) => {
    const { navigation, addToCart, getListDetails } = this.props;

    let extra = {};
    if (path === 'register_collab') {
      extra = { registerCollabThroughDeeplink: true };
    } else if (path === 'apply_coupon') {
      this.setState({ promoCodeThroughDynamicLink: slug });
      setTimeout(() => {
        this.toggleCartVisibility();
      }, 500);
    } else if (path === 'payment_methods') {
      setTimeout(() => {
        this.toggleCartVisibility();
      }, 500);
    }

    if (route === 'AddToBag') {
      addToCart(DynamicLinkUtilities.generateCartDataFromSlug(slug), () => {
        this.toggleCartVisibility();
      });
    } else if (route === 'ContentModal') {
      const { videoId, listId } = DynamicLinkUtilities.generateVideoItemDataFromSlug(slug);

      getListDetails(listId, (success, response) => {
        const selectedVideoIndex = Utility.deriveIndexFromListObject(response.objects, videoId);

        navigation.navigate('ContentModal', {
          listId,
          index: Utility.deriveIndexFromListObject(response.objects, videoId),
          itemData: response.objects[selectedVideoIndex],
          listData: response,
        });
      });
    } else {
      navigation.navigate(route, {
        slug,
        extra,
      });
    }
  };

  toggleCartVisibility = () => {
    const { navigation } = this.props;
    navigation.push('Cart');
  };

  onCartDismiss = () => {
    this.setState({
      isCartVisible: false,
      isUpgradeToPrepaid: false,
      showFreeProductScreen: false,
    });
  };

  showCartTray = () => {
    this.setState({
      isCartTrayVisible: true,
    });
  };

  hideCartTray = () => {
    this.setState({
      isCartTrayVisible: false,
    });
  };

  showToast = () => {
    if (Utility.isPresent(this.toast)) {
      this.toast.show('Press again to exit', 1000);
    }
  };

  hardUpdateCta = () => {
    AnalyticsManager.logEvent(EventType.forceUpdate.FORCE_UPDATE_CONTINUE);
    if (Utility.isAndroid()) {
      Linking.openURL(Config.PLAY_STORE_LINK);
    } else {
      Linking.openURL(Config.APP_STORE_LINK);
    }
  };

  lockScreenToPortrait = (payload) => {
    if (isNative()) {
      Orientation.lockToPortrait();
    }

    const { navigation, facialAnalysis } = this.props;
    if (
      // navigation.getParam('previousScreen') === SCREEN_CONSTANTS.IMAGE_REVIEW &&
      Utility.forceHitHomeApi &&
      this.forceRefreshHomePage
    ) {
      this.forceRefreshHomePage();
      Utility.forceHitHomeApi = false;
    }

    let extra = {};

    if (Utility.isPresent(facialAnalysis) && (Utility.isPresent(facialAnalysis.facialProperties) || Utility.isPresent(facialAnalysis.my_attributes_values))) {
      extra = { [EventParameterKey.PERSONALISED]: true };
    } else {
      extra = { [EventParameterKey.PERSONALISED]: false };
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.FEED_VIEW, {
      ...extra,
    });
    AnalyticsManager.setCurrentScreen(SCREEN_CONSTANTS.FEED);

    // StatusBar.setBackgroundColor('black');
    // StatusBar.setBarStyle('dark-content');
  };

  onWillBlur = payload => {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
    this.setState(prevState => ({
      currentlyPlayingIndex: null,
      lastPlayingIndex: prevState.currentlyPlayingIndex,
    }));
  };

  showToastForAddToCart = message => {
    this.toast.show(message, 1000);
  };

  setForceRefreshHomePage = callback => {
    this.forceRefreshHomePage = callback;
  };

  scrollPageToTop = () => {
    if (this.listRef) {
      this.listRef.scrollToOffset({ offset: 0, animated: true });
    }

    if (this.guestUserScrollViewRef) {
      this.guestUserScrollViewRef.scrollTo(0);
    }
  };

  setListRef = ref => {
    this.listRef = ref;
  };

  setGuestUserScrollViewRef = ref => {
    this.guestUserScrollViewRef = ref;
  };

  onDidFocus = () => {
    this.checkUnauthorizedAccess();
    this.props.updateLastVisitedProductId('');
    if (Utility.refreshDynamicListFn) {
      this.props.toggleDynamicListTitleVisibility(false);
      Utility.refreshDynamicListFn();
    }
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.TAB_VIEW, {
      tab: 'feed',
    });
    const {
      facialAnalysis: { my_attributes_values },
    } = this.props;
    const genderExists = Utility.isGenderAttributeExists(my_attributes_values);
    AnalyticsManager.setCurrentScreen(genderExists ? SCREEN_CONSTANTS.FEED : SCREEN_CONSTANTS.NON_PERSONALISED_FEED);
    Utility.setStatusBarWhite();
    if (Utility.forceHitHomeApi && this.forceRefreshHomePage) {
      Utility.forceHitHomeApi = false;
      Utility.setForceRefreshHomePage = false;
      this.forceRefreshHomePage();
    }
  };

  onRestorePreviousAccount = () => {
    const { restorePreviousAccount } = this.props;
    restorePreviousAccount();
    this.setState({
      showAssociatedAccountModal: false,
    });
  };

  onCancelPreviousAccountRestore = () => {
    const { setPreviousAssociatedAccount } = this.props;
    setPreviousAssociatedAccount(false);

    this.setState(
      {
        showAssociatedAccountModal: false,
      },
      () => {
        Session.previousAccountData = {};
      },
    );
  };

  checkUnauthorizedAccess = () => {
    const { authToken, authorized, navigation } = this.props;
    if (Utility.isPresent(authToken) && !authorized) {
      const inviteOnlyAccessEnabled = AppConfig.getBooleanValue(Config.ENABLE_INVITE_ONLY_ACCESS);
      const destination = inviteOnlyAccessEnabled ? 'InviteCenter' : 'ConsumerProfile';
      const params = inviteOnlyAccessEnabled ? { replaceScreen: 'ConsumerProfile' } : {};
      navigation.navigate(destination, params);
    }
  };

  restorePreviousAccountModal = () => {
    const { showAssociatedAccountModal } = this.state;
    return <AutoLoginModal showModal={showAssociatedAccountModal} onPressCancel={this.onCancelPreviousAccountRestore} onPressLogin={this.onRestorePreviousAccount} />;
  };

  invokeSalonLoginFlow() {
    const { setPhoneNumberModalInfo } = this.props;
    AnalyticsManager.logEvent(EventType.onboardingEvent.LOGIN_INITIATE, {
      [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.HOMEPAGE,
    });
    Utility.openOtpModalFromProfile = true;
    setPhoneNumberModalInfo(Utility.getLoginModalInfo('PAY_WITH_FOXY'));
    NavigationService.renderOnboarding();
  }

  payWithFoxyNavigation = () => {
    const { navigation } = this.props;
    navigation.navigate('SalonSearch');
  };

  handleBagIconPress = () => {
    const { navigation } = this.props;
    navigation.push('Cart');
  };

  onSearchHeaderTap = () => {
    const { navigation } = this.props;
    navigation.navigate('Search');
  };

  searchHeader = () => {
    if (!AppConfig.getBooleanValue(Config.SHOW_SEARCH_HEADER_ON_FEED)) return null;
    return (
      <TouchableWithoutFeedback onPress={this.debouncedOnSearchHeaderTap}>
        <View style={styles.searchInputContainer}>
          <View style={styles.searchHeaderInput}>
            <Image source={images.searchFeed} style={styles.searchHeaderImage} resizeMode='contain' />
            <Text style={styles.searchHeaderPlaceholderText}>Search products, videos, artists etc</Text>
          </View>

          <BagIcon handleTap={this.debouncedHandleBagIconPress} showCart={true} />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  onSalonStartNowPress = () => {
    const { authToken, navigation } = this.props;
    if (Utility.isBlank(authToken)) {
      this.invokeSalonLoginFlow();
      Utility.furtherAction = {
        action: this.payWithFoxyNavigation,
        params: [],
      };
      return;
    }

    AnalyticsManager.logEvent(EventType.salon.PAY_ON_FOXY_CLICK, {
      [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.MY_PROFILE,
    });
    navigation.navigate('SalonSearch');
  };

  onPressHideOosCheckBox = hideOosProduct => {
    this.setState({
      hideOosProduct,
    });
  };

  render() {
    const {
      navigation,
      facialAnalysis: { my_attributes_values },
      authToken,
      cartOffers,
      showUploadTray,
      authorized = false,
    } = this.props;
    const { HOME_TAB_SLUG = HOME_PAGE_SLUG } = Config || {};
    const { loggedOutUserState } = this.state;
    const genderExists = Utility.isGenderAttributeExists(my_attributes_values);

    if (Config.DISABLE_GENDER_SELECTION_ON_LOGGED_OUT_HOME !== 'true' && !genderExists && authorized) {
      this.personalisedHomePageAutoReloadAfterSelfie = false;
      return (
        <View style={styles.container}>
          <LoggedOutUserHomePage
            currentState={loggedOutUserState}
            firePageLoadStart={this.firePageLoadStart}
            navigation={navigation}
            onSalonStartNowPress={this.onSalonStartNowPress}
            scrollViewRef={this.setGuestUserScrollViewRef}
          />

          <this.restorePreviousAccountModal />
        </View>
      );
    }

    if (Utility.isIOS()) {
      AppTrackingTransparencyManager.showAppTrackingPrompt();
    }

    const { isCartTrayVisible, currentlyPlayingIndex, promoCodeThroughDynamicLink, isScreenActive, hideOosProduct } = this.state;
    let barStyle = 'dark-content';
    if (this.personalisedHomePageAutoReloadAfterSelfie === false) {
      this.personalisedHomePageAutoReloadAfterSelfie = undefined;

      AnalyticsManager.logEvent(EventType.discoveryEvents.FEED_VIEW, {
        [EventParameterKey.PERSONALISED]: true,
      });
    }
    if (Utility.isIOS()) {
      // barStyle = initialMode === 'dark' ? 'dark-content' : 'light-content';
      barStyle = 'dark-content';
    }

    const ContainerComponent = isWeb() ? ScrollView : View;
    return (
      <ErrorBoundary screen_name={AnalyticsScreenNames.HOME_PAGE} itemData={{ name: 'feed', type: 'list', id: 'undefined' }} hideHeader>
        <SelfieReviewModalWrapper isActive={isScreenActive}>
          <StatusBar
            backgroundColor='white' // white for android
            barStyle={barStyle}
            translucent={false}
          />

          <ContainerComponent style={styles.container}>
            <this.searchHeader />
            <List
              setForceRefreshHomePage={this.setForceRefreshHomePage}
              navigation={navigation}
              slug={HOME_TAB_SLUG}
              feed
              authToken={this.props.authToken}
              toggleCartVisibility={this.toggleCartVisibility}
              updateCurrentlyPlayingIndex={this.updateCurrentlyPlayingIndex}
              currentlyPlayingIndex={currentlyPlayingIndex}
              previousScreen={SCREEN_CONSTANTS.FEED}
              showToast={this.showToastForAddToCart}
              checkVisible={this.checkVisible}
              showFreeProductScreen={this.showFreeProductScreen}
              setListRef={this.setListRef}
              tracePerfObject={this.trace}
              onPressHideOosCheckBox={this.onPressHideOosCheckBox}
              hideOosProduct={hideOosProduct}
              showWebFooter
            />
            <Toast
              style={{ position: 'absolute', bottom: 70 }}
              ref={ref => {
                this.toast = ref;
              }}
            />
          </ContainerComponent>

          {!showUploadTray && (
            <CartTray
              toggleCartVisibility={this.toggleCartVisibility}
              toggleCartTrayVisibility={this.toggleCartTrayVisibility}
              showCartTray={this.showCartTray}
              hideCartTray={this.hideCartTray}
              cartOffers={cartOffers}
              isCartTrayVisible={isCartTrayVisible}
              navigation={navigation}
            />
          )}

          {!showUploadTray && (
            <OfferTray
              toggleCartVisibility={this.toggleCartVisibility}
              toggleCartTrayVisibility={this.toggleCartTrayVisibility}
              showCartTray={this.showCartTray}
              hideCartTray={this.hideCartTray}
              showFreeProductScreen={this.showFreeProductScreen}
              cartOffers={cartOffers}
              isCartTrayVisible={!this.state.isCartOfferTrayVisible}
            />
          )}

          <UploadStatusTray />

          { isNative() && (
            <FoxyAlert
              isVisible={this.state.showHardUpdateDialog}
              hideSecondButton
              alertBoxTitle={`Please update ${Config.APP_NAME}`}
              alertMessage='A newer version of this app is available with awesome features and enhancements'
              firstButtonTitle='Update'
              firstButtonOnPress={this.hardUpdateCta}
              height={180}
              autoWrapContent
            />
          )}
        </SelfieReviewModalWrapper>
      </ErrorBoundary>
    );
  }
}

// PropTypes
Feed.propTypes = {
  list: PropTypes.shape({
    type: PropTypes.string,
  }),
};

const mapStateToProps = function (state) {
  return {
    facial_master_attribute_list: state.facialAnalysis,
    guestProfile: state.UserAccountInfo.guestProfile,
    authToken: state.UserAccountInfo.authToken,
    facialAnalysis: state.UserAccountInfo.facialAnalysis,
    authenticated: state.UserAccountInfo.authenticated,
    otpRequestedThroughHintPrompt: state.UserAccountInfo.requestedFromHintPrompt,
    new_user: state.UserAccountInfo.new_user,
    appInstalledSource: state.UserAccountInfo.appInstalledSource,
    cartOffers: state.cart.cartOffers,
    uac_events: state.UserAccountInfo.uac_events,
    imageUrl: state.UserAccountInfo.profile.selfie_image_url,
    cartItems: state.bag.cartItems,
    appLaunchState: state.bag.appLaunchState,
    bagModalOpenTimeStamp: state.bag.bagModalOpenTimeStamp,
    initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
    showUploadTray: state.UserAccountInfo.showUploadTrayOnFeed,
    hasPreviousAssociatedAccount: state.UserAccountInfo.hasPreviousAssociatedAccount,
    authorized: state.UserAccountInfo.authorized,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      addToCart,
      setPhoneNumberModalInfo,
      showImageProgressBar,
      showPopover,
      imageUploadPercentage,
      saveFaceAnalysisData,
      getMasterAttributesList,
      getListDetails,
      retrySelfieAnalysis,
      getFreeItemForOffer,
      uacSelfieEventTriggered,
      updateUserData,
      getCartPricing,
      getAllReviews,
      restorePreviousAccount,
      setPreviousAssociatedAccount,
      updateLastVisitedProductId,
      toggleDynamicListTitleVisibility,
      saveBagModalOpenTimestampAndAppLaunchState,
    },
    dispatch,
  ),
});

export const FeedHeaderOptions = isDesktop() ? {
  headerShown: false,
} : {
  headerTitle: () => (
    <Image
      source={Utility.isPresent(Config.HEADER_LOGO) ? { uri: Config.HEADER_LOGO } : images.comparePriceImage.foxy}
      style={{
        height: parseInt(Config.HEADER_LOGO_HEIGHT),
        width: parseInt(Config.HEADER_LOGO_WIDTH),
        resizeMode: 'cover',
      }}
    />
  ),
  headerLeft: () => <TabNavigationLeftHeader tabBar={TABBAR_CONSTANT.feed} />,
  headerRight: () => <TabNavigationRightHeader tabBar={TABBAR_CONSTANT.feed} />,
  style: {
    width: 150,
  },
  headerBackVisible: false,
  headerShadowVisible: false,
  headerStyle: {
    backgroundColor: colors.white,
  },
  headerTitleAlign: 'center',
};

export const HomeNavigationOptions = {
  tabBarLabel: Config.HOME_TAB_LABEL || 'Home',
  tabBarActiveTintColor: Config.HOME_TAB_ACTIVE_TEXT_COLOR || Config.HOME_TAB_ACTIVE_COLOR,
  tabBarInactiveTintColor: Config.HOME_TAB_INACTIVE_COLOR,
  tabBarStyle: { borderTopWidth: 0, elevation: 0 },
  animation: 'fade',
  tabBarIcon: ({ focused }) => {
    const configImage = focused ? Config.HOME_TAB_ACTIVE_ICON_URL : Config.HOME_TAB_INACTIVE_ICON_URL;
    const image = focused ? images.tab.home_selected : images.tab.home_unselected;

    const tintColor = focused ? Config.HOME_TAB_ACTIVE_COLOR : Config.HOME_TAB_INACTIVE_COLOR;
    return (
      <Image
        testID='home-tab-icon'
        accessibilityLabel='home-tab-icon'
        source={Utility.isPresent(configImage) ? { uri: configImage } : image}
        style={{
          height: 24,
          width: 24,
          resizeMode: 'contain',
          tintColor,
        }}
      />
    );
  },
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Feed));
