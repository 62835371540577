import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Animated,
  Clipboard,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import PropTypes from 'prop-types';
import Toast from 'react-native-easy-toast';
import Share from '../../libraries/ReactNativeShare';
import _ from 'lodash';
import { Dropdown } from 'react-native-material-dropdown';
import {
  LAYOUT,
  AVAILABLE_BUILD_TYPES,
} from '../../config/Constants';
import { ProductDetailStyles } from '../Product/styles';
import Images from '../../theme/Images';
import LikeButton from './LikeButton';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { withMaybe } from '../../lib/Monads';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import ShareModal from './../Product/ShareModal';
import images from '../../theme/Images';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { FollowArtistButton } from '../Artist';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { BagIcon } from '../header';

class ProfileCustomNavigationHeader extends Component {
  constructor(props) {
    super(props);
    this.navigationHeaderHeight = Utility.isIOS() ? 44 + Utility.topInset : 56;
    this.state = {
      isShareModalVisible: false,
    };
    this.dropDownOption = [
      { type: 'share', value: 'Share' },
      { type: 'claim', value: 'Claim Profile' },
      { type: 'report', value: 'Report Abuse' },
    ];
    this.dropdownRef = React.createRef();
  }

  showLikeButtonCondition = (props) =>
    props.content === 'artist' ||
    props.previousScreen === 'dashboard' ||
    Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only ||
    props.previousScreen === SCREEN_CONSTANTS.COLLECTION ||
    props.previousScreen === SCREEN_CONSTANTS.PERSONALISED_PAGE ||
    props.morePageDisplay === 'full-page-banner';

  likeButton = withMaybe(this.showLikeButtonCondition)(LikeButton);

  logAnalyticsEvent = (...meta) => {
    const [
      screen_name = '',
      id,
      name = '',
      contentType,
      medium = '',
      skuId = '',
    ] = meta;

    AnalyticsManager.logEvent(EventType.miscAppEvents.ITEM_SHARE, {
      [EventParameterKey.SCREEN_NAME]: screen_name,
      [EventParameterKey.ITEM_ID]: id,
      [EventParameterKey.ITEM_NAME]: name,
      [EventParameterKey.ITEM_TYPE]: contentType,
      [EventParameterKey.MEDIUM]: medium,
    });
    AnalyticsManager.logFirebaseEvent(EventType.googleRemarketingEvents.SHARE, {
      [EventParameterKey.CONTENT_TYPE]: contentType,
      [EventParameterKey.ID]: Utility.isPresent(skuId) ? skuId : id,
    });
  };

  onSocialShareOptionTapped = (medium) => {
    const {
      shareUrl,
      isProduct = false,
      name,
      image,
      brand,
      previousScreen,
      id,
      screenName,
      content,
      skuId,
    } = this.props;
    const sharedUrl = shareUrl; // to change url when triggered from offer detail page
    let title = name;
    let message = '';
    if (isProduct) {
      title = `${name} ${brand.name}`;
    }
    if (screenName === SCREEN_CONSTANTS.ARTIST_DETAILS) {
      message = `Follow ${name} to catch their latest videos on ${Config.APP_NAME}, the best platform for all your beauty needs.`;
    } else if (screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL) {
      message = `Buy this product on ${Config.APP_NAME}, the best platform for all your beauty needs.`;
    } else if (screenName === SCREEN_CONSTANTS.BRAND_DETAILS) {
      message = `Check out top product and offers of ${name} on ${Config.APP_NAME}, the best platform for all your beauty needs`;
    } else if (screenName === SCREEN_CONSTANTS.MORE_PAGE) {
      message = `Check out this collection on ${Config.APP_NAME}, the best platform for all your beauty needs.`;
    } else if (screenName === SCREEN_CONSTANTS.OFFER_DETAIL) {
      message = `Check out this offer of ${name} on ${Config.APP_NAME}, the best platform for all your beauty needs.`;
    }
    const getDynamicLink = DynamicLinkManager.getShortenUrl(sharedUrl, {
      title,
      imageUrl: Utility.getMinifiedImage(image, 200, 200),
      description: '',
    });
    getDynamicLink
      .then((link) => {
        const options = {
          title: Config.APP_NAME,
          message,
          url: link,
          social: Share.Social[_.upperCase(medium)],
        };
        if (Utility.isPresent(id) && Utility.isPresent(content)) {
          const contentType = content;
          const screen_name = screenName;
          this.logAnalyticsEvent(
            screen_name,
            id,
            name,
            contentType,
            medium,
            skuId,
          );
        }
        if (medium === 'copy') {
          Clipboard.setString(`${message} ${link}`);
          return;
        }
        if (medium === 'more') {
          const generalShareOption = {
            title: Config.APP_NAME,
            message,
            url: link,
          };
          Utility.onShareTapped(link, generalShareOption);
          return;
        }
        Share.shareSingle(options)
          .then((res) => {})
          .catch((err) => {});
      })
      .catch((error) => {});
  };

  goBack = () => {
    this.props.goBack();
  };

  tintColor = this.props.scrollY.interpolate({
    inputRange: [50, 100],
    outputRange: [colors.white, colors.foxyBlack],
    extrapolate: 'clamp',
  });

  showShareModal = () => {
    const {
      shareUrl,
      isProduct = false,
      name,
      image,
      brand,
      previousScreen,
      id,
      screenName,
      content,
      skuId,
    } = this.props;
    this.logAnalyticsEvent(screenName, id, name, content, 'start', skuId);
    this.setState({
      isShareModalVisible: true,
    });
  };

  hideShareModal = () => {
    this.setState({
      isShareModalVisible: false,
    });
  };

  updateLikeOnDoubleTap = () => {
    console.log('update like on double tap');
    this.likeButtonRef.updateLikeOnDoubleTap();
  };

  onLike = () => {
    this.toast.show('Added to My Kit');
  };

  dropDownOptionSelected = (type) => {
    const typ = type.toLowerCase();
    if (typ === 'share') {
      setTimeout(() => {
        this.showShareModal();
      }, 500);
    } else if (typ === 'claim profile') {
      const { navigateToProfile } = this.props;
      setTimeout(() => {
        navigateToProfile();
      }, 500);
    } else if (typ === 'report abuse') {
      Utility.openWhatsApp('');
    }
  };

  menuButtonPressed = () => {
    const { previousScreen, onClickMenu } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.ARTIST) {
      this.dropdownRef.current.focus();
    } else {
      onClickMenu();
    }
  };

  conditionalDivider = (props) => {
    let {
      dividerInitialOpacity,
      previousScreen,
      morePage = false,
      quickFiltersAvailable,
    } = props;
    if (previousScreen === SCREEN_CONSTANTS.ARTIST && !morePage) {
      dividerInitialOpacity = 0;
    }
    const inputRange = quickFiltersAvailable ? [100, 130] : [150, 200];
    if (Utility.isIOS) {
      return (
        <Animated.View
          style={[
            ProductDetailStyles.navigationDivider,
            {
              opacity: this.props.scrollY.interpolate({
                inputRange,
                outputRange: [dividerInitialOpacity, 1],
              }),
              useNativeDriver: true,
            },
          ]}
        />
      );
    }
    return null;
  };

  likeButtonRef = (ref) => {
    this.likeButtonRef = ref;
  };

  backButtonContainer = () => {
    const {
      hideRichDescriptionVisibility = {},
      isRichDescriptionVisible = false,
    } = this.props;
    const action = isRichDescriptionVisible
      ? hideRichDescriptionVisibility
      : this.goBack;
    return (
      <TouchableOpacity
        onPress={action}
        style={[
          ProductDetailStyles.navigationBackButtonContainer,
          { backgroundColor: '#00000000' },
        ]}
        hitSlop={Utility.getHitSlop('backButton')}
      >
        <Image
          source={Images.ic_back_product}
          style={ProductDetailStyles.navbarIconDefaultStyle}
        />
      </TouchableOpacity>
    );
  };

  onSearchCardClick = () => {
    // const { navigation } = this.props;
    // AnalyticsManager.logEvent('scan_card_click');
    // navigation.push('Search', {});
  };

  searchButtonContainer = (props) => {
    const { showSearchButton, shareButtonRightSpace, onSearchIconTap } = props;
    console.log('onSearchIconTap', onSearchIconTap, showSearchButton);
    if (!showSearchButton || !onSearchIconTap) return null;
    return (
      <TouchableOpacity
        onPress={onSearchIconTap}
        style={[
          ProductDetailStyles.navigationShareButtonContainer,
          { right: shareButtonRightSpace },
        ]}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={Images.ic_search_product}
          style={ProductDetailStyles.navbarShareIconStyle}
        />
      </TouchableOpacity>
    );
  };

  shareButtonContainer = (props) => {
    const { showShareButton, shareButtonRightSpace } = props;
    if (!showShareButton) return null;
    return (
      <TouchableOpacity
        onPress={this.showShareModal}
        style={[
          ProductDetailStyles.navigationShareButtonContainer,
          { right: shareButtonRightSpace },
        ]}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={Images.share}
          style={ProductDetailStyles.navbarShareIconStyle}
        />
      </TouchableOpacity>
    );
  };

  likeButtonContainer = () => {
    const { id, type, slug, content, name, screenName } = this.props;

    if (!this.showLikeButtonCondition(this.props)) {
      return null;
    }
    return (
      <LikeButton
        ref={(ref) => {
          this.likeButtonRef = ref;
        }}
        id={id}
        type={type}
        slug={slug}
        layout={LAYOUT.SCREEN}
        content={content}
        onLike={this.onLike}
        itemName={name}
        screenName={screenName}
      />
    );
  };

  followButtonContainer = (props) => {
    const { showFollowButton } = props;
    const { id, slug } = this.props;
    if (!showFollowButton) return null;

    return (
      <FollowArtistButton
        id={id}
        followButtonLayout={LAYOUT.NAVIGATION_HEADER}
        slug={slug}
      />
    );
  };

  cartButtonContainer = (props) => {
    const { showCartIcon, cartButtonRightSpace, onPressCart } = props;
    if (!showCartIcon) return null;
    return (
      <View
        style={[
          ProductDetailStyles.navigationShareButtonContainer,
          { right: cartButtonRightSpace },
        ]}
      >
        <BagIcon handleTap={onPressCart} showCart />
      </View>
    );
  };

  menuButtonContainer = (props) => {
    const { shareButtonRightSpace, showMenuButton } = props;
    if (!showMenuButton) return null;

    return (
      <TouchableOpacity
        // onPress={onClickMenu}
        onPress={this.menuButtonPressed}
        style={[
          ProductDetailStyles.navigationShareButtonContainer,
          { right: shareButtonRightSpace },
        ]}
        hitSlop={Utility.getHitSlop()}
      >
        <Image
          source={images.overflow_menu}
          style={{ height: 35, width: 35 }}
        />
      </TouchableOpacity>
    );
  };

  dropDownContainer = (props) => {
    const { showDropdown } = props;
    if (!showDropdown) return null;
    return (
      <Dropdown
        ref={this.dropdownRef}
        label={null}
        data={this.dropDownOption}
        dropdownPosition={-2}
        pickerStyle={{
          position: 'absolute',
          width: 110,
        }}
        containerStyle={{
          right: 110,
          position: 'absolute',
          width: 0,
          height: 0,
          top: this.navigationHeaderHeight,
        }}
        renderAccessory={null}
        textColor={colors.foxyBlack}
        hitSlop={{ top: 20, right: 20, left: 20 }}
        onChangeText={(type) => this.dropDownOptionSelected(type)}
        fontSize={12}
        itemColor={colors.foxyBlack}
        selectedItemColor={colors.foxyBlack}
      />
    );
  };

  render() {
    const {
      liked = 'false',
      content,
      id,
      type,
      slug,
      image,
      isProduct,
      name,
      sp,
      mrp,
      discount,
      brand,
      subHeading,
      showMenuButton,
      onClickMenu,
      previousScreen,
      screenName,
      morePage = false,
      morePageDisplay,
      showCartIcon,
      onPressCart,
      quickFiltersAvailable,
      showBackIcon = true,
    } = this.props;
    let { showShareButton, showSearchButton, onSearchIconTap } = this.props;
    const { isShareModalVisible } = this.state;
    let shareButtonRightSpace = 60;
    const cartButtonRightSpace = 16;
    if (content === 'artist') {
      shareButtonRightSpace = 52; // for artist page there are three icons in navigation header
    }
    if (
      previousScreen === 'dashboard' ||
      Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only ||
      previousScreen === SCREEN_CONSTANTS.COLLECTION ||
      (previousScreen === SCREEN_CONSTANTS.ARTIST && morePage) ||
      previousScreen === SCREEN_CONSTANTS.PERSONALISED_PAGE ||
      morePageDisplay === 'full-page-banner'
    ) {
      showShareButton = false;
    }
    let navigationHeaderInitialColor = 'rgba(255, 255, 255,0.0)';
    let navigationHeaderInitialElevation = 0;
    let headerImageInitialOutput = 0;
    if (!Utility.isBlank(previousScreen)) {
      if (previousScreen === SCREEN_CONSTANTS.COLLECTION) {
        navigationHeaderInitialColor = 'rgba(255,255,255,1)';
        navigationHeaderInitialElevation = 2;
        headerImageInitialOutput = 1;
      }
    }

    if (screenName === SCREEN_CONSTANTS.OFFER_DETAIL) {
      navigationHeaderInitialColor = 'rgba(255,255,255,1)';
      navigationHeaderInitialElevation = 2;
      headerImageInitialOutput = 1;
    }

    const showDropdown = previousScreen === SCREEN_CONSTANTS.ARTIST;
    const showFollowButton =
      previousScreen === SCREEN_CONSTANTS.ARTIST && !morePage;
    const deviceSpecificInputRang = Utility.isAndroid() ? [70, 110] : [70, 120];
    const nonFilteredDeviceSpecificINputRange = Utility.isAndroid()
      ? [70, 110]
      : [110, 130];
    const inputRange = quickFiltersAvailable
      ? deviceSpecificInputRang
      : nonFilteredDeviceSpecificINputRange;
    return (
      <>
        <Animated.View
          style={[
            ProductDetailStyles.navigationHeader,
            {
              backgroundColor: this.props.scrollY.interpolate({
                inputRange,
                outputRange: [
                  navigationHeaderInitialColor,
                  'rgba(255,255,255,1)',
                ],
              }),
              elevation: this.props.scrollY.interpolate({
                inputRange,
                outputRange: [navigationHeaderInitialElevation, 2],
                extrapolate: 'clamp',
              }),
              height: this.navigationHeaderHeight,
              useNativeDriver: true,
            },
          ]}
        >
          <Animated.View
            style={[
              ProductDetailStyles.headerImageBackground,
              {
                opacity: this.props.scrollY.interpolate({
                  inputRange,
                  outputRange: [headerImageInitialOutput, 1],
                }),
                useNativeDriver: true,
              },
            ]}
          />
          <Animated.View
            style={[
              ProductDetailStyles.navigationHeaderChildView,
              {
                opacity: this.props.scrollY.interpolate({
                  inputRange,
                  outputRange: [headerImageInitialOutput, 1],
                }),
                useNativeDriver: true,
              },
            ]}
          >
            {this.props.children}
          </Animated.View>

          {showBackIcon && <this.backButtonContainer />}

          <this.dropDownContainer showDropdown={showDropdown} />
          {morePage && (
            <this.conditionalDivider
              dividerInitialOpacity={headerImageInitialOutput}
              previousScreen={previousScreen}
              morePage={morePage}
            />
          )}
        </Animated.View>
      </>
    );
  }
}

ProfileCustomNavigationHeader.propTypes = {
  slug: PropTypes.string,
  shareUrl: PropTypes.string,
  showShareButton: PropTypes.bool,
  showMenuButton: PropTypes.bool,
  onClickMenu: PropTypes.func,
  screenName: PropTypes.string,
};
ProfileCustomNavigationHeader.defaultProps = {
  slug: '',
  shareUrl: '',
  showShareButton: true,
  showMenuButton: false,
  screenName: '',
};

export default ProfileCustomNavigationHeader;
