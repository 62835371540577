import { StyleSheet } from 'react-native';
import React from 'react';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import colors from '../../theme/Colors';
import NavigationService from '../../navigator/NavigationService';

export default function LoginOption({ toggleAccountModal }) {
  const handlePress = () => {
    toggleAccountModal();
    NavigationService.renderOnboarding();
  };

  return (
    <FoxyShadowButton
      width={115}
      height={24}
      title="Login / Sign-up"
      borderRadius={16}
      onPress={handlePress}
      firstColor={colors.black}
      secondColor={colors.black}
      style={styles.buttonContainer}
      textStyle={styles.text}
    />
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 20,
    marginHorizontal: 14,
    marginBottom: 12,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  text: {
    color: colors.white,
    lineHeight: 32,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },
});
