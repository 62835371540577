import { jsonParser } from '../utils/JsonUtility';

export const UPDATE_LIST_DATA = 'UPDATE_LIST_DATA';
export const MERGE_LIST_DATA = 'MERGE_LIST_DATA';
export const NORMALIZE_DATA = 'NORMALIZE_DATA';
export const FETCH_LIST = 'FETCH_LIST';
export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_LIST_FAILURE = 'FETCH_LIST_FAILURE';
export const UPDATE_OFFER_DATA = 'UPDATE_OFFER_DATA';
export const UPDATE_OFFER_DATA_BY_SLUG = 'UPDATE_OFFER_DATA_BY_SLUG';
export const UPDATE_PRODUCT_DATA = 'UPDATE_PRODUCT_DATA';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const LIST_DETAIL_RECEIVED = 'LIST_DETAIL_RECEIVED';
export const FETCHING_LIST = 'FETCHING_LIST';
export const DISMISS_FEATURE_CARD = 'DISMISS_FEATURE_CARD';
export const UPDATE_PRODUCT_DATA_BY_SLUG = 'UPDATE_PRODUCT_DATA_BY_SLUG';
export const STORE_DATA_RECEIVED = 'STORE_DATA_RECEIVED';
export const FETCH_STORE = 'FETCH_STORE';
export const FETCH_ARTIST = 'FETCH_ARTIST';
export const UPDATE_ARTIST_DATA = 'UPDATE_ARTIST_DATA';
export const UPDATE_ARTIST_DATA_BY_SLUG = 'UPDATE_ARTIST_DATA_BY_SLUG';
export const VIEW_ARTIST_STORY = 'VIEW_ARTIST_STORY';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const FOLLOW_ARTIST = 'FOLLOW_ARTIST';
export const UNFOLLOW_ARTIST = 'UNFOLLOW_ARTIST';
export const FOLLOW_BRAND = 'FOLLOW_BRAND';
export const UNFOLLOW_BRAND = 'UNFOLLOW_BRAND';
export const FETCH_BRAND = 'FETCH_BRAND';
export const FETCH_STORIES = 'FETCH_STORIES';
export const BRAND_DATA_RECEIVED = 'BRAND_DATA_RECEIVED';
export const UPDATE_BRAND_DATA = 'UPDATE_BRAND_DATA';
export const UPDATE_BRAND_DATA_BY_SLUG = 'UPDATE_BRAND_DATA_BY_SLUG';
export const LIKE = 'LIKE';
export const UNLIKE = 'UNLIKE';
export const FETCH_RELATED_CONTENT = 'FETCH_RELATED_CONTENT';
export const RELATED_CONTENT_RECEIVED = 'FETCH_RELATED_RECEIVED';
export const UPDATE_RELATED_CONTENT = 'UPDATE_RELATED_CONTENT';
export const FOLLOW_ARTIST_FAILED = 'FOLLOW_ARTIST_FAILED';
export const UNFOLLOW_ARTIST_FAILED = 'UNFOLLOW_ARTIST_FAILED';
export const LIKE_ITEM_FAILED = 'LIKE_ITEM_FAILED';
export const UNLIKE_ITEM_FAILED = 'UNLIKE_ITEM_FAILED';
export const RATE_PRODUCT = 'RATE_PRODUCT';
export const VIEW_ITEM = 'VIEW_ITEM';
export const SEARCH_ITEM = 'SEARCH_ITEM';
export const UPDATE_SEARCH_HISTORY = 'UPDATE_SEARCH_HISTORY';
export const UPDATE_SEARCH_ID = 'UPDATE_SEARCH_ID';
export const EMPTY_SEARCH = 'EMPTY_SEARCH';
export const EMPTY_SEARCH_DATA_RECEIVED = 'EMPTY_SEARCH_DATA_RECEIVED';
export const SEARCH_SUGGESTION = 'SEARCH_SUGGESTION';
export const SEARCH_SUGGESTIONS_RECEIVED = 'SEARCH_SUGGESTIONS_RECEIVED';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const CLEAR_SEARCH_HISTORY = 'CLEAR_SEARCH_HISTORY';
export const CLEAR_SEARCH_SUGGESTION = 'CLEAR_SEARCH_SUGGESTION';
export const GET_ARTIST_STORY = 'GET_ARTIST_STORY';
export const UPDATE_ARTIST_STORY_LIST = 'UPDATE_ARTIST_STORY_LIST';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';
export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const REMOVE_ADDRESS_SUCCESS = 'REMOVE_ADDRESS_SUCCESS';
export const SAVE_CART_ITEMS = 'SAVE_CART_ITEMS';
export const LAST_PAYMENT_MODE = 'LAST_PAYMENT_MODE';
export const GET_ARTIST_TYPES = 'GET_ARTIST_TYPES';
export const GET_BRANDS_FOR_ARTIST = 'GET_BRANDS_FOR_ARTIST';
export const GET_INTERESTS_FOR_ARTIST = 'GET_INTERESTS_FOR_ARTIST';
export const GET_SERVICES_FOR_ARTISTS = 'GET_SERVICES_FOR_ARTISTS';
export const GET_CURRENT_LOCATION_VIA_MAP = 'GET_CURRENT_LOCATION_VIA_MAP';
export const SEARCH_PLACE_BY_TEXT = 'SEARCH_PLACE_BY_TEXT';
export const GET_SELECTED_YOUTUBE_CHANNEL_ID =
  'GET_SELECTED_YOUTUBE_CHANNEL_ID';
export const GET_SELECTED_YOUTUBE_CHANNEL_ID_PROFILE =
  'GET_SELECTED_YOUTUBE_CHANNEL_ID_PROFILE';
export const GET_GOOGLE_PROFILE = 'GET_GOOGLE_PROFILE';
export const SAVE_GOOGLE_DATA = 'SAVE_GOOGLE_DATA';
export const NEAR_BY_STORE = 'GET_NEARBY_STORE';
export const NEAR_BY_ACADEMIES = 'GET_NEARBY_ACADEMIES';
export const ARTIST_ONBOARDING_COMPLETED = 'ARTIST_ONBOARDING_COMPLETED';
export const GET_INSTAGRAM_PROFILE = 'INSTAGRAM_PROFILE';
export const UPLOAD_CONTACTS = 'UPLOAD_CONTACTS';
export const UPLOAD_CONTACTS_V3 = 'UPLOAD_CONTACTS_V3';
export const INSTAGRAM_PROFILE_VERIFY = 'INSTAGRAM_PROFILE_VERIFY';
export const GET_CAMPAIGN_DATA = 'GET_CAMPAIGN_DATA';
export const MY_PROFILE_DETAILS = 'MY_PROFILE_DETAILS';
export const GET_LATEST_MEDIA = 'GET_LATEST_MEDIA';
export const GET_POPULAR_MEDIA = 'GET_POPULAR_MEDIA';
export const GET_POST_DETAILS = 'GET_POST_DETAILS';
export const FETCH_PRODUCT_BY_SLUG = 'FETCH_PRODUCT_BY_SLUG';
export const MY_REFERRALS = 'GET_REFERRALS';
export const GET_USER_UPLOAD_FOR_CAMPAIGN = 'GET_USER_UPLOAD_FOR_CAMPAIGN';
export const SAVE_REFFERAL_CODE = 'SAVE_REFFERAL_CODE';
export const UPDATE_CAMPAIGN_DATA = 'UPDATE_CAMPAIGN_DATA';
export const SAVE_YOUTUBE_REFRESH_TOKEN = 'SAVE_YOUTUBE_REFRESH_TOKEN';
export const DELETE_MEDIA = 'DELETE_MEDIA';
export const GET_SUBSCRIBER_COUNT = 'GET_SUBSCRIBER_COUNT';
export const STORE_INSTAGRAM_FOLLOWER_COUNT = 'STORE_INSTAGRAM_FOLLOWER_COUNT';
export const STORE_YOUTUBE_SUBSCRIBER_COUNT = 'STORE_YOUTUBE_SUBSCRIBER_COUNT';
export const YOUTUBE_CHANNEL_ANALYSIS = 'YOUTUBE_CHANNEL_ANALYSIS';
export const REGISTER_CAMPAIGN = 'REGISTER_CAMPAIGN';
export const REGISTER_CAMPAIGN_SUCCESS = 'REGISTER_CAMPAIGN_SUCCESS';
export const CANCEL_CAMPAIGN = 'CANCEL_CAMPAIGN';
export const CANCEL_CAMPAIGN_SUCCESS = 'CANCEL_CAMPAIGN_SUCCESS';
export const UPDATE_ARTIST_PROFILE = 'UPDATE_ARTIST_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const GET_INSTAGRAM_POST = 'GET_INSTAGRAM_POST';
export const GET_CONTENT_FOR_ARTIST = 'GET_CONTENT_FOR_ARTIST';
export const ARTIST_RESPONSE_SUCCESS = 'ARTIST_RESPONSE_SUCCESS';
export const REFRESH_MY_PROFILE = 'REFRESH_MY_PROFILE';
export const BULK_ADD_TO_CART = 'BULK_ADD_TO_CART';
export const GET_CART_ITEMS = 'GET_CART_ITEMS';
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';
export const APPLY_CART_COUPON = 'APPLY_CART_COUPON';
export const APPLY_CART_COUPON_SUCCESS = 'APPLY_CART_COUPON_SUCCESS';
export const FETCH_LIST_DETAIL = 'FETCH_LIST_DETAIL';
export const VERIFY_HANDLE_CALLBACK = ' VERIFY_HANDLE_CALLBACK';
export const POST_ARTIST_TYPE = 'POST_ARTIST_TYPE';
export const SAVE_ARTIST_TYPE_LOCAL = 'SAVE_ARTIST_TYPE_LOCAL';
export const GET_YOUTUBE_VIEWS_ANALYTICS = 'GET_YOUTUBE_VIEWS_ANALYTICS';
export const SAVE_BRANDS = 'SAVE_BRANDS';
export const GET_ARTIST_GIFT = 'GET_ARTIST_GIFT';
export const SAVE_BRANDS_SUCCESS = 'SAVE_BRANDS_SUCCESS';
export const REQUEST_BOOST = 'REQUEST_BOOST';
export const REQUEST_EXPERT_REVIEW = 'REQUEST_EXPERT_REVIEW';
export const TEST = 'TEST';
export const SAVE_OFFERINGS = 'SAVE_OFFERINGS';
export const SAVE_OFFERINGS_SUCCESS = 'SAVE_OFFERINGS_SUCCESS';
export const SAVE_TRAININGS = 'SAVE_TRAININGS';
export const SAVE_TRAININGS_SUCCESS = 'SAVE_TRAININGS_SUCCESS';
export const GET_YOUTUBE_RECOMMENDATIONS = 'YOUTUBE_RECOMMENDATIONS';
export const SAVE_SALON = 'SAVE_SALON';
export const SAVE_FREELANCER_PROFILE = 'SAVE_FREELANCER_PROFILE';
export const SAVE_BEAUTY_ADVISORY_PROFILE = 'SAVE_BEAUTY_ADVISORY_PROFILE';
export const SAVE_TRAINER_PROFILE = 'SAVE_TRAINER_PROFILE';
export const SAVE_STUDENT_PROFILE = 'SAVE_STUDENT_PROFILE';
export const GET_VIDEOS_IN_REVIEW = 'GET_VIDEOS_IN_REVIEW';
export const GET_INSTA_HANDLE_HINT = 'GET_INSTA_HANDLE_HINT';
export const COLLECT_CASH = 'COLLECT_CASH';
export const SEND_INSTAGRAMDATA_FOR_ACCOUNTVERIFICATION =
  'SEND_INSTAGRAMDATA_FOR_ACCOUNTVERIFICATION';
export const LAST_VIDEO_UPLOAD = 'LAST_VIDEO_UPLOAD';
export const NUM_OF_UPLOAD_RETRIES = 'NUM_OF_UPLOAD_RETRIES';
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const TEMP_STORE_INSTA_HANDLE = 'TEMP_STORE_INSTA_HANDLE';
export const DELETE_DRAFT = 'DELETE_DRAFT';
export const SAVE_RESUMABLE_INDEX = 'SAVE_RESUMABLE_INDEX';
export const ALL_ARTIST_TYPES = 'ALL_ARTIST_TYPES';
export const INSTA_VERIFICATION_LAST_INITIATED_AT =
  'INSTA_VERIFICATION_LAST_INITIATED_AT';
export const SAVE_FREELANCER_PROFILE_LOCAL = 'SAVE_FREELANCER_PROFILE_LOCAL';
export const SAVE_SALON_LOCAL = 'SAVE_SALON_LOCAL';
export const SAVE_STORE_LOCAL = 'SAVE_STORE_LOCAL';
export const SAVE_STUDENT_ACADEMY_LOCAL = 'SAVE_STUDENT_ACADEMY_LOCAL';
export const SAVE_ACADEMY_LOCAL = 'SAVE_ACADEMY_LOCAL';
export const SAVE_ARTIST_GIFT = 'SAVE_ARTIST_GIFT';
export const GET_STATUS_MESSAGE = 'GET_STATUS_MESSAGE';
export const SAVE_STATUS_MESSAGE = 'SAVE_STATUS_MESSAGE';
export const UNLINK_YOUTUBE_PROFILE = 'UNLINK_YOUTUBE_PROFILE';
export const RESET_YOUTUBE_ACCOUNT_LOCAL = 'RESET_YOUTUBE_ACCOUNT_LOCAL';
export const RENDER_ONBOARDING = 'RENDER_ONBOARDING';
export const ADD_INVITE_CODE = 'ADD_INVITE_CODE';
export const ADD_INVITE_CODE_SUCCESS = 'ADD_INVITE_CODE_SUCCESS';
export const CANCEL_UPLOAD = 'CANCEL_UPLOAD';
export const STOP_UPLOADING = 'STOP_UPLOADING';
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL';
export const GET_ADDRESS_DETAIL = 'GET_ADDRESS_DETAIL';
export const SAVE_CART_ITEM = 'SAVE_CART_ITEM';
export const SAVE_CART = 'SAVE_CART';
export const GET_RECOMMENDED_VARIANTS = 'GET_RECOMMENDED_VARIANTS';
export const GET_CURRENT_PINCODE = 'GET_CURRENT_PINCODE';
export const SAVE_CURRENT_PINCODE = 'SAVE_CURRENT_PINCODE';
export const GET_DELIVERY_TIME = 'GET_DELIVERY_TIME';
export const CANCEL_ORDER_ITEM = 'CANCEL_ORDER_ITEM';
export const SEARCH_AUTO_SUGGEST = 'SEARCH_AUTO_SUGGEST';
export const RATE_DELIVERY = 'RATE_DELIVERY';
export const GET_VARIANTS_FOR_PRODUCT = 'GET_VARIANTS_FOR_PRODUCT';
export const FETCH_PRODUCT_CAMERA = 'FETCH_PRODUCT_CAMERA';
export const GET_CARD_DETAILS = 'GET_CARD_DETAILS';
export const FETCH_COLLECTION_RAILS = 'FETCH_COLLECTION_RAILS';
export const GET_ONGOING_SHIPMENTS = 'GET_ONGOING_SHIPMENTS';
export const INSTAGRAM_POST_COMMENTS = 'INSTAGRAM_POST_COMMENTS';
export const GET_ALL_ARTIST_TYPES = 'GET_ALL_ARTIST_TYPES';
export const SAVE_ALL_ARTIST_TYPES = 'SAVE_ALL_ARTIST_TYPES';
export const GET_CAMPAIGN_DETAIL = 'GET_CAMPAIGN_DETAIL';
export const SAVE_REMOTE_CONFIG = 'SAVE_REMOTE_CONFIG';
export const LAST_REMOTE_CACHE_REFRESHED_AT = 'LAST_REMOTE_CACHE_REFRESHED_AT';
export const GET_CART_DATA = 'GET_CART_DATA';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_NEW_ORDER = 'CREATE_NEW_ORDER';
export const FETCH_CART_EMPTY_STATE = 'FETCH_CART_EMPTY_STATE';
export const GET_ARTIST_CTA_OPTIONS = 'GET_ARTIST_CTA_OPTIONS';
export const SAVE_ARTIST_CTA_OPTIONS = 'SAVE_ARTIST_CTA_OPTIONS';
export const GET_MY_COLLECTION = 'GET_MY_COLLECTION';
export const SEND_SCAN_PRODUCT_DATA = 'SEND_SCAN_PRODUCT_DATA';
export const FETCH_LIST_DETAIL_WITH_CALLBACK =
  'FETCH_LIST_DETAIL_WITH_CALLBACK';
export const GET_ALL_VIDEOS = 'GET_ALL_VIDEOS';
export const CREATE_BOOKING = 'CREATE_BOOKING';
export const GET_ALL_EMOJIS = 'GET_ALL_EMOJIS';
export const SAVE_ALL_EMOJIS = 'SAVE_ALL_EMOJIS';
export const SAVE_LAST_USED_ADDRESS = 'SAVE_LAST_USED_ADDRESS';
export const GET_PRODUCT_SERVICEABILITY = 'GET_PRODUCT_SERVICEABILITY';
export const PAGINATED_MORE_SEARCH_RESULT = 'PAGINATED_MORE_SEARCH_RESULT';
export const SAVE_UPI_DETAILS = 'SAVE_UPI_DETAILS';
export const UPDATE_SURPRISE_LAST_TAKEN = 'UPDATE_SURPRISE_LAST_TAKEN';
export const GET_CART_OFFERS = 'GET_CART_OFFERS';
export const POST_DEVICE_INFORMATION = 'POST_DEVICE_INFORMATION';
export const LOGOUT_USER = 'LOGOUT_USER';
export const PINCODE_INFO = 'PINCODE_INFO';
export const SAVE_MY_KIT_DATA = 'SAVE_MY_KIT_DATA';
export const REMOVE_OFFER = 'REMOVE_OFFER';
export const REMOVE_LAST_PAYMENT_MODE = 'REMOVE_LAST_PAYMENT_MODE';
export const GET_SAVED_UPI = 'GET_SAVED_UPI';
export const SAVE_UPI_IN_REDUX = 'SAVE_UPI_IN_REDUX';
export const SAVE_CARDS_IN_REDUX = 'SAVE_CARDS_IN_REDUX';
export const SAVE_NETBANKING_IN_REDUX = 'SAVE_NETBANKING_IN_REDUX';
export const GET_NETBANKING_DETAILS = 'GET_NETBANKING_DETAILS';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const GET_LIST_DETAILS = 'GET_LIST_DETAILS';
export const GET_OFFER_PROMPT = 'GET_OFFER_PROMPT';
export const ADD_FREE_ITEM_TO_CART = 'ADD_FREE_ITEM_TO_CART';
export const REMOVE_FREE_ITEM_TO_CART = 'REMOVE_FREE_ITEM_TO_CART';
export const RESET_FREE_ITEMS_IN_CART = 'RESET_FREE_ITEMS_IN_CART';
export const PRODUCT_VIEW = 'PRODUCT_VIEW';
export const SIMILAR_PRODUCTS = 'SIMILAR_PRODUCTS';
export const SIMILAR_PRODUCTS_LIST_RECEIVED = 'SIMILAR_PRODUCTS_LIST_RECEIVED';
export const VIDEO_VIEW = 'VIDEO_VIEW';
export const GET_USER_LATEST_VIDEOS = 'GET_USER_LATEST_VIDEOS';
export const VIDEO_WATCHED = 'VIDEO_WATCHED';
export const FETCH_PRODUCT_REVIEWS = 'FECTH_PRODUCT_REVIEWS';
export const CALCULATE_UAC_SCORE_APP_DEVICE_INFO =
  'CALCULATE_UAC_SCORE_APP_DEVICE_INFO';

export const UPDATE_UAC_SCORE = 'UPDATE_UAC_SCORE';
export const UPDATE_ARTIST_INSTAGRAM_PROFILE =
  'UPDATE_ARTIST_INSTAGRAM_PROFILE';

export const USER_S1_EVENT_TRIGGERED = 'USER_S1_EVENT_TRIGGERED';
export const USER_S2_EVENT_TRIGGERED = 'USER_S2_EVENT_TRIGGERED';
export const USER_S3_EVENT_TRIGGERED = 'USER_S3_EVENT_TRIGGERED';
export const SET_VIDEO_UPLOAD_PROGRESS = 'SET_VIDEO_UPLOAD_PROGRESS';
export const SET_VIDEO_UPLOAD_STATUS = 'SET_VIDEO_UPLOAD_STATUS';
export const GET_ARTIST_STORY_RECOMMENDATION =
  'GET_ARTIST_STORY_RECOMMENDATION';

export const SET_STORY_ITEM_RECOMMENDATION = 'SET_STORY_ITEM_RECOMMENDATION';
export const FOLLOW_RECOMMENDATIONS = 'FOLLOW_RECOMMENDATIONS';
export const REFRESH_STORY_RAILS = 'REFRESH_STORY_RAILS';
export const COMBINED_STORY_VIEW = 'COMBINED_STORY_VIEW';
export const FOLLOWED_STORIES = 'FOLLOWED_STORIES';
export const UNFOLLOW_STORIES = 'UNFOLLOW_STORIES';

export const ADD_FREE_PRODUCT_TO_CART = 'ADD_FREE_PRODUCT_TO_CART';
export const GET_FREE_ITEM_FOR_OFFER = 'GET_FREE_ITEM_FOR_OFFER';
export const SAVE_FREE_OFFER_ITEM = 'SAVE_FREE_OFFER_ITEM';
export const GET_OFFER_REWARDS = 'GET_OFFER_REWARDS';
export const UAC_GENDER_EVENT_TRIGGERED = 'UAC_GENDER_EVENT_TRIGGERED';
export const UAC_SELFIE_EVENT_TRIGGERED = 'UAC_SELFIE_EVENT_TRIGGERED';
export const UAC_DEVICE_EVENT_TRIGGERED = 'UAC_DEVICE_EVENT_TRIGGERED';
export const UAC_COMMERCE_APP_EVENT_TRIGGERED =
  'UAC_COMMERCE_APP_EVENT_TRIGGERED';
export const UAC_BEAUTY_APP_EVENT_TRIGGERED = 'UAC_BEAUTY_APP_EVENT_TRIGGERED';
export const SAVE_GENDER = 'SAVE_GENDER';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const FREE_PRODUCT_MODAL_OPENED = 'FREE_PRODUCT_MODAL_OPENED';
export const GET_USER_ALL_ADDRESSES = 'GET_USER_ALL_ADDRESSES';
export const SAVE_ALL_ADDRESSES = 'SAVE_ALL_ADDRESSES';
export const FETCH_TAG_DATA = 'FETCH_TAG_DATA';

export const OPT_FOR_PLASTIC_FREE_PACKAGING = 'OPT_FOR_PLASTIC_FREE_PACKAGING';

export const IS_SPECIAL_OFFER_APPLIED = 'IS_SPECIAL_OFFER_APPLIED';
export const SAVE_INTENT_DETAILS = 'SAVE_INTENT_DETAILS';
export const VALIDATE_UPI = 'VALIDATE_UPI';
export const DELETE_CARD = 'DELETE_CARD';

export const CHECK_UPI_STATUS = 'CHECK_UPI_STATUS';
export const SET_DEFAULT_ADDRESSES = 'SET_DEFAULT_ADDRESSES';
export const SAVE_ANDY_WARHOL_IMAGES = 'SAVE_ANDY_WARHOL_IMAGES';
export const GET_MY_WARHOL_IMAGES = 'GET_MY_WARHOL_IMAGES';

export const GET_FOXY_LIVE_ARTISTS = 'GET_FOXY_LIVE_ARTISTS';
export const GET_CART_PRICING = 'GET_CART_PRICING';
export const SAVE_CART_PRICING = 'SAVE_CART_PRICING';
export const INCREMENT_DECREMENT_FROM_CART = 'INCREMENT_DECREMENT_FROM_CART';
export const FETCH_OFFER_DETAILS = 'FETCH_OFFER_DETAILS';
export const FETCH_OFFER_DETAILS_CART_ITEMS = 'FETCH_OFFER_DETAILS_CART_ITEMS';
export const RENDER_LOADER = 'RENDER_LOADER';
export const SAVE_BAG = 'SAVE_BAG';
export const SAVE_BAG_ITEMS = 'SAVE_BAG_ITEMS';
export const SAVE_BAG_PROMPTS = 'SAVE_BAG_PROMPTS';
export const SAVE_BAG_PRICE = 'SAVE_BAG_PRICE';
export const SAVE_BAG_ITEM_AND_PROMPT = 'SAVE_BAG_ITEM_AND_PROMPT';
export const SAVE_BAG_PRICE_AND_PROMPT = 'SAVE_BAG_PRICE_AND_PROMPT';
export const SAVE_BAG_ITEM_AND_PRICE = 'SAVE_BAG_ITEM_AND_PRICE';
export const INCREMENT_DECREMENT_FROM_CART_BEFORE_CALL =
  'INCREMENT_DECREMENT_FROM_CART_BEFORE_CALL';
export const SET_UPGRADE_TO_PREPAID = 'SET_UPGRADE_TO_PREPAID';
export const SET_CONTACT_LESS = 'SET_CONTACT_LESS';
export const RENDER_OFFER_SHIMMER = 'RENDER_OFFER_SHIMMER';
export const UPDATE_PLASTIC_FREE_DATA = 'UPDATE_PLASTIC_FREE_DATA';
export const SWIPE_NEXT_CUEUE = 'SWIPE_NEXT_CUEUE';

export const LAST_NOTIFICATION_MODAL_DISPLAY_TIME =
  'LAST_NOTIFICATION_MODAL_DISPLAY_TIME';
export const COPY_CART_ITEMS = 'COPY_CART_ITEMS';
export const CREATE_NEW_ORDER_V2 = 'CREATE_NEW_ORDER_V2';
export const PAGINATED_LIST_CALLBACK = 'PAGINATED_LIST_CALLBACK';
export const SEND_SKU_PRICE_ALERT = 'SEND_SKU_PRICE_ALERT';
export const FETCH_PERSONALIZED_LIST = 'FETCH_PERSONALIZED_LIST';
export const SET_COD = 'SET_COD';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_SELFIE = 'DELETE_SELFIE';
export const HAS_UAC_DEVICE_EVENT_TRIGGERED = 'HAS_UAC_DEVICE_EVENT_TRIGGERED';
export const FETCH_LIST_DETAIL_LATEST = 'FETCH_LIST_DETAIL_LATEST';
export const USER_TODAY_DEALS = 'USER_TODAY_DEALS';
export const USER_TODAY_DEALS_WITH_CALLBACK = 'USER_TODAY_DEALS_WITH_CALLBACK';
export const ADD_TODAY_DEALS_TO_REDUX = 'ADD_TODAY_DEALS_TO_REDUX';
export const SWIPED_LEFT_OR_RIGHT_WISHLIST_BUILDER =
  'SWIPED_LEFT_OR_RIGHT_WISHLIST_BUILDER';
export const BUILD_LIST = 'BUILD_LIST';
export const APPLY_FLASH_DEAL = 'APPLY_FLASH_DEAL';
export const UPDATE_WISHLIST_TOOLTIP = 'UPDATE_WISHLIST_TOOLTIP';
export const ADD_LAST_LIKE = 'ADD_LAST_LIKE';
export const WISHLIST_CROSS_CLICKED = 'WISHLIST_CROSS_CLICKED';
export const GET_IOS_UPI_APPS = 'GET_IOS_UPI_APPS';
export const SHOULD_RENDER_REFERRAL_MODAL = 'SHOULD_RENDER_REFERRAL_MODAL';
export const GET_BOOSTED_OFFERS = 'GET_BOOSTED_OFFERS';
export const SAVE_BOOSTED_OFFERS = 'SAVE_BOOSTED_OFFERS';
export const ADD_ORDER_ACTION_CARD = 'ADD_ORDER_ACTION_CARD';
export const UPDATE_APP_RATING = 'UPDATE_APP_RATING';
export const SAVE_CART_DATA = 'SAVE_CART_DATA';
export const GET_ROUTINES_DISCOVERY = 'GET_ROUTINES_DISCOVERY';
export const GET_ROUTINES = 'GET_ROUTINES';
export const GET_ROUTINE_DETAIL = 'GET_ROUTINE_DETAIL';
export const ADD_CARRY_BAG_CHARGES = 'ADD_CARRY_BAG_CHARGES';
export const SET_SEARCH_RESULT_CLICKS = 'SET_SEARCH_RESULT_CLICKS';
export const OPT_FOR_CARRY_BAG = 'OPT_FOR_CARRY_BAG';
export const UPDATE_CARRY_BAG_DATA = 'UPDATE_CARRY_BAG_DATA';
export const SET_LAST_ZOOM_ANIMATION_VISIBLE_AT =
  'SET_LAST_ZOOM_ANIMATION_VISIBLE_AT';
export const CURRENT_ZOOM_ANIMATION_VISIBILITY_COUNTER =
  'CURRENT_ZOOM_ANIMATION_VISIBILITY_COUNTER';
export const GET_FAQ_DATA = 'GET_FAQ_DATA';
export const STORE_FAQ_LIKE_AND_DISLIKE_DATA =
  'STORE_FAQ_LIKE_AND_DISLIKE_DATA';
export const ADD_ORDER_REQUEST_DETAIL = 'ADD_ORDER_REQUEST_DETAIL';
export const GET_CONTACT_US_REASON = 'GET_CONTACT_US_REASON';
export const GET_RETURN_REPLACE_REASON = 'GET_RETURN_REPLACE_REASON';
export const ADD_RETURN_REPLACE_REASON_ID = 'ADD_RETURN_REPLACE_REASON_ID';
export const GET_CANCEL_REASON = 'GET_CANCEL_REASON';
export const GET_SHIPMENT_TRACKING_DETAILS = 'GET_SHIPMENT_TRACKING_DETAILS';
export const UPDATE_ORDER_REQUEST_DETAIL = 'UPDATE_ORDER_REQUEST_DETAIL';
export const GET_ACTIVE_CALLBACK = 'GET_ACTIVE_CALLBACK';
export const SAVE_LOCAL_NOTIFICATION = 'SAVE_LOCAL_NOTIFICATION';
export const UPDATE_LOCAL_NOTIFICATION = 'UPDATE_LOCAL_NOTIFICATION';
export const SET_APP_FIRST_OPENED = 'SET_APP_FIRST_OPENED';
export const SET_SHOW_EDGE_PENDING_INDICATOR =
  'SET_SHOW_EDGE_PENDING_INDICATOR';

export const SAVE_SHOPIFY_ADDRESS = 'SAVE_SHOPIFY_ADDRESS';
export const CREATE_ROUTINE_PARTICIPATION = 'CREATE_ROUTINE_PARTICIPATION';

export const CLEAR_WISHLIST_TOOLTIP = 'CLEAR_WISHLIST_TOOLTIP';
export const SAVE_MEMBERSHIP_COHORT = 'SAVE_MEMBERSHIP_COHORT';
export const ADD_TO_RECENTLY_VIEWED = 'ADD_TO_RECENTLY_VIEWED';
export const UPDATE_LAST_VISITED_PRODUCT_ID = 'UPDATE_LAST_VISITED_PRODUCT_ID';
export const TOGGLE_DYNAMIC_LIST_TITLE_VISIBILITY =
  'TOGGLE_DYNAMIC_LIST_TITLE_VISIBILITY';
export const ADD_EMAIL_TO_LOCAL_STORE = 'ADD_EMAIL_TO_LOCAL_STORE';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const SAVE_USER_LOCATION_INFO = 'SAVE_USER_LOCATION_INFO';

export function renderReferralModal(
  isReferralModalVisible,
  referralModalType = '',
) {
  return {
    type: SHOULD_RENDER_REFERRAL_MODAL,
    isReferralModalVisible,
    referralModalType,
  };
}
export const ADDED_TO_CART_PRODUCT = 'ADDED_TO_CART_PRODUCT';

export const APP_REVIEW_SUBMITTED = 'APP_REVIEW_SUBMITTED';
export const SAVE_BAG_MODAL_OPEN_TIME_STAMP_AND_APP_LAUNCH_STATE =
  'SAVE_BAG_MODAL_OPEN_TIME_STAMP_AND_APP_LAUNCH_STATE';

export const GET_USER_ATTRIBUTE_VALUES = 'GET_USER_ATTRIBUTE_VALUES';
export const POST_USER_ATTRIBUTE_VALUES = 'POST_USER_ATTRIBUTE_VALUES';
export const POST_SHOPIFY_ORDER_EMAIL = 'POST_SHOPIFY_ORDER_EMAIL';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const GET_SHOPIFY_ORDERS_HISTORY = 'GET_SHOPIFY_ORDERS_HISTORY';
export const GET_SHOPIFY_ORDERS_DETAILS = 'GET_SHOPIFY_ORDERS_DETAILS';
export const DOES_SHOPIFY_ADDRESS_EXIST = 'DOES_SHOPIFY_ADDRESS_EXIST';
export const FETCH_CART = 'FETCH_CART';
export const SET_CART_NOTIFICATION = 'SET_CART_NOTIFICATION';
export const SET_PERMISSION_STATUS = 'SET_PERMISSION_STATUS';
export const SAVE_PERSONALIZATION_ATTRIBUTES =
  'SAVE_PERSONALIZATION_ATTRIBUTES';
export const USER_REGISTERED_ON_CT = 'USER_REGISTERED_ON_CT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

export function saveBagModalOpenTimestampAndAppLaunchState(data) {
  return {
    type: SAVE_BAG_MODAL_OPEN_TIME_STAMP_AND_APP_LAUNCH_STATE,
    data,
  };
}

export function addOrderActionCard(cardData) {
  return {
    type: ADD_ORDER_ACTION_CARD,
    cardData,
  };
}

export function fetchList(
  data,
  page,
  callback = () => {},
  use_modified_product_list,
) {
  return {
    type: FETCH_LIST,
    data,
    page,
    callback,
    use_modified_product_list,
  };
}

export function fetchData(data, callback = () => {}) {
  return {
    type: FETCH_DATA,
    data,
    callback,
  };
}

export function buildList(productIds, skuIds, callback = () => {}) {
  return {
    type: BUILD_LIST,
    productIds,
    skuIds,
    callback,
  };
}

export function applyFlashDeal(data) {
  return {
    type: APPLY_FLASH_DEAL,
    data,
  };
}

export function addAddedToCartProduct(itemData) {
  return {
    type: ADDED_TO_CART_PRODUCT,
    data: { itemData },
  };
}

export function addTodayDealsToRedux(data) {
  return {
    type: ADD_TODAY_DEALS_TO_REDUX,
    data,
  };
}

export function addLastLike(data) {
  return {
    type: ADD_LAST_LIKE,
  };
}

export function wishlistCrossClicked() {
  return {
    type: WISHLIST_CROSS_CLICKED,
  };
}

export function fetchListFailure() {
  return {
    type: FETCH_LIST_FAILURE,
  };
}

export function swipedLeftOrRightWishlistBuilder(id) {
  return {
    type: SWIPED_LEFT_OR_RIGHT_WISHLIST_BUILDER,
    data: {
      id,
    },
  };
}

export function isSpecialOfferApplied(isApplied) {
  return {
    type: IS_SPECIAL_OFFER_APPLIED,
    isApplied,
  };
}

export function dismissFeatureCard(card_details) {
  return {
    type: DISMISS_FEATURE_CARD,
    card_details,
  };
}

export function setDefaultAddresses(addressId, callback, addressType) {
  return {
    type: SET_DEFAULT_ADDRESSES,
    addressId,
    callback,
    addressType,
  };
}

export function fetchProduct(slug, callback = () => {}) {
  return {
    type: FETCH_PRODUCT,
    payload: slug,
    callback,
  };
}

export function fetchProductReviews(slug, callback) {
  return {
    type: FETCH_PRODUCT_REVIEWS,
    payload: slug,
    callback,
  };
}

export function optedForPlasticFreePackaging(optForPlasticFree, callback) {
  return {
    type: OPT_FOR_PLASTIC_FREE_PACKAGING,
    optForPlasticFree,
    callback,
  };
}

export function addFreeProductToCart(skuId, offerId, callback) {
  return {
    type: ADD_FREE_PRODUCT_TO_CART,
    skuId,
    offerId,
    callback,
  };
}

export function getFreeItemForOffer(offerID) {
  return {
    type: GET_FREE_ITEM_FOR_OFFER,
    offerID,
  };
}

export function freeProductModalOpen(opened) {
  return {
    type: FREE_PRODUCT_MODAL_OPENED,
    opened,
  };
}

// export function freeProductModalShown()

export function fetchLatestVideos(id, callback) {
  return {
    type: GET_USER_LATEST_VIDEOS,
    id,
    callback,
  };
}

export function videoWatched(id) {
  return {
    type: VIDEO_WATCHED,
    id,
  };
}

export function fetchProductInCamera(
  slug,
  callback1 = () => {},
  callback2 = () => {},
) {
  return {
    type: FETCH_PRODUCT_CAMERA,
    payload: slug,
    callback1,
    callback2,
  };
}

export function fetchArtist(slug, callback = () => {}) {
  return {
    type: FETCH_ARTIST,
    payload: slug,
    callback,
  };
}

export function fetchStore(callback) {
  return {
    type: FETCH_STORE,
    callback,
  };
}

export function viewPublisherStory(artist) {
  return {
    type: VIEW_ARTIST_STORY,
    payload: artist,
  };
}

export function addToCart(data, callback = () => {}) {
  return {
    type: ADD_TO_CART,
    data,
    callback,
  };
}

export function removeFromCart(data, callback) {
  return {
    type: REMOVE_FROM_CART,
    data,
    callback,
  };
}

export function incrementDecrementCart(data, callback) {
  return {
    type: INCREMENT_DECREMENT_FROM_CART,
    data,
    callback,
  };
}

export function incrementDecrementCartBeforeCall(data) {
  return {
    type: INCREMENT_DECREMENT_FROM_CART_BEFORE_CALL,
    data,
  };
}

export function validateUpi(vpa, callback) {
  return {
    type: VALIDATE_UPI,
    vpa,
    callback,
  };
}

export function checkUpiStatus(txId, callback) {
  return {
    type: CHECK_UPI_STATUS,
    txId,
    callback,
  };
}

export function followArtist(data) {
  return {
    type: FOLLOW_ARTIST,
    data,
  };
}

export function followBrand(data) {
  return {
    type: FOLLOW_BRAND,
    data,
  };
}

export function deleteCard(cardToken, callback) {
  return {
    type: DELETE_CARD,
    cardToken,
    callback,
  };
}

export function unFollowArtist(data) {
  return {
    type: UNFOLLOW_ARTIST,
    data,
  };
}

export function setCod(isCod) {
  return {
    type: SET_COD,
    data: { isCod },
  };
}

export function unFollowBrand(data) {
  return {
    type: UNFOLLOW_BRAND,
    data,
  };
}

export function fetchBrand(slug, callback) {
  return {
    type: FETCH_BRAND,
    payload: slug,
    callback,
  };
}

export function fetchStories(slug, callback) {
  return {
    type: FETCH_STORIES,
    payload: slug,
    callback,
  };
}

export function fetchRelatedContent(slug) {
  return {
    type: FETCH_RELATED_CONTENT,
    payload: slug,
  };
}

export function like(data, callback = () => {}) {
  return {
    type: LIKE,
    data,
    callback,
  };
}

export function updateUserData(userData) {
  return {
    type: UPDATE_USER_DATA,
    userData,
  };
}

export function updateAppRating(userData) {
  return {
    type: UPDATE_APP_RATING,
    userData,
  };
}

export function getUserTodayDeals(callback) {
  return {
    type: USER_TODAY_DEALS,
    callback,
  };
}

export function getUserTodayDealsWithCallback(callback) {
  return {
    type: USER_TODAY_DEALS_WITH_CALLBACK,
    callback,
  };
}

export function unLike(data) {
  return {
    type: UNLIKE,
    data,
  };
}

export function followArtistFailed(data) {
  return {
    type: FOLLOW_ARTIST_FAILED,
    data,
  };
}

export function unfollowArtistFailed(data) {
  return {
    type: UNFOLLOW_ARTIST_FAILED,
    data,
  };
}

export function likeItemFailed(data) {
  return {
    type: LIKE_ITEM_FAILED,
    data,
  };
}
export function unlikeItemFailed(data) {
  return {
    type: UNLIKE_ITEM_FAILED,
    data,
  };
}

export function rateProduct(data, callback) {
  return {
    type: RATE_PRODUCT,
    data,
    callback,
  };
}

export function viewItem(data) {
  return {
    type: VIEW_ITEM,
    data,
  };
}

export function searchItem(
  data,
  isPreviousScreenScanProduct = false,
  showProductsAndBrandsOnly,
  hideOosProduct = false,
  callback,
) {
  console.log('isPreviousScreenScanProduct', isPreviousScreenScanProduct);
  return {
    type: SEARCH_ITEM,
    data,
    isPreviousScreenScanProduct,
    showProductsAndBrandsOnly,
    hideOosProduct,
    callback,
  };
}

export function getEmptySearchData(callback) {
  return {
    type: EMPTY_SEARCH,
    callback,
  };
}

export function setStoryViewed(data, callback) {
  return {
    type: COMBINED_STORY_VIEW,
    data,
    callback,
  };
}

export function getSimilarProducts(productId, callback) {
  return {
    type: SIMILAR_PRODUCTS,
    id: productId,
    callback,
  };
}

export function getSearchSuggestions(text) {
  return {
    type: SEARCH_SUGGESTION,
    payload: text,
  };
}

export function sendSkuPriceAlert(id, callback) {
  return {
    type: SEND_SKU_PRICE_ALERT,
    id,
    callback,
  };
}

export function clearSearchSuggestions() {
  return {
    type: CLEAR_SEARCH_SUGGESTION,
  };
}

export function updateAddress(data, callback) {
  return {
    type: UPDATE_ADDRESS,
    data,
    callback,
  };
}

export function clearSearchHistory() {
  return {
    type: CLEAR_SEARCH_HISTORY,
  };
}

export function clearSalonSearchHistory() {
  return {
    type: CLEAR_SALON_SEARCH_HISTORY,
  };
}

export function getArtistStory(railId, pageNo, callback) {
  return {
    type: GET_ARTIST_STORY,
    railId,
    pageNo,
    callback,
  };
}

export function orderSuccess(cartItems) {
  return {
    type: ORDER_SUCCESS,
    data: cartItems,
  };
}

export function addAddress(data, callback) {
  return {
    type: ADD_ADDRESS,
    data,
    callback,
  };
}

export function removeAddress(data, callback) {
  return {
    type: REMOVE_ADDRESS,
    data,
    callback,
  };
}

export function saveCartItems(data) {
  return {
    type: SAVE_CART_ITEMS,
    data,
  };
}

export function getOfferRewards(offerID, callback) {
  return {
    type: GET_OFFER_REWARDS,
    offerID,
    callback,
  };
}

export function saveFreeOffersItems(data) {
  return {
    type: SAVE_FREE_OFFER_ITEM,
    data,
  };
}

export function updateSearchHistory(payload) {
  return {
    type: UPDATE_SEARCH_HISTORY,
    payload,
  };
}

export function updateSalonSearchHistory(payload) {
  return {
    type: UPDATE_SALON_SEARCH_HISTORY,
    payload,
  };
}

export function setLastPaymentMode(data) {
  return {
    type: LAST_PAYMENT_MODE,
    data,
  };
}

export function removeLastPaymentMode() {
  return {
    type: REMOVE_LAST_PAYMENT_MODE,
  };
}

export function getArtistTypes(callback) {
  return {
    type: GET_ARTIST_TYPES,
    callback,
  };
}

export function getBrandsForArtist(data, callback) {
  return {
    type: GET_BRANDS_FOR_ARTIST,
    data,
    callback,
  };
}

export function getInterestsForArtist(callback) {
  return {
    type: GET_INTERESTS_FOR_ARTIST,
    callback,
  };
}

export function getServicesForArtist(callback) {
  return {
    type: GET_SERVICES_FOR_ARTISTS,
    callback,
  };
}
export function getLocationViaGoogleMaps(latitute, longitute, callback) {
  return {
    type: GET_CURRENT_LOCATION_VIA_MAP,
    latitute,
    longitute,
    callback,
  };
}
export function searchPlaceText(text, callback) {
  return {
    type: SEARCH_PLACE_BY_TEXT,
    text,
    callback,
  };
}

export function getSelectedYoutubeChannelId(tokenType, accessToken, callback) {
  return {
    type: GET_SELECTED_YOUTUBE_CHANNEL_ID,
    tokenType,
    accessToken,
    callback,
  };
}

export function getSelectedYoutubeChannelIdForProfile(
  tokenType,
  accessToken,
  callback,
) {
  return {
    type: GET_SELECTED_YOUTUBE_CHANNEL_ID_PROFILE,
    tokenType,
    accessToken,
    callback,
  };
}

export function getGoogleProfileWithToken(accessToken, callback) {
  return {
    type: GET_GOOGLE_PROFILE,
    accessToken,
    callback,
  };
}

export function saveGoogleData(data, youtubeObject = null, callback) {
  return {
    type: SAVE_GOOGLE_DATA,
    data,
    youtubeObject,
    callback,
  };
}

export function getAcademiesNearBy(lat, longitude, searchQuery, callback) {
  return {
    type: NEAR_BY_ACADEMIES,
    lat,
    longitude,
    searchQuery,
    callback,
  };
}

export function getStoresNearBy(lat, longitude, searchQuery, callback) {
  return {
    type: NEAR_BY_STORE,
    lat,
    longitude,
    searchQuery,
    callback,
  };
}

export function artistOnboardingCompleted(data) {
  return {
    type: ARTIST_ONBOARDING_COMPLETED,
    data,
  };
}

export function getArtistInstagramProfile(instagramHandler, callback) {
  return {
    type: GET_INSTAGRAM_PROFILE,
    handler: instagramHandler,
    callback,
  };
}

export function uploadContacts(contactList, callback) {
  return {
    type: UPLOAD_CONTACTS,
    contactList,
    callback,
  };
}

export function uploadContactsV3(purpose, contactList, callback) {
  return {
    type: UPLOAD_CONTACTS_V3,
    purpose,
    contactList,
    callback,
  };
}

export function instagramProfileVerify(handle, callback) {
  return {
    type: INSTAGRAM_PROFILE_VERIFY,
    handle,
    callback,
  };
}
export function getCampaignData(data, callback) {
  return {
    type: GET_CAMPAIGN_DATA,
    data,
    callback,
  };
}

export function getCampaignDetail(data, callback) {
  return {
    type: GET_CAMPAIGN_DETAIL,
    data,
    callback,
  };
}

export function getMyProfileDetails(callback) {
  return {
    type: MY_PROFILE_DETAILS,
    callback,
  };
}
export function getPopularMedia(page, callback) {
  return {
    type: GET_POPULAR_MEDIA,
    page,
    callback,
  };
}

export function getLatestMedia(page, callback) {
  console.log('get latest media called');
  return {
    type: GET_LATEST_MEDIA,
    page,
    callback,
  };
}

export function fetchProductBySlug(slug, callback) {
  return {
    type: FETCH_PRODUCT_BY_SLUG,
    callback,
    slug,
  };
}
export function deleteMedia(postId, callback) {
  return {
    type: DELETE_MEDIA,
    postId,
    callback,
  };
}

export function getMyReferral(callback) {
  return {
    type: MY_REFERRALS,
    callback,
  };
}

export function getUserUploadForCampaign(campaignId, callback) {
  return {
    type: GET_USER_UPLOAD_FOR_CAMPAIGN,
    campaignId,
    callback,
  };
}

export function saveRefferalCode(referralCode) {
  return { type: SAVE_REFFERAL_CODE, referralCode };
}
export function updateProductData(itemData) {
  return {
    type: UPDATE_PRODUCT_DATA,
    data: itemData,
  };
}

export function saveYoutubeRefreshToken(
  refreshToken,
  accessToken,
  accessTokenExpirationDate,
) {
  return {
    type: SAVE_YOUTUBE_REFRESH_TOKEN,
    refreshToken,
    accessToken,
    accessTokenExpirationDate,
  };
}

export function getUserSubscribers() {
  return {
    type: GET_SUBSCRIBER_COUNT,
  };
}

export function storeInstagramFollowersCount(instagramProfileObject) {
  return {
    type: STORE_INSTAGRAM_FOLLOWER_COUNT,
    ...instagramProfileObject,
  };
}
export function storeYoutubeSubscriberCount(youtubeProfileObject) {
  return {
    type: STORE_YOUTUBE_SUBSCRIBER_COUNT,
    ...youtubeProfileObject,
  };
}
export function youtubeChannelAnalysis(callback) {
  return {
    type: YOUTUBE_CHANNEL_ANALYSIS,
    callback,
  };
}

export function registerCampaign(campaignId, callback) {
  return {
    type: REGISTER_CAMPAIGN,
    campaignId,
    callback,
  };
}

export function registerCampaignSuccess(campaignId) {
  return {
    type: REGISTER_CAMPAIGN_SUCCESS,
    campaignId,
  };
}

export function cancelCampaign(campaignId) {
  return {
    type: CANCEL_CAMPAIGN,
    campaignId,
  };
}

export function cancelCampaignSuccess(campaignId) {
  return {
    type: CANCEL_CAMPAIGN_SUCCESS,
    campaignId,
  };
}

export function updateArtistProfile(data) {
  return {
    type: UPDATE_ARTIST_PROFILE,
    data,
  };
}

export function editProfile(user, artist, callback) {
  return {
    type: EDIT_PROFILE,
    user,
    artist,
    callback,
  };
}

export function editProfileSuccess(user, artist) {
  return {
    type: EDIT_PROFILE_SUCCESS,
    user,
    artist,
  };
}
export function getInstagramPost(callback) {
  return {
    type: GET_INSTAGRAM_POST,
    callback,
  };
}

export function getArtistContent(slug) {
  return {
    type: GET_CONTENT_FOR_ARTIST,
    payload: slug,
  };
}

export function artistResponseSuccess() {
  return {
    type: ARTIST_RESPONSE_SUCCESS,
  };
}

export function refreshMyProfile() {
  return {
    type: REFRESH_MY_PROFILE,
  };
}

export function bulkAddToCart(data, callback) {
  return {
    type: BULK_ADD_TO_CART,
    data,
    callback,
  };
}

export function getCartItems(callback, showCartNotification = false) {
  return {
    type: GET_CART_ITEMS,
    callback,
    showCartNotification,
  };
}

export function fetchCart(callback, showCartNotification = false) {
  return {
    type: FETCH_CART,
    callback,
    showCartNotification,
  };
}

export function updateCartItems(data) {
  return {
    type: UPDATE_CART_ITEMS,
    data,
  };
}

export function applyCartCoupon(
  couponCode,
  callback,
  appliedFromDeeplink = false,
  force = false,
  source = 'cart',
  skuId = '',
) {
  return {
    type: APPLY_CART_COUPON,
    couponCode,
    callback,
    appliedFromDeeplink,
    force,
    source,
    skuId,
  };
}

export function fetchTagList(slug, currentPage = null, callback) {
  return {
    type: FETCH_LIST_DETAIL_WITH_CALLBACK,
    slug,
    currentPage,
    callback,
  };
}

export function fetchTagData(slug, currentPage = null, callback) {
  return {
    type: FETCH_TAG_DATA,
    slug,
    currentPage,
    callback,
  };
}

export function verifyHandleWithCallback(handle, callback) {
  return {
    type: VERIFY_HANDLE_CALLBACK,
    handle,
    callback,
  };
}
export function postArtistTypes(artistTypes) {
  return {
    type: POST_ARTIST_TYPE,
    artistTypes,
  };
}
export function saveArtistTypeLocal(artistTypes) {
  return {
    type: SAVE_ARTIST_TYPE_LOCAL,
    artistTypes,
  };
}

export function getYoutubeViewsAnalytics(dateObject, callback) {
  return {
    type: GET_YOUTUBE_VIEWS_ANALYTICS,
    dateObject,
    callback,
  };
}

export function saveBrandsForCollaboration(data) {
  return {
    type: SAVE_BRANDS,
    data,
  };
}

export function getArtistGift(callback) {
  return {
    type: GET_ARTIST_GIFT,
    callback,
  };
}

export function saveArtistOfferings(data, offeringObject) {
  return {
    type: SAVE_OFFERINGS,
    data,
    offeringObject,
  };
}

export function requestBoost(callback) {
  return {
    type: REQUEST_BOOST,
    callback,
  };
}

export function requestExpertReview(callback) {
  return {
    type: REQUEST_EXPERT_REVIEW,
    callback,
  };
}

export function saveArtistTrainings(data) {
  return {
    type: SAVE_TRAININGS,
    data,
  };
}
export function getYoutubeRecommendations(callback) {
  return {
    type: GET_YOUTUBE_RECOMMENDATIONS,
    callback,
  };
}

export function saveSalon(salonId) {
  return {
    type: SAVE_SALON,
    salonId,
  };
}
export function saveFreelancerProfile(payload) {
  return {
    type: SAVE_FREELANCER_PROFILE,
    payload,
  };
}
export function saveBeautyAdvisoryProfile(storeId) {
  return {
    type: SAVE_BEAUTY_ADVISORY_PROFILE,
    storeId,
  };
}
export function saveTrainerProfile(academyId) {
  return {
    type: SAVE_TRAINER_PROFILE,
    academyId,
  };
}

export function saveStudentProfile(academyId) {
  return {
    type: SAVE_STUDENT_PROFILE,
    academyId,
  };
}

export function getVideosInReview(callback) {
  return {
    type: GET_VIDEOS_IN_REVIEW,
    callback,
  };
}

export function fetchMorePageList(
  slug,
  pageNo = 0,
  addFacialAttributesInApiRequest = false,
  counterFilterPress = -1,
  callback = () => {},
) {
  return {
    type: FETCH_LIST_DETAIL,
    payload: slug,
    pageNo,
    callback,
    addFacialAttributesInApiRequest,
    counterFilterPress,
  };
}

export function paginatedMoreSearchResult(slug, page_no, callback) {
  return {
    type: PAGINATED_MORE_SEARCH_RESULT,
    slug,
    page_no,
    callback,
  };
}

export function getInstaHandleHint(callback) {
  return {
    type: GET_INSTA_HANDLE_HINT,
    callback,
  };
}

export function collectCash(campaignId, callback) {
  return {
    type: COLLECT_CASH,
    campaignId,
    callback,
  };
}
export function sendInstagramDataToServer(instagramProfile, callback) {
  return {
    type: SEND_INSTAGRAMDATA_FOR_ACCOUNTVERIFICATION,
    instagramProfile,
    callback,
  };
}

export function updateLastVideoUpload(data) {
  return {
    type: LAST_VIDEO_UPLOAD,
    data,
  };
}

export function setNumOfUploadRetries(numOfRetries) {
  return {
    type: NUM_OF_UPLOAD_RETRIES,
    numOfRetries,
  };
}

export function acceptTerms(isChecked) {
  return {
    type: ACCEPT_TERMS,
    isChecked,
  };
}

export function tempStoreInstaHandle(handle) {
  return {
    type: TEMP_STORE_INSTA_HANDLE,
    handle,
  };
}

export function deleteDraft(data) {
  return {
    type: DELETE_DRAFT,
    data,
  };
}
export function saveResumableIndex(lastOptinIndex, currentOptinStage) {
  return {
    type: SAVE_RESUMABLE_INDEX,
    lastOptinIndex,
    currentOptinStage,
  };
}
export function saveAllArtistTypes(artistTypes) {
  return {
    type: ALL_ARTIST_TYPES,
    artistTypes,
  };
}

export function instaVerificationLastIntiatedAt(time) {
  return {
    type: INSTA_VERIFICATION_LAST_INITIATED_AT,
    time,
  };
}

export function saveFreelancerProfileLocal(payload) {
  return {
    type: SAVE_FREELANCER_PROFILE_LOCAL,
    payload,
  };
}

export function saveSalonLocal(searchTerm, selectedId) {
  return {
    type: SAVE_SALON_LOCAL,
    searchTerm,
    selectedId,
  };
}

export function saveStoreLocal(searchTerm, selectedId) {
  return {
    type: SAVE_STORE_LOCAL,
    searchTerm,
    selectedId,
  };
}
export function saveStudentAcademyLocal(searchTerm, selectedId) {
  return {
    type: SAVE_STUDENT_ACADEMY_LOCAL,
    searchTerm,
    selectedId,
  };
}

export function saveAcademyLocal(searchTerm, selectedId) {
  return {
    type: SAVE_ACADEMY_LOCAL,
    searchTerm,
    selectedId,
  };
}

export function getStatusMessage() {
  return {
    type: GET_STATUS_MESSAGE,
  };
}

export function unlinkYoutubeProfile(callback) {
  return {
    type: UNLINK_YOUTUBE_PROFILE,
    callback,
  };
}

export function renderOnboarding(shouldRender) {
  return {
    type: RENDER_ONBOARDING,
    shouldRender,
  };
}

export function addInviteCode(inviteCode, callback) {
  return {
    type: ADD_INVITE_CODE,
    inviteCode,
    callback,
  };
}

export function cancelUpload() {
  return {
    type: CANCEL_UPLOAD,
  };
}

export function stopUploading() {
  return {
    type: STOP_UPLOADING,
  };
}

export function requestProduct(skuId, callback) {
  return {
    type: REQUEST_PRODUCT,
    skuId,
    callback,
  };
}

export function getOrders(pageNo, callback) {
  return {
    type: GET_ORDERS,
    pageNo,
    callback,
  };
}

export function getRecommendedVariants(id, callback) {
  return {
    type: GET_RECOMMENDED_VARIANTS,
    id,
    callback,
  };
}

export function getOrderDetail(orderId, callback) {
  return {
    type: GET_ORDER_DETAIL,
    orderId,
    page,
    callback,
  };
}

export function getAddressById(addressId, callback) {
  return {
    type: GET_ADDRESS_DETAIL,
    addressId,
    callback,
  };
}

export function getUsersAllAddress(callback) {
  return {
    type: GET_USER_ALL_ADDRESSES,
    callback,
  };
}

export function saveAllAddresses(data) {
  return {
    type: SAVE_ALL_ADDRESSES,
    data,
  };
}

export function getCurrentPincode(callback) {
  return {
    type: GET_CURRENT_PINCODE,
    callback,
  };
}

export function getDeliveryTime(pincode, skuId, callback) {
  return {
    type: GET_DELIVERY_TIME,
    pincode,
    skuId,
    callback,
  };
}

export function cancelOrderItemById(orderItemId, callback) {
  return {
    type: CANCEL_ORDER_ITEM,
    orderItemId,
    callback,
  };
}

export function saveCurrentPincode(data) {
  return {
    type: SAVE_CURRENT_PINCODE,
    data,
  };
}

export function getSearchAutoSuggest(query, callback) {
  return {
    type: SEARCH_AUTO_SUGGEST,
    query,
    callback,
  };
}
export function getVariantsForProduct(slug, offer_id, callback) {
  return {
    type: GET_VARIANTS_FOR_PRODUCT,
    slug,
    offer_id,
    callback,
  };
}

export function rateDelivery(id, rating, callback) {
  return {
    type: RATE_DELIVERY,
    id,
    rating,
    callback,
  };
}

export function getCardDetails(callback) {
  return {
    type: GET_CARD_DETAILS,
    callback,
  };
}
export function fetchCollectionEmptyState(slug, callback) {
  return {
    type: FETCH_COLLECTION_RAILS,
    slug,
    callback,
  };
}

export function onGoingShipments(callback) {
  return {
    type: GET_ONGOING_SHIPMENTS,
    callback,
  };
}

export function getInstagramPostComments(postUrl, callback) {
  return {
    type: INSTAGRAM_POST_COMMENTS,
    postUrl,
    callback,
  };
}

export function storeRemoteConfig(configData) {
  return {
    type: SAVE_REMOTE_CONFIG,
    configs: configData,
  };
}

export function lastRemoteCacheRefreshedAt(lastCacheRefreshedAt) {
  return {
    type: LAST_REMOTE_CACHE_REFRESHED_AT,
    lastCacheRefreshedAt,
  };
}

export function getCartData(callback) {
  return {
    type: GET_CART_DATA,
    callback,
  };
}

export function createOrder(
  pg,
  paymentMethod,
  isContactLessDelivery,
  addressId,
  orderId,
  upgradableToPrepaid,
  params,
  savePaymentMethod,
  callback,
) {
  return {
    type: CREATE_ORDER,
    pg,
    paymentMethod,
    isContactLessDelivery,
    addressId,
    orderId,
    upgradableToPrepaid,
    params,
    savePaymentMethod,
    callback,
  };
}

export function createNewOrder(payload, callback) {
  return {
    type: CREATE_NEW_ORDER,
    payload,
    callback,
  };
}

export function createNewOrderV2(payload, callback) {
  return {
    type: CREATE_NEW_ORDER_V2,
    payload,
    callback,
  };
}

export function fetchCartEmptyState(slug, callback) {
  return {
    type: FETCH_CART_EMPTY_STATE,
    slug,
    callback,
  };
}

export function getArtistCtaOptions() {
  return {
    type: GET_ARTIST_CTA_OPTIONS,
  };
}

export function getMyCollection(callback) {
  return {
    type: GET_MY_COLLECTION,
    callback,
  };
}

export function sendScanProductData(id, callback = () => {}) {
  return {
    type: SEND_SCAN_PRODUCT_DATA,
    id,
    callback,
  };
}

export function getAllVideos(
  id,
  page,
  dynamicDestinationSlug,
  callback = () => {},
) {
  return {
    type: GET_ALL_VIDEOS,
    id,
    page,
    dynamicDestinationSlug,
    callback,
  };
}

export function createBooking(booking, callback = () => {}) {
  return {
    type: CREATE_BOOKING,
    booking,
    callback,
  };
}

export function getAllEmojis() {
  return {
    type: GET_ALL_EMOJIS,
  };
}

export function saveLastUsedAddress(address) {
  return {
    type: SAVE_LAST_USED_ADDRESS,
    address,
  };
}

export function getProductServiceability(pincode, callback = () => {}) {
  return {
    type: GET_PRODUCT_SERVICEABILITY,
    pincode,
    callback,
  };
}

export function saveUpiDetails(data) {
  return {
    type: SAVE_UPI_DETAILS,
    data,
  };
}

export function saveIntentDetails(data) {
  return {
    type: SAVE_INTENT_DETAILS,
    data,
  };
}

export function getSavedUpiFromServer() {
  return {
    type: GET_SAVED_UPI,
  };
}

export function getNetbankingDetails(callback) {
  return {
    type: GET_NETBANKING_DETAILS,
  };
}

export function updateSurpriseLastTaken() {
  return {
    type: UPDATE_SURPRISE_LAST_TAKEN,
  };
}
export function getCartOffers(callback = () => {}) {
  return {
    type: GET_CART_OFFERS,
    callback,
  };
}

export function postDeviceInformation(device_information, callback) {
  const { ssid } = jsonParser(device_information) || {};

  return {
    type: POST_DEVICE_INFORMATION,
    device_information,
    deviceId: ssid,
    callback,
  };
}

export function pincodeInfo(pincode, callback) {
  return {
    type: PINCODE_INFO,
    pincode,
    callback,
  };
}

export function removeOffer(coupon_code, callback = () => {}) {
  return {
    type: REMOVE_OFFER,
    coupon_code,
    callback,
  };
}

export function cancelOrder(id, callback) {
  return {
    type: CANCEL_ORDER,
    id,
    callback,
  };
}

export function getListDetails(id, callback) {
  return {
    type: GET_LIST_DETAILS,
    id,
    callback,
  };
}

export function getOfferPrompt(
  entityType,
  entityId,
  callback,
  hasGroupDeal = false,
) {
  return {
    type: GET_OFFER_PROMPT,
    entityId,
    entityType,
    callback,
    hasGroupDeal,
  };
}

export function fetchOfferDetail(slug, currentPage = null, callback) {
  return {
    type: FETCH_LIST_DETAIL_WITH_CALLBACK,
    slug,
    currentPage,
    callback,
  };
}

export function fetchOfferDetails(slug, currentPage = null, callback) {
  return {
    type: FETCH_OFFER_DETAILS,
    slug,
    currentPage,
    callback,
  };
}

export function fetchOfferCartItems(slug, callback) {
  return {
    type: FETCH_OFFER_DETAILS_CART_ITEMS,
    slug,
    callback,
  };
}

export function fetchListDetail(slug, currentPage = null, callback) {
  return {
    type: FETCH_OFFER_DETAILS,
    slug,
    currentPage,
    callback,
  };
}

export function fetchFreeGifts(slug, currentPage = null, callback) {
  return {
    type: FETCH_LIST_DETAIL_WITH_CALLBACK,
    slug,
    currentPage,
    callback,
  };
}

export function addFreeItemsToCart(data) {
  return {
    type: ADD_FREE_ITEM_TO_CART,
    data,
  };
}

export function removeFreeItemToCart(data) {
  return {
    type: REMOVE_FREE_ITEM_TO_CART,
    data,
  };
}

export function resetFreeItemsInCart() {
  return {
    type: RESET_FREE_ITEMS_IN_CART,
    data: [],
  };
}

export function productView(productId) {
  return {
    type: PRODUCT_VIEW,
    productId,
  };
}

export function videoView(videoId) {
  return {
    type: VIDEO_VIEW,
    videoId,
  };
}

export function calculateUACAppDeviceScore() {
  return {
    type: CALCULATE_UAC_SCORE_APP_DEVICE_INFO,
  };
}

export function updateUACScore(score) {
  return {
    type: UPDATE_UAC_SCORE,
    score,
  };
}

export function updateArtistInstagramProfile(handle, callback) {
  return {
    type: UPDATE_ARTIST_INSTAGRAM_PROFILE,
    handle,
    callback,
  };
}
export function userS1EventTriggered(triggered) {
  return {
    type: USER_S1_EVENT_TRIGGERED,
    triggered,
  };
}

export function userS2EventTriggered(triggered) {
  return {
    type: USER_S2_EVENT_TRIGGERED,
    triggered,
  };
}

export function userS3EventTriggered(triggered) {
  return {
    type: USER_S3_EVENT_TRIGGERED,
    triggered,
  };
}

export function uacGenderEventTriggered(score) {
  return {
    type: UAC_GENDER_EVENT_TRIGGERED,
    score,
  };
}

export function saveGender(gender) {
  return {
    type: SAVE_GENDER,
    gender,
  };
}

export function uacSelfieEventTriggered(score) {
  return {
    type: UAC_SELFIE_EVENT_TRIGGERED,
    score,
  };
}

export function uacDeviceEventTriggered(score) {
  return {
    type: UAC_DEVICE_EVENT_TRIGGERED,
    score,
  };
}

export function uacCommerceAppEventTriggered(score) {
  return {
    type: UAC_COMMERCE_APP_EVENT_TRIGGERED,
    score,
  };
}

export function uacBeautyAppEventTriggered(score) {
  return {
    type: UAC_BEAUTY_APP_EVENT_TRIGGERED,
    score,
  };
}

export function setVideoUploadProgress(progress) {
  return {
    type: SET_VIDEO_UPLOAD_PROGRESS,
    progress,
  };
}

export function setVideoUploadStatus(status) {
  return {
    type: SET_VIDEO_UPLOAD_STATUS,
    status,
  };
}

export function getArtistStoryRecommendation(saveToRedux, callback) {
  return {
    type: GET_ARTIST_STORY_RECOMMENDATION,
    saveToRedux,
    callback,
  };
}

export function setStoryItemRecommendation(data) {
  return {
    type: SET_STORY_ITEM_RECOMMENDATION,
    data,
  };
}

export function followStories(data) {
  return {
    type: FOLLOWED_STORIES,
    data,
  };
}

export function unfollowStories(data) {
  return {
    type: UNFOLLOW_STORIES,
    data,
  };
}

export function followRecommendations(data, callback) {
  return {
    type: FOLLOW_RECOMMENDATIONS,
    data,
    callback,
  };
}

export function setNotificationModalDisplayTime(time) {
  return {
    type: LAST_NOTIFICATION_MODAL_DISPLAY_TIME,
    time,
  };
}

export function saveAndyWarholImages(filteredImagesArray) {
  return {
    type: SAVE_ANDY_WARHOL_IMAGES,
    filteredImagesArray,
  };
}

export const getAndyWarholImages = () => {
  return {
    type: GET_MY_WARHOL_IMAGES,
  };
};

export function getFoxyLiveArtists(api, callback) {
  return {
    type: GET_FOXY_LIVE_ARTISTS,
    api,
    callback,
  };
}

export function getCartPricing(callback = () => {}) {
  return {
    type: GET_CART_PRICING,
    callback,
  };
}

export function saveCartPricing(pricingData) {
  return {
    type: SAVE_CART_PRICING,
    data: pricingData,
  };
}

export function saveBag(cartData) {
  return {
    type: SAVE_BAG,
    data: cartData,
  };
}

export function saveBagItems(cartData) {
  return {
    type: SAVE_BAG_ITEMS,
    data: cartData,
  };
}

export function saveCartData(cartData) {
  return {
    type: SAVE_CART_DATA,
    data: cartData,
  };
}

export function saveBagPrompts(cartData) {
  return {
    type: SAVE_BAG_PROMPTS,
    data: cartData,
  };
}

export function saveBagPrice(cartData) {
  return {
    type: SAVE_BAG_PRICE,
    data: cartData,
  };
}

export function saveBagItemsAndPrompts(cartData) {
  return {
    type: SAVE_BAG_ITEM_AND_PROMPT,
    data: cartData,
  };
}

export function saveBagPriceAndPrompts(cartData) {
  return {
    type: SAVE_BAG_PRICE_AND_PROMPT,
    data: cartData,
  };
}

export function saveBagPriceAndPrice(cartData) {
  return {
    type: SAVE_BAG_ITEM_AND_PRICE,
    data: cartData,
  };
}

export function setUpgradeToPrepaid(data) {
  return {
    type: SET_UPGRADE_TO_PREPAID,
    data,
  };
}

export function setContactLess(data) {
  return {
    type: SET_CONTACT_LESS,
    data,
  };
}

export function renderOfferShimmers(renderShimmers) {
  return {
    type: RENDER_OFFER_SHIMMER,
    data: {
      isOfferShimmerVisible: renderShimmers,
    },
  };
}

export function saveCardsInRedux(cards) {
  return {
    type: SAVE_CARDS_IN_REDUX,
    data: {
      cards,
    },
  };
}

export function updatePlasticFreeData(amount, charge) {
  return {
    type: UPDATE_PLASTIC_FREE_DATA,
    data: {
      amount,
      charge,
    },
  };
}

export function videoSwipeNextCueDisplayed(isSwipeNextCueDisplayed) {
  return {
    type: SWIPE_NEXT_CUEUE,
    isSwipeNextCueDisplayed,
  };
}

export function copyCartItems(payload, callback) {
  return {
    type: COPY_CART_ITEMS,
    payload,
    callback,
  };
}

export function callbackPaginatedList(
  slug,
  pageNumber,
  callback,
  appliedFilterCounter,
  use_modified_product_list,
  hideOosProduct = false,
) {
  return {
    type: PAGINATED_LIST_CALLBACK,
    slug,
    pageNumber,
    callback,
    appliedFilterCounter,
    use_modified_product_list,
    hideOosProduct,
  };
}

export function fetchPersonalizedList(slug, pageNo, callback) {
  return {
    type: FETCH_PERSONALIZED_LIST,
    payload: slug,
    pageNo,
    callback,
  };
}

export function hasUACDeviceEventTriggered(triggered) {
  return {
    type: HAS_UAC_DEVICE_EVENT_TRIGGERED,
    triggered,
  };
}
export function deleteUserAccount(callback) {
  return {
    type: DELETE_USER,
    callback,
  };
}

export function deleteUserSelfie(callback, deleteAttributes = false) {
  return {
    type: DELETE_SELFIE,
    callback,
    deleteAttributes,
  };
}

export function fetchMorePageListLatest(
  slug,
  pageNo = 0,
  hideOosProduct = false,
  addFacialAttributesInApiRequest = false,
  counterFilterPress = -1,
  args = [],
  callback = () => {},
) {
  return {
    type: FETCH_LIST_DETAIL_LATEST,
    payload: slug,
    pageNo,
    hideOosProduct,
    callback,
    addFacialAttributesInApiRequest,
    counterFilterPress,
    args,
  };
}

export function isAppReviewSubmitted(submitted) {
  return {
    type: APP_REVIEW_SUBMITTED,
    submitted,
  };
}

export function updateWishlistToolTip(data) {
  return {
    type: UPDATE_WISHLIST_TOOLTIP,
    data,
  };
}

export function clearWishlistTooltip() {
  return {
    type: CLEAR_WISHLIST_TOOLTIP,
  };
}

export function getIOSUpiApps(callback) {
  return {
    type: GET_IOS_UPI_APPS,
    callback,
  };
}

export function fetchRoutinesDiscovery(api, callback) {
  return {
    type: GET_ROUTINES_DISCOVERY,
    api,
    callback,
  };
}

export function fetchRoutines(slug, callback) {
  return {
    type: GET_ROUTINES,
    slug,
    callback,
  };
}

export function createRoutineParticipation(data, callback) {
  return {
    type: CREATE_ROUTINE_PARTICIPATION,
    data,
    callback,
  };
}

export function fetchRoutineDetail(id, callback) {
  return {
    type: GET_ROUTINE_DETAIL,
    id,
    callback,
  };
}

export function getBoostedOffers() {
  return {
    type: GET_BOOSTED_OFFERS,
  };
}

export function saveBoostedOffers(offers) {
  return {
    type: SAVE_BOOSTED_OFFERS,
    offers,
  };
}

export function addCarryBagCharges(data) {
  return {
    type: ADD_CARRY_BAG_CHARGES,
    data,
  };
}

export function setSearchResultClicks(data = {}, callback = () => {}) {
  return {
    type: SET_SEARCH_RESULT_CLICKS,
    data,
    callback,
  };
}

export function optedForCarryBag(optForCarryBag, callback) {
  return {
    type: OPT_FOR_CARRY_BAG,
    optForCarryBag,
    callback,
  };
}
export function getFaqSectionData(slug, callback) {
  return {
    type: GET_FAQ_DATA,
    slug,
    callback,
  };
}
export function storeFaqLikeAndDislikeData(slug, actionType, faqId, callback) {
  return {
    type: STORE_FAQ_LIKE_AND_DISLIKE_DATA,
    slug,
    actionType,
    faqId,
    callback,
  };
}

export function addOrderRequestDetail(data, callback) {
  return {
    type: ADD_ORDER_REQUEST_DETAIL,
    data,
    callback,
  };
}

export function getContactUsReasons(entityType, entityId, callback) {
  return {
    type: GET_CONTACT_US_REASON,
    entityType,
    entityId,
    callback,
  };
}
export function getReturnReplaceReason(callback) {
  return {
    type: GET_RETURN_REPLACE_REASON,
    callback,
  };
}
export function getCancelReason(callback) {
  return {
    type: GET_CANCEL_REASON,
    callback,
  };
}
export function addReturnReplaceReasonId(
  reasonId,
  entityId,
  entityType,
  callback,
) {
  return {
    type: ADD_RETURN_REPLACE_REASON_ID,
    reasonId,
    entityId,
    entityType,
    callback,
  };
}

export function updateOrderRequestDetail(data, callback) {
  return {
    type: UPDATE_ORDER_REQUEST_DETAIL,
    data,
    callback,
  };
}

export function getShipmentTrackingDetails(id, callback) {
  return {
    type: GET_SHIPMENT_TRACKING_DETAILS,
    id,
    callback,
  };
}
export function getActiveCallBack(orderId, requestReasonId, callback) {
  return {
    type: GET_ACTIVE_CALLBACK,
    orderId,
    requestReasonId,
    callback,
  };
}
export function updateCarryBagData(amount, charge) {
  return {
    type: UPDATE_CARRY_BAG_DATA,
    data: {
      amount,
      charge,
    },
  };
}

export function setLastZoomAnimationVisibleAt(lastZoomAnimationVisibleAt) {
  return {
    type: SET_LAST_ZOOM_ANIMATION_VISIBLE_AT,
    lastZoomAnimationVisibleAt,
  };
}

export function setZoomAnimationVisibilityCounter(
  currentZoomAnimationVisiblityCounter,
) {
  return {
    type: CURRENT_ZOOM_ANIMATION_VISIBILITY_COUNTER,
    currentZoomAnimationVisiblityCounter,
  };
}

export function saveLocalNotificationData(data) {
  return {
    type: SAVE_LOCAL_NOTIFICATION,
    data,
  };
}
export function updateLocalNotificationData(notificationType) {
  return {
    type: UPDATE_LOCAL_NOTIFICATION,
    notificationType,
  };
}

export function setAppFirstOpened(appFirstOpened) {
  return {
    type: SET_APP_FIRST_OPENED,
    appFirstOpened,
  };
}

export function setShowEdgePendingIndicator(showEdgePendingIndicator) {
  return {
    type: SET_SHOW_EDGE_PENDING_INDICATOR,
    showEdgePendingIndicator,
  };
}

export function saveShopifyAddress(data) {
  return {
    type: SAVE_SHOPIFY_ADDRESS,
  };
}
export function saveMembershipCohort(data) {
  return {
    type: SAVE_MEMBERSHIP_COHORT,
    data,
  };
}

export function addToRecentlyViewed(productId) {
  return {
    type: ADD_TO_RECENTLY_VIEWED,
    productId,
  };
}

export function toggleDynamicListTitleVisibility(isVisible) {
  return {
    type: TOGGLE_DYNAMIC_LIST_TITLE_VISIBILITY,
    isVisible,
  };
}

export function updateLastVisitedProductId(id) {
  return {
    type: UPDATE_LAST_VISITED_PRODUCT_ID,
    id,
  };
}

export function fetchUserAttributeValues(id, callback) {
  return {
    type: GET_USER_ATTRIBUTE_VALUES,
    id,
    callback,
  };
}

export function postUserAttributeValues(requestData, callback) {
  return {
    type: POST_USER_ATTRIBUTE_VALUES,
    requestData,
    callback,
  };
}

export function addEmailToLocalStore(email, emailVerified) {
  return {
    type: ADD_EMAIL_TO_LOCAL_STORE,
    email,
    emailVerified,
  };
}

export function postShopifyOrderEmail(email, callback) {
  return {
    type: POST_SHOPIFY_ORDER_EMAIL,
    email,
    callback,
  };
}

export function verifyEmail(email, emailToken, callback) {
  return {
    type: VERIFY_EMAIL,
    email,
    emailToken,
    callback,
  };
}

export function getShopifyOrdersHistory(page, callback) {
  return {
    type: GET_SHOPIFY_ORDERS_HISTORY,
    page,
    callback,
  };
}

export function getShopifyOrdersDetails(id, callback) {
  return {
    type: GET_SHOPIFY_ORDERS_DETAILS,
    id,
    callback,
  };
}

export function isShopifyAddressExist(phoneNumber, callback) {
  return {
    type: DOES_SHOPIFY_ADDRESS_EXIST,
    phoneNumber,
    callback,
  };
}

export function fetchCategory(slug, callback) {
  return {
    type: FETCH_CATEGORY,
    payload: slug,
    callback,
  };
}

export function setCartNotification(nextState) {
  return {
    type: SET_CART_NOTIFICATION,
    nextState: nextState,
  };
}

export function savePermissionState(permission, permissionStatus) {
  return {
    type: SET_PERMISSION_STATUS,
    permission: permission,
    status: permissionStatus,
  };
}

export function savePersonalizationAttributes(attributes) {
  return {
    type: SAVE_PERSONALIZATION_ATTRIBUTES,
    attributes,
  };
}

export function userRegisteredOnCt(registered) {
  return {
    type: USER_REGISTERED_ON_CT,
    registered,
  };
}

export function saveUserLocationInfo(locationInfo, locationUpdatedAt) {
  return {
    type: SAVE_USER_LOCATION_INFO,
    locationInfo,
    locationUpdatedAt,
  };
}

export const updateProduct = (data = {}) => ({
  type: UPDATE_PRODUCT,
  data,
});
