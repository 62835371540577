import { takeEvery, select, call, put } from 'redux-saga/effects';
import {
  ADD_PROFILE_DETAILS,
  addProfileDetailsSuccess,
  addProfileDetailsFailure,
} from '../actions/LoginActions';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { convertJsonFromResponse } from './GeneratorUtil';

const fetchAddProfileDetail = (
  authToken,
  handle,
  name,
  email,
  latitude,
  longitude,
  locality,
  mobileNumber,
  deviceInfo,
  referralContactPhone,
  guest_token,
) => {
  let userData = {
    handle: `${Utility.isPresent(handle) ? handle : null}`,
    name: `${Utility.isPresent(name) ? name : ''}`,
    email: `${Utility.isPresent(email) ? email : ''}`,
    locality: `${Utility.isPresent(locality) ? locality : null}`,
    referrer: referralContactPhone,
    phone_number: `${mobileNumber}`,
  };

  if (!Utility.isBlank(latitude) && !Utility.isBlank(longitude)) {
    userData = {
      ...userData,
      lat: latitude,
      long: longitude,
    };
  }

  if (!Utility.isBlank(deviceInfo.email)) {
    userData = {
      ...userData,
      email: deviceInfo.email,
    };
  }

  const objectToSend = {
    device: deviceInfo,
    user: userData,
  };

  if (Utility.isBlank(objectToSend.auth_token)) {
    objectToSend.guest_token = guest_token;
  } else {
    objectToSend.auth_token = authToken;
  }

  const response = fetch(URL.ADD_PROFILE_DETAILS, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-auth-token': `${authToken}`,
      'x-guest-token': `${guest_token}`,
    },
    body: JSON.stringify(objectToSend),
  });
  return response;
};

function* addProfileDetails(action) {
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const { name, email, handle, locality, referralContactPhone } =
      action.userinfo;

    const mobileNumber = yield select(
      (state) => state.UserAccountInfo.mobileNumber,
    );
    const deviceInfo = yield select(
      (state) => state.UserAccountInfo.deviceInfo,
    );
    const latitude = yield select((state) => state.UserAccountInfo.latitude);
    const longitude = yield select((state) => state.UserAccountInfo.longitude);
    const guest_token = yield select(
      (state) => state.UserAccountInfo.guestProfile.guestAuthToken,
    );

    const response = yield call(
      fetchAddProfileDetail,
      authToken,
      handle,
      name,
      email,
      latitude,
      longitude,
      locality,
      mobileNumber,
      deviceInfo,
      referralContactPhone,
      guest_token,
    );

    if (response.status === 200) {
      console.log('Showing vaue *********', name, email, handle);
      yield put(
        addProfileDetailsSuccess(
          name,
          email,
          Utility.isBlank(handle) ? '' : handle,
        ),
      );
      if (action.callback !== undefined) action.callback();
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(addProfileDetailsFailure(response.status, json.errors));
      console.log(`Error in submitting profile info: ${JSON.stringify(json)}`);
    }
  } catch (error) {
    console.log(`Error in Adding Profile Details ${error}`);
  }
}

export default function* watchAddProfileDetailsSaga() {
  yield takeEvery(ADD_PROFILE_DETAILS, addProfileDetails);
}
