import React, { Component } from 'react';
import { View, ActivityIndicator, BackHandler } from 'react-native';
import { debounce } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { copyCartItems } from '../../actions/ActionTypes';
import Addresses from '../../components/cart/CartAddresses';
import Utility from '../../utils/Utility';
import withNavigation from '../../utils/WithNavigation';

export class CheckoutReminder extends Component {
  constructor(props) {
    super(props);
    const { route } = props;
    this.params = route.params?.params;
    this.debounceNavigateToCart = debounce(this.navigateToCart, 1000, {
      leading: true,
      trailing: false,
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.copyCart();
    }, 0);
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  navigateToCart = () => {
    const { navigation } = this.props;
    navigation.replace('Cart');
  };

  copyCart = () => {
    const { copyCartItems, showToast } = this.props;
    console.log(this.params, 'this.params');
    copyCartItems(this.params, (success) => {
      this.debounceNavigateToCart();
    });
  };

  render() {
    const { address, onRefresh } = this.props;

    return (
      <View
        style={{
          flex: 1,
          position: 'absolute',
          height: Utility.getScreenHeight(),
          width: Utility.getScreenWidth(),
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          opacity: 0.5,
          zIndex: 1,
        }}
      >
        <ActivityIndicator animating color="red" size="large" />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      copyCartItems,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(CheckoutReminder),
);
