import React from 'react';
import Config from '../../libraries/ReactNativeConfig';
import { View, StyleSheet, Image, Platform } from 'react-native';
import CountryPicker from 'react-native-country-picker-modal';
import images from '../../theme/Images';
import colors from '../../theme/Colors';

const preferredCountries = [Config.COUNTRY_ISO_CODE || ''];

const MyCountryPicker = ({ updateCountryCode, countryCode }) => {
  console.log({ countryCode })
  const onCountrySelect = (selectedCountry) => {
    const { cca2, callingCode = [] } = selectedCountry;
    updateCountryCode({ countryCode: cca2, callingCode: callingCode[0] });
  };

  return (
    <View style={styles.modalContainer}>
      <CountryPicker
        countryCode={countryCode}
        withFlag
        withCallingCodeButton
        withCallingCode
        withEmoji
        withFilter
        onSelect={onCountrySelect}
        preferredCountries={preferredCountries}
      />
      <Image source={images.chevronDown} style={styles.chevronDown} />
      <View style={styles.separator} />
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    ...Platform.select({
      android: { marginBottom: 0 },
      default: { marginBottom: -8 },
    }),
  },
  modalContent: {
    backgroundColor: 'white',
    width: '100%',
    height: '50%', // Half screen height
    padding: 20,
  },
  separator: {
    height: 12,
    width: 12,
    backgroundColor: colors.white,
    overflow: 'visible',
    alignSelf: 'flex-end',
  },
  chevronDown: {
    height: 16,
    width: 16,
    marginLeft: 4,
    tintColor: colors.black,
    resizeMode: 'contain',
  },
});

export default MyCountryPicker;
