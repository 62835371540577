import { Text, View, TouchableOpacity, Image, ScrollView } from 'react-native';
import React, { PureComponent } from 'react';
import styles from './styles';
import Modal from 'react-native-modal';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import images from '../../../theme/Images';
import FastImageView from '../../../components/FastImageView';
import Video from '../../../libraries/ReactNativeVideo';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';

class SeeHowModal extends PureComponent {
  constructor(props) {
    super(props);
    this.media = [
      {
        path: 'https://cdn3.foxy.in/videos/foxy_video/video/111534/vid_20230117_wa0001.mp4',
        mime: 'video',
      },
      {
        path: images.defective_product_1,
        mime: 'image',
      },
      {
        path: images.defective_product_2,
        mime: 'image',
      },
    ];
    this.state = {
      isVisible: true,
      isImageLoading: false,
    };
  }

  cancelpressed = () => {
    const { onDismiss } = this.props;
    onDismiss();
  };

  onPressVideoIcon = () => {
    const { isVisible } = this.state;
    console.log('hello');
    this.setState({
      isVisible: !isVisible,
    });
  };

  onVideoEnd = () => {
    this.setState({
      isVisible: true,
    });
  };

  onVideoLoadStart = () => {
    this.setState({
      isImageLoading: true,
    });
  };

  onVideoLoadEnd = () => {
    this.setState({
      isImageLoading: false,
    });
  };

  thumbNail = ({ image }) => {
    if (image.mime === 'image') {
      return (
        <FastImageView source={image.path} style={styles.modalSelectedMedia} />
      );
    }
    const { isVisible, isImageLoading } = this.state;
    let player;
    const assignRef = (ref) => {
      player = ref;
    };
    const seekTo0 = () => {
      player.seek(0);
    };
    const videoPaused = isVisible;
    return (
      <>
        <TouchableWithoutFeedback onPress={this.onPressVideoIcon}>
          <Video
            style={styles.modalSelectedMedia}
            source={{ uri: image.path }}
            resizeMode="cover"
            ref={assignRef}
            onLoad={seekTo0}
            paused={videoPaused}
            onEnd={this.onVideoEnd}
            onLoadStart={this.onVideoLoadStart}
            onLoadEnd={this.onVideoLoadEnd}
          />
        </TouchableWithoutFeedback>
        {isVisible && (
          <TouchableOpacity
            style={styles.videoPauseIconContainer}
            onPress={this.onPressVideoIcon}
          >
            <FastImageView
              source={images.video_pause_button}
              style={styles.videoPauseIcon}
            />
          </TouchableOpacity>
        )}
      </>
    );
  };

  renderEachImage = (image) => {
    return (
      <View style={styles.modalSelectedMediaContainer}>
        <this.thumbNail image={image} />
      </View>
    );
  };

  render() {
    const { isVisible } = this.props;
    return (
      <>
        <Modal
          isVisible={isVisible}
          style={styles.bottomModal}
          onBackButtonPress={this.cancelpressed}
          onBackdropPress={this.cancelpressed}
        >
          <View style={styles.seeHowContentContainer}>
            <View style={styles.headingTextContainer}>
              <Text style={styles.modalHeadingText}>
                Make sure you share all the images
              </Text>
              <Text style={styles.modalSubHeadingText}>
                of your foxy shipment, items in the order, barcode.
              </Text>
              <TouchableOpacity
                style={styles.crossIconStyle}
                onPress={this.cancelpressed}
              >
                <Image source={images.cross} style={styles.modalCrossIcon} />
              </TouchableOpacity>
            </View>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={styles.scrollViewContainer}
            >
              <View style={styles.modalMediaRailContainer}>
                {this.media.map(this.renderEachImage)}
              </View>
            </ScrollView>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 36}
              style={styles.foxyShadowButtonStyle}
              title={'OK got it!'}
              backgroundColor={colors.codGray}
              firstColor={colors.black}
              secondColor={colors.black}
              underlayColor={colors.black}
              borderRadius={4}
              onPress={this.cancelpressed}
            />
          </View>
        </Modal>
      </>
    );
  }
}

export default SeeHowModal;
