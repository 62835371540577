import React, { Component } from 'react';
import { View, StatusBar, DeviceEventEmitter, BackHandler } from 'react-native';
import Orientation from 'react-native-orientation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import styles from './styles';
import withNavigationFocus from '../../../utils/WithNavigationFocus';
import Utility from '../../../utils/Utility';
import * as UploadPostActions from '../../../actions/UploadPostActions';
import * as LoginActions from '../../../actions/LoginActions';
import FoxyCamera from '../../../components/camera/shared/FoxyCamera';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';

class CameraContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.foxyCamera = React.createRef();
    this.mkdirOptions = Utility.isAndroid()
      ? {}
      : { NSURLIsExcludedFromBackupKey: true };
    props.loginActions.toggleUpdateCurrentPostStatus(false);
    const { navigation, route } = this.props;
    this.campaignId = route.params?.campaignId ?? '';
    this.campaignProducts = route.params?.campaignProducts ?? '';
    this.reviewProductId = route.params?.reviewProductId ?? '';
    this.previousScreen = route.params?.previousScreen ?? '';
  }

  componentDidMount = () => {
    // Orientation.lockToPortrait();
    if (Utility.isAndroid()) {
      this.subscription = DeviceEventEmitter.addListener(
        'focusChange',
        this.onFocusChange,
      );
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          this.goBack();
          return true;
        },
      );
    }
  };

  onFocusChange = (params) => {
    if (params.appHasFocus) {
      Utility.setImmersiveModeOn();
    }
  };

  componentWillUnmount = () => {
    if (Utility.isAndroid()) {
      Utility.setImmersiveModeOff();
      this.subscription.remove();
      this.backHandler?.remove();
    }
  };

  goBack = () => {
    const { navigation } = this.props;

    console.log('Previous Screen', this.previousScreen);
    if (this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE) {
      navigation.goBack();
    } else if (this.previousScreen === 'productReview') {
      navigation.dismiss();
    } else if (this.previousScreen !== '') {
      navigation.pop();
    } else {
      navigation.navigate('Feed');
    }
    if (Utility.isAndroid()) {
      setTimeout(() => Utility.setImmersiveModeOff(), 0);
    }
  };

  render() {
    const {
      navigation,
      route,
      posts,
      currentPostID,
      updateCurrentPost,
      productTutorialVideo,
      loginActions,
      uploadPostActions,
      createDirectory,
    } = this.props;
    const keepThumbnails = route.params?.keepThumbnails;
    return (
      <View style={styles.container}>
        <StatusBar hidden />
        <FoxyCamera
          readOnlyCameraPermission={false}
          foxyCamera={this.foxyCamera}
          navigation={navigation}
          posts={posts}
          currentPostID={currentPostID}
          updateCurrentPost={updateCurrentPost}
          loginActions={loginActions}
          uploadPostActions={uploadPostActions}
          goBack={this.goBack}
          productTutorialVideo={productTutorialVideo}
          keepThumbnails={keepThumbnails}
          createDirectory={createDirectory}
          campaignId={this.campaignId}
          campaignProducts={this.campaignProducts}
          reviewProductId={this.reviewProductId}
          previousScreen={this.previousScreen}
        />
      </View>
    );
  }
}

CameraContainer.defaultProps = {
  posts: {},
  updateCurrentPost: false,
  currentPostID: '',
  productTutorialVideo: false,
  createDirectory: true,
};

CameraContainer.propTypes = {
  posts: PropTypes.object,
  uploadPostActions: PropTypes.any,
  loginActions: PropTypes.any,
  updateCurrentPost: PropTypes.bool,
  currentPostID: PropTypes.string,
  navigation: PropTypes.any,
  productTutorialVideo: PropTypes.bool,
  createDirectory: PropTypes.bool,
};

export default connect(
  (state) => ({
    posts: state.Posts,
    currentPostID: state.UserAccountInfo.currentPostId,
    createDirectory: state.UserAccountInfo.singletonSteps.createDirectory,
    updateCurrentPost: state.UserAccountInfo.updateCurrentPost,
    productTutorialVideo: state.UserAccountInfo.productTutorialVideo,
  }),
  (dispatch) => ({
    uploadPostActions: bindActionCreators(UploadPostActions, dispatch),
    loginActions: bindActionCreators(LoginActions, dispatch),
  }),
)(withNavigationFocus(CameraContainer));
