import { View, Text } from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import ProductGroups from './ProductGroups';
import ProductAttributesAndProperties from './ProductAttributesAndProperties';
import ProductRatingAndMoreInfo from './ProductRatingAndMoreInfo';
import ProductVariant from './ProductVariant';
import ProductTodayDeals from './ProductTodayDeals';
import ProductPrice from './ProductPrice';
import ProductImages from './ProductImages';
import { ProductDetailStyles } from './styles';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import ProductImageWithDescriptionCard from './ProductImageWithDescriptionCard';
import ProductHeroDescription from './ProductHeroDescription';
import RichContent from './RichContent/RichContent';
import { isDesktop } from '../../utils/BooleanUtility';
import FoxyPromises from './FoxyPromises';
import Config from '../../libraries/ReactNativeConfig';

export default function ProductDetailTopSection(props) {
  const navigation = useNavigation();

  const {
    selectedVariant,
    data,
    currentSku,
    isLoading,
    setSelectedVariant,
    setCurrentSku,
    offer_id: offerId,
    promptMessages,
    selectedMultiVariantPrimary,
    setSelectedMultiVariantPrimary,
    selectedMultiVariantSecondary,
    setSelectedMultiVariantSecondary,
    scrollToRatingSection,
    onMoreShadeTap,
    productPage = {},
  } = props;

  const {
    discount: selectedVariantDiscount,
    mrp: selectedVariantMrp,
    final_sp: selectedVariantSp,
  } = selectedVariant;

  const {
    name = '',
    id = '',
    hero_description: heroDescription = '',
    mrp = '',
    final_sp: sp = '',
    discount,
    stocked_status: stockedStatus,
    rating,
    sku_id: skuId,
    property_values: propertyValues,
    product_category: productCategory,
    brand,
    rich_content = {},
  } = data;

  const currentDiscount = discount || selectedVariantDiscount;

  if (stockedStatus !== 'in_stock') {
    return (
      <ProductImageWithDescriptionCard
        itemData={data}
        navigation={navigation}
      />
    );
  }

  const pattern = `${brand?.name} ` || '';
  const rep = new RegExp(pattern, 'gi');
  const productName = name?.replace(rep, '');

  return (
    <>
      <View style={ProductDetailStyles.productDescriptionContainerStyle}>
        <Text style={ProductDetailStyles.productNameStyle}>{productName}</Text>
      </View>
      <ProductHeroDescription
        hero_description={heroDescription}
        isLoading={isLoading}
      />
      <ProductPrice
        mrp={selectedVariantMrp || mrp}
        sp={selectedVariantSp || sp}
        discount={selectedVariantDiscount || discount}
      />

      <ProductTodayDeals
        itemData={data}
        selectedVariant={selectedVariant}
        navigation={navigation}
        offerId={offerId}
        discount={currentDiscount}
        promptMessages={promptMessages}
        currentSku={currentSku}
        isProductTodayDeals
        isLoading={isLoading}
      />

      {!isDesktop() && !isLoading && (
        <ProductVariant
          variantAttributes={data?.variant_attributes}
          hasVariants={data?.has_variants}
          packSize={data?.pack_size}
          itemData={data}
          updateSelectedVariant={(item) => {
            setSelectedVariant(item);
            setCurrentSku(item.sku_id);
          }}
          navigation={navigation}
          multiVariantAttributes={data?.multi_level_variant_attributes}
          productCategory={data?.product_category}
          selectedVariant={selectedVariant}
          selectedMultiVariantPrimary={selectedMultiVariantPrimary}
          setSelectedMultiVariantPrimary={setSelectedMultiVariantPrimary}
          selectedMultiVariantSecondary={selectedMultiVariantSecondary}
          setSelectedMultiVariantSecondary={setSelectedMultiVariantSecondary}
          onMoreShadeTap={onMoreShadeTap}
        />
      )}
      <ProductRatingAndMoreInfo
        onRatingTap={scrollToRatingSection}
        rating={rating}
        id={id}
        sku_id={skuId}
        data={data}
        show
      />
      <RichContent itemData={rich_content?.post_ratings} />
      <ProductAttributesAndProperties
        brand={brand}
        propertyValues={propertyValues}
        productCategory={productCategory}
        navigation={navigation}
        id={id}
        sku_id={skuId}
        isLoading={isLoading}
      />
      {isDesktop() && Config.APP_NAME === 'Foxy' && (
        <FoxyPromises
          productPage={productPage}
          navigation={navigation}
          product_id={id}
        />
      )}
      <ProductGroups itemData={data} />
    </>
  );
}
