import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import ScaleAnimate from '../shared/ScaleAnimate';
import RoutinesUtility from './RoutinesUtility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

const RoutineRailWithDescriptionCardWidth = Utility.getScreenWidth() * 0.65;
class RoutineRailWithDescriptionCard extends PureComponent {
  static getComponentHeight() {
    return styles.container.height;
  }

  constructor(props) {
    super(props);

    this.state = {
      itemData: {},
    };

    const {
      itemData: { routine = {} },
    } = props;

    try {
      new JSONAPIDeserializer({
        typeAsAttribute: false,
        pluralizeType: true,
        keyForAttribute: 'camelCase',
      })
        .deserialize(routine)
        .then((data) => {
          const { startsAt = '', endsAt = '' } = data;
          this.isLive = RoutinesUtility.isLive(startsAt, endsAt);
          this.setState({ itemData: data });
        });
    } catch (e) {}
  }

  viewDetails = () => {
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.navigateToDetailPage}
        style={styles.startAgainContainer}
      >
        <Text style={styles.startAgainText}>View Details</Text>
      </DebouncedTouchableOpacity>
    );
  };

  navigateToDetailPage = () => {
    const {
      navigation,
      previousScreen = '',
      orderAasmState = '',
      index = '',
      listIndex,
      listData: {
        name: listName = '',
        id: listId = '',
        slug: listSlug = '',
        display: listDisplay = '',
        content: listContent = '',
      } = {},
    } = this.props;
    const {
      itemData: {
        name = '',
        id = '',
        type = '',
        slug = '',
        skuId = '',
        startsAt = '',
        isUserSubscribed = false,
        endsAt = '',
      } = {},
    } = this.state;
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      id,
      type,
      name,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
    );
    const isExpired = new Date() > RoutinesUtility.getLocalTimeFromUTC(endsAt);
    if (isUserSubscribed && isExpired) {
      this.navigateToRoutineSchedule(false);
      return;
    }
    const isActive = RoutinesUtility.getLocalTimeFromUTC(startsAt) < new Date();
    if (isUserSubscribed && isActive) {
      this.navigateToRoutineSchedule(true);
      return;
    }
    navigation.push('PreRoutineDetails', {
      slug: slug,
      source: 'navigation_header',
      sku_id: skuId,
    });
  };

  navigateToRoutineSchedule = (isRoutineActive) => {
    const { navigation } = this.props;
    const { itemData: { slug = '' } = {} } = this.state;
    navigation.navigate('DuringRoutineDetails', {
      slug: slug,
      isRoutineActive: isRoutineActive,
    });
  };

  render() {
    const {
      itemData: {
        name,
        description,
        artist = {},
        startsAt = new Date(),
        endsAt = new Date(),
        pictureUrls = {},
      } = {},
    } = this.state;
    const { largeBanner = '' } = pictureUrls;

    const { name: artistName, imageUrl: imageUrl } = artist;
    const artistImage = Utility.isBlank(imageUrl)
      ? images.routines.artist_placeholder
      : imageUrl;
    const dateString = RoutinesUtility.getStartDateString(
      startsAt,
      endsAt,
      false,
      true,
    );
    const isLive = RoutinesUtility.isLive(startsAt, endsAt);
    return (
      <ScaleAnimate {...this.props} onPress={this.navigateToDetailPage}>
        <View style={styles.container}>
          <FastImageView
            source={Utility.getMinifiedImage(largeBanner)}
            style={styles.routineImage}
          />
          <View style={styles.detailsContainer}>
            <View style={styles.textContainer}>
              <Text style={styles.title} numberOfLines={1}>
                {name}
              </Text>
              <Text numberOfLines={3} ellipsizeMode='tail' style={styles.body}>
                {description}
              </Text>
              <View style={styles.artistContainer}>
                <FastImageView
                  source={artistImage}
                  style={styles.artistImage}
                />
                <Text style={styles.artistName}>by {artistName}</Text>
              </View>
              <View style={styles.statusContainer}>
                <Text style={styles.statusText}>{dateString}</Text>
                <this.viewDetails />
              </View>
            </View>
          </View>
          {isLive && (
            <View style={styles.liveContainer}>
              <Text style={styles.liveText}>LIVE</Text>
            </View>
          )}
        </View>
      </ScaleAnimate>
    );
  }
}

export default RoutineRailWithDescriptionCard;

const styles = StyleSheet.create({
  container: {
    width: RoutineRailWithDescriptionCardWidth,
    flexDirection: 'column',
    marginRight: 8,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: colors.border,
    height: RoutineRailWithDescriptionCardWidth * (158 / 254) + 200,
  },
  routineImage: {
    borderRadius: 8,
    width: '100%',
    height: RoutineRailWithDescriptionCardWidth * (158 / 254),
    resizeMode: 'contain',
  },
  detailsContainer: {
    // position: 'absolute',
    flexDirection: 'column',
    bottom: 0,
    backgroundColor: colors.white,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
  body: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
    height: 58,
  },
  artistName: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
  },
  textContainer: {
    padding: 12,
    alignItems: 'flex-start',
  },
  artistContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 8,
  },
  artistImage: {
    width: 16,
    height: 16,
    borderRadius: 8,
    marginRight: 8,
    marginTop: 4,
    resizeMode: 'cover',
  },
  startAgainContainer: {
    paddingVertical: 8,
    paddingHorizontal: 20,
    backgroundColor: colors.black,
    borderRadius: 18,
    flexDirection: 'row',
  },
  startAgainText: {
    color: colors.white,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
  },
  statusContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12,
    width: '100%',
  },
  statusText: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
  },
  inviteIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  liveContainer: {
    backgroundColor: colors.routine_card_pink,
    borderRadius: 2,
    paddingHorizontal: 12,
    paddingVertical: 2,
    marginLeft: 6,
    position: 'absolute',
    left: 4,
    top: 12,
  },
  liveText: {
    color: colors.white,
    fontSize: size.h4_5,
    fontFamily: 'Roboto-Medium',
  },
});
