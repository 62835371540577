import { put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { toLower } from 'lodash';
import {
  FETCH_LIST_DETAIL,
  MERGE_LIST_DATA,
  FETCH_COLLECTION_RAILS,
  FETCH_CART_EMPTY_STATE,
  FETCH_LIST_DETAIL_WITH_CALLBACK,
  GET_LIST_DETAILS,
  FETCH_TAG_DATA,
  FETCH_OFFER_DETAILS,
  FETCH_PERSONALIZED_LIST,
  fetchList,
  FETCH_LIST_DETAIL_LATEST,
  BUILD_LIST,
  FETCH_OFFER_DETAILS_CART_ITEMS,
} from '../actions/ActionTypes';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';
import { API_DOMAIN, URL, BUILD_NO, PLATFORM_VERSION } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse, getDynamicListParams } from './GeneratorUtil';
import { appendAttributesWithHomePageUrl } from '../utils/UriUtility';

export function* fetchListDetail(action) {
  const {
    payload,
    callback = () => {},
    pageNo = 0,
    hideOosProduct = false,
    addFacialAttributesInApiRequest,
    counterFilterPress = -1,
    args = [],
  } = action;
   
  let url = `${API_DOMAIN}${payload}`;
  if (url.includes('nav_provier=moengage')) {
    let indexOfQueryString = url.indexOf('?');
    url = url.substring(0, indexOfQueryString);
  }

  if (addFacialAttributesInApiRequest) {
    const facialAnalysis = yield select(
      (state) => state.UserAccountInfo.facialAnalysis,
    );
    if (
      Utility.isPresent(facialAnalysis) &&
      Utility.isPresent(facialAnalysis.facialProperties)
    ) {
      const { facialProperties } = facialAnalysis;
      url = appendAttributesWithHomePageUrl(
        url,
        facialProperties,
        false,
      );
    } else if (Utility.isPresent(facialAnalysis.my_attributes_values)) {
      url = appendAttributesWithHomePageUrl(
        url,
        facialAnalysis.my_attributes_values,
        true,
      );
    }
  }

  if (Utility.isPresent(args) && args?.length > 0) {
    for (const item of args) {
      params = yield getDynamicListParams(item);
      url += params;
    }
  }

  url = `${url}&page=${pageNo}&hide_oos=${hideOosProduct}`;
  url = encodeURI(url);

  // show loader
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, { status: response.status });
        return;
      }
      callback(true, json, pageNo, counterFilterPress, response.status);
      // yield put({ type: MERGE_LIST_DATA, data: json });   //TODO: Commented this line, need to investigate more
    } else {
      callback(false, { status: response.status }, pageNo, counterFilterPress);
    }
  } catch (error) {
    callback(false, {}, pageNo, counterFilterPress);
  }
}

export function* fetchListDetailCallback(action) {
  const { slug, callback, currentPage = 0 } = action;
  let url = `${API_DOMAIN}${slug}`;

  if (url.indexOf('?') === -1) {
    url = `${API_DOMAIN}${slug}?page=${currentPage}`;
  } else {
    url = `${API_DOMAIN}${slug}&page=${currentPage}`;
  }

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json, response.status);
    } else {
      console.log('error in fetching detail list', slug);
      callback(false, {}, response.status);
    }
  } catch (error) {
    console.log('error in fetching detail list', error);
    callback(false, {});
  }
}

export function* fetchOfferDetailsCartItems(action) {
  const { slug, callback, currentPage = 0 } = action;
  const updatedSlug = slug.replace('.json', '');
  let url = `${URL.OFFER_DETAIL}${updatedSlug}/cart_items_for_combo?use_modified_product_list=true`;

  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    let sendCallbackData = true;
    const timer = setTimeout(() => {
      sendCallbackData = false;
      callback(false, { errorType: 'api_failure' });
    }, parseInt(Utility.getTimeOutValue()));

    if (response.status >= 200 && response.status < 400 && sendCallbackData) {
      clearTimeout(timer);
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {}, response.status);
      console.log('error in fetching detail list', slug);
    }
  } catch (error) {
    console.log('error in fetching detail list', error);
    callback(false, {});
  }
}

export function* fetchOfferDetails(action) {
  const { slug, callback, currentPage = 0 } = action;
  let url = `${URL.OFFER_DETAIL}${slug}?use_modified_product_list=true`;
  if (Utility.isPresent(currentPage)) {
    url = `${URL.OFFER_DETAIL}${slug}?page=${currentPage}&use_modified_product_list=true`;
  }
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    let sendCallbackData = true;
    const timer = setTimeout(() => {
      sendCallbackData = false;
      callback(false, { errorType: 'api_failure' });
    }, parseInt(Utility.getTimeOutValue()));

    if (response.status >= 200 && response.status < 400 && sendCallbackData) {
      clearTimeout(timer);
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {}, response.status);
      console.log('error in fetching detail list', slug);
    }
  } catch (error) {
    console.log('error in fetching detail list', error);
    callback(false, {});
  }
}

export function* fetchTagDetailCallback(action) {
  const { slug, callback, currentPage = 0 } = action;
  let url = `${API_DOMAIN}${slug}`;
  if (Utility.isPresent(currentPage)) {
    url = `${API_DOMAIN}${slug}?page=${currentPage}`;
  }
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    console.log('error in fetching detail list', error);
    callback(false, {});
  }
}

export function* fetchCollectionRails(action) {
  const { callback, slug } = action;
  const url = `${API_DOMAIN}${slug}`;
  // show loader
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
      console.log('error in fetching detail list');
    }
  } catch (error) {
    console.log('error in fetching detail list', error);
    callback(false, {});
  }
}

export function* fetchCartEmptyState(action) {
  const { callback, slug } = action;
  const url = `${API_DOMAIN}${slug}`;
  // show loader
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      console.log('error in fetching cart list');
      callback(false, {});
    }
  } catch (error) {
    console.log('error in fetching cart list', error);
    callback(false, {});
  }
}

export function* getListDetailById(action) {
  const { id, callback } = action;
  try {
    const url = `${API_DOMAIN}/api/v6/lists/${id}`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

export function* buildList(action) {
  const { callback, productIds = [], skuIds = [] } = action;
  let url = `${URL.BUILD_LIST}?`;
  let productIdsString = '';
  let skuIdsString = '';

  productIds.forEach((element) => {
    productIdsString += `product_ids[]=${element}&`;
  });

  skuIds.forEach((element) => {
    skuIdsString += `sku_ids[]=${element}&`;
  });

  url = url + productIdsString + skuIdsString;
  // show loader
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: `DetailSaga.js L-258 ${error}`,
    });
    callback(false, {});
  }
}

// Our watcher Saga:
export default function* watcFetchDetail() {
  yield takeEvery(FETCH_LIST_DETAIL, fetchListDetail);
  yield takeEvery(FETCH_LIST_DETAIL_LATEST, fetchListDetail);
  yield takeLatest(FETCH_LIST_DETAIL_WITH_CALLBACK, fetchListDetailCallback);
  yield takeEvery(FETCH_OFFER_DETAILS, fetchOfferDetails);
  yield takeEvery(FETCH_OFFER_DETAILS_CART_ITEMS, fetchOfferDetailsCartItems);
  yield takeLatest(FETCH_COLLECTION_RAILS, fetchCollectionRails);
  yield takeEvery(FETCH_CART_EMPTY_STATE, fetchCartEmptyState);
  yield takeEvery(GET_LIST_DETAILS, getListDetailById);
  yield takeLatest(FETCH_TAG_DATA, fetchTagDetailCallback);
  yield takeLatest(FETCH_PERSONALIZED_LIST, fetchListDetail);
  yield takeEvery(BUILD_LIST, buildList);
}
