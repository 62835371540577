import React, { Component } from 'react';
import {
  Animated,
  BackHandler,
  Image,
  RefreshControl,
  Text,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPayoff } from '../../actions/PayoffActions';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import {
  getUserTodayDeals,
  setNotificationModalDisplayTime,
} from '../../actions/ActionTypes';
import TransparentHeader from '../../components/Product/TransparentHeader';
import TodayDeal from '../../components/todayDeals/TodayDeals';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import DockedHeader from '../../utils/DockedHeader';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import {
  NOTIFICATION_REQUEST_MODAL_TYPE,
  REMOTE_CONFIG_KEYS,
  WISHLIST_DEALS_SLUG,
} from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import NotificationModal from '../../utils/Notification/NotificationModal';
import AppConfig from '../../config/AppConfig';
import Config from '../../libraries/ReactNativeConfig';
import images from '../../theme/Images';
import { CartStyles } from '../../components/cart/styles';
import { goBackWithFallbackHome } from '../../utils/NavigationUtility';
import { isDesktop } from '../../utils/BooleanUtility';
import WebFooter from '../../components/webFooter/WebFooter';

export class TodayDeals extends Component {
  constructor(props) {
    super(props);
    const { group_deal: renderInviteCentreIcon } = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.hide_actions),
    );
    this.renderInviteCentreIcon = renderInviteCentreIcon;
    this.state = {
      todayDeals: {},
      scrollY: new Animated.Value(0),
      canDisplayNotificationRequestModal: false,
      showNotificationPrompt: false,
    };

    this.getTodayDeals();

    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.disableTranslucentStatusBar = AppConfig.getBooleanValue(
      Config.DISABLE_WISHLIST_STATUS_BAR_TRANSLUCENT,
    );
  }

  componentDidMount() {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
      [EventParameterKey.SOURCE]: SCREEN_CONSTANTS.WISH_DEALS,
    });
    this.didFocusListener = navigation.addListener('focus', this.onDidFocus);
    this.didBlurListener = navigation.addListener(
      'blur',
      Utility.setStatusBarWhite,
    );
    setTimeout(this.displayNotificationRequestModal, 2000);
  }

  componentWillUnmount() {
    this.didFocusListener();
    this.didBlurListener();
    Utility.setStatusBarWhite();
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  getTodayDeals = () => {
    const { getPayoff, getUserTodayDeals } = this.props;

    Promise.all([
      getUserTodayDeals(),
      getPayoff(WISHLIST_DEALS_SLUG, this.fetchTodayDealsCallback, 0),
    ]);
  };

  getTodayDealsRefresh = () => {
    const { getPayoff, getUserTodayDeals } = this.props;
    this.setState({
      refreshing: true,
    });
    getPayoff(WISHLIST_DEALS_SLUG, this.fetchTodayDealsCallback, 0);

    getUserTodayDeals();
  };

  refresh = () => {
    const { getUserTodayDeals } = this.props;
    getUserTodayDeals();
    this.getTodayDealsRefresh();
  };

  fetchTodayDealsCallback = (success, response) => {
    this.setState({
      refreshing: false,
    });

    if (success && response) {
      this.setState({
        todayDeals: response,
      });
    }
  };

  onDidFocus = () => {
    if (!this.disableTranslucentStatusBar) {
      Utility.setStatusbarTranslucent();
    }
    AnalyticsManager.setCurrentScreen(SCREEN_CONSTANTS.WISH_DEALS);
    this.getTodayDeals();
  };

  onHardwareBackKeyPress = () => {
    this.goBack();
    return true;
  };

  goBack = () => {
    const { navigation } = this.props;
    goBackWithFallbackHome(navigation);
  }

  showNotificationModal = (show, showNotification) => {
    this.setState({
      canDisplayNotificationRequestModal: show,
      showNotificationPrompt: showNotification,
    });
  };

  displayNotificationRequestModal = () => {
    const {
      lastNotificationModalDisplayTime,
      setNotificationModalDisplayTime,
      initial_app_opened_at,
    } = this.props;
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      'wishlist',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          const currentDate = new Date();
          setNotificationModalDisplayTime(currentDate.getTime());
        }
      },
      initial_app_opened_at,
    );
  };

  render() {
    const {
      todayDeals,
      refreshing,
      canDisplayNotificationRequestModal,
      showNotificationPrompt,
    } = this.state;

    return (
      <>
        {!AppConfig.getBooleanValue(Config.DISABLE_WISHLIST_HEADER) && !isDesktop() && (
          <TransparentHeader
            scrollY={this.state.scrollY}
            previousScreen={SCREEN_CONSTANTS.TODAY_DEALS}
            screenName={SCREEN_CONSTANTS.MORE_PAGE}
            goBack={this.goBack}
            renderInviteCentreIcon={this.renderInviteCentreIcon}
            hideBackButton={this.disableTranslucentStatusBar}
            navigationHeaderHeight={this.disableTranslucentStatusBar ? 0 : 24}
            hideAnimation={isDesktop()}
          >
            <DockedHeader
              name={Config.WISHLIST_TITLE || 'Your Wishlist Deals'}
            />
          </TransparentHeader>
        )}
        <Animated.ScrollView
          scrollEventThrottle={1}
          onScroll={Animated.event([
            { nativeEvent: { contentOffset: { y: this.state.scrollY } } },
          ])}
          removeClippedSubviews={true}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={this.getTodayDealsRefresh}
            />
          }
        >
          <TodayDeal
            todayDeals={todayDeals}
            refresh={this.refresh}
            refreshing={refreshing}
          />
          <WebFooter />
        </Animated.ScrollView>
        <NotificationModal
          isNotificationModalVisible={canDisplayNotificationRequestModal}
          showNotificationModal={this.showNotificationModal}
          showNotificationPrompt={showNotificationPrompt}
          type={NOTIFICATION_REQUEST_MODAL_TYPE.WISHLIST_PAGE}
          showAnimation
        />
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    isManuallyReviewed: state.facialAnalysis.isManuallyReviewed,
    facialProperties: state.UserAccountInfo?.facialAnalysis?.facialProperties,
    selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
    name: state.UserAccountInfo.profile.name,
    lastNotificationModalDisplayTime:
      state.UserAccountInfo.lastNotificationModalDisplayTime,
    initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { getPayoff, getUserTodayDeals, setNotificationModalDisplayTime },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(TodayDeals),
);
