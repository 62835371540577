import React, { Component } from 'react';
import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Image,
} from 'react-native';
import { AnalyticsManager, EventType } from '../../analytics';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import ServerError from './ServerError';
import NoInternet from './NoInternet';
import PageNotFound from './PageNotFound';

export default class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = {
      isError: false,
    };
  }

  componentDidCatch(error, info) {
    const { screen_name, itemData = {} } = this.props;
    console.log('Error Caught by Component Did Catch', error, info);
    this.setState({
      isError: true,
    });

    AnalyticsManager.logEvent(EventType.errorEvents.PAGE_LOAD_ERROR, {
      screen_name,
      item_type: itemData?.type,
      item_id: itemData?.id,
      item_name: itemData?.name,
    });
  }

  onResetState = () => {
    this.setState({
      isError: false,
    });
  };

  _button = () => {
    return (
      <TouchableOpacity onPress={this.onResetState}>
        <View style={style.button}>
          <Text style={style.buttonText}>Retry</Text>
        </View>
      </TouchableOpacity>
    );
  };

  _fallbackComponent = () => {
    return (
      <SafeAreaView style={style.safeArea}>
        <View style={style.fallbackComponent}>
          <Image source={images.serverError} />
          <Text style={style.heading}>Oops! Something went wrong</Text>
          <Text style={style.subHeading}>
            An unexpected error occurred. Please retry or check back later.
          </Text>

          <this._button />
        </View>
      </SafeAreaView>
    );
  };

  render() {
    const { isError } = this.state;
    const {
      showServerError = false,
      onPressReload = undefined,
      darkMode = false,
      connectionError = false,
      pageNotFound = false,
      loading = false,
      hideHeader = false,
      slug,
    } = this.props;
    const goToHome = onPressReload === undefined ? true : false;
    if (showServerError) {
      return (
        <ServerError
          onPress={onPressReload}
          goToHome={goToHome}
          darkMode={darkMode}
          loading={loading}
          hideHeader={hideHeader}
        />
      );
    }
    if (connectionError) {
      return (
        <NoInternet
          onPress={onPressReload}
          goToHome={goToHome}
          darkMode={darkMode}
          loading={loading}
        />
      );
    }
    if (pageNotFound) {
      return (
        <PageNotFound goToHome={goToHome} slug={slug} hideHeader={hideHeader} />
      );
    }
    if (isError) {
      return <this._fallbackComponent />;
    }
    return this.props.children;
  }
}

const style = StyleSheet.create({
  heading: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h1,
    color: colors.foxyBlack,
    marginBottom: 8,
    marginTop: 5,
  },
  subHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    textAlign: 'center',
    color: colors.subtitle,
  },
  safeArea: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fallbackComponent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 35,
  },
  buttonText: {
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
    fontSize: size.h2,
    color: colors.foxyBlack,
    fontWeight: '500',
  },
  button: {
    height: 46,
    marginTop: 30,
    width: 180,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: colors.darkBorder,
  },
});
