import {
  View,
  Text,
  TouchableOpacity,
  Linking,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import React from 'react';
import colors from '../../theme/Colors';
import DownloadAppButtons from './DownloadAppButtons';
import ConnectWithUs from './ConnectWithUs';

export default function FoxyPolicies() {
  return (
    <View style={styles.container}>
      <Policies />
      <MediaLinks />
    </View>
  );
}

const Policies = () => (
  <View style={styles.innerContainer}>
    <Text style={styles.heading} dataSet={{ media: ids.heading }}>POLICIES</Text>
    <PolicyLink link="/shipping-policy" text="Payments and Shipping Policy" />
    <PolicyLink link="/terms-of-use" text="Terms of Use" />
    <PolicyLink link="/privacy-policy" text="Privacy Policy " />
  </View>
);

const PolicyLink = ({ link, text }) => (
  <TouchableOpacity onPress={() => Linking.openURL(link)}>
    <Text style={styles.text} dataSet={{ media: ids.text }}>
      {text}
    </Text>
  </TouchableOpacity>
);

const MediaLinks = () => (
  <View style={styles.innerContainer}>
    <View>
      <Text style={styles.heading} dataSet={{ media: ids.heading }}>DOWNLOAD FOXY APP</Text>
      <DownloadAppButtons />
      <Text style={styles.heading} dataSet={{ media: ids.heading }}>CONNECT WITH US</Text>
      <ConnectWithUs />
    </View>
  </View>
);

const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingVertical: '6%',
    alignSelf: 'center',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  innerContainer: {
    width: 'fit-content',
    height: 'fit-content',
    justifyContent: 'center',
  },
  heading: {
    fontSize: 11,
    fontWeight: 900,
    fontFamily: 'Roboto-Bold',
    marginBottom: 12,
    '@media (min-width: 768px)': {
      fontSize: 16,
    },
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontSize: 11,
    fontWeight: 500,
    color: colors.black,
    marginBottom: 8,
    maxWidth: 90,
    ':hover': {
      opacity: 0.7,
    },
    '@media (min-width: 768px)': {
      fontSize: 16,
      maxWidth: 300,
    },
  },
});
