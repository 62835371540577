import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { LAYOUT, CURRENCY_SYMBOL, THEME } from '../../config/Constants';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import ProductAttributesAndProperties from './ProductAttributesAndProperties';

class ProductImageWithDescriptionCard extends PureComponent {
  render() {
    const {
      itemData: {
        id = '',
        sku_id = '',
        name = '',
        mrp = '',
        image_url: imageUrl = '',
        brand = {},
        brand: { name: brandName = '' } = {},
        product_category = {},
      } = {},
      navigation,
    } = this.props;
    const price = `${CURRENCY_SYMBOL}${mrp}`;
    return (
      <View style={styles.container}>
        <FastImageView
          style={styles.image}
          source={Utility.getMinifiedImage(imageUrl)}
          resizeMode='contain'
        />
        <View style={styles.contentContainer}>
          <Text style={styles.brand}>{brandName}</Text>
          <Text style={styles.productDescription}>{name}</Text>
          <Text style={styles.price}>{price}</Text>
          <ProductAttributesAndProperties
            brand={brand}
            productCategory={product_category}
            navigation={navigation}
            id={id}
            sku_id={sku_id}
            addHorizontalPadding={false}
          />
        </View>
      </View>
    );
  }
}

export default ProductImageWithDescriptionCard;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 24,
    paddingHorizontal: 12,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
  },
  image: {
    height: 164,
    width: 164,
  },
  brand: {
    fontFamily: 'Roboto-Medium',
    fontSize: 11,
    fontStyle: 'normal',
    color: '#979BAA',
  },
  productDescription: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginVertical: 4,
  },
  price: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  contentContainer: {
    flexDirection: 'column',
    marginTop: 24,
    paddingLeft: 8,
    width: Utility.getScreenWidth() - 188,
  },
});
