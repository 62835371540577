import React from 'react';
import { View, StyleSheet } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import AddToCart from './AddToCart';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import AppConfig from '../../config/AppConfig';
import { ProductDetailStyles } from './styles';
import RequestProductButton from './RequestProductButton';
import Utility from '../../containers/retailStore/utils/Utility';

const ProductPricingAndAddToCart = (props) => {
  const {
    currentSku,
    selectedVariant,
    itemData,
    avoidPositionAbsolute,
    showToast,
    isDesktopProductPage = false,
  } = props;

  const stockedStatus = Utility.isPresent(selectedVariant)
    ? selectedVariant.stocked_status
    : itemData.stocked_status;

  if (stockedStatus !== 'in_stock') {
    const containerStyle = isDesktopProductPage ? ProductDetailStyles.PricingContainerDesktop : ProductDetailStyles.pricingAndCartContainer;
    return (
      <View style={containerStyle}>
        <RequestProductButton
          skuId={currentSku}
          stocked_status={stockedStatus}
          showToast={showToast}
          extraButtonStyles={isDesktopProductPage && styles.RequestProductButton}
        />
      </View>
    );
  }

  let pricingContainer = avoidPositionAbsolute
    ? ProductDetailStyles.pricingAndCartContainerWithoutAbsolute
    : ProductDetailStyles.pricingAndCartContainer;

  if (isDesktopProductPage) {
    pricingContainer = ProductDetailStyles.PricingContainerDesktop;
  }

  return (
    <View style={pricingContainer}>
      <AddToCart
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
        skuId={currentSku}
        hasVariants={itemData.has_variants}
        selectedVariant={selectedVariant}
        itemData={itemData}
        alwaysShowQuantitySelector={AppConfig.getBooleanValue(
          Config.SHOW_QUANTITY_IN_ADD_TO_BAG_ALWAYS,
        )}
        buyNowButtonStyle={isDesktopProductPage && styles.addToCartButton}
      />
      <AddToCart
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
        skuId={currentSku}
        hasVariants={itemData.has_variants}
        selectedVariant={selectedVariant}
        itemData={itemData}
        alwaysShowQuantitySelector={AppConfig.getBooleanValue(
          Config.SHOW_QUANTITY_IN_ADD_TO_BAG_ALWAYS,
        )}
        isBuyNow={!AppConfig.getBooleanValue(Config.HIDE_BUY_NOW)}
        buyNowButtonStyle={isDesktopProductPage && styles.addToCartButton}
      />
    </View>
  );
};

export default React.memo(ProductPricingAndAddToCart);

const styles = StyleSheet.create({
  addToCartButton: {
    width: 220,
  },
  RequestProductButton: {
    width: 460,
  },
});
