import React, { useState, useCallback, memo } from 'react';
import { StyleSheet, Text, View, FlatList, Image, TouchableWithoutFeedback } from 'react-native';
import { useSelector } from 'react-redux';
import Config from '../../libraries/ReactNativeConfig';
import FastImageView from '../../components/FastImageView';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import RatingContainer from './RatingContainer';
import { AnalyticsManager } from '../../analytics';
import AppConfig from '../../config/AppConfig';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtils';
import { getHitSlop } from '../../utils/LayoutUtility';

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: colors.subtitle,
  },
  footer: {
    height: 1,
    backgroundColor: colors.background,
    marginHorizontal: 16,
    width: getMinifiedImage() - 32,
    alignSelf: 'center',
    marginVertical: 14,
  },
  hiddenFooter: {
    height: 1,
    backgroundColor: colors.white,
    marginHorizontal: 16,
    width: getMinifiedImage() - 32,
    alignSelf: 'center',
    marginVertical: 14,
  },
  allReviewsFooter: {
    height: 2,
    backgroundColor: colors.background,
    marginHorizontal: 16,
    width: getMinifiedImage() - 16,
    alignSelf: 'center',
    marginTop: 16,
  },
  container: {
    backgroundColor: colors.white,
    marginBottom: 0,
    paddingHorizontal: 16,
    paddingVertical: 0,
    paddingTop: 16,
  },
  allReviewsContainer: {
    backgroundColor: colors.white,
    marginBottom: 4,
    paddingHorizontal: 16,
    paddingVertical: 16,
    paddingTop: 4,
  },
  reviewImage: {
    width: 64,
    height: 64,
    marginRight: 16,
    borderRadius: 4,
    resizeMode: 'contain',
  },
  timestamp: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: colors.subtitle,
  },
  verticalTextSeparator: {
    height: 13,
    width: 1,
    backgroundColor: colors.subtitle,
    marginHorizontal: 4,
  },
  verifiedPurchaseText: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: colors.subtitle,
  },
  verifiedPurchaseImage: { width: 12, height: 12, marginRight: 4 },
  verifiedPurchaseContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  verifiedPurchaseAndTimeStamp: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  likeRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  liked: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    marginLeft: 12,
    color: colors.cta.lightBlue,
  },
  unLiked: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    marginLeft: 12,
    color: colors.subtitle,
  },
  likedImage: {
    width: 18,
    height: 16,
    tintColor: colors.cta.lightBlue,
  },
  unLikedImage: {
    width: 18,
    height: 16,
  },
  likeButtonContainer: { flexDirection: 'row' },
  userName: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: colors.subtitle,
    marginBottom: 6,
    marginTop: 4,
  },
  readMore: {
    lineHeight: 21,
    marginTop: 4,
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: colors.cta.lightBlue,
  },
  body: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
    opacity: 0.8,
    lineHeight: 21,
  },
  bodyContainer: { marginVertical: 12 },
  headingContainer: { flexDirection: 'row' },
  ratingContainer: { height: 18 },
  heading: {
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
    marginLeft: 12,
  },
  headingWithoutMargin: {
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
    marginLeft: 0,
  },
});

const CustomerReview = ({
  item,
  navigation,
  variants,
  productId,
  allReviews,
  likeRating = () => {},
  unlikeRating,
  productSlug,
  entity,
  hideFooter = false,
}) => {
  const [like, setLike] = useState(item.likes_count || 0);
  const mediaArray = item.video && item.pictures ? [...item.pictures, item.video] : item.pictures ? item.pictures : [];

  const Heading = () => {
    const headingTextStyle =
      isPresent(item?.subject) && !AppConfig.getBooleanValue(Config.SHOW_FOXY_DEFAULT_RATING_VIEW_ON_PRODUCT)
        ? styles.headingWithoutMargin
        : styles.heading;
    return (
      <View style={styles.headingContainer}>
        <RatingContainer
          rating={item?.star}
          showRatingStar
          styles={styles.ratingContainer}
          rightAlignRating={isBlank(item?.subject)}
        />
        <Text style={headingTextStyle}>{item?.subject}</Text>
      </View>
    );
  };

  const Body = () => {
    const [textShown, setTextShown] = useState(false);
    const [isReadMoreVisible, setReadMoreVisibility] = useState(false);
    const toggleNumberOfLines = () => {
      setTextShown(!textShown);
    };

    const onTextLayout = useCallback((event) => {
      const {
        nativeEvent: {
          lines: { length = 0 },
        },
      } = event;
      setReadMoreVisibility(length >= 7);
    }, []);

    const ReadMoreButton = () => {
      if (!isReadMoreVisible) {
        return null;
      }
      const text = textShown ? 'Read Less...' : 'Read More...';
      return (
        <Text onPress={toggleNumberOfLines} style={styles.readMore}>
          {text}
        </Text>
      );
    };

    return (
      <View style={styles.bodyContainer}>
        <Text onTextLayout={onTextLayout} style={styles.body} numberOfLines={textShown ? 0 : 6}>
          {item?.body}
        </Text>
        {/* <ReadMoreButton /> */}
      </View>
    );
  };

  const Author = () => {
    return <Text style={styles.userName}>{item.user_name}</Text>;
  };

  const LikeRating = () => {
    const likedRatings = useSelector((state) => state?.UserAccountInfo?.favourites?.ratings) || {};
    const likeText = !!likedRatings[`${item.id}`] ? styles.liked : styles.unLiked;

    const likeImage = !!likedRatings[`${item.id}`] ? styles.likedImage : styles.unLikedImage;

    const onLikeRatingPress = () => {
      if (!!likedRatings[`${item.id}`]) {
        AnalyticsManager.logEvent('product_rating_unlike', {
          rating_id: item.id,
          product_id: productId,
        });
        setLike(like - 1);

        unlikeRating({
          id: item.id,
          type: 'rating',
          url: `${productSlug.replace('api/v2', 'api/v1').replace('.json', '')}/ratings/${item.id}/unlike.json`,
          saveToRedux: false,
        });
      } else {
        AnalyticsManager.logEvent('product_rating_like', {
          rating_id: item.id,
          product_id: productId,
        });
        setLike(like + 1);

        likeRating({
          id: item.id,
          type: 'rating',
          url: `${productSlug.replace('api/v2', 'api/v1').replace('.json', '')}/ratings/${item.id}/like.json`,
          saveToRedux: false,
        });
      }
    };

    const RenderNumOfLikes = () => {
      if (like < 1) return null;
      return <Text style={likeText}>{like}</Text>;
    };

    return (
      <TouchableWithoutFeedback onPress={onLikeRatingPress} hitSlop={getHitSlop()}>
        <View style={styles.likeButtonContainer}>
          <Image source={images.likeComment} style={likeImage} />
          <RenderNumOfLikes />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  const VerifiedPurchase = () => {
    if (!item?.verified) {
      return null;
    }
    return (
      <View style={styles.verifiedPurchaseContainer}>
        <Image source={images.verifiedPurchase} style={styles.verifiedPurchaseImage} />
        <Text style={styles.verifiedPurchaseText}>Verified Purchase</Text>
        <View style={styles.verticalTextSeparator} />
      </View>
    );
  };

  const Like = () => {
    return (
      <View style={styles.likeRow}>
        <View style={styles.verifiedPurchaseAndTimeStamp}>
          <VerifiedPurchase />
          <Text style={styles.timestamp}>{Utility.getTimeAgo(item.created_at)}</Text>
        </View>
        <LikeRating />
      </View>
    );
  };

  const fireImageClickedEvent = (id, rating) => {
    AnalyticsManager.logEvent('review_image_clicked', {
      product_id: productId,
      rating_id: id,
      rating,
    });
  };

  const navigateToScreen = (index) => {
    navigation.navigate('ContentModal', {
      listId: 'customer_reviews',
      itemData: item,
      listData: { objects: mediaArray, index, id: 'customer_reviews' },
      index,
      variants,
      entity,
    });
  };

  const renderThumbnail = ({ item: oneItem, index }) => {
    const { id = '', star = 0 } = item;
    const imageUrl = oneItem?.url || oneItem?.image_url;
    const image = getMinifiedImage(imageUrl, 80, 80);
    const onThumbnailPress = () => {
      fireImageClickedEvent(id, star);
      navigateToScreen(index);
    };

    return (
      <TouchableWithoutFeedback onPress={onThumbnailPress}>
        <FastImageView style={styles.reviewImage} source={image} />
      </TouchableWithoutFeedback>
    );
  };

  const keyExtractor = (_, index) => index.toString();

  const RatingImages = () => {
    if (isBlank(mediaArray)) {
      return null;
    }
    return (
      <FlatList
        data={mediaArray}
        keyExtractor={keyExtractor}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={renderThumbnail}
        scrollEnabled
      />
    );
  };

  const container = allReviews ? styles.allReviewsContainer : styles.container;
  const footer = allReviews ? styles.allReviewsFooter : styles.footer;
  const footerStyle = hideFooter ? styles.hiddenFooter : footer;
  return (
    <View style={container}>
      <Heading />
      <Body />
      <RatingImages />
      <Author />
      <Like />
      <View style={footerStyle} />
    </View>
  );
};

export default memo(CustomerReview);
