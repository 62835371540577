import React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  SafeAreaView,
} from 'react-native';
import colors from '../../theme/Colors';

const ModalContainer = ({ children }) => {
  const navigation = useNavigation();
  const goBack = () => {
    navigation.goBack();
  };

  return (
    <>
      <TouchableWithoutFeedback onPress={goBack}>
        <View style={modalStyles.touchableContainer} />
      </TouchableWithoutFeedback>
      <View style={modalStyles.modalContainer} pointerEvents='box-none'>
        <SafeAreaView style={modalStyles.SafeAreaView}>{children}</SafeAreaView>
      </View>
    </>
  );
};

export default ModalContainer;

const modalStyles = StyleSheet.create({
  touchableContainer: {
    height: '100%',
    width: '100%',
  },
  modalContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.translucent,
    left: 0,
    right: 0,
  },
});
