import { StyleSheet, Platform } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import { isDesktop } from '../../../utils/BooleanUtility';

const GridStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  flatlistStyle: {
    overflow: 'visible',
    marginLeft: Utility.padding - Utility.spacingBetweenItems,
    marginRight: Utility.padding - Utility.spacingBetweenItems,
    alignSelf: isDesktop() ? 'center' : null,
    width: isDesktop() ? '100%' : null,
  },
  feedFooterContainer: {
    width: Utility.getScreenWidth(),
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerButtonView: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 16,
    borderColor: colors.foxyPink,
    borderWidth: 1,
  },
  footerTextHeading: {
    textAlign: 'center',
    color: '#979BAA',
    fontSize: 12,
    fontWeight: '400',
  },
  footerButtonText: {
    textAlign: 'center',
    color: colors.cta.lightBlue,
    fontSize: 14,
    marginTop: 8,
    fontWeight: '500',
    marginRight: 4,
  },
  footerChevronImage: {
    tintColor: '#4285F4',
    marginTop: 6,
  },
  footerSearchImage: {
    tintColor: '#4285F4',
    marginTop: 4,
  },
  footerTextAndImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  trashIconContainer: {
    tintColor: colors.foxyBlack,
    position: 'absolute',
    right: 16,
    top: 16,
  },
  trashIcon: {
    resizeMode: 'stretch',
    height: 22,
    width: 22,
    marginBottom: 2,
    tintColor: colors.foxyBlack,
  },
  centerAlign: { alignItems: 'center' },
  activityIndicatorStyle: { marginTop: 12 },
  contentContainerStyle: {
    backgroundColor: colors.background,
    padding: 8,
    ...Platform.select({
      web: { alignItems: 'center' },
      default: {},
    }),
  },
  verticalTabsActivityIndicatorStyle: {
    marginTop: 12,
    right: 50,
  },
});

export default GridStyles;
