import React, { PureComponent } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import _, { filter } from 'lodash';
import PropTypes from 'prop-types';
import { FlatList } from 'react-native-gesture-handler';
import ChipsButton from '../shared/ChipsButton';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import AppConfig from '../../config/AppConfig';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';

class SortingFilteringOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBottomModal: false,
      quickFilterOptions: [],
    };

    this.appliedFiltersFromModal = [];
  }

  componentDidMount = () => {
    const { quickFilters, onSortingFilterApplied, filtersFromDeepLink } =
      this.props;
    const { quickFilterOptions } = this.state;
    if (Utility.isBlank(quickFilters)) return;

    this.resetQuickFilters();
    onSortingFilterApplied(this.updateSortingChipName);
  };

  componentDidUpdate(prevProps) {
    const { selectedFilters: prevSelectedFilters = {} } = prevProps;
    const { selectedFilters = {} } = this.props;
    if (
      JSON.stringify(prevSelectedFilters) !== JSON.stringify(selectedFilters)
    ) {
      this.resetQuickFilters();
    }
  }

  resetQuickFilters = () => {
    const {
      quickFilters,
      filtersFromDeepLink,
      filters: listFilters,
      facialAnalysis: { facialProperties },
    } = this.props;
    let filters = [];
    if (Utility.isBlank(filtersFromDeepLink)) {
      filters = quickFilters.map((element) => ({
        ...element,
        selected: element.preSelected || false,
      }));
    } else {
      filters = quickFilters.map((element) => ({
        ...element,
        selected: filtersFromDeepLink.some((e) => e.value == element.value),
      }));
    }

    this.insertItemAtPosition(filters, 0, {
      leftIcon: images.iconSortFilter,
      criteria_name: 'quick_filter_sort_by',
      tintColor: colors.foxyBlack,
      name: 'Sort & Filter',
      value: 0,
      count: 0,
    });

    const isFoxyMatchPresent = Utility.isPresent(
      listFilters.filter((filter) => filter.display === 'foxy_match'),
    );

    if (
      isFoxyMatchPresent &&
      AppConfig.getBooleanValue(Config.FILTER_MODAL_FOXY_MATCH) &&
      Utility.isPresent(facialProperties)
    ) {
      this.insertItemAtPosition(filters, 1, {
        rightIcon: images.chevronDown,
        criteria_name: 'quick_filter_foxy_match',
        tintColor: colors.foxyBlack,
        name: 'Foxy Match',
        value: 1,
        count: 0,
      });
    }

    this.setState(
      {
        quickFilterOptions: filters,
      },
      () => {
        //  console.log("----", filters);
        const prevFilters = Utility.isPresent(filtersFromDeepLink)
          ? []
          : this.state.quickFilterOptions;
        this.applyQuickFiltersIfSelectedFiltersExists(prevFilters);
      },
    );
  };

  applyQuickFiltersIfSelectedFiltersExists = (prevFilters) => {
    const { selectedFilters } = this.props;

    if (
      Utility.isPresent(selectedFilters) &&
      Utility.isPresent(
        Utility.convertSelectedFiltersObjectIntoArray(selectedFilters),
      )
    ) {
      this.applyFilters(
        prevFilters,
        Utility.convertSelectedFiltersObjectIntoArray(selectedFilters),
      );
    }
  };

  resetQuickFiltersCallback = (filters, element, apply) => {
    let selected = false;
    const index = Utility.indexOfItem(filters, element.value);
    if (index === -1) {
      selected = element.selected;
    } else {
      selected = apply;
    }

    return { ...element, selected };
  };

  setSpecificFilters = (filters = [], apply = false) => {
    const { quickFilterOptions } = this.state;
    // quickFilterOptions = quickFilterOptions.map((element) => _.findIndex(filters, (index) => ({ ...element, selected: index.value !== element.value })));
    const arrayToUse = Utility.isBlank(this.appliedFiltersFromModal)
      ? quickFilterOptions
      : this.appliedFiltersFromModal;
    const newArray = arrayToUse.map((element) =>
      this.resetQuickFiltersCallback(filters, element, apply),
    );
    return newArray;
  };

  resetSpecificFilters = (filters = []) =>
    this.setSpecificFilters(filters, false);

  applyFilters = (prevFilters, nextFilters = [], sortByFilters) => {
    this.appliedFiltersFromModal = this.resetSpecificFilters(prevFilters);
    this.appliedFiltersFromModal = this.setSpecificFilters(nextFilters, true);
    // console.log("Apply Filters ", this.appliedFiltersFromModal),
    this.appliedFiltersFromModal[0].name = Utility.isPresent(sortByFilters)
      ? `Sort: ${sortByFilters[0].name}`
      : 'Sort & Filter';

    setTimeout(() => {
      this.setState(
        {
          quickFilterOptions: this.appliedFiltersFromModal,
        },
        () => this.updateSortingChipCount(),
      );
    }, 0);
  };

  insertItemAtPosition = (arr, index, item) => {
    arr.splice(index, 0, item);
  };

  updateSortingChipName = (name) => {
    const { quickFilterOptions } = this.state;
    quickFilterOptions[0].name = Utility.isBlank(name)
      ? 'Sort & Filter'
      : `Sort: ${name}`;
    this.setState({
      quickFilterOptions,
    });
  };

  updateSortingChipCount = () => {
    const { quickFilterOptions } = this.state;
    const { selectedFilters } = this.props;

    const groupByCriteriaNameObject = _.groupBy(
      Utility.convertSelectedFiltersObjectIntoArray(selectedFilters),
      (element) => element.criteria_name,
    );

    // const selectedQuickFiltersCount = quickFilterOptions.filter((element) => element.selected);
    const quickFiltersCopy = [...quickFilterOptions];
    const keys = Object.keys(groupByCriteriaNameObject);
    if (keys.indexOf('sort_by') == -1) {
      quickFiltersCopy[0].count = keys.length;
    } else {
      const count = keys.length - 1;
      quickFiltersCopy[0].count = count;
    }

    this.setState({
      quickFilterOptions: quickFiltersCopy,
    });
  };

  sortingOptionsView = ({ item, index }) => {
    const { unselectedChipBackgroundColor, selectedChipBackGroundColor } =
      this.props;
    return (
      <ChipsButton
        criteriaName={item.criteria_name}
        chipLeftIcon={item.leftIcon}
        foxyMatchRightIcon={item?.rightIcon}
        chipName={item.name}
        count={item.count}
        isSelected={item.selected}
        onChipClick={() => this.onChipClick(item, true, index)}
        onQuickFilterRemoved={() => this.onChipClick(item, false, index)}
        chipRightIcon={item.selected ? images.chevronDown : null}
        colorCode={item.colour_code}
        unselectedChipBackgroundColor={unselectedChipBackgroundColor}
        selectedChipBackGroundColor={selectedChipBackGroundColor}
        tintColor={item.tintColor}
        index={index}
      />
    );
  };

  highlightSelectedChip = (item, added, index) => {
    const { quickFilterOptions } = this.state;
    const { onQuickFilterPress } = this.props;
    const { criteria_name } = item;
    if (
      criteria_name !== 'quick_filter_sort_by' &&
      criteria_name !== 'quick_filter_foxy_match'
    ) {
      const quickFilterOptionsWithSelectedKey = quickFilterOptions.map(
        (element) =>
          element.name === item.name
            ? { ...element, selected: added }
            : { ...element },
      );
      this.setState(
        {
          quickFilterOptions: quickFilterOptionsWithSelectedKey,
        },
        () => this.updateSortingChipCount(),
      );
    }
    onQuickFilterPress(item, added, index);
  };

  onChipClick = (item, added = false, index) => {
    const { selected } = item;
    const { scrollY } = this.props;
    if (selected) {
      this.highlightSelectedChip(item, false, index);
      return;
    }
    this.highlightSelectedChip(item, added, index);
    this.showBottomModal();
  };

  showBottomModal = () => {
    this.setState({
      showBottomModal: true,
    });
  };

  hideBottomModal = () => {
    this.setState({
      showBottomModal: false,
    });
  };

  listFooterView = () => <View style={{ padding: 8 }} />;

  render() {
    const { showBottomModal, quickFilterOptions } = this.state;
    return (
      <View style={styles.flatListContainer}>
        <FlatList
          // extraData={this.state.quickFilterOptions}
          data={this.state.quickFilterOptions}
          horizontal
          showsHorizontalScrollIndicator={false}
          renderItem={this.sortingOptionsView}
          ListFooterComponent={this.listFooterView}
          style={{ paddingLeft: 8, paddingRight: 8 }}
          extraData={this.props.selectedFilters}
        />

        {/*
        <SortingFilteringModal
          isVisible={showBottomModal}
          onPressOutside={this.hideBottomModal}
          filterModel={quickFilterOptions}
        /> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  flatListContainer: {
    flexDirection: 'column',
  },
});

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
});

export default connect(mapStateToProps, null)(SortingFilteringOptions);

// SortingFilteringOptions.prototype = {
//   enableSortingOptions: PropTypes.bool,
//   sortingOptions: PropTypes.array,
// };

// SortingFilteringOptions.defaultProps = {
//   enableSortingOptions: false,
//   sortingOptions: [],
// };
