import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

import colors, { Theme } from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

let height = Utility.getScreenHeight();
let width = Utility.getScreenWidth();

if (height < width) {
  let temp = height;
  height = width;
  width = temp;
}

export const { ids, styles } = StyleSheet.create({
  modalContainerStyle: {
    height: height / 1.5,
  },
  modal: {
    height: '100%',
  },
  touchableContainer: { height: '100%', width: '100%' },
  loginInfoContainer: {
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    height: height / 2.2,
    backgroundColor: Theme.light.backgroundColor,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingBottom: 12,
    width,
    '@media (min-width: 800px)': {
      bottom: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 550,
      height: 400,
    },
  },

  appLogoContainer: {
    flex: 0.35,
    alignItems: 'center',
  },

  form: {
    flex: Utility.isIOS() ? 0.7 : 0.6,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    '@media (min-width: 800px)': {
      width: '100%',
      paddingHorizontal: 22,
    },
  },

  logo: {
    width: width / 2.2,
    height: height / 12.5,
    resizeMode: 'contain',
    marginTop: height / 112,
  },

  textInputContainer: {
    flexDirection: 'row',
    width: width / 1.154,
    height: height / 14.92,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Theme.light.backgroundColor,
    color: Theme.light.textColor,
  },

  phoneNumberContainer: {
    flex: 0.8,
    flexDirection: 'row',
    alignItems: 'center',
  },

  callingCode: Platform.select({
    ios: { marginRight: 5 },
    android: { color: Theme.light.textColor },
    web: {},
  }),

  warning: {
    color: 'red',
    alignSelf: 'flex-start',
  },

  enterPhoneNumberHeader: {
    fontFamily: 'Roboto-Regular',
    fontSize: 20,
    // width: 312,

    color: colors.foxyBlack,
  },
  skipButton: {
    fontFamily: 'Roboto-Regular',
    marginTop: 5,
    fontSize: size.h3,
    color: colors.foxyBlue,
  },
  rowTitle: {
    marginBottom: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: width - 48,
  },
  titleContainer: {
    alignSelf: 'flex-start',
    marginLeft: 24,
    marginTop: 12,
  },
  loginModalImage: {
    height: 140,
    width: Utility.getScreenWidth() - 24,
    marginTop: 12,
  },
  enterPhoneNumberTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    color: colors.foxyBlack,
  },
  enterPhoneNumberSubTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.foxyBlack,
    width: width - 50,
    marginTop: 2,
  },
  crossStyle: {
    position: 'absolute',
    top: 10,
    right: 10,
    height: 23,
    width: 23,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyView: {
    backgroundColor: colors.white,
    height: 20,
  },
  mobileNumberInput: {
    width: Utility.getScreenWidth() - 48,
    marginBottom: 15,
    '@media (min-width: 800px)': {
      width: '100%',
    },
  },
  keyboardAvoidingView: {
    ...Platform.select({
      web: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        flex: 1,
      },
      default: {},
    }),
    '@media (min-width: 768px)': {
      position: 'static',
      width: null,
      bottom: null,
      flex: null,
    },
  },
});
