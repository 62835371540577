import { takeLatest } from 'redux-saga/effects';
import {
  GET_USER_ATTRIBUTE_VALUES,
  POST_USER_ATTRIBUTE_VALUES,
} from '../actions/ActionTypes';
import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

function* userAttributeValues(action) {
  const { id, callback } = action;

  const url = `${URL.USER_ATTRIBUTE_VALUES}?product_category_id=${id}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
      return;
    }
    callback(false, {});
  } catch (error) {
    console.log('error in fetch user attribute values', error);
    callback(false, {});
  }
}

function* saveUserAttributeValues(action) {
  const { requestData = {}, callback } = action;

  const url = `${URL.USER_ATTRIBUTE_VALUES}`;
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(requestData),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(true, json);
      return;
    }
    callback(false, {});
  } catch (error) {
    console.log('error in post user attribute values', error);
    callback(false, {});
  }
}

export default function* watchUserAttributeValues() {
  yield takeLatest(GET_USER_ATTRIBUTE_VALUES, userAttributeValues);
  yield takeLatest(POST_USER_ATTRIBUTE_VALUES, saveUserAttributeValues);
}
