import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Animated,
  Easing,
  StyleSheet,
  Text,
  Alert,
  Platform,
} from 'react-native';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import LottieView from '../../libraries/LottieView';
import { connect } from 'react-redux';
import images from '../../theme/Images';
import sharedStyles from './styles';
import {
  LAYOUT,
  LOGIN_MODAL_MESSAGES,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import Utility from '../../utils/Utility';
import {
  like,
  unLike,
  updateWishlistToolTip,
  wishlistCrossClicked,
} from '../../actions/ActionTypes';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { withEither } from '../../lib/Monads';
import colors from '../../theme/Colors';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
  EventParameterValue,
} from '../../analytics';
import animations from '../../theme/Animations';
import {
  videoControlsViewStyles as styles,
  playerMiniControlsStyles,
} from '../media/Style';
import AddedToWishlistDeals from '../Product/AddedToWishlistDeals';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import AnimatedLottieView from '../../libraries/LottieView';
import RemoteConfig from '../../utils/RemoteConfig';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import WithNavigation from '../../utils/WithNavigation';
import NavigationService from '../../navigator/NavigationService';
import { navigateToScreen } from '../../utils/NavigationUtility';

class AnimatedLikeButton extends Component {
  constructor(props) {
    super(props);
    const {
      itemData: { sku: { flash_deal_discount_percentage = '' } = {}, id } = {},
      selectedVariant: {
        sku: {
          flash_deal_discount_percentage:
            selectedVariantDiscountPercentage = '',
        } = {},
      } = {},
      selectedVariant = {},
      lastLike,
      noOfTimesWishlistTooltipShown,
    } = this.props;
    this.state = {
      liked: false,
      progress: new Animated.Value(0),
      isItemPresentInTodayDeals: {},
      showModal: false,
    };

    this.wishlistDiscount = flash_deal_discount_percentage;
    this.hideTooltipOrAnimation = false;

    this.hideTooltipOrAnimation = noOfTimesWishlistTooltipShown.includes(id);

    if (Utility.isPresent(selectedVariant)) {
      this.wishlistDiscount = selectedVariantDiscountPercentage;
    }

    const lastWishlistedItemTimes = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.last_wishlisted_item_time,
    );

    this.hideTooltipOrAnimation = Utility.isSomethingSeenToday(
      lastLike,
      lastWishlistedItemTimes,
    );
  }

  componentDidMount = () => {
    const {
      liked,
      itemData,
      screenName,
      noOfTimesWishlistTooltipShown,
      updateWishlistToolTip,
    } = this.props;
    if (liked) {
      this.likeAnimation();
    }

    if (screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL && !!itemData?.id) {
      updateWishlistToolTip({ id: itemData?.id });
    }
  };

  updateLikedState = () => {
    const {
      authToken,
      mrp = '',
      skuId = '',
      onLike,
      like,
      unLike,
      setPhoneNumberModalInfo,
      showToast,
      likedItemSkuId,
      itemData = {},
      selectedVariant = {},
      todayDeals: { skus = {} } = {},
      id,
      type,
      showLiked,
      slug,
      screenName,
      itemName,
      isFromTooltip = false,
      liked,
    } = this.props;
    const { sku_id } = itemData;
    const { sku_id: selectedVariantSkuId = '' } = selectedVariant;

    let skuToAdd = sku_id;
    if (Utility.isPresent(selectedVariant)) {
      skuToAdd = selectedVariantSkuId;
    }

    if (
      Utility.isBlank(authToken) &&
      (type === 'product' || type === 'variant')
    ) {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('LIKE_PRODUCT'));
      NavigationService.renderOnboarding({
        furtherAction: this.updateLikedState,
      });
      Utility.invokeSelfieFlowAfterLoginForGuest(false);
      return;
    }

    const data = {
      id,
      type,
    };
    const analyticsMeta = {
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.ITEM_ID]: data.id,
      [EventParameterKey.ITEM_NAME]: itemName,
      [EventParameterKey.ITEM_TYPE]: data.type,
      sku_id: skuToAdd,
      wishlist_deal_discount: this.wishlistDiscount,
      wishdeal_price: skus[`${skuToAdd}`]?.esp || 'not_eligible',
      wishdeal_hint: isFromTooltip,
    };
    this.setState({ liked: !showLiked });

    if (
      showLiked ||
      (liked &&
        AppConfig.getBooleanValue(Config.UNLIKE_WHISHLIST_PRODUCT_IF_LIKED))
    ) {
      unLike(data);
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.ITEM_UNLIKE,
        analyticsMeta,
      );
    } else {
      like(data, (todayDealsSkus) => {
        if (Utility.isPresent(this.wishlistDiscount)) {
          AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
            [EventParameterKey.SOURCE]: todayDealsSkus[`${likedItemSkuId}`]
              ? 'wishdeal_active'
              : 'wishdeal_inactive',
          });

          this.setState({
            isItemPresentInTodayDeals:
              todayDealsSkus[`${likedItemSkuId}`] || {},
            showModal: true,
          });
        }
      });
      showToast(true, 'Added to wishlist');
      try {
        AnalyticsManager.logEvent(
          EventType.discoveryEvents.ITEM_LIKE,
          analyticsMeta,
        );
        AnalyticsManager.logFirebaseEvent(
          EventType.googleRemarketingEvents.ADD_TO_WISHLIST,
          {
            [EventParameterKey.CURRENCY]: 'INR',
            [EventParameterKey.VALUE]: mrp, // TODO: Get value when this is a product card
            [EventParameterKey.ITEMS]: [{ id: skuId }],
          },
        );
        AnalyticsManager.logFBStandardEvent(
          EventType.FB.EVENT_NAME_ADDED_TO_WISHLIST,
          mrp,
          {
            [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: 'INR',
            [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: `${skuId}`,
            [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
          },
        );
        onLike();
      } catch (e) {
        console.log(e);
      }
    }
  };

  navigationHeaderLikeButton = (props) => {
    const { id, type, slug, showLiked } = props;
    const styles = sharedStyles;
    const likeImageStyle = showLiked
      ? buttonStyles.likedImage
      : buttonStyles.unLikedImage;
    const source = showLiked ? images.likeImage : images.unlikeImage;
    return (
      <TouchableOpacity
        style={styles.likeButtonContainerForHeader}
        onPress={this.updateLikedState}
        hitSlop={Utility.getHitSlop('likeButton')}
      >
        <Image source={source} style={likeImageStyle}/>
      </TouchableOpacity>
    );
  };

  listOrCardLikeButton = (props) => {
    const { showLiked, conditionalRenderFn, layout, tintColor } = props;
    if (conditionalRenderFn && conditionalRenderFn(layout)) return null;
    const unlikeImage =
      layout === LAYOUT.CARD ? images.unlikeImageWhite : images.unlikeImage;
    const styles = sharedStyles;
    const likeImageStyle = showLiked
      ? buttonStyles.likedImage
      : buttonStyles.unLikedImage;
    const source = showLiked ? images.likeImage : images.unlikeImage;
    return (
      <View style={styles.likeButtonStyle}>
        <TouchableOpacity
          onPress={this.updateLikedState}
          hitSlop={Utility.getHitSlop('likeButton')}
        >
          <Image source={source} style={likeImageStyle}/>
        </TouchableOpacity>
      </View>
    );
  };

  onToolTipPress = () => {
    const { id, type, liked, slug } = this.props;

    this.updateLikedState(id, type, liked, slug, true);
  };

  favoriteButton = () => {
    const {
      id,
      type,
      liked,
      slug,
      layout = 'list',
      withoutShadow = false,
      screenName,
    } = this.props;

    let style =
      layout === LAYOUT.CARD || LAYOUT.DYNAMIC_CARD
        ? buttonStyles.container
        : buttonStyles.containerWhite;

    if (withoutShadow) {
      style = styles.likeButtonStyleWithoutShadow;
    }

    if (
      screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL &&
      AppConfig.getBooleanValue(
        Config.PRODUCT_PAGE_DISABLE_WISHLIST_ICON_SHADOW_AND_BORDER,
      )
    ) {
      style = styles.likeButtonProductDetailStyle;
    }

    const unlikeImage =
      layout === LAYOUT.CARD ? images.unlikeImageWhite : images.unlikeImage;

    return (
      <View>
        <this.toolTip onPress={this.onToolTipPress} />
        <TouchableOpacity
          testID="like-button"
          accessibilityLabel="like-button"
          style={style}
          onPress={this.updateLikedState}
          hitSlop={Utility.getHitSlop('likeButton')}
        >
          <this.animatedGifForLike unlikeImage={unlikeImage} />
        </TouchableOpacity>
      </View>
    );
  };

  onHintGifLayout = () => {
    const {
      itemData: { sku_id = '' } = {},
      selectedVariant: { sku_id: selectedVariantSkuId = '' } = {},
      selectedVariant = {},
      todayDeals: { skus = {} } = {},
    } = this.props;

    let skuToAdd = sku_id;

    if (Utility.isPresent(selectedVariant)) {
      skuToAdd = selectedVariantSkuId;
    }

    AnalyticsManager.logEvent(EventType.profileEvents.HEART_ICON_VIEW, {
      [EventParameterKey.wishlist_deal_discount]:
        this.wishlistDiscount || 'not_eligible',
      [EventParameterKey.flashdeal_active]: !!skus[skuToAdd]?.esp,
    });
  };

  animatedGifForLike = ({ unlikeImage }) => {
    const {
      screenName,
      itemData: { sku: { flash_deal_discount_percentage = '' } = {} } = {},
      selectedVariant: {
        sku: {
          flash_deal_discount_percentage:
            selectedVariantDiscountPercentage = '',
        } = {},
      } = {},
      selectedVariant = {},
      noOfTimesWishlistTooltipShown,
      isWishlistCrossClicked,
    } = this.props;

    const wishlist_tooltip = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.wishlist_tooltip),
    );

    const { no_of_time_show_hint, no_of_time_show_tooltip } = wishlist_tooltip;

    this.wishlistDiscount = flash_deal_discount_percentage;
    if (Utility.isPresent(selectedVariant)) {
      this.wishlistDiscount = selectedVariantDiscountPercentage;
    }

    let imageStyle = buttonStyles.likeImage;
    if (screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL) {
      imageStyle = buttonStyles.likeImageProductDetail;
    }

    if (
      Utility.isBlank(this.wishlistDiscount) ||
      screenName !== SCREEN_CONSTANTS.PRODUCT_DETAIL ||
      this.hideTooltipOrAnimation ||
      (noOfTimesWishlistTooltipShown.length <
        parseInt(no_of_time_show_tooltip) &&
        !isWishlistCrossClicked)
    ) {
      return <Image source={unlikeImage} style={imageStyle} />;
    }

    if (
      noOfTimesWishlistTooltipShown.length >
        parseInt(no_of_time_show_tooltip) &&
      noOfTimesWishlistTooltipShown.length <=
        parseInt(no_of_time_show_tooltip) + parseInt(no_of_time_show_hint)
    ) {
      return <this.likeAnimationLottie />;
    }

    if (
      noOfTimesWishlistTooltipShown.length >
      parseInt(no_of_time_show_tooltip) + parseInt(no_of_time_show_hint)
    ) {
      return <Image source={unlikeImage} style={buttonStyles.likeImage} />;
    }

    return <this.likeAnimationLottie />;
  };

  likeAnimationLottie = () => (
    <AnimatedLottieView
      source={animations.itemLike}
      style={buttonStyles.likeGifForHint}
      autoPlay
      loop={true}
      resizeMode="contain"
      duration={2500}
      onLayout={this.onHintGifLayout}
    />
  );

  onToolTipLayout = () => {
    const {
      itemData: { sku_id = '' } = {},
      selectedVariant: { sku_id: selectedVariantSkuId } = {},
      selectedVariant = {},
      todayDeals: { skus = {} } = {},
    } = this.props;
    let skuToAdd = sku_id;

    if (Utility.isPresent(selectedVariant)) {
      skuToAdd = selectedVariantSkuId;
    }
    AnalyticsManager.logEvent(EventType.profileEvents.tooltip_view, {
      [EventParameterKey.wishlist_deal_discount]:
        this.wishlistDiscount || 'not_eligible',
      [EventParameterKey.flashdeal_active]: !!skus[skuToAdd]?.esp,
    });
  };

  onToolTipClicked = () => {
    const {
      itemData: { sku_id = '' } = {},
      selectedVariant: { sku_id: selectedVariantSkuId } = {},
      todayDeals: { skus = {} } = {},
      selectedVariant = {},
    } = this.props;
    let skuToAdd = sku_id;

    if (Utility.isPresent(selectedVariant)) {
      skuToAdd = selectedVariantSkuId;
    }
    this.onToolTipPress();
    AnalyticsManager.logEvent(EventType.profileEvents.tooltip_action, {
      [EventParameterKey.ACTION]: 'click',
      [EventParameterKey.wishlist_deal_discount]:
        this.wishlistDiscount || 'not_eligible',
      [EventParameterKey.flashdeal_active]: !!skus[skuToAdd]?.esp,
    });
  };

  onToolTipCancel = () => {
    const {
      itemData: { sku_id = '' } = {},
      selectedVariant: { sku_id: selectedVariantSkuId = '' } = {},
      selectedVariant = {},
      todayDeals: { skus = {} } = {},
      wishlistCrossClicked,
    } = this.props;
    let skuToAdd = sku_id;

    if (Utility.isPresent(selectedVariant)) {
      skuToAdd = selectedVariantSkuId;
    }

    AnalyticsManager.logEvent(EventType.profileEvents.tooltip_action, {
      [EventParameterKey.ACTION]: 'dismiss',
      [EventParameterKey.wishlist_deal_discount]:
        this.wishlistDiscount || 'not_eligible',
      [EventParameterKey.flashdeal_active]: !!skus[skuToAdd]?.esp,
    });
    wishlistCrossClicked();
  };

  toolTip = (props) => {
    const {
      screenName,
      itemData = {},
      selectedVariant = {},
      todayDeals: { skus = {} } = {},
      noOfTimesWishlistTooltipShown,
      isWishlistCrossClicked,
    } = this.props;
    const {
      sku: {
        flash_deal_discount_percentage: selectedVariantDiscountPercentage = '',
      } = {},
    } = selectedVariant;
    const { sku: { flash_deal_discount_percentage = '' } = {} } = itemData;

    this.wishlistDiscount = flash_deal_discount_percentage;
    if (Utility.isPresent(selectedVariant)) {
      this.wishlistDiscount = selectedVariantDiscountPercentage;
    }
    const wishlist_tooltip = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.wishlist_tooltip),
    );

    const { no_of_time_show_hint, no_of_time_show_tooltip } = wishlist_tooltip;

    if (
      isWishlistCrossClicked ||
      Utility.isBlank(this.wishlistDiscount) ||
      screenName !== SCREEN_CONSTANTS.PRODUCT_DETAIL ||
      this.hideTooltipOrAnimation ||
      noOfTimesWishlistTooltipShown.length > parseInt(no_of_time_show_tooltip)
    ) {
      return null;
    }

    return (
      <View>
        <TouchableOpacity
          style={buttonStyles.tooltipContainer}
          onPress={this.onToolTipClicked}
          onLayout={this.onToolTipLayout}
        >
          <Text style={buttonStyles.tooltipText}>
            Wishlist now to see the magic ✨
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={buttonStyles.tooltipCrossContainer}
          hitSlop={Utility.getHitSlop('like')}
          onPress={this.onToolTipCancel}
        >
          <Image
            source={images.blackCross}
            style={buttonStyles.tooltipCrossIcon}
          />
        </TouchableOpacity>
      </View>
    );
  };

  likeAnimation = () => {
    const { progress } = this.state;
    Animated.timing(progress, {
      toValue: 1,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };

  unlikeAnimation = () => {
    const { progress } = this.state;
    Animated.timing(progress, {
      toValue: 0,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };

  likedAnimatedView = () => {
    const { onFavoriteTapped } = this.props;
    const {
      id,
      type,
      liked,
      slug,
      layout = 'list',
      conditionalRenderFn,
      tintColor = null,
      addToCartLikeButton,
      withoutShadow = false,
      screenName,
    } = this.props;
    let style =
      layout === LAYOUT.CARD || LAYOUT.DYNAMIC_CARD
        ? buttonStyles.container
        : buttonStyles.containerWhite;

    if (withoutShadow) {
      style = styles.likeButtonStyleWithoutShadow;
    }

    if (
      screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL &&
      AppConfig.getBooleanValue(
        Config.PRODUCT_PAGE_DISABLE_WISHLIST_ICON_SHADOW_AND_BORDER,
      )
    ) {
      style = styles.likeButtonProductDetailStyle;
    }

    this.likeAnimation();
    return (
      <TouchableOpacity
        style={style}
        onPress={this.updateLikedState}
        hitSlop={Utility.getHitSlop('likeButton')}
      >
        <LottieView
          source={animations.like}
          style={playerMiniControlsStyles.favoriteButtonIconAnimation}
          resizeMode="cover"
          progress={this.state.progress}
        />
      </TouchableOpacity>
    );
  };

  productPageLeftSplitChildLikeButton = (props) => {
    const { showLiked, conditionalRenderFn, layout } = props;
    if (conditionalRenderFn && conditionalRenderFn(layout)) return null;
    if (showLiked) {
      return <this.likedAnimatedView />;
    }

    return <this.favoriteButton />;
  };

  updateLikeOnDoubleTap = () => {
    this.updateLikedState();
  };

  listOrCardConditionFn = (props) =>
    props.layout === LAYOUT.LIST || props.layout === LAYOUT.CARD;

  likeButton = compose(
    withEither(this.listOrCardConditionFn, this.listOrCardLikeButton),
  )(this.navigationHeaderLikeButton);

  onModalClose = () => {
    const { isItemPresentInTodayDeals } = this.state;
    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_CLOSE, {
      [EventParameterKey.SOURCE]: Utility.isPresent(isItemPresentInTodayDeals)
        ? 'wishdeal_active'
        : 'wishdeal_inactive',
    });
    this.setState({
      showModal: false,
    });
  };

  onButtonClick = () => {
    const { isItemPresentInTodayDeals } = this.state;
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_ACTION, {
      [EventParameterKey.SOURCE]: Utility.isPresent(isItemPresentInTodayDeals)
        ? 'wishdeal_active'
        : 'wishdeal_inactive',
    });
    this.setState(
      {
        showModal: false,
      },
      () => {
        navigateToScreen({
          navigation,
          type: 'navigate',
          screen: 'TodayDeals',
        });
      },
    );
  };

  render() {
    const {
      id,
      type,
      liked,
      slug,
      layout = 'list',
      conditionalRenderFn,
      tintColor = colors.transparent,
      navigation,
    } = this.props;
    const { isItemPresentInTodayDeals, showModal } = this.state;

    const wishlistModalData = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.wishlist_modal_data),
    );

    const {
      heading: modalHeading,
      subheading,
      heading_flashdeal_inactive,
      subheading_flashdeal_inactive,
    } = wishlistModalData;

    const heading = Utility.isPresent(isItemPresentInTodayDeals)
      ? modalHeading
      : Utility.replacePlaceHolders(
          heading_flashdeal_inactive,
          this.wishlistDiscount,
        );

    const subHeading = Utility.isPresent(isItemPresentInTodayDeals)
      ? Utility.replacePlaceHolders(subheading, this.wishlistDiscount)
      : subheading_flashdeal_inactive;

    const animation = Utility.isPresent(isItemPresentInTodayDeals)
      ? animations.paymentSuccess
      : animations.itemLikeNoBg;

    const buttonText = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.checkout_wishlist_deals,
    );

    return (
      <View>
        <this.productPageLeftSplitChildLikeButton
          id={id}
          type={type}
          showLiked={liked}
          slug={slug}
          layout={layout}
          conditionalRenderFn={conditionalRenderFn}
          tintColor={tintColor}
        />
        <AddedToWishlistDeals
          isClubbableModalVisible={showModal}
          navigation={navigation}
          heading={heading}
          subHeading={subHeading}
          buttonText={buttonText}
          onModalClose={this.onModalClose}
          animation={animation}
          onButtonClick={this.onButtonClick}
          isItemPresentInTodayDeals={Utility.isPresent(
            isItemPresentInTodayDeals,
          )}
        />
      </View>
    );
  }
}

AnimatedLikeButton.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  authToken: PropTypes.string,
  mrp: PropTypes.string,
  skuId: PropTypes.string,
  layout: PropTypes.string,
  screenName: PropTypes.string,
  itemName: PropTypes.string,
  liked: PropTypes.bool,
  addToCartLikeButton: PropTypes.bool,
  tintColor: PropTypes.string,
  setPhoneNumberModalInfo: PropTypes.func,
  conditionalRenderFn: PropTypes.func,
  like: PropTypes.func,
  unLike: PropTypes.func,
  onLike: PropTypes.func,
  showToast: PropTypes.func,
};

AnimatedLikeButton.defaultProps = {
  authToken: null,
  mrp: '',
  skuId: '',
  layout: null,
  liked: false,
  addToCartLikeButton: false,
  tintColor: null,
  screenName: 'Unknown',
  itemName: 'Unknown',
  setPhoneNumberModalInfo: () => {},
  conditionalRenderFn: () => {},
  like: () => {},
  unLike: () => {},
  onLike: () => {},
  showToast: () => {},
};

const mapStateToProps = (store, ownProps) => {
  const { id } = ownProps;

  return {
    liked: !!(
      store.UserAccountInfo.favourites[`${ownProps.type}s`] &&
      store.UserAccountInfo.favourites[`${ownProps.type}s`][id]
    ),
    authToken: store.UserAccountInfo.authToken,
    todayDeals: store.todayDeals,
    noOfTimesWishlistTooltipShown: store.userInteractions.wishlistTooltip,
    lastLike: store.userInteractions.lastLike,
    isWishlistCrossClicked: store.userInteractions.isWishlistCrossClicked,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      like,
      unLike,
      setPhoneNumberModalInfo,
      updateWishlistToolTip,
      wishlistCrossClicked,
    },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(AnimatedLikeButton),
);

const buttonStyles = StyleSheet.create({
  containerWhite: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 0,
    backgroundColor: colors.white,
    elevation: 5,
    borderRadius: 16,
    shadowColor: colors.shadowGrey,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
  container: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 0,
    borderRadius: 16,
  },
  likeImage: {
    width: 20,
    height: 18,
    overflow: 'visible',
    resizeMode: 'contain',
  },
  likeImageProductDetail: {
    width: 24,
    height: 24,
    overflow: 'visible',
    resizeMode: 'contain',
  },
  tooltip: {
    width: 132,
    height: 44,
    resizeMode: 'contain',
  },
  likeGifForHint: {
    ...Platform.select({
      web: { width: 100, height: 100 },
      default: { width: 40, height: 80 },
    }),
  },
  tooltipContainer: {
    position: 'absolute',
    right: 0,
    top: -48,
    backgroundColor: colors.white_background,
    width: 131,
    height: 44,
    borderRadius: 4,
  },
  tooltipText: {
    position: 'absolute',
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  tooltipCrossContainer: {
    position: 'absolute',
    top: -70,
    right: 0,
    backgroundColor: colors.white_background,
    borderRadius: 9,
    height: 18,
    width: 18,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
  },
  tooltipCrossIcon: {
    height: 8,
    width: 8,
    tintColor: colors.foxyBlack,
    overflow: 'visible',
  },
  likedImage: {
    width: 20,
    height: 18,
    marginTop: 2,
    resizeMode: 'stretch',
    tintColor: colors.transparent,
  },
  unLikedImage: {
    width: 20,
    height: 18,
    marginTop: 2,
    resizeMode: 'stretch',
    tintColor: colors.foxyBlack,
  },
});
