import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Animated,
  BackHandler,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Share from '../../libraries/ReactNativeShare';
import PropTypes from 'prop-types';
import { CUSTOMIZED_SKU_FORM_PAGE, CURRENCY_SYMBOL } from '../../config/Constants';
import Toast from 'react-native-easy-toast';
import { ProductInfoStyles, ProductDetailStyles } from './styles';
import Images from '../../theme/Images';
import Utility from '../../utils/Utility';
import { withMaybe } from '../../lib/Monads';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import VariantSectionList from './VariantSectionList';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { BagIcon } from '../header';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import AppConfig from '../../config/AppConfig';
import VariantUtility from '../../utils/VariantUtility';
import ProductPricingAndAddToCart from './ProductPricingAndAddToCart';

class FullScreenVariant extends Component {
  constructor(props) {
    super(props);
    this.specialPriceVariant = [];
    this.premiumVariant = [];
    this.moreVariant = [];
    this.soldOutVariant = [];
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
    const {
      route: {
        params: {
          selectedVariant = {},
          selectedMultiVariantPrimary = {},
          selectedMultiVariantSecondary = {},
        } = {},
      } = {},
    } = this.props;
    this.state = {
      selectedVariant,
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
    };
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onSocialShareOptionTapped = (medium) => {
    const {
      route: {
        params: {
          itemData: {
            share_url: shareUrl,
            name,
            brand,
            id,
            type: content,
          } = {},
          isProduct = false,
          screenName,
          itemData = {},
          fromPersonalizedPage,
        } = {},
      } = {},
    } = this.props;
    const skuId = Utility.getSkuId(itemData);
    let sharedUrl = shareUrl; // to change url when triggered from offer detail page
    let title = name;
    let message = '';
    if (fromPersonalizedPage) {
      sharedUrl = `${sharedUrl}?fromPersonalizedPage=true`;
    }

    if (isProduct) {
      title = `${name} ${brand.name}`;
    }
    if (screenName === SCREEN_CONSTANTS.ARTIST_DETAILS) {
      message = `Follow ${name} to catch their latest videos on ${Config.APP_NAME}, the best platform for all your beauty needs.`;
    } else if (screenName === SCREEN_CONSTANTS.PRODUCT_DETAIL) {
      message = `Check out ${name} on ${Config.APP_NAME}`;
    } else if (screenName === SCREEN_CONSTANTS.BRAND_DETAILS) {
      message = `Check out top product and offers of ${name} on ${Config.APP_NAME}, the best platform for all your beauty needs`;
    } else if (screenName === SCREEN_CONSTANTS.MORE_PAGE) {
      message = `Check out this collection on ${Config.APP_NAME}, the best platform for all your beauty needs.`;
    } else if (screenName === SCREEN_CONSTANTS.OFFER_DETAIL) {
      message = `Check out this offer of ${name} on ${Config.APP_NAME}, the best platform for all your beauty needs.`;
    }
    if (Utility.isBlank(sharedUrl)) {
      this.toast.show('An unexpected error occurred');
      return;
    }
    const options = {
      title: Config.APP_NAME,
      message,
      url: sharedUrl,
      // social: Share.Social[_.upperCase(medium)],
    };
    if (Utility.isPresent(id) && Utility.isPresent(content)) {
      let contentType = content;
      let screen_name = screenName;
      this.logAnalyticsEvent(screen_name, id, name, contentType, medium, skuId);
    }
    Share.open(options)
      .then((res) => {})
      .catch((err) => {});
  };

  logAnalyticsEvent = (...meta) => {
    const [
      screen_name = '',
      id,
      name = '',
      contentType,
      medium = '',
      skuId = '',
    ] = meta;

    AnalyticsManager.logEvent(EventType.miscAppEvents.ITEM_SHARE, {
      [EventParameterKey.SCREEN_NAME]: screen_name,
      [EventParameterKey.ITEM_ID]: id,
      [EventParameterKey.ITEM_NAME]: name,
      [EventParameterKey.ITEM_TYPE]: contentType,
      [EventParameterKey.MEDIUM]: medium,
    });
    AnalyticsManager.logFirebaseEvent(EventType.googleRemarketingEvents.SHARE, {
      [EventParameterKey.CONTENT_TYPE]: contentType,
      [EventParameterKey.ID]: Utility.isPresent(skuId) ? skuId : id,
    });
  };

  onShareTapped = () => {
    const {
      route: {
        params: {
          itemData: { name, id, type: content } = {},
          screenName,
          itemData = {},
        } = {},
      } = {},
    } = this.props;
    const skuId = Utility.getSkuId(itemData);
    this.logAnalyticsEvent(screenName, id, name, content, 'start', skuId);
    this.onSocialShareOptionTapped();
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  discountConditionFn = (props) =>
    parseFloat(props.sp) === parseFloat(props.mrp);

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  VARIANT_TEXT = {
    RECOMMENDED: 'Recommended for you',
    PREMIUM: 'Premium/New Arrivals',
    SPECIAL_PRICE: 'Special Price',
    MORE: 'More Variants',
    OUT_OF_STOCK: 'Out of stock',
  };

  onSearchTap = () => {
    const { navigation } = this.props;
    navigation.push('Search', {});
  };

  header = (props) => {
    return (
      <View
        style={[
          ProductInfoStyles.navigationHeader,
          { marginTop: 0 },
        ]}
      >
        <TouchableOpacity
          onPress={this.goBack}
          style={ProductInfoStyles.navigationBackButtonContainer}
          hitSlop={Utility.getHitSlop()}
        >
          <Image
            source={Images.navigationWhiteBackIcon}
            style={ProductInfoStyles.navbarIconDefaultStyle}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={this.onShareTapped}
          style={ProductInfoStyles.fullScreenVariantShare}
        >
          <Image
            source={Images.ic_share_product}
            style={ProductDetailStyles.navbarShareIconStyle}
          />
        </TouchableOpacity>

        <View style={ProductDetailStyles.navigationShareButtonContainer}>
          <BagIcon showCart />
        </View>

        <TouchableOpacity
          onPress={this.onSearchTap}
          hitSlop={Utility.getHitSlop()}
          style={ProductDetailStyles.fullScreenVariantSearchButtonContainer}
        >
          <Image
            source={Images.ic_search_product}
            style={ProductDetailStyles.fullScreenVariantSearchButton}
          />
        </TouchableOpacity>
      </View>
    );
  };

  PricingComponent = (props) => {
    const { mrp, sp, discount, showPrice } = props;
    if (!showPrice) {
      return null;
    }
    const styles = ProductInfoStyles;
    return (
      <View style={styles.priceContainer}>
        <Text style={styles.spStyle}>{`${CURRENCY_SYMBOL}${sp} `}</Text>
        <this.conditionalPriceText style={styles.mrpStyle} sp={sp} mrp={mrp}>
          {`${CURRENCY_SYMBOL}${mrp}`}
        </this.conditionalPriceText>
        <this.conditionalDiscountText
          style={styles.discountRateStyle}
          discount={discount}
        >
          {`(${discount})`}
        </this.conditionalDiscountText>
      </View>
    );
  };

  updateSelectedVariant = (item) => {
    const {
      route: { params: { updateSelectedVariant = () => {} } = {} } = {},
    } = this.props;
    this.setState({
      selectedVariant: item,
    });
    updateSelectedVariant(item);
  };

  setSelectedMultiVariantSecondary = (item) => {
    const {
      route: {
        params: { setSelectedMultiVariantSecondary = () => {} } = {},
      } = {},
    } = this.props;
    this.setState({
      selectedMultiVariantSecondary: item,
    });
    setSelectedMultiVariantSecondary(item);
  };

  setSelectedMultiVariantPrimary = (item) => {
    const {
      route: {
        params: { setSelectedMultiVariantPrimary = () => {} } = {},
      } = {},
    } = this.props;
    this.setState({
      selectedMultiVariantPrimary: item,
    });
    setSelectedMultiVariantPrimary(item);
  };

  handleVariantItemPress = (item = {}, parentValue) => {
    const { navigation, productCategory = {} } = this.props;

    const changeProductOnPrimaryVariantSelect = AppConfig.getBooleanValue(
      Config.CHANGE_PRODUCT_ON_PRIMARY_VARIANT_SELECT,
    );
    if (parentValue) {
      this.secondaryVariantSelected(item);
    } else {
      const { multi_level_variant_attributes = [] } = item;
      if (
        changeProductOnPrimaryVariantSelect &&
        Utility.isPresent(multi_level_variant_attributes)
      ) {
        const firstVariant = multi_level_variant_attributes[0].variant;
        this.updateSelectedVariant(firstVariant); // updating top level primary variant
        this.secondaryVariantSelected(multi_level_variant_attributes[0]); // updating secondary variant
      }
      this.primaryVariantSelected(item);
    }
    const { variant = {} } = item;
    const { sku = {} } = variant;
    const { id: skuId, needs_customization: needsCustomization = false } = sku;
    if (CUSTOMIZED_SKU_FORM_PAGE && needsCustomization && skuId) {
      navigation.navigate(CUSTOMIZED_SKU_FORM_PAGE, { item, productCategory });
    }
  };

  primaryVariantSelected = (item) => {
    const {
      route: {
        params: {
          itemData: {
            multi_level_variant_attributes: multiVariantAttributes = [],
          } = {},
        } = {},
      } = {},
    } = this.props;
    if (VariantUtility.checkIfMultiVariant(multiVariantAttributes)) {
      this.setSelectedMultiVariantPrimary(item);
      return;
    }
    this.updateSelectedVariant(item?.variant ?? item);
  };

  secondaryVariantSelected = (item) => {
    this.setSelectedMultiVariantSecondary(item);
    this.updateSelectedVariant(item?.variant);
  };
  showToast = (message) => {
    this.toast.show(message, 2000);
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  render() {
    const {
      route: {
        params: {
          itemData = {},
          itemData: {
            multi_level_variant_attributes: multiVariantAttributes = [],
            variant_attributes: variantAttributes = [],
          } = {},
          quantity,
          setVariantQuantity,
          pricingInfoContainer = () => <View />,
          currentSku,
        } = {},
      } = {},
    } = this.props;
    const {
      selectedVariant,
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
    } = this.state;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    if (variantAttributes === undefined || variantAttributes.count === 0) {
      return null;
    }
    if (variantAttributes?.[0]?.type !== PRODUCT.ATTRIBUTE_TYPE.IMAGE) {
      return null;
    }
    const styles = ProductInfoStyles;
    return (
      <View style={styles.fullScreenVariantContainer}>
        <this.header
          id={itemData.id}
          type={itemData.type}
          slug={itemData.slug}
        />
        <VariantSectionList
          itemData={itemData}
          recommendedVariants={multiVariantAttributes}
          multiVariantAttributes={multiVariantAttributes}
          selectedVariantName={selectedVariant.display_name}
          selectedVariantImage={selectedVariant.image_url}
          selectedVariant={selectedVariant}
          updateSelectedVariant={this.updateSelectedVariant}
          imageArray={selectedVariant?.images}
          imageWebpArray={selectedVariant?.webp_images}
          variantAttributes={variantAttributes}
          previousScreen={SCREEN_CONSTANTS.FULL_SCREEN_VARIANT}
          quantity={quantity}
          setVariantQuantity={setVariantQuantity}
          selectedMultiVariantPrimary={selectedMultiVariantPrimary}
          selectedMultiVariantSecondary={selectedMultiVariantSecondary}
          handleVariantItemPress={this.handleVariantItemPress}
        />
        <ProductPricingAndAddToCart
          selectedVariant={selectedVariant}
          itemData={itemData}
          currentSku={selectedVariant?.sku_id ?? currentSku}
          showToast={this.showToast}
        />
        <Toast ref={this.toastRef} />
      </View>
    );
  }
}

FullScreenVariant.propTypes = {
  brand: PropTypes.shape({
    name: PropTypes.string,
    images: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
  }),
  image_url: PropTypes.string,
};
FullScreenVariant.defaultProps = {
  brand: {
    name: '',
    images: {
      thumbnail: '',
    },
  },
  image_url: '',
};

export default FullScreenVariant;
