import React, { Component } from 'react';
import { View, TouchableOpacity, Image, Text, BackHandler } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ScrollView } from 'react-native-gesture-handler';
import images from '../../theme/Images';
import { checkUpiStatus } from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { AnalyticsManager, EventParameterKey } from '../../analytics';
import CountDown from '../../helpers/Counter';
import { AddressStyles } from './styles';
import StaticNavigationHeader from '../shared/StaticNavigationHeader';

class PaymentProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerEnd: false,
    };
    this.paymentStatus = null;
    const { route } = props;
    this.payuPayload = route.params?.payuPayload;
    this.payuParams = route.params?.payuParams;
    this.createOrder = route.params?.createOrder;
    this.retryPayment = route.params?.retryPayment;
    this.openOrderSuccessPage = route.params?.openOrderSuccessPage;
    this.retryPayload = route.params?.retryPayload;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    this.paymentStatus = setInterval(() => {
      const { checkUpiStatus, navigation } = this.props;
      const { timerEnd } = this.state;
      const { order_id, txnid } = this.payuParams;

      if (timerEnd) {
        clearInterval(this.paymentStatus);
        return;
      }

      checkUpiStatus(txnid, (success, response) => {
        if (success) {
          if (response.status !== 'pending') {
            this.openOrderSuccessPage(
              response,
              this.retryPayload,
              this.payuParams,
            );

            clearInterval(this.paymentStatus);
            this.setState({
              timerEnd: true,
            });
          }
        }
      });
    }, 5000);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
    clearInterval(this.paymentStatus);
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  createOrderAndPay = () => {
    const paymentPayload = {
      paymentGateway: 'payu',
      paymentMethod: 'upi',
      isSavedPaymentMethod: true,
      paymentMethodPayload: this.payuPayload,
      payuPayload: this.payuPayload,
      extraData: {
        method: 'TEZOMNI_RESEND',
      },
    };

    this.createOrder(paymentPayload);
  };

  ResendPaymentRequest = () => {
    return (
      <View
        style={{
          marginTop: 16,
          paddingHorizontal: 16,
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'center',
          alignContent: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: 14,
            fontStyle: 'normal',
            letterSpacing: 0,
            color: colors.foxyBlack,
          }}
        >
          Haven’t received the request yet ?
        </Text>
        <TouchableOpacity onPress={this.createOrderAndPay}>
          <Text
            style={{
              fontFamily: 'Roboto-Regular',
              fontSize: 14,
              fontStyle: 'normal',
              letterSpacing: 0,
              color: colors.foxyBlue,
              marginLeft: 4,
            }}
          >
            Resend
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  PaymentRequestMessage = () => {
    const { phone_number: GPayMobileNumber } = this.payuPayload;
    return (
      <Text
        style={{
          fontFamily: 'Roboto-Regular',
          fontSize: 14,
          fontStyle: 'normal',
          letterSpacing: 0,
          textAlign: 'center',
          color: colors.silver,
          marginTop: 36,
          paddingHorizontal: 16,
        }}
      >
        {`Open app linked with ${GPayMobileNumber} and check for “Pending Payment”`}
      </Text>
    );
  };

  onTimerFinish = () => {
    const { navigation } = this.props;
    this.setState({
      timerEnd: true,
    });
    this.openOrderSuccessPage(
      { status: 'failure' },
      this.retryPayload,
      this.payuParams,
    );
  };

  PaymentRequest = () => {
    const { startPaymentTimer } = this.props;

    return (
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          width: Utility.getScreenWidth(),
          // height: Utility.getScreenHeight() / 3,
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: 14,
            fontStyle: 'normal',
            letterSpacing: 0,
            textAlign: 'center',
            color: colors.foxyBlack,
            marginTop: 36,
          }}
        >
          Payment request has been sent to
        </Text>
        <Image
          source={images.gpay}
          style={{
            height: 65,
            width: 65,
            marginVertical: 22,
          }}
        />
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: 12,
            fontStyle: 'normal',
            letterSpacing: 0,
            textAlign: 'center',
            color: colors.foxyPink,
          }}
        >
          Request will expire in 5 minutes
        </Text>
        <View
          style={{
            marginTop: 12,
          }}
        >
          <CountDown
            size={10}
            until={300}
            onFinish={this.onTimerFinish}
            digitStyle={{
              backgroundColor: '#000',
              marginLeft: 0,
              borderRadius: 2,
            }}
            digitTxtStyle={{
              color: '#fff',
              fontSize: 14,
              fontFamily: 'Roboto-Regular',
            }}
            running={startPaymentTimer}
            timeLabelStyle={{ color: 'red', fontWeight: 'bold' }}
            separatorStyle={{ color: '#000000' }}
            timeToShow={['M', 'S']}
            timeLabels={{ m: null, s: null }}
            showSeparator
          />
        </View>
      </View>
    );
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  render() {
    return (
      <>
        <StaticNavigationHeader title='Payment Processing' />
        <ScrollView style={AddressStyles.container}>
          <this.PaymentRequest />
          <this.PaymentRequestMessage />
          <this.ResendPaymentRequest />
        </ScrollView>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ checkUpiStatus }, dispatch),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(PaymentProcessing),
);
