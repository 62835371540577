import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS, CURRENCY_SYMBOL } from '../../config/Constants';
import OffersUtil from '../../utils/OffersUtil';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { jsonParser } from '../../utils/JsonUtility';
import Utility from '../../utils/Utility';

const useGreenTextData = (
  props,
  greenTextCandidatesHighestPriorityIdentifier,
  offerOnSkuId = '',
) => {
  const { groupPrice = '', promptMessages = {}, offerId: espOfferId = '' } = props;

  const boostedOffers = useSelector((state) => state.boostedOffers) || {};

  const boostedOffer = OffersUtil.getBoostedOffer(offerOnSkuId, boostedOffers);

  const todayDeals = useSelector((state) => state.todayDeals) || {};
  const bag = useSelector((state) => state.bag) || {};
  const {
    cartItems = [],
    cartPricing: { coupon_codes: cartCoupons = [] } = {},
  } = bag;

  const {
    skus = {},
    edge_deal_skus: edgeDealSkus = {},
    coupon_code: flashDealCouponCode = '',
    membership_cohort: membershipCohort = '',
    ends_at: endsAt = '',
  } = todayDeals;

  let espToShow = '';
  let discountToShow = '';
  let ctaType = '';
  let greenText = '';
  let greenTextForProductCard = '';
  let extraData = {};
  if (greenTextCandidatesHighestPriorityIdentifier === 'flash_deal') {
    if (isBlank(skus?.[offerOnSkuId])) {
      return;
    }
    const {
      member_esp: memberEsp,
      esp: nonMemberEsp,
      member_discount_text: memberDiscountText,
      discount,
    } = skus[offerOnSkuId];
    espToShow =
      membershipCohort === 'member' && isPresent(memberEsp)
        ? memberEsp
        : nonMemberEsp;
    discountToShow =
      membershipCohort === 'member' && isPresent(memberEsp)
        ? memberDiscountText
        : discount;
    greenText = Utility.isPresent(espToShow)
      ? `${CURRENCY_SYMBOL}${espToShow} (${discountToShow}% off) for you today`
      : '';
    greenTextForProductCard = `${CURRENCY_SYMBOL}${espToShow} (${discountToShow}) for you`;
    ctaType =
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.show_flash_deal_claim_button) ===
      'true'
        ? 'claim'
        : '';

    const isCouponClaimed =
      isPresent(
        cartCoupons?.filter(
          (element) => element.coupon_code === flashDealCouponCode,
        ),
      ) &&
      cartItems.filter((element) => {
        return element.sku_id === offerOnSkuId;
      });

    extraData = {
      ...extraData,
      endsAt,
      couponCode: flashDealCouponCode,
      isCouponClaimed,
      edge_deal_price: membershipCohort === 'member' ? espToShow : 'NA',
      wish_deal_price: membershipCohort !== 'member' ? espToShow : 'NA',
    };
  } else if (
    greenTextCandidatesHighestPriorityIdentifier === 'flash_deal_activation'
  ) {
    if (isBlank(edgeDealSkus?.[offerOnSkuId])) {
      return;
    }
    const { member_esp: memberEsp, member_discount_text: memberDiscountText } =
      edgeDealSkus[offerOnSkuId];
    espToShow = memberEsp;
    discountToShow = memberDiscountText;

    greenText = `${CURRENCY_SYMBOL}${espToShow} (${discountToShow} %off) for you today`;
    greenTextForProductCard = `${CURRENCY_SYMBOL}${espToShow} (${discountToShow}) for you`;
    ctaType = 'activate';
    extraData = {
      ...extraData,
      endsAt,
      couponCode: flashDealCouponCode,
    };
  } else if (greenTextCandidatesHighestPriorityIdentifier === 'boosted_offer') {
    const isCouponClaimed =
      isPresent(
        cartCoupons?.filter(
          (element) => element.coupon_code === boostedOffer.coupon_code,
        ),
      ) &&
      cartItems.filter((element) => {
        return element.sku_id === offerOnSkuId;
      });
    const boostedOfferCouponcode = boostedOffer.coupon_code || '';
    greenText = boostedOffer.promoted_offer_text || '';
    greenTextForProductCard = greenText;
    ctaType = 'claim';
    extraData = {
      ...extraData,
      isCouponClaimed,
      boostedOfferCouponcode,
      isClubMemberOffer: boostedOffer.is_club_member_offer,
      endsAt: boostedOffer.ends_at,
      couponCode: boostedOfferCouponcode,
    };
  } else if (
    greenTextCandidatesHighestPriorityIdentifier === 'group_deal_offer'
  ) {
    greenText = `Get this for ${CURRENCY_SYMBOL}${groupPrice} by inviting friends today`;
    extraData = {
      group_deal_price: groupPrice,
    };
  } else if (greenTextCandidatesHighestPriorityIdentifier === 'esp_offer') {
    greenText = '';
    //greenText = promptMessages[`${espOfferId}`];
  }
  if (isBlank(greenTextForProductCard)) {
    greenTextForProductCard = greenText;
  }
  return {
    greenText: greenText?.includes('undefined') ? '' : greenText,
    greenTextForProductCard,
    ctaType,
    espToShow,
    extraData: {
      ...extraData,
      offerOnSkuId,
      greenTextCandidatesHighestPriorityIdentifier,
    },
  };
};

const withGreenText = (WrappedComponent) => {
  return (props) => {
    const {
      itemData = {},
      selectedVariant: { sku_id: selectedSkuId } = {},
      groupPrice = '',
      discount: defaultDiscount = '',
    } = props;

    const {
      variants_details: { principal_sku_id: principleSkuId } = {},
      sku_id: productSkuId,
    } = itemData;
    const todayDeals = useSelector((state) => state.todayDeals) || {};
    const boostedOffers = useSelector((state) => state.boostedOffers) || {};
    const {
      skus = {},
      edge_deal_skus: edgeDealSkus = {},
      membership_cohort: membershipCohort = '',
    } = todayDeals;

    const offerOnSkuId = selectedSkuId || principleSkuId || productSkuId;

    const greenTextCandidates = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.green_text_candidates),
    );

    const boostedOffer = OffersUtil.getBoostedOffer(
      offerOnSkuId,
      boostedOffers,
    );

    const discountOffset = jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.discount_offset),
      'discountOffset',
    );

    const getEligibleGreenTextCandidates = useCallback(() => {
      const eligibleCandidatesIdentifier = [];

      const conditions = [
        {
          check: () => isPresent(skus[offerOnSkuId]),
          discount:
            membershipCohort === 'member'
              ? skus[offerOnSkuId]?.member_discount
              : skus[offerOnSkuId]?.discount,
          identifier: 'flash_deal',
          ignore_discount_offset: false,
        },
        {
          check: () =>
            edgeDealSkus[offerOnSkuId]?.is_edge_offer &&
            membershipCohort !== 'member',
          discount: edgeDealSkus[offerOnSkuId]?.member_discount,
          identifier: 'flash_deal_activation',
          ignore_discount_offset: false,
        },
        {
          check: () => isPresent(boostedOffer),
          discount: boostedOffer?.discount_percentage,
          identifier: 'boosted_offer',
          ignore_discount_offset: false,
        },
        {
          check: () => isPresent(groupPrice),
          discount: groupPrice?.discount_percentage,
          identifier: 'group_deal_offer',
          ignore_discount_offset: false,
        },
        {
          check: () => true,
          discount: defaultDiscount,
          identifier: 'esp_offer',
          ignore_discount_offset: false,
        },
      ];

      conditions.forEach((condition) => {
        if (condition.check() && !!condition?.discount) {
          const currentDiscount = condition?.discount;

          let isDiscountEligible =
            currentDiscount >=
            parseInt(defaultDiscount?.match(/\d+/)?.[0] || 0) +
              parseInt(discountOffset);

          isDiscountEligible = !condition.ignore_discount_offset;

          if (isDiscountEligible) {
            eligibleCandidatesIdentifier.push(condition.identifier);
          }
        }
      });

      if (eligibleCandidatesIdentifier.length === 0) {
        eligibleCandidatesIdentifier.push('esp_offer');
      }

      return eligibleCandidatesIdentifier;
    }, [skus, edgeDealSkus]);

    const greenTextCandidatesHighestPriorityIdentifier =
      getEligibleGreenTextCandidates()[0];

    return (
      <WrappedComponent
        {...props}
        {...useGreenTextData(
          props,
          greenTextCandidatesHighestPriorityIdentifier,
          offerOnSkuId,
        )}
      />
    );
  };
};

export default withGreenText;

//coupon_code
//status
