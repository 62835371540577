import React from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { useApiCall } from '../../lib/hooksUtil';
import List from '../List/List';
import WalletShimmer from './WalletShimmer';
import Utility from '../../utils/Utility';
import { API_DOMAIN } from '../../config/Constants';
import colors from '../../theme/Colors';
import AppConfig from '../../config/AppConfig';

const Wallet = React.memo((props) => {
  const isMyWalletEnabled = AppConfig.getProfileMenuItems().includes('my_wallet');
  if (!isMyWalletEnabled) {
    return null;
  }
  const {
    walletHeader,
    source,
    previousScreen,
    product_id,
    selectedVariantId,
    stocked_status,
  } = props;

  if (stocked_status !== 'in_stock' && previousScreen === 'product_detail') {
    return null;
  }

  let apiUrl = `${API_DOMAIN}/api/v6/offers/my_wallet?NaN=Nan`;

  if (Utility.isPresent(source)) {
    apiUrl += `&source=${source}`;
  }
  if (Utility.isPresent(selectedVariantId)) {
    apiUrl += `&variant_id=${selectedVariantId}`;
  } else if (Utility.isPresent(product_id)) {
    apiUrl += `&product_id=${product_id}`;
  }
  const cartItems = useSelector((state) => state?.bag?.cartItems) || {};

  const { data, isLoading } = useApiCall(apiUrl, {}, cartItems, {
    previousScreen,
    is_wallet_offer: true,
  });

  const { my_wallet_offers_list: myWalletOffersList = {} } = data;

  if (Utility.isBlank(myWalletOffersList?.objects) && !isLoading) {
    return null;
  }

  if (Utility.isBlank(myWalletOffersList?.objects)) {
    return <WalletShimmer />;
  }

  return (
    <View style={styles.container}>
      {walletHeader && walletHeader()}
      <List
        itemData={myWalletOffersList}
        id={myWalletOffersList.id}
        previousScreen={previousScreen}
        hideHeader
      />
    </View>
  );
});

const styles = {
  container: {
    paddingVertical: 12,
    backgroundColor: colors.white,
    marginBottom: 8,
  },
};

export default Wallet;
