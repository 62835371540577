import { takeEvery, call, put } from 'redux-saga/effects';
import { AUTOCOMPLETE_LOCALITY, addLocality } from '../actions/LoginActions';
import Utility from '../utils/Utility';
import { URL } from '../config/Constants';

import { convertJsonFromResponse } from './GeneratorUtil';

const identifyLocality = (localityHint) => {
  const response = fetch(URL.AUTOCOMPLETE_LOCALITY + localityHint, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response;
};

function* autoCompleteLocality(action) {
  try {
    const localityHint = yield action.localityHint;
    const response = yield call(identifyLocality, localityHint);
    if (response.status === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json.location);
      }
    }
  } catch (error) {
    console.log(`Error in verifying handle ${error}`);
  }
}

export default function* watchAutoCompleteLocalitySaga() {
  yield takeEvery(AUTOCOMPLETE_LOCALITY, autoCompleteLocality);
}
