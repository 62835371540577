import React, { Component } from 'react';
import { View, Animated, Easing, Text } from 'react-native';
import LottieView from '../../libraries/LottieView';
import { PaymentStyles } from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import CardPayment from './CardPayment';
import Netbanking from './Netbanking';
import SavedCard from './SavedCard';
import animations from '../../theme/Animations';
import { withEither } from '../../lib/Monads';
import UpiPayment from './UpiPayment';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';

class MoreBanks extends Component {
  constructor(props) {
    super(props);
    const { route } = this.props;
    this.state = {
      paymentCompletedWithSuccess: false,
      progress: new Animated.Value(0),
      isPaymentInitiated: false,
      shouldAutoFocus: true,
    };
    this.availableNetBanking = [];
    this.createOrder = route.params?.createOrder;

    const { netbanking } = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.available_payment_options),
    );

    for (const key in netbanking) {
      this.availableNetBanking.push(netbanking[key]);
    }
  }

  PaymentComponent = {
    'Credit Card/Debit Card': CardPayment,
    NetBanking: Netbanking,
    'Saved Card': SavedCard,
    UPI: UpiPayment,
  };

  openOrderStatusScreen = (orderResponse) => {
    const { viewOrderStatusScreen } = this.props;
    viewOrderStatusScreen(orderResponse);
    setTimeout(() => {
      this.setState({
        paymentCompletedWithSuccess: false,
        progress: new Animated.Value(0),
        isPaymentInitiated: false,
      });
    }, 1000);
  };

  playTickAnimation = (orderResponse) => {
    const { progress } = this.state;
    Animated.timing(progress, {
      toValue: 1,
      duration: 3000,
      easing: Easing.linear,
    }).start(() => this.openOrderStatusScreen(orderResponse));
  };

  paymentSuccess = (orderResponse) => {
    this.setState(
      {
        paymentCompletedWithSuccess: true,
      },
      () => this.playTickAnimation(orderResponse),
    );
  };

  initiatePayment = () => {
    setTimeout(() => {
      this.setState({
        isPaymentInitiated: true,
      });
    }, 0);
  };

  successTick = () => {
    const styles = PaymentStyles;
    return (
      <View style={styles.successAnimatedTickContainer}>
        <LottieView
          source={animations.successTick}
          style={styles.successAnimatedTick}
          resizeMode='cover'
          progress={this.state.progress}
          resizeMode='cover'
        />
      </View>
    );
  };

  removeAutofocusState = () => {
    this.setState({
      shouldAutoFocus: false,
    });
  };

  pendingPayment = () => {
    const styles = PaymentStyles;
    return (
      <View style={styles.successAnimatedTickContainer}>
        <LottieView
          source={animations.pendingPayment}
          style={styles.pendingPayment}
          resizeMode='cover'
          autoPlay={true}
          resizeMode='cover'
        />
      </View>
    );
  };

  hidePaymentDetailsScreen = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  paymentDetailPage = () => {
    const {
      selectedPaymentOption,
      viewOrderStatusScreen,
      availableNetBanking,
      selectedSavedCard,
      payWithNetBanking,
      hidePaymentDetailsScreen,
      updateLastPaymentMode,
      createOrder,
      setCurrentPaymentDetails,
      enabledPaymentOptions,
      totalPrice,
      isPaymentDetailScreenVisible,
      isOrderStatusScreenVisible,
      isContactLessDelivery,
    } = this.props;

    const { isPaymentInitiated, shouldAutoFocus } = this.state;

    if (isPaymentInitiated) {
      return <this.pendingPayment />;
    }

    return (
      <Netbanking
        viewOrderStatusScreen={viewOrderStatusScreen}
        ref={this.saveChildRef}
        availableNetBanking={this.availableNetBanking}
        selectedSavedCard={selectedSavedCard}
        payWithNetBanking={payWithNetBanking}
        paymentSuccess={this.paymentSuccess}
        hidePaymentDetailsScreen={this.hidePaymentDetailsScreen}
        updateLastPaymentMode={updateLastPaymentMode}
        createOrder={this.createOrder}
        isContactLessDelivery={isContactLessDelivery}
        setCurrentPaymentDetails={setCurrentPaymentDetails}
        enabledPaymentOptions={enabledPaymentOptions}
        totalPrice={totalPrice}
        initiatePayment={this.initiatePayment}
        openOrderStatusScreen={this.openOrderStatusScreen}
        isPaymentDetailScreenVisible={isPaymentDetailScreenVisible}
        isOrderStatusScreenVisible={isOrderStatusScreenVisible}
        shouldAutoFocus={shouldAutoFocus}
        removeAutofocusState={this.removeAutofocusState}
      />
    );
  };

  successTickCondition = () => this.state.paymentCompletedWithSuccess;

  conditionalPaymentDetail = withEither(
    this.successTickCondition,
    this.successTick,
  )(this.paymentDetailPage);

  render() {
    return <this.conditionalPaymentDetail />;
  }
}

export default withNavigation(MoreBanks);
