import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import ProductRatingsHeader from './ProductRatingsHeader';
import ProductReviewItems from './ProductReviewItems';
import { AnalyticsManager } from '../../analytics';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isDesktop } from '../../utils/BooleanUtility';
import { getCompactSlug } from '../../utils/StringUtility';

const styles = StyleSheet.create({
  footer: {
    height: 1,
    backgroundColor: colors.background,
    marginHorizontal: 16,
    width: Utility.getScreenWidth() - 32,
    alignSelf: 'center',
    marginTop: 16,
  },
  headerText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 32,
    marginBottom: 12,
    marginHorizontal: 12,
  },
  container: {
    backgroundColor: colors.background,
    zIndex: 1,
  },
  chevronRight: {
    height: 12,
    width: 12,
    marginLeft: 8,
    resizeMode: 'contain',
    tintColor: colors.cta.lightBlue,
  },
  viewAllReviewsCTA: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
    fontStyle: 'normal',
  },
  viewAllReviewCTAContainer: {
    flexDirection: 'row',
    marginRight: 12,
    alignItems: 'center',
    justifyContent: 'flex-end',
    // marginTop: 16,
  },
});

const ProductRating = ({
  reviewData,
  reviewsCount,
  ratingsCount,
  rating,
  navigation,
  productId,
  productSlug,
  unlikeRating,
  likeRating,
  likedRatings,
  variants,
  itemData,
  productPage,
}) => {
  // if (Utility.isBlank(reviewData)) {
  //   return null;
  // }
  const onCtaPress = () => {
    AnalyticsManager.logEvent('view_all_reviews', {
      product_id: productId,
    });
    navigateToScreen({
      navigation,
      type: isDesktop() ? 'push' : 'navigate',
      screen: 'AllReviews',
      params: {
        productId,
        productSlug,
        likeRating,
        unlikeRating,
        itemData,
        variants,
        shortSlug: getCompactSlug(productSlug),
      },
    });
  };
  const SeeAllReviews = () => {
    return (
      <TouchableOpacity onPress={onCtaPress}>
        <View style={styles.viewAllReviewCTAContainer}>
          <Text style={styles.viewAllReviewsCTA}>View all reviews</Text>
          <Image source={images.chevronRight} style={styles.chevronRight} />
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={{ backgroundColor: colors.white }}>
      <View style={styles.container}>
        <Text style={styles.headerText}>
          {productPage?.rating?.heading || ''}
        </Text>
      </View>

      <View style={{ backgroundColor: colors.white }}>
        <ProductRatingsHeader
          reviewData={reviewData}
          reviewsCount={reviewsCount}
          ratingsCount={ratingsCount}
          rating={rating}
        />
        <ProductReviewItems
          reviewData={reviewData}
          unlikeRating={unlikeRating}
          likeRating={likeRating}
          productId={productId}
          productSlug={productSlug}
          likedRatings={likedRatings}
          navigation={navigation}
          variants={variants}
          itemData={itemData}
          hideLastItemFooter
        />
        {reviewsCount > 3 && <SeeAllReviews />}
      </View>
      {(reviewsCount > 0 || (ratingsCount > 0 && Utility.isPresent(rating))) && (
        <View style={styles.footer} />
      )}
    </View>
  );
};

export default ProductRating;
