// Dependencies
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withEither } from '../../lib/Monads';
import { BackHandler } from 'react-native';
// Components
import {
  fetchProduct,
  updateProductData,
  fetchProductReviews,
  addToRecentlyViewed,
  toggleDynamicListTitleVisibility,
  updateLastVisitedProductId,
} from '../../actions/ActionTypes';
import ProductDetail from '../../components/Product/ProductDetail';
import { ProductCardForCart } from '../../components/Product';
import ProductCardForCartRetail from '../../containers/retailStore/components/Product/ProductCardForCart';
import { LAYOUT } from '../../config/Constants';
import Utility, { findIngredients } from '../../utils/Utility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  AnalyticsEvent,
  EventType,
} from '../../analytics';
import WithNavigation from '../../utils/WithNavigation';
import { addProductIdInProductViews } from '../../actions/UserInteractionsActions';
import OffersUtil from '../../utils/OffersUtil';
import RoutineCardForCart from '../../components/Product/RoutineCardForCart';
import LoyaltyPlanCardForCart from '../../components/Product/LoyaltyPlanCardForCart';
import { getCompactSlug } from '../../utils/StringUtility';

class Product extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = props;
    this.layout = props.layout || (route.params?.display ?? LAYOUT.SCREEN);
    this.shouldRenderDetailPage = this.layout === 'screen';
    this.variantItem = route.params?.sku_id;
    this.variants_details = route.params?.variants_details ?? {};
    this.cartConditionFn = (props) => props.cart === true;
    this.selectedVariantItem = {};
    this.singleStockedVariant = {};
    this.fromScan = false;
    this.previousScreen = route.params?.previousScreen;
    this.state = {
      productData: {},
      showShimmer: false,
      productPositiveIngredients: {},
      productNegativeIngredients: {},
      isPageLoading: true,
      serverError: false,
    };
    this.skuWiseGroupDealData = {};
    if (Utility.isAndroid() && !this.cartConditionFn(props)) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    const {
      navigation,
      layout,
      itemData = {},
      route,
      toggleDynamicListTitleVisibility,
    } = this.props;
    this.unsubscribeFocus = navigation.addListener('focus', () => {
      /**
       * FIXME: Hack as of now.
       * There is a very weird implementation of dynamic list whose header is dependent on redux value.
       * Ideally it should be implemented on basis of object of the list like we use at other places.
       * If you will remove this code:
       * 1 - Header for recently viewed items will be always shown on the lower consolidated list even if objects are not available.
       * 2 - Here setTimeout plays an important role because without this, on tap on any product card from home will trigger and immediate visibility=gone
       * to Recently viewed list and it will be something like header is fluctuating.
       *
       * Other Solutions are not possible because:
       *  <Header/> is in ProductDetail.js and recently viewed list is calling api inside List.js and actual data goes to
       *  Rail.js so <Header/> is totally clueless if list is having actual data or not because of this 2 level deep parent-child relation.
       *
       * You can't check if data is present in rail and set header because its redux and you dont know how many times your
       * data will get refresh and component will be rendered.
       */
      setTimeout(() => {
        toggleDynamicListTitleVisibility(false);
      }, 100);

      Utility.setStatusBarWhite();
    });

    if (Utility.isBlank(itemData)) {
      this.fetchProductData(slugString);
    }
    if (layout === undefined) {
      if (route.params?.fromScan) {
        this.fromScan = true;
        this.setState({
          showShimmer: true,
        });
        AnalyticsUtility.recordTime(
          {
            screen_name: SCREEN_CONSTANTS.PRODUCT_DETAIL,
            ...Utility.setRecordTimeEventMeta(itemData, 'product'),
          },
          this.fetchProductCallback,
          this.props.fetchProduct,
          slugString,
        );
      } else {
        if (!Utility.isBlank(slugString)) {
          this.setState({
            showShimmer: true,
          });
          if (Utility.isPresent(itemData)) {
            AnalyticsUtility.recordTime(
              {
                screen_name: SCREEN_CONSTANTS.PRODUCT_DETAIL,
                ...Utility.setRecordTimeEventMeta(itemData, 'product'),
              },
              this.fetchProductCallback,
              this.props.fetchProduct,
              slugString,
            );
          }
        }
        return;
      }
      if (Utility.isPresent(itemData)) {
        this.fetchProductData(slugString);
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribeFocus();
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  addProductToRecentlyViewed = (id) => {
    if (Utility.isPresent(id)) {
      this.props.addToRecentlyViewed(id);
    }
  };

  ProductCardWithCondition = withEither(
    this.shouldRenderDetailPage,
    ProductDetail,
  )(ProductCardForCart);

  findIngredients = () => {
    const { productData } = this.state;
    const {
      facialAnalysis: { my_attributes_values: myAttributesValues = [] } = {},
    } = this.props;
    const {
      star_ingredients: heroIngredients = [],
      foxy_match = [],
      product_category: {
        category_user_attributes: { principal = [] } = {},
      } = {},
    } = productData;
    const positiveIngredients = {};
    const negativeIngredients = {};
     const { newGood, newBad } = findIngredients(
      heroIngredients,
      myAttributesValues,
    );
    let positiveIngredientCount = 0;
    let negativeIngredientCount = 0;

    _.forEach(newGood, (positive) => {
      if (Utility.isPresent(principal[`${positive.indication}`])) {
        positiveIngredientCount += 1;
        positiveIngredients[`${principal[`${positive.indication}`]}`] = [
          ...(Utility.isPresent(
            positiveIngredients[`${principal[`${positive.indication}`]}`],
          )
            ? positiveIngredients[`${principal[`${positive.indication}`]}`]
            : []),
          {
            name: `${positive.name}`,
            slug: `${positive.slug}`,
            id: `${positive.id}`,
          },
        ];
      }
    });

    _.forEach(newBad, (negative) => {
      if (Utility.isPresent(principal[`${negative.contraindication}`])) {
        negativeIngredientCount += 1;
        negativeIngredients[`${principal[`${negative.contraindication}`]}`] = [
          ...(Utility.isPresent(
            negativeIngredients[`${principal[`${negative.contraindication}`]}`],
          )
            ? negativeIngredients[
                `${principal[`${negative.contraindication}`]}`
              ]
            : []),
          {
            name: `${negative.name}`,
            slug: `${negative.slug}`,
            id: `${negative.id}`,
          },
        ];
      }
    });

    AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_VIEW, {
      [EventParameterKey.SKU_ID]: productData?.sku_id,
      [EventParameterKey.PRODUCT_ID]: productData?.id,
      [EventParameterKey.POSITIVE_INGREDIENT_COUNT]: positiveIngredientCount,
      [EventParameterKey.NEGATIVE_INGREDIENT_COUNT]: negativeIngredientCount,
      [EventParameterKey.HERO_INGREDIENTS]: heroIngredients?.length,
    });

    this.setState({
      productPositiveIngredients: positiveIngredients,
      productNegativeIngredients: negativeIngredients,
    });
  };

  hasOrderCampaignInVariant = (itemData) => {
    const { variant_attributes } = itemData;
  };

  onScreenFocus = (itemData) => {
    const {
      facialAnalysis: { my_attributes_values = [] } = {},
      todayDeals: {
        skus = {},
        edge_deal_skus = {},
        membership_cohort,
        coupon_code,
      } = {},
      boostedOffers,
      addProductIdInProductViews,
      previousScreen = '',
    } = this.props;

    const isBoostedOffer = OffersUtil.isBoostedOffer(
      itemData?.sku_id,
      boostedOffers,
    );
    const orderCampaign = Utility.isPresent(itemData.order_campaign);

    const {
      star_ingredients = [],
      sku: { group_buying_price = '' } = {},
      order_campaign: { order_campaings_type = '' } = {},
    } = itemData;
    let orderCampaignType = order_campaings_type;
    if (
      Utility.isBlank(orderCampaignType) &&
      Utility.isPresent(this.selectedVariantItem) &&
      this.selectedVariantItem.length > 0
    ) {
      const { order_campaign: { order_campaings_type = '' } = {} } =
        this.selectedVariantItem[0];

      orderCampaignType = order_campaings_type;
    }

    let showEdgeDealActivationPrompt = false;
    let visibleCouponCode = isBoostedOffer.coupon_code;
    let isBoostedOfferPresent = isBoostedOffer.is_boosted;
    if (
      Utility.isPresent(edge_deal_skus[`${itemData?.sku_id}`]) ||
      Utility.isPresent(skus[`${itemData?.sku_id}`])
    ) {
      visibleCouponCode = coupon_code;
      isBoostedOfferPresent = true;
    }

    if (
      Utility.isPresent(edge_deal_skus[`${itemData?.sku_id}`]?.member_esp) &&
      Utility.isBlank(skus[`${itemData?.sku_id}`]?.member_esp)
    ) {
      showEdgeDealActivationPrompt = true;
      visibleCouponCode = coupon_code;
    }

    if (Utility.isPresent(skus[`${itemData?.sku_id}`])) {
      showEdgeDealActivationPrompt = false;
    } else if (
      membership_cohort !== 'member' &&
      isBoostedOffer.is_club_member_offer
    ) {
      showEdgeDealActivationPrompt = true;
    }

    const analyticsMeta = {
      [EventParameterKey.PRODUCT_ID]: itemData.id,
      [EventParameterKey.PRODUCT_NAME]: itemData.name,
      [EventParameterKey.BRAND_ID]: itemData?.brand?.id,
      [EventParameterKey.BRAND_NAME]: itemData?.brand?.name,
      [EventParameterKey.PRODUCT_LISTING_PRICE]: itemData.mrp,
      [EventParameterKey.PRODUCT_SELLING_PRICE]: itemData.sp,
      [EventParameterKey.PRODUCT_STOCKED_STATUS]: !!itemData?.gwp
        ? 'gwp'
        : itemData.stocked_status,
      star_ingredients_count: star_ingredients && star_ingredients?.length,
      [EventParameterKey.RATING]: itemData?.rating,
      [EventParameterKey.POSITIVE_INGREDIENTS]: Utility.getIngredientsCount(
        itemData?.star_ingredients,
        my_attributes_values,
      )?.goodCount,
      [EventParameterKey.NEGATIVE_INGREDIENTS]: Utility.getIngredientsCount(
        itemData?.star_ingredients,
        my_attributes_values,
      )?.badCount,
      [EventParameterKey.PREVIOUS_SCREEN]: Utility.isPresent(
        this.previousScreen,
      )
        ? this.previousScreen
        : previousScreen,
      [EventParameterKey.WISHLIST_DEAL_PRICE]:
        (membership_cohort !== 'member' && skus[`${itemData?.sku_id}`]?.esp) ||
        'not_eligible',
      [EventParameterKey.EDGE_DEAL_PRICE]:
        skus[`${itemData?.sku_id}`]?.member_esp ||
        edge_deal_skus[`${itemData?.sku_id}`]?.member_esp ||
        'not_eligible',
      [EventParameterKey.GROUP_DEAL_PRICE]: skus?.group_buying_price
        ? skus.group_buying_price
        : null,
      is_edge_activation_prompt: showEdgeDealActivationPrompt,
      [EventParameterKey.IS_BOOSTED]: isBoostedOfferPresent || false,
      [EventParameterKey.COUPON_CODE]: visibleCouponCode || '',
      [EventParameterKey.STOCK_STATUS]: orderCampaignType,
    };

    AnalyticsManager.logEvent(
      EventType.discoveryEvents.PRODUCT_VIEW,
      analyticsMeta,
    );

    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.VIEW_ITEM,
      {
        currency: 'INR',
        items: [{ id: Utility.getSkuId(itemData) }],
        value: itemData.mrp,
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_VIEWED_CONTENT,
      itemData.mrp,
      {
        [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: 'INR',
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: `${Utility.getSkuId(
          itemData,
        )}`,
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
      },
    );
    addProductIdInProductViews(itemData.id);
  };

  fireAnalytics = (data) => {
    if (Utility.isBlank(data)) {
      return;
    }
    if (Utility.isBlank(data.objects) || data.objects.length === 0) {
      AnalyticsUtility.fireContentErrorAnalytics(data.id, data.name, 'product');
    }
    if (
      data.has_variants &&
      (Utility.isBlank(data.variant_attributes) ||
        data.variant_attributes.length === 0)
    ) {
      AnalyticsUtility.fireVariantErrorAnalytics(data.id, data.name);
    }
    if (Utility.isBlank(data.description)) {
      AnalyticsUtility.fireDescriptionErrorAnalytics(data.id, data.name);
    }
  };

  fireListRenderedEvent = () => {
    const { productData } = this.state;
    const {
      property_values = [],
      description = '',
      ratings_count = 0,
      reviews_count = 0,
      rating,
    } = productData;
    Utility.fireListRenderedEvent(productData, SCREEN_CONSTANTS.PRODUCT_DETAIL);
    AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_VIEW, {
      [EventParameterKey.SKU_ID]: productData?.sku_id,
      [EventParameterKey.PRODUCT_ID]: productData?.id,
      [EventParameterKey.TAG_COUNT]: property_values?.length + 2,
      [EventParameterKey.TAG_COUNT]: property_values?.length + 2,
      [EventParameterKey.HAS_DESCRIPTION]: Utility.isPresent(description),
      [EventParameterKey.RATING_COUNT]: ratings_count,
      [EventParameterKey.TEXT_REVIEW_COUNT]: reviews_count,
      [EventParameterKey.RATING]: rating,
    });
  };

  getSingleStockedVariant = () => {
    const { productData } = this.state;
    this.findIngredients();

    setTimeout(this.fireListRenderedEvent, 1000);

    //TODO: Resolve this with promises, This is wrong hack.
    setTimeout(() => {
      this.onScreenFocus(productData);
    }, 5000);

    if (Utility.isBlank(productData.variant_attributes)) {
      return null;
    }
    const allowedVariantValues =
      productData?.variant_attributes?.[0]?.allowed_values;
    const selectedVariant = allowedVariantValues?.filter(
      (item) => item?.outOfStock !== true,
    );
    if (selectedVariant) {
      this.singleStockedVariant = selectedVariant;
    }
  };

  getVariantFromData = (itemData, variantID) => {
    const { productData } = this.props;

    const allowedVariantValues =
      itemData?.variant_attributes?.[0]?.allowed_values;

    if (Utility.isBlank(allowedVariantValues)) {
      return;
    }

    const selectedVariant = allowedVariantValues?.filter(
      (item) => item.sku_id === Number(variantID),
    );

    if (selectedVariant) {
      this.selectedVariantItem = selectedVariant;
      console.log('selected variant item', this.selectedVariantItem);
    }
  };

  fetchProductCallback = (success, data = {}, status) => {
    Utility.clearPageLoadTimer();
    this.setState({
      showShimmer: false,
      isPageLoading: false,
      serverError: !success && status !== 404,
    });

    if (Utility.isBlank(this.variants_details)) {
      const { variants_details = {} } = data; // in case we're not getting variant_details from route, we should get it from product response
      this.variants_details = variants_details;
    }

    const { principal_sku_id = '', variants_property_name = '' } =
      this.variants_details;
    if (
      Utility.isBlank(this.variantItem) &&
      Utility.isPresent(principal_sku_id)
    ) {
      this.variantItem = principal_sku_id;
    }
    if (success) {
      this.addProductToRecentlyViewed(data?.id);
      this.collateGroupDealData(data);
      if (!Utility.isBlank(this.variantItem)) {
        this.getVariantFromData(data, this.variantItem);
      }

      this.setState(
        {
          productData: data,
        },
        this.getSingleStockedVariant,
      );
      this.fireAnalytics(data);
    }
  };

  collateGroupDealData = (productData) => {
    const { sku = {}, variant_attributes = [] } = productData;
    this.addToGroupDealData(sku);
    const variants = variant_attributes?.[0]?.allowed_values;
    variants?.forEach(({ sku = {} }) => {
      this.addToGroupDealData(sku);
    });
  };

  addToGroupDealData = (sku = {}) => {
    const {
      todayDeals: { group_deal_skus: personalizedGroupDeals = {} },
    } = this.props;
    let {
      group_buying_price: groupPrice,
      required_group_size: groupSize,
      id,
    } = sku;

    if (Utility.isPresent(personalizedGroupDeals[id]?.group_buying_price)) {
      groupPrice = personalizedGroupDeals[id]?.group_buying_price;
      groupSize = personalizedGroupDeals[id]?.required_group_size;
    }

    if (Utility.isBlank(id) || Utility.isBlank(groupPrice)) return;
    this.skuWiseGroupDealData[id] = {
      groupPrice,
      groupSize,
    };
  };

  fetchProductData = (slugString) => {
    const { itemData = {}, fetchProduct } = this.props;
    AnalyticsUtility.recordTime(
      {
        screen_name: SCREEN_CONSTANTS.PRODUCT_DETAIL,
        ...Utility.setRecordTimeEventMeta(itemData, 'product'),
      },
      this.fetchProductCallback,
      fetchProduct,
      slugString,
    );
  };

  addProductToRedux = () => {
    const { itemData = {}, updateProductData } = this.props;
    updateProductData(itemData);
  };

  seeProductReviews = () => {
    const {
      itemData = {},
      fetchProductReviews,
      navigation,
      showToast,
    } = this.props;
    fetchProductReviews(itemData.id, (success, response) => {
      if (success && response.objects?.length > 0) {
        navigation.push('ContentModal', {
          itemData: response.objects[0],
          listId: response.id,
          index: 0,
          listData: response,
          id: itemData.id,
        });
      } else {
        showToast('Video review currently not available');
      }
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  productCartComponent = () => {
    const { itemData: { routine = {}, loyalty_plan } = {}, retail } =
      this.props;
    if (Utility.isPresent(routine)) {
      return RoutineCardForCart;
    }
    if (Utility.isPresent(loyalty_plan)) {
      return LoyaltyPlanCardForCart;
    }
    return retail ? ProductCardForCartRetail : ProductCardForCart;
  };

  onWillFocus = () => {
    const { productData } = this.state;
    const { updateLastVisitedProductId } = this.props;
    if (Utility.isPresent(productData)) {
      updateLastVisitedProductId(productData?.id);
    }
    Utility.setStatusBarWhite();
  };

  render() {
    const {
      itemData = {},
      baseProps,
      skuId,
      quantity,
      previousScreen,
      cartItem,
      cartProduct,
      listId,
      error,
      showToastInCart,
      refreshOfferStrip,
      maxFreeItemsToSelect,
      showToast,
      campaignId,
      onItemAddToCartFromCollab,
      refreshOffersDetailsPageDiscountStrip,
      showFreeProductScreen,
      viewFreeProductScreen,
      offer,
      hideAddToCart,
      isDigitalSku,
      boostedOffers,
      retail,
      route,
    } = this.props;

    const {
      productData,
      showShimmer,
      productPositiveIngredients,
      productNegativeIngredients,
      isPageLoading,
      serverError,
    } = this.state;

    if (this.shouldRenderDetailPage) {
      this.filteredProps = _.omit(baseProps, ['cart']);
      const data = Utility.isBlank(productData) ? itemData : productData;
      const { brand: { certificate_image_url = '' } = {} } = data;

      const showPageNotFound =
        Utility.isBlank(data) && !isPageLoading && !serverError;

      return (
        <ErrorBoundary
          onScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
          itemData={data}
          showServerError={serverError}
          pageNotFound={showPageNotFound}
        >
          <ProductDetail
            listId={listId}
            certificateImageUrl={certificate_image_url}
            itemData={data}
            productData={itemData}
            selectedVariantItem={this.selectedVariantItem}
            layout={this.layout}
            showShimmer={showShimmer}
            refreshOfferStrip={refreshOfferStrip}
            previousScreen={
              Utility.isPresent(this.previousScreen)
                ? this.previousScreen
                : previousScreen
            }
            maxFreeItemsToSelect={maxFreeItemsToSelect}
            showToast={showToast}
            fromScan={this.fromScan}
            campaignId={campaignId}
            onItemAddToCartFromCollab={onItemAddToCartFromCollab}
            refreshOffersDetailsPageDiscountStrip={
              refreshOffersDetailsPageDiscountStrip
            }
            singleStockedVariant={this.singleStockedVariant}
            navigation={this.props.navigation}
            productPositiveIngredients={productPositiveIngredients}
            productNegativeIngredients={productNegativeIngredients}
            variantItem={this.variantItem}
            isPageLoading={isPageLoading}
            skuWiseGroupDealData={this.skuWiseGroupDealData}
            boostedOffers={boostedOffers}
            isReplacement={route?.params?.isReplacement}
            productName={route?.params?.name || ''}
          />
        </ErrorBoundary>
      );
    }
    if (this.cartConditionFn(this.props)) {
      const LayoutComponent = this.productCartComponent(cartItem, retail);
      this.filteredProps = _.omit(baseProps, []);
      return (
        <LayoutComponent
          layout={this.layout}
          skuId={skuId}
          quantity={quantity}
          cartItem={cartItem}
          cartProduct={cartProduct}
          onPress={this.props.onPress}
          error={error}
          showToastInCart={showToastInCart}
          refreshOfferStrip={refreshOfferStrip}
          previousScreen={previousScreen}
          maxFreeItemsToSelect={maxFreeItemsToSelect}
          showToast={showToast}
          campaignId={campaignId}
          onItemAddToCartFromCollab={onItemAddToCartFromCollab}
          refreshOffersDetailsPageDiscountStrip={
            refreshOffersDetailsPageDiscountStrip
          }
          showFreeProductScreen={showFreeProductScreen}
          viewFreeProductScreen={viewFreeProductScreen}
          offer={offer}
          setPromptLoading={this.props.setPromptLoading}
          isPrompStopLoading={this.props.isPrompStopLoading}
          hideAddToCart={hideAddToCart}
          hasPromptStoppedLoading={this.props.hasPromptStoppedLoading}
          isDigitalSku={isDigitalSku}
          boostedOffers={boostedOffers}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  todayDeals: state.todayDeals,
  boostedOffers: state.boostedOffers,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchProduct,
      updateProductData,
      fetchProductReviews,
      addProductIdInProductViews,
      addToRecentlyViewed,
      toggleDynamicListTitleVisibility,
      updateLastVisitedProductId,
    },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Product),
);
