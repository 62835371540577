// Dependencies
import React, { PureComponent } from 'react';
import {
  View,
  ImageBackground,
  Text,
  StyleSheet,
  FlatList,
  Image,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Config from '../../libraries/ReactNativeConfig';
import { capitalize, debounce } from 'lodash';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import {
  LAYOUT,
  MAX_LINES,
  REMOTE_CONFIG_KEYS,
  ZOOM_ANIMATION,
} from '../../config/Constants';
import FullScreenImageModal from '../../utils/FullScreenImageModal';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { ProductInfoStyles } from './styles';
import FastImageView from '../FastImageView';
import colors from '../../theme/Colors';
import AnimatedLikeButton from '../shared/AnimatedLikeButton';
import Bullets from '../shared/Bullets';
import FullWidthShimmer from '../../lib/FullWidthShimmer';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import RemoteConfig from '../../utils/RemoteConfig';
import {
  setLastZoomAnimationVisibleAt,
  setZoomAnimationVisibilityCounter,
} from '../../actions/ActionTypes';
import AppConfig from '../../config/AppConfig';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { getHitSlop, getScreenWidth } from '../../utils/LayoutUtility';
import {
  isAndroid,
  isBlank,
  isPresent,
  isWeb,
} from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtils';
import { jsonParser } from '../../utils/JsonUtility';

const styles = StyleSheet.create({
  swiperContainer: {
    height: 0.72 * getScreenWidth(),
    overflow: 'hidden',
  },
  carouselContainer: {
    height: '100%',
    width: '100%',
  },
  viewPortContainer: {
    height: 0.62 * getScreenWidth(),
    width: getScreenWidth(),
  },
  chosenBulletStyles: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#4E4E4E',
    opacity: 0.6,
    borderWidth: 0.5,
    borderColor: '#4E4E4E',
  },
  dotStyle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 2,
    marginRight: 2,
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    backgroundColor: '#4E4E4E',
    opacity: 0.6,
  },
  tagTextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '92%',
  },
  productTag: {
    position: 'absolute',
    left: 0,
    maxWidth: 120,
    height: 64,
    flexDirection: 'row',
    alignItems: 'center',
    top: isAndroid() ? 20 : 10,
  },
  productTagText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
    margin: 4,
  },
  productDetailImage: {
    height: 0.62 * getScreenWidth(),
    width: getScreenWidth(),
  },

  mediaControl: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  playerContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playerStyle: { width: '100%', height: '100%', top: 0, position: 'absolute' },
  likeButtonContainer: {
    position: 'absolute',
    bottom: 8,
    right: 16,
    zIndex: 10,
  },
  clubStar: { height: 12, width: 12, resizeMode: 'contain' },
  zoomButtonContainer: {
    marginTop: 16,
  },
  zoomIcon: {
    width: 20,
    height: 20,
  },
  zoomButton: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 0,
    borderRadius: 16,
    backgroundColor: 'rgba(255, 255, 255,0.8)',
  },
  zoomButtonWithShadow: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 0,
    borderRadius: 16,
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    shadowColor: colors.shadowGrey,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 5,
  },
  zoomInImage: {
    width: 24,
    height: 24,
    overflow: 'visible',
    resizeMode: 'contain',
  },

  ratingContainer: {
    width: 40,
    height: 20,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 10,
    flexDirection: 'row',
    left: 12,
    backgroundColor: colors.white,
  },

  ratingText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 11,
    color: colors.black,
  },

  ratingImage: {
    height: 8,
    width: 8,
    marginLeft: 3,
  },
});

class ProductImages extends PureComponent {
  constructor(props) {
    super(props);
    const { itemData: { product_image_aspect_shape = 'square' } = {} } = props;
    this.state = {
      isImageModalVisible: false,
      isImageLoading: false,
      zoomAnimation: false,
    };
    this.debouncedOpenFullScreenImageModal = debounce(
      this.openFullScreenImageModal,
      200,
      {
        leading: false,
        trailing: true,
      },
    );

    this.viewabilityConfig = {
      waitForInteraction: true,
      itemVisiblePercentThreshold: 51,
    };
    this.imageContainerStyle =
      ProductInfoStyles[
        `productDetail${capitalize(product_image_aspect_shape)}Image`
      ];
    this.productDealsTagData = jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.product_deals_tag_data),
    );
    const {
      todayDeals: { skus = {} } = {},
      itemData,
      selectedVariant: { sku_id: selectedSkuId } = {},
    } = this.props;
    const { variants_details: { principal_sku_id } = {}, sku_id } = itemData;
    let skuToCheck = principal_sku_id || sku_id;

    if (isPresent(selectedSkuId)) {
      skuToCheck = selectedSkuId;
    }
    const isFlashDealActive = skus[`${skuToCheck}`];
    const {
      flash_deal_v2: {
        text_left_margin: flashDealTextMargin = 0,
        text_color: flashDealTextColor = colors.white,
      } = {},
      group_deal: {
        text_left_margin: groupDealTextMargin = 0,
        text_color: groupDealTextColor = colors.white,
      } = {},
    } = this.productDealsTagData;
    const labelTextMarginLeft = isFlashDealActive
      ? flashDealTextMargin
      : groupDealTextMargin;
    this.labelStyle = [
      ProductInfoStyles.labelTextStyle,
      {
        color: isFlashDealActive ? flashDealTextColor : groupDealTextColor,
        marginLeft: 6 + labelTextMarginLeft,
      },
    ];
    this.memoizedLabelImageStyleCache = {};
    this.memoizedLabelShimmerStyleCache = {};
  }

  imageTapped = () => {
    AnalyticsManager.logEvent(EventType.panZoom.ZOOM_MODAL_OPEN, {
      [EventParameterKey.INDEX]: this.props.currentImageIndex,
      [EventParameterKey.SKU_ID]: this.props.skuId,
    });
    this.debouncedOpenFullScreenImageModal();
  };

  openFullScreenImageModal = () => {
    const {
      navigation,
      images,
      itemData,
      addedToCart,
      addToCartLayout,
      showCart,
      skuId,
      refreshOfferStrip,
      showToast,
      mrp,
      sp,
      discount,
      selectedVariant,
      campaignId,
      isProductInStock,
      showFreeProductScreen,
      shouldShowChooseGiftScreen,
      offerId,
      name,
    } = this.props;
    const { zoomAnimation } = this.state;
    navigation.navigate('FullScreenImageModal', {
      images,
      itemData,
      addedToCart,
      addToCartLayout,
      showCart,
      skuId,
      refreshOfferStrip,
      showToast,
      mrp,
      sp,
      discount,
      selectedVariant,
      campaignId,
      isProductInStock,
      showFreeProductScreen,
      shouldShowChooseGiftScreen,
      offerId,
      name,
      zoomAnimation,
      clearZoomAnimationTimeout: this.clearZoomAnimationTimeout,
    });

    this.handleZoomAnimation();
  };

  handleZoomAnimation = () => {
    if (
      !AppConfig.getBooleanValue(
        Config.SHOW_ZOOM_BUTTON_ON_PRODUCT_PAGE_IMAGE_CAROUSEL,
      )
    ) {
      return;
    }
    const {
      setLastZoomAnimationVisibleAt,
      setZoomAnimationVisibilityCounter,
      last_zoom_animation_visible_at,
      current_zoom_animation_visiblity_counter,
    } = this.props;
    if (!last_zoom_animation_visible_at) {
      if (
        current_zoom_animation_visiblity_counter <
        ZOOM_ANIMATION.MAXIMUM_RENDER_COUNT
      ) {
        this.setZoomAnimation(true);
        this.createZoomAnimationTimeout();
        setZoomAnimationVisibilityCounter(
          current_zoom_animation_visiblity_counter + 1,
        );
        return;
      }
      setLastZoomAnimationVisibleAt(new Date());
      setZoomAnimationVisibilityCounter(0);
      return;
    }
    const timeDiffInDays =
      (new Date().getTime() -
        new Date(last_zoom_animation_visible_at).getTime()) /
      (1000 * 60 * 60 * 24);
    if (timeDiffInDays > ZOOM_ANIMATION.MINIMUM_DAYS_OF_INTERVAL) {
      setLastZoomAnimationVisibleAt(null);
      this.setZoomAnimation(true);
      this.createZoomAnimationTimeout();
    }
  };

  setZoomAnimation = (zoomAnimation) => {
    this.setState({
      zoomAnimation,
    });
    if (zoomAnimation) {
      Utility.setStatusBarColor(colors.zoomOverlay);
      return;
    }
    Utility.setStatusBarWhite();
  };

  getImage = ({ item: imageUrl, index }) => {
    const { itemData } = this.props;
    const { product_image_aspect_shape } = itemData;

    const newUrl = getMinifiedImage(imageUrl, 360);
    this.imageUrl = newUrl;
    return (
      <TouchableOpacity onPress={this.imageTapped}>
        <FastImageView
          resizeMode={
            product_image_aspect_shape === 'vertical' ? 'cover' : 'contain'
          }
          style={this.imageContainerStyle}
          source={getMinifiedImage(imageUrl, 480, 480)}
        />
      </TouchableOpacity>
    );
  };

  tag = (props) => {
    const { tag } = props;
    if (isBlank(tag)) {
      return null;
    }

    return (
      <ImageBackground
        resizeMode='contain'
        source={images.myProfile.freeProductTag}
        style={styles.productTag}
      >
        <View style={styles.tagTextContainer}>
          <Text
            numberOfLines={MAX_LINES.single}
            allowFontScaling={false}
            style={styles.productTagText}
          >
            {tag[0]}
          </Text>
        </View>
      </ImageBackground>
    );
  };

  getItemLayout = (data, index) => {
    return {
      length: getScreenWidth(),
      index,
      offset: getScreenWidth() * index,
    };
  };

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    const visibleItem = viewableItems[0];
    if (!visibleItem) {
      return;
    }
    if (visibleItem.isViewable && this.bulletsRef) {
      this.bulletsRef.setActiveIndex(visibleItem.index);
    }
  };

  onViewableItemsChangedWeb = ({ activeIndex }) => {
    this.bulletsRef.setActiveIndex(activeIndex);
  };

  renderTagShimmer = (props) => {
    const { shimmerWidth, shimmerHeight } = props;
    return (
      <FullWidthShimmer
        style={this.memoizedLabelShimmerStyle()(shimmerWidth, shimmerHeight)}
        speed={2000}
      />
    );
  };

  memoizedLabelImageStyle = () => {
    return (width, height) => {
      if (!this.memoizedLabelImageStyleCache[`${width}${height}`]) {
        this.memoizedLabelImageStyleCache[`${width}${height}`] = [
          ProductInfoStyles.labelImage,
          { width, height },
        ];
      }
      return this.memoizedLabelImageStyleCache[`${width}${height}`];
    };
  };

  memoizedLabelShimmerStyle = () => {
    return (width, height) => {
      if (!this.memoizedLabelShimmerStyleCache[`${width}${height}`]) {
        this.memoizedLabelShimmerStyleCache[`${width}${height}`] = [
          ProductInfoStyles.labelShimmer,
          { width, height },
        ];
      }
      return this.memoizedLabelShimmerStyleCache[`${width}${height}`];
    };
  };

  renderTag = ({ skus, skuToCheck }) => {
    const {
      scrollToInviteFriendCard,
      groupPrice,
      todayDeals: { membership_cohort } = {},
    } = this.props;
    const {
      flash_deal_v2: {
        image: flashDealImage = '',
        product_page_label_width: flashDealImageWidth,
        product_page_label_height: flashDealImageHeight,
        label_name: flashDealLabelName = '',
      } = {},
      group_deal: {
        image: groupDealImage = '',
        product_page_label_width: groupDealImageWidth,
        product_page_label_height: groupDealImageHeight,
        label_name: groupDealLabelName = '',
      } = {},
    } = this.productDealsTagData;

    if (!skus[`${skuToCheck}`] && !groupPrice) return null;

    const isFlashDealActive = skus[`${skuToCheck}`];
    const labelImage = !isFlashDealActive
      ? { uri: groupDealImage }
      : { uri: flashDealImage };
    const labelText = !isFlashDealActive
      ? groupDealLabelName
      : flashDealLabelName;
    const labelImageWidth = isFlashDealActive
      ? flashDealImageWidth
      : groupDealImageWidth;
    const labelImageHeight = isFlashDealActive
      ? flashDealImageHeight
      : groupDealImageHeight;

    return (
      <>
        <DebouncedTouchableOpacity
          {...this.props}
          style={ProductInfoStyles.labelContainer}
          onPress={scrollToInviteFriendCard}
          hitSlop={getHitSlop()}
          disabled={isFlashDealActive}
        >
          <Image
            source={labelImage}
            style={this.memoizedLabelImageStyle()(
              labelImageWidth,
              labelImageHeight,
            )}
          />
          <Text style={this.labelStyle}>{labelText}</Text>
          <this.renderTagShimmer
            shimmerWidth={labelImageWidth}
            shimmerHeight={labelImageHeight}
          />
        </DebouncedTouchableOpacity>
      </>
    );
  };

  zoomButton = () => {
    if (
      !AppConfig.getBooleanValue(
        Config.SHOW_ZOOM_BUTTON_ON_PRODUCT_PAGE_IMAGE_CAROUSEL,
      )
    ) {
      return null;
    }
    let zoomButtonStyle = styles.zoomButton;
    if (
      !AppConfig.getBooleanValue(
        Config.PRODUCT_PAGE_DISABLE_WISHLIST_ICON_SHADOW_AND_BORDER,
      )
    ) {
      zoomButtonStyle = styles.zoomButtonWithShadow;
    }
    return (
      <View style={styles.zoomButtonContainer}>
        <TouchableOpacity
          style={zoomButtonStyle}
          onPress={this.openFullScreenImageModal}
        >
          <FastImageView source={images.zoom_in} style={styles.zoomInImage} />
        </TouchableOpacity>
      </View>
    );
  };

  renderImageListMobile = ({ imagesArray }) => {
    return (
      <FlatList
        data={imagesArray}
        renderItem={this.getImage}
        horizontal
        style={this.imageContainerStyle}
        initialScrollIndex={0}
        initialNumToRender={3}
        // maxToRenderPerBatch={2}
        onViewableItemsChanged={this.onViewableItemsChanged}
        viewabilityConfig={this.viewabilityConfig}
        snapToAlignment={'start'}
        snapToInterval={getScreenWidth()} // Adjust to your content width
        decelerationRate={'fast'}
        showsHorizontalScrollIndicator={false}
        getItemLayout={this.getItemLayout}
        pagingEnabled
        disableIntervalMomentum
      />
    );
  };

  carouselWithoutHeroVideo = () => {
    const {
      images: imagesArray,
      selectedVariant: { sku_id: selectedSkuId } = {},
      selectedVariant,
      currentSku,
    } = this.props;
    const { todayDeals: { skus = {} } = {}, itemData } = this.props;
    const {
      variants_details: { principal_sku_id } = {},
      sku_id,
      rating,
      slug = '',
      content = '',
    } = itemData;
    let skuToCheck = principal_sku_id || sku_id;

    const likedThisItem = isPresent(selectedVariant)
      ? selectedVariant
      : itemData;

    const {
      id = '',
      sku_id: skuId = '',
      type = '',
      mrp = '',
      name = '',
    } = likedThisItem;

    if (isPresent(selectedSkuId)) {
      skuToCheck = selectedSkuId;
    }
    return (
      <View>
        <this.renderTag skus={skus} skuToCheck={skuToCheck} />
        <this.renderImageListMobile imagesArray={imagesArray} />
        <View style={styles.likeButtonContainer}>
          <AnimatedLikeButton
            id={id}
            likedItemSkuId={currentSku}
            type={type}
            slug={slug}
            mrp={mrp}
            skuId={Utility.getSkuId(itemData)}
            layout={LAYOUT.SCREEN}
            content={content}
            onLike={this.onLike}
            itemName={name}
            screenName={SCREEN_CONSTANTS.PRODUCT_DETAIL}
            addToCartLikeButton
            selectedVariant={selectedVariant}
            itemData={itemData}
          />
        </View>
      </View>
    );
  };

  saveBulletsRef = (ref) => {
    this.bulletsRef = ref;
  };

  createZoomAnimationTimeout = () => {
    this.animationTimeout = setTimeout(this.clearZoomAnimationTimeout, 10000);
  };

  clearZoomAnimationTimeout = () => {
    this.setZoomAnimation(false);
    clearTimeout(this.animationTimeout);
  };

  render() {
    const { images } = this.props;

    return (
      <View style={ProductInfoStyles.productImagesContainer}>
        <this.carouselWithoutHeroVideo />
        <Bullets onRef={this.saveBulletsRef} items={images} />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  last_zoom_animation_visible_at:
    state.zoomAnimationVisibility.last_zoom_animation_visible_at,
  current_zoom_animation_visiblity_counter:
    state.zoomAnimationVisibility.current_zoom_animation_visiblity_counter,
  todayDeals: state.todayDeals,
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setLastZoomAnimationVisibleAt,
      setZoomAnimationVisibilityCounter,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ProductImages));
