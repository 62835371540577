import React, { Component } from 'react';
import { Animated, Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';
import PropTypes from 'prop-types';
import DebouncedTouchableWithoutFeedback from './DebouncedTouchableWithoutFeedback';
import { connect } from 'react-redux';

class ScaleAnimate extends Component {
  constructor(props) {
    super(props);
    this.animatedValue = new Animated.Value(1);
  }

  UNSAFE_componentWillMount() {
    this.animatedValue = new Animated.Value(1);
  }

  handlePressIn = () => {
    const { scaleValue } = this.props;
    Animated.spring(this.animatedValue, {
      toValue: scaleValue,
    }).start();
  };

  handlePressOut = () => {
    Animated.spring(this.animatedValue, {
      toValue: 1,
      friction: 5,
      tension: 40,
    }).start();
  };

  handleOnPresss = () => {
    const {
      onPress,
      listData: { options: { is_tappable = false } = {} } = {},
      previousScreen,
      membership_cohort,
      navigation,
    } = this.props;

    if (
      membership_cohort !== 'member' &&
      ['foxy_edge_non_member', 'foxy_edge_expired'].includes(previousScreen) &&
      !is_tappable
    ) {
      navigation.push('BecomeMemberModal', {
        previousScreen,
      });
      return;
    }
    onPress();
  };

  render() {
    const animatedStyle = {
      transform: [{ scale: this.animatedValue }],
    };
    const { onLongPress, children, disabled = false } = this.props;
    return (
      <DebouncedTouchableWithoutFeedback
        disabled={disabled}
        onPressIn={this.handlePressIn}
        onPressOut={this.handlePressOut}
        onPress={this.handleOnPresss}
        onLongPress={onLongPress}
      >
        <Animated.View style={[animatedStyle, styles.container]} dataSet={{ media: ids.container }}>
          {children}
        </Animated.View>
      </DebouncedTouchableWithoutFeedback>
    );
  }
}
const mapStateToProps = (state) => ({
  membership_cohort: state.todayDeals.membership_cohort,
});

ScaleAnimate.defaultProps = {
  onPress: () => {},
  onLongPress: () => {},
  scaleValue: 0.94,
};

ScaleAnimate.propTypes = {
  onPress: PropTypes.func,
  onLongPress: PropTypes.func,
  children: PropTypes.any,
  scaleValue: PropTypes.number,
};

export default connect(mapStateToProps, null)(ScaleAnimate);

const { ids, styles } = StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
      default: {},
    }),
    ':hover': {
      transform: 'scale(0.98)',
    },
  },
});
