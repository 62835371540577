import React, { PureComponent } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Animated,
  Vibration,
  ActivityIndicator,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';
import Toast from 'react-native-easy-toast';
import LinearGradient from '../../../../libraries/ReactNativeLinearGradient';
import withNavigation from '../../../../utils/WithNavigation';
import { withMaybe, withEither } from '../../../../lib/Monads';
import {
  addToCart,
  removeFromCart,
  viewItem,
  renderOnboarding,
  addFreeItemsToCart,
  removeFreeItemToCart,
  incrementDecrementCart,
  incrementDecrementCartBeforeCall,
  getCartItems,
  renderOfferShimmers,
  like,
  addAddedToCartProduct,
} from '../../../../actions/ActionTypes';
import {
  LAYOUT,
  THEME,
  REMOTE_CONFIG_KEYS,
  SCREEN,
} from '../../../../config/Constants';
import ProductCardStyles from '../../../../components/Product/styles/styles';
import images from '../../../../theme/Images';
import { PRODUCT } from '../../../../config/LayoutConstants/ProductConfig';
import Utility from '../../../../utils/Utility';
import {
  ProductDetailStyles,
  ProductInCartStyles,
} from '../../../../components/Product/styles';
import colors, { Theme } from '../../../../theme/Colors';
import VariantModal from '../../../../components/Product/VariantModal';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../../../analytics';
import { SCREEN_CONSTANTS } from '../../../../config/ScreenConstants';

import BarLoader from '../../../../lib/BarLoader';
import RemoteConfig from '../../../../utils/RemoteConfig';
import RoundedButton from '../../../../components/layout/buttons/RoundedButton';
import NetworkStats from '../../../../utils/NetworkStat';
import AnimatedLikeButton from '../../../../components/shared/AnimatedLikeButton';
import DebouncedTouchableOpacity from '../../../../components/shared/DebouncedTouchableOpacity';
import USNRemovalModal from '../../container/cart/USNRemovalModal';
import FoxyAlert from '../../../../components/camera/shared/FoxyAlert';

class AddToCart extends PureComponent {
  // NOTE: state variable with prev is used for optimistic update, as state is changed instantaneously on
  // user initiation, while actual data from api is handled in get derivedStatefromprops
  constructor(props) {
    super(props);
    this.state = {
      showUSNRemovalModal: false,
    };
    this.addedToCart = props.addedToCart;
    this.addedToCartForCard = props.addedToCartForCard;
    this.state = {
      addedToCart: this.addedToCart,
      prevAddedToCard: this.addedToCart,
      addedToCartForCard: this.addedToCartForCard,
      prevAddedToCartForCard: this.addedToCartForCard,
      leftPosition: new Animated.Value(0),
      isAnimating: false,
      isRemoveProductConfirmationAlertVisible: false,
    };
    this.checkoutMeta = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.checkout_new),
    );

    if (!!props?.inModal) {
      this.checkoutMeta = {
        change_add_to_cart_button: false,
        button_name: 'Add to cart',
        navigate_after_add_to_cart: false,
        address_before_cart: false,
        cart_priority: 1,
        payment_priority: 0,
        address_before_cart_for_added_products: false,
      };
    }
    this.debouncedAddToCart = _.debounce(
      this.incrementProductInCartAndFireAnalytics,
      1000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.debouncedRemoveFromCart = _.debounce(
      this.decrementProductInCartAndFireAnalytics,
      1000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.debouncedUpdateCart = _.debounce(this.updateCart, 500, {
      leading: true,
      trailing: false,
    });
  }

  static getDerivedStateFromProps = (props, state) => {
    if (
      props.addedToCart !== state.prevAddedToCard ||
      props.addedToCartForCard !== state.prevAddedToCartForCard
    ) {
      return {
        addedToCart: props.addedToCart,
        prevAddedToCard: props.addedToCart,
        addedToCartForCard: props.addedToCartForCard,
        prevAddedToCartForCard: props.addedToCartForCard,
      };
    }
    // Return null if the state hasn't changed
    return null;
  };

  loadAddToCartAnimation = (isLoading) => {
    this.setState({
      isAnimating: isLoading,
    });
  };

  verticalRailOrPersonalisedRailCondition = (props) =>
    !(
      props.layout === LAYOUT.VERTICALRAIL ||
      props.layout === LAYOUT.PERSONALISEDRAIL
    );

  conditionalText = withMaybe(this.verticalRailOrPersonalisedRailCondition)(
    Text,
  );

  addToCartCallback = (response) => {
    const { auto_applied_coupon_message } = response;
    const {
      errorCallback,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      getCartItems,
      setUnfulfilledCoupon,
    } = this.props;

    if (response.condition_unfullfilled) {
      setUnfulfilledCoupon(json);
      this.setState({
        errors: true,
      });
    } else if (
      Utility.isPresent(response.errors) &&
      Utility.isPresent(response.errors[0])
    ) {
      this.setState({
        addedToCartForCard: false,
      });
      getCartItems(() => {}, false);
      if (showToast) {
        showToast(response.errors[0]);
      }
    }
    if (errorCallback !== undefined && errorCallback !== null) {
      errorCallback(response);
    }

    if (showToast && auto_applied_coupon_message) {
      showToast(auto_applied_coupon_message);
      AnalyticsManager.logEvent(EventType.discoveryEvents.COUPON_TOAST, {
        [EventParameterKey.MESSAGE]: auto_applied_coupon_message,
      });
    }
  };

  addItemToCart = (data) => {
    const { onItemAddToCartFromCollab, addToCart, offer_id } = this.props;

    this.setState({
      addedToCart: true,
      addedToCartForCard: true,
    });
    addToCart(updatedData, (response) =>
      this.addToCartCallback(response, data),
    );
    if (onItemAddToCartFromCollab) {
      onItemAddToCartFromCollab();
    }
  };

  changeItemInCart = (data) => {
    const {
      onItemAddToCartFromCollab,
      setPromptLoading,
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      renderOfferShimmers = () => {},
      itemData = {},
      offer_id,
      setUnfulfilledCoupon,
      offerNotClubbable,
      removeUnFullfilledCoupon,
      inModal,
      setSuccessfullyAppliedCoupon,
      getCartItems,
    } = this.props;
    this.loadAddToCartAnimation(true);
    renderOfferShimmers(true);

    setTimeout(() => {
      renderOfferShimmers(false);
      this.loadAddToCartAnimation(false);
    }, 4000);

    if (typeof setPromptLoading === 'function') setPromptLoading();
    this.setState({
      addedToCart: true,
      addedToCartForCard: true,
    });

    const flashDealData = {
      type: 'Product',
      id: itemData?.id,
    };

    data.flashDealData = flashDealData;
    if (Utility.isPresent(offer_id)) {
      data.offer_id = offer_id;
    }

    incrementDecrementCartBeforeCall(data);

    incrementDecrementCart(data, (response) => {
      renderOfferShimmers(false);

      if (inModal) {
        getCartItems();
      }

      this.loadAddToCartAnimation(false);
      if (response?.condition_unfullfilled) {
        setUnfulfilledCoupon(response);
        return;
      }
      if (response?.clubbable === false) {
        removeUnFullfilledCoupon();
        setTimeout(() => {
          offerNotClubbable(
            response.coupon_code,
            response.offer_id,
            response.offers_prompts,
          );
        }, 500);

        return;
      }
      if (response && inModal) {
        removeUnFullfilledCoupon();
        const appliedCoupon = response?.prompts?.find(
          (couponCode) => couponCode.coupon_code === offer_id,
        );
        setSuccessfullyAppliedCoupon(appliedCoupon);
        return;
      }
      this.addToCartCallback(response, data);
    });
    if (onItemAddToCartFromCollab) {
      onItemAddToCartFromCollab();
    }
  };

  addToCartDesignConstants = {
    textColor: {
      true: colors.foxyPink,
      false: colors.foxyBlack,
    },
    borderColor: {
      true: colors.borderDark,
      false: colors.foxyBlack,
    },
  };

  addFreeItemsToBag = () => {};

  updateCart = (cartItemData, showAddedToCart) => {
    const {
      previousScreen,
      onItemAddToCartFromCollab,
      listId,
      layout,
      listName,
      listIndex,
      selectedVariant = {},
      selectedVariant: {
        id: varId = '',
        name: variantName = '',
        sku: { group_buying_price = '' } = {},
      } = {},
      skuId,
      index,
      listContent,
      hasPromptLoaded,
      removeFromCart,
      navigation,
      dismissModal = () => {},
      address,
      variantModal = false,
      cancelpressed = () => {},
      todayDeals: { skus = {} } = {},
      isBuyNow,
      addAddedToCartProduct,
      itemData,
      favourites: { products = {}, variants = {} } = {},
    } = this.props;
    const { addedToCart } = this.state;
    const networkInfo = NetworkStats.networkDetails;

    if (isBuyNow) {
      dismissModal();
      variantModal && cancelpressed();
      if (Utility.isBlank(address)) {
        navigation.navigate('RetailAddress', {
          proceedTo: 'Payments',
        });
      } else {
        addAddedToCartProduct(itemData);
        navigation.navigate('Payments', { showOrderSummary: true });
      }
    } else if (!showAddedToCart) {
      if (this.checkoutMeta.navigate_after_add_to_cart) {
        dismissModal();
        variantModal && cancelpressed();
        if (Utility.isBlank(address) && this.checkoutMeta.address_before_cart) {
          navigation.navigate('RetailAddress', {
            proceedTo:
              this.checkoutMeta.payment_priority >
              this.checkoutMeta.cart_priority
                ? 'Payments'
                : 'Cart',
          });
        } else if (
          this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
        ) {
          navigation.navigate('Payments', { showOrderSummary: true });
        } else {
          navigation.navigate('Cart', { showOrderSummary: true });
        }
      }
    }

    if (typeof hasPromptLoaded === 'function') hasPromptLoaded();

    const data = {
      sku_id: cartItemData.skuId,
      quantity: 1,
    };

    if (showAddedToCart && !isBuyNow) {
      if (previousScreen === 'brandCollab') {
        removeFromCart(data, () => {
          if (onItemAddToCartFromCollab) {
            onItemAddToCartFromCollab();
          }
        });
        return;
      }
      dismissModal();
      if (
        Utility.isBlank(address) &&
        this.checkoutMeta.address_before_cart_for_added_products
      ) {
        navigation.navigate('RetailAddress', {
          proceedTo:
            this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
              ? 'Payments'
              : 'Cart',
        });
      } else if (
        this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
      ) {
        navigation.navigate('Payments', { showOrderSummary: true });
      } else {
        navigation.navigate('Cart', { showOrderSummary: true });
      }
    } else {
      const {
        itemData,
        prompts = [],
        couponCodes,
        address,
        addAddedToCartProduct,
        isBuyNow,
      } = this.props;
      const { offersEsp } = Utility.getEsp(prompts, couponCodes);

      const networkData = Utility.getNetworkInfo(networkInfo);
      if (Utility.isPresent(itemData)) {
        const productMeta = {
          ...networkData,
          [EventParameterKey.SCREEN_NAME]: previousScreen,
          [EventParameterKey.ITEM_POSITION]: index || 0,
          [EventParameterKey.SKU_ID]: skuId,
          [EventParameterKey.PRODUCT_ID]: itemData.id,
          [EventParameterKey.BRAND_ID]: Utility.isPresent(itemData?.brand)
            ? itemData?.brand?.id
            : '',
          [EventParameterKey.PRODUCT_LISTING_PRICE]: itemData?.mrp,
          [EventParameterKey.PRODUCT_SELLING_PRICE]: itemData?.sp,
          [EventParameterKey.QUANTITY]: 1,
          [EventParameterKey.LIST_ID]: listData?.id ?? '',
          [EventParameterKey.LIST_DISPLAY]: Utility.isPresent(layout)
            ? layout
            : '',
          [EventParameterKey.LIST_CONTENT]: listContent || 'product',
          [EventParameterKey.LIST_POSITION]: listIndex || 0,
          esp: offersEsp,
          [EventParameterKey.ADDRESS_PRESENT]: Utility.isPresent(address),
          wishlist_deal_price: skus[`${skuId}`]?.esp || 'not_eligible',
          edge_deal_price: skus[`${skuId}`]?.member_esp || 'not_eligible',
          is_buy_now: !!isBuyNow,
          [EventParameterKey.GROUP_DEAL_PRICE]: group_buying_price,
        };
        if (Utility.isPresent(selectedVariant)) {
          productMeta[[EventParameterKey.VARIANT_ID]] = varId;
          productMeta[[EventParameterKey.GROUP_DEAL_PRICE]] =
            group_buying_price;
        }

        AnalyticsManager.logEvent(
          EventType.discoveryEvents.PRODUCT_ADDED_TO_CART,
          productMeta,
        );
        AnalyticsManager.logFirebaseEvent(
          EventType.googleRemarketingEvents.ADD_TO_CART,
          {
            [EventParameterKey.VALUE]: itemData.mrp,
            [EventParameterKey.CURRENCY]: 'INR',
            [EventParameterKey.ITEMS]: [
              {
                [EventParameterKey.ITEM_ID]: skuId,
              },
            ],
          },
        );
        AnalyticsManager.logFBStandardEvent(
          EventType.FB.EVENT_NAME_ADDED_TO_CART,
          itemData.mrp,
          {
            [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: 'INR',
            [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: `${skuId}`,
            [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
            [EventParameterKey.FB.EVENT_PARAM_CONTENT]: JSON.stringify([
              {
                id: skuId,
                quantity: 1,
                item_price: itemData.mrp,
              },
            ]),
          },
        );
      }

      this.changeItemInCart(data); // Adding/Removing item from cart
    }
  };

  incrementProductInCartAndFireAnalytics = (cartItemData) => {
    const data = {
      sku_id: cartItemData.sku_id,
      quantity: +1,
      source: 'cart',
    };

    this.changeItemInCart(data); // Adding/Removing item from cart
    const {
      variant,
      cartItem = {},
      skuId,
      cartItem: { prompts = [], sku: { group_buying_price = '' } = {} } = {},
      couponCodes,
      address,
      todayDeals: { skus = {} } = {},
      isBuyNow,
      favourites: { products = {}, variants = {} } = {},
    } = this.props;
    const networkInfo = NetworkStats.networkDetails;
    const { offersEsp } = Utility.getEsp(prompts, couponCodes);
    const offerIds = [];
    _.forEach(prompts, (e) => {
      offerIds.push(e.offer_id);
    });
    const networkData = Utility.getNetworkInfo(networkInfo);
    const productMeta = {
      ...networkData,
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.CART,
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.PRODUCT_ID]: cartItem.id,
      [EventParameterKey.BRAND_ID]: cartItem.brand.id,
      [EventParameterKey.PRODUCT_LISTING_PRICE]: cartItem.mrp,
      [EventParameterKey.PRODUCT_SELLING_PRICE]: cartItem.sp,
      [EventParameterKey.QUANTITY]: data.quantity,
      esp: offersEsp,
      [EventParameterKey.ADDRESS_PRESENT]: Utility.isPresent(address),
      wishlist_deal_price: skus[`${skuId}`]?.esp || 'not_eligible',
      edge_deal_price: skus[`${skuId}`]?.member_esp || 'not_eligible',
      is_buy_now: !!isBuyNow,
      [EventParameterKey.GROUP_DEAL_PRICE]: group_buying_price,
    };
    if (Utility.isPresent(variant)) {
      productMeta[[EventParameterKey.VARIANT_ID]] = variant.id;
    }
    AnalyticsManager.logEvent(
      EventType.discoveryEvents.PRODUCT_ADDED_TO_CART,
      productMeta,
    );
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.ADD_TO_CART,
      {
        [EventParameterKey.VALUE]: cartItem.mrp,
        [EventParameterKey.CURRENCY]: 'INR',
        [EventParameterKey.ITEMS]: [
          {
            [EventParameterKey.ITEM_ID]: skuId,
          },
        ],
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_ADDED_TO_CART,
      cartItem.mrp,
      {
        [EventParameterKey.FB.EVENT_PARAM_CURRENCY]: 'INR',
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_ID]: `${skuId}`,
        [EventParameterKey.FB.EVENT_PARAM_CONTENT_TYPE]: 'product',
        [EventParameterKey.FB.EVENT_PARAM_CONTENT]: JSON.stringify([
          {
            id: skuId,
            quantity: 1,
            item_price: cartItem.mrp,
          },
        ]),
      },
    );
  };

  decrementProductInCartAndFireAnalytics = () => {
    const {
      onItemAddToCartFromCollab,
      isFreeItem = false,
      cartItem,
      quantity,
      variant,
      skuId,
    } = this.props;

    const data = {
      sku_id: skuId,
      quantity: -1,
      is_free: isFreeItem,
      source: 'cart',
    };

    this.changeItemInCart(data); // Adding/Removing item from cart
    const productMeta = {
      [EventParameterKey.PRODUCT_ID]: cartItem?.id,
      [EventParameterKey.PRODUCT_NAME]: cartItem?.name,
      [EventParameterKey.BRAND_ID]: cartItem?.brand?.id,
      [EventParameterKey.BRAND_NAME]: cartItem?.brand?.name,
      [EventParameterKey.PRODUCT_LISTING_PRICE]: cartItem?.mrp,
      [EventParameterKey.PRODUCT_SELLING_PRICE]: cartItem?.sp,
      [EventParameterKey.QUANTITY]: 1,
    };

    if (Utility.isPresent(variant)) {
      productMeta[[EventParameterKey.VARIANT_ID]] = variant.id;
      productMeta[[EventParameterKey.VARIANT_NAME]] = variant.display_name;
    }
    AnalyticsManager.logEvent(
      EventType.discoveryEvents.PRODUCT_REMOVE_FROM_CART,
      productMeta,
    );
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.REMOVE_FROM_CART,
      {
        [EventParameterKey.CURRENCY]: 'INR',
        [EventParameterKey.VALUE]: cartItem.mrp,
        [EventParameterKey.ITEMS]: [
          {
            [EventParameterKey.ITEM_ID]: { id: skuId },
          },
        ],
      },
    );
  };

  checkToAddProductOrShowCart = (cartItemData, showAddedToCart) => {
    const { isBuyNow } = this.props;
    if (!showAddedToCart) {
      const {
        hasVariants,
        selectedVariant,
        onMoreShadeTap,
        variantName,
        showToast = () => {},
        variantType,
        showProductPagePromptModal,
        showCart = () => {},
        dismissModal = () => {},
        navigation,
        address,
        isBuyNow,
      } = this.props;

      if (hasVariants && Utility.isBlank(selectedVariant)) {
        if (variantType === 'image') {
          onMoreShadeTap();
        } else {
          showToast('Please select a size');
        }
      } else {
        Vibration.vibrate(200);

        this.debouncedUpdateCart(cartItemData, showAddedToCart);
      }
    } else {
      const {
        variantModal = false,
        cancelpressed = () => {},
        dismissModal = () => {},
        navigation,
        address,
        isBuyNow,
        removeUnFullfilledCoupon,
        inModal,
      } = this.props;
      variantModal && cancelpressed();
      dismissModal();

      if (inModal) {
        removeUnFullfilledCoupon();
      }

      if (isBuyNow) {
        if (Utility.isBlank(address)) {
          navigation.push('RetailAddress', {
            proceedTo: 'Payments',
          });
        } else {
          navigation.push('Payments', { showOrderSummary: true });
        }
      } else {
        if (
          Utility.isBlank(address) &&
          this.checkoutMeta.address_before_cart_for_added_products
        ) {
          navigation.push('RetailAddress', {
            proceedTo:
              this.checkoutMeta.payment_priority >
              this.checkoutMeta.cart_priority
                ? 'Payments'
                : 'Cart',
          });
        } else if (
          this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
        ) {
          navigation.navigate('Payments', { showOrderSummary: true });
        } else {
          navigation.push('Cart', { showOrderSummary: true });
        }
      }
    }
  };

  showVariantModal = () => {
    const {
      itemData,
      listId,
      listName,
      listIndex,
      listData = {},
      onItemAddToCartFromCollab,
      previousScreen,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      navigation,
    } = this.props;
    navigation.navigate('VariantModal', {
      itemData,
      listId,
      listName,
      listIndex,
      listData,
      onItemAddToCartFromCollab,
      previousScreen,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
    });
  };

  checkToAddProductOrShowShade = (cartItemData, showAddedToCart) => {
    const {
      hasVariants,
      outOfStock,
      singleStockedVariantSku,
      showCart,
      navigation,
      address,
      isBuyNow,
      itemData,
    } = this.props;
    const { addedToCartForCard } = this.state;

    if (
      hasVariants &&
      !outOfStock &&
      Utility.isPresent(singleStockedVariantSku)
    ) {
      if (addedToCartForCard) {
        if (isBuyNow) {
          if (Utility.isBlank(address)) {
            navigation.navigate('RetailAddress', {
              proceedTo:
                this.checkoutMeta.payment_priority >
                this.checkoutMeta.cart_priority
                  ? 'Payments'
                  : 'Cart',
            });
          } else {
            navigation.navigate('Payments', { showOrderSummary: true });
          }
        } else {
          if (
            Utility.isBlank(address) &&
            this.checkoutMeta.address_before_cart_for_added_products
          ) {
            navigation.navigate('RetailAddress', {
              proceedTo:
                this.checkoutMeta.payment_priority >
                this.checkoutMeta.cart_priority
                  ? 'Payments'
                  : 'Cart',
            });
          } else if (
            this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
          ) {
            navigation.navigate('Payments', { showOrderSummary: true });
          } else {
            navigation.navigate('Cart', { showOrderSummary: true });
          }
        }
      } else {
        this.debouncedUpdateCart(cartItemData, showAddedToCart);
      }
    } else if (hasVariants && !outOfStock) {
      this.showVariantModal();
    } else if (outOfStock) {
      navigation.navigate('UnavailableProductsModal', { itemData });
    } else {
      this.debouncedUpdateCart(cartItemData, showAddedToCart);
    }
  };

  productDetailAddToCartButton = (props) => {
    const { showAddedToCart, skuId, isBuyNow } = props;
    const {
      variantModal = false,
      inModal = false,
      can_offer_fullfill,
      isOfferShimmerVisible,
    } = this.props;
    let bagText = showAddedToCart ? 'View Bag' : 'Add To Bag';

    const bagColor = showAddedToCart ? colors.foxyPink : colors.green;
    let imageSource = images.addedToCart.dark[showAddedToCart];
    const cartItemData = {
      skuId,
    };

    let bagFirstColor = '#51CB8C';
    let bagSecondColor = '#03B460';

    if (isBuyNow) {
      bagText = 'Buy Now';
      imageSource = images.addedToCart.flash;

      if (showAddedToCart) {
        imageSource = images.addedToCart.flash;
        bagText = 'Checkout';
      }
    } else {
      if (this.checkoutMeta.change_add_to_cart_button) {
        if (showAddedToCart) {
          bagText =
            this.checkoutMeta.payment_priority > this.checkoutMeta.cart_priority
              ? 'Checkout'
              : 'View Bag';
        } else {
          bagText = this.checkoutMeta.button_name;
          imageSource = images.addedToCart.flash;
        }
      }
    }

    let buyNowButtonStyle = !isBuyNow
      ? ProductDetailStyles.bagContainerModal
      : ProductDetailStyles.bagContainerVariantModal;

    let buyNowButtonTextStyle = !isBuyNow
      ? ProductDetailStyles.buyNowBagText
      : ProductDetailStyles.addToBagText;

    let iconStyle = !isBuyNow
      ? ProductDetailStyles.detailAddToCartImageDefault
      : ProductDetailStyles.detailAddToCartImageBuyNow;

    if (inModal) {
      buyNowButtonStyle = {
        height: 48,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        marginTop: 8,
        width: Utility.getScreenWidth() - 24,
        backgroundColor: colors.black,
      };

      buyNowButtonTextStyle = ProductDetailStyles.addToBagText;

      iconStyle = [
        ProductDetailStyles.detailAddToCartImageBuyNow,
        { tintColor: colors.white },
      ];
    }

    if (can_offer_fullfill) {
      bagText = 'Add to Bag & Apply Offer';
    }

    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={() => {
          this.checkToAddProductOrShowCart(
            cartItemData,
            showAddedToCart,
            bagText,
          );
        }}
        hitSlop={Utility.getHitSlop()}
      >
        <View style={buyNowButtonStyle}>
          {inModal && isOfferShimmerVisible ? (
            <ActivityIndicator color={colors.white} size="small" />
          ) : (
            <View style={ProductDetailStyles.addToBagViewVariantModal}>
              {!inModal && <Image source={imageSource} style={iconStyle} />}
              <Text style={buyNowButtonTextStyle}>{bagText}</Text>
            </View>
          )}
        </View>
      </DebouncedTouchableOpacity>
    );
  };

  matchedProductCardAddToCartButton = (props) => {
    const {
      showAddedToCart,
      layout,
      theme = THEME.LIGHT,
      skuId,
      itemData,
      listId,
      listData = {},
      listName,
      listIndex,
      style,
    } = props;
    const {
      campaignId,
      onItemAddToCartFromCollab,
      previousScreen,
      singleStockedVariantSku,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      smallButton = false,
    } = this.props;
    const { addedToCartForCard } = this.state;
    let { outOfStock = false } = this.props;
    if (outOfStock === null) {
      outOfStock = false;
    }
    let styleCart = ProductCardStyles[`${layout}CartStyle`];
    const imageSource = images.addedToCart[theme][addedToCartForCard];

    const addToCartText = addedToCartForCard ? 'View Bag' : 'Add to Bag';

    if (layout === LAYOUT.VERTICALRAIL) {
      styleCart = {
        ...ProductCardStyles[`${layout}CartStyle`],
      };
    }
    if (layout === LAYOUT.PERSONALISEDRAIL) {
      styleCart = {
        ...ProductCardStyles[`${layout}CartStyle`],
      };
    }
    let cartItemData = {
      skuId,
      quantity: 1,
    };
    if (Utility.isPresent(singleStockedVariantSku)) {
      cartItemData = {
        skuId: singleStockedVariantSku,
        quantity: 1,
      };
    }
    if (campaignId) {
      cartItemData['campaign_id'] = campaignId;
    }

    let buttonWidth = smallButton ? 132 : 153;

    if (Utility.isPresent(style)) {
      buttonWidth = style.width;
    }

    return (
      <>
        <RoundedButton
          buttonText={addToCartText}
          buttonTextColor={'#fff'}
          buttonColor={'#000'}
          buttonAction={() => {
            this.checkToAddProductOrShowShade(cartItemData, showAddedToCart);
          }}
          style={{
            width: buttonWidth,
            height: 36,
            borderRadius: 20,
            flexDirection: 'row',
            alignItems: 'center',
          }}
          buttonTextStyle={{
            fontSize: 14,
            fontFamily: 'Roboto-Medium',
            marginLeft: 8,
          }}
          renderProp={() => {
            return (
              <Image
                source={imageSource}
                style={{
                  ...ProductCardStyles[`${layout}AddToCartImage`],
                  tintColor: colors.white,
                }}
              />
            );
          }}
        />
      </>
    );
  };

  productCardAddToCartButton = (props) => {
    const {
      showAddedToCart,
      layout,
      theme = THEME.LIGHT,
      skuId,
      itemData,
      listId,
      listName,
      listIndex,
      listData = {},
    } = props;
    const {
      campaignId,
      onItemAddToCartFromCollab,
      previousScreen,
      singleStockedVariantSku,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      additional_data,
    } = this.props;
    const { addedToCartForCard } = this.state;
    let { outOfStock = false } = this.props;
    if (outOfStock === null) {
      outOfStock = false;
    }
    let styleCart = ProductCardStyles[`${layout}CartStyle`];
    const imageSource = images.addedToCart[theme][addedToCartForCard];

    if (layout === LAYOUT.VERTICALRAIL) {
      styleCart = {
        ...ProductCardStyles[`${layout}CartStyle`],
      };
    }
    if (layout === LAYOUT.PERSONALISEDRAIL) {
      styleCart = {
        ...ProductCardStyles[`${layout}CartStyle`],
      };
    }
    let cartItemData = {
      skuId,
      quantity: 1,
    };
    if (Utility.isPresent(singleStockedVariantSku)) {
      cartItemData = {
        skuId: singleStockedVariantSku,
        quantity: 1,
      };
    }
    if (campaignId) {
      cartItemData['campaign_id'] = campaignId;
    }
    let cartImageTintColor = colors.white;
    if (theme !== THEME.DARK) {
      cartImageTintColor = colors.foxyBlack;
    }
    let opacity = 1;

    if (addedToCartForCard) {
      cartImageTintColor = colors.foxyPink;
    }

    if (additional_data?.item_action?.primary === 'like') {
      return (
        <AnimatedLikeButton
          ref={this.likeButtonRef}
          id={itemData.id}
          likedItemSkuId={itemData.sku_id}
          type={itemData.type}
          slug={itemData.slug}
          mrp={itemData.mrp}
          skuId={Utility.getSkuId(itemData)}
          layout={LAYOUT.SCREEN}
          content={itemData.content}
          onLike={this.onLike}
          itemName={itemData.name}
          screenName={SCREEN_CONSTANTS.PRODUCT_CARD}
          addToCartLikeButton
          withoutShadow
        />
      );
    }

    return (
      <>
        <TouchableOpacity
          onPress={() =>
            this.checkToAddProductOrShowShade(cartItemData, showAddedToCart)
          }
          hitSlop={Utility.getHitSlop()}
        >
          <View style={[styleCart, { opacity }]}>
            <Image
              source={imageSource}
              style={[
                ProductCardStyles[`${layout}AddToCartImage`],
                { tintColor: cartImageTintColor },
              ]}
            />
          </View>
        </TouchableOpacity>
      </>
    );
  };

  onRemoveFromCartPressFromCart = () => {
    const { disableQuantityChange = false } = this.props;
    const { isAnimating, isRemoveProductConfirmationAlertVisible } = this.state;
    if (disableQuantityChange || isAnimating) return;
    if (isRemoveProductConfirmationAlertVisible) {
      this.closeRemoveProductConfirmationAlert();
    }
    this.debouncedRemoveFromCart();
  };

  onAddToCartPressFromCart = (cartItemData) => {
    const { isFreeItem = false, navigation } = this.props;
    const { isAnimating } = this.state;
    if (isFreeItem || isAnimating) return;
    // this.debouncedAddToCart(cartItemData);
    navigation.navigate('ScanUSN', { previousScreen: 'Cart' });
  };

  cartIncrementDecrementButton = (props) => {
    const { skuId, quantity, campaignId } = props;
    const {
      error,
      isFreeItem = false,
      disableQuantityChange = false,
    } = this.props;
    const { isAnimating } = this.state;
    const cartItemData = {
      sku_id: skuId,
      quantity,
      campaignId,
    };
    const styles = ProductInCartStyles;
    let plusButtonStyle = styles.plus;

    if (Utility.isPresent(error) || isFreeItem) {
      plusButtonStyle = styles.plusDisabled;
    }

    if (isAnimating) {
      plusButtonStyle = styles.plusWhileCartChanging;
    }
    return (
      <View style={styles.cartButtonContainer}>
        <View style={styles.incrementDecrementSwitch}>
          <TouchableOpacity
            onPress={this.showRemoveProductConfirmationAlert}
            style={styles.decrementButtonContainer}
            hitSlop={Utility.getHitSlop('cross')}
          >
            <this.renderDecrementButtonImage />
          </TouchableOpacity>
          <Text
            allowFontScaling={false}
            style={styles.productQuantandVariantText}
          >
            {quantity}
          </Text>
          <TouchableOpacity
            onPress={() => this.onAddToCartPressFromCart(cartItemData)}
            style={styles.incrementButtonContainer}
            hitSlop={Utility.getHitSlop('cross')}
          >
            <Image source={images.plus} style={plusButtonStyle} />
          </TouchableOpacity>
        </View>
        {isAnimating && <BarLoader iterations={5} width={65} />}
      </View>
    );
  };

  renderDecrementButtonImage = () => {
    const { quantity, disableQuantityChange = false } = this.props;
    const { isAnimating } = this.state;

    const styles = ProductInCartStyles;
    if (quantity > 1) {
      let minusButtonStyle = isAnimating
        ? styles.minusWhileCartChanging
        : styles.minus;
      if (disableQuantityChange) {
        minusButtonStyle = styles.minusDisabled;
      }
      return <View style={minusButtonStyle} />;
    }
    return (
      <Image source={images.previewScreenIcons.delete} style={styles.delete} />
    );
  };

  cartConditionFn = (props) =>
    props.addToCartLayout === PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL;

  showIncrementDecrementButtonFn = (props) =>
    props.addToCartLayout === PRODUCT.ADD_TO_CART_LAYOUT.CART;

  matchedProductAddToCartCondition = (props) =>
    props.layout === LAYOUT.MATCHED_PRODUCT;

  addToCartButton = compose(
    withEither(
      this.matchedProductAddToCartCondition,
      this.matchedProductCardAddToCartButton,
    ),
    withEither(this.cartConditionFn, this.productDetailAddToCartButton),
    withEither(
      this.showIncrementDecrementButtonFn,
      this.cartIncrementDecrementButton,
    ),
  )(this.productCardAddToCartButton);

  closeRemoveProductConfirmationAlert = () => {
    this.setState({ isRemoveProductConfirmationAlertVisible: false });
  };

  showRemoveProductConfirmationAlert = () => {
    const { quantity, isFreeItem, usns } = this.props;
    if (Utility.isPresent(usns)) {
      this.setState({ showUSNRemovalModal: true });
      return;
    }
    quantity === 1 && !isFreeItem
      ? this.setState({ isRemoveProductConfirmationAlertVisible: true })
      : this.onRemoveFromCartPressFromCart();
  };

  addToWishlist = () => {
    const {
      like,
      cartItem: { id, type },
    } = this.props;
    this.onRemoveFromCartPressFromCart();
    like({ id, type });
  };

  closeUSNRemovalModal = () => {
    this.setState({ showUSNRemovalModal: false });
  };

  render() {
    const {
      layout,
      skuId,
      theme,
      addToCartLayout,
      hideAddToCart,
      quantity,
      campaignId,
      isBuyNow,
      cartItem,
      cartItem: { image_url = '' } = {},
      usns,
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
    } = this.props;
    const { showUSNRemovalModal } = this.state;
    if (hideAddToCart) {
      return null;
    }
    const { addedToCart, isRemoveProductConfirmationAlertVisible } = this.state;
    const bagText = PRODUCT.BAG_TEXT[addedToCart];
    return (
      <>
        <this.addToCartButton
          {...this.props}
          showAddedToCart={addedToCart}
          bagText={bagText}
          theme={theme}
          skuId={skuId}
          layout={layout}
          addToCartLayout={addToCartLayout}
          quantity={quantity}
          campaignId={campaignId}
          isBuyNow={isBuyNow}
        />
        <FoxyAlert
          isVisible={isRemoveProductConfirmationAlertVisible}
          onTapOutside={this.closeRemoveProductConfirmationAlert}
          alertBoxTitle="Remove from bag?"
          alertMessage={'Are you sure you want to remove this?'}
          firstButtonTitle="Move to Wishlist"
          secondButtonTitle="Remove"
          image_url={cartItem?.image_url}
          firstButtonOnPress={this.addToWishlist}
          secondButtonOnPress={this.onRemoveFromCartPressFromCart}
          showImage
          firstButtonTextColor={colors.foxyBlue}
          autoWrapContent
        />
        <Toast
          style={{ position: 'absolute', bottom: 20 }}
          ref={(ref) => {
            this.toast = ref;
          }}
        />
        <USNRemovalModal
          isVisible={showUSNRemovalModal}
          usns={usns}
          image={image_url}
          closeModal={this.closeUSNRemovalModal}
          incrementDecrementCart={incrementDecrementCart}
          incrementDecrementCartBeforeCall={incrementDecrementCartBeforeCall}
        />
      </>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  const { skuId, id, previousScreen } = ownProps;
  const object =
    previousScreen === SCREEN_CONSTANTS.FREE_GIFTS ? 'freeItems' : 'cartItems';
  const isSkuAddedToCart = !!(
    store.bag && _.findKey(store.bag[object], (item) => item.sku_id === skuId)
  );
  const {
    UserAccountInfo: {
      remote_configs: { data = {} } = {},
      authToken = null,
    } = {},
    bag = {},
    cart: { freeItems = [] } = {}, // TODO: move all the keys from store.cart to store.bag
  } = store;
  const { cartPricing: { coupon_codes = [] } = {} } = bag;
  return {
    freeItemCount:
      store.cart && store.cart.freeItems ? store.cart.freeItems.length : 0,
    authToken: store.UserAccountInfo.authToken,
    addedToCart: isSkuAddedToCart,
    address: store.UserAccountInfo.addresses,
    todayDeals: store.todayDeals,
    favourites: store.UserAccountInfo.favourites,
    couponCodes:
      store.bag.cartPricing && store.bag.cartPricing.coupon_codes
        ? store.bag.cartPricing.coupon_codes
        : [],
    isOfferShimmerVisible: store.bag.isOfferShimmerVisible,
    addedToCartForCard:
      isSkuAddedToCart ||
      !!_.findKey(bag[object], (item) => {
        if (Utility.isPresent(item.product)) {
          return item.product?.id === id;
        }
        return false;
      }), // this gives added to cart true if any of the variant(skuid) of a product(product.id) is added to cart
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addToCart,
      removeFromCart,
      viewItem,
      renderOnboarding,
      addFreeItemsToCart,
      removeFreeItemToCart,
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      getCartItems,
      renderOfferShimmers,
      like,
      addAddedToCartProduct,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(AddToCart),
);
