// Dependencies
import React, { Component } from 'react';
import {
  View, Image, Text, TouchableOpacity,
} from 'react-native';
import { connect } from 'react-redux';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { ProductInfoStyles } from './styles';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import { MAX_LINES } from '../../config/Constants';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';

class RecommendationBanner extends Component {
  recommendationBannerPressed = () => {
    const { onMoreShadeTap } = this.props;
    onMoreShadeTap();
  };

  userAvatar = (props) => {
    const styles = ProductInfoStyles;
    const { imageUrl } = this.props;
    const imageSource = Utility.isImageUrlValid
      ? { uri: Utility.getMinifiedImage(imageUrl, 80, 80) }
      : images.profilePicPlaceholder;
    return (
      <>
        <Image
          source={imageSource} // profilePicPlaceholder
          style={styles.recommendationBannerAvatar}
        />
        <View style={styles.recommendationBannerTickContainer}>
          <Image source={images.recommendation_tick} style={styles.recommendationBannerTickImage} />
        </View>
      </>
    );
  };

  recommendedShimmerStrip = () => {
    const styles = ProductInfoStyles;
    return (
      <View style={styles.recommendationBannerCard}>
        <ShimmerPlaceHolder autoRun style={styles.recommendationBannerAvatarShimmer} />
        <ShimmerPlaceHolder autoRun style={styles.recommendationTextContainer} />
      </View>
    );
  };

  render() {
    const styles = ProductInfoStyles;
    const gradientArray = ['#52CB8C', '#01B460'];
    const {
      hasVariants, recommendedVariants, showRecommendationShimmer, variantType,
    } = this.props;
    if (!hasVariants) {
      return null;
    }
    if (showRecommendationShimmer) {
      return null;
      return <this.recommendedShimmerStrip />;
    }
    if (Utility.isBlank(recommendedVariants) || recommendedVariants.length === 0 || variantType === PRODUCT.ATTRIBUTE_TYPE.INTEGER ) {
      return null;
    }
    const textToShow = recommendedVariants.length === 1 ? 'shade recommended for you' : 'shades recommended for you';
  
    return (
      <LinearGradient
        colors={gradientArray}
        style={styles.cardContainer}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 1 }}
      >
        <TouchableOpacity
          style={styles.recommendationBannerCard}
          onPress={this.recommendationBannerPressed}
        >
          <this.userAvatar />
          <View style={styles.recommendationTextContainer}>
            <Text numberOfLines={MAX_LINES.two} style={styles.recommendedTextHeader}>
              {`${recommendedVariants.length} ${textToShow}`}
            </Text>
          </View>
          <Image source={images.chevronRight} style={styles.rightArrow} />
        </TouchableOpacity>
      </LinearGradient>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  imageUrl: state.UserAccountInfo.profile.image_url,
});

export default connect(
  mapStateToProps,
  null,
)(RecommendationBanner);
