import { takeEvery, call } from 'redux-saga/effects';
import { SEARCH_ARTIST } from '../actions/UploadPostActions';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { convertJsonFromResponse } from './GeneratorUtil';

const fetchSuggestions = (searchText) => {
  const url = `${URL.SEARCH_ARTIST}${searchText}`;
  const response = fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response;
};

function* getArtistsSuggestions(action) {
  try {
    const response = yield call(fetchSuggestions, action.searchString);
    console.log('Get Artist Suggestion Saga called');
    const json = yield convertJsonFromResponse(response);
    if (response.status === 200) {
      if (Utility.isBlank(json)) {
        return;
      }
      console.log('Response of artists Suggestions', json);
      action.callback(json);
    } else if (Utility.isPresent(json)) {
      console.log(`Error Message: ${JSON.stringify(json)}`);
    }
  } catch (error) {
    console.log(`Error in getting response ${error}`);
  }
}

export default function* watchGetArtistsSuggestionSaga() {
  yield takeEvery(SEARCH_ARTIST, getArtistsSuggestions);
}
