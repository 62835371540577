import React, { PureComponent } from 'react';
import { View } from 'react-native';
import Banner from '../banner';
import { LAYOUT, ORIENTATION } from '../../config/Constants';
import List from '../List/List';
import Utility from '../../utils/Utility';
import { Header } from '../../components/header';

export default class Offer extends PureComponent {
  LayoutStyle = {
    list: List,
  };

  constructor() {
    super();
    this.state = {
      removeMasterOffer: false,
    };
  }

  _banner = () => {
    const {
      itemData,
      index,
      previousScreen,
      layout,
      itemData: { list = {} },
      invokedFromOfferBannerWithRail,
      listData,
      listIndex,
      extraEventParameters = {},
      listName,
    } = this.props;
    // const item = {
    //   type: 'banner',
    //   id: itemData.id,
    //   image_url: itemData.big_banner,
    //   slug: itemData.slug || '',
    //   destination: list.slug || '',
    // };

    const margins = invokedFromOfferBannerWithRail
      ? {
          marginLeft: Utility.padding,
          marginRight: Utility.padding,
          // marginTop: Utility.padding,
          marginVertical: 8,
        }
      : { marginVertical: 8 };

    return (
      <View
        style={{
          ...margins,
          width: Utility.getScreenWidth() - 2 * Utility.padding,
          height: (1 / 3) * (Utility.getScreenWidth() - 24),
        }}
      >
        <Banner
          key={itemData.id}
          id={itemData.id}
          layout={LAYOUT.PAGER}
          itemData={itemData}
          type={itemData.type}
          // navigation={navigation}
          listId={list.id || ''}
          listData={list}
          index={index}
          listIndex={listIndex}
          listContent={list.content}
          // allVideosId={allVideosId}
          previousScreen={previousScreen}
          navigateToOfferDetail={Utility.isPresent(list)}
          extraEventParameters={extraEventParameters}
          listName={listName}
        />
      </View>
    );
  };

  onTimerFinish = () => {
    this.setState({
      removeMasterOffer: true,
    });
  };

  _bannerWithTimer = () => {
    const {
      itemData,
      index,
      previousScreen,
      layout,
      itemData: { list = {} },
      invokedFromOfferBannerWithRail,
      listData,
      listIndex,
      offerBannerWithTimer = false,
      listName,
    } = this.props;

    // const item = {
    //   type: 'banner',
    //   id: itemData.id,
    //   image_url: itemData.big_banner,
    //   slug: list.slug || '',
    //   destination: list.slug || '',
    // };

    const margins = invokedFromOfferBannerWithRail
      ? {
          marginLeft: Utility.padding,
          marginRight: Utility.padding,
          marginBottom: 12,
          marginTop: 8,
        }
      : {};

    let additionalHeightOfBanner = 0;

    if (Utility.calculateTimeDifferenceForOffer(itemData.ends_at) <= 86400) {
      additionalHeightOfBanner = 42;
    }

    return (
      <>
        <View
          style={{
            ...margins,
            width: Utility.getScreenWidth() - 2 * Utility.padding,
            height:
              (1 / 3) * (Utility.getScreenWidth() - 24) +
              additionalHeightOfBanner,
          }}
        >
          <Banner
            key={itemData.id}
            id={itemData.id}
            layout={LAYOUT.PAGER}
            itemData={itemData}
            type={itemData.type}
            // navigation={navigation}
            listId={list.id || ''}
            listData={list}
            index={index}
            listIndex={listIndex}
            listContent={list.content}
            // allVideosId={allVideosId}
            previousScreen={previousScreen}
            navigateToOfferDetail={Utility.isPresent(list)}
            endsAt={itemData.ends_at}
            onTimerFinish={this.onTimerFinish}
            offerBannerWithTimer={offerBannerWithTimer}
          />
        </View>
      </>
    );
  };

  _header = () => {
    const {
      itemData,
      previousScreen,
      layout,
      listIndex,
      offerBannerWithTimer = false,
      listName,
    } = this.props;
    if (Utility.isBlank(itemData)) {
      return null;
    }

    const { list, slug, id } = itemData;

    if (Utility.isBlank(list) || layout !== LAYOUT.TIMER_BANNER_WITH_RAIL) {
      return null;
    }

    console.log(layout, 'LAYOUTLAYOUTLAYOUT');

    return (
      <Header
        item={list}
        title={offerBannerWithTimer ? listName : ''}
        layout={layout}
        previousScreen={previousScreen}
        navigateToOfferDetail={true}
        index={listIndex}
        slug={slug}
        id={id}
      />
    );
  };

  _list = () => {
    const {
      itemData,
      previousScreen,
      index,
      media_count,
      layout,
      showToast,
      toggleCartVisibility,
      listIndex,
      listContent,
      extraEventParameters = {},
    } = this.props;

    if (Utility.isBlank(itemData)) {
      return null;
    }

    const { list } = itemData;

    if (Utility.isBlank(list)) {
      return null;
    }

    let displayCount = 5;
    if (list.display === 'list') {
      displayCount = 5;
    } else if (list.display === 'vertical-rail') {
      displayCount = 10;
    } else if (list.display === 'grid') {
      displayCount = 4;
    }
    const ContainerComponent = this.LayoutStyle[list.type];
    return (
      <>
        <ContainerComponent
          //  navigation={navigation}
          itemData={list}
          id={list.id}
          type={list.type}
          size={list.size}
          displayCount={displayCount}
          orientation={ORIENTATION.VERTICAL}
          layout={list.display}
          display={list.display}
          index={listIndex}
          toggleCartVisibility={toggleCartVisibility}
          //   feed={feed}
          //   iconName={iconName}
          //   showCustomIcon={showCustomIcon}
          //   showColorComponent={showColorComponent}
          //   addedProducts={this.props.addedProducts}
          //   onPress={onPress}
          //   preventReduxFetch={preventReduxFetch}
          //   toggleCartVisibility={toggleCartVisibility}
          previousScreen={previousScreen}
          //   allVideosId={allVideosId}
          //   search={search}
          //   searchQuery={searchQuery}
          elementItemCounts={list.media_count}
          //   updateCurrentlyPlayingIndex={updateCurrentlyPlayingIndex}
          //   currentlyPlayingIndex={currentlyPlayingIndex}
          //   shuffle={shuffle}
          listName={list.name}
          listContent={list.content}
          showToast={showToast}
          extraEventParameters={extraEventParameters}
        />
      </>
    );
  };

  render() {
    const { layout, offerBannerWithTimer = false } = this.props;
    if (offerBannerWithTimer && this.state.removeMasterOffer) {
      return null;
    }

    return (
      <View>
        {offerBannerWithTimer ? <this._bannerWithTimer /> : <this._banner />}
        <this._list />
      </View>
    );
  }
}
