import React, { Component } from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Dimensions,
  Image,
  Platform,
  NativeModules,
  NativeEventEmitter,
  Linking,
  Alert,
  StatusBar,
  AppState,
  ImageBackground,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uuidv1 from 'uuid/v1';
import Video from '../../libraries/ReactNativeVideo';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import smartLock from 'react-native-smartlock';
import {
  SCREEN,
  PERMISSION_STORE,
  ANDROID,
  CURRENT_GUEST_STATE,
  LOGIN_MODAL_STATE,
  TERM_OF_USAGE_URL,
  PRIVACY_POLICY_URL,
  AnalyticsScreenNames,
  NAVIGATION_BYPASS,
} from '../../config/Constants';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import styles from './Styles/SelfiePromptStyles';
import {
  addMobileNumber,
  insertMobileNumber,
  saveUserState,
  guestFirstAppOpenTime,
  saveGuestAuthToken,
  addProfileDetails,
} from '../../actions/LoginActions';
import ModalProgressView from './ModalProgressView';
import {
  calculateUACAppDeviceScore,
  updateUserData,
} from '../../actions/ActionTypes';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  AnalyticsEvent,
  EventParameterValue,
} from '../../analytics';
import Orientation from 'react-native-orientation';
import { getMasterAttributesList } from '../../actions/FacialAnalysisActions';
import StartLoginButton from '../../lib/StartLoginButton';
import size from '../../theme/Fonts';
import NotificationManager from '../../utils/NotificationsManager';
import BGNetworkTaskManager from '../../utils/BGNetworkTaskManager';
import NavigationService from '../../navigator/NavigationService';
import withNavigation from '../../utils/WithNavigation';

//Rename this file to AppVideo because it contain inital screen with video
class SelfiePrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgressModal: false,
      isNewUser: true,
      emailPromptAlreadyShowing: false,
    };

    this.deviceInfo = {
      deviceModel: null,
      deviceBrand: null,
      deviceName: null,
      deviceDisplayID: null,
      deviceHardware: null,
      deviceChangeListNumber: null,
      deviceManufacturer: null,
      deviceOverallProductName: null,
      deviceUser: null,
      deviceApiLevel: null,
      deviceImei: null,
      deviceImsi: null,
      connectionType: null,
      effectiveConnectionType: null,
      email: null,
      generalDeviceInfo: {},
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height,
    };

    this.isPermissionDenied = false;
    this.isCurrentScreenActive = true;
    this.isVideoReady = true;
    this.pageLoadTime = new Date();
    this.getStartedClick = false;
    this.lastVideoProgressTime = undefined;
    this.app_detected = false;
    Dimensions.addEventListener('change', (e) => {
      this.setState(e.window);
    });
    if (Utility.isAndroid()) {
      this.appDetectEventListener = new NativeEventEmitter(
        NativeModules.AppDetect,
      );
    }
    if (Utility.isAndroid()) {
      this.uploadManager = new NativeEventEmitter(NativeModules.UploadManager);
    } else {
      this.uploadManager = new NativeEventEmitter(
        NativeModules.LocalNotificationHandler,
      );
    }

    Utility.setStatusbarTranslucent();
    this.screenAfterMobileNumberInput =
      NAVIGATION_BYPASS.screenAfterPhoneNumberInput;
  }

  componentDidMount() {
    const {
      getMasterAttributesList,
      calculateUACAppDeviceScore,
      updateUserData,
    } = this.props;
    // FIXME: Add this for android too
    if (Utility.isIOS()) {
      // Orientation.lockToPortrait();
    }

    //FIXME: Native code for this function should wrap around asynctask, Right now its impacting performance
    getMasterAttributesList();
    this.currentAppState = AppState.currentState;
    AppState.addEventListener('change', this._handleAppStateChange);
    this.renderOnboardingFlow('automatic', true);
    // if (Utility.isAndroid()) {
    //   // this.subscribeAppDetectEventListener();
    //   calculateUACAppDeviceScore();
    // }
    // updateUserData();
  }

  componentDidUpdate() {
    const {
      registered,
      authenticated,
      navigation,
      new_user,
      requestedFromHintPrompt,
      loginModalState,
      saveUserState,
      has_selfie,
    } = this.props;

    // if (Utility.isAndroid() && Platform.Version < ANDROID.BUILD.VERSIONS.NOUGAT) {
    if (this.isCurrentScreenActive) {
      if (loginModalState === LOGIN_MODAL_STATE.USER_SKIPPED) {
        this.skip();
      }
      if (requestedFromHintPrompt && registered && new_user !== '') {
        if (new_user) {
          navigation.navigate(this.screenAfterMobileNumberInput);
          this.isCurrentScreenActive = false;
        } else if (!new_user && !has_selfie) {
          navigation.navigate(this.screenAfterMobileNumberInput);
          this.isCurrentScreenActive = false;
        } else {
          Utility.tabNavigatorReplaced = true;
          navigation.replace('TabNavigator');
          saveUserState(CURRENT_GUEST_STATE.FULLY_REGISTERED); // TODO:Remove this code once skip functionality is enabled
          this.isCurrentScreenActive = false;
        }
      } else {
        this.goToFeed(registered, authenticated, new_user, has_selfie);
      }
    }
    // } else if (Utility.isIOS()) {
    //   this.goToFeed(registered, authenticated);
    // }
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', () => {});
    AppState.removeEventListener('change', this._handleAppStateChange);
  }

  lockScreenToPortrait = (payload) => {
    // Orientation.lockToPortrait();
    this.setState({ state: this.state });
  };

  _handleAppStateChange = (nextAppState) => {
    const { authToken, navigation } = this.props;
    if (!Utility.isBlank(authToken)) {
      if (nextAppState === 'active') {
        NotificationManager.cancelSelfieSkipNotification();
      }
    }
    this.currentAppState = AppState.currentState;
  };

  navigateToScreen = (route, slug) => {
    const { navigation } = this.props;
    navigation.navigate(route, {
      slug,
    });
  };

  goToFeed = (registered, authenticated, isNewUser, has_selfie) => {
    const { saveUserState, lead, navigation } = this.props;
    if (registered && authenticated) {
      if (isNewUser || !has_selfie) {
        this.props.navigation.navigate(this.screenAfterMobileNumberInput);
        this.isCurrentScreenActive = false;
      } else {
        saveUserState(CURRENT_GUEST_STATE.FULLY_REGISTERED); // TODO:Remove this code once skip functionality is enabled
        this.props.navigation.replace('TabNavigator');
        this.isCurrentScreenActive = false;
      }
    }
  };

  toggleProgressDialog = (showDialog) => {
    this.setState({
      showProgressModal: showDialog,
    });
  };

  showEmailHintPrompt = () => {
    const { emailPromptAlreadyShowing } = this.state;
    const { navigation, addProfileDetails } = this.props;
    if (
      Utility.isAndroid() &&
      Platform.Version >= ANDROID.BUILD.VERSIONS.NOUGAT &&
      !emailPromptAlreadyShowing
    ) {
      AnalyticsManager.logEvent(EventType.onboardingEvent.HINT_REQUEST_VIEW, {
        [EventParameterKey.TYPE]: EventParameterValue.HINT_PROMPT_TYPES.EMAIL,
      });

      try {
        smartLock
          .getCredentials()
          .then((credentials) => {
            console.log('cre', credentials);
            const userinfo = {
              name: credentials.name,
              email: credentials.id,
            };
            addProfileDetails(userinfo);
            navigation.navigate(this.screenAfterMobileNumberInput);
            this.isCurrentScreenActive = false;
          })
          .catch((err) => {
            navigation.navigate(this.screenAfterMobileNumberInput);
            this.isCurrentScreenActive = false;
          });
        this.setState({ emailPromptAlreadyShowing: true });
      } catch (error) {
        console.log('User Canceled');
      }
    }
  };

  titleContainer = () => (
    <View style={styles.titleContainer}>
      <Text style={[styles.title, { fontSize: 20 }]}>Take a Selfie</Text>
      <Text style={[styles.title, { fontSize: 14 }]}>
        A selfie will help us personalize content and products for you.
      </Text>
    </View>
  );

  skip = () => {
    const {
      navigation,
      saveUserState,
      guestFirstAppOpenTime,
      authToken,
      guestProfile,
      saveGuestAuthToken,
    } = this.props;
    if (!authToken || Utility.isBlank(authToken)) {
      saveUserState(CURRENT_GUEST_STATE.SKIPPED);
      guestFirstAppOpenTime(new Date());
    }
    if (!guestProfile.guestAuthToken) {
      const guestToken = uuidv1();
      saveGuestAuthToken(guestToken);
      BGNetworkTaskManager.saveGuestAuthToken(guestToken);
    }
    navigation.navigate(this.screenAfterMobileNumberInput, {});
    this.isCurrentScreenActive = false;
  };

  skipButton = () => (
    <TouchableOpacity
      style={styles.skipButtonContainer}
      onPress={this.skip}
      hitSlop={Utility.getHitSlop()}
    >
      <View>
        <Text style={styles.skipText}>Skip</Text>
      </View>
    </TouchableOpacity>
  );

  continueButton = () => {
    const { height, width } = this.state;
    return (
      <StartLoginButton
        title="Continue"
        onPress={() => this.renderOnboardingFlow('button_click')}
        backgroundColor={colors.green}
        underlayColor={colors.green}
        screenHeight={height}
        screenWidth={width}
        appFirstLoadTime={Utility.appFirstLoaded}
      />
    );
  };

  onPressContinue = () => {
    Utility.checkPermission(
      PERMISSION_STORE.camera,
      true,
      (isAllowed) => {
        if (isAllowed) {
          const { navigation } = this.props;

          navigation.replace(SCREEN.TAKE_SELFIE, {
            previousScreen: SCREEN_CONSTANTS.SELFIE_PROMPT,
          });
          this.isCurrentScreenActive = false;
        } else if (Utility.isAndroid()) {
          if (this.isPermissionDenied) {
            this.skip();
          }
          this.isPermissionDenied = true;
        } else {
          if (this.isPermissionDenied) {
            this.openAlert(
              "We don't have permission to access your camera, Please go to settings and enable camera to take selfie",
            );
          }
          this.isPermissionDenied = true;
        }
      },
      Utility.createPermissionMetaObject(
        AnalyticsScreenNames.SELFIE_PROMPT,
        'camera',
      ),
    );
  };

  openAlert = (message) => {
    Alert.alert('Permission Required', message, [
      {
        text: 'Settings',
        onPress: () => {
          Linking.openURL('app-settings:');
        },
      },
      {
        text: 'Cancel',
        onPress: () => {
          this.skip();
        },
        style: 'cancel',
      },
    ]);
  };

  renderOnboardingFlow = (source, automaticInvoke) => {
    const { navigation } = this.props;
    if (automaticInvoke && !Utility.isOpenedFromOnboardingDeeplink) {
      NavigationService.renderOnboarding();
      return;
    }
    this.getStartedClick = true;
    if (!Utility.isOpenedFromOnboardingDeeplink) {
      navigation.navigate(this.screenAfterMobileNumberInput);
      this.isCurrentScreenActive = false;
    }

    this.trackEvent(EventType.onboardingEvent.ONBOARDING_START, {
      [EventParameterKey.INPUT_METHOD]: source,
      [EventParameterKey.TIME_TAKEN]: Utility.getTimeDiff(
        new Date(),
        this.pageLoadTime,
      ),
    });
  };

  openWebUrl = (url, title) => {
    const { navigation } = this.props;

    navigation.navigate('WebUrlView', {
      destination: url,
      title,
      showBottomButton: true,
      fromOnboarding: true,
    });
  };

  overlay = () => {
    return (
      <TouchableWithoutFeedback
        onPress={() => this.renderOnboardingFlow('outside_screen_click')}
      >
        <LinearGradient
          colors={[
            'transparent',
            'transparent',
            'rgba(0,0,0,0)',
            'rgba(0, 0, 0, 0)',
          ]}
          style={[
            styles.linearGradientOverlay,
            { width: Utility.getScreenWidth() },
          ]}
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 0 }}
        >
          <>
            <View style={styles.foxyLogoContainer}>
              <Image
                source={images.foxy_main_logo}
                style={{
                  height: 170,
                  width: 150,
                  resizeMode: 'contain',
                }}
              />
              <Text
                style={{
                  color: colors.white,
                  fontSize: size.h1,

                  fontFamily: 'Roboto-Medium',
                  position: 'absolute',
                  bottom: 30,
                }}
              >
                Personalized Beauty & Grooming
              </Text>
            </View>

            <View
              style={[
                styles.bottomButtonContainer,
                { width: Utility.getScreenWidth() },
              ]}
            >
              <this.continueButton {...this.props} />
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={styles.manualButtonText}>Review our </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.trackEvent(
                      EventType.onboardingEvent.ONBOARDING_LINK_CLICK,
                      {
                        [EventParameterKey.LINK_NAME]: 'Privacy Policy',
                      },
                    );
                    this.openWebUrl(PRIVACY_POLICY_URL, 'Privacy Policy');
                  }}
                >
                  <Text
                    style={[
                      styles.manualButtonText,
                      { textDecorationLine: 'underline', marginLeft: 2 },
                    ]}
                  >
                    Privacy Policy
                  </Text>
                </TouchableOpacity>
                <Text style={[styles.manualButtonText, { marginLeft: 2 }]}>
                  &
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.trackEvent(
                      EventType.onboardingEvent.ONBOARDING_LINK_CLICK,
                      {
                        [EventParameterKey.LINK_NAME]: 'Terms of Use',
                      },
                    );
                    this.openWebUrl(TERM_OF_USAGE_URL, 'Terms of Use');
                  }}
                >
                  <Text
                    style={[
                      styles.manualButtonText,
                      { textDecorationLine: 'underline', marginLeft: 2 },
                    ]}
                  >
                    Terms of Use
                  </Text>
                </TouchableOpacity>
              </View>
              {/* <TouchableOpacity style={styles.manualButton} onPress={this.openTermsAndConditions}>
              <Text style={styles.manualButtonText}>
                By continuing, you agree to our
                {' '}
                <Text style={{ textDecorationLine: 'underline', fontSize: size.h4 }}>
                  Privacy Policy & Terms of use
                </Text>
              </Text>
            </TouchableOpacity> */}

              {/* ))} */}
            </View>
          </>
        </LinearGradient>
      </TouchableWithoutFeedback>
    );
  };
  progressDialog = (props) => <ModalProgressView isVisible={props.isVisible} />;

  trackEvent = (eventType, meta = {}) => {
    AnalyticsManager.logEvent(eventType, meta);
  };

  handleProgress = (progress) => {
    let { currentTime } = progress;
    const milestones = [0, 2, 5, 10, 20, 40];
    currentTime = Math.floor(currentTime);
    if (currentTime === this.lastVideoProgressTime) {
      return;
    }
    if (milestones.includes(currentTime)) {
      this.lastVideoProgressTime = currentTime;
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.ONBOARDING_VIDEO_PLAY,
        {
          [EventParameterKey.MILESTONE]: currentTime,
        },
      );
    }
  };

  render() {
    const { showProgressModal } = this.state;
    return (
      <>
        {/* <NavigationEvents
          onDidFocus={(payload) => {
            this.lockScreenToPortrait(payload);
          }}
        /> */}
        <StatusBar backgroundColor="transparent" barStyle="light-content" />
        <View style={styles.modal}>
          <ImageBackground
            style={{ flex: 1 }}
            source={images.video_first_frame}
          />
          {/* <Video
            repeat
            onLoad={() => {
              this.trackEvent(EventType.onboardingEvent.ONBOARDING_VIDEO_LOAD, {
                [EventParameterKey.APP_STATE]: this.currentAppState,
                [EventParameterKey.LOAD_TIME]: Utility.getTimeDiff(
                  new Date(),
                  this.pageLoadTime,
                ),
              });
            }}
            muted
            source={onboardingVideo}
            resizeMode='cover'
            onProgress={this.getStartedClick ? null : this.handleProgress}
            onError={() =>
              this.trackEvent(
                EventType.onboardingEvent.ONBOARDING_VIDEO_LOAD_ERROR,
                {
                  [EventParameterKey.LOAD_TIME]: Utility.getTimeDiff(
                    new Date(),
                    this.pageLoadTime,
                  ),
                },
              )
            }
            style={{ flex: 1, borderRadius: 4 }}
          /> */}
          <this.overlay />
          <this.progressDialog isVisible={showProgressModal} />
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mobileNumber: state.UserAccountInfo.mobileNumber,
    registered: state.UserAccountInfo.registered,
    authenticated: state.UserAccountInfo.authenticated,
    authToken: state.UserAccountInfo.authToken,
    new_user: state.UserAccountInfo.new_user,
    requestedFromHintPrompt: state.UserAccountInfo.requestedFromHintPrompt,
    loginModalState: state.UserAccountInfo.loginModalState,
    guestProfile: state.UserAccountInfo.guestProfile,
    has_selfie: state.UserAccountInfo.has_selfie,
    lead: state.UserAccountInfo.lead,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addMobileNumber,
      insertMobileNumber,
      saveGuestAuthToken,
      saveUserState,
      guestFirstAppOpenTime,
      addProfileDetails,
      calculateUACAppDeviceScore,
      getMasterAttributesList,
      updateUserData,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(SelfiePrompt),
);
