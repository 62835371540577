// Dependencies
import React, { Component } from 'react';
import {
  Animated,
  ImageBackground,
  View,
  Image,
  Text,
  findNodeHandle,
  TouchableOpacity,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Orientation from 'react-native-orientation';
import Share from '../../libraries/ReactNativeShare';
import Toast from 'react-native-easy-toast';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import CategoryStyles from './styles';
import { withMaybe } from '../../lib/Monads';
import { CustomNavigationHeader } from '../../components/Product';
import DockedHeader from '../../utils/DockedHeader';
import Images from '../../theme/Images';
import { ShareModal } from '../../components/Product';
import List from '../List/List';
import {
  ORIENTATION,
  LAYOUT,
  NOTIFICATION_REQUEST_MODAL_TYPE,
  TRACKING_PARAMS,
} from '../../config/Constants';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AnimatedLikeButton from '../../components/shared/AnimatedLikeButton';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FoxyPersonalisedItems from '../FoxyPersonalisedItems';
import _ from 'lodash';
import { setNotificationModalDisplayTime } from '../../actions/ActionTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NotificationModal from '../../utils/Notification/NotificationModal';
import { firebase } from '../../libraries/Firebase';
import { StaticNavigationHeader } from '../../components/shared';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { isDesktop, isNative, isWeb } from '../../utils/BooleanUtility';
import { goBackWithFallbackHome } from '../../utils/NavigationUtility';
import WebListTitle from '../List/WebListTitle';

class CategoryDetail extends Component {
  constructor(props) {
    super(props);
    const { route: { params: { toggleCartVisibility = () => {} } = {} } = {} } =
      props;
    this.toggleCartVisibility = toggleCartVisibility;
    this.viewedItemsInList = [];
    this.trace = firebase.perf().newTrace(SCREEN_CONSTANTS.CATEGORY);
    this.trace.start();
    this.backAction = this.backAction.bind(this);
  }

  state = {
    liked: false,
    scrollY: new Animated.Value(0),
    viewRef: null,
    isShareModalVisible: false,
    isRichDescriptionVisible: false,
    canDisplayNotificationRequestModal: false,
    showNotificationPrompt: false,
  };

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.onDidFocus();
    });
    if (isNative()) {
      // Orientation.lockToPortrait();
    }
    this.displayNotificationRequestModal();
  }

  componentDidUpdate(prevProps) {
    const { categoryData, categoryData: { objects = {} } = {} } = this.props;
    const { categoryData: { categoryObject = {} } = {} } = prevProps;
    if (
      Utility.isPresent(categoryData) &&
      Utility.isPresent(objects) &&
      Utility.isBlank(categoryObject)
    ) {
      this.trace.stop();
      AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
        [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.CATEGORY,
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  backAction = () => {
    const { navigation, resetStatusBar } = this.props;
    const { isRichDescriptionVisible } = this.state;
    if (resetStatusBar && !isRichDescriptionVisible) {
      resetStatusBar();
    } else if (isRichDescriptionVisible) {
      this.setState({ isRichDescriptionVisible: false });
      return true;
    }
    goBackWithFallbackHome(navigation);
    return true;
  };

  goBack = () => {
    const { navigation } = this.props;
    goBackWithFallbackHome(navigation);
  };

  toggleLike = () => {
    this.setState({
      liked: !this.state.liked,
    });
  };

  listConditionFn = (props) => props.content === 'list';

  customNavigationHeader = withMaybe(this.listConditionFn)(
    CustomNavigationHeader,
  );

  setRef = (img) => {
    this.backgroundImage = img;
  };

  renderDescription = () => {
    const styles = CategoryStyles;
    const { categoryData: { short_description: shortDescription = '' } = {} } =
      this.props;
    if (Utility.isBlank(shortDescription)) return null;
    return (
      <Text //Category Hero Description Text
        style={styles.categoryShortDescription}
      >
        {shortDescription}
      </Text>
    );
  };

  categoryBanner = (props) => {
    const styles = CategoryStyles;
    const { categoryData, isLoading } = this.props;
    const { name, short_description = '' } = categoryData;
    const { uri } = props;
    if (Utility.isBlank(uri) && Utility.isBlank(short_description)) {
      return null;
    }

    return (
      <View style={styles.categoryBannerContainer}>
        {Utility.isPresent(uri) && (
          <ImageBackground //Category logo
            source={{
              uri: Utility.getMinifiedImage(
                uri,
                styles.bannerImage.width,
                styles.bannerImage.height,
              ),
            }}
            ref={this.setRef}
            style={styles.bannerImage}
            resizeMode="contain"
          />
        )}
        <View //Category Text Container
          style={styles.categoryDescStyle}
        >
          <this.renderDescription />
          <View // Buttons Container
            style={styles.descButtonContainer}
          >
            {!isWeb() && <this.renderShareButton />}
            <this.renderLikeButton />
          </View>
        </View>
      </View>
    );
  };

  setRefForLikeButton = (ref) => {
    this.likeButtonRef = ref;
  };

  renderLikeButton = () => {
    const {
      categoryData: {
        id = '',
        type = '',
        slug = '',
        content = '',
        name = '',
      } = {},
    } = this.props;
    const styles = CategoryStyles;
    return (
      <View style={styles.likeButtonContainer}>
        <AnimatedLikeButton
          ref={this.setRefForLikeButton}
          id={id}
          type={type}
          slug={slug}
          layout={LAYOUT.SCREEN}
          content={content}
          onLike={this.onLike}
          itemName={name}
          screenName={SCREEN_CONSTANTS.CATEGORY}
        />
      </View>
    );
  };

  renderShareButton = () => {
    const styles = CategoryStyles;
    return (
      <View style={styles.shareButtonStyle}>
        <TouchableOpacity onPress={this.onSocialShareOptionTapped}>
          <Image source={Images.ic_share_product} style={styles.shareButton} />
        </TouchableOpacity>
      </View>
    );
  };

  showShareModal = () => {
    this.setState({
      isShareModalVisible: true,
    });
  };

  hideShareModal = () => {
    this.setState({
      isShareModalVisible: false,
    });
  };

  onSearchIconTap = () => {
    const { navigation } = this.props;
    navigation.push('Search', {});
  };

  renderList = (props) => {
    const {
      navigation,
      getQuickFiltersListRef,
      stickyPosition,
      selectedFilters,
      onFiltersPress,
      onPressResetButton,
      setListPaginatedCallApiRef,
      categoryData,
      filtersEnabled,
      filteredSlug,
      onRefresh,
      hideOosProduct,
    } = this.props;
    if (Utility.isBlank(categoryData?.list)) {
      return null;
    }

    const paginateLastItem = categoryData.list.paginate_last_item;
    const totalItems = categoryData.list?.objects.length - 1;
    return (
      <List
        itemData={categoryData.list}
        navigation={navigation}
        search
        getQuickFiltersListRef={getQuickFiltersListRef}
        stickyPosition={stickyPosition}
        selectedFilters={selectedFilters}
        onFiltersPress={onFiltersPress}
        onPressResetButton={onPressResetButton}
        setListPaginatedCallApiRef={setListPaginatedCallApiRef}
        verticalListHeader={this.categoryHeader}
        paginateLastItem={paginateLastItem}
        totalItemsInList={totalItems}
        filtersEnabled={filtersEnabled}
        filteredSlug={filteredSlug}
        onRefresh={onRefresh}
        previousScreen={SCREEN_CONSTANTS.CATEGORY}
        categoryIds={categoryData.descendants}
        extraEventParameters={{
          [EventParameterKey.ENTITY_NAME]: categoryData?.name,
        }}
        hideOosProduct={hideOosProduct}
      />
    );
  };

  placeholderCondition = (props) =>
    Utility.isBlank(props.itemData.objects) && props.isLoading;

  onDidFocus = () => {
    const { categoryData, previousScreen } = this.props;
    const { id, name } = categoryData;
    AnalyticsManager.logEvent(EventType.discoveryEvents.CATEGORY_VIEW, {
      [EventParameterKey.CATEGORY_ID]: id,
      [EventParameterKey.CATEGORY_NAME]: name,
    });
    AnalyticsManager.setCurrentScreen(previousScreen);
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  hideRichDescriptionVisibility = () => {
    this.setState({ isRichDescriptionVisible: false });
  };

  onSocialShareOptionTapped = (medium) => {
    const { categoryData } = this.props;
    const {
      list,
      share_url: shareUrl,
      name: categoryName,
      id: categoryId = '',
    } = categoryData;
    const { name, image_url: imageUrl } = list;

    const sharedUrl = shareUrl; // to change url when triggered from offer detail page
    const title = name;
    const message = `Check out top product and offers of ${categoryName} on ${Config.APP_NAME}, the best platform for all your beauty needs`;

    const linkOptions = {
      title,
      imageUrl: Utility.getMinifiedImage(imageUrl, 200, 200),
      description: '',
      trackingParams: TRACKING_PARAMS.SHARE.CATEGORY,
    };
    const getDynamicLink = DynamicLinkManager.getShortenUrl(
      sharedUrl,
      linkOptions,
    );
    AnalyticsManager.logEvent(EventType.miscAppEvents.ITEM_SHARE, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.CATEGORY_DETAILS,
      [EventParameterKey.ITEM_ID]: categoryId,
      [EventParameterKey.ENTITY_NAME]: categoryName,
      [EventParameterKey.ITEM_TYPE]: 'category',
    });
    getDynamicLink
      .then((link) => {
        const options = {
          title: Config.APP_NAME,
          message,
          url: link,
        };
        Share.open(options)
          .then((res) => {})
          .catch((err) => {});
      })
      .catch((error) => {
        console.log('Error ', error);
      });
  };

  categoryHeader = () => {
    const { categoryData, isLoading } = this.props;
    const {
      name = '',
      slug: categorySlug,
      show_personalised_details: showPersonalisedDetails,
    } = categoryData;

    return (
      <>
        <this.categoryBanner
          uri={categoryData.banner_image_url || categoryData.image_url}
        />
        {showPersonalisedDetails && (
          <FoxyPersonalisedItems
            slug={categorySlug}
            subheading={`${name} products which you shouldn’t miss`}
            previousScreen={SCREEN_CONSTANTS.CATEGORY}
            extraEventParameters={{
              [EventParameterKey.ENTITY_NAME]: categoryData?.name,
            }}
          />
        )}
      </>
    );
  };

  setNavigationRef = (ref) => {
    this.navigationRefs = ref;
  };

  setToastRef = (ref) => {
    this.toast = ref;
  };

  displayNotificationRequestModal = () => {
    const {
      initial_app_opened_at,
      lastNotificationModalDisplayTime,
      setNotificationModalDisplayTime,
    } = this.props;
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      SCREEN_CONSTANTS.CATEGORY,
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          const currentDate = new Date();
          setNotificationModalDisplayTime(currentDate.getTime());
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  showNotificationModal = (show, showNotification) => {
    this.setState({
      canDisplayNotificationRequestModal: show,
      showNotificationPrompt: showNotification,
    });
  };

  render() {
    const { categoryData, isLoading, resetStatusBar, serverError } = this.props;
    const styles = CategoryStyles;
    const {
      scrollY,
      isShareModalVisible,
      isRichDescriptionVisible,
      canDisplayNotificationRequestModal,
      showNotificationPrompt,
      isPageLoading,
    } = this.state;

    const {
      list,
      share_url: shareUrl,
      short_description = '',
      banner_image_url = '',
      image_url = '',
    } = categoryData;
    if (Utility.isBlank(list)) {
      return (
        <View style={styles.errorStateContainer}>
          <StaticNavigationHeader name={''} />
          <ErrorBoundary
            showServerError={serverError && !isLoading}
            pageNotFound={!isPageLoading}
            hideHeader
          />
        </View>
      );
    }
    const { name = '' } = categoryData;
    const { slug, type, id, image_url: imageUrl } = list;
    const showSearchButton = true;
    const uri = banner_image_url || image_url;
    const showShareButton =
      Utility.isBlank(uri) && Utility.isBlank(short_description);
    return (
      <>
        {!isDesktop() && (
          <CustomNavigationHeader
            ref={this.setNavigationRef}
            scrollY={scrollY}
            slug={slug}
            goBack={this.goBack}
            content={type}
            type={type}
            id={id}
            screenName={SCREEN_CONSTANTS.CATEGORY}
            shareUrl={shareUrl}
            name={name}
            image={imageUrl}
            showCartIcon
            showSearchButton={showSearchButton}
            onSearchIconTap={this.onSearchIconTap}
            onPressCart={this.toggleCartVisibility}
            hideRichDescriptionVisibility={this.hideRichDescriptionVisibility}
            isRichDescriptionVisible={isRichDescriptionVisible}
            resetStatusBar={resetStatusBar}
            showShareButton={showShareButton}
            utmCampaign={'category'}
          >
            <DockedHeader
              name={isRichDescriptionVisible ? `About ${name}` : name}
            />
          </CustomNavigationHeader>
        )}
        {isWeb() && <WebListTitle title={isRichDescriptionVisible ? `About ${name}` : name} />}
        <this.renderList />

        <Toast style={styles.toastStyle} ref={this.setToastRef} />
        {isShareModalVisible && (
          <ShareModal
            isShareModalVisible={isShareModalVisible}
            hideShareModal={this.hideShareModal}
            onSocialShareOptionTapped={this.onSocialShareOptionTapped}
            image={imageUrl}
            isProduct="false"
            name={name}
          />
        )}
        <NotificationModal
          isNotificationModalVisible={canDisplayNotificationRequestModal}
          showNotificationModal={this.showNotificationModal}
          showNotificationPrompt={showNotificationPrompt}
          type={NOTIFICATION_REQUEST_MODAL_TYPE.BRAND_PAGE}
          showAnimation
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lastNotificationModalDisplayTime:
    state.UserAccountInfo.lastNotificationModalDisplayTime,
  initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setNotificationModalDisplayTime,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CategoryDetail),
);
