import React, { Component } from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  StatusBar,
  Image,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import Toast from 'react-native-easy-toast';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StackActions } from '@react-navigation/native';
import Interest from './Interest';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import styles from './styles';
import { CURRENT_GUEST_STATE } from '../../../config/Constants';
import { StaticNavigationHeader } from '../../shared';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import {
  toggleInterest,
  postSelectedInterest,
  saveUserState,
  fetchInterests,
  guestFirstAppOpenTime,
} from '../../../actions/LoginActions';
import images from '../../../theme/Images';
import colors from '../../../theme/Colors';
import Utility from '../../../containers/retailStore/utils/Utility';

const { width, height } = Dimensions.get('window');

class SelectYourInterest extends Component {
  constructor(props) {
    super(props);
    const {
      navigation,
      route,
      selectedInterests,
      toggleInterest,
      interestList,
      postSelectedInterest,
    } = this.props;
    this.styles = {
      height: {
        titleContainer: 77.0,
        continueButtonContainer: 77.0,
      },
    };
    this.state = {
      bottomShadow: false,
      topShadow: false,
      interests: [],
    };

    this.previousScreen = route.params?.previousScreen;

    this.toggleInterest = toggleInterest;
    this.selectedInterests = selectedInterests;
    this.postSelectedInterests = postSelectedInterest;
  }

  componentDidMount() {
    const { interestList, fetchInterests } = this.props;

    if (interestList.length < 1) {
      fetchInterests((response) => {
        this.setState({
          interests: response,
        });
      });
    } else {
      this.setState({
        interests: interestList,
      });
    }
  }

  close = () => {
    const { navigation, route, selectedInterests } = this.props;
    if (selectedInterests.length > 0) {
      this.postSelectedInterests();
      if (this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE) {
        route.params?.postSelectedInterests();
        navigation.goBack();
      } else {
        this.navigateToFeed();
      }
    } else {
      this.toast.show('No Interest selected');
    }
  };

  onContentSizeChange = (modalHeight) => (contentWidth, contentHeight) => {
    const { titleContainer, continueButtonContainer } = this.styles.height;
    const availableScrollViewHeight =
      modalHeight - (titleContainer + continueButtonContainer);
    if (contentHeight < availableScrollViewHeight) {
      this.setState({ bottomShadow: false });
    } else {
      this.setState({ bottomShadow: true });
    }
  };

  onScroll = () => this.setState({ topShadow: true, bottomShadow: true });

  skip = () => {
    const { navigation, saveUserState, guestFirstAppOpenTime } = this.props;
    saveUserState(CURRENT_GUEST_STATE.SKIPPED);
    guestFirstAppOpenTime(new Date());
    this.navigateToFeed();
  };

  navigateToFeed = () => {
    const { navigation } = this.props;
    navigation.dispatch(
      StackActions.replace('DetailStackNavigator', {
        screenName: 'TabNavigator',
      }),
    );
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  titleContainer = (props) => {
    const { topShadow } = this.state;
    const WrapperComponent = View;
    return (
      <WrapperComponent width={width} height={props.height}>
        <View style={[styles.titleContainer, { height: props.height }]}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableWithoutFeedback onPress={this.goBack}>
              <View
                style={{
                  height: 30,
                  width: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image
                  source={images.navigationWhiteBackIcon}
                  style={{ tintColor: colors.foxyBlack }}
                />
              </View>
            </TouchableWithoutFeedback>
            <View style={{ flexDirection: 'column', marginLeft: 8 }}>
              <Text style={styles.mainTitle}>Select your interests</Text>
              <Text style={styles.subTitle}>
                Content you would like to watch
              </Text>
            </View>
          </View>
          <TouchableOpacity onPress={this.skip}>
            <Text style={styles.skipTitle}>Skip</Text>
          </TouchableOpacity>
        </View>
      </WrapperComponent>
    );
  };

  interestsGrid = (props) => {
    const { interests } = props;
    console.log('INterests list ', interests);
    return (
      <View style={{ flex: 1, paddingHorizontal: 33.0 }}>
        <FlatList
          data={interests}
          showsVerticalScrollIndicator={false}
          onContentSizeChange={this.onContentSizeChange(props.modalHeight)}
          onScroll={this.onScroll}
          renderItem={({ item, index }) => (
            <Interest interest={item} {...props} />
          )}
          numColumns={3}
          keyExtractor={(item, index) => item.id}
        />
      </View>
    );
  };

  continueButton = (props) => {
    const { bottomShadow } = this.state;
    const WrapperComponent = View;
    return (
      <WrapperComponent height={props.height} width={width}>
        <View
          style={[styles.continueButtonContainer, { height: props.height }]}
        >
          <FoxyShadowButton
            title="Continue"
            onPress={this.close}
            backgroundColor={
              props.isDisabled ? colors.disabled : colors.enabled
            }
          />
        </View>
      </WrapperComponent>
    );
  };

  render() {
    const { interestList, selectedInterests } = this.props;
    const { titleContainer, continueButtonContainer } = this.styles.height;
    const modalHeight = Platform.select({
      android: height - StatusBar.currentHeight,
      ios: height - (Utility.topInset + Utility.bottomInset),
      web: height - (Utility.topInset + Utility.bottomInset),
    });
    const modalInsetWidth = width - 33.0 * 2;

    const { interests } = this.state;
    const additionalProps =
      this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE
        ? {
            previousScreen: this.previousScreen,
            toggleInterest: this.toggleInterest,
            selectedInterests: this.selectedInterests,
            interests,
          }
        : { interests };

    const isDisabled = !(selectedInterests.length > 0);
    const RootView =
      this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE
        ? View
        : SafeAreaView;
    return (
      <RootView style={[styles.modalContainer]}>
        <View style={[styles.modalInset, { flex: 1 }]}>
          {this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE && (
            <StaticNavigationHeader title="My Interests" />
          )}
          {this.previousScreen !== SCREEN_CONSTANTS.CONSUMER_PROFILE && (
            <this.titleContainer height={titleContainer} {...this.props} />
          )}
          <this.interestsGrid
            imageDimension={modalInsetWidth / 3.26}
            modalHeight={modalHeight}
            {...this.props}
            {...additionalProps}
          />
        </View>
        <this.continueButton
          height={continueButtonContainer}
          isDisabled={isDisabled}
        />
        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </RootView>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    selectedInterests: state.UserAccountInfo.selectedInterests,
    interestList: state.InterestsList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      toggleInterest,
      postSelectedInterest,
      saveUserState,
      fetchInterests,
      guestFirstAppOpenTime,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectYourInterest);
