import React, { useEffect, useRef, useState } from 'react';
import { View, Text, FlatList, TouchableOpacity, Image } from 'react-native';
import _, { parseInt } from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import Config from '../../libraries/ReactNativeConfig';
import { CURRENCY_SYMBOL, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { cartIds, CartStyles } from './styles';
import Utility from '../../utils/Utility';
import FullWidthDivider from '../../utils/FullWidthDivider';
import RemoteConfig from '../../utils/RemoteConfig';
import { useSelector } from 'react-redux';
import colors from '../../theme/Colors';
import { AnalyticsManager } from '../../analytics';
import AppConfig from '../../config/AppConfig';
import { getPriceText } from '../../utils/NumberUtility';
import { isDesktop } from '../../utils/BooleanUtility';

const hideCodAndDeliveryFee = AppConfig.getBooleanValue(Config.HIDE_COD_AND_DELIVERY_FEE);

const PricingDetailsCod = ({
  pricingData,
  navigation,
  isOfferShimmerVisible,
  showDeliveryFeeDiscountButton,
  onPressUnlockFreeDelivery,
}) => {
  const deliveryInfoText = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.delivery_info,
  );
  const codFeeInfoText = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.cod_fee_threshold,
  );
  let { delivery_fee: deliveryFee = '' } = pricingData;
  const [_showTip, setShowTip] = React.useState(false);
  const {
    items_total: itemTotal = '',
    discount = '',
    total = '',
    total_for_cod = '',
    cod_charges = '',
    plastic_free_packaging_charges: plasticFreePackagingCharge = '',
    original_prepaid_delivery_fee,
    coupon_codes = [],
    cod_availability: codAvailability = true,
  } = pricingData;

  const deliveryFeeCoupon =
    _.filter(
      coupon_codes,
      (e) => e?.reward_value_type == 'shipping_discount',
    )[0]?.coupon_code ?? 'FREESHIPPING';

  let deliveryFeeOrignal = undefined;
  let codCharges = undefined;

  if (parseInt(original_prepaid_delivery_fee) !== parseInt(deliveryFee)) {
    deliveryFeeOrignal = `${CURRENCY_SYMBOL} ${original_prepaid_delivery_fee}`;
  }

  if (deliveryFee === '0.0' || deliveryFee === 0.0 || deliveryFee === 0) {
    deliveryFee = 'FREE';
  } else {
    deliveryFee = `${CURRENCY_SYMBOL} ${deliveryFee}`;
  }
  if (cod_charges === '0.0' || cod_charges === 0.0 || cod_charges === 0) {
    codCharges = 'FREE';
  } else {
    codCharges = `${CURRENCY_SYMBOL} ${cod_charges}`;
  }
  const styles = CartStyles;
  return (
    <View style={styles.pricingDetailsContainer} dataSet={{ media: cartIds.pricingDetailsContainer }}>
      <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
        <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>Item Total</Text>
        {Utility.isBlank(itemTotal) || isOfferShimmerVisible ? (
          <ShimmerPlaceHolder
            autoRun
            style={styles.priceStyleAddWidth}
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          />
        ) : (
          <Text style={styles.price} dataSet={{ media: cartIds.price }}>{getPriceText(itemTotal)}</Text>
        )}
      </View>

      <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
        <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>Discount</Text>

        {Utility.isBlank(discount) || isOfferShimmerVisible ? (
          <ShimmerPlaceHolder
            autoRun
            style={styles.priceStyleAddWidth}
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          />
        ) : (
          <Text style={styles.price} dataSet={{ media: cartIds.price }}>{getPriceText(discount)}</Text>
        )}
      </View>

      {plasticFreePackagingCharge > 0 ? (
        <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
          <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>Packaging Charges</Text>
          {isOfferShimmerVisible ? (
            <ShimmerPlaceHolder
              autoRun
              style={styles.priceStyleAddWidth}
              colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            />
          ) : (
            <Text
              style={styles.price}
              dataSet={{ media: cartIds.price }}
            >{`${CURRENCY_SYMBOL} ${plasticFreePackagingCharge}`}</Text>
          )}
        </View>
      ) : null}

      {codAvailability && !hideCodAndDeliveryFee && (
        <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
          <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>COD Fee ({codFeeInfoText})</Text>
          {Utility.isBlank(itemTotal) || isOfferShimmerVisible ? (
            <ShimmerPlaceHolder
              autoRun
              style={styles.priceStyleAddWidth}
              colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            />
          ) : (
            <Text style={styles.price} dataSet={{ media: cartIds.price }}>{codCharges}</Text>
          )}
        </View>
      )}

      { !hideCodAndDeliveryFee && (
        <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
          <View
            style={styles.centerFlexContainer}
          >
            {Utility.isBlank(deliveryFeeOrignal) ? (
              <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>
                Delivery Fee ({deliveryInfoText})
              </Text>
            ) : (
              <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>Delivery Fee</Text>
            )}
            <View>
              {Utility.isPresent(deliveryFeeOrignal) && (
                <Text
                  style={styles.deliveryFeeOriginalText}
                  dataSet={{ media: styles.deliveryFeeOriginalText }}
                >
                  {`(${deliveryFeeCoupon} applied)`}
                </Text>
              )}
            </View>
          </View>
          {deliveryFee === `${CURRENCY_SYMBOL} ` || isOfferShimmerVisible ? (
            <ShimmerPlaceHolder
              autoRun
              style={[styles.price, { width: 54 }]}
              dataSet={{ media: cartIds.price }}
              colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            />
          ) : (
            <View
              style={styles.deliveryFeeOriginalView}
            >
              <Text style={styles.deliveryFee} dataSet={{ media: cartIds.deliveryFee }}>{deliveryFee}</Text>
              {Utility.isPresent(deliveryFeeOrignal) && (
                <Text style={styles.deliveryFeeOrignal} dataSet={{ media: cartIds.deliveryFeeOrignal }}>
                  {deliveryFeeOrignal}
                </Text>
              )}
            </View>
          )}
        </View>
      )}
      {showDeliveryFeeDiscountButton && !hideCodAndDeliveryFee && (
        <TouchableOpacity
          style={styles.unlockFreeDeliveryContainer}
          onPress={onPressUnlockFreeDelivery}
        >
          <Text style={styles.unlockFreeDeliveryText}>
            Unlock Free delivery on this order
          </Text>
          <View style={styles.claimButton}>
            <Text style={styles.claimButtonText}>Claim</Text>
          </View>
        </TouchableOpacity>
      )}

      <FullWidthDivider margin={16} />
      <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
        <Text style={styles.grandTotaltext}>Grand Total</Text>
        {Utility.isBlank(total) || isOfferShimmerVisible ? (
          <ShimmerPlaceHolder
            autoRun
            style={[styles.grandTotalPrice, { width: 54 }]}
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          />
        ) : (
          <Text
            style={styles.grandTotalPrice}
          >{getPriceText(total_for_cod)}</Text>
        )}
      </View>
    </View>
  );
};

const PricingDetails = ({
  pricingData,
  navigation,
  isOfferShimmerVisible,
  showDeliveryFeeDiscountButton,
  onPressUnlockFreeDelivery,
}) => {
  const deliveryInfoText = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.delivery_info,
  );
  let { delivery_fee: deliveryFee = '', original_prepaid_delivery_fee = '' } =
    pricingData;
  const [_showTip, setShowTip] = React.useState(false);
  const {
    items_total: itemTotal = '',
    discount = '',
    total = '',
    plastic_free_packaging_charges: plasticFreePackagingCharge = '',
    coupon_codes = [],
  } = pricingData;

  const deliveryFeeCoupon =
    _.filter(
      coupon_codes,
      (e) => e?.reward_value_type == 'shipping_discount',
    )[0]?.coupon_code ?? 'FREESHIPPING';

  let deliveryFeeOrignal = undefined;

  if (parseInt(original_prepaid_delivery_fee) !== parseInt(deliveryFee)) {
    deliveryFeeOrignal = `${CURRENCY_SYMBOL} ${original_prepaid_delivery_fee}`;
  }

  if (deliveryFee === '0.0' || deliveryFee === 0.0 || deliveryFee === 0) {
    deliveryFee = 'FREE';
  } else {
    deliveryFee = `${CURRENCY_SYMBOL} ${deliveryFee}`;
  }

  const styles = CartStyles;
  return (
    <View style={styles.pricingDetailsContainer} dataSet={{ media: cartIds.pricingDetailsContainer }}>
      <Text style={styles.priceHeader} dataSet={{ media: cartIds.priceHeader }}>Bill Details</Text>
      <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
        <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>Item Total</Text>
        {Utility.isBlank(itemTotal) || isOfferShimmerVisible ? (
          <ShimmerPlaceHolder
            autoRun
            style={[styles.price, { width: 54 }]}
            dataSet={{ media: cartIds.price }}
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          />
        ) : (
          <Text style={styles.price} dataSet={{ media: cartIds.price }}>{getPriceText(itemTotal)}</Text>
        )}
      </View>

      {parseFloat(discount) !== 0 && (
        <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
          <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>Discount</Text>

          {Utility.isBlank(discount) || isOfferShimmerVisible ? (
            <ShimmerPlaceHolder
              autoRun
              style={[styles.price, { width: 54 }]}
              dataSet={{ media: cartIds.price }}
              colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            />
          ) : (
            <Text style={styles.price} dataSet={{ media: cartIds.price }}>{getPriceText(discount)}</Text>
          )}
        </View>
      )}

      {plasticFreePackagingCharge > 0 ? (
        <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
          <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>Packaging Charges</Text>
          {isOfferShimmerVisible ? (
            <ShimmerPlaceHolder
              autoRun
              style={[styles.price, { width: 54 }]}
              dataSet={{ media: cartIds.price }}
              colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            />
          ) : (
            <Text
              style={styles.price}
              dataSet={{ media: cartIds.price }}
            >{`${CURRENCY_SYMBOL} ${plasticFreePackagingCharge}`}</Text>
          )}
        </View>
      ) : null}

      { !hideCodAndDeliveryFee && (
        <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 4,
            }}
          >
            {Utility.isBlank(deliveryFeeOrignal) ? (
              <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>
                Delivery Fee ({deliveryInfoText})
              </Text>
            ) : (
              <Text style={styles.itemTotaltext} dataSet={{ media: cartIds.itemTotaltext }}>Delivery Fee</Text>
            )}
            <View>
              {Utility.isPresent(deliveryFeeOrignal) && (
                <Text
                  style={styles.deliveryFeeOriginalText}
                  dataSet={{ media: cartIds.deliveryFeeOriginalText }}
                >
                  {`(${deliveryFeeCoupon} applied)`}
                </Text>
              )}
            </View>
          </View>

          {deliveryFee === `${CURRENCY_SYMBOL} ` || isOfferShimmerVisible ? (
            <ShimmerPlaceHolder
              autoRun
              style={[styles.price, { width: 54 }]}
              dataSet={{ media: cartIds.price }}
              colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            />
          ) : (
            <View
              style={styles.deliveryFeeOriginalView}
            >
              <Text style={styles.deliveryFee} dataSet={{ media: cartIds.deliveryFee }}>{deliveryFee}</Text>
              {Utility.isPresent(deliveryFeeOrignal) && (
                <Text style={styles.deliveryFeeOrignal} dataSet={{ media: cartIds.deliveryFeeOrignal }}>
                  {deliveryFeeOrignal}
                </Text>
              )}
            </View>
          )}
        </View>
      )}
      {showDeliveryFeeDiscountButton && !hideCodAndDeliveryFee && (
        <TouchableOpacity
          style={styles.unlockFreeDeliveryContainer}
          onPress={onPressUnlockFreeDelivery}
        >
          <Text style={styles.unlockFreeDeliveryText}>
            Unlock Free delivery on this order
          </Text>
          <View style={styles.claimButton}>
            <Text style={styles.claimButtonText}>Claim</Text>
          </View>
        </TouchableOpacity>
      )}
      <FullWidthDivider margin={16} />
      <View style={styles.pricingTextContainer} dataSet={{ media: cartIds.pricingTextContainer }}>
        <Text style={styles.grandTotaltext} dataSet={{ media: cartIds.grandTotaltext }}>To pay</Text>
        {Utility.isBlank(total) || isOfferShimmerVisible ? (
          <ShimmerPlaceHolder
            autoRun
            style={[styles.grandTotalPrice, { width: 54 }]}
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          />
        ) : (
          <Text
            style={styles.grandTotalPrice}
            dataSet={{ media: cartIds.grandTotalPrice }}
          >{getPriceText(total)}</Text>
        )}
      </View>
    </View>
  );
};

const CartPricing = ({
  pricingData = {},
  navigation,
  isOfferShimmerVisible,
  remoteConfigData,
  showDeliveryFeeDiscountButton = false,
  onPressUnlockFreeDelivery = {},
}) => {
  const iscod = useSelector((state) => state?.bag?.cod) || false;

  useEffect(
    () => {
      if (showDeliveryFeeDiscountButton) {
        AnalyticsManager.logEvent('list_item_view', {
          item_type: 'delivery_fee_discount',
          screen_name: 'cart',
          list_type: 'cart_builder',
        });
      }
    },

    [showDeliveryFeeDiscountButton], // <= means: run this effect only if any variable
  );

  if (iscod) {
    return (
      <PricingDetailsCod
        pricingData={pricingData}
        navigation={navigation}
        isOfferShimmerVisible={isOfferShimmerVisible}
        remoteConfigData={remoteConfigData}
        showDeliveryFeeDiscountButton={showDeliveryFeeDiscountButton}
        onPressUnlockFreeDelivery={onPressUnlockFreeDelivery}
      />
    );
  }
  return (
    <>
      <PricingDetails
        pricingData={pricingData}
        navigation={navigation}
        isOfferShimmerVisible={isOfferShimmerVisible}
        remoteConfigData={remoteConfigData}
        showDeliveryFeeDiscountButton={showDeliveryFeeDiscountButton}
        onPressUnlockFreeDelivery={onPressUnlockFreeDelivery}
      />
    </>
  );
};

export default CartPricing;
