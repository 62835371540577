import React, { Component } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CartStyles } from '../../components/cart/styles';
import {
  optedForPlasticFreePackaging,
  getCartPricing,
  setContactLess,
  updatePlasticFreeData,
} from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { AnalyticsManager, AnalyticsUtilty } from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import FastImageView from '../../components/FastImageView';

class CartFoxyPromise extends Component {
  constructor(props) {
    super(props);

    this.index = 0;
  }

  randomToast = (obj) => {
    if (this.index === Object.keys(obj).length) {
      this.index = 0;
    }
    console.log(this.index, 'this.index');
    let returnedValue = obj[`${this.index}`];
    this.index++;

    AnalyticsManager.logEvent('foxy_promise_click', {
      message: returnedValue || '',
    });

    return returnedValue;
  };

  foxyPromise = () => {
    const { showToast } = this.props;

    const foxyPromise = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.foxy_promise_cart,
    );

    let foxyPromiseToast = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.foxy_promise_toast,
    );

    try {
      foxyPromiseToast = Utility.jsonParser(foxyPromiseToast);
    } catch (e) {}

    return (
      <TouchableWithoutFeedback
        onPress={() => {
          const toast = this.randomToast(foxyPromiseToast);
          showToast(toast, 200);
        }}
      >
        <FastImageView
          source={foxyPromise}
          resizeMode="contain"
          style={{
            height: 80,
            width: Utility.getScreenWidth(),
            marginTop: 8,
          }}
        />
      </TouchableWithoutFeedback>
    );
  };

  render() {
    const foxyPromise = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.foxy_promise_cart,
    );

    if (Utility.isBlank(foxyPromise)) {
      return null;
    }

    return (
      <>
        <FastImageView
          source={foxyPromise}
          style={styles.image}
          dataSet={{ media: ids.image }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  isContactLess: state.bag.isContactLess,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      optedForPlasticFreePackaging,
      getCartPricing,
      setContactLess,
      updatePlasticFreeData,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CartFoxyPromise),
);

const { ids, styles } = StyleSheet.create({
  image: {
    height: Utility.getScreenWidth() / 2,
    width: Utility.getScreenWidth(),
    marginVertical: 41,
    '@media (min-width: 768px)': {
      width: '100%',
      height: 212,
    },
  },
});
