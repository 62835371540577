import { View, Text, StyleSheet, Image } from 'react-native';
import React from 'react';

export default function BottomLayer() {
  return (
    <View style={styles.container}>
      <View style={styles.benefits}>
        <Benefit
          source="https://www.foxy.in/assets/icons/f1-698e76f67f493d8f0c3a1b113e75dae58cc40c819bf3c5d85a9b4d382b02187b.png"
          text="Selfie Analysis & personalised recommendations"
        />
        <Benefit
          source="https://www.foxy.in/assets/icons/f2-2c9da8455b55d980463abc57a6f7b3328f4759b0f44d03b9426b5539c01fc429.png"
          text="Instant Rewards & free gifts. Best deals in town"
        />
        <Benefit
          source="https://www.foxy.in/assets/icons/f3-29f5d65590f01a3e12142584916a7a0c45841391f5cd453b18bb53834b1dbd4c.png"
          text="Honest Reviews & expert video content"
        />
        <Benefit
          source="https://www.foxy.in/assets/icons/f4-afc34afe01fb8b94f81153223441f22298b10005f63acde70cd2720eda442446.png"
          text="Sustainable Business Model with Foxy Zero"
        />
      </View>
      <View style={styles.circleContainer}>
        <View style={styles.dottedCircle} />
        <View style={styles.bottomCircle} />
      </View>
    </View>
  );
}


const Benefit = ({ source = '', text = '' }) => (
  <View style={styles.benefit}>
    <Image source={{ uri: source }} style={styles.image} />
    <Text style={styles.text}>{text}</Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 'calc(0.3401094605 * 100vw)',
    marginBottom: 50,
  },
  benefits: {
    width: '100%',
    height: '20vw',
    flexDirection: 'row',
    justifyContent: 'space-around',
    zIndex: 1,
    position: 'relative',
    top: '10%',
    paddingLeft: '8%',
    paddingRight: '3%',
    marginTop: 20,
  },
  benefit: {
    width: '12%',
  },
  image: {
    height: '6vw',
    width: '6vw',
  },
  text: {
    fontSize: '1.3vw',
    fontFamily: 'Roboto-Regular',
    lineHeight: 28,
  },
  circleContainer: {
    position: 'absolute',
    bottom: '2%',
    right: 0,
    width: '30%',
  },
  dottedCircle: {
    display: 'block',
    width: '13vw',
    height: '13vw',
    bottom: 0,
    float: 'left',
    borderRadius: '50%',
    border: '1px dashed black',
    position: 'absolute',
    zIndex: 1,
  },
  bottomCircle: {
    display: 'block',
    float: 'right',
    transform: 'rotate(-58.9deg)',
    backgroundImage: 'linear-gradient(184.48deg, #fcd0a3 3.63%, #e0effd 80.42%)',
    height: '33vw',
    width: '33vw',
    borderRadius: '50%',
    left: '20%',
    position: 'relative',
  },
});
