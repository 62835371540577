import React, { PureComponent } from 'react';
import {
  View,
  AppState,
  NativeEventEmitter,
  NativeModules,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { denormalize } from 'normalizr';
import Toast from 'react-native-easy-toast';
import Config from '../../libraries/ReactNativeConfig';
import { List } from '../List';
import {
  fetchStore,
  getFreeItemForOffer,
  setNotificationModalDisplayTime,
} from '../../actions/ActionTypes';
import styles from './styles';
import { listSchema } from '../../config/Schema';
import Utility from '../../utils/Utility';
import {
  TabNavigationRightHeader,
  TabNavigationLeftHeader,
} from '../../components/header';
import {
  TABBAR_CONSTANT,
  AnalyticsScreenNames,
  SCREEN,
  REMOTE_CONFIG_KEYS,
  STORE_PAGE_SLUG,
  NOTIFICATION_REQUEST_MODAL_TYPE,
} from '../../config/Constants';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { ScreenPlaceholder } from '../../components/shared';
import { CartTray } from '../feed';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import SelfieReviewModalWrapper from '../../utils/SelfieReviewModalWrapper';
import OfferTray from '../feed/OfferTray';
import RemoteConfig from '../../utils/RemoteConfig';
import BottomSearch from '../../components/bottomSearchComponent';
import { firebase } from '../../libraries/Firebase';
import NotificationModal from '../../utils/Notification/NotificationModal';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import WebFooter from '../../components/webFooter/WebFooter';
import { isNative } from 'lodash';

class Store extends PureComponent {
  static navigationOptions = ({ navigation }) => {
    const { STORE_TAB_LABEL = 'Deals' } = Config || {};
    let { params = {} } = navigation.state;
    let titleStyle = Utility.isAndroid()
      ? {
          textAlign: 'center',
          alignSelf: 'center',
          flex: 1, // must to centrally align title on android, react navigation bug
        }
      : {};
    params = {
      title: STORE_TAB_LABEL,
      headerTitleStyle: titleStyle,
      headerLeft: <TabNavigationLeftHeader tabBar={TABBAR_CONSTANT.store} />,
      headerRight: (
        <TabNavigationRightHeader
          showCart
          store
          tabBar={TABBAR_CONSTANT.store}
        />
      ),
    };

    return params;
  };

  Type = {
    list: List,
    bottomSearchContainer: BottomSearch,
  };

  constructor(props) {
    super(props);

    this.trace = firebase.perf().newTrace(SCREEN_CONSTANTS.STORE);
    this.trace.start();
    this.state = {
      currentlyPlayingIndex: null,
      isCartTrayVisible: false,
      isScreenActive: false,
      visible: false,
      showFreeProductScreen: false,
      list: {},
      showServerError: false,
      canDisplayNotificationRequestModal: false,
      showNotificationPrompt: false,
      hideOosProduct: false,
    };
    this.didFocusListener = null;
    this.willBlurListener = null;

    if (Utility.isAndroid()) {
      this.uploadManager = new NativeEventEmitter(NativeModules.UploadManager);
    } else {
      this.uploadManager = new NativeEventEmitter(
        NativeModules.LocalNotificationHandler,
      );
    }

    this.listRef = null;
    this.isCurrentScreen = false;

    Utility.setPageLoadStart('store');
  }

  toggleCartVisibility = () => {
    this.props.navigation.push('Cart', {
      source: 'store',
    });
  };

  showCartTray = () => {
    this.setState({
      isCartTrayVisible: true,
    });
  };

  hideCartTray = () => {
    this.setState({
      isCartTrayVisible: false,
    });
  };

  componentDidMount() {
    const { navigation } = this.props;

    this.props.fetchStore(this.storeApiCallback);
    if (isNative()) {
      this.props.navigation.setParams({
        handleCartPress: this.toggleCartVisibility,
      });
    }
    this.didFocusListener = navigation.addListener('focus', () => {
      this.onWillFocus();
      this.onDidFocus();
      this.isCurrentScreen = true;
    });
    this.willBlurListener = navigation.addListener('blur', () => {
      this.onWillBlur();
      this.isCurrentScreen = false;
    });
    this.tabPressListener = navigation
      .getParent()
      .addListener('tabPress', (e) => {
        if (this.isCurrentScreen) {
          this.scrollPageToTop();
        }
      });
    this.trace.stop();
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.STORE,
    });

    setTimeout(this.displayNotificationModal, 4000);
  }

  displayNotificationModal = () => {
    const {
      setNotificationModalDisplayTime,
      initial_app_opened_at,
      lastNotificationModalDisplayTime,
    } = this.props;
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      'Store',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          setNotificationModalDisplayTime(new Date().getTime());
        }
      },
      initial_app_opened_at,
    );
  };

  showNotificationModal = (show, showNotification) => {
    this.setState({
      canDisplayNotificationRequestModal: show,
      showNotificationPrompt: showNotification,
    });
  };

  handleAppStateChange = (nextAppState) => {
    const { navigation, isSpecialOfferApplied, cartItems } = this.props;
    if (nextAppState === 'active') {
      console.log('STORE', 'FG');
    }
  };

  updateCurrentlyPlayingIndex = (index) => {
    this.setState({
      currentlyPlayingIndex: index,
    });
  };

  onDidFocus = () => {
    const { getFreeItemForOffer } = this.props;

    const offerActive = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.active_offer);
    this.setState((prevState) => ({
      currentlyPlayingIndex: prevState.lastPlayingIndex,
      lastPlayingIndex: null,
      isScreenActive: true,
    }));
    AnalyticsManager.logEvent(EventType.discoveryEvents.OFFERS_VIEW);
    getFreeItemForOffer(offerActive);
  };

  storeApiCallback = (success, response) => {
    console.log('Store Api Callback = ', success, response);
    this.setState({
      showServerError: !success,
      retryLoading: false,
    });
    if (success) {
      this.setState({ list: response });
    }
  };

  showFreeProductScreen = () => {
    AnalyticsManager.logEvent('VIEW_PRODUCTS_CLICKED', {
      screen: 'store',
    });
    this.props.navigation.navigate('FreeGiftScreen');
  };

  onWillBlur = () => {
    this.setState((prevState) => ({
      currentlyPlayingIndex: null,
      lastPlayingIndex: prevState.currentlyPlayingIndex,
      isScreenActive: false,
    }));
  };

  checkVisible = (isVisible) => {
    if (isVisible) {
      if (!this.state.visible) {
        this.setState({ visible: true });
      }
    } else if (this.state.visible) {
      this.setState({ visible: false });
    }
  };

  refreshStore = () => {
    this.page = 0;
    this.canLoadMore = true;
  };

  componentWillUnmount() {
    this.didFocusListener();
    this.willBlurListener();
    this.tabPressListener();
  }

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  scrollPageToTop = () => {
    if (this.listRef) {
      this.listRef.scrollToOffset({ offset: 0, animated: true });
    }
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  firePageLoadEnd = () => {
    Utility.setPageLoadEnd(SCREEN_CONSTANTS.STORE);
  };

  onWillFocus = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.TAB_VIEW, {
      tab: 'store',
    });
    AnalyticsManager.setCurrentScreen('store');
    Utility.setStatusBarWhite();
    Utility.setStatusBarWhite();
    // this.setState((prevState) => ({ currentlyPlayingIndex: prevState.lastPlayingIndex, lastPlayingIndex: null }));
  };

  onRetryPressed = () => {
    const { fetchStore } = this.props;
    this.setState({
      retryLoading: true,
    });
    fetchStore(this.storeApiCallback);
  };

  onPressHideOosCheckBox = (hideOosProduct) => {
    this.setState({
      hideOosProduct,
    });
  };

  render() {
    const { navigation, cartOffers } = this.props;
    const {
      currentlyPlayingIndex,
      isCartTrayVisible,
      isScreenActive,
      list,
      showNotificationPrompt,
      canDisplayNotificationRequestModal,
      showServerError,
      retryLoading,
      hideOosProduct,
    } = this.state;
    const { STORE_TAB_LABEL = 'Deals' } = Config || {};

    if (Utility.isBlank(list?.objects) && !showServerError) {
      return <ScreenPlaceholder />;
    }
    const ContainerComponent = this.Type[list.type];

    if (ContainerComponent === undefined && !showServerError) {
      return null;
    }

    const paginateLastListItem = list.paginate_last_item;
    const RootComponent = isWeb() ? ScrollView : View;
    return (
      <ErrorBoundary
        screen_name={AnalyticsScreenNames.STORE}
        itemData={{ name: 'store', type: 'list', id: 'undefined' }}
        showServerError={showServerError}
        onPressReload={this.onRetryPressed}
        loading={retryLoading}
        hideHeader
      >
        <SelfieReviewModalWrapper isActive={isScreenActive}>
          <RootComponent style={styles.container}>
            <List
              navigation={navigation}
              slug={STORE_PAGE_SLUG}
              authToken={this.props.authToken}
              toggleCartVisibility={this.toggleCartVisibility}
              updateCurrentlyPlayingIndex={this.updateCurrentlyPlayingIndex}
              currentlyPlayingIndex={currentlyPlayingIndex}
              previousScreen={SCREEN_CONSTANTS.STORE}
              showToast={this.showToastForAddToCart}
              checkVisible={this.checkVisible}
              showFreeProductScreen={this.showFreeProductScreen}
              setListRef={this.setListRef}
              onPressHideOosCheckBox={this.onPressHideOosCheckBox}
              hideOosProduct={hideOosProduct}
            />
            <WebFooter />
          </RootComponent>
          <CartTray
            toggleCartVisibility={this.toggleCartVisibility}
            toggleCartTrayVisibility={this.toggleCartTrayVisibility}
            showCartTray={this.showCartTray}
            hideCartTray={this.hideCartTray}
            isCartTrayVisible={isCartTrayVisible}
          />
          <OfferTray
            toggleCartVisibility={this.toggleCartVisibility}
            toggleCartTrayVisibility={this.toggleCartTrayVisibility}
            showCartTray={this.showCartTray}
            hideCartTray={this.hideCartTray}
            showFreeProductScreen={this.showFreeProductScreen}
            isCartTrayVisible={!this.state.visible}
          />
          <Toast
            style={{ position: 'absolute', bottom: 100 }}
            ref={(ref) => {
              this.toast = ref;
            }}
          />
          <NotificationModal
            isNotificationModalVisible={canDisplayNotificationRequestModal}
            showNotificationModal={this.showNotificationModal}
            showNotificationPrompt={showNotificationPrompt}
            type={NOTIFICATION_REQUEST_MODAL_TYPE.STORE_PAGE}
          />
        </SelfieReviewModalWrapper>
      </ErrorBoundary>
    );
  }
}

const storeStyles = StyleSheet.create({
  storeContainer: {
    height: '100%',
    overflow: 'visible',
  },
  tabImageStyle: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
});

Store.propTypes = {
  list: PropTypes.shape({
    type: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  if (Utility.isBlank(state.data.entities)) {
    return {};
  }
  return {
    cartOffers: state.cart.cartOffers,
    remoteConfigData:
      state.UserAccountInfo.remote_configs &&
      state.UserAccountInfo.remote_configs.data
        ? state.UserAccountInfo.remote_configs.data
        : '{}',
    initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
    lastNotificationModalDisplayTime:
      state.UserAccountInfo.lastNotificationModalDisplayTime,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      { fetchStore, getFreeItemForOffer, setNotificationModalDisplayTime },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Store);

export const StoreHeaderOptions = isDesktop() ? {
  headerShown: false,
} : {
  title: Config.STORE_TAB_LABEL || 'Deals',
  headerTitleAlign: 'center',
  headerLeft: () => <TabNavigationLeftHeader tabBar={TABBAR_CONSTANT.store} />,
  headerRight: () => (
    <TabNavigationRightHeader showCart store tabBar={TABBAR_CONSTANT.store} />
  ),
  headerBackVisible: false,
  headerShadowVisible: false,
  headerStyle: {
    backgroundColor: colors.white,
  },
};

export const StoreNavigationOptions = {
  tabBarLabel: Config.STORE_TAB_LABEL,
  tabBarActiveTintColor:
    Config.STORE_TAB_ACTIVE_TEXT_COLOR ?? Config.STORE_TAB_ACTIVE_COLOR,
  tabBarInactiveTintColor: Config.STORE_TAB_INACTIVE_COLOR,
  tabBarStyle: { borderTopWidth: 0, elevation: 0 },
  tabBarIcon: ({ focused }) => {
    const focusedIcon = Utility.isPresent(Config.STORE_TAB_ACTIVE_ICON_URL)
      ? { uri: Config.STORE_TAB_ACTIVE_ICON_URL }
      : images.tab.offer_selected;
    const unfocusedIcon = Utility.isPresent(Config.STORE_TAB_ICON_URL)
      ? { uri: Config.STORE_TAB_ICON_URL }
      : images.tab.offer_unselected;
    const icon = focused ? focusedIcon : unfocusedIcon;
    const tintColor = focused
      ? Config.STORE_TAB_ACTIVE_COLOR
      : Config.STORE_TAB_INACTIVE_COLOR;

    const imageStyle = getMemoizedImageStyle(tintColor);

    return <Image source={icon} style={imageStyle} testID="store-tab-icon " accessibilityLabel="store-tab-icon" />;
  },
};

const imageStyles = {};

const getMemoizedImageStyle = (tintColor) => {
  if (!imageStyles[tintColor]) {
    imageStyles[tintColor] = [storeStyles.tabImageStyle, { tintColor }];
  }
  return imageStyles[tintColor];
};
