import React, { Component } from 'react';
import {
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  Animated,
  Image,
  TextInput,
  Keyboard,
  ActivityIndicator,
  DeviceEventEmitter,
  BackHandler,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { denormalize } from 'normalizr';
import _ from 'lodash';

import styles from './styles';
import FoxyIcon from '../../../lib/FoxyIcon';
import images from '../../../theme/Images';
import * as UploadPostActions from '../../../actions/UploadPostActions';
import * as LoginActions from '../../../actions/LoginActions';
import { fetchProduct } from '../../../actions/ActionTypes';
import colors, { Theme } from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import FoxyRoundedButton from '../../../lib/FoxyRoundedButton';
import { listSchema } from '../../../config/Schema';
import { List } from '../../List';
import FullScreenVariant from '../../../components/Product/FullScreenVariant';
import ScaleAnimate from '../../../components/shared/ScaleAnimate';

class ProductReview extends Component {
  constructor(props) {
    super(props);

    const { route } = props;
    this.currentContentItemIndex = route.params?.currentContentItemIndex;

    this.productPicUri = route.params?.productPicUri;
    this.productListID = route.params?.productListID;
    this.searchString = route.params?.searchString;

    this.state = {
      addedProducts:
        props.posts[props.currentPostID].items[this.currentContentItemIndex]
          .products,
      searchBoxVisibility: false,
      searchTerm: this.searchString[0],
      recentProductSearchListID: this.productListID,
      showSearchSuggestionBox: false,
      searchSuggestions: [],
      tappedProduct: '',
      doneButtonAvailability: true,
      showFullScreenVariant: false,
      currentProductVariantID: '',
      selectedVariantName: '',
      selectedVariantImage: '',
      showTopResult: true,
    };
    this.setupSafeVariables(Utility.topInset, Utility.bottomInset);

    this.scanProductsListContainerHeight = new Animated.Value(250);

    this.foxyCamera = React.createRef();
    this.textInput = React.createRef();
    this.leftOffset = new Animated.Value(0);
    this.variantRef = React.createRef();
  }

  componentDidMount() {
    if (Utility.isAndroid()) {
      this.keyboardDidShowListener = Keyboard.addListener(
        'keyboardDidShow',
        () => {},
      );
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        this.keyboardDidHide,
      );
      this.subscription = DeviceEventEmitter.addListener(
        'focusChange',
        this.onFocusChange,
      );
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          this.goBack();
          return true;
        },
      );
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.subscription.remove();
      this.backHandler.remove();
      this.keyboardDidShowListener?.remove();
      this.keyboardDidHideListener?.remove();
    }
  }

  componentDidUpdate = (prevState) => {
    const { searchBoxVisibility } = this.state;
    if (prevState.searchBoxVisibility !== searchBoxVisibility) {
      Animated.timing(this.leftOffset, {
        toValue: searchBoxVisibility ? -360 : 0,
        duration: searchBoxVisibility ? 300 : 0,
      }).start(() => {
        if (searchBoxVisibility) {
          this.textInput.current.focus();
        }
      });
    }
  };

  keyboardDidHide = () => {
    Utility.setImmersiveModeOn();
  };

  onFocusChange = (params) => {
    if (params.appHasFocus) {
      Utility.setImmersiveModeOn();
    }
  };

  setupSafeVariables = (topInset, bottomInset) => {
    const { deviceAPILevel } = Utility;
    this.topInset = topInset;
    this.bottomInset = bottomInset;
    this.bottomSectionHeight = Utility.isAndroid() ? 72 : 72 + bottomInset;
    if (Utility.isAndroid()) {
      if (
        (deviceAPILevel < 28 && Utility.statusBarHeight() > 24) ||
        (deviceAPILevel >= 28 && Utility.statusBarHeight() < 33)
      ) {
        this.topSectionHeight = 40 + 10;
        this.middleSectionHeight =
          Utility.screenHeight -
          (this.topSectionHeight +
            this.bottomSectionHeight +
            Utility.statusBarHeight());
      } else {
        this.topSectionHeight = 40 + Utility.statusBarHeight();
        this.middleSectionHeight =
          Utility.screenHeight -
          (this.topSectionHeight + this.bottomSectionHeight);
      }
    } else {
      this.topSectionHeight = 40 + topInset + Utility.statusBarHeight();
      this.middleSectionHeight =
        Utility.screenHeight -
        (this.topSectionHeight + this.bottomSectionHeight);
    }
    this.taggedProductsContainerHeight = Utility.isAndroid()
      ? 56
      : 56 + bottomInset;
    this.forceUpdate();
  };

  topSection = (props) => (
    <View
      style={[styles.topSectionContainer, { height: props.topSectionHeight }]}
    >
      <View style={styles.topSectionInset}>
        <FoxyIcon
          icon={images.addProductsScreenIcon.goBack}
          backgroundColor={Theme.addProducts.topSection.iconBackground}
          tintColor="#173143"
          onPress={this.goBack}
        />
        <this.emptyButton />
      </View>
    </View>
  );

  emptyButton = () => <FoxyIcon backgroundColor="transparent" />;

  goBack = () => {
    const { navigation } = this.props;
    this.addTaggedProductsOnGlobalState();
    navigation.goBack();
  };

  middleSection = (props) => (
    <View style={[styles.middleSection, { height: props.middleSectionHeight }]}>
      <this.searchBoxContainer searchTerm={props.searchTerm} />
      {!Utility.isBlank(props.currentProductsList) && (
        <this.productsListContainer
          productsListContainerHeight={props.middleSectionHeight - 60}
          {...props}
        />
      )}
      {props.productSearchingStatus && (
        <this.showActivityIndicator
          productListContainerHeight={props.productListContainerHeight}
        />
      )}
    </View>
  );

  searchBoxContainer = (props) => (
    <Animated.View style={styles.searchBoxContainer}>
      <View style={styles.searchBox}>
        <FoxyIcon
          icon={images.addProductsScreenIcon.search}
          height={18}
          width={18}
          backgroundColor="transparent"
          tintColor="rgb(153, 156, 173)"
        />
        <TextInput
          defaultValue={props.searchTerm}
          ref={this.textInput}
          placeholder="Search Product"
          placeholderTextColor="rgb(153, 156, 173)"
          onChangeText={this.enterSearchTerm}
          onSubmitEditing={this.onSubmitSearchTerm}
          style={styles.searchText}
          blurOnSubmit={false}
        />
        <FoxyIcon
          icon={images.addProductsScreenIcon.smallCross}
          height={18}
          width={18}
          backgroundColor="transparent"
          tintColor="rgb(153, 156, 173)"
          onPress={this.clearSearchResults}
        />
      </View>
    </Animated.View>
  );

  clearSearchResults = () => {
    this.setState({ searchTerm: '', recentProductSearchListID: '' });
    this.textInput.current.focus();
  };

  showSearchSuggestions = (props) => (
    <ScrollView
      style={[
        styles.searchSuggestionsContainer,
        { top: this.topSectionHeight + 50 },
      ]}
      keyboardShouldPersistTaps="always"
    >
      {props.searchSuggestions.map((item, index) => (
        <this.suggestion item={item} />
      ))}
    </ScrollView>
  );

  suggestion = (props) => (
    <TouchableOpacity
      style={{
        height: 39,
        borderBottomColor: colors.border,
        justifyContent: 'center',
        paddingHorizontal: 10,
      }}
      onPress={this.submitPickedItem(props.item.name)}
    >
      <Text style={{ color: 'rgb(153, 156, 173)' }}>{props.item.name}</Text>
    </TouchableOpacity>
  );

  enterSearchTerm = (text) => {
    const { uploadPostActions } = this.props;
    uploadPostActions.showSearchSuggestion(text, (data) => {
      this.setState({ searchSuggestions: data });
    });
    this.setState({ showSearchSuggestionBox: true });
    this.setState({ searchTerm: [text] });
  };

  onSubmitSearchTerm = () => {
    const { searchTerm } = this.state;
    const { uploadPostActions, loginActions } = this.props;
    this.setState({ showSearchSuggestionBox: false, showTopResult: false });
    uploadPostActions.searchProduct(searchTerm, (listID) => {
      this.setState({ recentProductSearchListID: listID });
    });
    loginActions.toggleProductSearchingStatus(true);
  };

  submitPickedItem = (searchText) => () => {
    this.setState({ searchTerm: searchText, showTopResult: false });
    const { uploadPostActions, loginActions } = this.props;
    Keyboard.dismiss();
    uploadPostActions.searchProduct([searchText, '', ''], (listID) => {
      this.setState({
        recentProductSearchListID: listID,
        showSearchSuggestionBox: false,
      });
    });
    loginActions.toggleProductSearchingStatus(true);
  };

  productsListContainer = (props) => {
    const productListWithTopMostProduct = props.currentProductsList;
    if (!Utility.isBlank(props.currentProductsList.objects)) {
      productListWithTopMostProduct.objects = _.slice(
        props.currentProductsList.objects,
        (start = 1),
        (end = props.currentProductsList.objects.length),
      );
    }
    return (
      <View
        style={{
          width: Utility.screenWidth,
          height: props.productsListContainerHeight,
        }}
      >
        {props.productSearchingStatus === false && (
          <ScrollView
            style={{ height: props.productListContainerHeight }}
            keyboardShouldPersistTaps="always"
          >
            {!Utility.isBlank(props.currentProductsList.objects) ? (
              <>
                {props.showTopResult && (
                  <this.topProductResult
                    topResult={props.topResult}
                    addedProducts={props.addedProducts}
                    currentProductsList={props.currentProductsList}
                  />
                )}
                <List
                  itemData={props.currentProductsList}
                  id={props.currentProductsList.id}
                  type={props.currentProductsList.type}
                  size={props.currentProductsList.size}
                  layout={props.currentProductsList.display}
                  onPress={this.onProductTapped}
                  iconName={images}
                  showCustomIcon
                  showColorComponent={false}
                  addedProducts={props.addedProducts}
                />
              </>
            ) : (
              <this.showNotFoundMessage
                productListContainerHeight={props.productListContainerHeight}
              />
            )}
          </ScrollView>
        )}
      </View>
    );
  };

  showNotFoundMessage = (props) => (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ textAlign: 'center', color: '#173143' }}>
        No Product found for current scanned product, tag products through
        manual search
      </Text>
    </View>
  );

  topProductResult = (props) => (
    <ScaleAnimate>
      <Animated.View style={styles.topProductResult}>
        <this.topScannedProductInfo
          item={props.topResult}
          addedProducts={props.addedProducts}
        />
      </Animated.View>
    </ScaleAnimate>
  );

  topScannedProductInfo = (props) => {
    const iconName = props.addedProducts.includes(props.item.id)
      ? images.interest.selected
      : images.interest.unselected;
    return (
      <View style={styles.topScannedProductInfo}>
        <Image
          elevation={2}
          source={{ uri: this.productPicUri }}
          style={styles.topListProductImage}
        />
        <View style={styles.topListProductInfo}>
          <Text style={styles.topProductBrandName}>
            {_.toUpper(props.item.brand.name)}
          </Text>
          <Text numberOfLines={2} style={styles.topProductName}>
            {props.item.name}
          </Text>
          <View style={styles.topProductShadeInfoContainer}>
            <FoxyIcon
              height={10}
              width={10}
              backgroundColor="rgb(255, 97, 91)"
            />
            <Text style={styles.topProductShadeName}>Coral Pink</Text>
          </View>
        </View>
        <FoxyIcon
          icon={iconName}
          backgroundColor="white"
          tintColor="#173143"
          onPress={this.onProductTapped(props.item.id)}
        />
      </View>
    );
  };

  showActivityIndicator = () => (
    <View style={styles.activityIndicatorContainer}>
      <ActivityIndicator size="large" color="#173143" />
    </View>
  );

  toggleSearchBoxVisibility = () =>
    this.setState((prevState) => ({
      searchBoxVisibility: !prevState.searchBoxVisibility,
    }));

  onProductTapped = (productID) => () => {
    const { fetchProduct } = this.props;
    const { addedProducts } = this.state;
    if (!addedProducts.includes(productID)) {
      this.setState({ currentProductVariantID: '' });
    }
    fetchProduct(
      `/api/v1/products/${productID}`,
      () => {
        this.setState({
          doneButtonAvailability: false,
          showFullScreenVariant: true,
        });
        this.variantRef.current.onView();
      },
      () =>
        this.setState({
          doneButtonAvailability: true,
          showFullScreenVariant: false,
        }),
    );
    this.toggleTappedProducts(productID);
  };

  bottomSection = (props) => {
    let doneButtonColor = '#173143';
    if (!props.doneButtonAvailability) {
      doneButtonColor = 'rgba(23,49,67,0.5)';
    }
    let onDoneButtonPress = this.goBackOnPreview;
    if (props.showFullScreenVariant) {
      onDoneButtonPress = this.toggleFullScreenVariant;
    }
    return (
      <View
        elevation={3}
        style={[
          styles.bottomSectionContainer,
          { height: props.bottomSectionHeight },
        ]}
      >
        <View style={styles.bottomSectionInset}>
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onPress={this.toggleFullScreenVariant}
          >
            <Image
              source={{
                uri: !Utility.isBlank(props.currentProductVariantID)
                  ? props.selectedVariantImage
                  : '',
              }}
              style={{ height: 40, width: 40, borderRadius: 20 }}
            />
            <Text style={{ marginLeft: 10 }}>
              {!Utility.isBlank(props.currentProductVariantID) &&
                props.selectedVariantName}
            </Text>
          </TouchableOpacity>
          <FoxyRoundedButton
            title="Done"
            onPress={onDoneButtonPress}
            backgroundColor={doneButtonColor}
            titleColor="white"
          />
        </View>
      </View>
    );
  };

  goBackOnPreview = () => {
    const { navigation } = this.props;
    this.addTaggedProductsOnGlobalState();
    navigation.pop(2);
  };

  addTaggedProductsOnGlobalState = () => {
    const { uploadPostActions, currentPostID } = this.props;
    const { addedProducts } = this.state;
    uploadPostActions.addProducts(
      currentPostID,
      this.currentContentItemIndex,
      addedProducts,
    );
  };

  toggleTappedProducts = (productID) => {
    const { entity } = this.props;
    console.log('Tapped Product Data', entity.products[productID]);
    this.setState({ addedProducts: [productID], tappedProduct: productID });
  };

  updateSelectedVariant = (variant) => {
    this.setState({
      currentProductVariantID: variant.id,
      doneButtonAvailability: true,
      selectedVariantName: variant.display_name,
      selectedVariantImage: variant.image_url,
    });
  };

  toggleFullScreenVariant = () => {
    this.setState((prevState) => ({
      showFullScreenVariant: !prevState.showFullScreenVariant,
    }));
  };

  render() {
    const { entity, productSearchingStatus } = this.props;
    const {
      addedProducts,
      recentProductSearchListID,
      showSearchSuggestionBox,
      searchSuggestions,
      tappedProduct,
      searchTerm,
      doneButtonAvailability,
      showFullScreenVariant,
      currentProductVariantID,
      showTopResult,
      selectedVariantName,
      selectedVariantImage,
    } = this.state;
    const currentProductsList = denormalize(
      entity.lists[recentProductSearchListID],
      listSchema,
      entity,
    );
    const currentSelectedProduct = entity.products[8784];
    return (
      <View style={styles.container}>
        <this.topSection topSectionHeight={this.topSectionHeight} />
        <this.middleSection
          middleSectionHeight={this.middleSectionHeight}
          topResult={
            !Utility.isBlank(currentProductsList)
              ? currentProductsList.objects[0]
              : {}
          }
          currentProductsList={currentProductsList}
          addedProducts={addedProducts}
          productSearchingStatus={productSearchingStatus}
          searchTerm={searchTerm}
          showTopResult={showTopResult}
        />
        <this.bottomSection
          entity={entity}
          addedProducts={addedProducts}
          bottomSectionHeight={this.bottomSectionHeight}
          doneButtonAvailability={doneButtonAvailability}
          showFullScreenVariant={showFullScreenVariant}
          currentProductVariantID={currentProductVariantID}
          selectedVariantName={selectedVariantName}
          selectedVariantImage={selectedVariantImage}
        />
        {showSearchSuggestionBox && !Utility.isBlank(searchSuggestions) && (
          <this.showSearchSuggestions searchSuggestions={searchSuggestions} />
        )}
        {showFullScreenVariant && (
          <FullScreenVariant
            ref={this.variantRef}
            itemData={currentSelectedProduct}
            // selectedVariant={currentProductVariantID}
            // updateSelectedVariant={this.updateSelectedVariant}
            // selectedVariantName={selectedVariantName}
            // selectedVariantImage={selectedVariantImage}
          />
        )}
      </View>
    );
  }
}

ProductReview.propTypes = {
  entity: PropTypes.any,
  posts: PropTypes.any,
  uploadPostActions: PropTypes.any,
  loginActions: PropTypes.any,
  currentPostID: PropTypes.any,
  productSearchingStatus: PropTypes.any,
  navigation: PropTypes.any,
};

export default connect(
  (state) => ({
    posts: state.Posts,
    entity: state.data.entities,
    currentPostID: state.UserAccountInfo.currentPostId,
    productSearchingStatus: state.UserAccountInfo.productSearchingStatus,
  }),
  (dispatch) => ({
    uploadPostActions: bindActionCreators(UploadPostActions, dispatch),
    loginActions: bindActionCreators(LoginActions, dispatch),
    fetchProduct: bindActionCreators(fetchProduct, dispatch),
  }),
)(ProductReview);
