// Dependencies
import React, { Component } from 'react';
import {
  ImageBackground,
  View,
  Image,
  Text,
  FlatList,
  StyleSheet,
  StatusBar,
} from 'react-native';
import Orientation from 'react-native-orientation';
import _ from 'lodash';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
// import sharedStyles from './styles';
import Utility from '../../utils/Utility';
import foxyImages from '../../theme/Images';
import colors from '../../theme/Colors';
import StaticNavigationHeader from './StaticNavigationHeader';
import FastImageView from '../FastImageView';
import Config from '../../libraries/ReactNativeConfig';

class ScreenPlaceholder extends Component {
  componentDidMount() {
    // Orientation.lockToPortrait();
  }

  componentWillUnmount() {
    const { firePageLoadEnd = () => {} } = this.props;
    firePageLoadEnd();
  }

  railPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.railPlaceholderShimmerContainer}>
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.productBrandNamePlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.productTextPlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.productTextPlaceholder}
        />
      </View>
    );
  };

  contentPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.contentPlaceholderShimmerContainer}>
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.cntentImagePlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.contentNamePlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.contentArtistImagePlaceholder}
        />
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.contentArtistNamePlaceholder}
        />
      </View>
    );
  };

  headerPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={{ width: '50%', marginTop: 24 }}>
        <ShimmerPlaceHolder
          //   colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.headerTextShimmerStyle}
        />
      </View>
    );
  };

  headerPlaceholderOffer = () => {
    const styles = sharedStyles;
    return (
      <View style={{ width: 145 }}>
        <ShimmerPlaceHolder
          colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.headerTextShimmerStyleOffer}
        />
      </View>
    );
  };

  headerPlaceholderSmall = () => {
    const styles = sharedStyles;
    return (
      <ShimmerPlaceHolder
        // colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
        autoRun
        style={styles.headerTextShimmerSmallStyle}
      />
    );
  };

  headerPlaceholderExtraSmall = () => {
    const styles = sharedStyles;
    return (
      <ShimmerPlaceHolder
        colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
        autoRun
        style={styles.headerTextShimmerExtraSmallStyle}
      />
    );
  };

  headerPlaceholderTwo = () => {
    const styles = sharedStyles;
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          // backgroundColor: colors.white,
        }}
      >
        <ShimmerPlaceHolder
          //   colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.headerTextShimmerExtraSmallTwoStyle}
        />
        <ShimmerPlaceHolder
          //   colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
          autoRun
          style={styles.headerTextShimmerExtraSmallTwoStyle}
        />
      </View>
    );
  };

  headerPlaceholderArray = () => {
    const styles = sharedStyles;
    return (
      <View
        style={{
          flexDirection: 'row',
          overflow: 'hidden',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {_.map([1, 2, 3, 4], (e) => {
          return (
            <ShimmerPlaceHolder
              //   colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
              autoRun
              style={styles.headerTextShimmerArrayStyle}
            />
          );
        })}
      </View>
    );
  };

  offerPlaceholder = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          marginTop: 32,
          marginHorizontal: 12,
          width: Utility.getScreenWidth() - 32,
          alignSelf: 'center',
        }}
      >
        <View
          style={{
            height: 193,
            width: Utility.getScreenWidth() - 32,
            backgroundColor: colors.white,
            borderRadius: 8,
            paddingHorizontal: 12,
            paddingVertical: 12,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <ShimmerPlaceHolder
              colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
              autoRun
              style={{
                height: 80,
                width: 80,
                borderRadius: 18,
              }}
            />
            <View style={{ alignItems: 'flex-start', marginLeft: 21 }}>
              <this.headerPlaceholderOffer />
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {_.map([1, 2, 3], (e) => {
                  return (
                    <ShimmerPlaceHolder
                      colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                      autoRun
                      style={{
                        height: 19,
                        width: e == 2 ? 72 : 62,
                        borderRadius: 4,

                        marginRight: 8,
                        marginTop: 12,
                      }}
                    />
                  );
                })}
              </View>
              <ShimmerPlaceHolder
                colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
                autoRun
                style={{
                  height: 19,
                  width: 63,
                  borderRadius: 4,

                  marginRight: 8,
                  marginTop: 12,
                }}
              />
            </View>
          </View>
          <ShimmerPlaceHolder
            colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
            autoRun
            style={{
              height: 16,
              width: Utility.getScreenWidth() - 70,
              borderRadius: 4,
              marginTop: 12,
            }}
          />
          <ShimmerPlaceHolder
            colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
            autoRun
            style={{
              height: 16,
              width: Utility.getScreenWidth() - 90,
              borderRadius: 4,
              marginTop: 4,
            }}
          />
          <ShimmerPlaceHolder
            colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
            autoRun
            style={{
              height: 16,
              width: Utility.getScreenWidth() - 59,
              borderRadius: 4,
              marginTop: 4,
            }}
          />
          <ShimmerPlaceHolder
            colorShimmer={['#f7F7f7', '#eeeeee', '#f7F7f7']}
            autoRun
            style={{
              height: 16,
              width: Utility.getScreenWidth() / 2,
              borderRadius: 4,
              marginTop: 4,
            }}
          />
        </View>
      </View>
    );
  };

  productImagePlaceHolder = () => {
    return (
      <View
        style={{
          // flex: 1,
          // position: 'absolute',
          height: 1.1 * Utility.getScreenWidth(),
          width: Utility.getScreenWidth(),
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          zIndex: 1,
          backgroundColor: colors.white,
          paddingTop: Utility.topInset,
        }}
      >
        <FastImageView
          style={{
            width: 160,
            height: 160,
            resizeMode: 'contain',
            opacity: 0.2,
          }}
          source={Config.PRODUCT_PAGE_ANIMATED_LOGO}
        />
        <View
          style={{
            position: 'absolute',
            bottom: 16,
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {_.map([1, 2, 3, 4, 5], (e) => {
            return (
              <View
                style={{
                  height: 6,
                  width: 6,
                  backgroundColor: e === 1 ? '#979BAA' : '#DFE0E5',
                  borderRadius: 3,
                  marginRight: 6,
                }}
              />
            );
          })}
        </View>

        <View
          style={{
            height: 32,
            width: 32,
            borderRadius: 16,
            position: 'absolute',
            bottom: 32,
            right: 16,
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: '#F4F6F8',
          }}
        />
      </View>
    );
  };

  pillsAndImageName = () => {
    return (
      <View
        style={{
          width: Utility.getScreenWidth(),
          backgroundColor: colors.background,
          alignSelf: 'center',
          paddingHorizontal: 12,
          paddingBottom: 12,
        }}
      >
        <this.headerPlaceholder />
        <this.headerPlaceholderSmall />
        <this.headerPlaceholderTwo />
        <this.headerPlaceholderArray />
      </View>
    );
  };

  render() {
    const styles = sharedStyles;
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: '#f4f6f8',
          paddingTop: 24 + Utility.topInset,
        }}
      >
        <StatusBar
          backgroundColor="#f4f6f8" // white for android
          barStyle="dark-content"
        />
        <>
          <View style={styles.shimmerContainer}>
            <this.pillsAndImageName />
            <this.offerPlaceholder />
            <this.offerPlaceholder />
            <this.offerPlaceholder />
            <this.offerPlaceholder />
          </View>
        </>
      </View>
    );
  }
}

const sharedStyles = StyleSheet.create({
  likeButtonStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addToCartLikeButtonStyles: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderLeftWidth: 2,
    borderTopWidth: 2,
    borderBottomWidth: 2,
    height: 40,
    width: '100%',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderColor: colors.border,
  },
  gradientStyle: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '25%',
  },

  backContainer: {
    position: 'absolute',
    zIndex: 1,
    top: 35,
    left: 16,
  },
  backImage: {
    tintColor: 'white',
    overflow: 'visible',
    elevation: 2,
    shadowRadius: 4,
    shadowColor: colors.black,
    shadowOpacity: 0.6,
    shadowOffset: { width: 1, height: 2 },
  },
  staticNavigationHeaderContainer: {
    height: 70,
    width: '100%',
    overflow: 'visible',
    zIndex: 1,
    flexDirection: 'row',
    elevation: 2,
    backgroundColor: colors.white,
  },
  divider: {
    position: 'absolute',
    bottom: 0,
    height: 1,
    backgroundColor: colors.border,
    width: '100%',
  },

  navbarIconDefaultStyle: {
    width: 20,
    height: 17.5,
    resizeMode: 'contain',
  },
  pageHeaderText: {
    position: 'absolute',
    fontFamily: 'Roboto-Regular',
    fontSize: 16,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    left: 48,
    bottom: Utility.isIOS() ? 13 : 18,
    right: 40,
    overflow: 'hidden',
  },
  shimmerContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.background,
  },
  itemPlaceholder: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginLeft: 12,
    marginRight: 16,
    width: Utility.getScreenWidth() - 2 * Utility.largePadding,
  },

  railPlaceholderShimmerContainer: {
    height: 208,
    width: 146,
    margin: 5,
    marginTop: 24,
    borderRadius: 4,
    backgroundColor: colors.white,
    justifyContent: 'flex-end',
  },

  railPlaceholderShimmerContainerInModal: {
    height: 100,
    width: 146,
    margin: 5,
    marginTop: 12,
    borderRadius: 4,
    backgroundColor: colors.white,
  },

  contentPlaceholderShimmerContainer: {
    height: 132,
    width: 150,
    margin: 5,
    marginTop: 24,
    borderRadius: 4,
    backgroundColor: colors.white,
  },
  cntentImagePlaceholder: {
    width: 150,
    height: 132 - 52,
    borderRadius: 4,
  },
  contentNamePlaceholder: {
    margin: 5,
    width: 140,
    height: 12,
    marginTop: 8,
  },
  contentArtistImagePlaceholder: {
    position: 'absolute',
    left: 5,
    bottom: 5,
    height: 18,
    width: 18,
    borderRadius: 9,
  },
  contentArtistNamePlaceholder: {
    position: 'absolute',
    bottom: 5,
    height: 18,
    width: 70,
    left: 28,
  },
  productTextPlaceholder: {
    margin: 5,
    width: 136,
    height: 12,
  },
  productBrandNamePlaceholder: {
    margin: 5,
    width: 80,
    height: 12,
  },
  headerTextShimmerStyle: {
    height: 18,
    width: Utility.getScreenWidth() / 2,
    borderRadius: 4,
  },
  headerTextShimmerStyleOffer: {
    height: 18,
    width: 145,
    borderRadius: 4,
  },
  headerTextShimmerSmallStyle: {
    height: 18,
    width: Utility.getScreenWidth() / 3,
    borderRadius: 4,
    marginTop: 8,
  },
  headerTextShimmerExtraSmallStyle: {
    height: 18,
    width: Utility.getScreenWidth() / 4,
    borderRadius: 4,
    marginTop: 24,
  },
  headerTextShimmerExtraSmallTwoStyle: {
    height: 20,
    width: 88,
    borderRadius: 4,
    marginTop: 30,
    marginRight: 12,
  },
  headerTextShimmerArrayStyle: {
    height: 48,
    width: 48,
    borderRadius: 12,

    marginRight: 8,
    marginTop: 30,
  },
  navigationHeader: {
    position: 'absolute',
    height: 44,
    width: Utility.getScreenWidth(),
    overflow: 'visible',
    backgroundColor: 'white',
    zIndex: 1,
    flexDirection: 'row',
    elevation: 2,
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
});

export default ScreenPlaceholder;
