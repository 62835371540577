// Dependencies
import React, { PureComponent } from 'react';
import {
  View,
  FlatList,
  Text,
  Switch,
  TouchableOpacity,
  Image,
  Animated,
  StyleSheet,
} from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import PropTypes from 'prop-types';
// Components
import { shuffle } from 'lodash';
import { Header } from '../../header';
import { ORIENTATION, LAYOUT } from '../../../config/Constants';
import { ShortBanner } from '../../banner';
import Utility from '../../../utils/Utility';
import { withMaybe } from '../../../lib/Monads';
import styles from './styles';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import colors from '../../../theme/Colors';

import { AnalyticsManager } from '../../../analytics';
import Images from '../../../theme/Images';
import GridScreenPlaceHolder from '../../shared/GridScreenPlaceHolder';

import images from '../../../theme/Images';
import AppConfig from '../../../config/AppConfig';
import { Viewport } from '../../../libraries/Viewport';
import { getListRefHelper } from '../../../containers/List/listRefHelper';
import { restructureVerticalItemForOffers } from '../../../utils/ArrayUtility';
import { isDesktop } from '../../../utils/BooleanUtility';

class Vertical extends PureComponent {
  constructor(props) {
    super(props);
    const { previousScreen, stickyPosition, item } = this.props;
    this.refreshing = false;
    this.state = {
      forceRefresh: false,
      // hideActivityIndicator: false,
      scrollY: new Animated.Value(0),
      hideHeaderForNoCoupons: false,
    };

    this.viewedItemsIds = [];
    this.refArray = [];
    this.viewabilityConfig = {
      // waitForInteraction: true,
      itemVisiblePercentThreshold: 0,
    };
    this.index = 0;
    AnalyticsManager.setCurrentScreen(previousScreen);

    this.stickyHeaderIndices = [stickyPosition];
    this.refreshing = false;

    this.onTimerFinishRef = [];
    this.onTimerFinishRefFn = [];
    item.forEach(this.onTimerFinishFunctions);
  }

  componentDidMount() {
    const { listData } = this.props;
    if (Utility.isPresent(listData) && !Utility.isBlank(listData.objects)) {
      setTimeout(this.fireListRenderedEvent, 3000);
    }
  }

  componentDidUpdate(prevProps) {
    const { navigation, listData, setForceRefreshHomePage, previousScreen } =
      this.props;

    if (
      JSON.stringify(prevProps?.listData?.objects) !==
      JSON.stringify(listData?.objects)
    ) {
      setTimeout(this.fireListRenderedEvent, 3000);
    }

    if (previousScreen === SCREEN_CONSTANTS.FEED && setForceRefreshHomePage) {
      if (typeof setForceRefreshHomePage === 'function') {
        setForceRefreshHomePage(this.onRefresh);
      }
    }
  }

  onTimerFinishFunctions = (value, index) => {
    this.onTimerFinishRefFn[value.id] = (ref) => {
      this.onTimerFinishRef[value.id] = ref;
    };
  };

  fireListRenderedEvent = () => {
    const { listData, previousScreen } = this.props;
    Utility.fireListRenderedEvent(listData, previousScreen);
  };

  getItemData = (propsItem) => {
    const { search } = this.props;
    if (search) {
      return propsItem;
    }
    return propsItem;
  };

  isMediaRail = (item) =>
    item.content === 'media' && item.display === LAYOUT.RAIL;

  isProductRail = (item) =>
    item.content === 'product' &&
    (item.display === LAYOUT.RAIL || item.display === 'vertical-rail');

  isVideoCard = (item) => item.type === 'video' && item.display === LAYOUT.CARD;

  shuffleSets = (propsItem) => {
    this.itemData = propsItem;
    const mediaItems = shuffle(this.itemData.filter(this.isMediaRail));
    const productItems = shuffle(this.itemData.filter(this.isProductRail));
    const videoCards = shuffle(this.itemData.filter(this.isVideoCard));
    this.itemData.map((item, index) => {
      if (this.isMediaRail(item)) {
        this.itemData[index] = mediaItems.pop();
      } else if (this.isProductRail(item)) {
        this.itemData[index] = productItems.pop();
      } else if (this.isVideoCard(item)) {
        this.itemData[index] = videoCards.pop();
      }
    });
  };

  onTimerFinish = (item) => {
    this.onTimerFinishRef[item.id]?.onTimerFinish();
  };

  listConditionFn = (props) => props.layout === LAYOUT.LIST;

  showBanner = withMaybe((props) => props.showBanner !== true)(ShortBanner);

  setHeaderForCoupons = (hideHeader) => {
    this.setState({
      hideHeaderForNoCoupons: hideHeader,
    });
  };

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }

    const { getVerticalComponent } = getListRefHelper();
    const ContainerComponent = getVerticalComponent(item.type);
    if (ContainerComponent === null || ContainerComponent === undefined) {
      return null;
    }

    const {
      navigation,
      feed,
      iconName,
      showCustomIcon,
      showColorComponent,
      onPress,
      preventReduxFetch = false,
      toggleCartVisibility,
      previousScreen = '',
      allVideosId,
      search = false,
      searchQuery,
      elementItemCounts,
      updateCurrentlyPlayingIndex,
      currentlyPlayingIndex,
      shuffle,
      showToast,
      showMoreButton,
      checkVisible,
      showFreeProductScreen,
      paginationResult,
      isSearchResultScreen,
      productAndBrandsOnlySwitchValue,
      paginateLastItem,
      selectedFilters,
      quickFiltersRef,
      onFiltersPress,
      getQuickFiltersListRef,
      filtersEnabled,
      onPressResetButton,
      randomTimeStamp,
      totalItemsInList,
      filteredSlug,
      showDynamicListTitle,
      brandId,
      categoryIds = [],
      extraEventParameters = {},
      useInViewPort,
      hideOosProduct,
      parentListsData,
      listData = {},
      verticalComponentWidth,
    } = this.props;
    const { scrollY, hideHeaderForNoCoupons } = this.state;
    if (
      previousScreen === SCREEN_CONSTANTS.SEARCH &&
      (item.content === 'artist' || item.content === 'media') &&
      productAndBrandsOnlySwitchValue
    ) {
      return null;
    }
    let extraProps = {};
    let extraHeaderProps = {};
    if (
      search &&
      (item.type === 'quick_filters' ||
        (item.content === 'product' && Utility.isPresent(item.quick_filters)))
    ) {
      extraProps = { scrollY };
      extraHeaderProps = { hideHeader: true };
    }

    let backgroundColor = Utility.isPresent(item?.background_color)
      ? item?.background_color
      : colors.background;

    if (
      item?.type === 'feature' &&
      (item?.display === 'selfie' || item?.display === 'onboarding_card')
    ) {
      backgroundColor = colors.background;
    }
    if (item?.type === 'feature' && item?.display === 'overflow') {
      backgroundColor = colors.white;
    }
    if (item?.display === 'staggered_grid' && item?.objects?.length < 2) {
      return null;
    }
    const hideHeaderForWishlistRailProducts =
      previousScreen === SCREEN_CONSTANTS.WISH_DEALS &&
      item?.display === 'hero_rail' &&
      Utility.isBlank(Utility.getStockedItems(item?.objects));
    if (
      hideHeaderForWishlistRailProducts ||
      (item?.display === 'coupon' && hideHeaderForNoCoupons)
    ) {
      return null;
    }

    const { options = {}, display = '', content = '' } = item;
    const itemOptions = Utility.isBlank(options) ? {} : options;

    const { show_timer_in_subtitle = false, counter_max_value } = itemOptions;

    const isProductOrVariantRail = display === 'rail' && content === 'product';

    const showFooter =
      item?.content === 'routine' &&
      item?.display === 'list' &&
      !isProductOrVariantRail;
    const itemData = item.is_dynamic ? undefined : item;
    const slug = item.is_dynamic ? item.slug : undefined;
    const args = item?.arguments;
    let hideHeader = item.is_dynamic ? !showDynamicListTitle : false;
    const showDynamicListShimmer = item.is_dynamic && Utility.isBlank(itemData);
    if (
      item.type === 'list' &&
      item.content === 'product' &&
      Utility.isPresent(item.quick_filters)
    ) {
      hideHeader = true;
    }

    const isNotDynamicListAndFancyRailItem = !(
      item.is_dynamic && item.display === 'fancy_rail'
    );

    return (
      <View
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        {isNotDynamicListAndFancyRailItem && (
          <Header
            item={item}
            navigation={navigation}
            layout={item.display}
            feed={feed}
            previousScreen={previousScreen}
            showMoreButton={showMoreButton}
            index={index}
            isSearchResultScreen={isSearchResultScreen}
            searchQuery={searchQuery}
            productAndBrandsOnlySwitchValue={productAndBrandsOnlySwitchValue}
            onTimerFinish={this.onTimerFinish}
            extraEventParameters={extraEventParameters}
            {...extraHeaderProps}
            hideHeader={hideHeader}
            // isVisible = {item.type !== 'quick_filters'}
          />
        )}
        <ContainerComponent
          onRef={this.onTimerFinishRefFn[item.id]}
          navigation={navigation}
          itemData={itemData}
          slug={slug}
          args={args}
          ignoreMinCount={item?.is_dynamic}
          id={item.id}
          type={item.type}
          size={item.size}
          orientation={ORIENTATION.VERTICAL}
          layout={item.display}
          index={index}
          feed={feed}
          iconName={iconName}
          showCustomIcon={showCustomIcon}
          showColorComponent={showColorComponent}
          addedProducts={this.props.addedProducts}
          onPress={onPress}
          preventReduxFetch={preventReduxFetch}
          toggleCartVisibility={toggleCartVisibility}
          previousScreen={previousScreen}
          allVideosId={allVideosId}
          search={search}
          searchQuery={searchQuery}
          elementItemCounts={elementItemCounts}
          updateCurrentlyPlayingIndex={updateCurrentlyPlayingIndex}
          currentlyPlayingIndex={currentlyPlayingIndex}
          shuffle={shuffle}
          listName={item.name}
          listContent={item.content}
          showToast={showToast}
          itemIndex={0}
          listIndex={index}
          checkVisible={checkVisible}
          showFreeProductScreen={showFreeProductScreen}
          paginationResult={paginationResult}
          isSearchResultScreen={isSearchResultScreen}
          productAndBrandsOnlySwitchValue={productAndBrandsOnlySwitchValue}
          randomTimeStamp={randomTimeStamp}
          hideActivityIndicator={this.hideActivityIndicator}
          // abc={() => {}} // TODO: dirty hack to rerender list.js
          selectedFilters={selectedFilters}
          quickFiltersRef={quickFiltersRef}
          onFiltersPress={onFiltersPress}
          getQuickFiltersListRef={getQuickFiltersListRef}
          filtersEnabled={filtersEnabled}
          onPressResetButton={onPressResetButton}
          paginateLastItem={paginateLastItem}
          totalItemsInList={totalItemsInList}
          filteredSlug={filteredSlug}
          setHeaderForCoupons={this.setHeaderForCoupons}
          brandId={brandId}
          extraEventParameters={extraEventParameters}
          categoryIds={categoryIds}
          useInViewPort={useInViewPort}
          hideOosProduct={hideOosProduct}
          showDynamicListShimmer={showDynamicListShimmer}
          parentListsData={parentListsData}
          listData={listData}
          {...extraProps}
          avoidFlashList
          extraHeaderProps={extraHeaderProps}
          isHeaderVisible={item.type !== 'quick_filters'}
          hideHeader={hideHeader}
          verticalComponentWidth={verticalComponentWidth}
        />

        {showFooter && <this.footerMoreView />}
      </View>
    );
  };

  onRefresh = () => {
    const { onRefresh, previousScreen } = this.props;

    if (onRefresh !== undefined) {
      onRefresh();
      this.refreshing = true;
      // this.πceRefresh: true });
    }
  };

  checkForRefreshing = () => {
    const { refreshing } = this.props;
    const { forceRefresh } = this.state;
    if (forceRefresh) {
      this.refreshing = refreshing;
    } else {
      this.refreshing = refreshing && this.refreshing;
    }
  };

  searchHeaderComponent = () => {
    const { previousScreen, productAndBrandsOnlySwitchValue, toggleSwitch } =
      this.props;
    if (
      Utility.isBlank(previousScreen) ||
      AppConfig.getBooleanValue(Config.DISABLE_PRODUCTS_AND_BRANDS_ONLY_SEARCH)
    ) {
      return null;
    }
    if (previousScreen !== SCREEN_CONSTANTS.SEARCH) {
      return null;
    }
    return (
      <View style={styles.searchHeaderContainer}>
        <Text style={styles.searchHeaderSwitchText}>
          Products and Brands only
        </Text>
        <Switch
          style={styles.searchHeaderSwitch}
          thumbTintColor={Utility.isIOS() ? null : 'rgba(145, 211, 69)'}
          trackColor={
            Utility.isIOS()
              ? null
              : {
                  true: 'rgba(145, 211, 69)',
                  false: 'rgba(0, 0, 0, 0.2)',
                }
          }
          onValueChange={toggleSwitch}
          tintColor={Utility.isIOS() ? 'rgba(145, 211, 69)' : null}
          onTintColor={Utility.isIOS() ? 'rgba(145, 211, 69)' : null}
          value={productAndBrandsOnlySwitchValue}
        />
      </View>
    );
  };

  refetchFeed = () => {
    const { refetchFeed } = this.props;
    refetchFeed();
  };

  hideActivityIndicator = () => {
    this.setState({ hideActivityIndicator: true });
  };

  onMoreTap = () => {
    const { navigation } = this.props;

    navigation.navigate('Routines', {});
  };

  footerMoreView = () => {
    return (
      <View style={styles.footerMoreViewContainer}>
        <TouchableOpacity onPress={() => this.onMoreTap()}>
          <View style={styles.footerMoreViewDivider} />
          <View style={styles.footerMoreViewContent}>
            <Text style={styles.footerMoreViewCTAText}>See all routines</Text>
            <Image
              source={images.rightBlueChevron}
              style={styles.footerChevronImage}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  renderFooter = () => {
    // it will show indicator at the bottom of the list when data is loading otherwise it returns null
    const {
      loadMore,
      feedLoadFailed,
      navigateToSearch,
      previousScreen,
      showFooterShimmer,
    } = this.props;

    if (
      previousScreen === SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE ||
      previousScreen === SCREEN_CONSTANTS.MEMBER_LANDING_PAGE
    )
      return null;

    if (feedLoadFailed) {
      return (
        <View style={styles.feedFooterContainer}>
          <TouchableOpacity onPress={() => this.refetchFeed()}>
            <View style={styles.footerTextAndImageContainer}>
              <Text style={styles.footerButtonText}>Load More</Text>
              <Image
                style={styles.footerChevronImage}
                source={Images.chevronDown}
              />
            </View>
          </TouchableOpacity>
        </View>
      );
    }

    if (loadMore || showFooterShimmer) {
      return <GridScreenPlaceHolder columnCount={1} />;
    }

    if (previousScreen === SCREEN_CONSTANTS.PRODUCT_DETAIL) {
      return null;
    }

    return <View style={styles.footerBottom} />;
  };

  keyExtractor = (item, index) => `${item.type}_${item.id}_${index}_vertical`;

  renderItem = ({ item, index }) => this.getComponent(item, index);

  createListRef = (ref) => {
    const { setListRef } = this.props;
    if (setListRef) {
      setListRef(ref);
    }
  };

  setScrollYToBrandDetail = (nativeEvent) => {
    const { setScrollYToNavigationHeader } = this.props;
    if (!setScrollYToNavigationHeader) {
      return;
    }

    setScrollYToNavigationHeader(nativeEvent);
  };

  onScrollToIndexFailed = () => {
    const { onScrollToIndexFailed } = this.props;
    if (onScrollToIndexFailed && typeof onScrollToIndexFailed === 'function') {
      onScrollToIndexFailed();
    }
  };

  getItemLayout = (item, index) => {
    const { componentOffsetList = {} } = this.props;
    return { ...componentOffsetList[index], index };
  };

  onEndReached = () => {
    const { endReached = () => {}, previousScreen } = this.props;
    if (previousScreen === SCREEN_CONSTANTS.SEARCH) endReached();
  };

  render() {
    const { scrollY } = this.state;
    const { stickyPosition, item, refreshing, verticalComponentWidth } = this.props;

    const isRefreshing = refreshing ?? this.refreshing;
    const filteredItem = restructureVerticalItemForOffers(item);
    const flatListStyles = [styles.listContainer, verticalComponentWidth && { width: verticalComponentWidth }]
    return (
      <View style={styles.container}>
        <Viewport.Tracker>
          <FlatList
            ref={this.createListRef}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { y: scrollY } } }],
              { listener: this.setScrollYToBrandDetail },
            )}
            horizontal={false}
            data={filteredItem}
            onRefresh={this.onRefresh}
            refreshing={isRefreshing}
            renderItem={this.renderItem}
            keyExtractor={this.keyExtractor}
            style={flatListStyles}
            showsVerticalScrollIndicator={false}
            initialNumToRender={15}
            ListHeaderComponent={
              this.props.verticalListHeader || this.searchHeaderComponent
            }
            ListFooterComponent={this.renderFooter}
            stickyHeaderIndices={this.stickyHeaderIndices}
            // extraData={this.props.item}
            stickyHeaderIndices={[stickyPosition]}
            removeClippedSubviews={false}
            onScrollToIndexFailed={this.onScrollToIndexFailed}
            getItemLayout={isDesktop() ? undefined : this.getItemLayout}
            onEndReached={this.onEndReached}
            // decelerationRate={0.98}
          />
        </Viewport.Tracker>
      </View>
    );
  }
}

// PropTypes
Vertical.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  onRefresh: PropTypes.func,
  refreshing: PropTypes.bool,
};

Vertical.defaultProps = {
  item: [],
  onRefresh: () => {},
  refreshing: false,
};

export default Vertical;
