import React from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Utility from '../../utils/Utility';
import ProductRatingsHeader from './ProductRatingsHeader';
import ProductReviewItems from './ProductReviewItems';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  footer: {
    height: Utility.getScreenWidth(),
    backgroundColor: colors.background,
    '@media (min-width: 768px)': {
      height: 300,
    },
  },
  header: {
    height: 8,
    backgroundColor: colors.background,
  },
});

const AllReviews = ({
  ratingData,
  rating,
  ratings_count,
  reviews_count,
  onEndReached,
  productSlug,
  productId,
  likeRating,
  unlikeRating,
  navigation,
  variants,
  itemData,
}) => {
  const ListFooterComponent = () => {
    return <View style={styles.footer} dataSet={{ media: ids.footer }} />;
  };

  const ListHeaderComponent = () => {
    return <View style={styles.header} />;
  };

  return (
    <View style={styles.container}>
      <ProductRatingsHeader
        reviewData={ratingData}
        reviewsCount={reviews_count}
        ratingsCount={ratings_count}
        rating={rating}
      />

      <ProductReviewItems
        reviewData={ratingData}
        onEndReached={onEndReached}
        productSlug={productSlug}
        productId={productId}
        likeRating={likeRating}
        unlikeRating={unlikeRating}
        allReviews
        navigation={navigation}
        variants={variants}
        itemData={itemData}
        listFooterComponent={ListFooterComponent}
        listHeaderComponent={ListHeaderComponent}
      />
    </View>
  );
};

export default AllReviews;
