import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop } from '../../utils/BooleanUtility';
import { getCategorySlugParams, getDisplayNameFromSlugSubstring } from '../../utils/StringUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

export default function ProductBreadcrumb({ productCategorySlug = '' }) {
  if (!productCategorySlug || !isDesktop()) return null;
  const categories = getCategorySlugParams(`/${productCategorySlug}`)
  return (
    <View style={styles.container}>
      <AppTitleBreadCrumb />
      {categories.map((category, index) => (
        <CategoryBreadCrumb
          category={category}
          categories={categories}
          index={index}
        />
      ))}
    </View>
  );
}

const AppTitleBreadCrumb = () => {
  const navigation = useNavigation();
  const handlePress = () => navigation.navigate('Feed');
  return (
    <TouchableOpacity onPress={handlePress}>
      <Text style={styles.text}>Foxy</Text>
    </TouchableOpacity>
  );
};

const CategoryBreadCrumb = ({ category, categories = [], index }) => {
  const navigation = useNavigation();
  const [first, second, third] = categories;
  const params = {};
  if (index === 0) params.first = first;
  if (index === 1) {
    params.first = first;
    params.second = second;
  }
  if (index === 2) {
    params.first = first;
    params.second = second;
    params.third = third;
  }
  const handlePress = () => navigateToScreen({
    navigation,
    type: 'push',
    screen: 'Category',
    params,
  });
  return (
    <>
      <Text style={styles.text}> / </Text>
      <TouchableOpacity key={category} onPress={handlePress}>
        <Text style={styles.text}>{getDisplayNameFromSlugSubstring(category)}</Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    width: getScreenWidth(),
    alignSelf: 'center',
    paddingBottom: 12,
    paddingTop: 3,
    marginTop: 30,
    flexDirection: 'row',
  },
  text: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 14,
  },
});
