import React, { Component } from 'react';
import {
  View,
  Text,
  BackHandler,
  TouchableHighlight,
  Alert,
  PermissionsAndroid,
  Linking,
  ActivityIndicator,
  SafeAreaView,
  Image,
  StatusBar,
  AppState,
} from 'react-native';
import colors from '../../../theme/Colors';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { PERMISSION_STORE, PERMISSIONS } from '../../../config/Constants';
import DynamicLinkManager from '../../../utils/DynamicLinkManager';
import styles from './styles';
import FoxyIcon from '../../../lib/FoxyIcon';
import { Theme } from '../../../theme/Colors';
import images from '../../../theme/Images';
import { uploadScannedImage } from '../../../actions/UploadPostActions';
import withNavigationFocus from '../../../utils/WithNavigationFocus';
import Utility from '../../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import CameraUtils from '../../../utils/CameraUtils';
import {
  fetchProduct,
  applyCartCoupon,
  addToCart,
  getListDetails,
} from '../../../actions/ActionTypes';
import DynamicLinkUtilities from '../../../utils/DynamicLinkUtilities';
import FoxyAlert from '../../../components/camera/shared/FoxyAlert';
import AddProductFromScan from './AddProductFromScan';
import {
  AnalyticsEvent,
  AnalyticsManager,
  AnalyticsUtilty,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../../analytics';
import { navigateToScreen } from '../../../utils/NavigationUtility';

class ScanProduct extends Component {
  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    const { route } = props;
    this.currentContentItemIndex = route.params?.currentContentItemIndex;
    this.isPreviousScreenSearch = route.params?.isPreviousScreenSearch;
    this.debounceItemScanEvent = _.debounce(this.fireItemScanEvent, 500, {
      leading: true,
      trailing: false,
    });
    this.product_type = '';
    this.isScanCompleted = false;
    this.link = '';
    this.message = '';
    this.imageLink = '';
    this.productData = {};
    this.title = '';
    this.state = {
      turnOnFlash: false,
      cameraPermission: false,
      isQRCodeValid: true,
      isImageUploading: false,
      isLaunched: Utility.isAndroid() ? true : false,
      isCartVisible: false,
      isProductModalVisible: false,
      showAlert: false,
      showExternalLinkAlert: false,
      loaderViewText: '',
    };
    // this.setTimer();
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribeFocus = navigation.addListener('focus', () => {
      this.onDidFocus();
    });
  }

  // onDidFocus = () => {
  //   AnalyticsManager.logEvent(EventType.discoveryEvents.OFFERS_VIEW);
  // };

  UNSAFE_componentWillMount() {
    if (Utility.isAndroid()) {
      BackHandler.addEventListener(
        'hardwareBackPress',
        this.handleBackButtonClick,
      );
    }
    Utility.checkForPermissions(PERMISSION_STORE.camera, true);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      BackHandler.removeEventListener(
        'hardwareBackPress',
        this.handleBackButtonClick,
      );
    }
    this.unsubscribeFocus();
  }

  handleBackButtonClick = () => {
    console.log('handle back button called');

    this.goBackToPreviousScreen();
  };

  componentDidMount = () => {
    const { navigation } = this.props;
    // const timer = setTimeout(() => {
    //   this.requestCameraPermission();
    //   clearTimeout(timer);
    // }, 1000);
    // this.openAppSettings();
    this.setStatusBar();
    // Utility.checkPermissionIsGiven((response) => {
    //   if (response) {
    //     console.log('Permission response is true >>>>>>>>>>>>>>>>>');
    //     this.setState({
    //       cameraPermission: true,
    //       isLaunched: false,
    //     });
    //   } else {
    //     console.log('Permission response is false >>>>>>>>>>>>>>>>>');
    //     this.setState({
    //       isLaunched: true,
    //     });
    //   }
    // });
    // if (
    //   !Utility.permissionsGrantedForScan &&
    //   !Utility.showCameraPermissionScreen &&
    //   Utility.setNeverAskAgainPermission
    // ) {
    //   Alert.alert(
    //     'Permission Required',
    //     'Change Access permission inside Settings to allow photo access',
    //     [
    //       {
    //         text: 'Open Settings',
    //         onPress: this.openAppSettings,
    //       },
    //       {
    //         text: 'Cancel',
    //         onPress: () => navigation.goBack(),
    //         style: 'cancel',
    //       },
    //     ],
    //   );
    // }
    AnalyticsManager.logEvent(EventType.camera.CAMERA_CLICK);
  };

  setStatusBar = () => {
    StatusBar.setTranslucent(false);
    StatusBar.setBackgroundColor('#000000');
    StatusBar.setBarStyle('light-content');
  };

  onDidFocus = () => {
    console.log('On did focus >>>>>>>');

    this.isScanCompleted = false;
    this.setStatusBar();

    BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackButtonClick,
    );
  };

  fireItemScanEvent = (type, detail) => {
    AnalyticsManager.logEvent(EventType.camera.ITEM_SCAN, {
      [EventParameterKey.TYPE]: type,
      item_Detail: detail,
    });
  };

  topSection = (props) => (
    <SafeAreaView style={styles.topSection}>
      <FoxyIcon
        icon={images.addProductsScreenIcon.closeIcon}
        backgroundColor={Theme.addProducts.topSection.iconBackground}
        tintColor={Theme.addProducts.topSection.iconTintColor}
        onPress={this.goBackToPreviousScreen}
      />
      <Text style={styles.containerTitle}>Scan</Text>
      <FoxyIcon
        icon={
          props.flashOn
            ? images.addProductsScreenIcon.scanFlash.on
            : images.addProductsScreenIcon.scanFlash.off
        }
        backgroundColor={Theme.addProducts.topSection.iconBackground}
        tintColor={Theme.addProducts.topSection.iconTintColor}
        onPress={this.turnFlashOn}
      />
    </SafeAreaView>
  );

  turnFlashOn = () => {
    const { turnOnFlash } = this.state;
    const turnOn = !turnOnFlash;
    if (turnOn) {
      AnalyticsManager.logEvent(EventType.camera.FLASH_ON);
    } else {
      AnalyticsManager.logEvent(EventType.camera.FLASH_OFF);
    }
    this.setState({ turnOnFlash: turnOn });
    this.camera.onSetTorch();
  };

  removeInvalidQRCodeMessage = () => {
    this.setState({ isQRCodeValid: true });
  };

  navigateToScreen = (route, slug, path, extra = {}) => {
    const { navigation, applyCartCoupon, addToCart, getListDetails } =
      this.props;

    console.log(
      'Scan product navigation Handle link Url ',
      path,
      route,
      slug,
      extra,
    );

    if (extra.externalActionRequired) {
      if (route === 'ContentModal') {
        const { listId, videoId } = extra;
        this.getVideoListDetailById(listId, videoId);
      }
    } else if (route === 'Cart') {
      this.toggleCartVisibility();
    } else if (path === 'apply_coupon') {
      const couponCode = extra.promoCodeThroughDynamicLink;
      console.log('entered in apply_coupon condition for coupon', couponCode);
      applyCartCoupon(couponCode, (success) => {
        if (success) {
          this.toggleCartVisibility();
        } else {
          this.message = `${couponCode} is already applied or not valid for this user.`;
          this.title = 'Coupon error.';
          this.setState({ showAlert: true });
        }
      });
    } else if (route === 'AddToBag') {
      addToCart(
        DynamicLinkUtilities.generateCartDataFromSlug(slug),
        (response) => {
          if (!Utility.isBlank(response.errors)) {
            this.message = `${response.errors[0]}`;
            this.title = 'Error';
            this.setState({ showAlert: true });
          } else {
            this.productData = response;
            this.setState({ isProductModalVisible: true });
          }
        },
      );
    } else if (route === 'WebUrlView') {
      this.setState({ isQRCodeValid: false });
    } else if (path === 'products' && extra !== {}) {
      const { variant } = extra;
      navigation.navigate(route, {
        slug,
        fromScan: true,
        variantID: variant,
        display: 'screen',
      });
      BackHandler.removeEventListener(
        'hardwareBackPress',
        this.handleBackButtonClick,
      );
    } else {
      navigation.navigate(route, { slug, fromScan: true, display: 'screen' });
      BackHandler.removeEventListener(
        'hardwareBackPress',
        this.handleBackButtonClick,
      );
    }
  };

  getVideoListDetailById = (listId, videoId) => {
    const { getListDetails, navigation } = this.props;
    getListDetails(listId, (success, response) => {
      const selectedVideoIndex = Utility.deriveIndexFromListObject(
        response.objects,
        videoId,
      );
      navigation.navigate('ContentModal', {
        listId,
        index: Utility.deriveIndexFromListObject(response.objects, videoId),
        itemData: response.objects[selectedVideoIndex],
        listData: response,
      });
    });
  };

  toggleCartVisibility = () => {
    this.props.navigation.push('Cart');
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  onBarcodeScan = (code) => {
    if (this.isScanCompleted === true) {
      return;
    }
    this.isScanCompleted = true;
    if (code.startsWith('http')) {
      this.debounceItemScanEvent('qr_code', code);
      if (code.includes('foxy')) {
        AnalyticsManager.logEvent('scan_success');
        DynamicLinkManager.handleLinkWithInternalTrackingParams(
          code,
          this.navigateToScreen,
        );
      } else {
        AnalyticsManager.logEvent('scan_failure', {
          item_type: 'qr code',
          error: 'External URL detected',
        });
        this.message = `${code} This will not open in Foxy App and redirect you to browser.`;
        this.title = 'External URL detected';
        this.link = code;
        this.setState({ showExternalLinkAlert: true });
      }
    } else if (!Utility.validateQRCode(code)) {
      AnalyticsManager.logEvent('scan_failure', {
        item_type: 'qr code',
        error: 'invalid qr code',
      });
      this.setState({ isQRCodeValid: false });
      this.isScanCompleted = false;
    } else {
      this.debounceItemScanEvent('bar_code', code);
      const link = `/api/v1/products/${code}`;
      this.setState({
        isImageUploading: true,
        loaderViewText: 'Matching Products..',
      });
      this.product_type = 'barcode';
      this.callProductApi(link);
    }
  };

  cancelClicked = () => {
    console.log('----========== Cancel Clicked');
    this.isScanCompleted = false;
    this.setState((prevState) => ({
      showExternalLinkAlert: !prevState.showExternalLinkAlert,
    }));
  };

  openExternalLink = () => {
    Linking.openURL(this.link);
    const timer = setTimeout(() => {
      console.log('----========== openExternal Link');
      this.isScanCompleted = false;
      this.setState((prevState) => ({
        showExternalLinkAlert: !prevState.showExternalLinkAlert,
      }));
      clearTimeout(timer);
    }, 1000);
  };

  goBackToPreviousScreen = () => {
    const { navigation, route } = this.props;
    AnalyticsManager.logEvent(EventType.camera.CAMERA_EXIT);
    this.previousScreen = route.params?.previousScreen ?? '';
    if (this.previousScreen === SCREEN_CONSTANTS.COLLECTION) {
      navigation.navigate('MyCollection');
    } else {
      navigation.goBack();
    }
  };

  onBottomButtonPressed = ({ captureImages }) => {
    const { uploadScannedImage } = this.props;
    if (!captureImages[0]) {
      return;
    }
    AnalyticsManager.logEvent(EventType.camera.PHOTO_CLICK);
    const { uri } = captureImages[captureImages.length - 1];
    this.product_type = 'image';
    this.setState({ isImageUploading: true });

    AnalyticsManager.logEvent('media_upload_start', {
      media_type: 'image',
      file_count: 1,
    });

    CameraUtils.resizeImage(uri)
      .then((imgUri) => {
        this.imageLink = imgUri;
        this.setState({
          isImageUploading: true,
          loaderViewText: 'Uploading Image...',
        });
        uploadScannedImage(imgUri, (response) => {
          if (!Utility.isBlank(response)) {
            this.setState({ loaderViewText: 'Matching Products..' });
            this.callProductApi(
              `/api/v1/product_pictures/${response.product_picture_id}`,
            );
            AnalyticsManager.logEvent('media_upload_finish', {
              media_type: 'image',
              file_count: 1,
            });
          } else {
            this.imageLink = '';
            AnalyticsManager.logEvent('media_upload_fail', {
              media_type: 'image',
              file_count: 1,
            });
            this.message = 'Unable to upload image';
            this.title = 'Connection Error';
            this.setState({ isImageUploading: false, showAlert: true });
          }
        });
      })
      .catch((error) => console.log('error while resize', error));
  };

  callProductApi = (slug) => {
    const { fetchProduct } = this.props;
    fetchProduct(slug, this.fetchProductCallback);
  };

  fetchProductCallback = (success, data) => {
    const { navigation } = this.props;
    if (success) {
      this.setState({ isImageUploading: false });
      AnalyticsManager.logEvent('scan_success');
      navigateToScreen({
        navigation,
        type: 'navigate',
        screen: 'Product',
        params: {
          itemData: data,
          display: 'screen',
          fromScan: true,
          display: 'screen',
        },
      });
      BackHandler.removeEventListener(
        'hardwareBackPress',
        this.handleBackButtonClick,
      );
    } else {
      if (this.product_type === 'image') {
        this.message =
          'Sorry, we could not identify any product from this picture.';
        this.title = 'Product not identified';
        AnalyticsManager.logEvent('scan_failure', {
          item_type: 'image',
          error: 'Product not identified',
        });
      } else {
        this.message =
          'Oops! Product is not available on Foxy for this barcode/QR code.';
        this.title = 'Product not available';
        AnalyticsManager.logEvent('scan_failure', {
          item_type: 'qr code/barcode',
          error: 'Product not available',
        });
      }

      this.setState({ isImageUploading: false, showAlert: true });
    }
    this.imageLink = '';
  };

  toggleSuccessAlert = () => {
    this.setState((prevState) => ({
      showAlert: !prevState.showAlert,
    }));
    console.log('----========== toggle success Alert');
    this.isScanCompleted = false;
  };

  invalidQRCode = () => (
    <TouchableHighlight
      style={styles.submit}
      onPress={this.removeInvalidQRCodeMessage}
      underlayColor="#fff"
    >
      <Text style={styles.submitText}> QR Code not supported X </Text>
    </TouchableHighlight>
  );

  generalMessageToShow = () => (
    <Text style={styles.generalMessageText}>
      Point the camera towards a QR code / barcode or click a picture of
      product.
    </Text>
  );

  loaderView = () => (
    <View style={styles.loaderView}>
      <Image
        source={{ uri: `file://${this.imageLink}` }}
        style={styles.backgroundImage}
      />
      <View style={styles.blurView}>
        <ActivityIndicator size="large" color={colors.white} />
        <Text style={styles.loaderText}>{this.state.loaderViewText}</Text>
      </View>
    </View>
  );

  cameraScanner = () => {
    const height = Utility.getScreenWidth() * 0.55 * 1.7;
    const offset = (Utility.getScreenWidth() * 0.2) / 2;
    const {
      turnOnFlash,
      isQRCodeValid,
      isImageUploading,
      isCartVisible,
      isProductModalVisible,
      showAlert,
      showExternalLinkAlert,
    } = this.state;

    const { navigation } = this.props;
    const startScan =
      !isImageUploading &&
      !isCartVisible &&
      !isProductModalVisible &&
      !showExternalLinkAlert &&
      !showAlert
        ? true
        : false;

    return (
      <View style={{ flex: 1, backgroundColor: 'black' }}>
        {/* <CameraKitCameraScreen
          ref={(cam) => (this.camera = cam)}
          onBottomButtonPressed={(event) => this.onBottomButtonPressed(event)}
          captureButtonImage={images.cameraScreenIcons.productShutter}
          showFrame={startScan}
          heightForScannerFrame={height}
          offsetForScannerFrame={offset}
          scanBarcode={startScan}
          laserColor={'white'}
          frameColor={'white'}
          colorForScannerFrame={'white'}
          cameraOptions={{
            flashMode: turnOnFlash ? 'on' : 'off',
          }}
          onReadCode={(event) =>
            this.onBarcodeScan(event.nativeEvent.codeStringValue)
          }
          buttonStyle={{
            position: 'absolute',
            bottom: 0,
            left: '45%',
          }}
        /> */}
        {/* <this.showScanFrame frameHeight={height} /> */}
        {!isQRCodeValid && <this.invalidQRCode />}
        <this.generalMessageToShow />
        {isImageUploading ? <this.loaderView /> : null}
        <this.topSection flashOn={turnOnFlash} />
        <FoxyAlert
          isVisible={showExternalLinkAlert}
          alertBoxTitle={this.title}
          alertMessage={this.message}
          firstButtonTitle={'Open in browser'}
          firstButtonTextColor={'#4990e2'}
          firstButtonOnPress={this.openExternalLink}
          secondButtonTitle={'Cancel'}
          secondButtonTextColor={'#979baa'}
          secondButtonOnPress={this.cancelClicked}
          textAlignment={'center'}
          height={230}
        />
        <FoxyAlert
          isVisible={showAlert}
          alertBoxTitle={this.title}
          alertMessage={this.message}
          firstButtonTitle={'Scan Again'}
          firstButtonTextColor={'#1467ff'}
          firstButtonOnPress={this.toggleSuccessAlert}
          hideSecondButton={true}
          textAlignment={'center'}
          height={160}
        />
        {isProductModalVisible && (
          <AddProductFromScan
            isVisible={isProductModalVisible}
            title={'Items added to bag'}
            onClose={this.closeAddedProductDetail}
            onButtonPress={this.openCart}
            prodData={this.productData}
          />
        )}
      </View>
    );
  };

  openCart = () => {
    this.closeAddedProductDetail();
    this.toggleCartVisibility();
  };

  closeAddedProductDetail = () => {
    this.setState({ isProductModalVisible: false });
  };

  openAppSettings = () => {
    Linking.openSettings();
  };

  emptyState = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
      [EventParameterKey.MODAL_NAME]: 'camera permission',
    });
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'black',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          style={{
            height: (Utility.getScreenWidth() * 0.8) / 0.66,
            width: Utility.getScreenWidth() * 0.8,
          }}
          source={images.addProductsScreenIcon.permissionBackground}
        />
        <View style={styles.backCover}>
          <Text style={styles.permissionTextHeader}>
            {' '}
            Scan it away on Foxy!
          </Text>
          <Text style={styles.permissionText}>
            {' '}
            Scan a QR code, Barcode or Product. Shop the products, check it’s
            reviews and learn how to use.{' '}
          </Text>
          <TouchableHighlight
            style={styles.permissionButton}
            onPress={() => {
              if (
                // !Utility.permissionsGrantedForScan &&
                // !Utility.showCameraPermissionScreen &&
                Utility.setNeverAskAgainPermission
              ) {
                Alert.alert(
                  'Permission Required',
                  'Change Access permission inside Settings to allow photo access',
                  [
                    {
                      text: 'Open Settings',
                      onPress: this.openAppSettings,
                    },
                    {
                      text: 'Cancel',
                      onPress: () => navigation.goBack(),
                      style: 'cancel',
                    },
                  ],
                );
              }
              // Utility.showCameraPermissionScreen = false;
              this.requestCameraPermission();
              // Utility.checkPermissionIsGiven((response) => {
              //   if (response) {
              //     console.log(
              //       'Permission response is true >>>>>>>>>>>>>>>>>',
              //     );
              //     this.setState({ cameraPermission: true });
              //   } else {
              //     OpenAppSettings.open();
              //   }
              // });
            }}
          >
            <Text style={styles.goToSettingsText}> Start Scanning </Text>
          </TouchableHighlight>
        </View>
        <View
          style={{
            width: Utility.getScreenWidth() - 20,
            height: 84,
            position: 'absolute',
            justifyContent: 'space-between',
            marginLeft: 10,
            marginRight: 10,
            top: 30,
          }}
        >
          <FoxyIcon
            icon={images.addProductsScreenIcon.closeIcon}
            backgroundColor={Theme.addProducts.topSection.iconBackground}
            tintColor={Theme.addProducts.topSection.iconTintColor}
            onPress={this.goBackToPreviousScreen}
          />
        </View>
      </View>
    );
  };

  requestCameraPermission = () => {
    if (Utility.isAndroid()) {
      Utility.checkMultiplePermission(
        [
          PERMISSION_STORE.camera,
          PERMISSION_STORE.read_storage,
          PERMISSION_STORE.write_storage,
        ],
        (response) => {
          this.setState({ isLaunched: false });
          // Utility.showCameraPermissionScreen = false;
          if (
            response['android.permission.CAMERA'] === 'granted' &&
            response['android.permission.READ_EXTERNAL_STORAGE'] ===
              'granted' &&
            response['android.permission.WRITE_EXTERNAL_STORAGE'] === 'granted'
          ) {
            this.setState({ cameraPermission: true });
            AnalyticsManager.logEvent(EventType.camera.CAMERA_OPEN);
            Utility.showCameraPermissionScreen = false;
          } else if (
            response['android.permission.CAMERA'] === 'never_ask_again' &&
            response['android.permission.READ_EXTERNAL_STORAGE'] ===
              'never_ask_again' &&
            response['android.permission.WRITE_EXTERNAL_STORAGE'] ===
              'never_ask_again'
          ) {
            Utility.setNeverAskAgainPermission = true;
            Utility.showCameraPermissionScreen = true;
          } else {
            this.setState({ cameraPermission: false });
            Utility.showCameraPermissionScreen = true;
          }
        },
      );
    } else {
      this.setState({ isLaunched: false });
      this.setState({ cameraPermission: true });
    }
  };

  render() {
    const { cameraPermission, isLaunched } = this.state;
    if (!isLaunched || !Utility.showCameraPermissionScreen) {
      if (cameraPermission || !Utility.showCameraPermissionScreen) {
        return <this.cameraScanner />;
      }
      return <this.emptyState />;
    }
    return <this.emptyState />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      uploadScannedImage,
      fetchProduct,
      applyCartCoupon,
      addToCart,
      getListDetails,
    },
    dispatch,
  ),
});

ScanProduct.propTypes = {
  navigation: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(withNavigationFocus(ScanProduct));
