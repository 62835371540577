import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import { isPresent } from '../../utils/BooleanUtility';
import { getScreenHeight } from '../../utils/LayoutUtility';

export default class FilterResultsEmptyState extends PureComponent {
  render() {
    const {
      onPressResetButton,
      buttonText = 'Clear all filters',
      title = 'No products found',
      subtitle = 'Try removing some filters to get the results',
      children,
      showFilterEmptyState = true,
      areFiltersEnabled = true,
    } = this.props;
    return (
      <>
        {isPresent(children) && !showFilterEmptyState && children}
        {areFiltersEnabled && showFilterEmptyState && (
          <View style={style.container}>
            <Image
              source={images.emptyFilterResults}
              style={style.emptyStateImage}
              resizeMode={'contain'}
            />
            <Text style={style.title}>{title}</Text>
            <Text style={style.subtitle}>{subtitle}</Text>

            <TouchableWithoutFeedback onPress={onPressResetButton}>
              <View style={style.buttonContainer}>
                <Text style={style.buttonText}>{buttonText}</Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        )}
      </>
    );
  }
}

const style = StyleSheet.create({
  container: {
    height: getScreenHeight() / 1.6,
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    alignContent: 'center',
  },
  emptyStateImage: {
    height: 95,
    width: 100,
  },
  title: {
    marginTop: 15,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.foxyBlack,
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.subtitle,
    marginTop: 8,
  },
  buttonContainer: {
    width: 120,
    marginTop: 30,
    borderRadius: 16,
    backgroundColor: colors.black,
    padding: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.white,
  },
});
