// Dependencies
import React, { Component } from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withMaybe } from '../../lib/Monads';
import Card from '../../lib/Card';
import {
  LAYOUT,
  THEME,
  MAX_LINES,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import fontSize from '../../theme/Fonts';
import colors from '../../theme/Colors';
// Components
import { MEDIA } from '../../config/LayoutConstants/ContentMediaConfig';
import MediaCardStyles from './styles';
import images from '../../theme/Images';
import LikeButton from '../shared/LikeButton';
import ScaleAnimate from '../shared/ScaleAnimate';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
  AnalyticsUtilty,
} from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import FastImageView from '../FastImageView';

class DefaultSizeVideoList extends Component {
  static cardLayoutStyle = {
    vertical: {
      cardElevation: 2,
      cardMaxElevation: 2,
      cornerRadius: 4,
      borderWidth: 1,
    },
    horizontal: {
      cardElevation: 0,
      cardMaxElevation: 0,
      cornerRadius: 0,
      overFlow: 'visible',
    },
  };

  constructor(props) {
    super(props);
    this.itemCapturedInViewport = { currentState: '' };
    this.debounceMediaCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
  }

  forRailOrGridLayout = (props) =>
    props.layout == LAYOUT.RAIL || props.layout == LAYOUT.GRID;

  listOrCardCondition = (props) =>
    !(props.layout === LAYOUT.CARD || props.layout === LAYOUT.LIST);

  artistVerifiedCondition = (props) => !props.verified;

  viewsWithCondition = withMaybe(this.listOrCardCondition)(Text);

  LikeButtonWithCondition = withMaybe(this.forRailOrGridLayout)(LikeButton);

  verifiedTickWithCondition = withMaybe(this.artistVerifiedCondition)(Image);

  navigateToMedia = () => {
    const {
      navigation,
      itemData,
      itemData: { slug = '' } = {},
      listId,
      index,
      listIndex,
      searchQuery,
      search,
      elementItemCounts,
      listName,
      listContent,
      layout,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' } = {},
      onPress,
      cardSource,
      toast,
    } = this.props;

    const toastMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.non_dimensional_video,
    );

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;
      const list_index = index + listIndex + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.metadata.title,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };
      this.debounceMediaCardClick(meta);
    }

    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.id,
        itemData.type,
        itemData.metadata.title,
        index,
        listId,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
      );
    }

    const {
      metadata: { width },
    } = itemData;

    if (onPress !== null && onPress !== undefined) {
      onPress(index);
    } else if (
      cardSource === SCREEN_CONSTANTS.BRAND_COLLAB_DETAIL &&
      Utility.isBlank(width)
    ) {
      console.log(
        'Will not navigate to media page, Height and width is null for media',
      );
      toast.show(toastMessage, 1000);
    } else {
      navigation.push('ContentModal', {
        itemData,
        listId,
        index,
        listData,
      });
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  imageError = () => {
    const { itemData, navigation } = this.props;
    const screen = '';
    AnalyticsUtilty.fireImageErrorAnalytics(
      itemData.id,
      itemData.image_url,
      itemData.name,
      'media',
      screen,
    );
  };

  MediaImage = (props) => {
    const {
      type,
      imageUrl,
      imageBackgroundStyle,
      layout,
      imageheight,
      imagewidth,
    } = props;
    const imageIcon =
      layout === LAYOUT.LIST
        ? images[`list${type}Icon`]
        : images[`${type}Icon`];
    let newImageUrl = imageUrl;
    if (!Utility.isBlank(imageUrl)) {
      newImageUrl = Utility.getMinifiedImage(imageUrl, 146, 146);
    }
    return (
      <FastImageView
        source={newImageUrl}
        style={imageBackgroundStyle}
        onError={this.imageError}
      >
        <Image
          source={imageIcon}
          resizeMode="cover"
          style={styles.listContentTypeIndicatorStyle}
        />
      </FastImageView>
    );
  };

  MediaViews = (props) => {
    const { layout, views, size, viewCountColor } = props;
    if (views === '0' || views === 0) {
      return null;
    }
    return (
      <View style={styles.viewsContainer}>
        <Text
          style={[styles.listViewCount, { color: viewCountColor }]}
          layout={layout}
          size={size}
        >
          {`${views}`}
        </Text>
        <this.viewsWithCondition
          layout={layout}
          style={[
            styles.listViewCount,
            { color: viewCountColor, marginLeft: 2 },
          ]}
        >
          views
        </this.viewsWithCondition>
      </View>
    );
  };

  MediaDescription = (props) => {
    const {
      layout = LAYOUT.RAIL,
      views,
      description,
      size,
      artistName,
      artistImage,
      verified,
    } = props;
    const textSize = fontSize.h5;
    const textColor = colors.foxyBlack;
    const viewCountColor = colors.disabled;
    const maxLines = MEDIA.MAX_LINES.TITLE[layout];
    return (
      <View style={styles.listDescriptionContainerStyle}>
        <View
          style={{
            flex: 1,
            marginLeft: 8,
            flexDirection: 'column',
            marginTop: 11,
          }}
        >
          {/* <View style={styles.listArtistContainer}> */}
          <View>
            <Text numberOfLines={maxLines} style={styles.listTextStyle}>
              {description}
            </Text>
          </View>

          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FastImageView
              borderRadius={9}
              resizeMode="cover"
              source={{
                uri: Utility.getMinifiedImage(artistImage, 120, 120),
              }}
              style={styles.artistMiniAvatar}
            />

            <Text numberOfLines={MAX_LINES.single} style={styles.artistName}>
              {artistName}
            </Text>
            <this.verifiedTickWithCondition
              verified={verified}
              source={images.verifiedTick}
              style={styles.verifiedTick}
            />
          </View>
          <this.MediaViews
            layout={layout}
            views={views}
            size={size}
            textColor={textColor}
            viewCountColor={viewCountColor}
          />
        </View>
      </View>
    );
  };

  render() {
    const {
      layout,
      itemData,
      size = 'defaultSize',
      hideDivider = false,
      orientation,
      views,
    } = this.props;

    const { metadata: { title = ' ', viewCount = ' ' } = {} } = itemData;
    const artistName = (itemData.artist && itemData.artist.name) || '';
    // TODO: Use a default image for an artist
    const artistImage =
      (itemData.artist &&
        itemData.artist.images &&
        itemData.artist.images.thumbnail) ||
      (itemData.artist && itemData.artist.image_url) ||
      'https://apan.net/people/avatar.png';
    const verified = itemData.artist && itemData.artist.verified;

    return (
      <ScaleAnimate {...this.props} onPress={this.navigateToMedia}>
        <Card
          {...DefaultSizeVideoList.cardLayoutStyle[orientation]}
          style={styles.listLayoutCardStyleLight}
        >
          <this.MediaImage
            {...this.props}
            imageBackgroundStyle={styles.imageBackgroundStyleForContent}
            imageUrl={itemData.image_url}
            layout={layout}
            imageheight={48}
            imagewidth={78}
          />
          <this.MediaDescription
            {...this.props}
            layout={layout}
            size={size}
            description={title || ''}
            views={viewCount || ''}
            artistName={artistName}
            artistImage={artistImage}
            verified={verified}
          />
        </Card>
        {hideDivider ? null : <View style={styles.listSeparatorStyle} />}
      </ScaleAnimate>
    );
  }
}

// PropTypes
DefaultSizeVideoList.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string,
  size: PropTypes.string,
  itemData: PropTypes.shape({
    image_url: PropTypes.string,
    id: PropTypes.string,
  }),
  theme: PropTypes.string,
  listId: PropTypes.string,
  onPress: PropTypes.func,
};

DefaultSizeVideoList.defaultProps = {
  theme: THEME.LIGHT,
  listId: null,
  onPress: null,
};

export default withNavigation(DefaultSizeVideoList);

const styles = StyleSheet.create({
  listLayoutCardStyleLight: {
    borderColor: colors.borderColor,
    flexDirection: 'row',
    backgroundColor: colors.white,
    alignSelf: 'center',
    borderRadius: 4,
    width: Utility.getScreenWidth() - 24,
    height: 112,
  },
  imageBackgroundStyleForContent: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 4,
    width: 146,
    height: 113,
  },
  listDescriptionContainerStyle: {
    flex: 1,
    marginLeft: 4,
  },
  listSeparatorStyle: {
    height: 1,
    backgroundColor: '#eaeaea',
    alignSelf: 'center',
    width: Utility.getScreenWidth() - 24,
  },
  likeButtonStyle: {
    marginRight: 22,
  },
  listViewCount: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 12,
    marginLeft: 4,
    fontStyle: 'normal',
  },
  viewsContainer: {
    flexDirection: 'row',
    marginTop: 4,
    alignItems: 'center',
  },
  listArtistContainer: {
    marginRight: 8,
    marginBottom: 8,
    marginTop: 4,
    height: 28,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'green',
  },
  artistMiniAvatar: {
    height: 25,
    width: 25,
    borderRadius: 13,
    overflow: 'hidden',
    elevation: 2,
    shadowRadius: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 1, height: 1 },
    marginTop: 5,
  },
  artistName: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 4,
    color: colors.codGray,
  },
  listMediaDescriptionContainer: {
    flex: 1,
    flexDirection: 'column',
    marginRight: 0,
    height: 64,
  },
  listTextStyle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    fontSize: 13,
    fontStyle: 'normal',
  },
  gradientStyle: {
    width: '100%',
    justifyContent: 'space-evenly',
  },
  listContentTypeIndicatorStyle: {
    alignContent: 'center',
    width: 40,
    height: 40,
  },
  verifiedTick: {
    height: 16,
    width: 16,
    marginTop: 1,
    resizeMode: 'cover',
  },
});
