import _ from 'lodash';
import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Star from './Star';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import { renderOnboarding } from '../../actions/ActionTypes';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { LOGIN_MODAL_MESSAGES, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import NavigationService from '../../navigator/NavigationService';

class TapRating extends Component {
  static defaultProps = {
    defaultRating: 3,
    count: 5,
    onFinishRating: () => {},
  };

  constructor() {
    super();
    this.state = {
      position: 5,
    };
  }

  componentDidMount() {
    const { defaultRating } = this.props;

    this.setState({ position: defaultRating });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.defaultRating !== this.props.defaultRating) {
      this.setState({ position: nextProps.defaultRating });
    }
  }

  renderStars(rating_array) {
    return _.map(rating_array, (star, index) => star);
  }

  starSelectedInPosition(position) {
    const {
      onFinishRating,
      authToken,
      renderOnboarding,
      setPhoneNumberModalInfo,
      disabled,
    } = this.props;
    if (disabled) return;
    if (Utility.isBlank(authToken)) {
      NavigationService.renderOnboarding();
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('RATE'));
      return;
    }
    onFinishRating(position);
    this.setState({ position });
  }

  onHover(position) {
    this.setState({ position });
  }

  render() {
    let { position } = this.state;
    const { count, rating, disabled } = this.props;
    if (rating) {
      position = rating;
    }
    const rating_array = [];

    _.times(count, (index) => {
      rating_array.push(
        <Star
          key={index}
          position={index + 1}
          starSelectedInPosition={this.starSelectedInPosition.bind(this)}
          fill={position >= index + 1}
          selectedRating={
            images.rating[`product_${Math.floor(position).toString()}_star`]
          }
          selectedColor={PRODUCT.RATING.color[Math.floor(position)]}
          selectedStarPosition={position}
          onHover={this.onHover.bind(this)}
          {...this.props}
        />,
      );
    });

    return (
      <View
        style={[styles.ratingContainer, { width: this.props.containerSize }]}
      >
        <View style={styles.starContainer}>
          {this.renderStars(rating_array)}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  ratingContainer: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: Utility.getScreenWidth() - 4 * Utility.largePadding,
  },
  starContainer: {
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 16,
  },
});

const mapStateToProps = (state) => ({
  authToken: state.UserAccountInfo.authToken,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      renderOnboarding,
      setPhoneNumberModalInfo,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TapRating);
