import React, { useEffect } from 'react';
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Image,
  StyleSheet,
} from 'react-native';
import _ from 'lodash';
import images from '../../theme/Images';

import { cartIds, CartStyles } from './styles';
import colors from '../../theme/Colors';
import CartPrompt from '../prompts/CartPrompt';
import Utility from '../../utils/Utility';
import FullWidthShimmer from '../../lib/FullWidthShimmer';

const PromoCodeButton = ({ navigateToOffers }) => {
  const styles = CartStyles;

  return (
    <TouchableOpacity
      style={styles.promoButtonContainer}
      onPress={navigateToOffers}
    >
      <View>
        <Text style={styles.promoCodeText} dataSet={{ media: cartIds.promoCodeText }}>Offers</Text>
      </View>
      <Image source={images.chevronRight} style={styles.rightArrow} />
    </TouchableOpacity>
  );
};

const CartAddresses = ({
  promptData = [],
  navigation,
  removeCartOffer = () => {},
  getOfferPrompt,
  navigateToOffers,
  isOfferShimmerVisible,
  applyOffer,
  authToken,
  address,
  date_of_birth,
  todayDeals = {},
}) => {
  if (getOfferPrompt) {
    useEffect(() => {
      const unsubscribe = navigation.addListener('focus', { getOfferPrompt });
      return unsubscribe;
    }, [navigation]);
  }
  return (
    <>
      <PromoCodeButton navigateToOffers={navigateToOffers} />
      {Utility.isPresent(promptData) && (
        <View style={styles.cartPromptsContainer}>
          <CartPrompt
            couponCodes={promptData}
            navigation={navigation}
            promptLoading={isOfferShimmerVisible}
            removeOffer={removeCartOffer}
            applyOffer={applyOffer}
            authToken={authToken}
            address={address}
            date_of_birth={date_of_birth}
            todayDeals={todayDeals}
          />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  cartPromptsContainer: {
    backgroundColor: colors.white,
    // paddingHorizontal: 16,
  },
});

export default CartAddresses;
