import { takeEvery, select } from 'redux-saga/effects';
import { GET_USER_LATEST_VIDEOS, VIDEO_WATCHED } from '../actions/ActionTypes';
import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

function* watchedVideo(action) {
  const { id } = action;

  const url = `${URL.ATTRIBUTE_VIDEO_WATCHED}${id}/watched`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken }),
    });
    if (response.status >= 200 && response.status < 300) {
      console.log('Video watched');
    }
  } catch (error) {
    console.log('error in watch video post', error);
  }
}

function* fetchUserVideosAsync(action) {
  const { id, callback } = action;

  const url = `${URL.USER_ATTRIBUTE_LATEST_VIDEOS}${id}/latest_videos`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
      return;
    }
    callback(false, {});
  } catch (error) {
    console.log('error in fetch videos', error);
    callback(false, {});
  }
}

export default function* watchUserAttributesVideos() {
  yield takeEvery(GET_USER_LATEST_VIDEOS, fetchUserVideosAsync);
  yield takeEvery(VIDEO_WATCHED, watchedVideo);
}
