import React, { Component } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';
import Images from '../../theme/Images';
import styles from './styles';
import BagIcon from './BagIcon';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import {
  EventParameterValue,
  AnalyticsManager,
  EventType,
} from '../../analytics';
import WishlistIcon from './WishlistIcon';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import { getMinifiedImage } from '../../utils/ImageUtils';

class TabNavigationRightHeader extends Component {
  constructor() {
    super();
    this.currentScreen = EventParameterValue.SCREEN_NAME.FEED;
    this.debounceSearchIconTap = _.debounce(this.fireSearchOpenEvent, 2000, {
      leading: true,
      trailing: false,
    });
    this.debounceCartIconTap = _.debounce(this.handleTap, 2000, {
      leading: true,
      trailing: false,
    });
  }

  handleTap = () => {
    const { showCart } = this.props;
    if (showCart) {
      this.props.navigation.push('Cart');
    }
  };

  onSearchTap = () => {
    const { navigation, route, store = false, moreSearchPage } = this.props;
    this.debounceSearchIconTap('');
    if (moreSearchPage) {
      navigation.navigate('Search', { moreSearchPage });
    } else {
      navigation.navigate('Search', {});
    }
  };

  fireSearchOpenEvent = (screen) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_OPEN, {
      screen_name: screen,
    });
  };

  render() {
    const { showCart = true, tabBar, isArtist, invitationLink } = this.props;
    this.currentScreen = tabBar;
    const shouldShowCart = showCart;

    const searchImage = Utility.isPresent(Config.SEARCH_HEADER_IMAGE)
      ? { uri: getMinifiedImage(Config.SEARCH_HEADER_IMAGE) }
      : Images.ic_search_product;

    return (
      <View style={styles.rightNavbarHeaderStyle}>
        {!AppConfig.getBooleanValue(Config.DISABLE_FEED_HEADER_WISHLIST_ICON) && (
          <View style={styles.cartIconMargin}>
            <WishlistIcon />
          </View>
        )}
        {!AppConfig.getBooleanValue(Config.DISABLE_FEED_HEADER_BAG_ICON) && (
          <View style={styles.cartIconMargin}>
            <BagIcon
              handleTap={this.debounceCartIconTap}
              showCart={shouldShowCart}
            />
          </View>
        )}
        {!AppConfig.getBooleanValue(Config.DISABLE_FEED_HEADER_SEARCH_ICON) && (
          <TouchableOpacity
            testID="header-search-icon"
            accessibilityLabel="header-search-icon"
            onPress={this.onSearchTap}
            hitSlop={Utility.getHitSlop()}
          >
            <Image
              source={searchImage}
              style={styles.navbarIconDefaultStyle}
            />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

export default withNavigation(TabNavigationRightHeader);
