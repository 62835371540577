import _ from 'lodash';
import { Linking } from 'react-native';
import AnalyticsManager from '../analytics/AnalyticsManager';
import EventType from '../analytics/AnalyticsEventType';
import EventParameterKey from '../analytics/EventParameterKey';
import { PERMISSIONS, PERMISSION_STORE } from '../config/Constants';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';
import { isBlank, isPresent } from './BooleanUtility';
import PermissionsUtility from './PermissionsUtility';

export default class SelfieUtilities {
  static currentPermissionState = '';

  static extractUserGender(my_attributes, remoteAttributes) {
    const genderAttributeId = 28;
    const defaultUserGender = 'female';
    const objRemoteGender = _.find(
      remoteAttributes,
      (attribute) => attribute.id === genderAttributeId,
    );
    const objUserSelectedGender = _.find(
      my_attributes,
      (attribute) => attribute.user_attribute_id === genderAttributeId,
    );

    let userGender = _.find(
      objRemoteGender.allowed_values,
      (gender) => gender.id === objUserSelectedGender.new_values[0],
    );

    if (!userGender) {
      userGender = { name: defaultUserGender };
    }

    return userGender.name.toLowerCase();
  }

  static detectIfSelfieIsReviewed(facialProperties) {
    const skinConditionAttributeId = 18;
    const ifSkinAttributeDetected =
      _.find(
        facialProperties,
        (attribute) => attribute?.id === skinConditionAttributeId,
      ) || [];
    const maleHairConditionAttributeId = 8;
    const ifMaleHairConditionAttributeDetected =
      _.find(
        facialProperties,
        (attribute) => attribute?.id === maleHairConditionAttributeId,
      ) || [];
    const femaleHairConditionAttributeId = 25;
    const ifFemaleHairConditionAttributeDetected =
      _.find(
        facialProperties,
        (attribute) => attribute?.id === femaleHairConditionAttributeId,
      ) || [];
    const ifHairAttributeDetected =
      isPresent(ifMaleHairConditionAttributeDetected.values) ||
      isPresent(ifFemaleHairConditionAttributeDetected.values);

    const ifSkinGoalAttributeDetected = isPresent(
      ifSkinAttributeDetected.values,
    );

    return ifHairAttributeDetected && ifSkinGoalAttributeDetected;
  }

  static getListOfUserSuitableAttributes(userGender, remoteAttributes) {
    const userSuitableList = _.filter(remoteAttributes, (attribute) => {
      if (userGender !== 'male' && userGender !== 'female') {
        return attribute.gender === 'female' || attribute.gender === 'unisex';
      }
      return attribute.gender === userGender || attribute.gender === 'unisex';
    });
    return userSuitableList;
  }

  static addUserSelectedValuesToAttributes = (
    userSelectedAttributes,
    userSuitableList,
  ) => {
    const finalUserAttributes = [];
    userSuitableList.forEach((attribute) => {
      const values = [];
      const userSelectedAttribute = _.find(userSelectedAttributes, {
        user_attribute_id: attribute.id,
      });
      if (userSelectedAttribute) {
        userSelectedAttribute.new_values.forEach((id) => {
          const value = _.find(attribute.allowed_values, { id: id });
          values.push(value);
        });
      }
      attribute.values = values;
      finalUserAttributes.push(attribute);
    });

    return finalUserAttributes;
  };

  static logAttributesDetectionEvent = (userAttributes) => {
    userAttributes.forEach((attribute) => {
      let attributeValue = '';
      attribute.values.forEach((value) => {
        attributeValue = `${attributeValue}${value.name},`;
      });
      if (isPresent(attributeValue)) {
        attributeValue = attributeValue.replace(/,$/, '');
        AnalyticsManager.logEvent(EventType.onboardingEvent.ATTRIBUTE_DETECT, {
          [EventParameterKey.ATTRIBUTE_NAME]: attribute.name,
          [EventParameterKey.ATTRIBUTE_ID]: attribute.id,
          [EventParameterKey.DETECTED_VALUE]: attributeValue,
        });
        AnalyticsManager.setUserProperty(
          _.snakeCase(attribute.name),
          _.toLower(attributeValue),
        );
      }
    });
  };

  static navigateToSelfieWithAnalytics = (
    source,
    navigation,
    extraData = null,
  ) => {
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_INITIATE, {
      [EventParameterKey.SOURCE]: source,
    });
    this.navigateToSelfieScreen(navigation, source, extraData);
  };

  static navigateToTakeSelfieWithAnalytics = (
    source,
    navigation,
    extraData = null,
  ) => {
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_INITIATE, {
      [EventParameterKey.SOURCE]: source,
    });
    console.log(extraData, 'extraData');
    navigation.navigate('SelfieIntro', {
      previousScreen: source,
      ...extraData,
      extraData,
    });
  };

  static navigateToSelfieScreen = (
    navigation,
    previousScreen,
    extraData = null,
  ) => {
    PermissionsUtility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        if (
          permissionStatus === 'granted' ||
          permissionStatus === 'authorized'
        ) {
          navigation.navigate('TakeSelfie', {
            previousScreen,
            extraData,
          });
          SelfieUtilities.currentPermissionState = '';
        } else if (
          permissionStatus === 'never_ask_again' ||
          permissionStatus === 'denied'
        ) {
          if (isBlank(SelfieUtilities.currentPermissionState)) {
            SelfieUtilities.currentPermissionState = permissionStatus;
          } else {
            Linking.openSettings();
          }
        }
      },
      PermissionsUtility.createPermissionMetaObject(
        SCREEN_CONSTANTS.FEED,
        PERMISSIONS.CAMERA,
      ),
    );
  };
}
