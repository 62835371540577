import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import size from '../../../theme/Fonts';
import { getScreenWidth } from '../../../utils/LayoutUtility';

const styles = {
  container: {
    flex: 1,
    backgroundColor: colors.white,
    height: Utility.getScreenHeight(),
    width: getScreenWidth(),
    alignSelf: 'center',
  },
  topHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginTop: 8,
  },
  subHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 8,
  },
  subContainer: {
    marginHorizontal: 20,
    marginTop: 10,
    height: Utility.getScreenHeight(),
  },
  writeCallBackReasonContainer: {
    backgroundColor: 'transparent',
    paddingTop: 12,
    marginBottom: 20,
  },
  extraMargin: {
    marginBottom: 10,
  },
  bodyInputContainerStyle: { paddingBottom: 24 },
  uploadContentContainer: {
    // paddingHorizontal: 12,
  },
  uploadImageText: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  seeHowTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.cta.lightBlue,
  },
  uploadContentCardStyle: {
    width: 112,
    height: 160,
    backgroundColor: colors.cardBackGroundColor,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.silverLit,
    borderStyle: 'dashed',
    marginTop: 12,
    justifyContent: 'center',
    marginRight: 8,
  },
  errorUploadContentCardStyle: {
    width: 112,
    height: 160,
    backgroundColor: colors.cardBackGroundColor,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.foxyRed,
    borderStyle: 'dashed',
    marginTop: 12,
    justifyContent: 'center',
    marginRight: 8,
  },
  imageStyle: {
    height: 54,
    width: 54,
    alignSelf: 'center',
  },
  selectedMedia: {
    width: 101,
    height: 161,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.silverLit,
    borderStyle: 'dashed',
    marginTop: 12,
  },
  // scrollViewContainer: { height: 160, },
  selectedMediaRailContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    overflow: 'visible',
  },
  selectedMediaContainer: { marginRight: 8 },
  emptyPlaceHolder: { height: 72, width: 72, marginRight: 32 },
  crossIcon: { height: 23, width: 23, alignSelf: 'center' },
  crossIconContainer: {
    position: 'absolute',
    top: 16,
    right: 4,
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: colors.cardBackGroundColor,
  },
  buttonContainer: {
    position: 'absolute',
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingTop: 15,
    bottom: Utility.isIOS() ? 12 : 0,
    paddingBottom: 12,
    zIndex: 3,
  },
  button: {
    height: 44,
  },
  timePickerContainer: {
    paddingHorizontal: 20,
    paddingTop: 12,
  },
  boldText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  datePillContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: 12,
  },
  timePillContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 12,
    justifyContent: 'flex-start',
  },
  pillStyle: {
    backgroundColor: colors.ultraLightGray,
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.white,
  },
  pillText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#173143',
  },
  datePicker: {
    width: Utility.getScreenWidth() - 24,
    alignSelf: 'center',
    marginTop: 24,
    height: 219,
  },
  SelectedPillStyle: {
    backgroundColor: colors.ultraLightGray,
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.foxyBlack,
  },
  disablePillStyle: {
    backgroundColor: colors.ultraLightGray,
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.white,
    opacity: 0.6,
  },
  bottomModal: {
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    justifyContent: 'flex-end',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
  seeHowContentContainer: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: colors.white,
    flexDirection: 'column',
    paddingBottom: 20,
    paddingHorizontal: 12,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },
  addEmailContentContainer: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: colors.white,
    flexDirection: 'column',
    paddingBottom: 20,
  },
  modalHeadingText: {
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    fontSize: 18,
    justifyContent: 'center',
  },
  modalSubHeadingText: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 14,
    justifyContent: 'center',
  },
  errorTextView: {
    marginVertical: 24,
    minHeight: 38,
    maxHeight: 40,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  errorText: {
    padding: 4,
    color: colors.foxyRed,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
  },
  crossTouchableOpacity: {
    position: 'absolute',
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    right: 10,
    top: -20,
  },
  crossButton: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  modalCrossIcon: {
    tintColor: colors.foxyBlack,
    height: 12,
    width: 12,
  },
  foxyShadowButton: {
    marginBottom: Utility.isAndroid ? 22 : 42,
    marginHorizontal: 16,
  },
  modalHeadingTextContainer: {
    paddingHorizontal: 24,
    marginTop: 32,
    marginBottom: 26,
    flexDirection: 'column',
  },
  emailTextFieldStyle: {
    width: Utility.getScreenWidth(),
    paddingHorizontal: 20,
    paddingBottom: 24,
  },
  timeSlotContainer: {
    marginTop: 40,
  },
  videoPauseIconContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    top: 140,
  },
  videoPauseIconStyle: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    top: 80,
  },
  videoPauseIcon: {
    height: 23,
    width: 23,
  },
  modalMediaRailContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    overflow: 'visible',
    paddingBottom: 24,
  },
  modalSelectedMedia: {
    width: 150,
    height: 300,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.silverLit,
    borderStyle: 'dashed',
  },
  headingTextContainer: {
    marginTop: 24,
    marginBottom: 14,
  },
  foxyShadowButtonStyle: {
    marginBottom: Utility.isAndroid ? 22 : 42,
  },
  modalSelectedMediaContainer: {
    marginRight: 8,
  },
  crossIconStyle: {
    position: 'absolute',
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    right: 2,
    top: -5,
  },
  seeHowTextContainer: {
    flexDirection: 'row',
    marginTop: 8,
    justifyContent: 'flex-start',
  },
  blueChevronStyle: {
    height: 12,
    width: 7.5,
    top: 4.5,
    left: 4,
  },
  errorMessage: {
    marginTop: 8,
    fontSize: size.h4,
    color: colors.foxyRed,
  },
};

export default styles;
