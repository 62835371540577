import { StyleSheet, Dimensions, PixelRatio } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { isDesktop } from '../../utils/BooleanUtility';

const videoScreenWidth = Utility.getScreenWidth();
const padding = 12.0;
const middleControlsWidth = videoScreenWidth * 0.5;
const sliderWidth = isDesktop() ? '72%' : videoScreenWidth - 166;
const verticalPlaylistContainerHeight = (619 / 375) * Utility.getScreenWidth();
export const verticalPlaylistCardWidth = isDesktop()
  ? videoScreenWidth / 4 - 8
  : Utility.getScreenWidth() - 80;
export const smallVerticalPlaylistCardWidth = 260;
export const smallVerticalPlaylistCardHeight =
  (16 / 9) * smallVerticalPlaylistCardWidth;
export const verticalPlaylistCardHeight =
  (526 / 296) * verticalPlaylistCardWidth;

export const videoComponentStyles = StyleSheet.create({
  videoComponentContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  videoContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  videoControlsContainer: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
});

export const playerMiddleControlsStyles = StyleSheet.create({
  nextPreviousButton: { flex: 0.3, alignItems: 'center' },
  activityIndicator: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const playerBottomControlsStyles = StyleSheet.create({
  timeLabelContainer: {
    height: 18,
    width: 95,
    flexDirection: 'row',
  },
  sliderContainer: {
    height: 1,
    width: sliderWidth,
    position: 'relative',
    left: 32,
    flexDirection: 'row',
    // position: 'relative',
  },
  slider: {
    flex: 1,
    height: 2,
    bottom: 11,
  },
  sliderThumbStyle: {
    width: 10,
    justifyContent: 'center',
    height: 10,
    backgroundColor: 'white',
    borderRadius: 10 / 2,
  },
  playPause: {
    width: 22,
    height: 20,
    position: 'relative',
    bottom: 0,
    left: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  playPauseTouchable: {
    width: 22,
    height: 18,
  },
  contentDurationLabel: {
    height: 16,
    width: 40,
  },
  currentPlayedTimeLabel: {
    height: 16,
    width: 40,
  },
  timeLabelStyle: {
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    color: '#ffffff',
  },
  timeDividerView: {
    width: 12,
    height: 16,
    paddingLeft: 2,
  },
  timeDividerText: {
    color: 'white',
  },
});

export const playerMiniControlsStyles = StyleSheet.create({
  fullscreenButtonIcon: {
    width: 18,
    height: 18,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 24,
  },
  favoriteButtonIcon: {
    width: 20,
    height: 18,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
  favoriteButtonImage: {
    width: 22,
    height: 22,
    marginTop: 2,
    resizeMode: 'stretch',
    tintColor: colors.white,
  },
  favoriteButtonIconAnimation: {
    height: 50,
    width: 50,
    resizeMode: 'cover',
    alignSelf: 'center',
  },
  shareButtonIcon: {
    width: 16,
    height: 18,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
    // marginLeft: 16
  },
  addToCartButtonIcon: {
    width: 18,
    height: 18,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 6,
  },
});

export const videoControlsViewStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  artistHeader: {
    height: 64,
    flexDirection: 'row',
    width: videoScreenWidth,
    padding,
  },
  playerMiddleControls: {
    height: 100,
    width: middleControlsWidth,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playerBottomControls: {
    position: 'absolute',
    bottom: 22,
    height: 48,
    width: '100%',
    flexDirection: 'column',
    paddingLeft: padding,
    paddingRight: padding,
  },
  playerMiniControls: {
    width: 102.0,
    height: 18.0,
    flexDirection: 'row',
    position: 'absolute',
    right: 16,
    justifyContent: 'flex-end',
  },
  likeButtonStyle: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
    backgroundColor: colors.white,
    elevation: 5,
    borderRadius: 16,
    shadowColor: colors.grey,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
  likeButtonStyleWithoutShadow: {
    width: 38,
    height: 38,
    borderRadius: 36 / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.border,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  likeButtonProductDetailStyle: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 16,
    shadowColor: colors.grey,
    marginRight: 0,
  },
});

export const playlistStyle = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginHorizontal: 12,
    marginVertical: 8,
    borderRadius: 4,
    backgroundColor: colors.white,
  },
  horizontalProductUsedContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 52,
    width: '100%',
  },
  ratingContainer: {
    margin: 12,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 52,
    width: '100%',
    backgroundColor: colors.white,
  },
  ratingBoldText: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    color: colors.silver,
  },
  ratingNormalText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    color: colors.silver,
  },
  titleTextContainer: {
    margin: 12,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 52,
    width: '100%',
    backgroundColor: colors.white,
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: '800',
    fontStyle: 'normal',
    color: colors.silver,
  },
  productUsedTextContainer: {
    width: 74,
    paddingLeft: 12,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  productUsedImages: {
    position: 'absolute',
    height: 52,
    right: 12,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  productCircle: {
    height: 36,
    margin: 2,
    width: 36,
    borderWidth: 1,
    borderRadius: 18,
    borderColor: colors.borderDark,
    resizeMode: 'cover',
  },

  similarProductCircles: {
    height: 36,
    margin: -6,
    width: 36,
    borderWidth: 1,
    borderRadius: 18,
    borderColor: colors.borderDark,
    resizeMode: 'cover',
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  productsUsedText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    color: colors.silver,
  },
  verticalPlaylistDescContiner: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
  },
  verticalProductUsedContainer: {
    justifyContent: 'flex-start',
    width: '100%',
  },
  productContainerForVerticalPlaylist: {
    flexDirection: 'row',
    height: 54,
    width: '100%',
    alignItems: 'center',
  },
  verticalPlaylistProductDescContainer: {
    height: 44,
    width: 40,
    justifyContent: 'space-between',
  },
  verticalPlaylistTextStyle: {
    fontSize: 10,

    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    marginLeft: 4,
    color: colors.foxyBlack,
  },
  playlistProductSpStyle: {
    color: colors.foxyBlack,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    marginRight: 4,
    marginLeft: 4,
  },
  playlistProductMrpStyle: {
    fontSize: 12,
    textDecorationLine: 'line-through',
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  playlistProductDiscountStyle: {
    color: colors.foxyPink,
    fontSize: 12,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
  },
  verticalPlaylistProductSeperator: {
    height: 1,
    position: 'absolute',
    right: 12,
    left: 12,
    bottom: 0,
    backgroundColor: colors.border,
  },
  verticalPlaylistProgressIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    left: 4,
    right: 4,
    bottom: 6,
  },
  verticalPlaylistContentTypeIndicatorStyle: {
    position: 'absolute',
    top: 8,
    left: 8,
    width: 20,
    height: 10,
    resizeMode: 'cover',
  },
  verticalRailContainer: {
    position: 'relative',
    marginBottom: 28,
    height: 72,
    width: Utility.getScreenWidth(),
    alignItems: 'center',
  },
  verticalCardMediaContainer: {
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
  },
  verticalPlaylistContainer: {
    width: Utility.getScreenWidth(),
    height: verticalPlaylistCardHeight + 12,
    marginVertical: 8,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallVerticalPlaylistContainer: {
    width: smallVerticalPlaylistCardWidth + 12,
    height: smallVerticalPlaylistCardHeight + 12,
    marginVertical: 8,
    alignSelf: 'center',
  },
  verticalPlaylistsCardsContainer: {
    position: 'relative',
    flexDirection: 'row',
    bottom: 12,
  },
  verticalPlaylistCardContentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 12,
    paddingLeft: 12,
  },
  verticalRailItemCard: {
    width: verticalPlaylistCardWidth,
    height: verticalPlaylistCardHeight,
    borderRadius: 16,
    marginRight: 8,
  },
  smallVerticalRailItemCard: {
    width: smallVerticalPlaylistCardWidth,
    height: smallVerticalPlaylistCardHeight,
    borderRadius: 16,
    marginRight: 8,
  },
  verticalPlaylistMediaImageContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'black',
  },
  verticalPlaylistMediaImageBlackContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'black',
  },
  verticalPlaylistImageBackgroundStyle: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },

  verticalCardMiddleControls: {
    height: 42,
    width: 42,
    resizeMode: 'cover',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  verticalCardMiddleControlsLarge: {
    height: 54,
    width: 54,
    resizeMode: 'cover',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  verticalCardMiddleControlsContainer: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalCardFullScreenButtonContainer: {
    position: 'absolute',
    bottom: 0,
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalPlaylistCardFullScreenButton: {
    height: 24,
    width: 24,
    resizeMode: 'cover',
    position: 'absolute',
    // bottom: 16,
    // right: 16,
  },
  verticalPlaylistCardFullScreenButtonContainer: {
    height: 36,
    width: 36,
    resizeMode: 'cover',
    position: 'absolute',
    bottom: 10,
    right: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  verticalPlaylistCardMuteButton: {
    height: 24,
    width: 24,
    resizeMode: 'cover',
    position: 'absolute',
    bottom: 16,
    right: 54,
  },
  verticalPlaylistHeadingContainer: {
    marginTop: 12,
    marginLeft: 16,
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: 44,
    width: '100%',
  },
  verticalPlaylistTitle: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: '700',
    fontStyle: 'normal',
    color: '#E0E0E0',
  },
  verticalPlaylistSubtitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'normal',
    color: '#979BAA',
  },
  verticalListCarouselContainer: {
    marginTop: 4,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  verticalPlaylistPlayerStyle: {
    height: '100%',
    width: '100%',
    top: 0,
    position: 'absolute',
  },
});
