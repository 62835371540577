import {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { BackHandler } from 'react-native';
import { useSelector } from 'react-redux';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import AnalyticsManager from '../analytics/AnalyticsManager';
import { isAndroid, isPresent, isWeb } from '../utils/BooleanUtility';
import { getCompactSlug, getFullSlugFromName } from '../utils/StringUtility';

export const useHardwareBackKeyPress = (onBackPress) => {
  const navigation = useNavigation();
  useEffect(() => {
    let backHandler;
    if (isAndroid()) {
      backHandler = BackHandler.addEventListener('hardwareBackPress', onHardwareBackKeyPress);
    }
    return () => {
      if (isAndroid()) {
        backHandler.remove();
      }
    };
  }, []);
  const onHardwareBackKeyPress = () => {
    if (typeof onBackPress === 'function') {
      onBackPress();
    } else {
      navigation.goBack();
    }
    return true;
  };
};

export const useApiCall = (url, itemData = {}, refetch, analyticsData) => {
  const [data, setData] = useState(itemData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    authToken,
    guestProfile: { guestAuthToken },
  } = useSelector((state) => state.UserAccountInfo);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': authToken,
        'x-guest-token': guestAuthToken,
      };

      if (isWeb()) {
        headers.Platform = 'web';
      }

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers,
        });
        const statusCode = response.status;

        const jsonData = await response.json();
        if (statusCode >= 200 && statusCode < 300) {
          if (isPresent(analyticsData)) {
            AnalyticsManager.logEvent('item_view', {
              ...analyticsData,
            });
          }

          setData(jsonData);
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (e) {
        setError(`${e}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url, refetch]);

  return { data, isLoading, error };
};

export const useAuthToken = () => {
  const authToken = useSelector((state) => state.UserAccountInfo.authToken);
  return authToken;
};

export const useEmail = () => {
  const profileEmail = useSelector((state) => state.UserAccountInfo.profile.email);
  const emailVerified = useSelector((state) => state.UserAccountInfo.profile.email_verified);
  const [email, setEmail] = useState(profileEmail || '');

  useEffect(() => {
    if (profileEmail) {
      setEmail(profileEmail);
    }
  }, [profileEmail]);

  return { email, setEmail, emailVerified };
};

export const useIsComponentMounted = () => {
  const isComponentMounted = useRef(false);
  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);
  return isComponentMounted;
};

export const useShortSlug = (type) => {
  const route = useRoute();
  const navigation = useNavigation();
  useEffect(() => {
    if (isWeb()) {
      const { params = {} } = route;
      const { shortSlug, slug } = params;
      let slugString = slug;
      if (!shortSlug && slug) {
        navigation.setParams({ shortSlug: getCompactSlug(slug) });
      }
      if (!slug && shortSlug) {
        slugString = getFullSlugFromName(shortSlug, type);
        navigation.setParams({ slug: slugString });
      }
    }
  });
};

export const useRemoveParams = (params = []) => {
  const navigation = useNavigation();
  useFocusEffect(
    useCallback(() => {
      navigation.setParams(params.reduce((acc, param) => ({ ...acc, [param]: undefined }), {}));
    }, []),
  );
};
