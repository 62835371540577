import React, { PureComponent } from 'react';
import {
  BackHandler,
  Image,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Share from '../../libraries/ReactNativeShare';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getInfluencerDetails } from '../../actions/InfluencerActions';
import { followArtist, unFollowArtist } from '../../actions/ActionTypes';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import FastImageView from '../../components/FastImageView';
import { StaticNavigationHeader } from '../../components/shared';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import NoInternet from '../../components/shared/NoInternet';
import {
  API_ERRORS,
  LOGIN_MODAL_MESSAGES,
  ONE_LINK_REGISTRATION,
  REMOTE_CONFIG_KEYS,
  URL,
  WEB_URL,
} from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { withEither } from '../../lib/Monads';
import images from '../../theme/Images';
import Utility, { openUrlInBrowser } from '../../utils/Utility';
import { List } from '../List';
import CircularGradient from './CircularGradient';
import InfluencerPlaceHolder from './InfluencerPlaceHolder';
import ProAccountsList from './ProAccountsList';
import styles from './styles';
import { Theme } from '../../theme/Colors';
import RemoteConfig from '../../utils/RemoteConfig';
import WithNavigation from '../../utils/WithNavigation';
import NavigationService from '../../navigator/NavigationService';
import { isWeb } from '../../utils/BooleanUtility';
import WebFooter from '../../components/webFooter/WebFooter';

class Influencer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      error: undefined,
      isFollowing: false,
    };

    const goBack = () => {
      this.props.navigation?.goBack();
      return true;
    };
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        goBack,
      );
    }
    this.shareButtonStyle = {
      position: 'relative',
      height: 48,
      width: 48,
      left: Utility.getScreenWidth() - 48,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: Utility.isIOS() ? Utility.topInset : 8,
    };
    const { navigation, route } = this.props;
    this.placeholderProfileImageUrl = route.params?.profilePlaceholderUrl || '';
  }

  componentDidMount() {
    this.fetchInfluencerDetails();
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) this.backHandler?.remove();
  }

  fetchInfluencerDetails = () => {
    const { getInfluencerDetails } = this.props;
    getInfluencerDetails(
      this.getInfluencerHandle(),
      this.getInfluencerDetailsCallback,
    );
  };

  getInfluencerHandle = () => {
    const { route } = this.props;
    const bioHandle = route.params?.bioHandle || route.params?.destination;
    return bioHandle ? bioHandle.slice(bioHandle.indexOf('@') + 1) : ' ';
  };

  getInfluencerDetailsCallback = (success, details) => {
    this.setState({ loading: false });
    if (!success || details?.pro_accounts?.length === 0) {
      this.setState({ error: details?.error, data: details?.list });
      return;
    }

    const { followedArtists = {} } = this.props;
    const isFollowing = Utility.isPresent(followedArtists[details?.artist_id]);
    this.setState({ data: details, isFollowing, error: undefined });
    AnalyticsManager.logEvent(EventType.influencer.BIO_PAGE_VIEW, {
      [EventParameterKey.FOXY_HANDLE]: this.getInfluencerHandle(),
    });
  };

  circularGradientWithPlayButton = (props) => {
    return (
      <>
        <CircularGradient {...props}>{props.children}</CircularGradient>
        <FastImageView
          source={images.introVideoPlay}
          style={styles.videoPlayIcon}
        />
      </>
    );
  };

  getVideoUrl = () => {
    const {
      data: { intro_video: { metadata: { videoUrl = '' } = {} } = {} } = {},
    } = this.state;
    return videoUrl;
  };

  isVideoPresent = () => {
    return Utility.isPresent(this.getVideoUrl());
  };

  conditionalHalo = withEither(
    this.isVideoPresent,
    this.circularGradientWithPlayButton,
  )(View);

  navigateToVideo = () => {
    const { navigation } = this.props;
    const videoUrl = this.getVideoUrl();
    if (Utility.isBlank(videoUrl)) return;
    navigation.navigate(SCREEN_CONSTANTS.MEDIA_COMPONENT_WITHOUT_CONTROLS, {
      uri: videoUrl,
    });
    AnalyticsManager.logEvent(EventType.influencer.INTRO_PLAY_VIDEO, {
      [EventParameterKey.FOXY_HANDLE]: this.getInfluencerHandle(),
    });
  };

  tag = ({ image_url: imageUrl, name }, index) => {
    const { data: { influencer_tag_colors: tagColors = [] } = {} } = this.state;
    return (
      <View style={[styles.tag, { backgroundColor: tagColors[index] }]}>
        <FastImageView
          source={Utility.getMinifiedImage(imageUrl, 20, 20)}
          style={styles.tagIcon}
        />
        <Text style={styles.tagText}>{name}</Text>
      </View>
    );
  };

  Tags = ({ tags = [] }) => {
    if (Utility.isBlank(tags)) return null;
    return <View style={styles.tagsContainer}>{tags.map(this.tag)}</View>;
  };

  ProfilePicture = ({ imageUrl = '' }) => {
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.navigateToVideo}
        style={styles.profilePictureContainer}
        disabled={!this.isVideoPresent()}
      >
        <this.conditionalHalo
          circleRadius={52}
          padding={2}
          colorsArray={Theme.influencerHaloGradient}
        >
          <FastImageView
            source={Utility.getMinifiedImage(imageUrl, 200, 200)}
            style={styles.profileImage}
          />
        </this.conditionalHalo>
      </DebouncedTouchableOpacity>
    );
  };

  onShareButtonPress = () => {
    Share.open({
      title: Config.APP_NAME,
      message: '',
      url: `${WEB_URL}/@${this.getInfluencerHandle()}`,
    });
  };

  ShareButton = () => {
    return (
      <TouchableOpacity
        style={this.shareButtonStyle}
        onPress={this.onShareButtonPress}
      >
        <FastImageView source={images.shareButton} style={styles.shareIcon} />
      </TouchableOpacity>
    );
  };

  followArtist = () => {
    const { followArtist, unFollowArtist, authToken, setPhoneNumberModalInfo } =
      this.props;
    const { isFollowing, data: { artist_id: id = '' } = {} } = this.state;
    if (Utility.isBlank(authToken)) {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('FOLLOW_ARTIST'));
      NavigationService.renderOnboarding();
      return;
    }
    const slug = isFollowing
      ? URL.UNFOLLOW_INFLUENCER_SLUG
      : URL.FOLLOW_INFLUENCER_SLUG;
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_ARTIST_FOLLOW);
    const url = slug?.replace('id', id);
    const data = {
      url,
      isFollowing,
      id,
    };
    if (isFollowing) {
      unFollowArtist(data);
    } else {
      followArtist(data);
    }
    this.setState({ isFollowing: !isFollowing });
  };

  FollowButton = ({ isFollowing }) => {
    const containerStyle = isFollowing
      ? styles.followingButtonContainer
      : styles.followButtonContainer;
    const iconStyle = isFollowing
      ? styles.followingButtonIcon
      : styles.followButtonIcon;
    const textStyle = isFollowing
      ? styles.followingButtonText
      : styles.followButtonText;
    const text = isFollowing ? 'Following' : 'Follow';
    const icon = isFollowing ? images.followingIcon : images.followIcon;

    return (
      <TouchableOpacity style={containerStyle} onPress={this.followArtist}>
        <FastImageView style={iconStyle} source={icon} />
        <Text style={textStyle}>{text}</Text>
      </TouchableOpacity>
    );
  };

  IntroCard = ({ data, isFollowing }) => {
    const {
      pro_accounts = [],
      image_url: imageUrl = '',
      name = '',
      bio = '',
      influencer_tags = [],
    } = data;
    const profileUrl = Utility.isBlank(imageUrl)
      ? this.placeholderProfileImageUrl
      : imageUrl;
    return (
      <>
        <View style={styles.introContainer}>
          <View style={styles.headerContainer}>
            <this.ProfilePicture imageUrl={profileUrl} />
            <Text style={styles.influencerName}>{name}</Text>
            <this.Tags tags={influencer_tags} />
            {Utility.isPresent(bio) && (
              <Text style={styles.influencerBio}>
                {bio?.replace(/(\r\n|\n|\r)/gm, '')}
              </Text>
            )}
            <this.FollowButton isFollowing={isFollowing} />
          </View>
          <ProAccountsList
            accounts={pro_accounts}
            getInfluencerHandle={this.getInfluencerHandle}
          />
        </View>
        <View style={styles.introCardFooterContainer}>
          <View style={styles.bottomCurveLayer1} />
          <View style={styles.bottomCurveLayer2} />
        </View>
      </>
    );
  };

  registerOneLink = () => {
    openUrlInBrowser(ONE_LINK_REGISTRATION);
  };

  OneLinkButton = () => {
    return (
      <View style={styles.registerOneLink}>
        <DebouncedTouchableOpacity
          {...this.props}
          style={styles.oneLinkCta}
          onPress={this.registerOneLink}
        >
          <Image source={images.comparePriceImage.foxy} style={styles.icon} />
          <Text style={styles.oneLinkText}>OneLink</Text>
        </DebouncedTouchableOpacity>
        <Text style={styles.ctaText}>Claim your handle now</Text>
      </View>
    );
  };

  ConnectionErrorPage = () => {
    return (
      <>
        <StaticNavigationHeader />
        <NoInternet onPress={this.fetchInfluencerDetails} />
      </>
    );
  };

  ErrorCard = () => {
    return (
      <View style={styles.errorCardContainer}>
        <Image
          source={images.no_internet}
          style={styles.errorCardHeight}
          resizeMode="contain"
        />
        <Text style={styles.heading}>Uh-oh!</Text>
        <Text style={styles.subHeading}>
          The profile doesn't exist or may have been moved permanently to a new
          location
        </Text>
      </View>
    );
  };

  hasApiFailed = () => {
    const { error } = this.state;
    return error === API_ERRORS.SERVER_ERROR;
  };

  conditionalErrorOrIntroCard = withEither(
    this.hasApiFailed,
    this.ErrorCard,
  )(this.IntroCard);

  render() {
    const {
      data: { lists = {} } = {},
      data = {},
      loading,
      error,
      isFollowing,
    } = this.state;
    const { navigation } = this.props;
    if (loading) {
      return <InfluencerPlaceHolder />;
    }
    if (!loading && error === API_ERRORS.CONNECTION_ERROR) {
      return <this.ConnectionErrorPage />;
    }
    return (
      <View style={styles.background}>
        <StaticNavigationHeader title={this.getInfluencerHandle()}>
          {!this.hasApiFailed() && !isWeb() && <this.ShareButton />}
        </StaticNavigationHeader>
        <ScrollView
          contentContainerStyle={styles.scrollContainer}
          showsVerticalScrollIndicator={false}
        >
          <this.conditionalErrorOrIntroCard
            data={data}
            isFollowing={isFollowing}
          />
          {Utility.isPresent(lists) && (
            <List
              itemData={lists}
              previousScreen={SCREEN_CONSTANTS.INFLUENCER}
              disableRefresh
              navigation={navigation}
            />
          )}
          <this.OneLinkButton />
          {isWeb() && <WebFooter />}
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    followedArtists: store.UserAccountInfo?.followedArtists,
    authToken: store.UserAccountInfo?.authToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getInfluencerDetails,
      followArtist,
      unFollowArtist,
      setPhoneNumberModalInfo,
    },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Influencer),
);
