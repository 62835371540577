// Dependencies
import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { memoize } from 'lodash';
import Images from '../../theme/Images';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { AnalyticsManager } from '../../analytics';
import { isDesktop } from '../../utils/BooleanUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';

const FoxyPromise = ({ onPress, style, promiseImage, promise }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.foxyPromiseContainer} dataSet={{ media: ids.foxyPromiseContainer }}>
        <View style={styles.imageContainer}>
          <Image source={promiseImage} style={style} />
        </View>

        <Text style={styles.promiseText} allowFontScaling={false} dataSet={{ media: ids.promiseText }}>
          {promise}
        </Text>
      </View>
    </TouchableOpacity>
)};

const FoxyPromises = ({
  productPage: { foxy_promise = {}, promise = {} } = {},
  navigation,
  product_id,
}) => {
  const navigateToCertificateModal = data => {
    AnalyticsManager.logEvent('item_action_2', {
      item_name: promise,
      item_id: product_id,
    });

    navigation.push('CertificateModal', {
      ...data,
    });
  };
  return (
    <View style={styles.container}>
      {!isDesktop() && (
        <View style={styles.headingContainer}>
          <Text style={styles.heading}>{promise?.heading || ''}</Text>
        </View>
      )}

      <View style={styles.bottomContainer} dataSet={{ media: ids.bottomContainer }}>
        <View style={styles.flexRow}>
          <FoxyPromise
            promise='100% Original Products'
            promiseImage={Images.foxyPromise.orignalProducts}
            style={styles.originalProduct}
            onPress={memoize(
              () => navigateToCertificateModal(foxy_promise.orignal),
              () => [foxy_promise.orignal?.heading],
            )}
          />
          <FoxyPromise
            promise={foxy_promise.delivery?.heading}
            promiseImage={Images.deliveryPromise}
            style={styles.foxyPromise}
            onPress={memoize(
              () => navigateToCertificateModal(foxy_promise.delivery),
              () => [foxy_promise.delivery?.heading],
            )}
          />
          <FoxyPromise
            promise='Hassle-Free Returns'
            promiseImage={Images.foxyPromise?.easyReturns}
            style={styles.return}
            onPress={memoize(
              () => navigateToCertificateModal(foxy_promise.return),
              () => [foxy_promise.return?.heading],
            )}
          />
          <FoxyPromise
            promise='Secure Payments'
            promiseImage={Images.foxyPromise.securePayments}
            style={styles.securePayments}
            onPress={memoize(
              () => navigateToCertificateModal(foxy_promise.payment),
              () => [foxy_promise.payment?.heading],
            )}
          />
        </View>
      </View>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  container: { marginBottom: 16 },
  headingContainer: { backgroundColor: colors.background, zIndex: 1 },
  heading: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginTop: 32,
    marginBottom: 12,
    marginHorizontal: 12,
  },
  bottomContainer: {
    flexDirection: 'column',
    padding: 16,
    backgroundColor: colors.white,
    marginHorizontal: 12,
    '@media (min-width: 768px)': {
      padding: 0,
      paddingTop: 12,
      marginHorizontal: 0,
      backgroundColor: colors.background,
      marginBottom: -32,
    },
  },
  flexRow: { flexDirection: 'row', justifyContent: 'space-around', flex: 1 },
  originalProduct: {
    width: 36,
    height: 40,
    resizeMode: 'contain',
    alignSelf: 'center',
    overflow: 'visible',
  },
  foxyPromise: {
    width: 36,
    height: 36,
    resizeMode: 'contain',
    alignSelf: 'center',
    overflow: 'visible',
  },
  return: {
    width: 36,
    height: 34,
    resizeMode: 'contain',
    alignSelf: 'center',
    overflow: 'visible',
  },
  securePayments: {
    width: 36,
    height: 40,
    resizeMode: 'contain',
    alignSelf: 'center',
    overflow: 'visible',
  },
  foxyPromiseContainer: {
    width: getScreenWidth() / 5,
    alignContent: 'center',
    marginVertical: 8,
    overflow: 'visible',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'center',
      width: PRODUCT_RIGHT_CONTAINER_WIDTH / 4 - 20,
    },
  },
  imageContainer: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 12,
  },
  promiseText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#000',
    '@media (min-width: 768px)': {
      marginTop: -10,
    },
  },
});

export default FoxyPromises;
