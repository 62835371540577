// Dependencies
import React, { Component } from 'react';
import {
  View,
  FlatList,
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
} from 'react-native';
import { memoize } from 'lodash';
import Config from '../../libraries/ReactNativeConfig';
import Images from '../../theme/Images';
import { ProductDetailStyles, ProductInfoStyles, productDetailIds } from './styles';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { withEither } from '../../lib/Monads';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import VariantUtility from '../../utils/VariantUtility';
import AppConfig from '../../config/AppConfig';
import { CUSTOMIZED_SKU_FORM_PAGE } from '../../config/Constants';
import { isBlank, isDesktop, isPresent, isWeb } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtils';
import { getHitSlop } from '../../utils/LayoutUtility';

// FIXME: LOT OF INLINE FUNCTIONS, DESTRUCTURING CAN BE FIXED. REFACTORING EFFORT : HIGH
class ProductVariant extends Component {
  constructor(props) {
    super(props);

    this.inStockedVariants = [];
    this.stockedOutVariants = [];
    this.unStockedVariants = [];
    this.discontinuedVariants = [];
    const { variantAttributes = [], multiVariantAttributes } = this.props;
    this.sortedVariants = multiVariantAttributes ?? variantAttributes;
    this.allowedVariants = [];
    this.availableVariants = [];
    this.unavailableVariants = [];
    this.state = {
      isMoreShadePressed: false,
    };
  }

  checkIfShadeVariantIsSelected = item => {
    const { selectedMultiVariantPrimary, selectedVariant } = this.props;
    if (isPresent(selectedMultiVariantPrimary)) {
      return selectedMultiVariantPrimary?.value === item?.value;
    }
    if (isPresent(selectedVariant)) {
      return selectedVariant.id === item?.id;
    }
    return false;
  };

  primaryVariantSelected = item => {
    const {
      updateSelectedVariant,
      setSelectedMultiVariantPrimary,
      multiVariantAttributes = [],
    } = this.props;
    if (VariantUtility.checkIfMultiVariant(multiVariantAttributes)) {
      setSelectedMultiVariantPrimary(item);
      return;
    }
    updateSelectedVariant(item?.variant ?? item);
  };

  variantItem = ({ item }) => {
    const {
      selectedVariant,
      showSelectedVariantIdFromSlug,
      disableSelectedVariantFromSlug,
      selectedMultiVariantPrimary = {},
    } = this.props;

    if (
      selectedVariant === '' &&
      item?.default !== undefined &&
      AppConfig.getBooleanValue(
        Config.PRODUCT_PAGE_SHOW_DEFAULT_SELECTED_VARIANT,
      )
    ) {
      this.props.updateSelectedVariant(item);
    }
    const variantAttribute = item?.id || '';
    const borderColor = this.checkIfShadeVariantIsSelected(item.variant ?? item)
      ? 'black'
      : 'white';
    if (
      isPresent(showSelectedVariantIdFromSlug) &&
      showSelectedVariantIdFromSlug === item?.id
    ) {
      this.props.updateSelectedVariant(item);
      disableSelectedVariantFromSlug();
    }

    return (
      <DebouncedTouchableOpacity
        onPress={memoize(
          () => this.handleVariantItemPress(item),
          () => [item],
        )}
        style={memoize(
          () => ([
            ProductDetailStyles.ImageBackgroundContainer,
            { borderColor },
          ]),
          () => [borderColor],
        )()}
      >
        <ImageBackground
          source={memoize(
            () => ({
              uri: item.image_url,
            }),
            () => [item.image_url],
          )()}
          style={ProductDetailStyles.productVariantWhiteImageBackground}
        />
        {this.checkIfShadeVariantIsSelected(item.variant ?? item) ? (
          <View style={ProductDetailStyles.whiteTickContainer}>
            <Image
              style={ProductDetailStyles.productVariantTickImage}
              source={Images.whiteTick}
            />
          </View>
        ) : null}
        {item?.outOfStock && <View style={ProductDetailStyles.oosContainer} />}
      </DebouncedTouchableOpacity>
    );
  };

  handleVariantItemPress = (item = {}, parentValue) => {
    const { navigation, productCategory = {} } = this.props;

    const changeProductOnPrimaryVariantSelect = AppConfig.getBooleanValue(
      Config.CHANGE_PRODUCT_ON_PRIMARY_VARIANT_SELECT,
    );

    if (parentValue) {
      this.secondaryVariantSelected(item);
    } else {
      const { multi_level_variant_attributes = [] } = item;
      if (
        changeProductOnPrimaryVariantSelect &&
        isPresent(multi_level_variant_attributes)
      ) {
        this.secondaryVariantSelected(multi_level_variant_attributes[0]); // updating secondary variant
      }

      this.primaryVariantSelected(item);
    }
    const { variant = {} } = item;
    const { sku = {} } = variant;
    const { id: skuId, needs_customization: needsCustomization = false } = sku;
    if (CUSTOMIZED_SKU_FORM_PAGE && needsCustomization && skuId) {
      navigation.navigate(CUSTOMIZED_SKU_FORM_PAGE, { item, productCategory });
    }
  };

  sizeVariantItem = (item = {}) => {
    const {
      showSelectedVariantIdFromSlug,
      disableSelectedVariantFromSlug = () => {},
    } = this.props;

    const selectedContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? ProductDetailStyles.flexWrappedSizeContainerSelected
      : ProductDetailStyles.sizeContainerSelected;
    const unselectedContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? ProductDetailStyles.flexWrappedSizeContainer
      : ProductDetailStyles.sizeContainer;
    const containerStyle = this.checkIfShadeVariantIsSelected(
      item.variant ?? item,
    )
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const isDisabled = item.variant?.outOfStock || item.outOfStock;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    if (showSelectedVariantIdFromSlug == item?.id) {
      disableSelectedVariantFromSlug();
    }
    return (
      <TouchableOpacity
        onPress={memoize(
          () => this.handleVariantItemPress(item),
          () => [item.value ?? item.id],
        )}
        style={containerStyle}
        disabled={isDisabled}
      >
        <Text style={textStyle}>{item?.value || item?.display_name}</Text>
        {isDisabled && (
          <View style={ProductDetailStyles.disabledSizePillLine} />
        )}
      </TouchableOpacity>
    );
  };

  variantName = props => {
    const { name, variantAttributes } = props;
    const { multiVariantAttributes } = this.props;

    if (
      isBlank(variantAttributes?.[0]?.allowed_values) &&
      isBlank(variantAttributes?.[0]?.variant_attributes) &&
      isBlank(variantAttributes?.[0]?.variant) &&
      isBlank(multiVariantAttributes)
    )
      return null;

    const { selectedMultiVariantPrimary, selectedVariant } = this.props;

    const { display_name } = selectedVariant;

    const variantName = selectedMultiVariantPrimary?.value ?? display_name;

    return (
      <View style={ProductDetailStyles.variantNameContainer}>
        <View style={ProductDetailStyles.flexRow}>
          <Text
            style={ProductDetailStyles.variantNameTextStyle}
          >{`${name} : `}</Text>

          <Text style={ProductDetailStyles.darkTextStyleVariantName}>
            {variantName}
          </Text>
        </View>
      </View>
    );
  };

  packSize = props => {
    const { name } = props;

    return (
      <View style={ProductDetailStyles.packSizeVariantContainer}>
        <Text style={ProductDetailStyles.variantNameTextStyle}>{'Size :'}</Text>

        <Text style={ProductDetailStyles.darkTextStyleVariantName}>{name}</Text>
      </View>
    );
  };

  unavailableVariantName = () => {
    return (
      <View style={ProductDetailStyles.variantNameContainerUnavailableVariant}>
        <Text style={ProductDetailStyles.variantNameTextStyle}>
          Other unavailable sizes
        </Text>
      </View>
    );
  };

  renderItems = ({ item, index }) => {
    const moreVariantCount = isDesktop() ? 19 : 13;
    const { isMoreShadePressed } = this.state;
    if (!isMoreShadePressed && index === moreVariantCount) {
      return (
        <TouchableOpacity onPress={this.onMoreShadeTap} hitSlop={getHitSlop()}>
          <View style={ProductDetailStyles.moreVariantsContainer}>
            <Text style={ProductDetailStyles.variantNameText}>{`+ ${
              this.sortedVariants.length - index
            }`}</Text>
          </View>
        </TouchableOpacity>
      );
    }

    if (!isMoreShadePressed && index > moreVariantCount) return null;

    return <this.variantItem item={item} />;
  };

  navigateToSizeChart = () => {
    const { sizeChart, navigation } = this.props;
    navigation.navigate('SizeChart', {
      sizeChartContent: sizeChart,
    });
  };

  sizeChartButton = () => {
    const { sizeChart } = this.props;
    if (
      isBlank(sizeChart) ||
      !AppConfig.getBooleanValue(Config.PRODUCT_PAGE_SHOW_SIZE_CHART)
    )
      return null;
    return (
      <View style={ProductDetailStyles.sizeChartButton}>
        <DebouncedTouchableOpacity
          {...this.props}
          onPress={this.navigateToSizeChart}
        >
          <Text style={ProductDetailStyles.sizeChartButtonText}>
            Size Chart
          </Text>
        </DebouncedTouchableOpacity>
      </View>
    );
  };

  renderPrimaryProductAttributes = () => {
    if (isWeb()) {
      return (
        <View style={ProductDetailStyles.webShadesContainer}>
          {this.sortedVariants.map((item, index) => <this.renderItems item={item} index={index} />)}
        </View>
      );
    }
    return (
      <FlatList
        data={this.sortedVariants}
        numColumns={7}
        horizontal={false}
        renderItem={this.renderItems}
        keyExtractor={this.flatListKeyExtractor}
        showsHorizontalScrollIndicator={false}
        style={ProductDetailStyles.imageVariantFlatlistStyle}
        contentContainerStyle={ProductDetailStyles.imageVariantFlatlistContentStyle}
        extraData={{
          ...this.props,
          ...this.state,
        }}
      />
    );
  }

  productAttribute = props => {
    const { variantAttributes } = props;
    const {
      selectedVariant,
      selectedMultiVariantPrimary = {},
      selectedMultiVariantSecondary = {},
    } = this.props;

    let name = '';
    if (isBlank(selectedVariant)) {
      name = 'Select a Shade';
    } else {
      name = variantAttributes[0].name;
    }

    if (isBlank(selectedMultiVariantPrimary)) {
      name = `Select a ${this.sortedVariants?.[0]?.name}`;
    } else {
      name = this.sortedVariants?.[0]?.name;
    }
    if (variantAttributes === undefined || variantAttributes.count === 0) {
      return null;
    }

    const showViewAllButton =
      variantAttributes?.[0]?.allowed_values?.length <= 13;
    const styles = ProductInfoStyles;

    const {
      variant_attributes: selectedVariantAttributes,
      multi_level_variant_attributes: selectedMultiVariantAttributes,
    } = selectedMultiVariantPrimary;

    return (
      <View style={ProductDetailStyles.productVariantContainer}>
        <this.variantName
          name={name}
          showViewAllButton={showViewAllButton}
          variantAttributes={variantAttributes}
        />
        <this.renderPrimaryProductAttributes />
        {(isPresent(selectedVariantAttributes) ||
          isPresent(selectedMultiVariantAttributes)) && (
          <View style={styles.sizeHeadingContainer}>
            <Text style={styles.shadeText}>
              {selectedMultiVariantAttributes?.[0]?.name ??
                selectedVariantAttributes?.[0]?.name}{' '}
              :{' '}
              <Text style={styles.selectedShadeText}>
                {selectedMultiVariantSecondary?.value}
              </Text>
            </Text>

            <this.sizeChartButton />
          </View>
        )}
        <FlatList
          data={selectedMultiVariantAttributes ?? selectedVariantAttributes}
          horizontal
          showsHorizontalScrollIndicator={false}
          renderItem={this.sizeVariantsPill}
          keyExtractor={this.sizeListKeyExtractor}
          style={ProductDetailStyles.imageSecondaryVariantFlatlistStyle}
          extraData={{
            ...selectedMultiVariantPrimary,
            ...selectedMultiVariantSecondary,
          }}
        />
      </View>
    );
  };

  sizeListKeyExtractor = item => `${item.value}`;

  secondaryVariantSelected = item => {
    const { setSelectedMultiVariantSecondary, updateSelectedVariant } = this.props;
    setSelectedMultiVariantSecondary(item);
    const { variant = {} } = item || {};
    updateSelectedVariant(variant);
  };

  sizeVariantsPill = ({ item = {}, index }) => {
    const styles = ProductInfoStyles;
    const { selectedMultiVariantSecondary, selectedMultiVariantPrimary = {} } =
      this.props;
    const { value: parentVariantValue } = selectedMultiVariantPrimary;
    const isDisabled = isBlank(item.variant) || item?.variant?.outOfStock;
    const isSelected =
      selectedMultiVariantSecondary?.value === item?.value && !isDisabled;
    const selectedContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? styles.flexWrappedSelectedSizePillBorder
      : styles.selectedSizePillBorder;
    const unselectedContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? styles.flexWrappedUnselectedSizePillBorder
      : styles.unselectedSizePillBorder;
    const borderStyle = isSelected
      ? selectedContainerStyle
      : unselectedContainerStyle;
    const textStyle = isDisabled
      ? ProductDetailStyles.disabledSizeVariantText
      : ProductDetailStyles.sizeVariantText;
    return (
      <TouchableOpacity
        disabled={isDisabled}
        style={borderStyle}
        onPress={memoize(
          () => this.handleVariantItemPress(item, parentVariantValue),
          () => [item.value ?? item.id, parentVariantValue],
        )}
      >
        <Text style={textStyle}>{item?.value}</Text>
        {isDisabled && (
          <View style={ProductDetailStyles.disabledSizePillLine} />
        )}
      </TouchableOpacity>
    );
  };

  flatListKeyExtractor = (item, index) => index;

  variantsFlatListRenderItem = ({ item, index }) => {
    if (index >= 5) return null;
    return <this.variantItem item={item} />;
  };

  sizeVariantFlatListRenderItem = ({ item }) => this.sizeVariantItem(item);

  productSizeAttribute = props => {
    const { variantAttributes, multiVariantAttributes } = props;
    const {
      stockedOut,
      selectedMultiVariantPrimary,
      selectedMultiVariantSecondary,
    } = this.props;
    if (isBlank(multiVariantAttributes)) {
      return null;
    }

    const variantName = !stockedOut ? (
      <this.variantName
        name={multiVariantAttributes[0]?.name || 'Size'}
        variantAttributes={variantAttributes}
        isSize
      />
    ) : (
      <this.unavailableVariantName />
    );

    const {
      variant_attributes: selectedVariantAttributes,
      multi_level_variant_attributes: selectedMultiVariantAttributes,
    } = selectedMultiVariantPrimary;

    const flatListContentContainerStyle = AppConfig.getBooleanValue(
      Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
    )
      ? ProductDetailStyles.flexWrappedContentContainerStyle
      : {};

    return (
      <View style={ProductDetailStyles.productSizeVariantContainer}>
        {variantName}
        <this.sizeChartButton />
        <FlatList
          data={multiVariantAttributes}
          horizontal={
            !AppConfig.getBooleanValue(
              Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
            )
          }
          renderItem={this.sizeVariantFlatListRenderItem}
          keyExtractor={this.flatListKeyExtractor}
          showsHorizontalScrollIndicator={false}
          style={ProductDetailStyles.flatListItemStyle}
          contentContainerStyle={flatListContentContainerStyle}
          extraData={{
            ...this.props.selectedVariant,
            ...selectedMultiVariantPrimary,
          }}
        />
        {(isPresent(selectedVariantAttributes) ||
          isPresent(selectedMultiVariantAttributes)) && (
          <Text style={ProductInfoStyles.shadeTextWithBottomMargin}>
            {`${
              selectedMultiVariantAttributes?.[0]?.name ??
              selectedVariantAttributes?.[0]?.name
            } : `}
            <Text style={ProductInfoStyles.selectedShadeText}>
              {selectedMultiVariantSecondary?.value}
            </Text>
          </Text>
        )}
        <FlatList
          data={selectedMultiVariantAttributes ?? selectedVariantAttributes}
          horizontal={
            !AppConfig.getBooleanValue(
              Config.DISABLE_VARIANT_LIST_SCROLLABILITY,
            )
          }
          showsHorizontalScrollIndicator={false}
          renderItem={this.sizeVariantsPill}
          keyExtractor={this.sizeListKeyExtractor}
          style={ProductInfoStyles.secondaryVariantFlatlistStyle}
          contentContainerStyle={flatListContentContainerStyle}
          extraData={{
            ...selectedMultiVariantPrimary,
            ...selectedMultiVariantSecondary,
          }}
        />
      </View>
    );
  };

  onMoreShadeTap = () => {
    const { onMoreShadeTap } = this.props;
    if (isDesktop()) {
      this.setState({
        isMoreShadePressed: true,
      });
      return;
    }
    onMoreShadeTap();
  };

  additionalAttributeButton = props => {
    const { variantAttributes } = props;
    if (variantAttributes === undefined || variantAttributes.count === 0) {
      return null;
    }
    const allowedVariantValues = variantAttributes[0]?.allowed_values;
    if (allowedVariantValues.length < 5) {
      return null;
    }
    return (
      <TouchableOpacity
        onPress={this.onMoreShadeTap}
        style={ProductDetailStyles.extraAttributeButtonStyle}
      >
        <Text style={ProductDetailStyles.variantText}>
          {`${allowedVariantValues.length} variants`}
        </Text>
        <View style={ProductDetailStyles.additionalAttributeCirclesStyle}>
          {allowedVariantValues.map((attr, index) => {
            const variantAttribute = attr.color_code || '';
            return index < 5 ? (
              <FastImageView
                source={getMinifiedImage(attr.image_url, 32, 32)}
                key={attr.image_url}
                resizeMode='stretch'
                style={ProductDetailStyles.extraVariantCircle}
                variantColor={variantAttribute}
              />
            ) : null;
          })}
          <Image
            source={images.chevronRight}
            style={ProductDetailStyles.rightArrowStyle}
          />
        </View>
      </TouchableOpacity>
    );
  };

  otherVariants = props => {
    const { variantAttributes, multiVariantAttributes } = props;
    return (
      <View style={ProductDetailStyles.integerVariantsContainer} dataSet={{ media: productDetailIds.integerVariantsContainer }}>
        <this.productSizeAttribute
          variantAttributes={variantAttributes}
          multiVariantAttributes={multiVariantAttributes}
        />
      </View>
    );
  };

  imageVariants = (props = {}) => {
    const { variantAttributes, recommendedVariants, multiVariantAttributes } =
      props;
    const { stockedOut } = this.props;

    return !stockedOut ? (
      <View style={ProductDetailStyles.variantAttributeContainer}>
        <this.productAttribute
          variantAttributes={variantAttributes}
          recommendedVariants={recommendedVariants}
          multiVariantAttributes={multiVariantAttributes}
        />
      </View>
    ) : null;
  };

  imageCondition = props =>
    props.type?.toLowerCase() === PRODUCT.ATTRIBUTE_TYPE.IMAGE;

  attribute = withEither(
    this.imageCondition,
    this.imageVariants,
  )(this.otherVariants);

  areVariantAttributesPresent = () => {
    const { variantAttributes, multiVariantAttributes } = this.props;

    return (
      isPresent(multiVariantAttributes) ||
      isPresent(variantAttributes) ||
      isPresent(variantAttributes?.[0]?.allowed_values)
    );
  };

  render() {
    const {
      variantAttributes,
      hasVariants,
      recommendedVariants,
      packSize,
      multiVariantAttributes,
    } = this.props;

    if (!hasVariants) {
      if (isPresent(packSize)) {
        return (
          <View
            style={ProductDetailStyles.productWhiteSizeVariantContainer}
            dataSet={{ media: productDetailIds.productWhiteSizeVariantContainer }}
          >
            <this.packSize name={packSize} />
          </View>
        );
      }
      return null;
    }
    if (!this.areVariantAttributesPresent()) {
      return null;
    }
    return (
      <View style={ProductDetailStyles.whiteBackground}>
        <this.attribute
          variantAttributes={variantAttributes}
          type={multiVariantAttributes[0]?.type}
          recommendedVariants={recommendedVariants}
          multiVariantAttributes={multiVariantAttributes}
        />
      </View>
    );
  }
}

export default React.memo(ProductVariant);
