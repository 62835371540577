import React, { PureComponent, useEffect } from 'react';
import {
  View,
  Animated,
  Dimensions,
  Easing,
  Platform,
  Clipboard,
  BackHandler,
  TouchableOpacity,
  StyleSheet,
  Image,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { denormalize } from 'normalizr';
import { compose } from 'recompose';
import Toast from 'react-native-easy-toast';
import Orientation from 'react-native-orientation';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import _ from 'lodash';
import ScaleAnimate from '../../components/shared/ScaleAnimate';
import {
  State,
  TapGestureHandler,
} from '../../libraries/ReactNativeGestureHandler';
import Share from '../../libraries/ReactNativeShare';
import MediaComponent from '../../components/media';
import ErrorPage from '../error/ErrorPage';
import { updateMediaId } from '../../actions/MediaDetailActions';
import {
  followArtist,
  unFollowArtist,
  like,
  unLike,
  // viewItem,
  videoView,
  videoWatched,
} from '../../actions/ActionTypes';
import { videoSchema, listSchema } from '../../config/Schema';
import { schemaBySlug } from '../../config/SchemaBySlug';
import MediaDetailListsContainer from '../mediaDetailListsContainer';
import ContentPageHeader from '../../components/contentPageHeader';
import { CONTENT_PAGE_LAYOUT } from '../../config/LayoutConstants/ContentPageLayoutConfig';
import { withEither } from '../../lib/Monads';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import {
  ORIENTATION,
  API_DOMAIN,
  ContentPlatforms,
  LOGIN_MODAL_MESSAGES,
  WEB_URL,
  TRACKING_PARAMS,
} from '../../config/Constants';
import PlayerBottomControls from '../../components/media/PlayerBottomControls';
import PlayerMidControls from '../../components/media/PlayerMidControls';
import {
  AnalyticsEvent,
  EventType,
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ShareModal from '../../components/Product/ShareModal';
import colors from '../../theme/Colors';
import LottieView from '../../libraries/LottieView';
import animations from '../../theme/Animations';
import gifs from '../../theme/Gifs';
import { firebase } from '../../libraries/Firebase';
import VerticalMediaImage from '../../components/media/VerticalMediaImage';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class ContentPage extends PureComponent {
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // const { iteratorIndex, listData } = nextProps;
  //   // const videoData = listData.objects[iteratorIndex];
  //   console.log('areObjectsLoading 1: ', prevState.areObjectsLoading)
  //   if (!Utility.isBlank(nextProps.videoData.objects) && prevState.areObjectsLoading) {
  //     return { areObjectsLoading: false };
  //   }
  //   return null;
  // }

  constructor(props) {
    super(props);
    const {
      // listData,
      listId,
      iteratorIndex,
      index,
      // areObjectsLoading,
      goToNextPage,
      goToPrevPage,
      goToNumberedPage,
      topicDetail,
      bottomPaddingToAdd,
      videoData,
      reviewsTab = false,
      previousScreen,
      parentComponent,
    } = this.props;
    // this.videoData = props.listData.objects[iteratorIndex];

    this.trace = firebase.perf().newTrace(SCREEN_CONSTANTS.CONTENT_PAGE);
    this.trace.start();
    let {
      // artist: { name: artistName = '', id: artistId } = {},
      entity: { name: entityName = '', id: entityId, type: entityType } = {},
      metadata: {
        contentPlatform = ContentPlatforms.foxy,
        title: title,
        id: videoId,
        duration,
      },
      objects,
      cta = {},
    } = videoData;
    this.ctaAction = cta?.action;
    this.ctaGif = cta?.overlay_gif;
    this.currentTime = props.seekToTimeOnLoad;
    this.doubleTapRef = React.createRef();
    this.fromContentPageCarousel = parentComponent === 'ContentPageCarousel';
    this.reviewsAsBottomTab = Config.TABS.includes('Reviews');

    this.bottomInset =
      Utility.isIOS() && this.fromContentPageCarousel
        ? Utility.bottomInset
        : 10;

    if (listId === 'stories' && Utility.isPresent(objects)) {
      // objects has only single object in the array.
      const products = objects[0]?.objects?.filter(
        (element) => !element.outOfStock,
      );
      if (products.length > 0) {
        objects[0] = { ...objects[0], objects: products };
      } else {
        objects = [];
      }
    }

    this.state = {
      isFullscreen: false,
      leftInset: 0,
      rightInset: 0,
      bottomInset: 0,
      topInset: 0,
      isBuffering: true,
      paused: false,
      showControls: true,
      featuredListsAndContentHeaderVisible: true,
      objects,
      // areObjectsLoading,
      // rotatedListData: {
      //   ...listData,
      //   name: 'Up Next',
      //   objects: Utility.rotateArray(listData.objects, iteratorIndex).splice(1),
      // },
      isCurrentIndex: iteratorIndex === index,
      isLiked: props.isFavorite,
      didVideoStart: false,
    };

    let source = listId;
    if (!isNaN(listId)) {
      source = 'LIST';
    }
    let screenName = previousScreen;

    if (listId === 'stories') {
      screenName = 'feed';
    } else if (listId === 'reviews') {
      screenName = 'reviews';
    }

    this.createProductInfo(objects[0]?.objects);

    this.videoMetadata = {
      [EventParameterKey.VIDEO_ID]: videoId,
      [EventParameterKey.VIDEO_TITLE]: title,
      [EventParameterKey.VIDEO_DURATION]: duration,
      [EventParameterKey.VIDEO_TYPE]: _.capitalize(contentPlatform),
      [EventParameterKey.ENTITY_TYPE]: entityType,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.ENTITY_ID]: entityId,
      [EventParameterKey.SOURCE]: source,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.LIST_ID]: !isNaN(listId) ? listId : null,
      [EventParameterKey.PUBLISHER_ID]: topicDetail ? topicDetail.id : null,
      [EventParameterKey.PUBLISHER_NAME]: topicDetail ? topicDetail.name : null,
    };

    this.updateStateWithInsets();
    this.progressHeaderHeight = new Animated.Value(
      CONTENT_PAGE_LAYOUT.ProgressHeader.height,
    );
    this.headerContainerTop = new Animated.Value(Utility.topInset);

    this.productListHeight = new Animated.Value(
      CONTENT_PAGE_LAYOUT.ProductList.horizontalListHeight + this.bottomInset,
    );
    this.bottomControlsContainerBottom = new Animated.Value(
      CONTENT_PAGE_LAYOUT.ProductList.horizontalListHeight +
        this.bottomInset +
        bottomPaddingToAdd,
    );
    this.featuredListsContainerBottom = new Animated.Value(bottomPaddingToAdd);
    this.hideControlsTimer = null;

    this.onNextButtonTapped = _.debounce(goToNextPage, 1000, {
      leading: true,
      trailing: false,
    });

    this.onPreviousButtonTapped = _.debounce(goToPrevPage, 1000, {
      leading: true,
      trailing: false,
    });
    this.onMediaCardTapped = _.debounce(goToNumberedPage, 1000, {
      leading: true,
      trailing: false,
    });
    this.topicDetail = topicDetail;
  }

  createProductInfo(products) {
    if (Utility.isBlank(products)) return;
    const productIdArray = [];
    const skuIdArray = [];
    this.productIdsObject = {};
    products.forEach((product) => {
      productIdArray.push(product?.id);
      skuIdArray.push(product?.sku_id);
    });
    this.productIdsObject = { productIdArray, skuIdArray };
  }

  componentDidMount() {
    if (this.props.onRef != null) {
      this.props.onRef(this);
    }
    AnalyticsManager.setCurrentScreen(
      EventParameterValue.SCREEN_NAME.VIDEO_PLAYER,
    );
    const { videoData, iteratorIndex, updateMediaId, navigation } = this.props;
    // const { metadata: { orientation } = {}, cta = {} } = videoData;

    // const { objects, areObjectsLoading } = this.state;

    // // Orientation.lockToPortrait();
    // if (orientation === ORIENTATION.VERTICAL) {
    //   // Orientation.lockToPortrait();
    // } else {
    //   Orientation.unlockAllOrientations();
    // }
    // Orientation.addOrientationListener(this.orientationDidChange);

    this.unsubscribeFocus = navigation.addListener('focus', () => {
      this.setState({ paused: false });
    });
    this.unsubscribeBlur = navigation.addListener('blur', () => {
      this.setState({ paused: true });
    });

    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackPress,
      );
    }

    this.trace.stop();
  }

  componentWillUnmount() {
    if (this.hideControlsTimer) {
      clearTimeout(this.hideControlsTimer);
    }
    // Orientation.removeOrientationListener(this.orientationDidChange);

    if (Utility.isAndroid()) {
      this.backHandler.remove();
    }
    this.unsubscribeFocus();
    this.unsubscribeBlur();
    // // Orientation.lockToPortrait();
  }

  onHardwareBackPress = () => {
    const { navigation, listId } = this.props;
    if (listId === 'reviews') {
      navigation.navigate('Feed', {});
      return true;
    }
    navigation.goBack();
    return true;
  };

  setCurrentIndexStatus = (isCurrent) => {
    this.setState({ isCurrentIndex: isCurrent });
  };

  ratioMultiplier = () => (isDesktop() ? getScreenWidth() / (360 * 2.5) : Dimensions.get('window').width / 360);

  setCurrentTime = (currentTime) => {
    if (this.bottomControlsRef != null) {
      this.bottomControlsRef.setCurrentTime(currentTime);
    }
    this.currentTime = currentTime;
  };

  updateStateWithInsets = () => {
    this.setState({
      leftInset: 0,
      rightInset: 0,
      topInset: Utility.topInset,
      bottomInset: Utility.bottomInset,
    });
  };

  onVideoPlayerFrameEndAnimation = () => {
    this.playNextVideo();
  };

  // Button Actions
  onCancelTap = () => {
    const { navigation } = this.props;
    // // Orientation.lockToPortrait();
    navigation.goBack();
  };

  onLoadVideo = () => {
    const { videoViewed, videoData } = this.props;
    this.startTimerToHideControls();
    this.showFeaturedListsAndContentHeader();
    if (videoViewed != null) {
      videoViewed(videoData.id);
    }
  };

  startTimerToHideControls = () => {
    const { showControls } = this.state;
    if (showControls) {
      if (this.hideControlsTimer) {
        clearTimeout(this.hideControlsTimer);
      }
      this.hideControlsTimer = setTimeout(this.toggleControls, 2500);
    }
  };

  toggleFeaturedListAndContentHeaderVisibility = () => {
    const { featuredListsAndContentHeaderVisible } = this.state;
    if (featuredListsAndContentHeaderVisible) {
      this.showFeaturedListsAndContentHeader();
    } else {
      this.hideFeaturedListsAndContentHeader();
    }
  };

  onMediaComponentTap = (event) => {
    if (event.nativeEvent.state === State.ACTIVE) {
      this.setState(
        (prevState) => ({
          featuredListsAndContentHeaderVisible:
            !prevState.featuredListsAndContentHeaderVisible,
          showControls: !prevState.showControls,
        }),
        () => {
          this.toggleFeaturedListAndContentHeaderVisibility();
          this.startTimerToHideControls();
        },
      );
    }
  };

  onMediaDoubleTap = (event) => {
    if (event.nativeEvent.state === State.ACTIVE) {
      this.onFavoriteTapped();
    }
  };

  toggleControls = () => {
    this.setState(
      (prevState) => ({ showControls: !prevState.showControls }),
      () => {
        this.startTimerToHideControls();
        // this.onSwipeUp();
      },
    );
  };

  logAnalyticsEvent = (id, title, medium) => {
    AnalyticsManager.logEvent(EventType.miscAppEvents.ITEM_SHARE, {
      [EventParameterKey.SCREEN_NAME]: 'video_page',
      [EventParameterKey.ITEM_ID]: id,
      [EventParameterKey.ITEM_NAME]: title,
      [EventParameterKey.ITEM_TYPE]: 'video',
      [EventParameterKey.MEDIUM]: medium,
    });
    AnalyticsManager.logFirebaseEvent(EventType.googleRemarketingEvents.SHARE, {
      [EventParameterKey.CONTENT_TYPE]: 'video',
      [EventParameterKey.ID]: id,
    });
  };

  onFollowTapped = (isFollowingArtist) => {
    AnalyticsManager.logEvent(
      EventType.videoEvents.VIDEO_ARTIST_FOLLOW,
      this.videoMetadata,
    );
    const { followArtist, unFollowArtist, videoData } = this.props;
    const { artist: { id: artistId, name, slug, youtube_url } = {} } =
      videoData;
    const url = slug?.replace(
      '.json',
      isFollowingArtist ? '/unfollow.json' : '/follow.json',
    );
    const data = {
      url,
      isFollowingArtist,
      id: artistId,
    };
    let message = 'You unfollowed the artist';
    if (!Utility.isBlank(name)) {
      message = `You ${isFollowingArtist ? 'unfollowed' : 'followed'} ${name}`;
    }
    if (isFollowingArtist) {
      unFollowArtist(data);
    } else {
      followArtist(data);
    }
    this.showToast(true, message);
  };

  onSocialShareOptionTapped = (type) => {
    const { videoData, listId = '' } = this.props;
    const {
      id = '',
      image_url: imageUrl,
      share_url: shareUrl,
      metadata: { title },
    } = videoData;
    const message = `Watch this amazing video on ${Config.APP_NAME}, ${Config.APP_DESCRIPTION_FOR_SHARE_TEXT}`;

    const reviewShareUrl = `${WEB_URL}/reviews?video_id=${id}`;
    const getDynamicLink = DynamicLinkManager.getShortenUrl(reviewShareUrl, {
      title,
      imageUrl: Utility.getMinifiedImage(imageUrl, 200, 200),
      description: '',
      trackingParams: TRACKING_PARAMS.SHARE.VIDEO,
    });
    getDynamicLink
      .then((link) => {
        const options = {
          title: Config.APP_NAME,
          message,
          url: link,
        };

        Share.open(options)
          .then((res) => {})
          .catch((err) => {});
        this.fireVideoCtaClickEvent('share');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showShareModal = () => {
    this.setState({
      isShareModalVisible: true,
    });
  };

  hideShareModal = () => {
    this.setState({
      isShareModalVisible: false,
    });
  };

  showFeaturedListsAndContentHeader = () => {
    const { objects } = this.state;
    Animated.parallel([
      Animated.timing(this.featuredListsContainerBottom, {
        toValue: Utility.isBlank(objects)
          ? -CONTENT_PAGE_LAYOUT.ProductList.horizontalListHeight -
            this.bottomInset -
            this.props.bottomPaddingToAdd
          : this.props.bottomPaddingToAdd,
        duration: 300,
      }),
      Animated.timing(this.bottomControlsContainerBottom, {
        toValue:
          CONTENT_PAGE_LAYOUT.ProductList.horizontalListHeight +
          this.bottomInset +
          this.props.bottomPaddingToAdd,
        duration: 300,
      }),
      Animated.timing(this.headerContainerTop, {
        toValue: Utility.topInset,
        duration: 300,
      }),
    ]).start();
  };

  hideFeaturedListsAndContentHeader = () => {
    const { parentComponent = '' } = this.props;
    Animated.parallel([
      Animated.timing(this.featuredListsContainerBottom, {
        toValue:
          -CONTENT_PAGE_LAYOUT.ProductList.horizontalListHeight -
          this.bottomInset -
          10 -
          this.props.bottomPaddingToAdd,
        duration: 300,
      }),
      Animated.timing(this.bottomControlsContainerBottom, {
        toValue: -90 - this.props.bottomPaddingToAdd,
        duration: 300,
      }),
      Animated.timing(this.headerContainerTop, {
        toValue:
          parentComponent !== SCREEN_CONSTANTS.CONTENT_PAGE_CAROUSEL ||
          Utility.isIOS()
            ? -88 - Utility.topInset
            : -120.0 - Utility.topInset,
        duration: 300,
      }),
    ]).start();
  };

  getStyles = () => {
    const {
      isFullscreen,
      leftInset,
      rightInset,
      objects,
      topInset,
      bottomInset,
    } = this.state;
    const {
      metadata: {
        orientation = 'horizontal',
        width: videoWidth,
        height: videoHeight,
      },
    } = this.props.videoData;
    const { bottomPaddingToAdd, parentComponent = '' } = this.props;
    const shouldShowList = !Utility.isBlank(objects);
    // const shouldShowList = true;

    const screenWidth = isDesktop() ? getScreenWidth() / 2.5 : Dimensions.get('window').width;
    let screenHeight = Dimensions.get('window').height;
    if (Utility.isAndroid()) {
      screenHeight = Dimensions.get('screen').height;
    }
    const availableHeight = screenHeight;
    const availableWidth = screenWidth - (leftInset + rightInset);

    const contentPageContainerStyle = {
      width: availableWidth,
      height: availableHeight,
      overflow: 'hidden',
      alignSelf: 'center',
    };

    const videoContainerStyle = {
      width: availableWidth,
      height: availableHeight - bottomPaddingToAdd,
      backgroundColor: 'black',
      overflow: 'hidden',
    };

    const playerContainerStyle = {
      width: isDesktop() ? getScreenWidth() / 2.5 : '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    };
    const playerStyle = {
      width: isDesktop() ? getScreenWidth() / 2.5 : '100%',
      height: '100%',
    };

    const headerContainerStyle = {
      height:
        parentComponent !== SCREEN_CONSTANTS.CONTENT_PAGE_CAROUSEL ||
        Utility.isIOS()
          ? 30 + 58 + Utility.topInset
          : 30 + 90.0 + Utility.topInset,
      width: availableWidth,
      paddingTop: 0,
      zIndex: 60,
      position: 'absolute',
      overflow: 'hidden',
      top: this.headerContainerTop,
    };

    const headerGradientStyle = {
      width: isDesktop() ? getScreenWidth() / 2.5 : '100%',
      height: 99 + Utility.topInset,
      position: 'absolute',
      top: 0,
      zIndex: 50,
    };

    const progressBarContainerStyle = {
      height: 17,
      flexDirection: 'row',
      width: availableWidth,
      overflow: 'hidden',
    };
    const progressContainerStyle = {
      width: 296 * this.ratioMultiplier(),
      flexDirection: 'row',
      alignItems: 'center',
      height: 17.0,
      marginLeft: 16.0,
    };

    const avatarImageContainerStyle = {
      width: 36,
      height: 36,
    };
    const textContainerStyle = {
      width: 172,
      height: 36,
    };
    const contentTitleStyle = {
      fontSize: 12,
      fontStyle: 'normal',
      lineHeight: 14,
      letterSpacing: 0,
      color: '#ffffff',
      fontFamily: 'Roboto-Medium',
    };
    const artistNameStyle = {
      fontSize: 11,

      fontStyle: 'normal',
      letterSpacing: 0,
      color: '#efefef',
      top: 2,
      fontFamily: 'Roboto-Regular',
    };
    const avatarImageStyle = {
      width: 36,
      height: 36,
      borderRadius: 18,
    };
    const followButtonStyle = {
      width: 41.0,
      height: 25.0,
      marginTop: 5.5,
      marginBottom: 5.5,
      position: 'absolute',
      right: 0,
    };

    const playerMidControlsStyle = {
      height: 100,
      width: isDesktop() ? getScreenWidth() / 5 : '50%',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      top: videoContainerStyle.height / 2 - 50,
      left: videoContainerStyle.width / 4,
      zIndex: 150,
    };

    const bottomControlsContainerStyle = {
      position: 'absolute',
      bottom: this.bottomControlsContainerBottom,
      width: isDesktop() ? getScreenWidth() / 2.5 : '100%',
      height: 34,

      marginBottom:
        Utility.isAndroid() && !this.fromContentPageCarousel && this.reviewsAsBottomTab ? 50 : 0,
    };

    const playerBottomControlsStyle = {
      position: 'relative',
      top: 0,
      height: 34,
      width: isDesktop() ? getScreenWidth() / 2.5 : '100%',
      flexDirection: 'column',
      paddingLeft: 12,
      paddingRight: 12,
      justifyContent: 'center',
    };

    const featuredListsContainerStyle = {
      height: this.productListHeight,
      position: 'absolute',
      bottom: this.featuredListsContainerBottom,
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      width: isDesktop() ? getScreenWidth() / 2.5 : '100%',
      marginBottom:
        Utility.isAndroid() && !this.fromContentPageCarousel && this.reviewsAsBottomTab ? 50 : 0,
    };

    const animationStyle = {
      position: 'absolute',
      height: 200,
      width: 200,
      resizeMode: 'cover',
      alignSelf: 'center',
      justifyContent: 'center',
      bottom: 100,
    };
    const requiredPlayerHeight = availableWidth * (videoHeight / videoWidth);
    if (isFullscreen) {
      // TODO: Handle full screen video in vertical
    } else if (shouldShowList) {
      if (requiredPlayerHeight <= availableHeight - 58) {
        playerContainerStyle.height = requiredPlayerHeight;
        const showableHeight =
          videoContainerStyle.height -
          (58 +
            CONTENT_PAGE_LAYOUT.ProductList.horizontalListHeight +
            Utility.bottomInset +
            34);
        if (requiredPlayerHeight > showableHeight) {
          playerContainerStyle.top = 0;
          playerContainerStyle.height = availableHeight;
        } else {
          const topDiff = (showableHeight - requiredPlayerHeight) / 2;
          playerContainerStyle.top = 58 + topDiff;
        }
      }
      playerMidControlsStyle.top =
        (videoContainerStyle.height -
          (CONTENT_PAGE_LAYOUT.ProductList.horizontalListHeight +
            Utility.bottomInset) +
          58 -
          44) /
          2 -
        50;
      bottomControlsContainerStyle.bottom = this.bottomControlsContainerBottom;
    } else {
      bottomControlsContainerStyle.bottom = this.bottomControlsContainerBottom;
    }

    if (!shouldShowList) {
      bottomControlsContainerStyle.bottom = isDesktop() ? 18 : 0;
      bottomControlsContainerStyle.height = Utility.isAndroid()
        ? 68 + Utility.bottomInset
        : 34 + Utility.bottomInset;
    }

    return {
      contentPageContainerStyle,
      videoContainerStyle,
      playerContainerStyle,
      playerStyle,
      bottomControlsContainerStyle,
      playerBottomControlsStyle,
      playerMidControlsStyle,
      headerContainerStyle,
      headerGradientStyle,
      progressBarContainerStyle,
      progressContainerStyle,
      avatarImageContainerStyle,
      textContainerStyle,
      contentTitleStyle,
      artistNameStyle,
      avatarImageStyle,
      followButtonStyle,
      featuredListsContainerStyle,
    };
  };

  normalizeAndAnimateToContentPage = (tappedIndex) => {
    // const { iteratorIndex, listData } = this.props;
    // const normalizedIndex =
    //   (iteratorIndex + 1 + tappedIndex) % listData.objects.length;
    // this.onMediaCardTapped(normalizedIndex);
  };

  renderMediaDetailListsContainer = (props) => {
    const { featuredListsContainerStyle, navigation, objects } = props;
    if (Utility.isBlank(objects)) {
      return null;
    }
    return (
      <Animated.View style={featuredListsContainerStyle}>
        <MediaDetailListsContainer
          objects={objects}
          navigation={navigation}
          videoMetadata={this.videoMetadata}
          onPress={this.normalizeAndAnimateToContentPage}
          showToast={this.showToast}
        />
      </Animated.View>
    );
  };

  saveMediaComponentRef = (ref) => {
    this.mediaComponent = ref;
  };

  saveToastRef = (ref) => {
    this.toastRef = ref;
  };

  saveBottomControlsRef = (ref) => {
    this.bottomControlsRef = ref;
  };

  showToast = (show, message) => {
    if (Utility.isBlank(message)) {
      return;
    }
    if (show) {
      this.toastRef.show(message);
    }
  };

  onSlidingStart = (value) => {
    if (this.mediaComponent) {
      this.mediaComponent.onSlidingStart(value);
    }
  };

  onSlidingComplete = (value) => {
    if (this.mediaComponent) {
      this.mediaComponent.onSlidingComplete(value);
    }
  };

  // this method is called when user taps on play/pause button in controls
  // and is called via props from VideoControls.
  togglePlayPause = () => {
    this.setState(
      (prevState) => ({ paused: !prevState.paused }),
      this.sendPlayPauseAnalytics,
    );
  };

  onFavoriteTapped = () => {
    const { authToken, like, unLike, videoData } = this.props;
    const { id, slug = '', metadata = {} } = videoData;

    const { contentPlatform = '' } = metadata;
    const { isLiked } = this.state;

    const type =
      contentPlatform === ContentPlatforms.youtube
        ? 'Youtube Video'
        : 'Foxy Video';

    const url = slug.replace('.json', isLiked ? '/unlike.json' : '/like.json');
    const data = {
      id,
      type: 'video',
      url,
    };
    const videoAnalyticsMeta = {
      [EventParameterKey.SCREEN_NAME]: 'video',
      [EventParameterKey.ITEM_ID]: id,
      [EventParameterKey.ITEM_NAME]: metadata?.title,
      [EventParameterKey.ITEM_TYPE]: 'video',
    };
    if (isLiked) {
      this.fireVideoCtaClickEvent('unlike');
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.ITEM_UNLIKE,
        videoAnalyticsMeta,
      );
      unLike(data);
      this.setState({ isLiked: false });
    } else {
      this.fireVideoCtaClickEvent('like');
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.ITEM_LIKE,
        videoAnalyticsMeta,
      );
      like(data);
      this.setState({ isLiked: true });
    }
  };

  reportBuffering = (isBuffering) => {
    this.setState({ isBuffering });
  };

  renderMiddleControls = (props) => {
    const { style } = props;
    const { isBuffering } = this.state;
    if (!isBuffering) {
      return null;
    }
    return (
      <PlayerMidControls
        style={style}
        nextButtonTapped={this.onNextButtonTapped}
        previousButtonTapped={this.onPreviousButtonTapped}
      />
    );
  };

  renderBottomControls = (props) => {
    const {
      listId,
      bottomControlsContainerStyle,
      playerBottomControlsStyle,
      duration,
    } = props;

    if (listId === 'CustomerReview') {
      return null;
    }

    const { isFullscreen, paused, isLiked, objects } = this.state;

    const shouldShowList = !Utility.isBlank(objects);
    // const shouldShowList = true;
    const gradientArray = ['rgba(0, 0, 0, 0.25)', 'transparent'];

    return (
      <Animated.View style={bottomControlsContainerStyle}>
        <LinearGradient
          colors={gradientArray}
          style={[constantStyles.coverContainer, isDesktop() && { width: getScreenWidth() / 2.5 }]}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
        >
          <PlayerBottomControls
            ref={this.saveBottomControlsRef}
            style={playerBottomControlsStyle}
            duration={duration}
            shouldShowList={shouldShowList}
            isFullscreen={isFullscreen}
            isFavorite={isLiked}
            onShareTapped={this.onSocialShareOptionTapped}
            onFavoriteTapped={this.onFavoriteTapped}
            toggleFullscreen={this.toggleFullscreen}
            onSlidingStart={this.onSlidingStart}
            onSlidingComplete={this.onSlidingComplete}
            togglePlayPause={this.togglePlayPause}
            nextButtonTapped={this.onNextButtonTapped}
            previousButtonTapped={this.onPreviousButtonTapped}
            paused={paused}
            fireVideoCtaClickEvent={this.fireVideoCtaClickEvent}
          />
        </LinearGradient>
      </Animated.View>
    );
  };

  RenderMediaViewComponent = () => {
    const {
      isFullscreen,
      isShareModalVisible,
      objects,
      rotatedListData,
      isCurrentIndex,
      didVideoStart,
      isBuffering,
    } = this.state;
    const {
      navigation,
      index,
      iteratorIndex,
      // listData,
      videoView,
      user_engagement,
      videoWatched,
      isFollowingArtist,
      showCrossButton,
      videoData,
      parentComponent = '',
      lastNotificationModalDisplayTime,
      showContentPageHeader = true,
      listId,
      publisherType = '',
      publisherSlug = '',
      publisherId,
    } = this.props;
    let { productRating } = this.props;
    const {
      image_url: imageUrl,
      entity: {
        name: entityName = '',
        image_url: entityThumbnail,
        id: entityId,
        slug: entitySlug,
        bio_handle: foxyHandle = '',
      } = {},
      metadata: {
        contentPlatform = 'youtube',
        videoUrl,
        title,
        id: videoId,
        orientation = 'horizontal',
        duration,
      },
      recommendation_text: recommendationText,
      rating = 0,
    } = videoData;
    productRating = rating > 0 ? rating : productRating;
    const paused = !isCurrentIndex || this.state.paused;
    const screenWidth = isDesktop() ? (getScreenWidth() / 2.5) : Dimensions.get('window').width;
    const shouldShowList = !Utility.isBlank(objects);

    const styles = this.getStyles();

    const progressViewStyle = {
      height: this.progressHeaderHeight,
      paddingLeft: 12.0,
      paddingRight: 12.0,
      justifyContent: 'center',
      flexDirection: 'row',
      width: screenWidth,
    };
    const config = {
      velocityThreshold: 0.3,
      directionalOffsetThreshold: 80,
    };

    const gradientArray = ['rgba(0, 0, 0, 0.5)', 'transparent'];
    return (
      <View style={styles.contentPageContainerStyle}>
        {showContentPageHeader && (
          <ContentPageHeader
            style={progressViewStyle}
            screenWidth={screenWidth}
            availableWidth={styles.videoContainerStyle.width}
            artistId={entityId}
            artistSlug={entitySlug}
            artistName={entityName}
            artistThumbnail={entityThumbnail}
            contentTitle={title}
            destination={entitySlug}
            contentSubtitle={recommendationText}
            headerContainerStyle={styles.headerContainerStyle}
            isFollowingArtist={isFollowingArtist}
            videoMetadata={this.videoMetadata}
            onCancelTap={this.onCancelTap}
            onFollowTapped={this.onFollowTapped}
            showCrossButton={showCrossButton}
            parentComponent={parentComponent}
            lastNotificationModalDisplayTime={lastNotificationModalDisplayTime}
            productRating={productRating}
            listId={listId}
            foxyHandle={foxyHandle}
            publisherType={publisherType}
            publisherSlug={publisherSlug}
            publisherId={publisherId}
          />
        )}
        <TapGestureHandler
          onHandlerStateChange={this.onMediaComponentTap}
          waitFor={this.doubleTapRef}
          numberOfTaps={1}
        >
          <TapGestureHandler
            ref={this.doubleTapRef}
            maxDelayMs={200}
            onHandlerStateChange={this.onMediaDoubleTap}
            numberOfTaps={2}
          >
            <View style={styles.videoContainerStyle}>
              <Animated.View style={styles.headerGradientStyle}>
                <LinearGradient
                  colors={gradientArray}
                  style={constantStyles.coverContainer}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 0, y: 1 }}
                />
              </Animated.View>
              <VerticalMediaImage
                isBuffering={isBuffering}
                showImage={paused}
                imageUrl={imageUrl}
                isContentPage
              />
              {isCurrentIndex && (
                <MediaComponent
                  onRef={this.saveMediaComponentRef}
                  videoId={videoId}
                  videoUrl={videoUrl}
                  videoTitle={title}
                  artistId={entityId}
                  artistName={entityName}
                  contentPlatform={contentPlatform}
                  duration={duration}
                  videoMetadata={this.videoMetadata}
                  orientation={orientation}
                  isFullscreen={isFullscreen}
                  // toggleFullscreen={this.toggleFullscreen}
                  shouldShowList={shouldShowList}
                  playerStyle={styles.playerStyle}
                  playerContainerStyle={styles.playerContainerStyle}
                  paused={paused}
                  imageUrl={imageUrl}
                  currentPage={index === iteratorIndex}
                  reportBuffering={this.reportBuffering}
                  setCurrentTime={this.setCurrentTime}
                  onLoadVideo={this.onLoadVideo}
                  onMediaComponentTap={this.onMediaComponentTap}
                  onMediaDoubleTap={this.onMediaDoubleTap}
                  onNextButtonTapped={this.onNextButtonTapped}
                  onNextButtonClick={this.onNextButtonClick}
                  videoView={videoView}
                  userEngagement={user_engagement}
                  videoWatched={videoWatched}
                  navigation={navigation}
                  seekToTimeOnLoad={this.currentTime}
                  productIdsObject={this.productIdsObject}
                  setDidVideoStart={this.setDidVideoStart}
                />
              )}
              <this.renderMiddleControls
                style={styles.playerMidControlsStyle}
                togglePlayPause={this.togglePlayPause}
                contentPlatform={contentPlatform}
              />
            </View>
          </TapGestureHandler>
        </TapGestureHandler>
        <this.renderGif />
        <this.renderBottomControls
          bottomControlsContainerStyle={styles.bottomControlsContainerStyle}
          playerBottomControlsStyle={styles.playerBottomControlsStyle}
          contentPlatform={contentPlatform}
          duration={duration}
          listId={listId}
        />
        <this.renderMediaDetailListsContainer
          featuredListsContainerStyle={styles.featuredListsContainerStyle}
          objects={objects}
          navigation={navigation}
        />
        <Toast ref={this.saveToastRef} />
        <ShareModal
          isShareModalVisible={isShareModalVisible}
          hideShareModal={this.hideShareModal}
          onSocialShareOptionTapped={this.onSocialShareOptionTapped}
          image={imageUrl}
          name={title}
          subHeading={`Watch this amazing video on ${Config.APP_NAME}`}
        />
      </View>
    );
  };

  handleRouteFromLink = (route, slug, path, extra) => {
    const { navigation } = this.props;
    navigation.navigate(route, { slug, extra });
  };

  // For navigation within app if url includes foxy.in
  onNavigationStateChange = () => {
    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      this.ctaAction,
      this.handleRouteFromLink,
    );
    this.fireVideoCtaClickEvent('gif', this.ctaAction);
  };

  renderGif = () => {
    const { didVideoStart } = this.state;
    if (!this.ctaAction || !didVideoStart) return null;
    return (
      <View style={constantStyles.gifContainer}>
        <ScaleAnimate {...this.props} onPress={this.onNavigationStateChange}>
          <Image source={gifs[this.ctaGif]} style={constantStyles.gifImage} />
        </ScaleAnimate>
      </View>
    );
  };

  setDidVideoStart = (didVideoStart) => {
    this.setState({ didVideoStart });
  };

  RenderActivityIndicator = () => {
    return Utility.getActivityIndicator(constantStyles.activityIndicator);
  };

  ErrorView = () => <ErrorPage onRetryTap={this.onCancelTap} />;

  isDataLoadingFn = () => this.props.isLoading;

  noVideoDataFn = () => {
    return Utility.isBlank(this.props.videoData);
  };

  withConditionalRenderings = compose(
    withEither(this.isDataLoadingFn, this.RenderActivityIndicator),
    withEither(this.noVideoDataFn, this.ErrorView),
  );

  mediaComponentWithConditionRendering = this.withConditionalRenderings(
    this.RenderMediaViewComponent,
  );

  fireVideoCtaClickEvent = (cta, action) => {
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_CTA_CLICK, {
      [EventParameterKey.CTA]: cta,
      [EventParameterKey.ACTION]: action ? action : null,
      ...this.videoMetadata,
    });
  };

  render() {
    const { videoData } = this.props;

    const {
      metadata: { width, duration },
    } = videoData;
    if (
      Utility.isBlank(videoData) ||
      Utility.isBlank(duration) ||
      Utility.isBlank(width)
    ) {
      return <View style={constantStyles.container} />;
    }

    return (
      <View style={constantStyles.container}>
        <this.mediaComponentWithConditionRendering />
      </View>
    );
  }
}

ContentPage.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  id: PropTypes.string,
  slug: PropTypes.string,
  // listData: PropTypes.shape({
  //   id: PropTypes.string,
  //   objects: PropTypes.array,
  // }),
  index: PropTypes.number,
  // areObjectsLoading: PropTypes.bool,
  iteratorIndex: PropTypes.number.isRequired,
  goToPrevPage: PropTypes.func.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToNumberedPage: PropTypes.func.isRequired,
  seekToTimeOnLoad: PropTypes.number,
  videoViewed: PropTypes.func,
  canShowCloseButton: PropTypes.bool,
  bottomPaddingToAdd: PropTypes.number,
  publisherType: PropTypes.string,
  publisherSlug: PropTypes.string,
};

ContentPage.defaultProps = {
  isLoading: false,
  id: null,
  slug: null,
  error: null,
  // listData: {},
  index: 0,
  // areObjectsLoading: false,
  seekToTimeOnLoad: 0,
  canShowCloseButton: true,
  bottomPaddingToAdd: 0,
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateMediaId,
      followArtist,
      unFollowArtist,
      // viewItem,
      like,
      unLike,
      videoView,
      videoWatched,
    },
    dispatch,
  ),
});

const constantStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
  },
  coverContainer: {
    width: '100%',
    height: '100%',
  },
  gifContainer: {
    position: 'absolute',
    height: 200,
    width: 200,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    bottom: '2%',
  },
  gifImage: {
    height: 200,
    width: 200,
    resizeMode: 'contain',
  },
  activityIndicator: {
    flex: 1,
    justifyContent: 'center',
  },
});

export default withNavigation(connect(null, mapDispatchToProps)(ContentPage));
