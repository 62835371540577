// Dependencies
import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  TouchableHighlight,
  TouchableOpacity,
  Text,
} from 'react-native';
import LinearGradient from '../libraries/ReactNativeLinearGradient';
import PropTypes from 'prop-types';
import CardView from '../libraries/ReactNativeCardView';
import _ from 'lodash';
import ScaleAnimate from '../components/shared/ScaleAnimate';

// Components, Styles ,...
import images from '../theme/Images';
import Utility from '../utils/Utility';

class ContentCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, onPress, selectedContentCategory } = this.props;
    const GradientArray = [
      'transparent',
      'transparent',
      'transparent',
      'rgba(0,0,0,0.9)',
    ];
    const iconName = selectedContentCategory.includes(item.id)
      ? images.interest.selected
      : images.interest.unselected;
    return (
      <ScaleAnimate {...this.props} onPress={onPress(item.id)}>
        <CardView
          cardElevation={2}
          cornerRadius={4}
          cardMaxElevation={4}
          style={{
            margin: 4,
            borderRadius: 4,
            overflow: 'hidden',
            width: _.memoize(Utility.getDynamicWidth)(3, 32),
            height: _.memoize(Utility.getDynamicWidth)(3, 32),
          }}
        >
          <ImageBackground
            source={{ uri: Utility.getMinifiedImage(item.image_url, 120, 120) }}
            style={{
              width: _.memoize(Utility.getDynamicWidth)(3, 32),
              height: _.memoize(Utility.getDynamicWidth)(3, 32),
              borderRadius: 4,
              overflow: 'hidden',
            }}
            imageStyle={{ borderRadius: 4 }}
          >
            <LinearGradient
              colors={GradientArray}
              style={{
                flex: 1,
                padding: 6,
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              start={{ x: 0, y: 0 }}
              end={{ x: 0, y: 1 }}
            >
              <TouchableOpacity
                style={{
                  width: 19,
                  height: 19,
                  alignSelf: 'flex-end',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={onPress(item.id)}
              >
                <Image source={iconName} />
              </TouchableOpacity>
              <Text
                numberOfLines={2}
                style={{
                  position: 'absolute',
                  fontSize: 12,
                  color: 'white',
                  fontFamily: 'Roboto-Medium',
                  fontStyle: 'normal',
                  letterSpacing: 0,
                  elevation: 1,
                  shadowRadius: 2,
                  shadowColor: 'black',
                  shadowOpacity: 1,
                  bottom: 4,
                  left: 6,
                  right: 6,
                  zIndex: 1,
                  shadowOffset: { width: 1, height: 1 },
                }}
              >
                {item.name}
              </Text>
            </LinearGradient>
          </ImageBackground>
        </CardView>
      </ScaleAnimate>
    );
  }
}

ContentCategory.propTypes = {
  item: PropTypes.any,
  index: PropTypes.number,
  onPress: PropTypes.func,
  selectedContentCategory: PropTypes.array,
};

ContentCategory.defaultProps = {
  onPress: () => {},
  selectedContentCategory: [],
};

export default ContentCategory;
