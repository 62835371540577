import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Linking,
} from 'react-native';
import React from 'react';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import { EXTERNAL_LINKS } from '../../config/Constants';
import images from '../../theme/Images';
import Config from '../../libraries/ReactNativeConfig';
import { isPresent } from '../../utils/BooleanUtility';

export default function FoxyContactDetails() {
  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      <ActionContact url={EXTERNAL_LINKS.mailToFoxy} icon={images.mailIcon} text={Config.CONTACT_MAIL} />
      {isPresent(Config.CONTACT_US_NUMBER) && <ActionContact url={EXTERNAL_LINKS.whatsappFoxy} icon={images.phoneIcon} text={Config.CONTACT_US_NUMBER} />}
      <TextContact
        icon={images.locationPinIcon}
        text={`${Config.COMPANY_NAME}, ${Config.CONTACT_ADDRESS}`}
      />
    </View>
  );
}

const ActionContact = ({ url, icon, text }) => (
  <TouchableOpacity
    onPress={() => Linking.openURL(url)}
    style={styles.contactItem}
  >
    <Image
      source={{ uri: icon }}
      style={styles.image}
      dataSet={{ media: ids.image }}
    />
    <Text style={styles.text}>{text}</Text>
  </TouchableOpacity>
);

const TextContact = ({ icon, text }) => (
  <View style={styles.contactItem}>
    <Image
      source={{ uri: icon }}
      style={styles.image}
    />
    <Text style={styles.text}>{text}</Text>
  </View>
);

const { ids, styles } = StyleSheet.create({
  container: {
    height: 80,
    width: '100%',
    backgroundColor: '#f4f6f8',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    '@media (min-width: 768px)': {
      height: 110,
    },
  },
  contactItem: {
    height: '100%',
    width: '28%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 30,
    height: 30,
    '@media (min-width: 768px)': {
      width: 40,
      height: 40,
    },
  },
  text: {
    color: colors.black,
    marginLeft: '3.5%',
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    minWidth: 100,
  },
});
