import _ from 'lodash';
import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import { FlatList } from 'react-native-gesture-handler';
import withNavigation from '../../../utils/WithNavigation';
import { AnalyticsManager } from '../../../analytics';
import { REMOTE_CONFIG_KEYS } from '../../../config/Constants';
import colors from '../../../theme/Colors';
import images from '../../../theme/Images';
import DynamicLinkManager from '../../../utils/DynamicLinkManager';
import RemoteConfig from '../../../utils/RemoteConfig';
import Utility from '../../../utils/Utility';
import RateProductActionCard from '../../layout/cards/RateProductActionCard';
import { navigateToScreen } from '../../../utils/NavigationUtility';

class RateProductCards extends Component {
  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.onFocus();
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  navigateToScreen = (route, slug, path, extra = {}, params, url) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      params: {
        slug,
        extra,
        params,
        url,
      },
    });
  };

  primaryButtonAction = (slug, sku_id) => {
    const { id = {}, item = {}, orderId } = this.props;

    AnalyticsManager.logEvent('card_action', {
      entity_type: id.split('-')[0],
      entity_id: id.split('-')[1],
      card_id: item.id,
      button: 'primary',
    });

    const url = `${Config.HTTPS_DOMAIN_NAME}/post_review?slug=/api/v2/products/${slug}&rating=0&order_id=${orderId}&sku_id=${sku_id}`;
    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      url,
      this.navigateToScreen,
    );
  };

  renderItem = ({ item = {}, index }) => {
    const { orderItemsData } = this.props;
    const { sku: { isRated = false, slug, id } = {} } = item;
    if (item?.sku?.itemType === 'offer') {
      return null;
    }
    if (isRated) {
      return null;
    }

    const rateProductActionCardStyles = {
      width:
        orderItemsData.length == 1
          ? Utility.getScreenWidth() - 24
          : Utility.getScreenWidth() - 72,
    };

    return (
      <RateProductActionCard
        backgroundColor='#EAF5FF'
        imageUrl={item?.sku?.imageUrl}
        slug={item?.sku?.slug}
        heading='Help others make the right purchase!'
        subheading={`Share your opinion on ${item?.sku?.name}, and earn a free gift! 😉`}
        firstActionButtonText={'Rate Product'}
        style={rateProductActionCardStyles}
        buttonWidth={
          orderItemsData.length == 1
            ? Utility.getScreenWidth() - 24 - 24
            : Utility.getScreenWidth() - 72 - 24
        }
        firstActionIcon={images.empty_star}
        primaryButtonAction={_.memoize(
          () => this.primaryButtonAction(slug, id),
          () => [slug],
        )}
      />
    );
  };

  onFocus = () => {
    const { onRefresh } = this.props;

    onRefresh();
  };

  listHeaderComponent = () => {
    const { orderItemsData } = this.props;

    const nonRatedSkus = _.filter(
      orderItemsData,
      (orderItem) => !orderItem?.sku?.isRated,
    );

    if (Utility.isBlank(nonRatedSkus)) {
      return null;
    }

    const rateProductData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.rate_product_data),
    );
    return (
      <RateProductActionCard
        backgroundColor={colors.powderBlue}
        imageUrl={rateProductData?.image}
        heading={rateProductData?.heading}
        subheading={rateProductData?.subheading}
        style={styles.header}
        buttonWidth={Utility.getScreenWidth() - 72 - 24}
        firstActionIcon={images.empty_star}
        noOfLines={4}
      />
    );
  };

  render() {
    const { orderItemsData } = this.props;

    return (
      <>
        <FlatList
          data={orderItemsData}
          renderItem={this.renderItem}
          horizontal
          showsHorizontalScrollIndicator={false}
          ListHeaderComponent={this.listHeaderComponent}
          contentContainerStyle={styles.containerFlatlist}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  header: { width: Utility.getScreenWidth() / 2 },
  containerFlatlist: {
    paddingLeft: 12,
  },
});

export default withNavigation(RateProductCards);
