// Dependencies
import React, { Component } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { Rating } from 'react-native-rating-element';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withMaybe } from '../../lib/Monads';
import Utility from '../../utils/Utility';
// Components
import ProductCardStyles from './styles/styles';
import {
  THEME,
  LAYOUT,
  SIZE,
} from '../../config/Constants';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import sizes from '../../theme/Fonts';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import { getProductOfferMessage } from '../../utils/OffersUtil';
import { getPriceText } from '../../utils/NumberUtility';
import { isWeb } from '../../utils/BooleanUtility';

class ProductDescription extends Component {
  constructor(props) {
    super(props);
    const { name = '', brandName = '' } = props;
    const rep = new RegExp(brandName, 'gi');
    this.name = name.replace(rep, '').trim();
  }

  shortenName = (name, limit) =>
    name.length > limit ? (name = `${name.substring(0, limit)}...`) : name;

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  discountConditionFn = (props) =>
    parseFloat(props.sp) === parseFloat(props.mrp);

  smallListCondition = (props) =>
    props.layout === LAYOUT.LIST && props.size === SIZE.small;

  showBrandCondition = () => true;

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  brandNameText = (props) => {
    const { size, layout } = props;
    const { hideBrandName = false } = this.props;
    if (hideBrandName) {
      return null;
    }
    return (
      <Text
        numberOfLines={PRODUCT.MAX_LINES.BRAND_NAME[layout][size] || 1}
        style={ProductCardStyles[`${layout}BrandName`]}
      >
        {props.name?.toUpperCase()}
      </Text>
    );
  };
  brandNameImage = (props) => (
    <View style={ProductCardStyles.brandContainerStyle}>
      <Image
        source={{ uri: props.thumbnail }}
        style={ProductCardStyles.brandImageStyle}
      />
    </View>
  );

  brand = this.brandNameText;

  discountSection = (props) => {
    const { discount, size } = props;
    const { layout, enableGrayscale } = this.props;
    let style = ProductCardStyles[`${layout}DiscountRateStyle`];
    if (size === 'large' && layout === LAYOUT.RAIL) {
      style = ProductCardStyles[`${size}${layout}DiscountRateStyle`];
    }
    if (enableGrayscale) {
      style = [style, { color: colors.subtitle }];
    }
    const disc = discount || '';
    return (
      <this.conditionalDiscountText style={style} discount={discount}>
        {disc}
      </this.conditionalDiscountText>
    );
  };

  PricingComponent = (props) => {
    let { display_price: displayPrice, leftAlignText, itemData } = this.props;

    let { mrp, sp, discount, theme, layout, size } = props;
    let displayDiscount = `(${discount})`;
    const alignment = leftAlignText ? 'LeftAligned' : '';
    let spStyle = [
      ProductCardStyles[`${theme}spStyle`],
      ProductCardStyles[`${layout}spStyle`],
    ];
    let priceContainer =
      ProductCardStyles[`${layout}PriceContainer${alignment}`];
    let conditionalPriceTextStyle = ProductCardStyles[`${layout}mrpStyle`];

    if (size === 'large' && layout === LAYOUT.RAIL) {
      priceContainer =
        ProductCardStyles[`${size}${layout}PriceContainer${alignment}`];
      spStyle = ProductCardStyles[`${size}${layout}spStyle`];
      conditionalPriceTextStyle = ProductCardStyles[`${size}${layout}mrpStyle`];
    }

    if (itemData.gwp) {
      return (
        <View style={priceContainer}>
          <Text style={spStyle}>{`Gift*`}</Text>
        </View>
      );
    }
    return (
      <View style={priceContainer}>
        <Text style={spStyle}>{`${getPriceText(
          parseFloat(displayPrice),
        )} `}</Text>
        <this.conditionalPriceText
          style={conditionalPriceTextStyle}
          sp={sp}
          mrp={mrp}
        >
          {getPriceText(parseFloat(mrp))}
        </this.conditionalPriceText>

        {parseFloat(mrp) !== parseFloat(sp) && (
          <this.discountSection
            displayDiscount={displayDiscount}
            discount={discount}
            size={size}
          />
        )}
      </View>
    );
  };

  renderTodayDealsOrPromptMessage = () => {
    const styles = ProductCardStyles;
    const {
      itemData = {},
      disableTodayDealsOrPromptMessage = false,
      todayDeals = {},
      boostedOffers = {},
      size = 'defaultSize',
      layout = '',
    } = this.props;

    if (disableTodayDealsOrPromptMessage) return null;

    const message = getProductOfferMessage(itemData, todayDeals, boostedOffers);

    if (Utility.isBlank(message)) {
      return <View style={styles[`${size}GreenTextPlaceholder`]} />;
    }

    const discountMessageStyle =
      size === 'large' ? styles.largeSpecialPriceText : styles.specialPriceText;
    const discountTextStyle =
      layout === 'slimGrid'
        ? styles.slimGridDiscountPriceText
        : discountMessageStyle;

    return (
      <Text
        style={discountTextStyle}
        allowFontScaling={false}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {message}
      </Text>
    );
  };

  bottomRating = () => {
    const { ratingsPosition = '', rating = '' } = this.props;
    if (
      Utility.isBlank(ratingsPosition) ||
      ratingsPosition === 'top_right' ||
      ratingsPosition === 'hide' ||
      Utility.isBlank(rating)
    )
      return null;
    return (
      <View style={ProductCardStyles.bottomRatingContainer}>
        <Rating
          rated={parseFloat(rating)}
          totalCount={5}
          size={12}
          marginBetweenRatingIcon={3}
          direction='row'
          type='custom' // default is always to "icon"
          selectedIconImage={images.rating.filled_star}
          emptyIconImage={images.empty_star}
        />
        <Text style={ProductCardStyles.bottomRatingText}>{rating}</Text>
      </View>
    );
  };

  heroDescription = () => {
    const {
      heroDescription = '',
      subtitleLineCount,
      hideBrandName,
    } = this.props;
    if (
      !hideBrandName ||
      Utility.isBlank(subtitleLineCount) ||
      parseInt(subtitleLineCount) === 0
    )
      return null;
    const subtitleCountStyle =
      subtitleLineCount === 1 ? 'singleLine' : 'multiLine';
    const heroDescriptionContainer =
      ProductCardStyles[`${subtitleCountStyle}HeroDescriptionContainer`] || {};
    return (
      <View style={heroDescriptionContainer}>
        <Text
          allowFontScaling={false}
          numberOfLines={subtitleLineCount}
          ellipsizeMode={'tail'}
          style={ProductCardStyles.heroDescription}
        >
          {heroDescription}
        </Text>
      </View>
    );
  };

  productName = () => {
    const {
      titleLineCount = 2,
      layout = LAYOUT.GRID,
      leftAlignText = false,
      theme = THEME.LIGHT,
      size = 'defaultSize',
      name = '',
      brandName = '',
    } = this.props;
    const rep = new RegExp(brandName, 'gi');
    const productName = name.replace(rep, '').trim();
    const alignment = leftAlignText ? 'LeftAligned' : '';
    // TODO: This object is instantiated as many times as there are cards in the list. Should be a simple constant.
    let productNameStyle = [
      ProductCardStyles[`${theme}ProductName${alignment}`],
      ProductCardStyles[`${layout}ProductName${alignment}`],
    ];

    if (size === 'large' && layout === LAYOUT.RAIL) {
      productNameStyle =
        ProductCardStyles[`${size}${layout}ProductName${alignment}`];
    }

    const titleCountStyle = titleLineCount === 1 ? 'SingleLine' : 'MultiLine';
    const productNameContainer =
      ProductCardStyles[`${layout}${titleCountStyle}ProductNameContainer`] ||
      {};
    return (
      <View style={productNameContainer}>
        <Text
          allowFontScaling={false}
          numberOfLines={titleLineCount}
          ellipsizeMode={'tail'}
          style={[productNameStyle, isWeb() && styles.webProductName]}
        >
          {productName}
        </Text>
      </View>
    );
  };

  render() {
    const styles = ProductCardStyles;
    const {
      mrp,
      discount,
      layout = LAYOUT.GRID,
      size = 'defaultSize',
      theme = THEME.LIGHT,
      renderProp,
      brandName = '',
      prompts = [],
      couponCodes,
      display_price,
      matchedProduct,
      onlyPricing = false,
      excludePricing = false,
      itemData = {},
      children,
      leftAlignText = false,
    } = this.props;

    const { variants_details: { principal_sku_id } = {}, sku_id } = itemData;
    const skuToCheck = principal_sku_id || sku_id;
    const { offersEsp, promptMrp, promptDiscount } = Utility.getEsp(
      prompts,
      couponCodes,
    );

    const alignment = leftAlignText ? 'LeftAligned' : '';
    const isMatchedProduct = matchedProduct ? 'Matched' : '';
    let containerStyle =
      styles[`desriptionContainer${isMatchedProduct}${alignment}`];
    if (size === 'large' && layout === LAYOUT.RAIL) {
      containerStyle = styles[`largeDescriptionContainer${alignment}`];
    }

    if (onlyPricing) {
      return (
        <this.PricingComponent
          mrp={mrp}
          sp={display_price}
          discount={discount}
          theme={theme}
          layout={layout}
          size={size}
          offersEsp={offersEsp}
          promptMrp={promptMrp}
          promptDiscount={promptDiscount}
        />
      );
    }
    return (
      <View style={containerStyle}>
        <this.brand layout={layout} size={size} name={brandName} />
        <this.productName />
        <this.heroDescription />
        {renderProp && renderProp()}
        {!excludePricing && (
          <this.PricingComponent
            mrp={mrp}
            sp={display_price}
            discount={discount}
            theme={theme}
            layout={layout}
            size={size}
            offersEsp={offersEsp}
            promptMrp={promptMrp}
            promptDiscount={promptDiscount}
          />
        )}
        {children || <this.renderTodayDealsOrPromptMessage />}
        <this.bottomRating />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tagImageContainer: {
    width: 75,
    height: 14,
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 8,
  },
  tagTextStyle: {
    fontSize: sizes.h5,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },
  webProductName: {
    lineHeight: 17,
  },
});

// PropTypes
ProductDescription.propTypes = {
  mrp: PropTypes.number,
  sp: PropTypes.number,
  discount: PropTypes.string,
  brandName: PropTypes.string,
  theme: PropTypes.string,
};

ProductDescription.defaultProps = {
  theme: THEME.LIGHT,
};

ProductDescription.defaultProps = {
  brandName: '',
};

const mapStateToProps = (state) => {
  return {
    couponCodes:
      state.bag && state.bag.cartPricing && state.bag.cartPricing.coupon_codes
        ? state.bag.cartPricing.coupon_codes
        : [],
    todayDeals: state.todayDeals,
    boostedOffers: state.boostedOffers,
  };
};

export default connect(mapStateToProps, null)(ProductDescription);
