import { Dimensions, Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';
import size from '../../../theme/Fonts';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import Config from '../../../libraries/ReactNativeConfig';
import {
  isDesktop,
  isIOS,
  isPresent,
  isWeb,
} from '../../../utils/BooleanUtility';
import { getScreenWidth } from '../../../utils/LayoutUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../../config/LayoutConstants/ProductConfig';

const numOfItems = isDesktop() ? 4 : 2;

const screenWidth = Dimensions.get('window').width;
const bottomSpace = Utility.isIOS() ? 4 : 8;
const bottomMargin = Utility.isIOS() ? 12 : 0;
const iconsBottomSpace = bottomSpace + 4;
const iconSize = isDesktop() ? 33 : (Utility.getScreenWidth() - 120) / 7;
export const { ids: productDetailIds, styles: ProductDetailStyles } = StyleSheet.create({
  container: {
    marginHorizontal: 12,
    flexDirection: 'row',
  },
  walletImage: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    marginRight: 4,
  },
  walletText: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
  },
  descriptionText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginLeft: 12,
    marginBottom: 4,
  },

  scrollview: {
    marginBottom: 60,
  },
  SafeAreaView: {
    backgroundColor: '#fff',
    top: Utility.topInset,
    bottom: Utility.bottomInset,
    '@media (min-width: 800px)': {
      width: PRODUCT_RIGHT_CONTAINER_WIDTH,
      marginLeft: 10,
      backgroundColor: 'transparent',
    },
  },
  navigationHeader: {
    width: screenWidth,
    overflow: 'visible',
    backgroundColor: 'white',
    zIndex: 2,
    flexDirection: 'row',
    elevation: 2,
    alignItems: 'baseline',
  },
  headerImageGradientBackground: {
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
  stockedStatusContainer: {
    paddingHorizontal: 20,
    backgroundColor: '#FFECEC',
    paddingVertical: 8,
  },
  stockedStatusText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: '#000',
  },
  notReviewedMessageContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 20,
    backgroundColor: colors.white,
  },
  notReviewedMessageTextContainer: {
    padding: 2,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notReviewedMessageTextContainerVariant: {
    padding: 2,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  notReviewedMessageText: {
    fontFamily: 'Roboto-Medium',
    color: '#007fff',
    fontSize: size.h2,
    marginRight: 3,
  },
  notReviewedMessageTextVariant: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,

    fontSize: size.h3,
    marginRight: 8,
    marginLeft: 16,
    marginHorizontal: 10,
  },
  notReviewedMessageIcon: {
    width: 16,
    height: 16,
  },
  searchIcon: { width: 24, height: 24, resizeMode: 'contain' },
  askForReviewButtonContainer: {
    height: 40,
    width: 150,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 2,
    padding: 2,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  askForReviewButtonText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h3,
  },
  headerImageBackground: {
    // position: 'absolute',
    width: screenWidth,
    height: '100%',
    resizeMode: 'stretch',
    backgroundColor: 'white',
    zIndex: 0,
  },

  navigationHeaderChildView: {
    height: 40,
    position: 'absolute',
    bottom: bottomSpace,
    left: 36,
    right: 110,
    backgroundColor: 'transparent',
    zIndex: 3,
  },
  gradientStyle: {
    position: 'absolute',
    width: screenWidth,
    height: '100%',
    zIndex: 0,
  },
  navbarIconDefaultStyle: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  navbarShareIconStyle: {
    height: 24,
    width: 24,
    tintColor: isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
  },
  addUsersIconStyle: {
    height: 20,
    width: 22,
    tintColor: colors.black,
    resizeMode: 'contain',
  },
  productRatingContainerStyle: {
    height: 20,
    backgroundColor: '#01b460',
    marginLeft: 4,
    borderRadius: 4,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  productRatingTextStyle: {
    color: 'white',
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 4,
    marginRight: 4,
  },
  productNameStyle: {
    fontSize: 18,
    color: '#173143',
    fontFamily: 'Roboto-Bold',
    lineHeight: 21,
  },
  productDescriptionHeroStyle: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    lineHeight: 16,
  },
  productDescriptionContainerStyle: {
    paddingHorizontal: 12,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: colors.white,
    paddingTop: 12,
  },
  brandNameContainer: { flexDirection: 'row' },
  navigationBackButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    left: 12,
    bottom: iconsBottomSpace,
  },
  navigationShareButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: iconsBottomSpace,
    right: 52,
  },
  fullScreenVariantSearchButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: iconsBottomSpace,
    right: 16,
  },
  fullScreenVariantSearchButton: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  navigationContainer: {
    position: 'absolute',
    width: Utility.screenWidth,
    overflow: 'visible',
    zIndex: 3,
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  navigationEditButtonContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: iconsBottomSpace,
    right: 12,
  },
  brandImageStyle: {
    height: 25,
    resizeMode: 'contain',
    width: 75,
  },
  brandNameStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h2,
    letterSpacing: 0,
    color: colors.disabled,

    fontStyle: 'normal',
  },
  offerCardFlatlistStyle: {
    marginLeft: 7,
    overflow: 'visible',
    marginTop: 8,
  },
  offerCardStyle: {
    flex: 1,
    overflow: 'hidden',
    height: 76,
    width: 240,
    borderRadius: 4,
    backgroundColor: 'white',
  },
  offerSelectedBorderStyle: {
    borderColor: '#01b460',
  },
  offerUnSelectedBorderStyle: {
    borderColor: '#eaeaea',
  },
  offerApplyButtonStyle: {
    position: 'absolute',
    zIndex: 1,
    height: 26,
    width: 87,
    backgroundColor: 'transparent',
    borderRadius: 6,
    bottom: 10,
    right: 10,
    borderWidth: 1,
    justifyContent: 'center',
    alignContent: 'center',
  },
  offerApplyInnerButtonStyle: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 6,
    margin: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  applyButtonCircularIndicatorStyle: {
    height: 16,
    width: 16,
    borderRadius: 8,
    marginLeft: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  applyTextStyle: {
    marginLeft: 4,
    fontSize: 11,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#000000',
  },
  whiteTickImageStyle: {
    height: 12,
    width: 12,
  },
  offerUnSelectedBackgroundStyle: { backgroundColor: '#eaeaea' },
  offerSelectedBackgroundStyle: { backgroundColor: '#01b460' },
  productVariantContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  productSizeVariantContainer: {
    flexDirection: 'column',
  },
  productWhiteSizeVariantContainer: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    paddingTop: 4,
    '@media (min-width: 768px)': {
      padding: 10,
    },
  },
  whiteBackground: {
    backgroundColor: colors.white,
  },
  sizeVariantText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
    marginLeft: 12,
    marginRight: 12,
  },
  disabledSizeVariantText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.border,
    marginLeft: 12,
    marginRight: 12,
  },
  productVariantImageBackground: {
    height: iconSize,
    width: iconSize,
    borderRadius: iconSize / 2,
    // margin: 8,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productVariantWhiteImageBackground: {
    height: iconSize,
    width: iconSize,
    borderRadius: iconSize / 2,
    // margin: 8,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  ImageBackgroundContainer: {
    height: iconSize + 4,
    width: iconSize + 4,
    borderRadius: (iconSize + 4) / 2,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 4,
    marginBottom: 4,
    borderWidth: 1.5,
    padding: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreVariantsContainer: {
    height: iconSize,
    width: iconSize,
    borderRadius: iconSize / 2,
    margin: 8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
  },
  variantNameText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: colors.foxyBlack,
  },
  disabledSizePillLine: {
    width: 1,
    transform: [{ rotate: '45deg' }],
    height: 45,
    position: 'absolute',
    zIndex: 1,
    backgroundColor: colors.border,
  },
  sizeContainer: {
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.border,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  sizeContainerSelected: {
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.havelockBlue,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  flexWrappedSizeContainer: {
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.border,
    marginRight: 8,
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  flexWrappedSizeContainerSelected: {
    height: 40,
    minWidth: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.havelockBlue,
    marginRight: 8,
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  productVariantTickImage: {
    height: 6,
    width: 6,
    resizeMode: 'contain',
  },
  variantNameTextStyle: {
    fontFamily: 'Roboto-Regular',
    marginLeft: 12,
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#979BAA',
  },
  darkTextStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 16,
    color: colors.black,
    maxWidth: '70%',
  },
  darkTextStyleReturn: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 16,
    color: colors.black,
  },
  darkTextStyleVariantName: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 2,
    color: colors.foxyBlack,
  },
  greyTextStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 4,
    color: colors.black,
  },
  greyTextStyleWhite: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 4,
    color: colors.white,
  },
  variantNameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  variantNameContainerUnavailableVariant: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
    paddingTop: 10,
  },
  flexRow: { flexDirection: 'row' },
  whiteTickContainer: {
    height: 14,
    width: 14,
    borderRadius: 7,
    borderWidth: 1.5,
    borderColor: 'white',
    padding: 2.5,
    position: 'absolute',
    bottom: -2,
    right: -2,
    backgroundColor: 'black',
  },
  oosContainer: {
    height: '100%',
    width: 2,
    backgroundColor: 'white',
    position: 'absolute',
    transform: [{ rotateZ: '45deg' }],
  },
  packSizeVariantContainer: {
    flexDirection: 'row',
  },
  flatListItemStyle: {
    marginTop: 10,
    marginLeft: 10,
  },
  flexWrappedContentContainerStyle: { flexDirection: 'row', flexWrap: 'wrap' },
  secondaryVariantFlatlistStyle: {
    flexWrap: 'wrap',
    marginLeft: 10,
    marginTop: 10,
  },
  secondaryVariantFlatlistContainerStyle: {
    paddingHorizontal: 8,
  },
  variantAttributeContainer: {
    flexDirection: 'row',
  },
  variantViewAllButton: {
    color: colors.cta.lightBlue,
    marginRight: 12,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  extraAttributeButtonStyle: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#eaeaea',
    flex: 1,
    height: 54,
    width: 114,
    marginTop: 16,
    marginLeft: 8,
    marginRight: 12,
    alignSelf: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 12,
  },
  variantText: {
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    fontSize: 12,
  },
  additionalAttributeCirclesStyle: {
    margin: 8,
    overflow: 'visible',
    marginLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  extraVariantCircle: {
    height: 12,
    width: 12,
    borderRadius: 6,
    overflow: 'hidden',
    marginLeft: -5,
  },
  viewAllReviews: {
    height: 54,
    flexDirection: 'column',
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'space-around',
  },
  viewAllReviewsText: {
    lineHeight: 19,
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 16,
  },
  fullWidthSeperator: {
    height: 1,
    width: '100%',
    backgroundColor: colors.border,
  },
  viewAllReviewArrow: {
    position: 'absolute',
    right: 16,
    height: 12,
    top: 21,
    tintColor: colors.foxyBlack,
  },
  pricingAndCartContainer: {
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 100,
    borderTopWidth: 1,
    borderTopColor: colors.border,
    paddingHorizontal: 12,
    ...Platform.select({
      web: { position: 'fixed', height: 68 },
      android: { position: 'absolute', height: 68 },
      ios: { position: 'absolute', height: 78 },
    }),
  },
  pricingAndCartContainerWithoutAbsolute: {
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 100,
    paddingHorizontal: 12,
  },
  PricingContainerDesktop: {
    width: '100%',
    zIndex: 100,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    marginTop: 2,
  },
  pricingAndCartContainerRelative: {
    height: Utility.isAndroid() ? 68 : 78,
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 100,
    borderTopWidth: 1,
    borderTopColor: colors.border,
    paddingHorizontal: 12,
  },
  pricingAndCartContainerVariantModal: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 100,
    borderTopWidth: 1,
    borderTopColor: colors.border,
    paddingRight: 12,
    paddingBottom: Utility.isIOS() ? 16 : 0,
  },
  pricingContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    marginLeft: 16,
    marginBottom: 8,
  },
  pricingContainerTop: {
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    paddingLeft: 12,
  },
  gradientDivider: {
    height: 2,
    width: screenWidth,
  },
  bagContainer: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginRight: 16,
    marginVertical: 8,
    // width: 172,
    marginLeft: 16,
  },
  bagContainerVariantModal: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginVertical: 8,
    width: (Utility.getScreenWidth() - 24 - 8) / 2,
    backgroundColor: Utility.isPresent(Config.PRODUCT_PAGE_BUY_NOW_BG_COLOR)
      ? Config.PRODUCT_PAGE_BUY_NOW_BG_COLOR
      : colors.linearGradientGreenSecond,
  },
  bagContainerSimilar: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginVertical: 8,
    width: Utility.getScreenWidth() - 24,
    alignSelf: 'center',
  },
  bagContainerVariantModalSimilar: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginVertical: 8,
    paddingHorizontal: 12,
  },
  bagContainerModal: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginVertical: 8,
    width: (Utility.getScreenWidth() - 24 - 8) / 2,
    borderWidth: 1,
    borderBottomWidth: 1.2,
    borderColor: colors.linearGradientGreenFirst,
    backgroundColor: colors.white,
  },
  bagNowButtonWithBlackBg: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginTop: 8,
    width: Utility.getScreenWidth() - 24,
    backgroundColor: Utility.isPresent(Config.PRODUCT_PAGE_BUY_NOW_BG_COLOR)
      ? Config.PRODUCT_PAGE_BUY_NOW_BG_COLOR
      : colors.black,
  },
  quantityIncrementContainer: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    marginVertical: 8,
    width: (Utility.getScreenWidth() - 24 - 8) / 2,
    borderWidth: 1,
    borderBottomWidth: 1.2,
    borderColor: colors.linearGradientGreenFirst,
    backgroundColor: colors.white,
    paddingHorizontal: 24,
  },
  quantityIcon: {
    height: 12,
    width: 12,
    tintColor: colors.linearGradientGreenFirst,
  },
  quantityText: {
    fontSize: 18,
    fontFamily: 'Roboto-Medium',
    color: colors.linearGradientGreenFirst,
  },
  addToBagView: {
    height: 40,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    backgroundColor: colors.green,
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
  },
  addToBagViewWithRoundedRadius: {
    height: 46,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    backgroundColor: colors.green,
    borderRadius: 4,
  },
  addToBagViewVariantModal: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  comparePriceContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    left: -4,
    top: -4,
    right: -4,
    bottom: -4,
  },
  comparePriceText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.foxyBlack,
  },
  rightArow: {
    tintColor: colors.foxyBlack,
    height: 14,
    width: 9,
    marginLeft: 4,
    marginTop: 2,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: Utility.getScreenWidth(),
    height: Utility.getWindowHeight(),
    backgroundColor: colors.black,
    opacity: 0.7,
    zIndex: 1,
  },
  spStyle: {
    color: colors.foxyBlack,
    fontSize: size.h2,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
    marginRight: 4,
  },
  mrpStyle: {
    color: colors.silver,
    fontSize: size.h3,
    textDecorationLine: 'line-through',
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
  },
  discountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: size.h3,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
    opacity: 0.7,
  },
  addToBagText: {
    lineHeight: 18,
    fontFamily: 'Roboto-Medium',
    fontSize: 15,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#fff',
  },
  buyNowBagText: {
    lineHeight: 18,
    fontFamily: 'Roboto-Medium',
    fontSize: 15,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Utility.isPresent(Config.PRODUCT_PAGE_BUY_NOW_TEXT_COLOR)
      ? Config.PRODUCT_PAGE_BUY_NOW_TEXT_COLOR
      : colors.linearGradientGreenFirst,
  },
  sellerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 4,
  },
  verifiedTick: {
    height: 20,
    width: 20,
    resizeMode: 'cover',
  },
  rightArrowStyle: {
    marginLeft: 8,
    tintColor: colors.disabled,
  },
  innerShadow: {
    resizeMode: 'stretch',
    width: '100%',
    height: '100%',
  },
  dockedHeaderContainer: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 16,
    marginRight: 8,
  },
  offerModal: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
  },
  offerTerms: {
    width: Utility.getScreenWidth(),
    // height: 1.2 * Utility.getScreenWidth(),
    maxHeight: 520,
    borderRadius: 6,
    backgroundColor: 'white',
  },
  offerBanner: {
    width: Utility.getScreenWidth() - 32,
    height: 0.3 * (Utility.getScreenWidth() - 32),
    borderRadius: 6,
    alignSelf: 'center',
  },
  offerTermsHeading: {
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    width: '100%',
  },
  offerTermsHeadingText: {
    fontSize: 16,

    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
  },
  termsAndConditionText: {
    fontSize: 12,
    color: colors.foxyBlack,

    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    // padding: 16,
    paddingHorizontal: 16,
    // paddingTop: 8,
    // backgroundColor:'#ff0',
    // paddingBottom: 16,
    // paddingLeft: 0,
  },
  divider: {
    height: 1,
    backgroundColor: colors.border,
  },
  navigationDivider: {
    position: 'absolute',
    bottom: 0,
    height: 1,
    backgroundColor: colors.border,
    width: '100%',
  },
  offerActionButtonsContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 52,
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  offerActionButtonView: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    right: 12,
    elevation: 2,
    shadowRadius: 4,
    height: 46,
    borderRadius: 4,
    backgroundColor: colors.green,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowOffset: { width: 1, height: 1 },
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
  },
  offerCancelButtonView: {
    padding: 4,
    position: 'absolute',
    top: 12,
    right: 12,
    shadowRadius: 4,
    height: 32,
    width: 32,
    borderRadius: 16,
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailAddToCartImage: {
    width: 16,
    height: 20,
    resizeMode: 'contain',
    marginRight: 8,
    marginBottom: 2,
    // overflow: 'visible',
  },
  detailAddToCartImageBuyNow: {
    width: 16,
    height: 20,
    resizeMode: 'contain',
    marginRight: 8,
    marginBottom: 2,
    overflow: 'visible',
  },
  detailAddToCartImageBuyNowModal: {
    width: 16,
    height: 20,
    resizeMode: 'contain',
    marginRight: 8,
    marginBottom: 2,
    overflow: 'visible',
    tintColor: Config.PRODUCT_PAGE_BUY_NOW_TEXT_COLOR ?? colors.white,
  },
  detailAddToCartImageDefault: {
    width: 16,
    height: 20,
    resizeMode: 'contain',
    marginRight: 8,
    marginBottom: 2,
    tintColor: colors.linearGradientGreenFirst,
    overflow: 'visible',
  },
  shareModal: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
  },
  shareModalContainer: {
    position: 'absolute',
    bottom: -20,
    left: -20,
    right: -20,
    height: 260,
    borderRadius: 6,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
  },
  shareOptionsContainer: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    right: 12,
    elevation: 2,
    shadowRadius: 4,
    height: 68,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  socialImage: {
    height: 48,
    width: 48,
    borderRadius: 24,
  },
  socialShareContainer: {
    alignItems: 'center',
  },
  socialMediaTypeText: {
    fontSize: 12,

    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    letterSpacing: 0,
    marginTop: 4,
  },
  previewContainer: {
    height: 78,
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 2,
    borderColor: colors.border,
    borderRadius: 6,
    padding: 8,
    flexDirection: 'row',
  },
  previewImage: {
    height: 62,
    width: 62,
    borderRadius: 4,
    alignSelf: 'center',
  },
  previewDescriptionContainer: {
    marginLeft: 8,
    marginRight: 0,
    flex: 1,
    justifyContent: 'center',
  },
  brandNameInPreview: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    letterSpacing: 0,
    color: colors.disabled,

    fontStyle: 'normal',
  },
  nameInPreview: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    letterSpacing: 0,
    color: colors.foxyBlack,

    fontStyle: 'normal',
    marginBottom: 2,
  },
  previewPriceContainer: { flexDirection: 'row', justifyContent: 'flex-start' },
  previewSpStyle: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    marginRight: 4,
  },
  previewMrpStyle: {
    color: colors.silver,
    fontSize: size.h3,
    textDecorationLine: 'line-through',
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
  },
  previewDiscountRateStyle: {
    color: colors.foxyPink,
    fontSize: size.h3,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
  },
  productDescriptionViewInComparePrice: {
    width: '100%',
    marginLeft: 4,
    marginBottom: 12,
  },
  comparePriceItem: {
    height: 48,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  comparePriceWebsiteLogo: {
    marginLeft: 16,
    resizeMode: 'contain',
    height: 46,
    width: 82,
  },
  websiteSpText: {
    position: 'absolute',
    right: 16,
    alignSelf: 'center',
    color: colors.foxyBlack,
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  hashtagColor: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
    marginLeft: 4,
    borderRadius: 4,
  },
  hashtagText: {
    fontSize: 11,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
    paddingLeft: 4,
    paddingRight: 4,
  },
  actionButtonFontStyle: {
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontSize: 18,
  },
  infoImage: {
    height: 12,
    width: 12,
    marginLeft: 16,
    marginTop: 2,
    tintColor: colors.subtitle,
  },
  disclaimerText: {
    fontSize: 12,

    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    marginLeft: 4,
    marginRight: 32,
  },
  certificateButton: {
    height: 28,
    width: 110,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  certificateButtonText: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.cta.lightBlue,
    marginLeft: 4,
  },
  certificateContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  certificateHeader: {
    fontSize: 20,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
  },
  certificateSubtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
    marginTop: 8,
    alignSelf: 'center',
    textAlign: 'center',
  },
  certificateModal: {
    width: '100%',
    // backgroundColor: colors.foxyBlack,
    position: 'absolute',
    bottom: 0,
    height: '100%',
    paddingTop: 12.0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    paddingHorizontal: 24,
  },
  certificateModalContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.extraDarkTranslucent,
  },
  certificateImage: {
    width: Utility.getScreenWidth() - 64,
    height: 1.414 * (Utility.getScreenWidth() - 64),
    marginTop: 16,
  },
  crossButtonInCertificate: {
    position: 'absolute',
    top: 30,
    right: 0,
  },
  crossButtonImageInCertificate: {
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  originalProductLogo: {
    height: 16,
    width: 16,
  },
  verticalSpacing: {
    height: 50,
    width: '100%',
    backgroundColor: colors.background,
  },
  actionBottonContainer: {
    // position: 'absolute',
    height: 70,
    bottom: 0,
    width: Utility.getScreenWidth(),
    flexDirection: 'column',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  offerName: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 12,
    width: '75%',
  },
  subtitleText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 8,
    marginBottom: 16,
  },
  couponCodeView: {
    height: 45,
    // borderWidth: 1,
    // borderColor: colors.border,
    // borderTopColor: colors.border,
    // borderBottomColor: colors.border,
    // marginTop: 12,
    // marginLeft: 16,
    // marginRight: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tapToCopyCouponCodeText: {
    position: 'absolute',
    alignSelf: 'center',
    right: 4,
    fontSize: 12,

    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
    // marginRight: 15,
  },

  horizontalLine: {
    height: 1,
    width: '100%',
    backgroundColor: colors.border,
  },

  specialDiscountPopoverRowWrapper: {
    width: Utility.getScreenWidth(),
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 1000,
    position: 'absolute',
    height: 50,
    bottom: Utility.isAndroid() ? 55 : 90,
    flexDirection: 'column',
    backgroundColor: 'rgba(255,255,255,0.8)',
  },

  specialDiscountPopoverContainer: {
    flexDirection: 'column',
  },

  specialDiscountTextBox: {
    paddingTop: 5,
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.foxyPink,
    borderStyle: 'dashed',
    minHeight: 30,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.ultraLightFoxyPink,
    top: 4,
  },
  downarrow: {
    height: 15,
    marginLeft: 10,
    width: 15,
    zIndex: 1,
    tintColor: colors.ultraLightFoxyPink,
  },

  specialDiscountPopoverText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
    color: colors.foxyBlack,
  },
  offerTextContainer: {
    marginLeft: 12,
    flexDirection: 'row',
    marginTop: 0,
    marginBottom: 4,
  },
  offerSubTextContainer: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    fontStyle: 'normal',
    letterSpacing: 0,
    zIndex: 5,
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.silver,
    textAlign: 'center',
    lineHeight: 19,
  },
  offerCouponSubTextContainer: {
    backgroundColor: '#F6E1E3',
    paddingLeft: 8,
    paddingRight: 8,
    borderWidth: 1,
    borderColor: '#FF6268',
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1,
    opacity: 0.5,
    marginLeft: 4,
  },
  offerCouponSubText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    zIndex: 100,
  },
  heroDescriptionBoxContainer: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: colors.white,
  },
  toast: {
    position: 'absolute',
    bottom: 20,
  },
  productDetailSeparator: {
    height: 98 + Utility.bottomInset,
    backgroundColor: colors.background,
  },
  productDetailScrollView: {
    marginBottom: Utility.bottomInset + 60,
    height: Utility.getScreenHeight(),
    backgroundColor: colors.background,
    '@media (min-width: 800px)': {
      marginBottom: 0,
    },
  },
  productDetailContainer: {
    flex: 1,
    backgroundColor: colors.white,
    '@media (min-width: 768px)': {
      flex: 1,
      width: getScreenWidth(),
      flexDirection: 'row',
      backgroundColor: colors.background,
      marginBottom: 20,
      alignSelf: 'center',
    },
  },
  pricingTextContainer: {
    flexDirection: 'row',
  },
  hashTagContainer: {
    marginLeft: 4,
    flexDirection: 'row',
  },
  brandNameContainerStyle: {
    maxWidth: '80%',
    marginRight: 4,
  },
  addToCartButtonContainer: {
    flexDirection: 'row',
    width: '90%',
    alignSelf: 'center',
  },
  likeButtonContainer: {
    flex: 1,
    backgroundColor: colors.white,
    shadowRadius: 4,
    height: 68,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  addToCartTextContainer: {
    flex: 3,
    zIndex: -1,
  },
  easyReturnContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  deliveryTimeRow: {
    marginTop: 8,
    marginBottom: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subtleSeparator: {
    height: 16,
    width: 1,
    backgroundColor: colors.subtitle,
  },
  scanCard: {
    height: 75,
    backgroundColor: colors.background,
    justifyContent: 'center',
  },
  scanContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 10,
    paddingHorizontal: 10,
    backgroundColor: colors.white,
  },
  scanNotAccurateText: {
    fontSize: size.h3,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
  },
  searchForProductText: {
    fontSize: size.h3,
    color: colors.silverWicked,

    fontFamily: 'Roboto-Regular',
  },
  scanSearchIcon: {
    width: 17.5,
    height: 17.5,
    resizeMode: 'contain',
  },
  sectionListItemContainer: {
    height: 1,
    width: Utility.getScreenWidth(),
  },
  navigationShareButtonContainerTransparentHeader: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: iconsBottomSpace,
    right: 16,
  },
  navigationChatButtonContainerTransparentHeader: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: iconsBottomSpace,
    right: 58,
  },
  inviteCentreIcon: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    bottom: iconsBottomSpace,
    right: 110,
    zIndex: 4,
  },
  certificateModalActionButton: {
    position: 'absolute',
    bottom: 10,
    backgroundColor: colors.primaryActionBackgroundColor,
    left: 16,
    right: 16,
  },
  addToCartButtonInModal: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 100,
  },
  orderSuccessFirstActionButtonStyle: {
    backgroundColor: colors.black,
    borderRadius: 4,
  },
  navigationScanButtonContainer: {
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    top: 15,
    height: 30,
    width: 30,
  },

  scanIcon: {
    height: 25,
    width: 25,
  },
  sizeChartButton: { position: 'absolute', right: 15 },
  sizeChartButtonText: {
    color: colors.foxyBlue,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
  },
  webShadesContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginLeft: 10,
  },
  imageVariantFlatlistStyle: {
    flex: 1,
    flexWrap: 'wrap',
    marginHorizontal: 4,
  },
  imageVariantFlatlistContentStyle: {
    alignSelf: 'center',
    justifyContent: 'space-evenly',
  },
  imageSecondaryVariantFlatlistStyle: {
    flexWrap: 'wrap',
    marginLeft: 10,
    marginTop: 6,
  },
  integerVariantsContainer: {
    backgroundColor: colors.white,
    marginTop: 10,
    '@media (min-width: 768px)': {
      marginVertical: 10,
    },
  },
  extraMarginTop: { marginTop: 30 },
  sectionListContainer: {
    marginTop: 30,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      height: '100%',
    },
  },
  variantSectionContentContainerStyle: {
    paddingBottom: 80,
  },
  ratingsMarginTop: { marginTop: -18, marginBottom: 16 },
  incrementButtonContainer: {
    height: 38,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginTop: 8,
    width: Utility.getDynamicWidthForGrid(numOfItems, 16) - 16,
    backgroundColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
    marginBottom: 8,
    alignSelf: 'center',
    zIndex: 1,
  },
  incrementButtonContainerVariantModal: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginVertical: 8,
    width: Utility.getDynamicWidthForGrid(numOfItems, 16) - 16,
    backgroundColor: isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.black,
    alignSelf: 'center',
    zIndex: 1,
  },
  incrementButtonContainerOos: {
    height: 36,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginTop: 8,
    width: Utility.getDynamicWidthForGrid(numOfItems, 16) - 16,
    backgroundColor: colors.grey,
    marginBottom: 8,
    alignSelf: 'center',
    zIndex: 1,
  },
  productSummaryPricingContainer: {
    position: isWeb() ? 'fixed' : 'absolute',
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 100,
    borderTopWidth: 1,
    borderTopColor: colors.border,
    paddingHorizontal: 12,
    paddingBottom: isIOS() || isWeb() ? Utility.bottomInset + 12 : 0,
    alignItems: 'center',
    alignSelf: 'center',
  },
  crossButtonContainer: {
    padding: 4,
    position: 'absolute',
    top: 12,
    right: 12,
    shadowRadius: 4,
    height: 32,
    width: 32,
    borderRadius: 16,
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  crossButton: { height: 12, width: 12, tintColor: colors.black },
  search_icon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  crossButtonForModal: {
    height: 16,
    width: 16,
  },
});
