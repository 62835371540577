const envVariables = process.env;

const Config = Object.keys(envVariables)
  .filter(key => key.startsWith('REACT_APP_'))
  .reduce((result, key) => {
    const newKey = key.replace('REACT_APP_', '');
    return { ...result, [newKey]: envVariables[key] };
  }, {});

export default Config;
