import {
  select,
  call,
  put,
  delay,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import RNFetchBlob from '../libraries/RNFetchBlob';
import {
  URL,
  SELFIE_STATE,
  SELFIE_STATE_ON_DELAY,
  SELFIE_ANALYSIS_DELAYED_TIME,
  UPLOAD_NOTIFICATION_TEXT,
  REMOTE_CONFIG_KEYS,
  NOTIFICATION_IDENTIFIERS,
  NOTIFICATION_TYPE,
  COUNTRY_CODE,
} from '../config/Constants';
import {
  markProfilePicUploadSuccess,
  saveCDNlinktoRedux,
  START_FACIAL_ANALYSIS,
  UPDATE_USER_ATTRIBUTE,
  userFacialProperties,
  saveFaceAnalysisData,
  RESUME_SELFIE_ATTRIBUTE_FETCH,
  RETRY_SELFIE_ANALYSIS_PROCESS,
  RESUME_SELFIE_ATTRIBUTE_MAPPING,
  retrySelfieAnalysis,
} from '../actions/LoginActions';
import Utility from '../utils/Utility';
import {
  saveAllowedValues,
  UPLOAD_IMAGE,
  GET_MASTER_ATTRIBUTES_LIST,
  REFRESH_MY_ATTRIBUTES,
  masterlistLastUpdatedAt,
  UPLOAD_FND_IMAGE,
} from '../actions/FacialAnalysisActions';
import SelfieUtilities from '../utils/SelfieUtilities';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../analytics';

import {
  uacGenderEventTriggered,
  uacSelfieEventTriggered,
  saveAndyWarholImages,
  getAndyWarholImages,
  GET_MY_WARHOL_IMAGES,
} from '../actions/ActionTypes';

import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import AnalyticsUtility from '../analytics/AnalyticsUtility';
import CameraUtils from '../utils/CameraUtils';
import NotificationManager from '../utils/NotificationsManager';
import { NativeModules } from 'react-native';
import RemoteConfig from '../utils/RemoteConfig';
import AppConfig from '../config/AppConfig';
import { isWeb } from '../utils/BooleanUtility';
// Fetch requests

/*
 *  send image name and get the response with form data.  MOD 1
 */

const profilePicApi = (
  countryCode,
  phoneNumber,
  imageName,
  authToken,
  guestAuthToken,
  imageType,
) => {
  const headers = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-auth-token': `${authToken}`,
      'x-guest-token': `${guestAuthToken}`,
    },
  };
  const imageQueryString =
    imageType === 'profile'
      ? `profile_image_name=${imageName}`
      : `selfie_image_name=${imageName}`;
  let url = `${
    URL.PROFILE_PIC_UPLOAD_URL
  }?user[phone_number]=${encodeURIComponent(phoneNumber)}&${imageQueryString}`;
  if (!phoneNumber.startsWith('+')) {
    url = `${url}&user[country_code]=${countryCode}`;
  }
  const response = fetch(url, headers);
  return response;
};

// upload image to s3   MOD 2

//Using in case of Profile pic only
const uploadFileToS3 = async (data, fileUri, url) => {
  const headers = { 'Content-type': 'multipart/form-data' };
  const formdata = [];
  for (const key in data) {
    formdata.push({ name: key, data: data[key] });
  }

  if (Utility.isIOS()) {
    fileUri = fileUri.replace('file://', '');
  }
  formdata.push({
    name: 'file',
    filename: Utility.extractImageName(fileUri),
    data: RNFetchBlob.wrap(fileUri),
  });
  const response = await RNFetchBlob.config({ timeout: 60000 })
    .fetch('POST', url, headers, formdata)
    .uploadProgress((written, total) => {})
    .progress((received, total) => {})
    .then((res) => {
      if (res.respInfo.status === 201) {
        console.log('File uploaded toserver s3');
        return true;
      }

      return false;
    })
    .catch((error) => {
      console.log('Error while uploading File to s3', error);
      return false;
    });

  return response;
};
// Once file is uploaded to S3 inform server that file is uploaded MOD 3
const markProfilePicSuccessful = (
  countryCode,
  phoneNumber,
  imageName,
  authToken,
  guestToken,
  imageType,
) => {
  let postData = {};
  let postDataBody = {};

  const userObject = !phoneNumber.startsWith('+')
    ? { country_code: `${countryCode}`, phone_number: `${phoneNumber}` }
    : { phone_number: `${phoneNumber}` };

  if (!authToken || Utility.isBlank(authToken)) {
    postDataBody = {
      guest_token: `${guestToken}`,
      user: userObject,
    };
  } else {
    postDataBody = {
      guest_token: `${guestToken}`,
      user: userObject,
    };
  }

  if (imageType === 'selfie') {
    postDataBody.selfie_image_name = `${imageName}`;
  } else {
    postDataBody.profile_image_name = `${imageName}`;
  }

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-auth-token': authToken,
    'x-guest-token': guestToken,
  };
  if (isWeb()) {
    headers.Platform = 'web';
  }
  postData = {
    method: 'POST',
    headers,
    body: JSON.stringify(postDataBody),
  };

  const response = fetch(URL.PROFILE_IMAGE_UPLOAD_SUCCESSFUL, postData);
  return response;
};

function* markProfileUploadSuccessfull(action) {
  const { returnValue = false, imageType } = action;
  try {
    const UserAccountInfo = yield select((state) => state.UserAccountInfo);

    const {
      callingCode: countryCode,
      mobileNumber: phoneNumber,
      authToken,
      guestProfile: { guestAuthToken = '' } = {},
    } = UserAccountInfo;

    const imageName = action.profilePicName;

    const response = yield call(
      markProfilePicSuccessful,
      countryCode,
      phoneNumber,
      imageName,
      authToken,
      guestAuthToken,
      imageType,
    );
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      // yield put(saveCDNlinktoRedux(json.profile.selfie_image_url));
      if (Utility.isBlank(json)) {
        return;
      }
      if (returnValue) {
        return json;
      }
      action.action.callback(json);
    } else {
      action.action.callback(false);
    }
  } catch (error) {
    action.action.callback(false);
  }
}

function* uploadImageToS3(action) {
  const { formData, fileUri, postUrl, needReturn = false } = action;
  try {
    const resultS3 = yield call(uploadFileToS3, formData, fileUri, postUrl);
    if (needReturn) {
      return resultS3;
    }
    if (resultS3) {
      yield put(
        markProfilePicUploadSuccess(
          Utility.extractImageName(fileUri),
          action.action,
        ),
      );
    } else {
      action.action.callback(false);
    }
  } catch (error) {
    action.action.callback(false);
  }
}

function* startFacialAnalysis(action) {
  const { imageName, imageUri, callback = () => {}, imageType } = action;
  try {
    if (Utility.isSelfie(imageType)) {
      Utility.selfieAnalysisStartedAt = new Date();
    }

    const UserAccountInfo = yield select((state) => state.UserAccountInfo);

    const {
      callingCode: countryCode,
      mobileNumber: phoneNumber,
      authToken,
      guestProfile: { guestAuthToken = '' } = {},
    } = UserAccountInfo;

    const response = yield call(
      profilePicApi,
      countryCode,
      phoneNumber,
      imageName,
      authToken,
      guestAuthToken,
      imageType,
    );
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      const base64Image = yield CameraUtils.convertImageToBase64(imageUri);
      const uploadImageResponse = yield fetch(URL.ADD_PROFILE_DETAILS, {
        method: 'POST',
        headers: yield getApiHeaders(),
        body: JSON.stringify({
          user: {
            image: `data:image/jpeg;base64,${base64Image}`,
          },
        }),
      });
      if (
        uploadImageResponse.status >= 200 &&
        uploadImageResponse.status < 300
      ) {
        callback(true);
        return;
      }
      callback(false);
      return;
    }
    callback(false);
  } catch (error) {
    callback(false);
  }
}

export function* startSelfieAnalysis() {
  yield put(
    saveFaceAnalysisData({
      analysis_progress: 0,
      analysis_stage: SELFIE_STATE.STARTING,
      show_progress_around_pic: true,
      selfie_upload_failure: false,
    }),
  );
}
export function* selfieAnalysisSuccess(analysisReviewPopupRequired) {
  yield put(
    saveFaceAnalysisData({
      analysis_progress: 100,
      popover_state: analysisReviewPopupRequired ? 'show' : 'hidden',
      analysis_stage: SELFIE_STATE.COMPLETED,
      show_progress_around_pic: false,
    }),
  );
}

export function* selfieAnalysisFailed(
  analysisPopupRequired,
  isUploadFailure = false,
) {
  const selfieUploadFailure = isUploadFailure
    ? { selfie_upload_failure: true }
    : {};
  yield put(
    saveFaceAnalysisData({
      analysis_progress: 0,
      popover_state: analysisPopupRequired ? 'show' : 'hidden',
      analysis_stage: SELFIE_STATE.ERROR,
      show_progress_around_pic: false,
      ...selfieUploadFailure,
    }),
  );
}

function* tryToGetMyAttributes(imageId) {
  //Retry increase count to 5 to 10
  let lastStatusCode = 0;
  for (let index = 0; index < 10; index++) {
    yield delay(5000);
    yield put(
      saveFaceAnalysisData({
        analysis_progress: 50 + 5 * index,
      }),
    );
    yield delay(5000);
    const response = yield getMyAttributes(imageId);
    const attempt = index + 1;
    lastStatusCode = response.statusCode;
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_ANALYSIS_RETRY, {
      [EventParameterKey.RETRY_COUNT]: attempt,
      [EventParameterKey.STATUS_CODE]: lastStatusCode,
    });

    if (response.statusCode === 200 && Utility.isPresent(response.data)) {
      return { user_attributes: response.data, statusCode: lastStatusCode };
    }
    if (response.statusCode === 404) {
      return { user_attributes: undefined, statusCode: lastStatusCode };
    }
  }

  return { user_attributes: undefined, statusCode: lastStatusCode };
}

const uploadSelfieImageToFoxy = async (base64Data, headers) => {
  const { data } = await RNFetchBlob.fetch(
    'POST',
    URL.UPLOAD_BASE_64_IMAGE,
    { ...headers },
    JSON.stringify(base64Data),
    // formData,
  ).uploadProgress({ interval: 50 }, (written, total) => {
    NotificationManager.setProgress(total, written);
  });

  if (data) {
    // NotificationManager.cancelUploadingNotification();
    return Utility.jsonParser(data);
  }

  return undefined;
};

export function* uploadBase64Data(data) {
  const headers = yield getApiHeaders();
  for (let i = 0; i < 4; i++) {
    try {
      const response = yield uploadSelfieImageToFoxy(data, headers);

      if (response) {
        return response;
      }
      AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_UPLOAD_RETRY, {
        [EventParameterKey.RETRY_COUNT]: i + 1,
        [EventParameterKey.RAW_ERROR]: 'server_error',
        [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.AUTOMATIC,
        [EventParameterKey.STATUS_CODE]: response.status,
      });
    } catch (error) {
      AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_UPLOAD_RETRY, {
        [EventParameterKey.RETRY_COUNT]: i + 1,
        [EventParameterKey.RAW_ERROR]: 'network_error',
        [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.AUTOMATIC,
      });
    }

    yield delay(500);
  }

  NotificationManager.retryUpload();
  AnalyticsManager.logEvent(
    EventType.onboardingEvent.SELFIE_UPLOAD_RETRY_EXHAUSTED,
  );
  return undefined;
}

export function* uploadImage(action) {
  let selfieAnalysisCompleted = false;
  let currentState = '';
  const selfieAnalysisStartedAt = new Date();
  const { base64Data, analysisReviewPopupRequired } = action;
  try {
    const userInfo = yield select((state) => state.UserAccountInfo);
    const { authToken, mobileNumber, callingCode } = userInfo;

    NotificationManager.showUploadingNotification();
    const postData = {
      auth_token: authToken,
      user: {
        country_code: callingCode,
        phone_number: mobileNumber,
        base64_selfie_image: base64Data,
      },
    };

    yield startSelfieAnalysis();

    // FIXME: There is no timeout or retry.

    const timer = setTimeout(() => {
      if (!selfieAnalysisCompleted) {
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.SELFIE_ANALYSIS_DELAY,
          { [EventParameterKey.STATE]: currentState },
        );
      }
      clearTimeout(timer);
    }, SELFIE_ANALYSIS_DELAYED_TIME);
    currentState = SELFIE_STATE_ON_DELAY.IMAGE_UPLOAD_START;
    AnalyticsUtility.logSelfieState(currentState);

    const response = yield uploadBase64Data(postData);

    if (response) {
      currentState = SELFIE_STATE_ON_DELAY.IMAGE_UPLOAD_DONE;
      NotificationManager.removeProgressBar();
      const payload = {
        title: UPLOAD_NOTIFICATION_TEXT.ANALYSIS_START_TITLE,
        description: UPLOAD_NOTIFICATION_TEXT.ANALYSIS_END_DESCRIPTION,
        type: NOTIFICATION_TYPE.PROFILE_PIC_UPLOAD,
      };
      NotificationManager.createNotification(
        payload,
        NOTIFICATION_IDENTIFIERS.PROFILE_PIC_UPLOAD,
      );
      AnalyticsUtility.logSelfieState(currentState);
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.SELFIE_UPLOAD_SUCCESS,
        {
          [EventParameterKey.TIME_TAKEN]: Utility.getTimeDiff(
            new Date(),
            selfieAnalysisStartedAt,
          ),
        },
      );
      yield put(saveCDNlinktoRedux(response.profile.selfie_image_url));

      yield put(getAndyWarholImages());

      yield put(
        saveFaceAnalysisData({
          analysis_progress: 50,
          analysis_stage: SELFIE_STATE.SELFIE_UPLOADED,
          image_id: response.image_id,
          selfie_image: response.profile.selfie_image_url,
        }),
      );
      //TODO:Extract Code from Here Step 1
      currentState = SELFIE_STATE_ON_DELAY.WAITING_FOR_USER_ATTRIBUTES;
      yield fetchUserAttributes({
        image_id: response.image_id,
        analysisReviewPopupRequired: analysisReviewPopupRequired,
      });
    } else {
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.SELFIE_UPLOAD_FAILURE,
        {
          [EventParameterKey.TIME_TAKEN]: Utility.getTimeDiff(
            new Date(),
            selfieAnalysisStartedAt,
          ),
        },
      );
      yield selfieAnalysisFailed(analysisReviewPopupRequired, true);
    }
  } catch (error) {
    AnalyticsManager.logEvent(
      EventType.onboardingEvent.SELFIE_ANALYSIS_FAILURE,
      {
        [EventParameterKey.STATE]: currentState,
        [EventParameterKey.RAW_ERROR]: error,
        [EventParameterKey.TIME_TAKEN]: Utility.getTimeDiff(
          new Date(),
          selfieAnalysisStartedAt,
        ),
      },
    );
    yield selfieAnalysisFailed(analysisReviewPopupRequired);
  }
}

export function* calculateGenderScoreForUAC(gender) {
  try {
    try {
      const genderScore = calculateGenderScore(gender);
      yield put(uacGenderEventTriggered(genderScore));
      yield put(uacSelfieEventTriggered(1));
    } catch (error) {
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.UAC_GENDER_SCORE_ERROR,
      );
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_GENDER_SCORE_ERROR);
  }
}

export function* startAttributesMappingWithMasterList(action) {
  const { user_attributes, analysisReviewPopupRequired = false } = action;
  const userGender = yield mapMyAttributesWithMasterList(user_attributes, true);

  AnalyticsUtility.logSelfieState(SELFIE_STATE_ON_DELAY.SELFIE_ANALYSIS_DONE);
  yield selfieAnalysisSuccess(analysisReviewPopupRequired);
  NotificationManager.cancelUploadingNotification();
  AnalyticsManager.setUserProperty('selfie_analysis_success', 'true');
  AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_ANALYSIS_SUCCESS, {
    //FIXME: fix this code
    // [EventParameterKey.TIME_TAKEN]: Utility.getTimeDiff(
    //   new Date(),
    //   selfieAnalysisStartedAt,
    // ),
    [EventParameterKey.GENDER]: userGender,
    [EventParameterKey.ATTRIBUTE_COUNT]: user_attributes.length,
  });

  yield calculateGenderScoreForUAC(userGender);
}

export function* fetchUserAttributes(action) {
  const { image_id, analysisReviewPopupRequired } = action;
  AnalyticsUtility.logSelfieState(
    SELFIE_STATE_ON_DELAY.WAITING_FOR_USER_ATTRIBUTES,
  );
  const { user_attributes, statusCode } = yield tryToGetMyAttributes(image_id);
  yield put(
    saveFaceAnalysisData({
      raw_user_attributes: user_attributes,
    }),
  );
  if (user_attributes) {
    AnalyticsUtility.logSelfieState(
      SELFIE_STATE_ON_DELAY.STARTED_ATTRIBUTES_MAPPING,
    );
    yield put(
      saveFaceAnalysisData({
        analysis_stage: SELFIE_STATE.STARTED_ATTRIBUTES_MAPPING,
      }),
    );
    yield startAttributesMappingWithMasterList({
      user_attributes: user_attributes,
      analysisReviewPopupRequired: analysisReviewPopupRequired,
    });
  } else {
    AnalyticsManager.setUserProperty('selfie_analysis_success', 'false');
    AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_ANALYSIS_ERROR, {
      [EventParameterKey.STATUS_CODE]: statusCode,
      [EventParameterKey.RAW_ERROR]: "Didn't get attributes after 5 retries",
      //FIXME: Please fix this time
      // [EventParameterKey.TIME_TAKEN]: Utility.getTimeDiff(
      //   new Date(),
      //   selfieAnalysisStartedAt,
      // ),
    });
    yield selfieAnalysisFailed(analysisReviewPopupRequired);
    NotificationManager.cancelUploadingNotification();
  }
}

export function* mapMyAttributesWithMasterList(
  myAttributes,
  captureEvent = false,
) {
  try {
    const master_attribute_list = yield select(
      (state) => state.facialAnalysis.master_attribute_list,
    );

    const userGender = SelfieUtilities.extractUserGender(
      myAttributes,
      master_attribute_list,
    );

    const isSelfieReviewed =
      SelfieUtilities.detectIfSelfieIsReviewed(myAttributes);

    const listUserSuitableAttributes =
      SelfieUtilities.getListOfUserSuitableAttributes(
        userGender,
        master_attribute_list,
      );

    const userAttributes = SelfieUtilities.addUserSelectedValuesToAttributes(
      myAttributes,
      listUserSuitableAttributes,
    );
    yield put(userFacialProperties(userAttributes));
    if (captureEvent) {
      SelfieUtilities.logAttributesDetectionEvent(userAttributes);
    }
    return userGender;
  } catch (err) {}
}

function* getAllowedAttributes(action) {
  const { callback } = action;
  const master_attribute_list = yield select(
    (state) => state.facialAnalysis?.master_attribute_list,
  );

  try {
    const headers = yield getApiHeaders();
    const response = yield fetch(URL.USER_ATTRIBUTES, {
      method: 'GET',
      headers: headers,
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(saveAllowedValues(json));
      yield put(masterlistLastUpdatedAt(new Date()));
      if (!callback) {
        return json;
      }
      callback(json);
    }
  } catch (error) {}
}
export function* getMyAttributes(
  imageId = '',
  forceFetchAttributesForGuest = false,
  updateSelfieProgressInfo = true,
  captureEvent = false,
) {
  try {
    const { mobileNumber } = yield select((state) => state.UserAccountInfo);

    let url = `${URL.MY_ATTRIBUTE_VALUES}`;
    let queryString = '';

    if (Utility.isPresent(mobileNumber)) {
      queryString = '?';
      if (mobileNumber.startsWith('+')) {
        queryString = `${queryString}user[phone_number]=${mobileNumber}`;
      } else {
        queryString = `${queryString}user[phone_number]=${mobileNumber}&user[country_code]=${COUNTRY_CODE}`;
      }
      url = `${url}${queryString}`;
      if (Utility.isPresent(imageId)) {
        url = `${url}&sad_id=${imageId}`;
      }
    } else {
      //Case for guest user
      if (Utility.isPresent(imageId)) {
        url = `${url}?sad_id=${imageId}`;
      }
    }

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put(saveFaceAnalysisData({ my_attributes_values: json }));
      }
      //If existing user logged in, get user attributes and map it immediately
      if (Utility.isBlank(imageId) && Utility.isBlank(json)) {
        if (!forceFetchAttributesForGuest) {
          yield selfieAnalysisFailed(false);
        }
      } else if (Utility.isBlank(imageId)) {
        yield mapMyAttributesWithMasterList(json, captureEvent);

        if (updateSelfieProgressInfo) {
          yield put(
            saveFaceAnalysisData({
              analysis_progress: 100,
              popover_state: 'hidden',
              analysis_stage: SELFIE_STATE.COMPLETED,
              show_progress_around_pic: false,
            }),
          );
        }
      }

      return { data: json, statusCode: statusCode };
      //  yield put(saveMySelfieAttributes(apiResponse));
    }
    return { statusCode };
  } catch (error) {
    console.log('Your Error ', error);
  }
}

export function* refreshAttributes() {
  yield getMyAttributes();
}

function* updateUserAttribute(action) {
  const { data } = action;
  try {
    const UserAccountInfo = yield select((state) => state.UserAccountInfo);
    const { authToken, guestProfile: { guestAuthToken = '' } = {} } =
      UserAccountInfo;

    const postData = {
      auth_token: authToken,
      guest_token: guestAuthToken,
      user_attribute_id: data.id,
      new_values: data.new_values,
    };
    const response = yield fetch(URL.UPDATE_USER_ATTRIBUTES, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postData),
    });
  } catch (error) {
    console.log('log Work', error);
  }
}

function* retrySelfieAnalysisProcess(action) {
  const { analysisReviewPopupRequired } = action;
  try {
    const localImageUri = yield select(
      (state) => state.UserAccountInfo.profile.local_image_uri,
    );
    const base64Data = yield CameraUtils.convertImageToBase64(localImageUri);
    yield uploadImage({
      base64Data: `data:image/jpeg;base64,${base64Data}`,
      analysisReviewPopupRequired: analysisReviewPopupRequired,
    });
  } catch (error) {}
}

function* uploadFndImageToFoxy(action) {
  const { imageUrl } = action;
  const base64Data = yield CameraUtils.convertImageToBase64(imageUrl);
  try {
    const userInfo = yield select((state) => state.UserAccountInfo);
    const { authToken, mobileNumber, callingCode } = userInfo;
    const postData = {
      auth_token: authToken,
      user: {
        country_code: callingCode,
        phone_number: mobileNumber,
        base64_selfie_image: `data:image/jpeg;base64,${base64Data}`,
      },
    };
    const response = yield fetch(URL.UPLOAD_BASE_64_IMAGE, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postData),
    });

    if (response.status >= 200 && response.status < 300) {
      AnalyticsManager.logEvent(EventType.onboardingEvent.FND_IMAGE_UPLOAD);
    } else {
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.FND_IMAGE_UPLOAD_FAILURE,
        {
          [EventParameterKey.STATUS_CODE]: response.status,
        },
      );
    }
  } catch (error) {
    AnalyticsManager.logEvent(
      EventType.onboardingEvent.FND_IMAGE_UPLOAD_FAILURE,
      {
        [EventParameterKey.STATUS_CODE]: 0,
        [EventParameterKey.RAW_ERROR]: JSON.stringify(error),
      },
    );
  }
}

const calculateGenderScore = (gender) => {
  const uac_gender = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.uac_gender);
  const uac_selfie = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.uac_selfie);

  const jsonUacGender = Utility.jsonParser(uac_gender);
  const jsonUacSelfie = Utility.jsonParser(uac_selfie);
  AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_GENDER_SCORE, {
    [EventParameterKey.SCORE]: jsonUacGender[gender] || 0,
  });

  AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_SELFIE_SCORE, {
    [EventParameterKey.SCORE]: Utility.isPresent(jsonUacSelfie)
      ? jsonUacSelfie['yes']
      : 0,
  });

  return jsonUacGender[gender] || 0;
};

function* fetchMyWarholImages() {
  try {
    const headers = yield getApiHeaders();
    const response = yield fetch(URL.GET_WARHOL_IMAGES, {
      method: 'GET',
      headers: headers,
    });
    let warholImages = [];
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        yield put(saveAndyWarholImages([]));
        return;
      }
      warholImages = json.user?.profile?.andy_warhol_filters;
    }
    yield put(saveAndyWarholImages(warholImages));
    const authorized = yield select(
      (state) => state.UserAccountInfo.authorized,
    );
    const profileMenuItems = AppConfig.getProfileMenuItems(authorized);
    if (
      profileMenuItems?.includes('add_shortcut') &&
      Utility.isAndroid() &&
      Utility.isPresent(warholImages) &&
      Utility.isPresent(warholImages[2])
    ) {
      NativeModules.ShortcutCreator.updateShortcut(
        Utility.getMinifiedImage(warholImages[2], 100),
      );
    }
  } catch (error) {
    console.log('GET_WARHOL_RESPONSE', error);
  }
}

export default function* watchGetProfilePicUrlSaga() {
  yield takeLatest(START_FACIAL_ANALYSIS, startFacialAnalysis);
  yield takeLatest(UPDATE_USER_ATTRIBUTE, updateUserAttribute);
  yield takeLatest(GET_MASTER_ATTRIBUTES_LIST, getAllowedAttributes);
  yield takeLatest(UPLOAD_IMAGE, uploadImage);
  yield takeLatest(REFRESH_MY_ATTRIBUTES, refreshAttributes);
  yield takeLatest(RETRY_SELFIE_ANALYSIS_PROCESS, retrySelfieAnalysisProcess);
  yield takeLatest(RESUME_SELFIE_ATTRIBUTE_FETCH, fetchUserAttributes);
  yield takeLatest(
    RESUME_SELFIE_ATTRIBUTE_MAPPING,
    startAttributesMappingWithMasterList,
  );

  yield takeEvery(UPLOAD_FND_IMAGE, uploadFndImageToFoxy);
  yield takeEvery(GET_MY_WARHOL_IMAGES, fetchMyWarholImages);
}
