import { Dimensions } from 'react-native';
import { max } from 'lodash';
import { isDesktop } from './BooleanUtility';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const spacingBetweenItems = 4;

export const getDynamicWidthForGrid = (numberOfItems, spacing, windowRatio = 1) => {
  return (windowWidth * windowRatio - (12 - spacingBetweenItems) * 2 - spacing) / numberOfItems;
};

export const getScreenWidth = () => {
  return isDesktop() ? 1080 : Dimensions.get('window').width;
};

export const getHitSlop = (type = 'default') => {
  const hitslopValues = {
    inputField: {
      top: 100,
      bottom: 100,
      left: 100,
      right: 100,
    },
    likeButton: {
      top: 20,
      bottom: 20,
      left: 20,
      right: 20,
    },
    default: {
      top: 10,
      bottom: 10,
      left: 10,
      right: 10,
    },
    contentPageCrossButton: {
      top: 40,
      bottom: 40,
      left: 40,
      right: 40,
    },
    backButton: {
      top: 20,
      bottom: 4,
      left: 50,
      right: 0,
    },
    address: {
      top: 20,
      bottom: 20,
      left: 20,
      right: 0,
    },
    checkBoxes: {
      top: 40,
      bottom: 40,
      left: 100,
      right: 100,
    },
    button: {
      top: 30,
      right: 50,
      bottom: 20,
      left: 0,
    },
    cross: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    },
    slider: {
      top: 10,
      bottom: 10,
      left: 10,
    },
    playPause: {
      top: 16,
      left: 12,
      bottom: 10,
      right: 4,
    },
    matched: {
      top: 20,
      bottom: 20,
      left: 60,
      right: 100,
    },
    quantityPlus: {
      top: 10,
      right: 50,
      left: 50,
      bottom: 10,
    },
    quantityMinus: {
      top: 10,
      left: 50,
      right: 50,
      bottom: 10,
    },
    birthDateCross: {
      top: 100,
      right: 100,
      left: 100,
      bottom: 100,
    },
    my_profile: {
      top: 30,
      right: 30,
      left: 20,
      bottom: 25,
    },
  };

  const hitslop = hitslopValues[type];
  return hitslop;
};

export const getWindowWidth = () => {
  return Dimensions.get('window').width;
};

export const getScreenHeight = () => {
  return max([Dimensions.get('screen').width, Dimensions.get('screen').height]);
};

export const CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP = 636;
