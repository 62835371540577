import { StyleSheet } from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';

const cartContainerDefaultSize = 38;

const ProductCardStyles = StyleSheet.create({
  defaultSizeLightVerticalCardStyle: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
  },
  defaultSizeLightVerticalCardStyleSku: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    borderRadius: 4,
    flexDirection: 'column',
  },
  defaultSquareLightVerticalCardStyle: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
  },
  largeLightVerticalCardStyle: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
  },
  smallLightVerticalCardStyle: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
  },
  seeReviewCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    marginTop: Utility.spacingBetweenItems,
    marginHorizontal: 12,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 4,
  },
  seeReviewContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingHorizontal: 5,
  },
  seeReviewHeader: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    marginLeft: 10,
    color: colors.foxyBlack,
  },
  seeReviewButtonContainer: {
    flexDirection: 'row',
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  seeReviewButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    marginLeft: 5,
    color: colors.foxyBlack,
    zIndex: 100,
  },
  largeLightHorizontalCardStyle: {
    flexDirection: 'row',
    borderWidth: 1,
    marginTop: 0,
    marginBottom: 0,
    borderColor: colors.borderColor,
    backgroundColor: colors.white,
    borderRadius: 4,
  },
  smallLightHorizontalCardStyle: {
    flexDirection: 'row',
    borderWidth: 0,
    marginTop: 0,
    marginBottom: 0,
    borderColor: colors.borderColor,
    backgroundColor: colors.white,
    borderRadius: 4,
  },

  defaultSizeDarkVerticalCardStyle: {
    backgroundColor: 'black',
    borderColor: colors.borderColor,
    margin: Utility.spacingBetweenItems,
    flexDirection: 'column',
    borderRadius: 4,
  },
  defaultSizeDarkHorizontalCardStyle: {
    backgroundColor: 'black',
    margin: Utility.spacingBetweenItems,
    flexDirection: 'row',
    borderRadius: 4,
  },
  defaultSizeGreyVerticalCardStyle: {
    backgroundColor: 'rgb(68,68,68)',
    borderColor: colors.borderColor,
    margin: Utility.spacingBetweenItems,
    flexDirection: 'column',
    borderRadius: 4,
  },
  defaultSizeGreyHorizontalCardStyle: {
    backgroundColor: 'black',
    margin: Utility.spacingBetweenItems,
    flexDirection: 'row',
    borderRadius: 4,
  },
  defaultSizeLightHorizontalCardStyle: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    // marginBottom: Utility.spacingBetweenItems,
    marginHorizontal: Utility.spacingBetweenItems,
    flexDirection: 'row',
    borderRadius: 4,
  },
  largeDarkHorizontalCardStyle: {
    flexDirection: 'row',
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: 'black',
    borderRadius: 4,
  },
  smallDarkHorizontalCardStyle: {
    flexDirection: 'row',
    borderWidth: 0,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: 'black',
    borderRadius: 4,
  },
  largeGreyHorizontalCardStyle: {
    flexDirection: 'row',
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: 'rgb(68,68,68)',
    borderRadius: 4,
  },
  smallGreyHorizontalCardStyle: {
    flexDirection: 'row',
    borderWidth: 0,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: 'rgb(68,68,68)',
    borderRadius: 4,
  },
  verticalCartImageContainer: {
    width: cartContainerDefaultSize,
    height: cartContainerDefaultSize,
    zIndex: 5,
    right: 9,
    bottom: 48,
    position: 'absolute',
  },
  horizontalCartImageContainer: {
    position: 'absolute',
    width: cartContainerDefaultSize,
    height: cartContainerDefaultSize,
    right: 14,
    top: '50%',
    marginTop: -18,
    backgroundColor: 'transparent',
  },
  verticalRailCartImageContainer: {
    position: 'absolute',
    width: 146,
    height: 28,
    bottom: 2,
    right: 0,
  },
  gridCartImageContainer: {
    position: 'absolute',
    bottom: 78,
    right: 10,
  },
  listCartImageContainer: {
    position: 'absolute',
    bottom: 12,
    right: 12,
  },
  personalisedRailCartImageContainer: {
    position: 'absolute',
    bottom: 64,
    right: 12,
  },

  desriptionContainer: {
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'flex-start',
  },
  desriptionContainerMatched: {
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'flex-start',
  },
  desriptionContainerLeftAligned: {
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  desriptionContainerMatchedLeftAligned: {
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  ratingRectangle: {
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    right: 12,
    height: 17,
    borderRadius: 4,
    backgroundColor: colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 0,
  },

  ratingOval: {
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    right: 12,
    minWidth: 30,
    height: 17,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 20,
    backgroundColor: '#d0d0d0',
    justifyContent: 'center',
    alignItems: 'center',
  },

  ratingOvalLeftAligned: {
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 34,
    height: 17,
    borderRadius: 20,
    backgroundColor: '#d0d0d0',
    justifyContent: 'center',
    alignItems: 'center',
  },

  railRatingRectangle: {},

  gridRatingRectangle: {},

  listRatingRectangle: {},

  ratingnumber: {
    fontSize: size.h5,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 11,
    marginTop: 2,
  },
  railCartStyle: {
    width: 52,
    height: 52,
    borderRadius: 52 / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.border,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  largerailCartStyle: {
    width: cartContainerDefaultSize,
    height: cartContainerDefaultSize,
    borderRadius: cartContainerDefaultSize / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.border,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  largegridCartStyle: {
    width: cartContainerDefaultSize,
    height: cartContainerDefaultSize,
    borderRadius: cartContainerDefaultSize / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.border,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  slimRailCartStyle: {
    width: 37,
    height: 37,
    borderRadius: 37 / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.border,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  slimGridCartStyle: {
    width: 37,
    height: 37,
    borderRadius: 37 / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.border,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  horizontalRailCartStyle: {
    width: cartContainerDefaultSize,
    height: cartContainerDefaultSize,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  gridCartStyle: {
    width: cartContainerDefaultSize,
    height: cartContainerDefaultSize,
    borderRadius: cartContainerDefaultSize / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.border,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    opacity: 1,
  },
  listCartStyle: {
    width: cartContainerDefaultSize,
    height: cartContainerDefaultSize,
    borderRadius: cartContainerDefaultSize / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.border,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  verticalRailCartStyle: {
    width: cartContainerDefaultSize,
    height: cartContainerDefaultSize,
    borderRadius: cartContainerDefaultSize / 2,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  personalisedRailCartStyle: {
    width: cartContainerDefaultSize,
    height: cartContainerDefaultSize,
    borderRadius: cartContainerDefaultSize / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.border,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
  },
  listAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 21,
    height: 24,
    resizeMode: 'cover',
  },
  gridAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 21,
    height: 24,
    resizeMode: 'contain',
  },
  railAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 28,
    height: 30,
    resizeMode: 'contain',
  },
  largerailAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 21,
    height: 24,
    resizeMode: 'contain',
  },
  largegridAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 22,
    height: 22,
    resizeMode: 'contain',
  },
  slimRailAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 22,
    height: 22,
    resizeMode: 'contain',
  },
  slimGridAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 22,
    height: 22,
    resizeMode: 'contain',
  },
  gridAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 21,
    height: 24,
    resizeMode: 'contain',
  },
  horizontalRailAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 21,
    height: 24,
    resizeMode: 'contain',
  },
  personalisedRailAddToCartImage: {
    position: 'absolute',
    alignSelf: 'center',
    width: 21,
    height: 24,
    resizeMode: 'contain',
  },
  priceStyle: {
    flex: 1,
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    left: 8,
    bottom: 6,
  },
  railPriceContainer: {
    // flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
  },
  slimRailPriceContainer: {
    // flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
  },
  slimGridPriceContainer: {
    // flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
  },
  gridPriceContainer: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    paddingLeft: 8,
  },
  matchedProductPriceContainer: {
    flexDirection: 'row',
    paddingLeft: 12,
    alignItems: 'center',
  },
  verticalRailPriceContainer: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
  },
  horizontalRailPriceContainer: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
  },
  personalisedRailPriceContainer: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
  },
  listPriceContainer: {
    // flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
  },
  railPriceContainerLeftAligned: {
    // flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  gridPriceContainerLeftAligned: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    paddingLeft: 8,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  matchedProductPriceContainerLeftAligned: {
    flexDirection: 'row',
    paddingLeft: 12,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    marginLeft: 8,
  },
  verticalRailPriceContainerLeftAligned: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  horizontalRailPriceContainerLeftAligned: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  personalisedRailPriceContainerLeftAligned: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  listPriceContainerLeftAligned: {
    // flex: 1,
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    marginLeft: 8,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  lightspStyle: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  railspStyle: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  largerailspStyle: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  dynamicCardspStyle: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  slimRailspStyle: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  slimGridspStyle: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  darkspStyle: {
    color: 'white',
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  greyspStyle: {
    color: 'white',
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  matchedProductspStyle: {
    color: colors.foxyBlack,
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
  },
  mrpStyle: {
    color: colors.silver,
    fontSize: size.h5,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  gridmrpStyle: {
    color: colors.silver,
    fontSize: size.h5,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  dynamicCardmrpStyle: {
    color: colors.silver,
    fontSize: size.h4,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  listmrpStyle: {
    color: colors.silver,
    fontSize: size.h5,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  railmrpStyle: {
    color: colors.silver,
    fontSize: 12,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  largerailmrpStyle: {
    color: colors.silver,
    fontSize: 12,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  personalisedRailmrpStyle: {
    color: colors.silver,
    fontSize: size.h5,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  slimRailmrpStyle: {
    color: colors.silver,
    fontSize: 12,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  slimGridmrpStyle: {
    color: colors.silver,
    fontSize: 11,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  matchedProductmrpStyle: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 19,
    color: colors.silver,
    textDecorationLine: 'line-through',
    marginLeft: 3,
  },
  gridDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: size.h5,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  dynamicCardDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: size.h4,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  verticalRailDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: size.h5,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  horizontalRailDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: size.h5,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  listDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: size.h5,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  railDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: 12,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  largerailDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: 12,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  slimRailDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: 12,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },
  slimGridDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: 11,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.9,
  },

  personalisedRailDiscountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: 12,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
  },

  matchedProductDiscountRateStyle: {
    letterSpacing: 0,
    lineHeight: 14,
    opacity: 0.7,
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: size.h3,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
  },
  lightProductName: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8,
    marginVertical: 4,
  },
  matchedProductProductName: {
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8,
    marginVertical: 4,
  },
  listProductName: {
    marginLeft: 8,
    marginRight: 8,

    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,

    fontStyle: 'normal',
  },
  gridProductName: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8,
    marginVertical: 4,
    height: 32,
  },
  darkProductName: {
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    fontSize: size.h4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginVertical: 4,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 2,
    lineHeight: 14,
    height: 28,
  },
  greyProductName: {
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    fontSize: size.h4,

    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginVertical: 4,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 2,
    lineHeight: 14,
    height: 28,
  },
  lightProductNameLeftAligned: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8,
    marginVertical: 4,
  },
  matchedProductProductNameLeftAligned: {
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8,
    marginVertical: 4,
  },
  listProductNameLeftAligned: {
    marginLeft: 8,
    marginRight: 8,

    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,

    fontStyle: 'normal',
    textAlign: 'left',
  },
  gridProductNameLeftAligned: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8,
    marginVertical: 4,
    height: 32,
  },
  dynamicCardProductNameLeftAligned: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginHorizontal: 8,
    marginVertical: 0,
    marginTop: 4,
  },
  dynamicCardProductName: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    marginHorizontal: 8,
    marginVertical: 0,
    marginTop: 4,
  },
  dynamicCardSingleLineProductNameContainer: {
    height: 22,
  },
  dynamicCardMultiLineProductNameContainer: {
    height: 40,
  },
  darkProductNameLeftAligned: {
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    fontSize: size.h4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginVertical: 4,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 2,
    lineHeight: 14,
    height: 28,
  },
  greyProductNameLeftAligned: {
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    fontSize: size.h4,

    fontStyle: 'normal',
    letterSpacing: 0,
    alignItems: 'flex-start',
    marginVertical: 4,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 2,
    lineHeight: 14,
    height: 28,
    textAlign: 'left',
  },
  railProductNameLeftAligned: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 1,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 14,
    marginVertical: 4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    height: 36,
  },
  largerailProductNameLeftAligned: {
    marginTop: 4,
    marginHorizontal: 6,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontStyle: 'normal',
    textAlign: 'left',
    alignItems: 'center',
    height: 40,
  },
  productName: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 1,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,
    marginVertical: 4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    height: 28,
  },
  railProductName: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 1,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 14,
    marginVertical: 4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    height: 36,
  },
  listBrandName: {
    marginTop: 8,
    marginLeft: 8,
    marginRight: 35,
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
  },
  slimRailProductName: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 1,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 14,
    marginVertical: 4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    height: 36,
  },
  slimGridProductName: {
    marginLeft: 8,
    marginRight: 8,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 12,
    marginTop: 1,
    marginBottom: 4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    height: 32,
  },
  brandName: {
    marginTop: 8,
    marginLeft: 8,
    marginRight: 35,
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
  },
  railBrandName: {
    marginTop: 8,
    marginLeft: 8,
    marginRight: 35,
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
  },
  gridBrandName: {
    marginTop: 8,
    marginLeft: 8,
    marginRight: 35,
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
  },
  dynamicCardBrandName: {
    marginTop: 8,
    marginLeft: 8,
    marginRight: 35,
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
  },
  slimRailBrandName: {
    marginTop: 8,
    marginLeft: 8,
    marginRight: 35,
    fontFamily: 'Roboto-Bold',
    fontSize: 11,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
  },
  slimGridBrandName: {
    marginTop: 6,
    marginLeft: 8,
    marginRight: 8,
    fontFamily: 'Roboto-Medium',
    fontSize: 11,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
  },
  matchedProductBrandName: {
    marginTop: 8,
    marginLeft: 8,
    marginRight: 35,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.subtitle,
  },
  verticalRailBrandName: {
    marginTop: 8,
    marginLeft: 8,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h5,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
    marginRight: 8,
  },
  horizontalRailBrandName: {
    marginTop: 8,
    marginLeft: 8,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h5,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
    marginRight: 8,
  },
  personalisedRailBrandName: {
    marginTop: 8,
    marginLeft: 8,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h5,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
    marginRight: 8,
  },
  productImageBackground: {
    alignItems: 'flex-end',
    borderRadius: 14,
    overflow: 'hidden',
  },
  listProductImageBackground: {
    alignItems: 'flex-end',
    borderRadius: 4,
    overflow: 'hidden',
  },
  railProductImageBackground: {
    alignItems: 'flex-end',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: 'hidden',
  },
  gridProductImageBackground: {
    alignItems: 'flex-end',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: 'hidden',
  },
  slimGridProductImageBackground: {
    alignItems: 'flex-end',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: 'hidden',
  },
  smallListSeparatorStyle: {
    height: 2,
    backgroundColor: colors.background,
  },
  defaultSizeListSeparatorStyle: {
    height: 2,
    backgroundColor: colors.background,
  },
  largeListSeparatorStyle: {
    height: 2,
    backgroundColor: colors.background,
  },
  smallGridSeparatorStyle: {
    height: 1,
    backgroundColor: colors.background,
  },
  defaultSizeGridSeparatorStyle: {
    height: 1,
    backgroundColor: colors.background,
  },
  largeGridSeparatorStyle: {
    height: 1,
    backgroundColor: colors.background,
  },
  brandContainerStyle: {
    flexDirection: 'row',
    position: 'absolute',
    top: 8,
    height: 25,
    width: 80,
  },

  brandImageStyle: {
    flex: 1,
    marginRight: 0,
    resizeMode: 'contain',
  },
  railDescriptionContainerStyle: {
    flex: 1,
  },
  gridDescriptionContainerStyle: {
    flex: 1,
  },
  listDescriptionContainerStyle: {
    flex: 1,
    marginRight: 60,
  },
  verticalRailDescriptionContainerStyle: {
    flex: 1,
    marginRight: 4,
  },
  personalisedRailDescriptionContainerStyle: {
    flex: 1,
    marginRight: 4,
  },
  verticalRailDivider: {
    height: '100%',
    width: 1,
    backgroundColor: colors.borderColor,
  },
  personalisedRailDivider: {
    height: '100%',
    width: 1,
    backgroundColor: colors.borderColor,
  },
  railDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  gridDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  listProdImageRatingContainer: {
    alignSelf: 'center',
    marginLeft: 8,
  },
  verticalRailProdImageRatingContainer: {
    alignSelf: 'center',
    marginLeft: 0,
  },
  personalisedRailProdImageRatingContainer: {
    alignSelf: 'center',
    marginLeft: 0,
  },
  verticalRailSeparator: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 1,
    backgroundColor: colors.borderColor,
  },
  personalisedRailSeparator: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 1,
    backgroundColor: colors.borderColor,
  },
  addToBagText: {
    position: 'absolute',
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    left: 44,
    bottom: 3,
    overflow: 'visible',
  },
  prodImageRatingContainerForCart: {
    alignSelf: 'center',
    marginLeft: 12,
  },
  row: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    paddingTop: 8,
    justifyContent: 'space-between',
    bottom: 1,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 15,
  },
  orderStatus: {
    color: colors.silver,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4,
  },
  productCardPromptsText: {
    fontSize: 12,
    fontFamily: 'Roboto',
    color: colors.foxyPink,
    marginBottom: 8,
    marginLeft: 8,
  },
  specialPriceText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.darkGreen,
    marginBottom: 4,
    marginLeft: 8,
    opacity: 0.7,
  },
  specialPriceTextRail: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.darkGreen,
    marginBottom: 4,
    marginLeft: 8,
    opacity: 0.7,
  },
  promptText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyPink,
    marginBottom: 4,
    marginLeft: 8,
  },
  labelImageBackground: {
    height: 20,
    width: 80,
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelImageBackgroundVariant: {
    height: 20,
    width: 80,
    overflow: 'visible',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelContainerVariant: {
    position: 'absolute',
    top: 30,
    overflow: 'hidden',
  },
  labelContainer: {
    position: 'absolute',
    top: 0,
    overflow: 'hidden',
    zIndex: 1,
  },
  slimGridLabelOverflowContainer: {
    position: 'absolute',
    top: -4,
    overflow: 'visible',
  },
  labelHideOverflow: {
    overflow: 'hidden',
  },
  labelTextContainer: {
    marginLeft: 8,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
  },
  redDot: {
    backgroundColor: colors.foxyPink,
    height: 4,
    width: 4,
    borderRadius: 2,
    marginLeft: -4,
    marginRight: 2,
  },
  labelTextStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4_5,
    color: colors.white,
    letterSpacing: 0,
    // marginTop: -2,
  },
  labelTextExtraMarginStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4_5,
    color: colors.black,
    letterSpacing: 0,
    marginLeft: 4,
  },
  smallLabelTextExtraMarginStyle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 10,
    color: colors.black,
    letterSpacing: 0,
    marginLeft: 4,
  },
  labelShimmer: {
    width: 80,
    height: 24,
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    left: -8,
  },
  smallLabelShimmer: {
    width: 72,
    height: 14,
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    left: -8,
  },

  pointsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 6,
  },
  coinsImage: {
    height: 20,
    width: 20,
    marginRight: 2,
  },
  coinsValue: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h4_5,
    color: colors.subtitle,
  },
  lockIcon: {
    position: 'absolute',
    right: 12,
    top: 12,
    width: 24,
    height: 24,
  },
  selectedBoxStyle: {
    flexDirection: 'column',
    borderColor: colors.green,
    shadowColor: colors.green,
    shadowOpacity: 0.5,
    shadowRadius: 2,
    shadowOffset: {
      height: 2,
      width: 1,
    },
    padding: 0,
    borderWidth: 2,
    margin: 4,
    elevation: 3,
  },
  boxStyle: { margin: 4, elevation: 3 },
  greenTickStyle: { position: 'absolute', left: 12, top: 12 },
  clubDealStar: {
    height: 10,
    width: 10,
    resizeMode: 'contain',
    marginRight: 4,
  },
  lockImageStyle: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  fullHeight: {
    height: '100%',
  },
  fullHeightWithPadding: {
    height: '100%',
    marginVertical: 12,
  },
  largeDescriptionContainer: {
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
  },
  largeDescriptionContainerLeftAligned: {
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  marginTop: { marginTop: 12 },
  largerailProductName: {
    marginTop: 4,
    marginHorizontal: 6,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontStyle: 'normal',
    textAlign: 'center',
    alignItems: 'center',
    height: 40,
  },
  largerailPriceContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 4,
  },
  dynamicCardPriceContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 4,
    marginTop: 8,
  },
  dynamicCardPriceContainerLeftAligned: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 4,
    marginTop: 8,
    marginLeft: 8,
    alignItems: 'baseline',
    alignSelf: 'flex-start',
  },
  largerailPriceContainerLeftAligned: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 4,
    marginLeft: 8,
    alignItems: 'baseline',
    alignSelf: 'flex-start',
  },
  verticalRailmrpStyle: {
    color: colors.silver,
    fontSize: size.h5,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  horizontalRailmrpStyle: {
    color: colors.silver,
    fontSize: size.h5,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 14,
  },
  largeSpecialPriceText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.darkGreen,
    marginBottom: 4,
    marginLeft: 8,
    opacity: 0.7,
  },
  fullWidthShimmer: {
    height: 52,
    width: 52,
    position: 'absolute',
    borderRadius: 26,
  },
  rectangularBagButton: {
    width: '92%',
    height: 34,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 8,
    backgroundColor: colors.black,
    flexDirection: 'row',
  },
  rectangularBagButtonPlusIcon: {
    width: 10,
    height: 10,
    resizeMode: 'contain',
    tintColor: colors.white,
  },
  rectangularBagButtonPlusIconLarge: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    tintColor: colors.white,
  },
  rectangularBagButtonTrashIcon: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    tintColor: colors.white,
  },
  barLoader: { backgroundColor: colors.white, marginBottom: 2 },
  rectangularBagText: {
    fontFamily: 'Roboto-Medium',
    marginLeft: 8,
    fontSize: 14,
  },
  quantityIncrementContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '92%',
    height: 34,
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 8,
    backgroundColor: colors.black,
    borderRadius: 4,
  },
  quantityIncrementContainerLoading: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '92%',
    height: 34,
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 8,
    backgroundColor: colors.black,
    opacity: 0.9,
    borderRadius: 4,
  },
  quantityText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: colors.white,
    marginHorizontal: 24,
  },
  slimGridDiscountPriceText: {
    fontSize: 11,
    fontFamily: 'Roboto-Regular',
    color: colors.darkGreen,
    marginBottom: 4,
    marginLeft: 8,
    opacity: 0.7,
  },
  bottomRatingContainer: {
    flexDirection: 'row',
    marginLeft: 5,
    marginBottom: 8,
    alignItems: 'center',
  },
  bottomRatingText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: colors.black,
    marginLeft: 4,
  },
  heroDescription: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    marginHorizontal: 8,
  },
  singleLineHeroDescriptionContainer: {
    height: 16,
  },
  multiLineHeroDescriptionContainer: {
    height: 32,
  },
  dynamicGreenTextPlaceholder: {
    height: 20,
  },
});

export default ProductCardStyles;
