import { takeLatest } from 'redux-saga/effects';
import {
  SEARCH_SALON_NEARBY,
  SEARCH_SALON,
  CREATE_SALON_UPI_ORDER,
  GET_SETU_PAYMENT_STATUS,
  GET_WHEEL_OFFERS,
  GET_ELIGIBILITY_FOR_KIT,
  NOTIFY_USER_AFTER_WHEEL_SPIN,
} from '../actions/SalonActions';
import { getApiHeaders } from './GeneratorUtil';
import { URL, API_DOMAIN } from '../config/Constants';
import Utility from '../utils/Utility';

export function* getSalonsNearBy(action) {
  const { GET_SALON_NEARBY } = URL;
  const { lat, longitude, searchQuery, callback } = action;
  try {
    let url = `${GET_SALON_NEARBY}?Nan=Nan`;
    if (Utility.isPresent(lat)) {
      url = `${url}&lat=${lat}&long=${longitude}`;
    }

    if (Utility.isPresent(searchQuery)) {
      url = `${url}&q=${searchQuery}`;
    }

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield response.json();
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* getSalon(action) {
  const { searchQuery, callback } = action;
  try {
    const url = `${API_DOMAIN}${searchQuery}`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield response.json();
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* createSalonUpiOrder(action) {
  const { entityType, salonId, amount, packageName, callback } = action;
  try {
    const amountInPaisa = amount * 100;
    const postObject = {
      setu_payment: {
        entity_type: entityType,
        entity_id: salonId,
        amount: amountInPaisa,
      },
    };
    const response = yield fetch(URL.SETU_PAYMENTS, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postObject),
    });
    if (response.status >= 200 && response.status < 300) {
      const jsonResponse = yield response.json();
      callback(true, jsonResponse, packageName);
    } else {
      callback(false);
    }
  } catch (err) {
    callback(false);
  }
}

export function* getSetuPaymentStatus(action) {
  const { setuPaymentId, callback } = action;
  try {
    const response = yield fetch(`${URL.SETU_PAYMENTS}/${setuPaymentId}`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const responseJson = yield response.json();
      callback(true, responseJson);
    } else {
      callback(false);
    }
  } catch (err) {
    callback(false);
  }
}

export function* getWheelOffers(action) {
  const { callback } = action;
  try {
    const response = yield fetch(`${URL.SETU_PAYMENTS}/offers`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const responseJson = yield response.json();
      callback(true, responseJson);
    } else {
      callback(false);
    }
  } catch (err) {
    callback(false);
  }
}

export function* getEligibilityForKit(action) {
  const { callback } = action;
  try {
    const response = yield fetch(`${URL.SETU_PAYMENTS}/eligible_for_kits`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const responseJson = yield response.json();
      callback(true, responseJson);
    } else {
      callback(false);
    }
  } catch (err) {
    callback(false);
  }
}

export function* notifyUserAfterWheelSpin(action) {
  const { setuId } = action;
  try {
    const url = `${URL.SETU_PAYMENTS}/${setuId}/notify`;
    const response = yield fetch(url, {
      headers: yield getApiHeaders(),
      method: 'POST',
    });
  } catch (err) {
    console.log(`Error ${err}`);
  }
}

export default function* watchSalonSaga() {
  yield takeLatest(SEARCH_SALON_NEARBY, getSalonsNearBy);
  yield takeLatest(SEARCH_SALON, getSalon);
  yield takeLatest(CREATE_SALON_UPI_ORDER, createSalonUpiOrder);
  yield takeLatest(GET_SETU_PAYMENT_STATUS, getSetuPaymentStatus);
  yield takeLatest(GET_WHEEL_OFFERS, getWheelOffers);
  yield takeLatest(GET_ELIGIBILITY_FOR_KIT, getEligibilityForKit);
  yield takeLatest(NOTIFY_USER_AFTER_WHEEL_SPIN, notifyUserAfterWheelSpin);
}
