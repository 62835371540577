import { takeEvery, select, call } from 'redux-saga/effects';
import { POST_SELECTED_INTEREST } from '../actions/LoginActions';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';

import { getApiHeaders } from './GeneratorUtil';

const fetchPostSelectedInterests = (
  authToken,
  selectedInterests,
  guestAuthToken,
  headers,
) => {
  let postData = {};
  if (!authToken || Utility.isBlank(authToken)) {
    postData = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        guest_token: guestAuthToken,
        interests: selectedInterests,
      }),
    };
  } else {
    postData = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        auth_token: authToken,
        interests: selectedInterests,
      }),
    };
  }
  const response = fetch(URL.POST_SELECTED_INTERESTS, postData);
  return response;
};

function* postInterest(action) {
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const guestAuthToken = yield select(
      (state) => state.UserAccountInfo.guestProfile.guestAuthToken,
    );
    const selectedInterests = yield select(
      (state) => state.UserAccountInfo.selectedInterests,
    );
    console.log('selectedInterests in saga', selectedInterests);
    const headers = yield getApiHeaders();
    const response = yield call(
      fetchPostSelectedInterests,
      authToken,
      selectedInterests,
      guestAuthToken,
      headers,
    );
    if (response.status === 200) {
      console.log('Sucess', response);
    } else {
      console.log(
        `Error in posting Selected Interests: ${JSON.stringify(response)}`,
      );
    }
  } catch (error) {
    console.log(`Error in posting Selected Interests ${error}`);
  }
}

export default function* watchPostSelectedInterestSaga() {
  yield takeEvery(POST_SELECTED_INTEREST, postInterest);
}
