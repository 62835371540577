import React, { Component, useMemo } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ImageBackground,
  Linking,
  TouchableWithoutFeedback,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Toast from 'react-native-easy-toast';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withMaybe } from '../../lib/Monads';
import {
  LAYOUT,
  THEME,
  MAX_LINES,
  WHATS_APP_NUMBER,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import ProductCardStyles from './styles/styles';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AddToCart from './AddToCart';
import { productInCartIds, ProductInCartStyles } from './styles';
import images from '../../theme/Images';
import {
  removeFromCart,
  cancelOrderItemById,
  isSpecialOfferApplied,
} from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import FoxyAlert from '../camera/shared/FoxyAlert';
import AddReviewModal from './AddReviewModal';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../analytics';
import CountDown from '../../helpers/Counter';
import RemoteConfig from '../../utils/RemoteConfig';
import FullWidthShimmer from '../../lib/FullWidthShimmer';
import FastImageView from '../FastImageView';
import AppConfig from '../../config/AppConfig';
import TimeUtility from '../../utils/TimeUtility';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import { getPriceText } from '../../utils/NumberUtility';
import { interpolateString } from '../../utils/StringUtility';
import { isDesktop } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';

class ProductCardForCart extends Component {
  constructor(props) {
    super(props);
    this.name =
      this.props?.cartItem?.product?.name?.replace(
        `${this.props.cartItem.product.brandName} `,
        '',
      ) || '';
    this.state = {
      showAlert: false,
      alertText: '',
      itemStatus: this.props.cartItem.status,
      alertWhatsAppMessage: '',
      showFlashDealTimer: true,
    };
    this.productMeta = {};
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });

    this.x = { x: 0, y: 0 };
    this.y = { x: 0, y: 1 };

    this.productDealsTagData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.product_deals_tag_data),
    );
  }

  componentDidMount() {
    const { cartItem } = this.props;

    const offerActive = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.active_offer);

    if (Utility.isPresent(cartItem)) {
      this.productMeta = this.getProductMeta();
    }
    if (cartItem.offer_id === offerActive) {
      isSpecialOfferApplied(cartItem.eligible_for_free_item);
    }
  }

  componentDidUpdate(prevProps) {
    const { cartItem, isSpecialOfferApplied } = this.props;
    if (Utility.isPresent(cartItem) && Utility.isPresent(cartItem.offer_id)) {
      if (
        prevProps.cartItem.eligible_for_free_item !==
        cartItem.eligible_for_free_item
      ) {
        isSpecialOfferApplied(cartItem.eligible_for_free_item);
      }
    }
  }

  setShowFlashDealTimer = (show) => {
    this.setState({
      showFlashDealTimer: show,
    });
  };

  navigate = () => {
    const { navigation, id, cartItem, onPress = () => {} } = this.props;
    onPress();
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        id,
        slug: cartItem.product.slug,
        display: LAYOUT.SCREEN,
        sku_id: cartItem?.sku_id,
        previousScreen: this.props?.previousScreen,
      },
    });
  };

  navigatefromOrders = () => {
    const { navigation, cartItem } = this.props;
    navigateToScreen({
      navigation,
      type: 'navigate',
      screen: 'Product',
      params: {
        slug: cartItem.product.slug,
        display: LAYOUT.SCREEN,
      },
    });
  };

  navigateToOrderDetail = () => {
    const { navigation, orderId } = this.props;
    navigation.navigate('OrderDetails', { id: orderId });
  };

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  discountConditionFn = (props) =>
    parseFloat(props.sp) === parseFloat(props.mrp);

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  getProductMeta = () => {
    const { cartItem } = this.props;
    const productMeta = {
      [EventParameterKey.PRODUCT_ID]: cartItem?.product?.id,
      [EventParameterKey.PRODUCT_NAME]: cartItem?.product?.name,
      [EventParameterKey.BRAND_ID]:
        cartItem?.product?.brand && cartItem?.product?.brand?.id,
      [EventParameterKey.BRAND_NAME]:
        cartItem?.product?.brand && cartItem?.product?.brand?.name,
      [EventParameterKey.PRODUCT_LISTING_PRICE]: cartItem?.product?.mrp,
      [EventParameterKey.PRODUCT_SELLING_PRICE]: cartItem?.product?.sp,
      [EventParameterKey.QUANTITY]: cartItem?.quantity,
    };
    if (Utility.isPresent(cartItem.variant)) {
      productMeta[[EventParameterKey.VARIANT_ID]] = cartItem?.variant?.id;
      productMeta[[EventParameterKey.VARIANT_NAME]] =
        cartItem?.variant?.display_name;
    }
    return productMeta;
  };

  removeProductFromCart = () => {
    const {
      skuId,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      onItemAddToCartFromCollab,
      campaignId,
    } = this.props;
    const data = {
      sku_id: skuId,
    };
    this.props.removeFromCart(data, () => {
      if (onItemAddToCartFromCollab) {
        if (Utility.isPresent(campaignId)) onItemAddToCartFromCollab();
      }
    });
    if (refreshOfferStrip) {
      setTimeout(() => {
        refreshOfferStrip();
        if (refreshOffersDetailsPageDiscountStrip) {
          refreshOffersDetailsPageDiscountStrip();
        }
      }, 200);
    }
    AnalyticsManager.logEvent(
      EventType.discoveryEvents.PRODUCT_REMOVE_FROM_CART,
      this.productMeta,
    );
  };

  fireStockErrorAnalytics = () => {
    AnalyticsManager.logEvent(EventType.product.STOCK_ERROR, this.productMeta);
  };

  RatingComponent = (props) => {
    const { layout, rating } = props;
    const styles = ProductCardStyles;
    return (
      <View
        style={[styles.ratingRectangle, styles[`${layout}RatingRectangle`]]}
      >
        <Text numberOfLines={MAX_LINES.single} style={styles.ratingnumber}>
          {rating}
        </Text>
      </View>
    );
  };

  productImageAndRating = (props) => {
    const {
      imageUrl,
      webpImageUrl,
      variantImageUrl,
      variantWebpImageUrl,
      id,
      isFree,
      opacity,
    } = props;
    let newImageUrl = Utility.getMinifiedImage(imageUrl, 200, 200);

    if (Utility.isPresent(variantImageUrl) && variantImageUrl !== '') {
      newImageUrl = Utility.isAndroid()
        ? variantWebpImageUrl
        : Utility.getMinifiedImage(variantImageUrl, 200, 200);
    }
    const styles = ProductInCartStyles;
    return (
      <>
        <FastImageView
          resizeMode="contain"
          source={newImageUrl}
          style={[styles.productImage, { opacity }]}
        />
      </>
    );
  };

  productImage = (props) => {
    const { imageUrl, fromOrderDetail, inOrderDetail } = props;
    const styles = ProductInCartStyles;
    return (
      <TouchableOpacity
        onPress={
          fromOrderDetail || inOrderDetail
            ? this.navigatefromOrders
            : this.navigateToOrderDetail
        }
      >
        <Image
          source={{ uri: Utility.getMinifiedImage(imageUrl) }}
          style={styles.productImageOrders}
        />
      </TouchableOpacity>
    );
  };

  descriptionForFreeProduct = (props) => {
    const styles = ProductInCartStyles;
    const { shouldShowStrikedPriceForFreeItem, mrp } = props;

    return (
      <View style={styles.priceContainer}>
        <Text style={[styles.spStyle, { color: colors.green }]}>FREE</Text>
        {parseFloat(mrp) !== 0 && (
          <Text style={[styles.mrpStyle, { marginRight: 4 }]}>
            {getPriceText(mrp)}
          </Text>
        )}
      </View>
    );
  };

  productDescription = (props) => {
    const styles = ProductInCartStyles;
    const { couponCodes, hideAddToCart } = this.props;
    const {
      mrp = '',
      sp = '',
      discount = '',
      brandName = Config.APP_NAME,
      name = '',
      isFree,
      couponDiscount,
      participating_discount_offer_id,
      orderCampaign: { expected_instock_date = '' } = {},
    } = props;
    const dispatchDate = expected_instock_date ?? '';
    let { prompt_discount = '', prompt_esp = '', prompt_mrp = '' } = props;
    const productName = name.replace(`${brandName} `, '');
    const shouldShowStrikedPriceForFreeItem =
      isFree && Utility.isPresent(couponDiscount) && couponDiscount !== 0;
    if (Utility.isPresent(prompt_esp)) {
      sp = prompt_esp;
      mrp = prompt_mrp;
      discount = prompt_discount;
    }

    const participating_discount_offer = _.find(
      couponCodes,
      (code) => code.offer_id === participating_discount_offer_id,
    );

    const productDescriptionStyleInCart = hideAddToCart
      ? styles.descriptionContainerStyleWithoutAddToCart
      : styles.descriptionContainerStyleWithAddToCart;

    const productDescriptionMediaInCart = hideAddToCart
      ? productInCartIds.descriptionContainerStyleWithoutAddToCart
      : productInCartIds.descriptionContainerStyleWithAddToCart;

    const discountedPrice = sp === 0 ? `FREE` : `${getPriceText(sp)} `;
    const isDispatchDelay = true;
    const advanceOrderCampaignCartText = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.advance_order_campaign_cart_text,
    );

    return (
      <View style={productDescriptionStyleInCart} dataSet={{ media: productDescriptionMediaInCart }}>
        {!isDispatchDelay ? (
          <Text numberOfLines={MAX_LINES.single} style={styles.brandName}>
            {brandName?.toUpperCase()}
          </Text>
        ) : (
          <View style={styles.brandNameContainer}>
            <Text
              numberOfLines={MAX_LINES.two}
              style={styles.brandNameWithDispatchDate}
            >
              {brandName?.toUpperCase()}
            </Text>
            {Utility.isPresent(dispatchDate) && (
              <View style={styles.dispatchDateContainer}>
                <Text style={styles.dispatchDateText} e>
                  {interpolateString(advanceOrderCampaignCartText, {
                    date: dispatchDate,
                  })}
                </Text>
              </View>
            )}
          </View>
        )}
        <Text numberOfLines={MAX_LINES.two} style={styles.productName}>
          {productName}
        </Text>
        <this.productVariant />
        {isFree ? (
          <this.descriptionForFreeProduct
            shouldShowStrikedPriceForFreeItem={
              shouldShowStrikedPriceForFreeItem
            }
            mrp={mrp}
          />
        ) : (
          <View style={styles.priceContainer}>
            {Utility.isPresent(sp) && (
              <Text style={styles.spStyle}>{discountedPrice}</Text>
            )}
            {Utility.isPresent(mrp) && (
              <this.conditionalPriceText
                style={styles.mrpStyle}
                sp={sp}
                mrp={mrp}
              >
                {getPriceText(mrp)}
              </this.conditionalPriceText>
            )}
            {parseFloat(sp) !== parseFloat(mrp) && (
              <this.conditionalDiscountText
                style={styles.discountRateStyle}
                discount={discount}
              >
                {` ${discount || ''}`}
              </this.conditionalDiscountText>
            )}
          </View>
        )}
        {Utility.isPresent(participating_discount_offer) && (
          <View style={styles.productCardCouponContainer}>
            <Image
              source={images.ic_coupon}
              style={styles.productCardCouponImage}
            />
            <Text
              style={styles.couponAppliedStyle}
            >{`${participating_discount_offer?.coupon_code} applied`}</Text>
          </View>
        )}
      </View>
    );
  };

  cancelAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  cancelOrderItem = (id) => {
    const { cancelOrderItemById } = this.props;
    cancelOrderItemById(id, (response) => {
      if (response === 'success') {
        this.setState({ showAlert: false, itemStatus: 'cancelled' });
      }
    });
  };

  addToCartError = (response) => {
    const { showToastInCart = () => {} } = this.props;
    if (Utility.isPresent(response.errors) && response.errors.length > 0) {
      showToastInCart(response.errors[0]);
      this.fireStockErrorAnalytics();
    }
  };

  variantImage = (props) => {
    const { variantImage } = props;
    if (Utility.isBlank(variantImage) || variantImage === '') {
      return null;
    }
    return (
      <Image
        style={{
          height: 10,
          width: 10,
          borderRadius: 5,
          marginRight: 4,
          // tintColor: 'black',
        }}
        source={{ uri: Utility.getMinifiedImage(variantImage) }}
      />
    );
  };

  productVariant = () => {
    const {
      cartItem: { variant = [] },
      cartProduct: {
        variants_details: { variants_property_name = '' } = {},
      } = {},
    } = this.props;
    if (Utility.isBlank(variant.display_name)) {
      return null;
    }
    const styles = ProductInCartStyles;

    let variantLabel = variant.display_name;
    if (
      AppConfig.getBooleanValue(Config.SHOW_PRODUCT_VARIANT_LABEL_ON_CART_ITEM)
    ) {
      variantLabel = `${variants_property_name ?? 'Size'}: ` + variantLabel;
    }
    return (
      <View style={styles.variant}>
        <this.variantImage variantImage={variant.image_url} />
        <Text
          allowFontScaling={false}
          style={styles.productQuantandVariantText}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {variantLabel}
        </Text>
      </View>
    );
  };

  productVariantOrders = () => {
    const {
      cartItem: { variant = [], shipQuantity, quantity, fromOrderDetail },
    } = this.props;
    const qty = fromOrderDetail ? shipQuantity : quantity;
    const styles = ProductInCartStyles;
    if (Utility.isBlank(variant.display_name)) {
      return (
        <View style={styles.variant}>
          <this.variantImage variantImage={variant.image_url} />
          <Text
            allowFontScaling={false}
            style={[
              styles.productQuantandVariantText,
              { color: colors.subtitle },
            ]}
          >
            Qty: {qty}
          </Text>
        </View>
      );
    }

    return (
      <View style={styles.variant}>
        <this.variantImage variantImage={variant.image_url} />
        <Text
          allowFontScaling={false}
          style={[
            styles.productQuantandVariantText,
            { color: colors.subtitle },
          ]}
        >
          {variant.display_name} x{qty}
        </Text>
      </View>
    );
  };

  orderStatusRow = (props) => {
    const { item } = props;

    let isDeliveryDateVisible =
      Utility.isBlank(item.formatted_expected_delivery_date) ||
      item.status !== 'cancelled';

    let deliveryDate = '';
    if (item.status === 'delivered' || item.status === 'closed') {
      deliveryDate = `Delivered on: ${item.formatted_actual_delivery_date}`;
    } else if (
      item.status === 'cancelled' ||
      Utility.isBlank(item.formatted_expected_delivery_date)
    ) {
      deliveryDate = '';
    } else {
      deliveryDate = `Expected Delivery By: ${item.formatted_expected_delivery_date}`;
    }
    return (
      <View style={ProductCardStyles.row}>
        <Text style={[ProductCardStyles.orderStatus, { color: colors.green }]}>
          {Utility.getOrderStatusText(Utility.getShipmentStatus(item.status))}
        </Text>

        {isDeliveryDateVisible && (
          <Text
            style={[ProductCardStyles.orderStatus, { color: colors.silver }]}
          >
            {deliveryDate}
          </Text>
        )}
      </View>
    );
  };

  decideNavigation = () => {
    const {
      addToCart,
      fromOrderDetail,
      cartItem,
      cartProduct: { type = '', slug = '', sku_id = '' } = {},
      navigation,
    } = this.props;
    console.log(type, 'ssss');
    if (type === 'routine') {
      navigation.navigate('PreRoutineDetails', {
        slug: slug,
        sku_id: sku_id,
      });
      return;
    }

    if (addToCart) {
      this.debouncedNavigate();
    } else if (cartItem.fromOrderDetail) {
      this.navigatefromOrders();
    } else if (!fromOrderDetail) {
      this.navigateToOrderDetail();
    }
  };

  oosProductMessage = () => {
    return (
      <View
        style={{
          position: 'absolute',
          bottom: '40%',
          left: 12,
          backgroundColor: colors.white,
          padding: 4,
          borderRadius: 2,
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: 12,

            fontStyle: 'normal',
            letterSpacing: 0,
            color: colors.foxyPink,
          }}
        >
          Out of stock
        </Text>
      </View>
    );
  };

  viewFreeProductScreen = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent('change_free_product', {
      screen: 'cart',
    });
    navigation.push('FreeProductScreen');
  };

  freeOfferProduct = () => {
    const { layout, size, cartItem, skuId, addToCart, error, userCreatedAt } =
      this.props;

    if (Utility.isBlank(userCreatedAt)) {
      return null;
    }

    if (Utility.isBlank(cartItem) && Utility.isBlank(cartItem.offer_id)) {
      return null;
    }

    if (Utility.isBlank(skuId) && addToCart) {
      if (Utility.isBlank(cartItem)) {
        return null;
      }
    }
    if (Utility.isBlank(cartItem.product)) {
      // TODO: show shimmer
    }
    let { sp } = cartItem.product;
    let { mrp } = cartItem.product;
    let { discount } = cartItem.product;
    if (!Utility.isBlank(cartItem.variant)) {
      sp = cartItem.variant.sp;
      mrp = cartItem.variant.mrp;
      discount = cartItem.variant.discount;
    }
    let amountLeftToBeEligible =
      cartItem.amount_left_to_be_eligible < 0
        ? 0
        : cartItem.amount_left_to_be_eligible;
    let amountLeftPercent = ((500 - amountLeftToBeEligible) / 500) * 100;

    let offerText = !cartItem.eligible_for_free_item
      ? `Add product worth ${getPriceText(
          cartItem.amount_left_to_be_eligible,
        )} to unlock this`
      : 'Hurry! your FREE gift expires in';

    let opacity = 1;
    let cardColor = colors.white;
    if (Utility.isPresent(error)) {
      opacity = 0.6;
    }
    if (!cartItem.eligible_for_free_item) {
      opacity = 0.3;
      cardColor = '#F9FAFB';
    } else {
      amountLeftPercent = 100;
    }

    const {
      product: { order_campaign: orderCampaignProduct = {} } = {},
      variant: { order_campaign: orderCampaignVariant = {} } = {},
    } = cartItem;
    const orderCampaign = Utility.isPresent(orderCampaignProduct)
      ? orderCampaignProduct
      : orderCampaignVariant;

    return (
      <>
        <View
          style={{
            backgroundColor: cardColor,
          }}
        >
          <View
            style={{
              height: 17,
              alignItems: 'center',
              alignSelf: 'flex-start',
              marginHorizontal: 20,
              marginTop: 8,
            }}
          >
            <View
              style={{
                backgroundColor: '#F6E1E3',
                paddingLeft: 8,
                paddingRight: 8,
                borderWidth: 1,

                borderColor: '#FF6268',
                borderStyle: 'dashed',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 1,
                flex: 1,
                opacity: 0.5,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: 10,
                  fontStyle: 'normal',
                  letterSpacing: 0,
                  color: colors.ultraDarkBlue,
                  zIndex: 5,
                }}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {cartItem.coupon_code}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 2,
              backgroundColor: cardColor,
            }}
          >
            {addToCart && (
              <this.productImageAndRating
                imageUrl={cartItem.product.image_url}
                webpImageUrl={cartItem.product.image_webp_url}
                layout={layout}
                size={size}
                id={cartItem.product.id}
                isFree={cartItem.is_free}
                opacity={opacity}
              />
            )}

            <this.productDescription
              mrp={mrp}
              name={cartItem.product.name}
              sp={sp}
              discount={discount}
              brandName={cartItem.product.brand && cartItem.product.brand.name}
              brand={cartItem.product.brand}
              orderStatus={cartItem.product.orderStatus}
              expectedDeliveryDate={cartItem.product.expectedDeliveryDate}
              isFree={cartItem.is_free}
              opacity={opacity}
              discount={cartItem.product.discount}
              couponDiscount={cartItem.discount}
              orderCampaign={orderCampaign}
            />

            <TouchableWithoutFeedback
              onPress={this.viewFreeProductScreen}
              hitSlop={Utility.getHitSlop()}
              style={{
                zIndex: 10,
              }}
            >
              <View
                style={{
                  position: 'absolute',
                  flexDirection: 'row',
                  right: 16,
                  bottom: 35,
                  alignItems: 'center',
                  alignSelf: 'center',
                  borderWidth: 1,
                  borderRadius: 3,
                  borderColor: '#E4E4E4',
                  padding: 0,
                  width: 80,
                  height: 35,
                  justifyContent: 'center',
                  zIndex: 5,
                }}
              >
                <Text
                  style={{
                    color: '#FF5A60',
                    fontSize: 12,
                    fontFamily: 'Roboto-Regular',
                    textAlign: 'center',
                    zIndex: 5,
                  }}
                >
                  CHANGE
                </Text>
              </View>
            </TouchableWithoutFeedback>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 10,
              marginHorizontal: 12,
            }}
          >
            <Text
              style={{
                color: '#173143',
                fontSize: 12,
                fontFamily: 'Roboto-Regular',
                alignSelf: 'center',
              }}
            >
              {offerText}
            </Text>
            <CountDown
              size={10}
              until={Utility.calculateTimeDifference(
                userCreatedAt,
                cartItem.timer_interval,
              )}
              endsAt={cartItem.timer_interval}
              // onFinish={() => alert('Finished')}
              digitStyle={{
                backgroundColor: '#000',
                marginLeft: 0,
              }}
              digitTxtStyle={{
                color: '#fff',
                fontSize: 14,
                fontFamily: 'Roboto-Regular',
              }}
              timeLabelStyle={{ color: 'red', fontWeight: 'bold' }}
              separatorStyle={{ color: '#000000' }}
              timeToShow={['H', 'M', 'S']}
              timeLabels={{ m: null, s: null }}
              showSeparator
            />
          </View>
        </View>
      </>
    );
  };

  render() {
    const { orientation, theme = THEME.LIGHT, previousScreen } = this.props;

    const {
      layout,
      size,
      cartItem,
      skuId,
      quantity,
      addedToCart,
      addToCart,
      fromOrderDetail,
      showOrderStatus,
      error,
      refreshOfferStrip,
      maxFreeItemsToSelect,
      showToast,
      refreshOffersDetailsPageDiscountStrip,
      campaignId,
      onItemAddToCartFromCollab,
      couponCodes,
      hideAddToCart = false,
      isDigitalSku,
      todayDealss: { ends_at, skus = [] } = {},
      cartProduct: { type = '' } = {},
      listData = {},
    } = this.props;
    const { participating_discount_offer_type = '', bgColor = '' } = cartItem;

    const offerActive = RemoteConfig.getValue(REMOTE_CONFIG_KEYS.active_offer);

    if (Utility.isBlank(skuId) && addToCart) {
      if (Utility.isBlank(cartItem)) {
        return null;
      }
    }
    if (Utility.isBlank(cartItem.product)) {
      // TODO: show shimmer
    }
    const { showAlert, alertText, showFlashDealTimer } = this.state;
    const styles = ProductInCartStyles;
    let { final_sp: sp } = cartItem?.product || {};
    let { mrp } = cartItem?.product || {};
    let { discount } = cartItem?.product || {};
    let color = colors.white;
    if (!Utility.isBlank(cartItem.variant)) {
      sp = cartItem.variant.final_sp;
      mrp = cartItem.variant.mrp;
      discount = cartItem.variant.discount;
    }

    let opacity = 1;
    let isComboColorPresent = false;
    const offerID =
      cartItem.participating_discount_offer_id || cartItem.offer_id;
    if (Utility.isPresent(error)) {
      opacity = 0.4;
      color = '#fafafa';
    } else if (
      Utility.isPresent(offerID) &&
      Utility.isPresent(Utility.usedComboColors[offerID])
    ) {
      color = Utility.usedComboColors[offerID];
      isComboColorPresent = true;
    }

    mrp = cartItem?.final_mrp;
    sp = cartItem?.final_sp;
    discount = cartItem?.final_discount;

    if (cartItem.offer_id == offerActive) {
      return <this.freeOfferProduct />;
    }

    const {
      product: { order_campaign: orderCampaignProduct = {} } = {},
      variant: { order_campaign: orderCampaignVariant = {} } = {},
    } = cartItem;
    const orderCampaign = Utility.isPresent(orderCampaignProduct)
      ? orderCampaignProduct
      : orderCampaignVariant;

    const disableItemPress =
      (hideAddToCart || isDigitalSku) && type !== 'routine';

    const {
      flash_deal_v2: {
        image: flashDealImage = '',
        label_name: flashDealLabelName = '',
      } = {},
    } = this.productDealsTagData;

    const cardStyle = !isComboColorPresent
      ? styles.cardStyleForCartTransparent
      : _.memoize(
          () => ([
            styles.cardStyleForCart,
            { backgroundColor: Utility.addAlpha(color, 0.12) },
          ]),
          () => [color],
        )();

    return (
      <>
        <TouchableOpacity
          onPress={this.decideNavigation}
          disabled={disableItemPress}
        >
          <View style={cardStyle}>
            {addToCart && (
              <this.productImageAndRating
                imageUrl={
                  isDigitalSku
                    ? cartItem?.product?.card_image
                    : cartItem?.product?.image_url
                }
                webpImageUrl={cartItem?.product?.image_webp_url}
                variantImageUrl={
                  Utility.isPresent(cartItem.variant) &&
                  Utility.isPresent(cartItem.variant.images)
                    ? cartItem.variant.images[0]
                    : ''
                }
                variantWebpImageUrl={
                  Utility.isPresent(cartItem.variant) &&
                  Utility.isPresent(cartItem.variant.webp_images)
                    ? cartItem.variant.webp_images[0]
                    : ''
                }
                layout={layout}
                size={size}
                id={cartItem?.product?.id}
                isFree={cartItem.is_free}
                opacity={opacity}
              />
            )}
            {!addToCart && (
              <this.productImage
                imageUrl={cartItem.product.image_url}
                layout={layout}
                size={size}
                id={cartItem.product.id}
                fromOrderDetail={cartItem.fromOrderDetail}
                inOrderDetail={cartItem.inOrderDetail}
              />
            )}

            {addToCart && (
              <this.productDescription
                mrp={mrp}
                name={cartItem?.product?.name}
                sp={sp}
                discount={discount}
                brandName={
                  cartItem?.product?.brand && cartItem?.product?.brand?.name
                }
                brand={cartItem?.product?.brand}
                orderStatus={cartItem?.product?.orderStatus}
                expectedDeliveryDate={cartItem?.product?.expectedDeliveryDate}
                isFree={cartItem.is_free}
                opacity={opacity}
                couponDiscount={discount}
                participating_discount_offer_id={
                  cartItem?.participating_discount_offer_id
                }
                orderCampaign={orderCampaign}
              />
            )}
            {addToCart && !hideAddToCart && (
              <AddToCart
                skuId={skuId}
                quantity={quantity}
                orientation={orientation}
                layout={layout}
                theme={theme}
                addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.CART}
                cartItem={cartItem.product}
                variant={cartItem.variant}
                error={error}
                errorCallback={this.addToCartError}
                refreshOfferStrip={refreshOfferStrip}
                previousScreen={previousScreen}
                maxFreeItemsToSelect={maxFreeItemsToSelect}
                showToast={showToast}
                refreshOffersDetailsPageDiscountStrip={
                  refreshOffersDetailsPageDiscountStrip
                }
                campaignId={campaignId}
                onItemAddToCartFromCollab={onItemAddToCartFromCollab}
                isFree={!!cartItem.is_free}
                disableQuantityChange={cartItem.disable_quantity_change}
                isFreeItem={!!cartItem.is_free_item}
                isFreeProduct={!!cartItem.is_free_product}
                setPromptLoading={this.props.setPromptLoading}
                isPrompStopLoading={this.props.isPrompStopLoading}
                type={type}
                listData={listData}
              />
            )}
            <FoxyAlert
              isVisible={showAlert}
              alertBoxTitle="Cancel this product?"
              alertMessage={alertText}
              firstButtonTitle="Yes"
              firstButtonTextColor={colors.dodgerBluePressed}
              secondButtonTitle="No"
              firstButtonOnPress={() => this.cancelOrderItem(cartItem.id)}
              secondButtonOnPress={this.cancelAlert}
              showWhatsappHyperlink
              whatsappHyperlinkMessage={this.state.alertWhatsAppMessage}
              height={250}
            />
          </View>

          {/* FIXME: This is not a proper way to do it. You should either
                    ask for this from service or it should be a part of component's property.
                    @Aman needs to fix it.
           */}
          {this.state.showFlashDealTimer &&
            Utility.isPresent(skus[`${skuId}`]) &&
            (participating_discount_offer_type === 'flash_deal' ||
              participating_discount_offer_type === 'club_deal') && (
              <>
                <FastImageView
                  source={flashDealImage}
                  style={styles.flashDealImage}
                  resizeMode="contain"
                />
                <View style={styles.flashDealContainer}>
                  <Text style={[styles.flashDealText,{ marginTop: isDesktop() ? 2 : 0}]}>{flashDealLabelName}</Text>
                  <FullWidthShimmer style={styles.shimmer} speed={2000} />
                </View>
              </>
            )}
          {showOrderStatus && <this.orderStatusRow item={cartItem} />}
          {Utility.isPresent(error) && <this.oosProductMessage />}
        </TouchableOpacity>
        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </>
    );
  }
}

ProductCardForCart.defaultProps = {
  addToCart: true,
  fromOrderDetail: false,
  inOrderDetail: false,
};

const mapStateToProps = (store, ownProps) => {
  const { id } = ownProps;
  return {
    addedToCart: !!(store.bag && store.bag.cartItems[id]),
    configs: store.UserAccountInfo.remote_configs,
    userCreatedAt: store.UserAccountInfo.app_installed_at,
    todayDealss: store.todayDeals,
    couponCodes:
      store.bag.cartPricing && store.bag.cartPricing.coupon_codes
        ? store.bag.cartPricing.coupon_codes
        : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { removeFromCart, cancelOrderItemById, isSpecialOfferApplied },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ProductCardForCart,
  ),
);
