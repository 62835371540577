import { crashlytics } from '../../../libraries/Firebase';

import AnalyticsEvent from './AnalyticsEvent';
import FirebaseAnalytics from './FirebaseAnalytics';
import MoengageAnalytics from './MoengageAnalytics';
import FacebookAnalytics from './FacebookAnalytics';
// import UXCamAnalytics from './UXCamAnalytics';
import Utility from '../../../utils/Utility';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';
import WebengageAnalytics from '../../../analytics/WebengageAnalytics';

class AnalyticsManager {
  static networkInfo = {};

  static eventTrackingAgents = [
    FirebaseAnalytics,
    MoengageAnalytics,
    FacebookAnalytics,
    // UXCamAnalytics,
  ];

  static trackEvent = (event) => {
    // console.log('Event', event);
    if (event instanceof AnalyticsEvent) {
      AnalyticsManager.eventTrackingAgents.forEach((trackingAgent) => {
        trackingAgent.trackEvent(event);
      });
    }
  };

  static logEvent = (eventName, eventParams = {}) => {
    AnalyticsManager.trackEvent(
      new AnalyticsEvent({
        eventType: eventName,
        meta: eventParams,
      }),
    );
  };

  static logFirebaseEvent = (eventName, eventParams = {}) => {
    FirebaseAnalytics.trackEvent(
      new AnalyticsEvent({
        eventType: eventName,
        meta: eventParams,
      }),
    );
  };

  static logFBStandardEvent = (eventName, valueToSum, parameters = {}) => {
    FacebookAnalytics.logStandardEvent(eventName, valueToSum, parameters);
  };

  static logFBPurchaseEvent = (purchaseAmount, currencyCode, parameters) => {
    FacebookAnalytics.logPurchaseEvent(
      purchaseAmount,
      currencyCode,
      parameters,
    );
  };

  static setCurrentScreen = (screenName) => {
    if (
      Utility.isPresent(screenName) &&
      screenName !== SCREEN_CONSTANTS.MORE_PAGE
    ) {
      FirebaseAnalytics.currentScreen(screenName);
      // UXCamAnalytics.currentScreen(screenName);
    }
  };

  static setUserProperty = (key, value) => {
    FirebaseAnalytics.setUserProperty(key, value);
    MoengageAnalytics.setUserProperty(key, value);
    // UXCamAnalytics.setUserProperty(key, value);
  };

  static setUserId = (id) => {
    FirebaseAnalytics.setUserId(id);
    MoengageAnalytics.setUserId(id);
    // UXCamAnalytics.setUserId(id);
    crashlytics().setUserId(id);
  };

  static setUserAlias = (id) => {
    MoengageAnalytics.setUserAlias(id);
  };

  static setGeneralUserAttributes = (object) => {
    MoengageAnalytics.setGeneralUserAttributes(object);
    WebengageAnalytics.setGeneralUserAttributes(object);
  };

  static setNetworkStateMeta = (networkInfo) => {
    AnalyticsManager.networkInfo = networkInfo;
  };

  static logPurchaseEvent = (purchaseAmount, currencyCode, parameters) => {
    FacebookAnalytics.logPurchaseEvent(
      purchaseAmount,
      currencyCode,
      parameters,
    );
  };
}

export default AnalyticsManager;
