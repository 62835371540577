import { takeEvery, call, put } from 'redux-saga/effects';
import {
  VERIFY_HANDLE,
  verifyHandleSuccess,
  verifyHandleFailure,
} from '../actions/LoginActions';
import Utility from '../utils/Utility';
import { URL } from '../config/Constants';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

const checkHandle = (handle, headers) => {
  const response = fetch(URL.VERIFY_HANDLE + handle, {
    method: 'GET',
    headers: headers,
  });
  return response;
};

function* verifyHandle(action) {
  try {
    const handle = yield action.handle;
    let headers = yield getApiHeaders();
    const response = yield call(checkHandle, handle, headers);
    if (response.status === 200) {
      yield put(verifyHandleSuccess());
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put(verifyHandleFailure(json.errors[0].message));
      }
    }
  } catch (error) {
    console.log(`Error in verifying handle ${error}`);
  }
}

export default function* watchVerifyHandleSaga() {
  yield takeEvery(VERIFY_HANDLE, verifyHandle);
}
