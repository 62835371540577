import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Linking,
} from 'react-native';
import React from 'react';
import StyleSheet from 'react-native-media-query';
import { useNavigation } from '@react-navigation/native';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import FullWidthDivider from '../../utils/FullWidthDivider';
import { PopUpStyles } from '../Product/styles';
import { isDesktop } from '../../utils/BooleanUtility';
import DownloadAppButtons from '../webFooter/DownloadAppButtons';
import { APP_DOWNLOAD_LINK } from '../../config/Constants';
import { setCookie } from '../../utils/webUtility';

export default function AppInstallPrompt() {
  const navigation = useNavigation();
  const closePrompt = () => {
    setCookie('app_installed_prompt_closed', true, 7);
    navigation.pop();
  };

  return (
    <>
      <TouchableOpacity onPress={closePrompt} style={styles.touchableContainer} />
      <View style={styles.modalContainer} pointerEvents="box-none">
        <View style={styles.bottomModal} dataSet={{ media: ids.bottomModal }}>
          <TouchableOpacity
            style={PopUpStyles.cancelButton}
            onPress={closePrompt}
          >
            <Image source={images.cross} style={PopUpStyles.cancelStyle} />
          </TouchableOpacity>
          <Image
            source={{ uri: images.appInstallPrompt }}
            style={styles.image}
            dataSet={{ media: ids.image }}
          />
          {isDesktop() && <DesktopButtons closePrompt={closePrompt} />}
          {!isDesktop() && <FullWidthDivider />}
          {!isDesktop() && <MobileButtons closePrompt={closePrompt} />}
        </View>
      </View>
    </>
  );
}

const MobileButtons = ({ closePrompt }) => {
  const handlePress = () => {
    closePrompt();
    Linking.openURL(APP_DOWNLOAD_LINK);
  };

  return (
    <View style={styles.mobileButtonsContainer}>
      <View style={styles.logoAndAppNameContainer}>
        <View style={styles.logoContainer}>
          <Image source={images.webHeaderLogo} style={styles.logo} />
        </View>
        <Text style={styles.logoCaption}>FOXY</Text>
      </View>
      <TouchableOpacity onPress={handlePress}>
        <Text style={styles.appOpenButton}>Open App</Text>
      </TouchableOpacity>
    </View>
  );
};

const DesktopButtons = ({ closePrompt }) => (
  <View style={styles.desktopButtonsContainer}>
    <DownloadAppButtons containerStyle={styles.downloadAppButtons} onPress={closePrompt} />
  </View>
);

const { ids, styles } = StyleSheet.create({
  container: { backgroundColor: 'white', flex: 1 },
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.translucent,
  },
  bottomModal: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 1,
    padding: 20,
    paddingBottom: 24,
    '@media (min-width: 800px)': {
      top: '50%',
      left: '50%',
      bottom: 'auto',
      right: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: 16,
    },
  },
  image: {
    width: getScreenWidth() - 40,
    height: 110,
    marginVertical: 10,
    '@media (min-width: 800px)': {
      width: 535,
      height: 178,
    },
  },
  logo: {
    width: 22,
    height: 22,
  },
  logoContainer: {
    height: 40,
    width: 40,
    boxShadow: '1px 2px 4px 0px #00000040',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoCaption: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    marginLeft: 12,
    fontSize: 16,
  },
  mobileButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 12,
  },
  logoAndAppNameContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  appOpenButton: {
    backgroundColor: colors.black,
    height: 40,
    width: 140,
    color: colors.white,
    fontSize: 14,
    fontWeight: '700',
    borderRadius: 20,
    lineHeight: 40,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular',
  },
  desktopButtonsContainer: {
    marginTop: 24,
    width: '70%',
  },
  downloadAppButtons: {
    justifyContent: 'space-between',
  },
});
