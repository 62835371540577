import React, { PureComponent } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { debounce } from 'lodash';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ScaleAnimate } from '../shared';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { LAYOUT, MORE_ITEMS, SIZE } from '../../config/Constants';
import colors from '../../theme/Colors';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { addMyBrowsing } from '../../actions/UserInteractionsActions';
import {
  applyFlashDeal,
  setSearchResultClicks,
} from '../../actions/ActionTypes';
import {
  getCardContainerStyle,
  DYNAMIC_CARD_DEFAULT_STYLES,
} from '../../utils/DynamicProductCardUtility';
import images from '../../theme/Images';
import AnimatedLikeButton from '../shared/AnimatedLikeButton';
import { isPresent } from '../../utils/BooleanUtility';
import { ProductDescription } from '../Product';
import ProductImageAndRating from '../Product/ProductImageAndRating';
import SkuSizeVariant from './SkuSizeVariant';
import SkuColorVariant from './SkuColorVariant';
import FastImageView from '../FastImageView';
import AddToCart from '../Product/AddToCart';
import { navigateToScreen } from '../../utils/NavigationUtility';

class DynamicVariantRail extends PureComponent {
  static getComponentHeight(item) {
    const { options: listOptions = {} } = item || {};
    const { height = 0 } =
      getCardContainerStyle(listOptions, LAYOUT.RAIL) || {};
    return height;
  }

  constructor(props) {
    super(props);
    this.debounceOnPress = debounce(this.onPress, 500, {
      leading: true,
      trailing: false,
    });
    this.likeButtonRef = React.createRef();

    const {
      listData: {
        options = {},
        background_color: listBackgroundColor = '',
      } = {},
      previousScreen = '',
      itemData: {
        transparent_image_url = '',
        transparent_image_vibrant_colors: {
          vibrant_color: backgroundColor = colors.white,
        } = {},
      } = {},
    } = this.props;

    this.cardContainerStyle = getCardContainerStyle(options, LAYOUT.RAIL);

    const {
      image_aspect_ratio:
        imageAspectRatio = Config.DYNAMIC_PRODUCT_CARD_IMAGE_ASPECT_RATIO ||
          '1:1',
      show_borders_for_list_items = '0',
      disable_card_background_color = '1',
    } = options ?? {};

    if (
      isPresent(transparent_image_url) ||
      show_borders_for_list_items === '1' ||
      listBackgroundColor === '#ffffff'
    ) {
      this.cardContainerStyle = {
        ...this.cardContainerStyle,
        borderColor: colors.border,
        borderWidth: 1,
      };
    }

    const imageType = imageAspectRatio === '1:1' ? 'Square' : 'Vertical';
    this.imageContainer =
      DYNAMIC_CARD_DEFAULT_STYLES[`${LAYOUT.RAIL}${imageType}ImageStyle`];

    this.viewMoreIconContainer = {
      ...this.cardContainerStyle,
      ...styles.viewMoreContainer,
    };

    this.disabled =
      previousScreen === 'brandCollab' || previousScreen === 'free_items';

    const finalBackgroundColor =
      disable_card_background_color === '1' ||
      Utility.isBlank(transparent_image_url)
        ? colors.white
        : backgroundColor || colors.white;

    this.gradientArray = [
      Utility.addAlpha(finalBackgroundColor, 0.15),
      Utility.addAlpha(finalBackgroundColor, 0.25),
    ];

    this.transparentImageUrl =
      disable_card_background_color !== '1' ? transparent_image_url : '';

    this.x = { x: 0, y: 0 };
    this.y = { x: 0, y: 1 };
  }

  showCart = () => {
    const { toggleCartVisibility } = this.props;
    // TODO: This prevents a crash from the search results page but doesn't allow the user to navigate to the cart page
    if (toggleCartVisibility !== undefined) {
      toggleCartVisibility();
    }
  };

  navigate = () => {
    const {
      navigation,
      itemData: { id, product_slug: slug, sku_id } = {},
      toggleCartVisibility,
      previousScreen = '',
      applyFlashDeal,
    } = this.props;
    applyFlashDeal({
      type: 'Variant',
      id,
    });
    navigation.push(SCREEN_CONSTANTS.PRODUCT_PAGE, {
      slug: `/api/v2/products/${slug}.json`,
      display: LAYOUT.SCREEN,
      toggleCartVisibility,
      sku_id,
      previousScreen,
    });
  };

  onPress = async () => {
    const {
      onPress = () => {},
      listIndex,
      index,
      searchQuery,
      elementItemCounts,
      itemData,
      listId,
      listData = {},
      layout,
      previousScreen,
      listName,
      listContent,
      extraEventParameters = {},
      navigation,
      toggleUnavailableProductsModal,
      parentListsData = [],
      itemData: {
        id: itemId = '',
        slug: itemSlug = '',
        type: itemType = '',
        name: itemName = '',
        objects: itemObjects = [],
      } = {},
      listData: { slug: listSlug = '', display_count = '' } = {},
    } = this.props;

    if (index === display_count - 1) {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: MORE_ITEMS,
        params: {
          displayCount: itemObjects.length,
          heading: itemName,
          navigation,
          title: itemName,
          slug: listSlug,
        },
      });
      return;
    }

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );

      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.PRODUCT_ID]: itemData.id,
        [EventParameterKey.PRODUCT_STOCKED_STATUS]: itemData.stocked_status,
        [EventParameterKey.ITEM_POSITION]: clickedIndex || 'NAN', //TODO: this was crashing need to check
      };
      this.fireSearchResultClickEvent(meta);
    }

    if (toggleUnavailableProductsModal) {
      await toggleUnavailableProductsModal();
    }

    const extraParams = {
      ...extraEventParameters,
      ...AnalyticsUtility.getParentsList(parentListsData),
    };
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      Utility.getSkuId(itemData),
      itemType,
      itemName,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      !itemData.outOfStock,
      listContent,
      itemId,
      itemSlug,
      listSlug,
      extraParams,
    );

    this.navigate();
    if (onPress) {
      onPress();
    }
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  addToCart = (props) => {
    const {
      skuId,
      orientation,
      hideAddToCart,
      id,
      itemData,
      hasVariants,
      priority,
      singleStockedVariantSku,
      campaignId,
      onItemAddToCartFromCollab,
      prompts,
    } = props;
    const {
      previousScreen,
      maxFreeItemsToSelect,
      showToast,
      refreshOfferStrip,
      refreshOffersDetailsPageDiscountStrip,
      listId,
      listName,
      listIndex,
      listContent,
      index,
      listData,
      listData: { additional_data = {} } = {},
      extraEventParameters = {},
    } = this.props;

    let { outOfStock = false } = props;
    if (outOfStock === null) {
      outOfStock = true;
    }

    return (
      <AddToCart
        skuId={skuId}
        orientation={orientation}
        toggleCartState={this.toggleCartState}
        layout={LAYOUT.GRID}
        addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
        hideAddToCart={hideAddToCart}
        id={id}
        itemData={itemData}
        hasVariants={hasVariants}
        showCart={this.showCart}
        priority={priority}
        outOfStock={outOfStock}
        refreshOfferStrip={refreshOfferStrip}
        refreshOffersDetailsPageDiscountStrip={
          refreshOffersDetailsPageDiscountStrip
        }
        previousScreen={previousScreen}
        maxFreeItemsToSelect={maxFreeItemsToSelect}
        showToast={showToast}
        campaignId={campaignId}
        onItemAddToCartFromCollab={onItemAddToCartFromCollab}
        listId={listId}
        listName={listName}
        listIndex={listIndex}
        listContent={listContent}
        index={index}
        singleStockedVariantSku={singleStockedVariantSku}
        prompts={prompts}
        additional_data={additional_data}
        listData={listData}
        extraEventParameters={extraEventParameters}
        cta_position_bottom={'1'}
      />
    );
  };

  skuSizeCondition = ({ variantName }) => isPresent(variantName);

  conditionalVariants = withEither(
    this.skuSizeCondition,
    SkuSizeVariant,
  )(SkuColorVariant);

  skuVariants = (props) => {
    const { variantName, imageUrl, packSize, containerStyle } = props;
    return (
      <this.conditionalVariants
        variantShade={variantName}
        variantImage={imageUrl}
        packSize={packSize}
        containerStyle={containerStyle}
      />
    );
  };

  likeButton = () => {
    const {
      itemData = {},
      itemData: {
        slug = '',
        content = '',
        id = '',
        sku_id: skuId = '',
        type = '',
        mrp = '',
        name = '',
      } = {},
      listData: { options = {} } = {},
    } = this.props;
    const { show_wishlist_icon: showWishlistIcon = '1' } = options ?? {};
    if (showWishlistIcon !== '1') {
      return null;
    }
    return (
      <View style={styles.likeButtonContainer}>
        <AnimatedLikeButton
          ref={this.likeButtonRef}
          id={id}
          likedItemSkuId={skuId}
          type={type}
          slug={slug}
          mrp={mrp}
          skuId={Utility.getSkuId(itemData)}
          layout={LAYOUT.DYNAMIC_CARD}
          content={content}
          onLike={this.onLike}
          itemName={name}
          addToCartLikeButton
          itemData={itemData}
        />
      </View>
    );
  };

  viewMore = () => {
    const {
      index,
      listData: {
        display_count = '',
        objects = [],
        paginate_last_item = '',
      } = {},
    } = this.props;
    if (
      index !== display_count - 1 ||
      (display_count === objects.length && !paginate_last_item)
    ) {
      return null;
    }
    return (
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={this.debounceOnPress}
        style={this.viewMoreIconContainer}
      >
        <FastImageView
          source={images.view_more_icon}
          style={styles.viewMoreIcon}
          resizeMode='contain'
        />
        <Text style={styles.viewMoreText}>View More</Text>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      orientation,
      previousScreen,
      itemData,
      hideAddToCart,
      hideActivityIndicator,
      itemData: {
        offer_value,
        final_sp: display_price,
        brand_name: variantBrandName,
        images,
        webp_images,
        image_webp_url,
        rating,
        id,
        name = '',
        mrp,
        sp,
        discount,
        brand: { name: brandName } = {},
        sku_id: skuId,
        prompts,
        has_variants: hasVariants,
        outOfStock,
        priority,
        image_url: imageUrl,
        single_stocked_variant: singleStockedVariantSku,
        hero_description: heroDescription = '',
        pack_size,
        display_name,
        image_url,
      } = {},
      listData: { options = {} } = {},
      showEditButton,
    } = this.props;
    const {
      ratings_position:
        ratingsPosition = Config.DYNAMIC_PRODUCT_CARD_RATINGS_POSITION ||
          'hide',
      hide_brand_name:
        hideBrandName = Config.DYNAMIC_PRODUCT_CARD_HIDE_BRAND_NAME || '1',
      title_line_count:
        titleLineCount = Config.DYNAMIC_PRODUCT_CARD_TITLE_LINE_COUNT || '1',
      subtitle_line_count:
        subtitleLineCount = Config.DYNAMIC_PRODUCT_CARD_SUBTITLE_LINE_COUNT ||
          '0',
      show_wishlist_icon:
        showWishlistIcon = Config.DYNAMIC_PRODUCT_CARD_SHOW_WISHLIST_ICON ||
          '1',
    } = options ?? {};
    return (
      <View>
        <ScaleAnimate
          {...this.props}
          disabled={this.disabled}
          onPress={this.onPress}
        >
          <Card style={this.cardContainerStyle}>
            <View style={this.imageContainer}>
              <ProductImageAndRating
                layout={LAYOUT.GRID}
                rating={rating}
                ratingsPosition={ratingsPosition}
                images={images}
                itemData={itemData}
                webp_images={webp_images}
                image_webp_url={image_webp_url}
                imageUrl={imageUrl}
                id={id}
                name={name ?? display_name}
                previousScreen={previousScreen}
                hideActivityIndicator={hideActivityIndicator}
                isVariant
                showEditButton={showEditButton}
                showWishlistIcon={showWishlistIcon}
                transparentImageUrl={this.transparentImageUrl}
              />
              <LinearGradient
                colors={this.gradientArray}
                style={styles.gradient}
                start={this.x}
                end={this.y}
              />
            </View>
            <View style={styles.fullCardDivider} />
            <View style={styles.productDescriptionContainer}>
              <ProductDescription
                itemData={itemData}
                name={name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={brandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.DYNAMIC_CARD}
                id={id}
                previousScreen={previousScreen}
                prompts={prompts}
                hideActivityIndicator={hideActivityIndicator}
                rating={rating}
                ratingsPosition={ratingsPosition}
                hideBrandName={hideBrandName === '1'}
                titleLineCount={parseInt(titleLineCount)}
                subtitleLineCount={parseInt(subtitleLineCount)}
                heroDescription={heroDescription}
                size={SIZE.DYNAMIC}
                leftAlignText
              />
            </View>
            <this.addToCart
              skuId={skuId}
              orientation={orientation}
              toggleCartState={this.toggleCartState}
              layout={LAYOUT.GRID}
              addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
              hideAddToCart={hideAddToCart}
              id={id}
              itemData={itemData}
              hasVariants={hasVariants}
              showCart={this.showCart}
              priority={priority}
              outOfStock={outOfStock}
              previousScreen={previousScreen}
              singleStockedVariantSku={singleStockedVariantSku}
              prompts={prompts}
              hideActivityIndicator={hideActivityIndicator}
            />
          </Card>
          <this.skuVariants
            variantName={display_name}
            imageUrl={image_url}
            packSize={pack_size}
            layout={LAYOUT.GRID}
            containerStyle={styles.variantContainerStyle}
          />
        </ScaleAnimate>
        <this.likeButton />
        <this.viewMore />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: colors.white,
  },
  likeButtonContainer: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  productDescriptionContainer: {
    flex: 1,
  },
});

const mapStateToProps = (state) => ({
  myBrowsing: state.userInteractions.myBrowsing,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addMyBrowsing,
      applyFlashDeal,
      setSearchResultClicks,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(DynamicVariantRail),
);
