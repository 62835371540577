import React, { Component } from 'react';
import {
  View,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  Text,
  Platform,
  ScrollView,
  BackHandler,
  Image,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import colors from '../../theme/Colors';
import {
  getPopularMedia,
  getLatestMedia,
  getVideosInReview,
  getArtistGift,
} from '../../actions/ActionTypes';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ARTIST_CONSTANTS } from '../../config/Constants';
import { StaticNavigationHeader } from '../../components/shared';
import { UserPostCard, Drafts } from '../../components/profile';
import GridStyles from '../../components/layout/Grid/styles';
import { withEither } from '../../lib/Monads';
import styles from './styles';
import { Rail } from '../../components/layout';
import images from '../../theme/Images';
import { AnalyticsManager, EventParameterValue } from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

class MyUploads extends Component {
  constructor(props) {
    super(props);
    this.filterOptions = [{ value: 'Latest' }, { value: 'Popular' }];
    this.state = {
      currentPage: 0,
      noMediaFoundFurther: false,
      currentFilter: 'Latest',
      showLoadMoreIndicator: false,
      showLoader: false,
      mediaObject: [],
      inReviewVideos: [''],
      reviewListData: {},
    };
    const { route } = props;
    this.openImagePicker = route.params?.openImagePicker;
    this.previousScreen = route.params?.previousScreen;
    this.myUploadConstants = {
      userPost: {
        headerText: 'My Videos',
      },
    };

    if (this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE) {
      this.myUploadConstants.userPost.headerText = 'My Media';
    }
  }

  showMyUploadCondition = () =>
    !Utility.isBlank(this.state.mediaObject) &&
    this.state.mediaObject.length !== 0;

  componentDidMount() {
    const { artistGift } = this.props;
    this.getMedia();
    this.getInReviewMedia();
    if (Platform.OS === 'android') {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    if (Utility.isBlank(artistGift)) {
      getArtistGift((response) => {});
    }

    AnalyticsManager.setCurrentScreen(
      EventParameterValue.SCREEN_NAME.MY_VIDEOS,
    );
  }

  componentWillUnmount() {
    if (Platform.OS === 'android') {
      this.backHandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  _deletePost = (selectedIndex, videoType) => {
    const { mediaObject, inReviewVideos } = this.state;
    if (videoType === 'inReview') {
      inReviewVideos.splice(selectedIndex, 1);
      this.setState({
        ...this.state,
        inReviewVideos,
      });
    } else {
      mediaObject.splice(selectedIndex, 1);
      this.setState({
        ...this.state,
        mediaObject,
      });
    }
  };

  getInReviewMedia = () => {
    const { getVideosInReview } = this.props;
    getVideosInReview((result, data) => {
      if (result) {
        this.setState({
          inReviewVideos: data.objects,
          reviewListData: data,
        });
      }
    });
  };

  _updatePostTitle = (selectedIndex, updatedName, videoType) => {
    const { mediaObject, inReviewVideos } = this.state;

    if (videoType === 'inReview') {
      inReviewVideos[selectedIndex].metadata.title = updatedName;
      this.setState({
        ...this.state,
        inReviewVideos,
      });
    } else if (videoType === 'myVideos') {
      mediaObject[selectedIndex].metadata.title = updatedName;
      this.setState({
        ...this.state,
        mediaObject,
      });
    }
  };

  navigateToPostDetails = (object, position, type) => {
    const { navigation } = this.props;
    navigation.navigate('PostDetail', {
      object,
      deletePost: this._deletePost,
      updatePost: this._updatePostTitle,
      selectedIndex: position,
      videoType: type,
    });
  };

  isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - 1;
  }

  populateMediaList = (page, results) => {
    const { mediaObject } = this.state;
    if (results) {
      let mediaObj = null;
      if (page === 0) {
        mediaObj = results;
      } else {
        mediaObj = [...mediaObject, ...results];
      }

      this.setState({
        ...this.state,
        showLoader: false,
        mediaObject: mediaObj,
        showLoadMoreIndicator: false,
        noMediaFoundFurther: !(results.length > 0),
      });
    } else {
      this.setState({
        ...this.state,
        showLoader: false,
      });
    }
  };

  getPopularMediaList(isFromScroll) {
    const { getPopularMedia } = this.props;
    const { currentPage, noMediaFoundFurther } = this.state;
    if (!noMediaFoundFurther) {
      let page = currentPage;
      if (isFromScroll) {
        page = this.incrementPage();
      } else {
        this.toggleLoader(true);
      }
      getPopularMedia(page, (results) => {
        this.populateMediaList(page, results);
      });
    }
  }

  getLatestMediaList(isFromScroll = false) {
    const { getLatestMedia } = this.props;
    const { currentPage, noMediaFoundFurther } = this.state;
    if (!noMediaFoundFurther) {
      let page = currentPage;
      if (isFromScroll) {
        page = this.incrementPage();
      } else {
        this.toggleLoader(true);
      }
      getLatestMedia(page, (results) => {
        this.populateMediaList(page, results);
      });
    }
  }

  getMedia(isFromScroll = false) {
    const { currentFilter } = this.state;
    if (currentFilter === 'Popular') {
      this.getPopularMediaList(isFromScroll);
    } else {
      this.getLatestMediaList(isFromScroll);
    }
  }

  incrementPage = () => {
    const { currentPage } = this.state;
    let page = currentPage;
    page++;
    this.setState({
      ...this.state,
      currentPage: page,
      showLoadMoreIndicator: true,
    });

    return page;
  };

  toggleLoader = (showToggle) => {
    this.setState({
      ...this.state,
      showLoader: showToggle,
    });
  };

  toggleVideoList = (value) => {
    // Reset everything and move to page 0
    this.setState({
      currentFilter: value,
      currentPage: 0,
      mediaObject: [],
      showLoader: true,
      showLoadMoreIndicator: false,
      noMediaFoundFurther: false,
    });
    this.getMedia(false);
  };

  myUploadsEmptyState = (props) => {
    const { mediaObject, inReviewVideos } = this.state;
    const { artistGift } = this.props;
    let imageSource = undefined;
    let showGift = false;
    let desc = `Upload new videos on ${Config.APP_NAME} and grow your audience`;
    if (
      (Utility.isBlank(mediaObject) || mediaObject.length !== 0) &&
      (Utility.isBlank(inReviewVideos) ||
        (inReviewVideos.length === 0 && !showLoader && !claimedWelcomeGift)) &&
      !Utility.isBlank(artistGift)
    ) {
      imageSource = {
        uri: Utility.getMinifiedImage(artistGift.image_url, 240, 240),
      };
      desc =
        'Upload your first video to claim tools that let you shoot like a pro';
      showGift = true;
      console.log(
        'artist gift in upload video is  ',
        artistGift,
        Utility.isBlank(artistGift),
        Utility.getMinifiedImage(artistGift.image_url, 120, 120),
      );
    }
    return (
      <View>
        {/* <this.mediaHeader name={this.myUploadConstants.userPost.headerText} /> */}
        <Text style={[styles.uploadSubheadingFont, { alignSelf: 'center' }]}>
          {desc}
        </Text>
        <View style={styles.uploadContentContainer}>
          <View style={styles.uploadRow} />
          <View style={styles.uploadCenterAlignedColumn}>
            <Image
              source={imageSource}
              style={{ height: 200, width: 200 }}
              resizeMode="contain"
            />
            {showGift ? (
              <Text style={styles.uploadSubheadingFont}>
                {artistGift.sub_text}
              </Text>
            ) : null}
            <TouchableOpacity
              onPress={this.uploadVideoFromProfile}
              style={styles.uploadButton}
            >
              <Text style={styles.uploadText}>Upload</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  uploadVideoFromProfile = () => {
    const { navigation } = this.props;
    if (this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE) {
      navigation.navigate('Camera', {
        previousScreen: SCREEN_CONSTANTS.CONSUMER_PROFILE,
      });
    } else {
      this.openImagePicker();
      this.onHardwareBackKeyPress();
    }
  };

  mediaHeader = (props) => {
    const { name } = props;
    const { currentFilter } = this.state;
    return (
      <View style={styles.headingContainer}>
        <Text style={styles.subHeading}>{name}</Text>
        {/* <TouchableOpacity>
          <Dropdown
            value={currentFilter}
            data={this.filterOptions}
            containerStyle={{ width: 100, height: 30, marginLeft: 10 }}
            inputContainerStyle={{ borderBottomColor: 'transparent' }}
            dropdownOffset={{ top: 0, left: 0 }}
            textColor={colors.foxyBlack}
            onChangeText={value => this.toggleVideoList(value)}
            baseColor={colors.silver}
            textColor={colors.foxyPink}
          />
        </TouchableOpacity> */}
      </View>
    );
  };

  navigateToVideo = (item) => {
    const { navigation } = this.props;
    const { reviewListData } = this.state;
    const itemData = item;

    if (Utility.isBlank(itemData.metadata)) return;
    itemData.metadata.duration = itemData.metadata.duration || 0;

    navigation.push('ContentModal', {
      itemData,
      listId: 0,
      index: 0,
      listData: reviewListData,
    });
  };

  inReviewVideos = () => {
    const { inReviewVideos } = this.state;
    if (Utility.isBlank(inReviewVideos)) {
      return null;
    }
    return (
      <>
        <Text style={styles.inReviewHeader}>
          {this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE
            ? 'Media in review'
            : 'Videos in review'}
        </Text>
        <View style={styles.railView}>
          <FlatList
            extraData={inReviewVideos}
            data={inReviewVideos}
            horizontal
            showsHorizontalScrollIndicator={false}
            displayCount={inReviewVideos.length}
            renderItem={({ item, index }) => (
              <UserPostCard
                itemData={item}
                onClick={() => this.navigateToVideo(item)}
                size="small"
              />
            )}
            style={{ overflow: 'visible' }}
          />
        </View>
      </>
    );
  };

  myUploads = () => {
    const { showLoader, showLoadMoreIndicator, mediaObject } = this.state;
    return (
      <>
        <this.mediaHeader name={this.myUploadConstants.userPost.headerText} />
        {showLoader && (
          <View
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <ActivityIndicator size="large" color={colors.green} />
          </View>
        )}
        {!showLoader && (
          <View style={GridStyles.container}>
            <FlatList
              extraData={mediaObject}
              data={mediaObject}
              numColumns={2}
              renderItem={({ item, index }) => (
                <UserPostCard
                  itemData={item}
                  onClick={() =>
                    this.navigateToPostDetails(item, index, 'myVideos')
                  }
                />
              )}
              style={{ overflow: 'visible' }}
            />
            {showLoadMoreIndicator && (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ActivityIndicator size="large" color={colors.green} />
              </View>
            )}
          </View>
        )}
      </>
    );
  };

  userUploads = withEither(
    this.showMyUploadCondition,
    this.myUploads,
  )(this.myUploadsEmptyState);

  emptyState = () => {
    const { mediaObject, inReviewVideos } = this.state;

    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <StaticNavigationHeader
          title={
            this.previousScreen === SCREEN_CONSTANTS.CONSUMER_PROFILE
              ? 'My Media'
              : 'My Videos'
          }
        />
        <View style={styles.uploadContentContainer}>
          <View style={styles.uploadRow}>
            <Text style={styles.uploadHeaderText}>No Videos Found</Text>
          </View>
          <View style={styles.uploadCenterAlignedColumn}>
            <Text style={styles.uploadSubheadingFont}>
              Upload new videos on {Config.APP_NAME} and grow your audience
            </Text>
            <TouchableOpacity
              onPress={this.uploadVideoFromProfile}
              style={styles.uploadButton}
            >
              <Text style={styles.uploadText}>Upload</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  render() {
    const { mediaObject, inReviewVideos, showLoader } = this.state;
    const { route } = this.props;
    const uploadVideoFromProfile = route.params?.uploadVideoFromProfile;
    // if (
    //   (Utility.isBlank(mediaObject) || mediaObject.length !== 0) &&
    //   (Utility.isBlank(inReviewVideos) || (inReviewVideos.length === 0 && !showLoader))
    // ) {
    //   return <this.emptyState />;
    // }
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <StaticNavigationHeader title="My Videos" />
        <ScrollView
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={({ nativeEvent }) => {
            if (this.isCloseToBottom(nativeEvent)) {
              // do something
              this.getMedia(true);
            }
          }}
        >
          <Drafts uploadVideoFromProfile={uploadVideoFromProfile} />
          {showLoader && (
            <View style={{ flex: 1 }}>
              <ActivityIndicator
                size="large"
                color={colors.grey}
                style={{
                  position: 'absolute',
                  alignSelf: 'center',
                  top: Utility.getScreenWidth() / 2,
                }}
              />
            </View>
          )}
          <this.inReviewVideos />
          {!showLoader && <this.userUploads />}
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  artistGift: state.UserAccountInfo.artistGift,
  claimedWelcomeGift: state.UserAccountInfo.artistProfile.claimedWelcomeGift,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getLatestMedia,
      getPopularMedia,
      getVideosInReview,
      getArtistGift,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(MyUploads),
);
