import { takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import {
  GET_LOYALTY_PLAN_PROMPT_DETAILS,
  GET_LOYALTY_PLANS,
  GET_TESTIMONIALS,
  INVITE_CODE_VALIDATE,
  INVITE_CODE_CLAIM,
} from '../actions/FoxyEdgeAction';
import Utility from '../utils/Utility';
import { URL } from '../config/Constants';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

function* getLoyaltyPlanPromptDetails(action) {
  const { callback = () => {}, slug = '' } = action;
  const url = `${URL.EDGE_LISTS}/${slug}`;

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.log(`${JSON.stringify(error)}`);
  }
}

function* getLoyaltyPlans(action) {
  const {
    callback = () => {},
    upgradable = false,
    minimumEdgePriority = -1,
  } = action;
  let url = `${URL.LOYALTY_PLANS}`;

  if (upgradable) {
    url = `${url}?show_upgradable=true&minimum_edge_priority=${minimumEdgePriority}`;
  } else {
    url = `${url}?minimum_edge_priority=${minimumEdgePriority}`;
  }

  try {
    let sendCallbackData = true;
    const timer = setTimeout(() => {
      sendCallbackData = false;
      callback(false, { connectionError: true });
    }, 25000);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    clearTimeout(timer);
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300 && sendCallbackData) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    console.log(`${JSON.stringify(error)}`);
  }
}

function* getTestimonials(action) {
  const { callback = () => {} } = action;
  const url = `${URL.TESTIMONIALS}`;

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.log(`${JSON.stringify(error)}`);
  }
}

function* validateInviteCode(action) {
  const { callback = () => {}, invite_code = '' } = action;
  const url = `${URL.INVITE_CODE_VALIDATION}`;

  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        invite_code: {
          invite_code: invite_code,
        },
      }),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.log(`${JSON.stringify(error)}`);
  }
}

function* claimInviteCode(action) {
  const { callback = () => {}, invite_code = '' } = action;
  const url = `${URL.INVITE_CODE_CLAIM}`;

  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        invite_code: {
          invite_code: invite_code,
        },
      }),
    });
    const statusCode = response.status;
    const json = yield convertJsonFromResponse(response);
    if (statusCode >= 200 && statusCode < 300) {
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, json);
    }
  } catch (error) {
    console.log(`${JSON.stringify(error)}`);
  }
}

export default function* watchFoxyEdgeSaga() {
  yield takeLatest(
    GET_LOYALTY_PLAN_PROMPT_DETAILS,
    getLoyaltyPlanPromptDetails,
  );
  yield takeEvery(GET_LOYALTY_PLANS, getLoyaltyPlans);
  yield takeLatest(GET_TESTIMONIALS, getTestimonials);
  yield takeLatest(INVITE_CODE_VALIDATE, validateInviteCode);
  yield takeLatest(INVITE_CODE_CLAIM, claimInviteCode);
}
