import React, { PureComponent } from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Utility from '../../utils/Utility';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import { postVideoView } from '../../../src/actions/LoginActions';
import { isWeb } from '../../utils/BooleanUtility';
import Hls from 'hls.js';

const MediaStyles = StyleSheet.create({
  desktopVideoContainer: { width: '100%', height: '100%', alignSelf: 'center' },
  playerContainerStyle: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playerStyle: {
    width: '100%',
    height: '100%',
  },
});
class MediaComponent extends PureComponent {
  doubleTapRef = React.createRef();

  constructor(props) {
    super(props);
    const {
      reportBuffering = () => {},
      videoMetadata = {},
      seekToTimeOnLoad,
    } = this.props;
    this.state = {
      isSeeking: false,
      milestonesHit: {},
    };
    this.videoMetadata = videoMetadata;
    this.reportBuffering = reportBuffering;
    this.milestones = [5, 10, 20, 30, 60, 300, 600, 1200, 7200];
    this.currentVideoTime = 0;
    this.currentVideoState = '';
    this.bufferingStartedAt = new Date();
    this.startTime = seekToTimeOnLoad;
    this.isVideoLoaded = false;

    this.hls = new Hls();
  }

  // Life Cycle Methods
  componentDidMount() {
    if (this.props.onRef != null) {
      this.props.onRef(this);
    }

    // Dimensions.addEventListener('change', this.onRotated);

    const { videoUrl = '' } = this.props;
    if (isWeb()) {
      if (this.webVideoRef) {
        this.hls.loadSource(videoUrl);
        this.hls.attachMedia(this.webVideoRef);
      }
      this.setupHLS();
    }

    // always first video will be not paused
    const { paused } = this.props;
    if (!paused) {
      Utility.video_open_time = new Date();
      this.triggerVideoOpenEvent();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { paused: prevPaused, currentPage: prevCurrentPage } = prevProps;
    const { paused, currentPage } = this.props;
    if (!prevCurrentPage && currentPage) {
      this.triggerVideoOpenEvent();
    }
    if (prevPaused && !paused && this.isVideoLoaded) {
      this.webVideoRef?.play();
      this.triggerVideoStartEvent();
    } else if (!prevPaused && paused) {
      this.webVideoRef?.pause();
      this.triggerVideoStopEvent();
    }
  }

  componentWillUnmount() {
    const { setDidVideoStart } = this.props;
    if (setDidVideoStart) setDidVideoStart(false);
    this.destroyHLSSetup();
  }

  // Playback handling methods
  // This method is called when player starts loading of video.
  onLoadStart = () => {
    this.reportBuffering(true);
  };

  // This method is called when video is loaded and ready to play. In this we
  onLoad = () => {
    const {
      onLoadVideo,
      paused,
      // playerStyle: { height },
    } = this.props;
    this.reportBuffering(false);
    this.isVideoLoaded = true;

    if (onLoadVideo) {
      onLoadVideo();
    }
    if (!paused) {
      this.webVideoRef?.play();
      this.triggerVideoStartEvent();
    }
  };

  // This method will be called when Youtube video is playing.
  onPlaying = _.throttle(
    (currentTime) => {
      const { setCurrentTime, duration, isLastVideo } = this.props;
      const { isSeeking, milestonesHit, videoCompleted } = this.state;
      if (isSeeking) {
        return;
      }
      const currentProgress = currentTime / duration;
      setCurrentTime(currentTime);

      //Saving time in this variable so that we can send it with event param (currentTime )
      this.currentVideoTime = currentTime;
      // Log an event for video engagement
      if (currentTime > this.milestones[0]) {
        const currentMilestone = this.milestones.shift();
        if (!milestonesHit[currentMilestone]) {
          this.setState(
            {
              milestonesHit: {
                ...milestonesHit,
                [currentMilestone]: true,
              },
            },
            () => {
              AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_ENGAGE, {
                ...this.videoMetadata,
                milestone: currentMilestone,
              });
            },
          );
        }
      }

      // Log an event for video completion
      if (currentProgress >= 0.98 && !videoCompleted) {
        this.setState({ videoCompleted: true }, () => {
          AnalyticsManager.logEvent(
            EventType.videoEvents.VIDEO_COMPLETE,
            this.videoMetadata,
          );
        });
      }
      if (duration - currentTime <= 0) {
        if (!isLastVideo) {
          setCurrentTime(0);
          setTimeout(() => {
            this.seekToValue(0);
          }, 500);
        }
        this.onEnd();
      }
    },
    500,
    { trailing: true },
  );

  triggerVideoOpenEvent = () => {
    const { productIdsObject } = this.props;
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_OPEN, {
      ...this.videoMetadata,
      [EventParameterKey.PRODUCT_ID]:
        productIdsObject?.productIdArray.toString(),
      [EventParameterKey.SKU_ID]: productIdsObject?.skuIdArray.toString(),
    });
  };

  triggerVideoStopEvent = () => {
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_STOP, {
      ...this.videoMetadata,
      [EventParameterKey.CURRENT_TIME]: Math.round(this.currentVideoTime),
    });
  };

  triggerVideoStartEvent = () => {
    const { postVideoView, videoId } = this.props;
    postVideoView(videoId);
    const { setDidVideoStart } = this.props;
    if (setDidVideoStart) setDidVideoStart(true);
    Utility.video_play_time = new Date();
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_START, {
      ...this.videoMetadata,
      [EventParameterKey.CURRENT_TIME]: Math.round(this.currentVideoTime),
      [EventParameterKey.LOAD_TO_PLAY_TIME]: Utility.getTimeDiffInMs(
        Utility.video_play_time,
        Utility.video_open_time,
      ),
    });
  };

  triggerBufferStartEvent = () => {
    this.bufferingStartedAt = new Date();
    AnalyticsManager.logEvent(
      EventType.videoEvents.VIDEO_BUFFERING_START,
      this.videoMetadata,
    );
  };

  triggerBufferStopEvent = () => {
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_BUFFERING_STOP, {
      ...this.videoMetadata,
      [EventParameterKey.BUFFERING_DURATION]: Utility.getTimeDiff(
        new Date(),
        this.bufferingStartedAt,
      ),
    });
  };

  // This method will be called when Foxy video is playing.
  handleProgress = (progress) => {
    this.onPlaying(progress.currentTime);
  };

  // this method is called when video ends
  onEnd = () => {
    const { onNextButtonTapped, onNextButtonClick, navigation, route } =
      this.props;

    const isHomePageAttributes = route?.params?.homepageAttributes
      ? true
      : false;
    AnalyticsManager.logEvent(
      EventType.videoEvents.VIDEO_END,
      this.videoMetadata,
    );
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_CHANGE, {
      ...this.videoMetadata,
      [EventParameterKey.METHOD]: 'Auto',
    });

    if (!isHomePageAttributes) onNextButtonTapped();
  };

  onError = (e) => {
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_ERROR, {
      ...this.videoMetadata,
      [EventParameterKey.ERROR_TYPE]: 'Playback Error',
      [EventParameterKey.RAW_ERROR]: e,
    });
  };

  setupHLS = () => {
    if (this.webVideoRef) {
      const video = this.webVideoRef;
      if (Hls.isSupported()) {
        // Event listeners
        video.addEventListener('error', this.onError);
        video.addEventListener('loadstart', this.onLoadStart);
        video.addEventListener('loadeddata', this.onLoad);
        video.addEventListener('progress', () =>
          this.handleProgress({ currentTime: video.currentTime }),
        );
        video.addEventListener('waiting', this.onFoxyPlayerBuffer);
        video.addEventListener('ended', this.onEnd);
      }
    }
  };

  destroyHLSSetup = () => {
    if (this.webVideoRef) {
      const video = this.webVideoRef;
      if (Hls.isSupported()) {
        this.hls.destroy();

        // Remove event listeners
        video.removeEventListener('error', this.onError);
        video.removeEventListener('loadstart', this.onLoadStart);
        video.removeEventListener('loadeddata', this.onLoad);
        video.removeEventListener('progress', () =>
          this.handleProgress({ currentTime: video.currentTime }),
        );
        video.removeEventListener('waiting', this.onFoxyPlayerBuffer);
        video.removeEventListener('ended', this.onEnd);
      }
    }
  };

  // Dimensions change Event
  // onRotated = ({ window: { width, height } }) => {
  //   const orientation = width > height ? 'LANDSCAPE' : 'PORTRAIT';
  //   console.tron.display({
  //     name: 'Device Rotated',
  //     value: orientation,
  //     important: true,
  //   });
  // };

  // Bottom Controls
  onSlidingComplete = (value) => {
    const { slidingStartTime } = this.state;
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_SCRUB, {
      ...this.videoMetadata,
      [EventParameterKey.SCRUB_START_TIME]: slidingStartTime,
      [EventParameterKey.SCRUB_END_TIME]: value,
    });
    this.setState(
      {
        slidingStartTime: null,
        isSeeking: false,
      },
      this.seekToValue(value),
    );
  };

  onSlidingStart = (value) => {
    this.setState({
      slidingStartTime: value,
      isSeeking: true,
    });
  };

  saveVideoPlayerRef = (ref) => {
    this.webVideoRef = ref;
  };

  seekToValue = (value) => {
    if (this.webVideoRef) {
      this.webVideoRef.currentTime = value;
    }
  };

  source = () => {
    const { imageUrl } = this.props;
    const source = {
      uri: Utility.getMinifiedImage(
        imageUrl,
        Utility.screenWidth,
        Utility.screenWidth,
      ),
    };
    return source;
  };

  getStyles = (playerStyle) => {
    const videoStyle = {
      width: playerStyle.width,
      height: playerStyle.height,
      position: 'absolute',
      zIndex: 0,
    };

    const placeholderViewStyle = {
      width: playerStyle.width,
      height: playerStyle.height,
      position: 'absolute',
      zIndex: 10,
    };

    const placeholderImageStyle = [
      playerStyle,
      {
        top: 0,
        position: 'absolute',
      },
    ];

    return {
      videoStyle,
      placeholderViewStyle,
      placeholderImageStyle,
    };
  };

  onFoxyPlayerBuffer = (event) => {
    const { isBuffering } = event;
    this.reportBuffering(isBuffering);
    if (isBuffering) {
      this.triggerBufferStartEvent();
    } else {
      this.triggerBufferStopEvent();
    }
  };

  renderVideo = () => {
    const { muted = false } = this.props;
    return (
      <View style={MediaStyles.desktopVideoContainer}>
        <video
          height={'100%'}
          width={'100%'}
          ref={this.saveVideoPlayerRef}
          muted={muted}
        />
      </View>
    );
  };

  render() {
    const {
      playerContainerStyle = MediaStyles.playerContainerStyle,
      playerStyle = MediaStyles.playerStyle,
    } = this.props;
    return (
      <View style={playerContainerStyle}>
        <View style={playerStyle}>
          <this.renderVideo />
        </View>
      </View>
    );
  }
}

MediaComponent.propTypes = {
  videoId: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  paused: PropTypes.bool.isRequired,
  playerStyle: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }).isRequired,
  duration: PropTypes.string.isRequired,
  videoMetadata: PropTypes.shape({
    [EventParameterKey.VIDEO_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_TITLE]: PropTypes.string,
    [EventParameterKey.ARTIST_NAME]: PropTypes.string,
    [EventParameterKey.ARTIST_ID]: PropTypes.string,
    [EventParameterKey.VIDEO_DURATION]: PropTypes.string,
    [EventParameterKey.VIDEO_TYPE]: PropTypes.string,
  }).isRequired,
  currentPage: PropTypes.bool.isRequired,
  onLoadVideo: PropTypes.func.isRequired,
  reportBuffering: PropTypes.func.isRequired,
  onNextButtonTapped: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  setCurrentTime: PropTypes.func,
  onMediaComponentTap: PropTypes.func,
  onMediaDoubleTap: PropTypes.func,
  isLastVideo: PropTypes.bool,
};

MediaComponent.defaultProps = {
  onMediaComponentTap: () => {},
  onMediaDoubleTap: () => {},
  setCurrentTime: (time) => {},
  isLastVideo: false,
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      postVideoView,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(MediaComponent);
