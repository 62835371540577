// Dependencies
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import FoxyPromises from './FoxyPromises';
import ProductIngredients from './ProductIngredients';
import ProductDelivery from './ProductDelivery';
import RateProduct from './RateProduct';
import ProductDetailDescription from './ProductDetailDescription';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import ProductRating from '../productRatings/ProductRating';
import FoxyPersonalizedProducts from './FoxyPersonalizedProducts';
import AppConfig from '../../config/AppConfig';
import List from '../../containers/List/List';
import RichContent from './RichContent/RichContent';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { isDesktop, isPresent } from '../../utils/BooleanUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';

const ProductInStock = (props) => {
  const {
    itemData,
    selectedVariant,
    updateDeliveryTime,
    toggleReturnModal,
    onReview,
    getComponent,
    onPressMore = () => {},
    setDescriptionBoxRef,
    facialAnalysis,
    navigation,
    unlikeRating,
    likeRating,
    likedRatings,
    variantAttributes,
    toggleVariantModal,
    onRatingLayout,
    toggleCertificateModal,
    // productPositiveIngredients,
    // productNegativeIngredients,
    displaySp,
    productPage = {},
    toggleSecurePaymentModal,
    toggleEasyDeliveryModal,
    sendAlertForPrice,
    productPersonalizedDetails,
    orderCampaign = {},
  } = props;

  const { variant_attributes = [] } = itemData;
  const {
    product_page_type,
    product_category: {
      category_user_attributes: { principal = {}, goal = {} } = {},
    } = {},
    rich_content = {},
  } = itemData;

  const [upperList, setupperList] = useState(null);
  const [lowerList, setLowerList] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setupperList({
        display: 'vertical',
        content: 'mixed',
        objects: itemData?.consolidated_list_upper || [],
      });
      setLowerList({
        display: 'vertical',
        content: 'mixed',
        objects: itemData?.consolidated_list_lower || [],
      });
    }, 0);
  }, [itemData.consolidated_list_upper, itemData.consolidated_list_lower]);

  return (
    <>
      <ProductIngredients
        ingredients={itemData.star_ingredients}
        principalAttributes={{ ...principal, ...goal }}
        facialAnalysis={facialAnalysis}
        navigation={navigation}
        productId={itemData.id}
        sku_id={itemData.sku_id}
        productPage={productPage}
      />

      {product_page_type === 'foxy' && (
        <ProductDetailDescription
          description={itemData.description || ''}
          howTo={itemData.how_to || ''}
          ingredients={itemData.ingredients || ''}
          name={itemData.name}
          expiryDate={itemData.expiry_date}
          bestBefore={itemData.best_before}
          itemData={itemData}
          selectedVariant={selectedVariant}
          onPressMore={onPressMore}
          sku={
            isPresent(selectedVariant) ? selectedVariant?.sku : itemData?.sku
          }
          displaySp={displaySp}
          sendAlertForPrice={sendAlertForPrice}
          productPage={productPage}
        />
      )}

      {/* <View onLayout={onLayout} style={styles.emptyView} /> */}

      <FoxyPersonalizedProducts
        productId={itemData.id}
        productPersonalizedDetails={productPersonalizedDetails}
      />
      {Utility.isPresent(upperList?.objects) && (
        <View>
          <List
            itemData={upperList}
            id={itemData.id}
            previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
            useInViewPort
            verticalComponentWidth={isDesktop() ? PRODUCT_RIGHT_CONTAINER_WIDTH : undefined}
          />
        </View>
      )}
      {!AppConfig.getBooleanValue(Config.HIDE_RATING) && (
        <View onLayout={onRatingLayout}>
          <ProductRating
            rating={itemData.rating}
            reviewData={itemData.reviews}
            reviewsCount={itemData.reviews_count}
            ratingsCount={itemData.ratings_count}
            navigation={navigation}
            productId={itemData.id}
            productSlug={itemData.slug}
            unlikeRating={unlikeRating}
            likeRating={likeRating}
            likedRatings={likedRatings}
            variants={variant_attributes}
            toggleVariantModal={toggleVariantModal}
            itemData={itemData}
            productPage={productPage}
          />

          <View style={styles.rateProductContainer}>
            <RateProduct
              reviewData={itemData.reviews}
              onReview={onReview}
              slug={itemData.slug}
              id={itemData.sku_id}
              itemData={itemData}
              selectedVariant={selectedVariant}
            />
          </View>
        </View>
      )}

      {isPresent(rich_content?.post_reviews) && (
        <View style={styles.separator} />
      )}
      <RichContent itemData={rich_content?.post_reviews} />

      <ProductDelivery
        updateDeliveryTime={updateDeliveryTime}
        productPage={productPage}
        orderCampaign={orderCampaign}
        skuId={itemData.sku_id}
      />

      {Config.APP_NAME === 'Foxy' && !isDesktop() && (
        <FoxyPromises
          productPage={productPage}
          navigation={navigation}
          product_id={itemData.id}
        />
      )}
      {!isDesktop() && Utility.isPresent(lowerList?.objects) && (
        <View>
          <List
            itemData={lowerList}
            id={itemData.id}
            previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
            useInViewPort
          />
        </View>
      )}
      <RichContent itemData={rich_content?.page_end} />
    </>
  );
};

const styles = StyleSheet.create({
  emptyView: {
    width: Utility.getScreenWidth(),
    height: 70 + Utility.bottomInset,
  },
  upperList: {
    backgroundColor: colors.background,
  },
  lowerList: {
    backgroundColor: colors.background,
    paddingBottom: 20,
    marginTop: 12,
  },
  rateProductContainer: { backgroundColor: colors.white },
  bottomPadding: {
    paddingBottom: 20,
  },
  separator: {
    height: 12,
  },
});

export default React.memo(ProductInStock);
