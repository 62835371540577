import React, { Component } from 'react';
import { View, StatusBar, BackHandler } from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';
import { denormalize } from 'normalizr';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { brandSchema } from '../../config/Schema';
import { BrandDetail } from '../../components/brand';
import { LAYOUT } from '../../config/Constants';
import { withMaybe, withEither } from '../../lib/Monads';
import { fetchBrand, fetchList } from '../../actions/ActionTypes';
import Utility from '../../utils/Utility';
import { schemaBySlug } from '../../config/SchemaBySlug';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { AnalyticsManager } from '../../analytics';
import FilterUtils from '../../components/sort-filter-options/FilterUtils';
import { StaticNavigationHeader } from '../../components/shared';
import ScreenPlaceholder from '../../components/shared/ScreenPlaceholder';
import ServerError from '../../components/shared/ServerError';
import PageNotFound from '../../components/shared/PageNotFound';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { EventParameterKey } from '../retailStore/analytics';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { getFullSlugFromName, nestedParamToName } from '../../utils/StringUtility';

class Brand extends Component {
  constructor(props) {
    super(props);
    Utility.setPageLoadStart('brand_detail');
    this.state = {
      brandData: {},
      showShimmer: true,
      stickyPosition: -1,
      selectedFilters: {},
      filters: [],
      quickFilters: [],
      filtersEnabled: false,
      filteredSlug: '',
      isPageLoading: true,
      serverError: false,
      hideOosProduct: false,
    };

    this.slug = '';
    this.prevAppliedFiltersFromModal = [];
    this.selectedQuickFilters = [];
    this.quickFiltersRef = null;
    this.fnAutoListPagination = null;
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
    this.brandSlug = this.getSlug().replace(/\/v\d+\//, '/v6/');
  }

  componentDidMount() {
    this.getSlugAndHitApi();
    setTimeout(this.fireListRenderedEvent, 3000);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  getSlugAndHitApi = () => {
    const { layout } = this.props;
    if (layout === undefined) {
      this.hitApiCall(this.brandSlug);
    }
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  fireListRenderedEvent = () => {
    const { itemData } = this.props;
    Utility.fireListRenderedEvent(itemData, SCREEN_CONSTANTS.BRAND_DETAILS);
  };

  fetchBrandCallBack = (success, data, status) => {
    this.setState({
      showShimmer: false,
    });
    if (success) {
      this.addFiltersObjectIntoListData(data);
    } else {
      const { route: { params: { query = '' } = {} } = {} } = this.props;
      AnalyticsManager.logEvent('fetch_brand_fail', {
        [EventParameterKey.SLUG]: this.brandSlug?.slice(0, 98),
        query,
      });
      Utility.clearPageLoadTimer();
      this.setState({
        isPageLoading: false,
        serverError: status !== 404,
      });
    }
  };

  firePageLoadEnd = () => {
    Utility.setPageLoadEnd(SCREEN_CONSTANTS.BRAND_DETAILS);
  };

  hitApiCall = (slug) => {
    this.setState({
      showShimmer: true,
    });
    const { itemData, fetchBrand } = this.props;
    AnalyticsUtility.recordTime(
      {
        screen_name: SCREEN_CONSTANTS.BRAND_DETAILS,
        ...Utility.setRecordTimeEventMeta(itemData, 'brand'),
      },
      this.fetchBrandCallBack,
      fetchBrand,
      { slug },
    );
  };

  setListPaginatedCallApiRef = (fnAutoListPagination) => {
    this.fnAutoListPagination = fnAutoListPagination;
  };

  addFiltersObjectIntoListData = (apiResponse) => {
    const response = apiResponse;
    const {
      list: objects,
      position: stickyPosition,
      slug,
    } = Utility.createFilterObjectIfExists(
      apiResponse.list.objects,
      true,
      false,
      true,
    );
    this.slug = slug;
    response.list.objects = objects;
    const { filters, quickFilters } = FilterUtils.getFiltersFromList(
      response.list,
    );

    this.setState({
      brandData: response,
      filters,
      quickFilters,
      showShimmer: false,
      stickyPosition,
    });
  };

  onFiltersPress = (item, added, index) => {
    const { navigation } = this.props;
    const {
      brandData,
      brandData: { id = '', name = '', slug: brandSlug = '' },
      selectedFilters,
      filters,
      hideOosProduct,
    } = this.state;
    const { list } = FilterUtils.getList(brandData.list);

    if (
      item.criteria_name === 'quick_filter_sort_by' ||
      item.criteria_name === 'quick_filter_foxy_match'
    ) {
      navigation.navigate('SortingFilteringModal', {
        selectedFilters,
        filterModel: filters,
        onFilterApplyPress: this.onFiltersPress,
        listName: name,
        listId: id,
        listSlug: brandSlug,
        screenName: SCREEN_CONSTANTS.BRAND,
        listData: brandData,
        entityName: name,
        onPressHideOosCheckBox: this.onPressHideOosCheckBox,
        hideOosProduct,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
      });
      return;
    }

    const {
      slug,
      pageNo,
      allowNextPageLoad,
      filtersEnabled,
      groupedSelectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
    } = FilterUtils.onFilterApplied(
      item,
      added,
      index,
      list,
      selectedFilters,
      this.quickFiltersRef,
      this.slug,
      this.prevAppliedFiltersFromModal,
      this.selectedQuickFilters,
      SCREEN_CONSTANTS.BRAND_DETAILS,
      brandData?.name,
    );

    this.slug = slug;
    this.page = pageNo;
    this.allowNextPageLoad = allowNextPageLoad;
    this.selectedQuickFilters = selectedQuickFilters;
    this.filtersEnabled = filtersEnabled;
    this.prevAppliedFiltersFromModal = prevAppliedFiltersFromModal;
    brandData.list.objects = Utility.addShimmerInListObject(brandData.list);
    this.filtersEnabled = this.slug.includes('&');
    this.setState({
      selectedFilters: groupedSelectedFilters,
      brandData,
      filteredSlug: this.slug,
    });

    this.fetchFilteredList(this.slug);
  };

  getQuickFiltersListRef = (ref) => {
    this.quickFiltersRef = ref;
  };

  onPressHideOosCheckBox = (isHideOos) => {
    this.setState({
      hideOosProduct: isHideOos,
    });
  };

  resetFilters = () => {
    const { navigation } = this.props;
    this.page = 0;
    this.filtersEnabled = false;
    this.slug = this.slug.substr(0, this.slug.indexOf('&'));
    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.selectedQuickFilters = [];

    this.fetchFilteredList(this.slug, true);
  };

  onRefresh = () => {
    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.filtersEnabled = false;
    this.selectedQuickFilters = [];
    this.getSlugAndHitApi(this.brandSlug);
  };

  fetchFilteredList = (slug) => {
    const { fetchList } = this.props;
    const { hideOosProduct } = this.state;
    this.page = 0;
    // FIXME: Buggy Code, need to fix
    fetchList({ slug, hideOosProduct }, this.page, (success, data) => {
      const { brandData } = this.state;
      const paginateLastItem = brandData.list.paginate_last_item;
      const listData = FilterUtils.appendFilteredListToMasterListObjects(
        slug,
        data,
        brandData.list,
      );
      brandData.list = listData;
      this.setState(
        {
          brandData: { ...brandData }, // spreading : this will create a new object ref and will re-render component. Otherwise we are just changing list of main object. Id of previous object will be same and component will not get re-render
        },
        () => {
          if (!paginateLastItem) {
            this.fnAutoListPagination(slug, -1, true);
          }
        },
      );
    });
  };

  itemDataNullFn = (props) => !props.itemData;

  isLayoutScreenFn = (props) => props.layout === 'screen';

  getSlug() {
    const { route = {} } = this.props;
    const { params = {} } = route;
    const {
      slug,
      shortSlug,
      isCategory,
      first,
      second,
      third,
    } = params;
    if (slug) return slug;
    if (!shortSlug) return '';
    return getFullSlugFromName(
      nestedParamToName([shortSlug, isCategory, first, second, third]),
      'brand',
    );
  }

  render() {
    const {
      navigation,
      route,
      itemData,
      isLoading,
      toggleCartVisibility,
      onPressResetButton,
    } = this.props;
    const {
      brandData,
      showShimmer,
      filters,
      selectedFilters,
      isPageLoading,
      serverError,
      hideOosProduct,
    } = this.state;
    let { layout } = this.props;
    layout = layout || (route.params?.display ?? LAYOUT.SCREEN);

    if (layout === 'screen') {
      return (
        <>
          {showShimmer && isPageLoading && (
            <ScreenPlaceholder
              fullscreen
              firePageLoadEnd={this.firePageLoadEnd}
            />
          )}
          {!showShimmer && (
            <BrandDetail
              {...this.props}
              brandData={brandData}
              id={brandData.id}
              layout={layout}
              isLoading={showShimmer} // for placeholder
              toggleCartVisibility={toggleCartVisibility}
              stickyPosition={this.state.stickyPosition}
              selectedFilters={this.state.selectedFilters}
              onFiltersPress={this.onFiltersPress}
              getQuickFiltersListRef={this.getQuickFiltersListRef}
              onPressResetButton={this.resetFilters}
              // abc={() => {}}
              setListPaginatedCallApiRef={this.setListPaginatedCallApiRef}
              slug={this.slug}
              filteredSlug={this.state.filteredSlug}
              resetStatusBar={this.resetStatusBar}
              filtersEnabled={this.filtersEnabled}
              isPageLoading={isPageLoading}
              onRefresh={this.onRefresh}
              serverError={serverError}
              hideOosProduct={hideOosProduct}
            />
          )}
        </>
      );
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchBrand, fetchList }, dispatch),
});

export default connect(null, mapDispatchToProps)(Brand);
