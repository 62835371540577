import React, { Component } from 'react';
import { View, Text, ScrollView, Image, ActivityIndicator } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CartStyles } from '../../components/cart/styles';
import {
  optedForPlasticFreePackaging,
  getCartPricing,
  fetchCartEmptyState,
  buildList,
} from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import List from '../List/List';
import FullWidthDivider from '../../utils/FullWidthDivider';
import ActionButton from '../../components/shared/ActionButton';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  EMPTY_CART_LIST_SLUG,
  MY_LIKED_PRODUCTS,
} from '../../config/Constants';
import { withEither } from '../../lib/Monads';
import sharedStyles from '../../components/shared/styles';

class CartCheckout extends Component {
  cartPageConstants = {
    emptyBagHeader: 'Your bag looks empty',
    surpriseGiftAddedHeader: 'Free surprise available',
    emptyBagSubtitle: 'Lets fill it up',
    surpriseGiftSubtitle: 'Let’s fill up your bag and claim the surprise.',
    bottomDockHeight: 70,
    emptyCartProductsSlug: EMPTY_CART_LIST_SLUG,
    myWishlist: MY_LIKED_PRODUCTS,
  };

  constructor(props) {
    super(props);
    this.state = {
      emptyCartProductList: {},
      recentlyViewedProducts: {},
      emptyCartProductListFallback: {},
      showLoader: false,
      emptyCartError: false,
    };
  }

  componentDidMount() {
    this.setState({ showLoader: true });

    this.getEmptyCartList(this.cartPageConstants.myWishlist);
    this.getRecentProductsViewed();
  }

  getEmptyCartList = (slug) => {
    const { fetchCartEmptyState } = this.props;

    fetchCartEmptyState(slug, (success, data) => {
      const fetchCartEmptyStateFallback = data?.objects
        ? () =>
            this.getEmptyCartListFallback(
              this.cartPageConstants.emptyCartProductsSlug,
            )
        : () => {};
      this.setState({ showLoader: false });
      if (success) {
        this.setState(
          {
            emptyCartProductList: data,
          },
          fetchCartEmptyStateFallback,
        );
      }
    });
  };

  getEmptyCartListFallback = (slug) => {
    const { fetchCartEmptyState } = this.props;
    this.setState({ showLoader: true });
    fetchCartEmptyState(slug, (success, data) => {
      this.setState({ showLoader: false });
      if (success) {
        this.setState({
          emptyCartProductListFallback: data,
          emptyCartError: !success,
        });
      }
    });
  };

  getRecentProductsViewed = () => {
    const { buildList, productViews = [] } = this.props;

    if (Utility.isPresent(productViews)) {
      buildList(productViews, [], (success, data) => {
        if (success) {
          this.setState({
            recentlyViewedProducts: data,
          });
        }
      });
    }
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  productHeader = (props) => {
    const styles = CartStyles;
    const { itemData } = props;
    if (Utility.isBlank(itemData) || itemData.objects.length === 0) {
      return null;
    }

    return (
      <View style={[styles.headingContainer, { marginTop: 16 }]}>
        <Text style={styles.subHeading}>{itemData?.name}</Text>
      </View>
    );
  };

  listView = (props) => {
    const { list } = props;
    return (
      <>
        <this.productHeader itemData={list} />
        <List
          itemData={list}
          id={list.id}
          previousScreen={SCREEN_CONSTANTS.MORE_PAGE}
        />
      </>
    );
  };

  checkAreMyWishlistAndLastViewedProductsBlank = () => {
    const {
      emptyCartProductList,
      recentlyViewedProducts,
      emptyCartProductListFallback,
    } = this.state;
    return (
      Utility.isBlank(recentlyViewedProducts?.objects) &&
      Utility.isBlank(emptyCartProductList?.objects) &&
      Utility.isPresent(emptyCartProductListFallback?.objects)
    );
  };
  cartLoadingCondition = (props) =>
    Utility.isBlank(props.emptyCartProductList) && !props.emptyCartError;

  loader = () => {
    const styles = CartStyles;
    return (
      <View style={styles.indicatorContainer}>
        <ActivityIndicator animating color={colors.foxyPink} size='large' />
      </View>
    );
  };

  renderLists = () => {
    const {
      emptyCartProductList,
      recentlyViewedProducts,
      emptyCartProductListFallback,
      showLoader,
    } = this.state;
    return (
      <View>
        {Utility.isPresent(emptyCartProductList?.objects) && (
          <this.listView list={emptyCartProductList} />
        )}

        {Utility.isPresent(recentlyViewedProducts) && (
          <this.listView list={recentlyViewedProducts} />
        )}

        {this.checkAreMyWishlistAndLastViewedProductsBlank() && (
          <this.listView list={emptyCartProductListFallback} />
        )}

        {showLoader && (
          <View style={CartStyles.loaderContainer}>
            <ActivityIndicator animating color={colors.foxyPink} size='large' />
          </View>
        )}
      </View>
    );
  };

  conditionalListRendering = withEither(
    this.cartLoadingCondition,
    this.loader,
  )(this.renderLists);

  render() {
    const styles = CartStyles;
    const { surpriseAvailable = false } = this.props;
    const { emptyCartProductList, emptyCartError } = this.state;
    const shouldShowSurprise = surpriseAvailable;
    return (
      <>
        <ScrollView style={styles.emptyStateContainer}>
          <View style={styles.emptyBagImageContainer}>
            <Image
              source={shouldShowSurprise ? images.gift : images.cartEmptyBag}
              style={styles.emptyBagImage}
            />
            <Text style={[styles.headerText, { marginTop: 16 }]}>
              {shouldShowSurprise
                ? this.cartPageConstants.surpriseGiftAddedHeader
                : this.cartPageConstants.emptyBagHeader}
            </Text>
            <Text style={styles.subtitleText}>
              {shouldShowSurprise
                ? this.cartPageConstants.surpriseGiftSubtitle
                : this.cartPageConstants.emptyBagSubtitle}
            </Text>
          </View>
          <this.conditionalListRendering
            emptyCartProductList={emptyCartProductList}
            emptyCartError={emptyCartError}
          />
          <FullWidthDivider componentStyle={styles.divider} />
        </ScrollView>
        <View style={CartStyles.actionButton}>
          <ActionButton
            title='Continue Shopping'
            actionButtonColor={colors.primaryActionBackgroundColor}
            onActionPress={this.goBack}
          />
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productViews: state.userInteractions.productViews,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      optedForPlasticFreePackaging,
      getCartPricing,
      fetchCartEmptyState,
      buildList,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CartCheckout),
);
