// Dependencies
import React, { Component } from 'react';
import { View, Text } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Modal from 'react-native-modal';
import { ScrollView } from 'react-native-gesture-handler';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { ProductDetailStyles } from '../Product/styles';

export const { ids, styles: foxyBottomModalStyles } = StyleSheet.create({
  modalStyle: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    height: 20,
  },
  modalContainer: {
    width: Utility.getScreenWidth(),
    borderRadius: 6,
    backgroundColor: 'white',
    marginBottom: -20,
    paddingBottom: 24,
    paddingTop: 20,
    '@media (min-width: 768px)': {
      width: '100%',
    },
  },
  offersContainer: { marginVertical: 8 },
  actionButtonsContainer: {
    marginHorizontal: 16,
    marginTop: 10,
  },
  firstButtonStyle: {
    marginTop: 8,
    borderColor: colors.green,
    borderWidth: 1,
    borderBottomWidth: 1.5,
    overflow: 'visible',
  },
  firstButtonTextStyle: {
    color: colors.green,
    fontSize: 14,
  },
  secondaryButtonTextStyle: {
    fontSize: 14,
  },
  offersInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 4,
    marginHorizontal: 16,
  },
  couponCodePillStyle: {
    flex: 1,
    height: 24,
    marginRight: 4,
  },
  offersDescription: {
    alignSelf: 'flex-start',
    flex: 4,
    paddingHorizontal: 4,
    color: colors.foxyBlack,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
  },
});

class FoxyBottomModal extends Component {
  render() {
    let {
      isModalVisible,
      onModalClose,
      headingText,
      subheadingText,
      firstButtonText,
      secondButtonText,
      firstButtonAction,
      secondButtonAction,
    } = this.props;

    const styles = ProductDetailStyles;
    return (
      <Modal
        isVisible={isModalVisible}
        onBackdropPress={onModalClose}
        style={foxyBottomModalStyles.modalStyle}
        swipeDirection="down"
        scrollOffsetMax={100}
        onRequestClose={onModalClose}
      >
        <View style={foxyBottomModalStyles.modalContainer} dataSet={{ media: ids.modalContainer }}>
          <ScrollView ref={this.scrollViewRef} scrollEventThrottle={16}>
            <Text style={styles.offerName}>{headingText}</Text>

            <Text style={styles.subtitleText}>{subheadingText}</Text>

            <View style={foxyBottomModalStyles.actionButtonsContainer}>
              <FoxyShadowButton
                width={Utility.getScreenWidth() - 34}
                title={firstButtonText}
                onPress={firstButtonAction}
                backgroundColor={colors.primaryActionBackgroundColor}
                textStyle={foxyBottomModalStyles.secondaryButtonTextStyle}
                firstColor={colors.linearGradientGreenFirst}
                secondColor={colors.linearGradientGreenSecond}
              />
            </View>
          </ScrollView>
        </View>
      </Modal>
    );
  }
}

export default FoxyBottomModal;
