import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  ImageBackground,
  ActivityIndicator,
  RefreshControl,
  Platform,
  BackHandler,
  Linking,
  Alert,
  TouchableWithoutFeedback,
} from 'react-native';
import { denormalize } from 'normalizr';
import Orientation from 'react-native-orientation';
import Permissions from '../../libraries/ReactNativePermissions';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CardView from '../../libraries/ReactNativeCardView';
import Toast from 'react-native-easy-toast';
import ImagePicker from '../../libraries/ImagePicker';
import uuidv1 from 'uuid/v1';
import uuidv5 from 'uuid/v5';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { withEither } from '../../lib/Monads';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { listSchema } from '../../config/Schema';
import {
  artistOnboardingCompleted,
  getMyProfileDetails,
  getLatestMedia,
  getArtistInstagramProfile,
  getUserSubscribers,
  saveRefferalCode,
  saveArtistTypeLocal,
  getArtistContent,
  getArtistGift,
  acceptTerms,
  getSelectedYoutubeChannelIdForProfile,
  saveGoogleData,
  getGoogleProfileWithToken,
  saveYoutubeRefreshToken,
  getCampaignData,
  updateArtistInstagramProfile,
} from '../../actions/ActionTypes';
import styles from './styles';
import {
  logoutUser,
  setPhoneNumberModalInfo,
} from '../../actions/LoginActions';
import {
  TABBAR_CONSTANT,
  ARTIST_CONSTANTS,
  AVAILABLE_BUILD_TYPES,
  PREVIOUS_SCREEN_SOURCES,
  OPTIN_PROCESS_STAGES,
  LOGIN_MODAL_MESSAGES,
} from '../../config/Constants';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import ScaleAnimate from '../../components/shared/ScaleAnimate';
import { UploadVideoBanner } from '../../components/profile';
import { addPostToLocalState } from '../../actions/UploadPostActions';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { ProfilePic } from '.';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';
import size from '../../theme/Fonts';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import NavigationService from '../../navigator/NavigationService';
import withTabNavigationHeader from '../../lib/withTabNavigationHeader';
import { isNative } from '../../utils/BooleanUtility';

class MyProfile extends Component {
  myProfileConstants = {
    artistBanner: {
      initialCardTitle: 'Claim FREE Gift',
      cardTitle: 'Upload New Video',
      cardSubtitle: 'Submit your latest creation to our Editorial Team',
      initialCardSubtitle:
        'Upload your first video to claim tools that let you shoot like a pro',
    },
    userPost: {
      headerText: 'Uploads',
    },
    social: {
      youtube: 'YouTube',
      instagram: 'Instagram',
    },
    addAddressText:
      'You are eligible for free products. Please add an address to continue',
  };

  constructor(props) {
    super(props);

    this.dashboardData = [
      {
        id: 1,
        size: 'large',
        title: 'YouTube',
        subtitle: 'Earn money in your wallet for people you refer',
        type: 'analytics',
        titleColor: '#000000',
        color: '#000000',
      },
      {
        id: 2,
        size: 'small',
        title: 'My Videos',
        subtitle: 'Keep your professional profile updated',
        type: 'professionalProfile',
        titleColor: colors.foxyBlack,
        color: colors.silver,
      },
      {
        id: 3,
        size: 'small',
        title: 'My Collabs',
        subtitle: 'Find drafts, published and in-review videos',
        type: '',
        titleColor: colors.foxyBlack,
        color: colors.silver,
      },
      {
        id: 4,
        size: 'small',
        title: 'Invite and Earn',
        subtitle: 'Gifts for you and every beauty influencer ',
        type: 'brandCollab',
        titleColor: '#FE723C',
        color: '#FE723C',
      },

      {
        id: 5,
        size: 'small',
        title: 'Talk to us',
        subtitle: 'Reach out to us on WhatsApp',
        type: 'whatsapp',
        titleColor: '#01B460',
        color: '#01B460',
      },
      {
        id: 6,
        size: 'small',
        title: 'Invite and Earn',
        subtitle: 'Gifts for you and every beauty influencer that you invite',
        type: 'refer',
        titleColor: '#853DE0',
        color: '#853DE0',
      },
      {
        id: 7,
        size: 'small',
        title: 'Certification',
        subtitle: 'Earn certifications',
        type: 'certification',
        titleColor: '#2A8DFF',
        color: '#2A8DFF',
      },
    ];
    this.state = {
      profileData: {},
      isArtist: false,
      currentFilter: 'Latest',
      currentPage: 0,
      mediaObject: [],
      showLoader: false,
      showLoadMoreIndicator: false,
      referral_url: '',
      noMediaFoundFurther: false,
      inArtistSignupComplete: false,
      isInstagrammer: false,
      showVideoUploadModal: false,
      fetchingGift: false,
      giftName: '',
      giftImage: '',
      giftDescription: '',
      postData: this.props.lastVideoPostData || {},
      currentPostId: this.props.currentPostId || '',
      isBoostRequested: false,
      hideNewContentBanner: false,
      isRefreshing: false,
      isInstagramVerified: false,
      uploadingProcessBegan: false,
      showWarningModal: false,
      isExpertReviewRequested: false,
      uploadVideoModalSubheading: Platform.select({
        ios: 'Tap OK when prompted to select your video',
        android: 'Tap Allow when prompted to select your video',
        web: '',
      }),
      isArtistGiftScreenVisible: false,
      isUserRegisteredBefore: false,
      isUserAuthenticatedBefore: false,
      alertType: '',
      showAlert: false,
      alertText: '',
      firstButtonTitleInAlert: '',
      alertTitle: '',
      brandCollabList: [],
      loadingBrandCollabs: false,
      showSocialStatModal: false,
      socialStatModalContentText: '',
      statsRefreshing: false,
    };
    this.forGift = !(
      props.artistProfile && props.artistProfile.claimedWelcomeGift
    );
    this.sameStatModalAlreadyShown = false;
  }

  componentDidMount() {
    const {
      getMyProfileDetails,
      getLatestMedia,
      getPopularMedia,
      navigation,
      saveRefferalCode,
      getArtistContent,
      registered,
      authenticated,
      getCampaignData,
      artistProfile: { isArtist },
    } = this.props;

    const { currentFilter } = this.state;
    this.setState({ loadingBrandCollabs: true });

    this.fetchInitialUserData();
    getArtistContent(ARTIST_CONSTANTS.artistContentSlug);
    if (Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only) {
      Linking.addEventListener('url', this.handleDeepLinkUrl);
      DynamicLinkManager.navigateFunction = this.navigateToScreen;
      DynamicLinkManager.checkForInitialDynamicLink(this.navigateToScreen);
      DynamicLinkManager.subscribeDynamicLinks(
        this.navigateToScreen,
        this.extractInviteCode,
      );
      DynamicLinkManager.checkForInitialDeepLink(this.navigateToScreen);
    }
    this.backHandler = null;
    if (Platform.OS === 'android') {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }

    this.tapCount = 0;
    if (isArtist) {
      AnalyticsManager.setCurrentScreen(
        EventParameterValue.SCREEN_NAME.ARTIST_DASHBOARD,
      );
    } else {
      AnalyticsManager.setCurrentScreen(
        EventParameterValue.SCREEN_NAME.ARTIST_SIGNUP,
      );
    }
    // TODO: Add this for android as well
  }

  componentDidUpdate(prevProps) {
    const {
      artistProfile: { isArtist },
      artistGift,
      registered,
      authenticated,
      giftName,
    } = this.props;

    const { isUserRegisteredBefore, isUserAuthenticatedBefore } = this.state;

    if (Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only) {
      if (
        !isUserAuthenticatedBefore &&
        authenticated &&
        !isUserRegisteredBefore &&
        registered &&
        !isArtist
      ) {
        this.setState({
          isUserAuthenticatedBefore: true,
          isUserRegisteredBefore: true,
        });
        this.navigateToArtistType(PREVIOUS_SCREEN_SOURCES.artistOption);
      }
    }

    if (!prevProps.artistProfile.isArtist && isArtist) {
      this.fetchInitialUserData();
      this.hitArtistGiftAPI();
    }
  }

  componentWillUnmount() {
    if (Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only) {
      Linking.removeEventListener('url', this.handleDeepLinkUrl);
    }
    if (Platform.OS === 'android') {
      this.backHandler.remove();
    }
    if (!Utility.isBlank(DynamicLinkManager.unsubscribeDynamicLinks)) {
      DynamicLinkManager.unsubscribeDynamicLinks();
    }
  }

  fetchInitialUserData = (isFromRefresh = false) => {
    const {
      getMyProfileDetails,
      saveRefferalCode,
      saveArtistTypeLocal,
      getCampaignData,
    } = this.props;
    if (isFromRefresh) {
      this.setState({
        ...this.state,
        isRefreshing: true,
      });
    }

    getCampaignData({ pageNum: 0 }, (callbackData, error) => {
      this.setState({
        brandCollabList: callbackData,
        loadingBrandCollabs: false,
      });
    });

    getMyProfileDetails((userObject) => {
      const { mobile, youtubeProfile } = this.props;
      if (userObject) {
        this.setState({
          profileData: userObject.user,
          isArtist: userObject.user.is_artist,
          referral_url: userObject.user.referral_url,
          isBoostRequested:
            userObject.user.youtube_channel.boost_requested || false,
          isRefreshing: false,
          isInstagramVerified:
            userObject.user.instagram_profile.verified || false,
          isExpertReviewRequested:
            userObject.user.youtube_channel.expert_review_requested,
        });

        this.generateShortUrlFromInviteLink(userObject.user.referral_url);

        AnalyticsManager.logEvent(EventType.artistEvents.IS_ARTIST);
        AnalyticsManager.setUserProperty(
          'is_artist',
          `${userObject.user.is_artist}`,
        );
        AnalyticsManager.setUserProperty(
          'artist_level',
          `${userObject.user.artist_level}`,
        );
        AnalyticsManager.setUserProperty(
          'published_media_count',
          `${userObject.user.published_videos_count}`,
        );
        AnalyticsManager.setGeneralUserAttributes({
          name: `${userObject.user.profile.name}`,
          email: `${userObject.user.profile.email}`,
          contactNumber: `${mobile}`,
        });

        if (
          Utility.isBlank(userObject.user.youtube_channel.youtube_channel_id) &&
          !Utility.isBlank(youtubeProfile.youtube_channel_id)
        ) {
          this.getArtistYoutubeProfile();
        }

        saveArtistTypeLocal(userObject.user.artist.artist_type);
      } else {
        this.setState({
          refreshing: false,
        });
      }
      this.getAnalyticsDetails();
    });
  };

  refreshProfile = () => {
    this.fetchInitialUserData(true);
  };

  onPressUpdate = () => {
    const {
      updateArtistInstagramProfile,

      instagramProfile: { insta_user_name, follower_count },
    } = this.props;
    const currentInstagramFollowers = follower_count;
    this.sameStatModalAlreadyShown = false;
    this.setState({
      statsRefreshing: true,
    });
    this.getArtistYoutubeProfile();
    if (Utility.isPresent(insta_user_name)) {
      updateArtistInstagramProfile(insta_user_name, (response) => {
        if (response) {
          const {
            edge_followed_by: { count },
          } = response;

          if (
            count === currentInstagramFollowers &&
            !this.sameStatModalAlreadyShown
          ) {
            this.sameStatModalAlreadyShown = true;
            this.showModalOnSameSocialStats();
            this.setState({
              statsRefreshing: false,
            });
          }
        } else {
          this.setState({
            statsRefreshing: false,
          });
        }
      });
    }
  };

  onHardwareBackKeyPress = () => {
    // const {currentIndex} = this.state;
    // this.backPressed(this.componentArray[currentIndex]);
    this.setState({
      isArtistGiftScreenVisible: false,
    });
    this.tapCount++;

    if (this.tapCount > 1) {
      return false;
    }
    this.toast.show('Press back again to exit ');
    return true;
  };

  handleNotificationTap = (payload) => {
    console.log('payload is ', payload);
    if (Utility.isIOS()) {
      if (!Utility.isBlank(payload.app_extra.moe_deeplink)) {
        // TODO: Create notification handler for custom key value pair
        DynamicLinkManager.handleLinkWithoutInternalTrackingParams(
          payload.app_extra.moe_deeplink,
          this.navigateToScreen,
        );
      }
    }
  };

  hitArtistGiftAPI = () => {
    const {
      artistProfile: { isArtist },
      artistGift,
      getArtistGift,
    } = this.props;
    if (Utility.isBlank(artistGift) && isArtist) {
      getArtistGift((response) => {
        if (response) {
          this.setState({
            giftName: response.gift.name,
            giftImage: response.gift.image_url,
            giftDescription: response.gift.sub_text,
          });
        }
      });
    }
  };

  generateShortUrlFromInviteLink = (url) => {
    const { saveRefferalCode } = this.props;

    const getDynamicLink = DynamicLinkManager.getShortenUrl(url, {
      title: 'Foxy',
      description:
        'Foxy is a platform that connects beauty influencers and artists with top brands and savvy shoppers',
    });
    getDynamicLink
      .then((link) => {
        saveRefferalCode(link);
      })
      .catch((error) => {});
  };

  extractInviteCode = (slug) => {
    const { saveRefferalCode } = this.props;

    const code = slug;
    const lastIndexOfSlash = code.lastIndexOf('/');
    const extractCode = code.substring(lastIndexOfSlash + 1, code.length);
    saveRefferalCode(extractCode);
  };

  navigateToScreen = (route, slug) => {
    const { navigation } = this.props;
    navigation.navigate(route, {
      slug,
    });
  };

  handleDeepLinkUrl = (urlObj) => {
    if (!Utility.isBlank(urlObj)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        urlObj.url,
        this.navigateToScreen,
      );
    }
  };

  getAnalyticsDetails = () => {
    const { getUserSubscribers } = this.props;
    getUserSubscribers();
  };

  onArtistOnboardingFlowCompletion = (isInstagramSelected) => {
    this.setState({
      inArtistSignupComplete: true,
      isInstagrammer: isInstagramSelected,
      isArtist: true,
      fetchingGift: true,
    });
    const { getArtistGift } = this.props;
    getArtistGift((response) => {
      if (response) {
        this.setState({
          giftName: response.gift.name,
          giftImage: response.gift.image_url,
          giftDescription: response.gift.sub_text,
        });
      }
    });

    AnalyticsManager.logEvent(EventType.artistEvents.ARTIST_SIGNUP_END);
  };

  goToCamera = () => {
    // const { navigation } = this.props;
    // navigation.navigate('CameraContainer', {});
    this.setState({
      showVideoUploadModal: true,
    });
  };

  uploadVideoBannerTapped = () => {
    const { artistProfile, getArtistGift, instagramProfile, addresses } =
      this.props;
    if (Utility.isBlank(addresses)) {
      this.setState({
        showAlert: true,
        alertText: this.myProfileConstants.addAddressText,
        firstButtonTitleInAlert: 'Add Address',
        alertType: 'address',
        alertTitle: 'Add Address',
      });
    } else {
      if (!instagramProfile.verified) {
        this.setState({
          showInstaVerifyModal: true,
        });
        return;
      }
      if (!artistProfile.claimedWelcomeGift) {
        if (Utility.isBlank(this.state.giftName)) {
          getArtistGift((response) => {
            if (response) {
              this.setState({
                ...this.state,
                giftName: response.gift.name,
                giftImage: response.gift.image_url,
                giftDescription: response.gift.sub_text,
                isArtistGiftScreenVisible: true,
              });
            }
          });
        } else {
          this.setState({
            isArtistGiftScreenVisible: true,
          });
        }
      } else {
        this.goToCamera();
      }
    }
  };

  registerAsArtist = (click_source = '') => {
    const { registered, authenticated, authToken, setPhoneNumberModalInfo } =
      this.props;

    // if (Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.artist_only) {
    if (Utility.isBlank(authToken)) {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('DEFAULT'));
      NavigationService.renderOnboarding();
      AnalyticsManager.logEvent(EventType.onboardingEvent.LOGIN_INITIATE, {
        [EventParameterKey.SOURCE]:
          EventParameterValue.SOURCE.ARTIST_ONBOARDING,
      });
    } else {
      this.navigateToArtistType(click_source);
    }
    // } else if (Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.foxy) {
    //   this.navigateToArtistType(click_source);
    // }
  };

  navigateToArtistType = (click_source = '') => {
    const { navigation } = this.props;

    AnalyticsManager.logEvent(EventType.artistEvents.ARTIST_SIGNUP_START);
    navigation.navigate('ArtistType', {
      onArtistOnboardingFlowCompletion: this.onArtistOnboardingFlowCompletion,
      previousScreen: click_source,
    });
  };

  goToProfileEdit = () => {
    const { navigation } = this.props;
    navigation.navigate('ProfileEdit', {});
  };

  goToArtistType = () => {
    const { navigation } = this.props;
    navigation.navigate('ArtistType', {
      previousScreen: PREVIOUS_SCREEN_SOURCES.editProfessionalProfile,
    });
  };

  goToMyUploads = () => {
    const { navigation } = this.props;
    navigation.push('MyUploads', {
      uploadVideoFromProfile: this.uploadVideoFromProfile,
      openImagePicker: this.openImagePicker,
    });
  };

  goToBookingPage = () => {
    const { navigation } = this.props;
    navigation.navigate('GetBooking', {});
  };

  goToBrandCollab = () => {
    const { navigation } = this.props;
    navigation.navigate('BrandCollab', {
      createVideo: this.createVideoFile,
      showMyCollabs: true,
    });
  };

  showProfileCompletePopUp = () => {
    this.setState({
      showWarningModal: true,
    });
  };

  completeProfile = () => {
    const { artistProfile, navigation } = this.props;

    this.cancelModal();
    setTimeout(() => {
      navigation.navigate('ArtistType', {
        previousScreen: PREVIOUS_SCREEN_SOURCES.artistOption,
      });
    }, 200);
  };

  verifyInstagram = () => {
    const { navigation } = this.props;
    this.setState({
      showInstaVerifyModal: false,
    });
    navigation.navigate('InstagramAccountVerification', {});
  };

  onDashBoardCardPressed = (route) => {
    const {
      navigation,
      currentOptinStage,
      configs: { data = {} } = {},
    } = this.props;
    const talkToUsMessage = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.whatsapp_artistDashboard_talkToUs,
    );
    const { referral_url } = this.state;

    if (
      currentOptinStage === OPTIN_PROCESS_STAGES.INITIALLY_DISCARDED &&
      route !== 'BrandCollab' &&
      route !== 'whatsapp'
    ) {
      this.showProfileCompletePopUp();
    } else if (
      currentOptinStage === OPTIN_PROCESS_STAGES.INITIALLY_DISCARDED &&
      route === 'BrandCollab'
    ) {
      this.goToBrandCollab();
      this.logAnalyticsEvent(EventParameterValue.CARD_TITLE.BRAND_COLLABS);
    } else if (
      currentOptinStage === OPTIN_PROCESS_STAGES.INITIALLY_DISCARDED &&
      route === 'whatsapp'
    ) {
      Utility.openWhatsApp(talkToUsMessage);
    } else if (route === 'ReferAndEarn') {
      navigation.navigate(route, { inviteLink: referral_url });
      this.logAnalyticsEvent(EventParameterValue.CARD_TITLE.INVITE_AND_EARN);
    } else if (route === 'ComingSoon') {
      this.toast.show('Coming Soon');
    } else if (route === 'ContentForYou') {
      this.goToContentMorePage();
    } else if (route === 'myUpload') {
      this.goToMyUploads();
      this.logAnalyticsEvent(EventParameterValue.CARD_TITLE.MY_VIDEOS);
    } else if (route === 'booking') {
      this.goToBookingPage();
      this.logAnalyticsEvent(EventParameterValue.CARD_TITLE.GET_BOOKINGS);
    } else if (route === 'professionalProfile') {
      this.goToArtistType();
      this.logAnalyticsEvent(
        EventParameterValue.CARD_TITLE.MY_PROFESSIONAL_PROFILE,
      );
    } else if (route === 'BrandCollab') {
      this.goToBrandCollab();
      this.logAnalyticsEvent(EventParameterValue.CARD_TITLE.BRAND_COLLABS);
    } else if (route === 'whatsapp') {
      this.logAnalyticsEvent(EventParameterValue.CARD_TITLE.TALK_TO_US);

      Utility.openWhatsApp(talkToUsMessage);
    } else {
      navigation.navigate(route, {});
    }
  };

  logAnalyticsEvent = (cardTitle) => {
    AnalyticsManager.logEvent(EventType.artistEvents.DASHBOARD_CLICK, {
      [EventParameterKey.CARD_TITLE]: cardTitle,
    });
  };

  getImageUrl = (profileUrl) => {
    const { getProfilePicUploadUrl } = this.props;
    const imageName = Utility.extractImageName(profileUrl);
    this.setState({
      showProfilePicLoader: true,
    });
    getProfilePicUploadUrl(imageName, profileUrl, (data) => {
      this.setState({
        showProfilePicLoader: false,
      });
      if (data) {
        this.setState({
          profilePicUrl: profileUrl,
        });
      }
    });
  };

  completeProfileButton = () => (
    <TouchableOpacity
      style={styles.completeProfileButtonContainer}
      onPress={this.goToProfileEdit}
    >
      <Text
        allowFontScaling={false}
        style={[styles.userHandle, { color: colors.foxyPink }]}
      >
        Update Personal Info
      </Text>
      <Image source={images.myProfile.rightArow} style={styles.rightArrow} />
    </TouchableOpacity>
  );

  myProfileButton = () => {
    const { handle } = this.props;
    if (Utility.isBlank(handle)) {
      return null;
    }
    return (
      <TouchableOpacity
        style={styles.editProfileButton}
        onPress={this.goToProfileEdit}
      >
        <Text style={styles.editButtonText}>Edit</Text>
      </TouchableOpacity>
    );
  };

  profileCard = (props) => {
    const { mobileNumber } = this.props;
    const { name, handle } = props;

    const userName = Utility.isBlank(name) ? mobileNumber : name;
    const userHandle = Utility.isBlank(handle) ? '' : `@${handle}`;
    return (
      <View style={styles.profileCardContainer}>
        <ProfilePic />
        <View style={styles.profileCardNameContainer}>
          <View>
            <Text
              numberOfLines={1}
              allowFontScaling={false}
              style={styles.userName}
            >
              {userName}
            </Text>
            {Utility.isBlank(userHandle) ? (
              <this.completeProfileButton />
            ) : (
              <Text style={styles.userHandle}>{userHandle}</Text>
            )}
          </View>
        </View>
        <this.myProfileButton />
      </View>
    );
  };

  navigateToAnalytics = (title = '') => {
    const { navigation } = this.props;
    const { isBoostRequested, isInstagramVerified, isExpertReviewRequested } =
      this.state;

    // if (currentOptinStage === OPTIN_PROCESS_STAGES.INITIALLY_DISCARDED) {
    // this.showProfileCompletePopUp();
    // } else {
    navigation.navigate('Analytics', {
      previousScreen: SCREEN_CONSTANTS.MY_PROFILE,
      isBoostRequested,
      isInstagramVerified,
      selectedTabIndex: title,
      isExpertReviewRequested,
    });
    this.logAnalyticsEvent(
      title === 'YouTube'
        ? EventParameterValue.CARD_TITLE.YOUTUBE_ANALYTICS
        : EventParameterValue.CARD_TITLE.INSTAGRAM_ANALYTICS,
    );
    // }
  };

  navigateToSocial = (id, title) => {
    const { navigation } = this.props;
    // if (currentOptinStage === OPTIN_PROCESS_STAGES.INITIALLY_DISCARDED) {
    //   this.showProfileCompletePopUp();
    //   return;
    // }
    if (title == 'Instagram') {
      this.logAnalyticsEvent(
        EventParameterValue.CARD_TITLE.INSTAGRAM_ANALYTICS,
      );
      const { instagramProfile } = this.props;
      const { verified, insta_user_name } = instagramProfile;
      if (Utility.isBlank(insta_user_name) && !verified) {
        navigation.navigate('ArtistOnboardingContainer', {
          previousScreen: SCREEN_CONSTANTS.INSTAGRAM,
        });
        return;
      }
      if (!verified && !Utility.isBlank(insta_user_name)) {
        this.verifyInstagram();
        return;
      }
    }
    if (!Utility.isBlank(id)) {
      this.navigateToAnalytics(title);
    } else if (title === this.myProfileConstants.social.youtube) {
      this.logAnalyticsEvent(EventParameterValue.CARD_TITLE.YOUTUBE_ANALYTICS);
      if (title === 'YouTube') {
        navigation.navigate('ArtistOnboardingContainer', {
          previousScreen: SCREEN_CONSTANTS.YOUTUBE,
        });
      }
      // this.navigateToAnalytics(title);
    } else if (title === this.myProfileConstants.social.instagram) {
      this.navigateToAnalytics(title);
      // navigation.navigate('InstagramAccountVerification', { previousScreen: 'myProfile' });
    }
  };

  addAccountText = (props) => {
    const { color } = props;
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text
          allowFontScaling={false}
          style={[styles.followersCount, { color }]}
        >
          {'Add Account'}
        </Text>
      </View>
    );
  };

  verifyInstaText = (props) => {
    const { color } = props;
    const {
      instagramProfile: { verified, insta_user_name },
    } = this.props;
    let title = 'Verify Account';
    if (Utility.isBlank(verified) && Utility.isBlank(insta_user_name)) {
      title = 'Add Account';
    }
    // if (!Utility.isBlank(insta_verification_last_initiated_at)) {
    //   title = 'Retry Verification';
    // }
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text
          numberOfLines={1}
          allowFontScaling={false}
          style={[styles.followersCount, { color }]}
        >
          {title}
        </Text>
      </View>
    );
  };

  followerCountText = (props) => {
    const { color, followerCount } = props;
    return (
      <Text
        allowFontScaling={false}
        style={[styles.followersCount, { color }]}
      >
        {followerCount}
      </Text>
    );
  };

  showAddAccountCondition = (props) => Utility.isBlank(props.id);

  conditionalSocialText = withEither(
    this.showAddAccountCondition,
    this.addAccountText,
  )(this.followerCountText);

  socialStatRefreshButton = () => {
    const { statsRefreshing } = this.state;
    return (
      <TouchableWithoutFeedback onPress={this.onPressUpdate}>
        <View
          style={{
            position: 'absolute',
            right: 7,
            padding: 4,
            bottom: 3,
            borderRadius: 4,
            backgroundColor: colors.borderLight,
            borderWidth: 1,
            borderColor: colors.border,
            justifyContent: 'center',
            alignItems: 'center',
            width: 60,
          }}
        >
          {statsRefreshing && <ActivityIndicator size={'small'} />}
          {!statsRefreshing && (
            <Text
              style={{
                fontSize: size.h4,
                padding: 2,
                color: colors.foxyPink,
                fontFamily: 'Roboto-Medium',
              }}
            >
              Update
            </Text>
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  };

  socialStatsCard = (props) => {
    const {
      image,
      color,
      title,
      followers,
      iconImage,
      id,
      verified = false,
      type,
      showUpdateButton,
    } = props;

    let cardSubText = 'Get real-time insights';
    if (!Utility.isBlank(id)) {
      if (title === 'Instagram') {
        cardSubText = 'Followers';
      } else {
        cardSubText = 'Subscribers';
      }
    }
    const followerCount = followers === 0 ? '-' : followers;
    if (title === 'Instagram' && verified === false) {
      cardSubText = 'Link your Insta page';
    }
    return (
      <TouchableOpacity onPress={() => this.navigateToSocial(id, title)}>
        <ImageBackground source={image} style={styles.statsMiniContainer}>
          <View style={styles.dashboardStatsTextContainer}>
            <View style={styles.analyticsLogoContainer}>
              <Image source={iconImage} style={styles.analyticsLogo} />
              <Text style={styles.socialStatsTitle}>{title}</Text>
            </View>
            {title === 'Instagram' && verified === false ? (
              <this.verifyInstaText color={color} />
            ) : (
              <this.conditionalSocialText
                id={id}
                color={color}
                followerCount={followerCount}
                title={title}
                verified={verified}
                type={type}
              />
            )}
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Text allowFontScaling={false} style={styles.followersText}>
                {cardSubText}
              </Text>
              {showUpdateButton && verified && <this.socialStatRefreshButton />}
            </View>
          </View>
        </ImageBackground>
      </TouchableOpacity>
    );
  };

  statsComponent = (props) => {
    const {
      instagramFollowerCount,
      youtubeSubscriberCount,
      youtubeId,
      instagramUserName,
    } = props;
    const { instagramProfile } = this.props;
    return (
      // <>
      <ScaleAnimate onPress={() => this.onDashBoardCardPressed('')}>
        <CardView
          cardElevation={2.0}
          cardMaxElevation={2}
          cornerRadius={4}
          style={styles.statsCard}
        >
          <View style={styles.dashboardDivider} />
        </CardView>
      </ScaleAnimate>
      // </>
    );
  };

  dashboardComponent = (props) => {
    const { dashboardData, route } = props;
    const { artistProfile } = this.props;
    const { profileData } = this.state;
    const width = Utility.getDynamicWidth(2, 12);
    let subtitle = dashboardData.subtitle;

    let extraStyle = {};
    if (route === 'myUpload') {
      subtitle = profileData.uploaded_videos_count;
      extraStyle = {
        fontSize: size.h1,
        color: colors.foxyPink,
        marginTop: 6,
      };
    } else if (route === 'BrandCollab') {
      subtitle = profileData.campaigns_count;
      extraStyle = {
        fontSize: size.h1,
        color: colors.foxyPink,
        marginTop: 6,
      };
    }
    return (
      <ScaleAnimate onPress={() => this.onDashBoardCardPressed(route)}>
        <CardView
          cardElevation={2.0}
          cardMaxElevation={2}
          cornerRadius={4}
          style={[styles.dashboardCard, { width }]}
        >
          <ImageBackground
            source={images.dashboard[dashboardData.type]}
            style={styles.dashboardCardImage}
            resizeMode="cover"
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text
                style={[
                  styles.dashboardCardTitle,
                  { color: dashboardData.titleColor },
                ]}
                allowFontScaling={false}
              >
                {dashboardData.title}
              </Text>
              <Image
                source={images.myProfile.rightArow}
                style={[
                  styles.rightArrow,
                  {
                    tintColor: dashboardData.color,
                    marginBottom: -6,
                  },
                ]}
              />
            </View>
            <Text
              numberOfLines={2}
              style={[
                styles.dashboardCardSubtitle,
                { color: dashboardData.color },
                extraStyle,
              ]}
            >
              {subtitle}
            </Text>
          </ImageBackground>
          {/* <Image source={Images[img]} style={styles[`${size}ImagePosition`]} /> */}
        </CardView>
      </ScaleAnimate>
    );
  };

  dashboard = (props) => {
    const {
      dashboardData,
      instagramFollowerCount,
      youtubeSubscriberCount,
      youtubeId,
      instagramUserName,
    } = props;

    return (
      <View style={{ margin: 6, marginTop: 0 }}>
        <View style={{ flexDirection: 'row' }}>
          <this.statsComponent
            dashboardData={dashboardData[0]}
            instagramFollowerCount={instagramFollowerCount}
            youtubeSubscriberCount={youtubeSubscriberCount}
            youtubeId={youtubeId}
            instagramUserName={instagramUserName}
          />
          <View>
            <this.dashboardComponent
              dashboardData={dashboardData[1]}
              route="myUpload"
            />
            <this.dashboardComponent
              dashboardData={dashboardData[2]}
              route="BrandCollab"
            />
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <this.dashboardComponent
            dashboardData={dashboardData[3]}
            route="ReferAndEarn"
          />
          <this.dashboardComponent
            dashboardData={dashboardData[4]}
            route="whatsapp"
          />
        </View>
        {/* <View style={{ flexDirection: 'row' }}>
          <this.dashboardComponent
            dashboardData={dashboardData[5]}
            route='ReferAndEarn'
          /> */}
        {/* <this.dashboardComponent dashboardData={dashboardData[6]} route="certification" /> */}
        {/* </View> */}
      </View>
    );
  };

  editAddressCondition = () =>
    this.state.showAddAddressScreen === true || this.state.addressToEdit !== '';

  claimMyGift = () => {
    this.setState({
      inArtistSignupComplete: false,
      isArtistGiftScreenVisible: false,
    });

    setTimeout(() => {
      this.checkPermissionForImagePicker();
    }, 500);
  };

  onCloseBottomDialog = () => {
    this.setState({
      showVideoUploadModal: false,
    });
  };

  createPostAndNavigate = (videoUrl) => {
    this.createVideoFile(videoUrl, '', '');
  };

  uploadVideoFromProfile = (currentPostId, postData) => {
    this.setState({
      postData,
      currentPostId,
      hideNewContentBanner: true,
    });
  };

  createVideoFile = (videoUrl, campaignId, campaignName) => {
    const { navigation, addPostToLocalState, artistProfile } = this.props;
    const { claimedWelcomeGift } = artistProfile;
    if (claimedWelcomeGift) {
      this.forGift = false;
    } else {
      this.forGift = true;
    }
    const postId = uuidv1();
    const videoTitle = '';
    const postData = {
      id: postId,
      localId: postId,
      category: 'video',
      postCacheDirectoryPath: '',
      uploadType: 'FoxyVideo',
      items: [
        {
          mimeType: 'video/mp4',
          uuid: uuidv5(videoUrl, uuidv5.URL),
          uri: videoUrl,
          mediaUriAbsolutePath: Utility.isAndroid()
            ? `file://${videoUrl}`
            : videoUrl,
          mediaUriExternalDirectoryPath: '',
          mediaBase64Uri: '',
          thumbnail: '',
          height: Utility.getScreenHeight(),
          width: Utility.getScreenWidth(),
          videoLength: 0,
          mediaType: 'video',
          caption: videoTitle,
          recentProductSearchID: '',
          products: [],
          videoFrames: [],
          productsTag: [],
          campaignId,
        },
      ],
      postTitle: videoTitle,
      postDescription: '',
      postThumbnail: '',
      postCategory: 'video',
      hashTags: [],
      mentions: [],
      editingMode: false,
      campaignId,
    };
    addPostToLocalState(postData, (isAllowedToNavigate) => {
      if (isAllowedToNavigate) {
        navigation.navigate('UploadVideo', {
          previousScreen: SCREEN_CONSTANTS.MY_PROFILE,
          uploadVideoFromProfile: this.uploadVideoFromProfile,
          campaignId,
          forGift: this.forGift,
          campaignName,
        });
      }
    });
    this.setState({
      uploadingProcessBegan: false,
    });
  };

  openImagePicker = () => {
    const { navigation, instagramProfile } = this.props;
    if (!instagramProfile.verified) {
      this.setState({
        showInstaVerifyModal: true,
      });
      return;
    }
    const options = {
      title: 'Select Avatar',
      mediaType: 'video',
      storageOptions: {
        skipBackup: true,
        path: 'videos',
      },
    };

    Permissions.request('photo').then((response) => {
      if (response === 'authorized') {
        ImagePicker.launchImageLibrary(options, (response) => {
          AnalyticsManager.logEvent(EventType.artistEvents.GALLERY_OPEN, {});
          if (response.error) {
            return;
          }
          if (!response.didCancel) {
            this.setState({
              ...this.state,
              showVideoUploadModal: false,
              uploadingProcessBegan: true,
            });
            this.createPostAndNavigate(response.path || response.uri);
          } else {
            this.setState({
              ...this.state,
              showVideoUploadModal: false,
            });
          }
        });
      }
    });

    if (Utility.isIOS()) {
      Permissions.check('photo').then((response) => {
        if (response === 'denied') {
          Alert.alert(
            'Permission Required',
            'Change Access permission inside Settings to allow photo access',
            [
              {
                text: 'Open Settings',
                onPress: () => Linking.openURL('app-settings:'),
              },
              {
                text: 'Cancel',
                onPress: () => navigation.goBack(),
                style: 'cancel',
              },
            ],
          );
        }
      });
    }
  };

  checkPermissionForImagePicker = () => {
    this.openImagePicker();
  };

  lockScreenToPortrait = (payload) => {
    if (isNative()) {
      // Orientation.lockToPortrait();
    }
  };

  showActivityIndicator = () => (
    <View
      style={{
        zIndex: 100,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <ActivityIndicator size="large" color="black" />
    </View>
  );

  cancelModal = () => {
    this.setState({
      ...this.state,
      showWarningModal: false,
      showInstaVerifyModal: false,
    });
  };

  //  firstButtonOnPress={this.completeProfile}
  //         secondButtonOnPress={this.cancelModal}

  getAddress = () => {
    const { navigation } = this.props;
    navigation.navigate('Address', {
      previousScreen: SCREEN_CONSTANTS.MY_PROFILE,
    });
    this.cancelAlert();
  };

  cancelAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  navigateToBrandCollabList = () => {
    const { navigation } = this.props;
    navigation.navigate('BrandCollab', {
      previousScreen: SCREEN_CONSTANTS.ARTIST_PROPOSITION_QUICK_VIEW,
    });
  };

  getArtistYoutubeProfile() {
    const {
      getSelectedYoutubeChannelIdForProfile,
      getGoogleProfileWithToken,
      saveGoogleData,
      saveYoutubeRefreshToken,
      youtubeProfile,
      youtubeProfile: { subscriber_count },
    } = this.props;

    Utility.refreshAccessToken(youtubeProfile.refreshToken)
      .then((gResult) => {
        let googleResult = gResult;
        googleResult.refreshToken =
          googleResult.refreshToken || youtubeProfile.refreshToken;

        getSelectedYoutubeChannelIdForProfile(
          googleResult.tokenType,
          googleResult.accessToken,
          (result) => {
            getGoogleProfileWithToken(googleResult.accessToken, (profile) => {
              let bannerImageUrl = '';
              let featuredChannelsUrls = [];
              if (
                result.items[0].brandingSettings &&
                result.items[0].brandingSettings.image
              ) {
                bannerImageUrl =
                  result.items[0].brandingSettings.image.bannerImageUrl;
              }
              if (
                result.items[0].brandingSettings &&
                result.items[0].brandingSettings.channel &&
                result.items[0].brandingSettings.channel.featuredChannelsUrls &&
                result.items[0].brandingSettings.channel.featuredChannelsUrls
                  .length > 0
              ) {
                featuredChannelsUrls =
                  result.items[0].brandingSettings.channel.featuredChannelsUrls;
              }
              const youtubeChannelObject = {
                youtube_channel_id: result.items[0].id,
                title: result.items[0].snippet.title,
                description: result.items[0].snippet.description,
                customurl: '',
                view_count: result.items[0].statistics.viewCount,
                subscriber_count: result.items[0].statistics.subscriberCount,
                video_count: result.items[0].statistics.videoCount,
                image_thumbnail_url:
                  result.items[0].snippet.thumbnails.high.url,
                country: '',
                banner_image_url: bannerImageUrl,
                featured_channels_urls: featuredChannelsUrls,
              };
              const googleProfile = {
                email: profile.emails[0].value,
                google_id: profile.id,
                user_name: profile.displayName,
                profile_pic: profile.image.url,
              };
              const dataObject = {
                ...googleProfile,
                ...googleResult,
              };

              saveGoogleData(dataObject, youtubeChannelObject, (success) => {
                if (success) {
                  console.log('youtube data successfully saved');
                }
              });
              const refreshToken = `${googleResult.refreshToken}`;
              const accessToken = `${googleResult.tokenType} ${googleResult.accessToken}`;
              const accessTokenExpirationDate = `${googleResult.accessTokenExpirationDate}`;
              saveYoutubeRefreshToken(
                refreshToken,
                accessToken,
                accessTokenExpirationDate,
              );
            });

            if (
              subscriber_count === youtubeChannelObject.subscriber_count &&
              !this.sameStatModalAlreadyShown
            ) {
              this.sameStatModalAlreadyShown = true;
              this.showModalOnSameSocialStats();
            }
          },
        );
      })
      .catch((err) => {});
  }

  showSocialStatAlert = (show) => {
    this.setState({ showSocialStatModal: show });
  };

  showModalOnSameSocialStats = () => {
    const {
      configs: { data },
    } = this.props;

    let modalMessage =
      'Participate in Foxy Brand Collabs now to increase your follower count';
    if (data && data.text_modal_participate_in_collabs) {
      modalMessage = data.text_modal_participate_in_collabs;
    }
    this.setState({
      socialStatModalContentText: modalMessage,
    });

    this.showSocialStatAlert(true);
  };

  render() {
    const {
      mobile,
      name,
      handle,
      youtubeProfile,
      instagramProfile,
      profile,
      artistProfile,
      isTermsAccepted,
      acceptTerms,
      currentOptinStage,
      artistGift,
      configs: { data },
    } = this.props;

    const {
      isArtist,
      inArtistSignupComplete,
      isInstagrammer,
      showVideoUploadModal,
      giftName,
      giftDescription,
      giftImage,
      isUploading,
      currentPostId,
      postData,
      isRefreshing,
      uploadingProcessBegan,
      showWarningModal,
      isArtistGiftScreenVisible,
      showInstaVerifyModal,
      uploadVideoModalSubheading,
      showAlert,
      alertTitle,
      alertText,
      firstButtonTitleInAlert,
      socialStatModalContentText,
      showSocialStatModal,
    } = this.state;

    const youtubeSubscriberCount =
      youtubeProfile.subscriber_count === '0'
        ? '-'
        : youtubeProfile.subscriber_count;
    const instagramFollowersCount =
      instagramProfile.follower_count === '0'
        ? '-'
        : instagramProfile.follower_count;

    let user_name = '';
    let user_handle = '';
    let profile_pic = '';
    if (Utility.isBlank(profile.handle)) {
      if (!Utility.isBlank(instagramProfile.insta_user_name)) {
        user_handle = `@${instagramProfile.insta_user_name}`;
      }
    } else {
      user_handle = `@${profile.handle}`;
    }

    if (Utility.isBlank(profile.name)) {
      if (!Utility.isBlank(instagramProfile.insta_full_name)) {
        user_name = instagramProfile.insta_full_name;
      } else {
        user_name = mobile;
      }
    } else {
      user_name = profile.name;
    }

    if (Utility.isBlank(profile.image_url)) {
      if (!Utility.isBlank(instagramProfile.insta_profile_picture_url)) {
        profile_pic = { uri: instagramProfile.insta_profile_picture_url };
      } else {
        profile_pic = images.profileEmptyState;
      }
    } else {
      profile_pic = { uri: instagramProfile.insta_profile_picture_url };
    }

    return (
      <View
        style={{
          flex: 1,
        }}
      >
        {/* <NavigationEvents onDidFocus={this.lockScreenToPortrait} /> */}
        {(artistProfile.isArtist ||
          currentOptinStage === OPTIN_PROCESS_STAGES.INITIALLY_DISCARDED) && (
          <ScrollView
            style={styles.scrollview}
            scrollEventThrottle={16}
            refreshControl={
              <RefreshControl
                refreshing={isRefreshing}
                onRefresh={this.refreshProfile}
              />
            }
          >
            {/* <this.profileCard name={name} handle={handle} /> */}
            <View style={{ height: 2 }}></View>
            <UploadVideoBanner
              currentPostId={currentPostId}
              postData={postData}
              artistProfile={artistProfile}
              forGift={this.forGift}
              previousScreen={SCREEN_CONSTANTS.MY_PROFILE}
            />

            <this.dashboard
              dashboardData={this.dashboardData}
              instagramFollowerCount={instagramFollowersCount}
              youtubeSubscriberCount={youtubeSubscriberCount}
              youtubeId={youtubeProfile.youtube_channel_id}
              instagramUserName={instagramProfile.insta_user_name}
            />
            <View style={styles.spacingForNotch} />
          </ScrollView>
        )}

        <FoxyAlert
          isVisible={showWarningModal}
          onTapOutside={this.onTapOutSideAlert}
          alertBoxTitle="Complete Signup"
          alertMessage="Resume signup to access Foxy Pro-tools and features"
          firstButtonTitle="Resume"
          secondButtonTitle="Not now"
          firstButtonOnPress={this.completeProfile}
          secondButtonOnPress={this.cancelModal}
          autoWrapContent
        />
        <FoxyAlert
          isVisible={showInstaVerifyModal}
          onTapOutside={this.onTapOutSideAlert}
          alertBoxTitle="Verify Instagram"
          alertMessage="You need to verify your Instagram account to continue"
          firstButtonTitle="Verify Now"
          secondButtonTitle="Later"
          firstButtonOnPress={this.verifyInstagram}
          secondButtonOnPress={this.cancelModal}
          autoWrapContent
        />
        <FoxyAlert
          isVisible={showAlert}
          alertBoxTitle={alertTitle}
          alertMessage={alertText}
          firstButtonTitle={firstButtonTitleInAlert}
          firstButtonTextColor={colors.dodgerBluePressed}
          secondButtonTitle="Not Now"
          firstButtonOnPress={this.getAddress}
          secondButtonOnPress={this.cancelAlert}
          textAlignment="left"
          autoWrapContent
        />

        <FoxyAlert
          isVisible={showSocialStatModal}
          alertBoxTitle={'Numbers not changing?'}
          alertMessage={socialStatModalContentText}
          firstButtonTitle="Ok"
          firstButtonTextColor={colors.dodgerBluePressed}
          hideSecondButton
          firstButtonOnPress={() => this.showSocialStatAlert(false)}
          textAlignment="left"
          autoWrapContent
        />

        <Toast
          ref={(ref) => {
            this.toast = ref;
          }}
        />
        {uploadingProcessBegan && <this.showActivityIndicator />}
      </View>
    );
  }
}

MyProfile.propTypes = {
  artistProfile: PropTypes.shape({
    isArtist: PropTypes.bool,
    claimedWelcomeGift: PropTypes.bool,
  }),
  authenticated: PropTypes.bool,
  registered: PropTypes.bool,
  mobile: PropTypes.string,
  mobileNumber: PropTypes.string,
  name: PropTypes.string,
  handle: PropTypes.string,
  youtubeProfile: PropTypes.shape({}),
  instagramProfile: PropTypes.shape({}),
  profile: PropTypes.shape({}),
  isTermsAccepted: PropTypes.bool,
  acceptTerms: PropTypes.func,
  currentOptinStage: PropTypes.string,
  artistGift: PropTypes.shape({
    image_Url: PropTypes.string,
  }),
  configs: PropTypes.shape({
    data: PropTypes.object,
  }),
  itemData: PropTypes.object,
  navigation: PropTypes.any.isRequired,
  getArtistContent: PropTypes.func.isRequired,
  getMyProfileDetails: PropTypes.func,
  getArtistGift: PropTypes.func,
  saveRefferalCode: PropTypes.func,
  addPostToLocalState: PropTypes.func,
};

MyProfile.defaultProps = {
  artistProfile: {},
  authenticated: false,
  registered: false,
  mobile: '',
  mobileNumber: '',
  name: '',
  handle: '',
  youtubeProfile: {},
  instagramProfile: {},
  profile: {},
  isTermsAccepted: false,
  acceptTerms: () => {},
  currentOptinStage: '',
  artistGift: {},
  configs: {},
  itemData: {},
  getArtistGift: () => {},
  getMyProfileDetails: () => {},
  saveRefferalCode: () => {},
  addPostToLocalState: () => {},
};

const mapStateToProps = (state) => {
  const itemData = denormalize(
    state.data.entities.lists[ARTIST_CONSTANTS.artistContentSlug],
    listSchema,
    state.data.entities,
  );
  return {
    // showCongratulationsModal: state.UserAccountInfo.artistOnboarding.showCongratulationsModal,
    showCongratulationsModal: false,
    mobile: state.UserAccountInfo.mobileNumber,
    imageUrl: state.UserAccountInfo.profile.image_url,
    name: state.UserAccountInfo.profile.name,
    handle: state.UserAccountInfo.profile.handle,
    youtubeAnalyticsObject: state.UserAccountInfo.profileAnalytics.youtube,
    instagramAnalyticsObject: state.UserAccountInfo.profileAnalytics.instagram,
    instagramProfile: state.UserAccountInfo.instagram_profile,
    youtubeProfile: state.UserAccountInfo.youtube_profile,
    mobileNumber: state.UserAccountInfo.mobileNumber,
    profile: state.UserAccountInfo.profile,
    artistProfile: state.UserAccountInfo.artistProfile,
    isUploading: state.UserAccountInfo.isUploading,
    isTermsAccepted: state.UserAccountInfo.termsAccepted,
    itemData,
    lastOptinIndex: state.UserAccountInfo.lastOptinIndex,
    currentOptinStage: state.UserAccountInfo.currentOptinStage,
    artistGift: state.UserAccountInfo.artistGift,
    registered: state.UserAccountInfo.registered,
    authenticated: state.UserAccountInfo.authenticated,
    addresses: state.UserAccountInfo.addresses,
    authToken: state.UserAccountInfo.authToken,
    campaigns: state.UserAccountInfo.campaigns,
    currentPostId: state.UserAccountInfo.currentPostId,
    lastVideoPostData:
      state.UserAccountInfo.lastVideoUpload &&
      state.UserAccountInfo.lastVideoUpload.postData
        ? state.UserAccountInfo.lastVideoUpload.postData
        : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      artistOnboardingCompleted,
      logoutUser,
      getMyProfileDetails,
      getLatestMedia,
      getArtistInstagramProfile,
      getUserSubscribers,
      saveRefferalCode,
      getArtistGift,
      getArtistContent,
      saveArtistTypeLocal,
      addPostToLocalState,
      acceptTerms,
      getSelectedYoutubeChannelIdForProfile,
      saveGoogleData,
      getGoogleProfileWithToken,
      setPhoneNumberModalInfo,
      getCampaignData,
      saveYoutubeRefreshToken,
      updateArtistInstagramProfile,
    },

    dispatch,
  ),
});

export default withNavigation(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withNavigation(
      withTabNavigationHeader(MyProfile, { type: TABBAR_CONSTANT.myProfile }),
    ),
  ),
);
