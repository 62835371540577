import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CountDown from '../../helpers/Counter';
import withNavigation from '../../utils/WithNavigation';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import RoutinesUtility from './RoutinesUtility';
import {
  incrementDecrementCart,
  incrementDecrementCartBeforeCall,
  createRoutineParticipation,
} from '../../actions/ActionTypes';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import _ from 'lodash';
import ScaleAnimate from '../shared/ScaleAnimate';
import FoxyAlert from '../camera/shared/FoxyAlert';
import animations from '../../theme/Animations';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { LOGIN_MODAL_MESSAGES } from '../../config/Constants';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import NavigationService from '../../navigator/NavigationService';
const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

const imageHeight = (Utility.getScreenWidth() - 24) * (118 / 350);

class RoutineListWithDescriptionCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isVisible: false,
      itemData: {},
    };
    this.debouncedButtonClick = _.debounce(this.onButtonClick, 800, {
      leading: true,
      trailing: false,
    });
    this.debouncedNavigation = _.debounce(this.navidateToDetailPage, 800, {
      leading: true,
      trailing: false,
    });
    const {
      itemData: { routine = {} },
    } = this.props;
    try {
      new JSONAPIDeserializer({
        typeAsAttribute: false,
        pluralizeType: true,
        keyForAttribute: 'camelCase',
      })
        .deserialize(routine)
        .then((data) => {
          const { startsAt = '', endsAt = '' } = data;
          this.isLive = RoutinesUtility.isLive(startsAt, endsAt);
          this.setState({ itemData: data });
        });
    } catch (e) {}
  }

  countdown = (props) => {
    const { startsAt } = props;

    if (Utility.isBlank(startsAt)) {
      return null;
    }
    const startDate = RoutinesUtility.getLocalTimeFromUTC(startsAt);
    const currentDate = new Date();
    if (currentDate > startDate) {
      return null;
    }
    const timeDiff = startDate.getTime() - currentDate.getTime();
    const timeDiffInHours = timeDiff / (1000 * 60 * 60);
    if (timeDiffInHours > 24) {
      return null;
    }
    return (
      <View style={styles.countdownContainer}>
        <Text style={styles.countdownText}>Live in</Text>
        <CountDown
          size={12}
          until={timeDiff / 1000}
          //   onFinish={refresh}
          digitStyle={styles.digitStyle}
          digitTxtStyle={styles.digitTxtStyle}
          timeLabelStyle={styles.timeLabelStyle}
          separatorStyle={styles.separatorStyle}
          timeToShow={['H', 'M', 'S']}
          timeLabels={{ h: 'hrs', m: 'min', s: 'sec' }}
          showSeparator
        />
      </View>
    );
  };

  subscribeRoutine = () => {
    const { createRoutineParticipation } = this.props;
    const {
      itemData: { slug = '' },
    } = this.state;

    this.setState({ isLoading: true });

    createRoutineParticipation(
      { routine_participation: { routine_slug: slug } },
      this.subscribeRoutineCallback,
    );
  };

  subscribeRoutineCallback = (success, data) => {
    this.setState({ isLoading: false });
    if (success) {
      const { name = '', mrp = '', id = '' } = this.state.itemData;
      const routineMeta = {
        [EventParameterKey.ROUTINES.ROUTINE_NAME]: name,
        [EventParameterKey.ROUTINES.ROUTINE_PRICE]: mrp,
        [EventParameterKey.SOURCE]: 'list_with_description_card',
        [EventParameterKey.ROUTINES.ROUTINE_ID]: id,
      };
      AnalyticsManager.logEvent(
        EventType.routine.ROUTINE_SUBSCRIBE,
        routineMeta,
      );
      this.setState({ isVisible: true });
    }
  };

  closeModal = () => {
    this.setState({
      isVisible: false,
    });
  };

  viewAllRoutines = () => {
    const { navigation } = this.props;
    this.setState({
      isVisible: false,
    });
    setTimeout(() => {
      navigation.push('Routines');
    }, 0);
  };

  onButtonClick = () => {
    const { listData: { additional_data = {} } = {}, membership_cohort } =
      this.props;

    const {
      itemData: {
        id,
        skuId,
        name = '',
        slug = '',
        isUserSubscribed = false,
        endsAt,
      },
    } = this.state;

    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.ROUTINES_PAGE,
      [EventParameterKey.ID]: id,
      [EventParameterKey.NAME]: name,
      [EventParameterKey.SLUG]: slug,
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.ROUTINES.MEMBERSHIP_COHORT]: membership_cohort,
    });

    this.debouncedNavigation();
  };

  invokeLoginFlow = () => {
    const { setPhoneNumberModalInfo } = this.props;
    setPhoneNumberModalInfo(Utility.getLoginModalInfo('DEFAULT'));
    NavigationService.renderOnboarding();
  };

  navidateToDetailPage = () => {
    const { navigation } = this.props;
    const {
      itemData: {
        slug = '',
        skuId = '',
        startsAt = '',
        isUserSubscribed = false,
        endsAt = '',
      } = {},
    } = this.state;
    const isExpired = new Date() > RoutinesUtility.getLocalTimeFromUTC(endsAt);
    if (isUserSubscribed && isExpired) {
      this.navigateToRoutineSchedule(false);
      return;
    }
    const isActive = RoutinesUtility.getLocalTimeFromUTC(startsAt) < new Date();
    if (isUserSubscribed && isActive) {
      this.navigateToRoutineSchedule(true);
      return;
    }
    navigation.push('PreRoutineDetails', {
      slug: slug,
      source: 'navigation_header',
      sku_id: skuId,
    });
  };

  navigateToRoutineSchedule = (isRoutineActive) => {
    const { navigation } = this.props;
    const { itemData: { slug = '' } = {} } = this.state;
    navigation.navigate('DuringRoutineDetails', {
      slug: slug,
      isRoutineActive: isRoutineActive,
    });
  };

  onRoutineAddToCart = (usn, source = '') => {
    const {
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      navigation,
      cartItems = [],
      previousScreen = '',
    } = this.props;
    const {
      itemData: { artist = {}, skuId = '' },
    } = this.state;
    const isPresentInCart = cartItems.find((item) => item?.sku_id === skuId);
    if (isPresentInCart) {
      navigation.navigate('Cart', {
        source: previousScreen,
      });
      return;
    }
    const data = {
      sku_id: skuId,
      quantity: 1,
      source: 'cart',
    };
    this.setState({
      isLoading: true,
    });

    incrementDecrementCartBeforeCall(data);
    incrementDecrementCart(data, (response) => {
      if (response?.errors?.length > 0) {
        // this.toast.show(response.errors[0]);
        return;
      }
      this.setState({
        isLoading: false,
      });

      navigation.navigate('Cart', {
        source: previousScreen,
      });

      // if (source === 'product_view_modal') {
      //   this.onCloseProductModal();
      // }
    });
  };

  actionButtonImage = ({ isLive, isUserSubscribed }) => {
    const { isLoading = false } = this.state;
    if (this.isLive) {
      return <View style={styles.liveIndicator} />;
    }

    if (isLoading) {
      return (
        <ActivityIndicator
          animating
          color={colors.white}
          size='small'
          style={styles.inviteIcon}
        />
      );
    }
    return (
      <FastImageView
        source={images.routines.invite_icon}
        style={styles.inviteIcon}
      />
    );
  };

  viewDetails = (props) => {
    const { isUserSubscribed = false } = props;
    const { itemData: { endsAt = '' } = {} } = this.state;

    return (
      <TouchableOpacity
        style={styles.viewDetailsContainer}
        onPress={this.debouncedButtonClick}
      >
        <this.actionButtonImage isUserSubscribed={isUserSubscribed} />
        <Text style={styles.viewDetailsText}>View Details</Text>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      itemData: {
        name,
        description,
        cardImageUrl: cardImage,
        startsAt = '',
        endsAt = '',
        artist = {},
        isUserSubscribed = false,
        pictureUrls = {},
      },
    } = this.state;
    const { bigBanner: bannerImageUrl = '' } = pictureUrls;

    const { isVisible } = this.state;

    const { name: artistName, imageUrl } = artist;
    const isLive = RoutinesUtility.isLive(startsAt, endsAt);
    const artistImage = Utility.isBlank(imageUrl)
      ? images.routines.artist_placeholder
      : imageUrl;

    const dateString = RoutinesUtility.getStartDateString(
      startsAt,
      endsAt,
      isLive,
    );

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedButtonClick}>
        <View style={styles.container}>
          <FastImageView
            source={Utility.getMinifiedImage(bannerImageUrl)}
            style={styles.imageContainer}
          />
          <View style={styles.detailsContainer}>
            {<this.countdown endsAt={endsAt} startsAt={startsAt} />}
            <View style={styles.textContainer}>
              <Text style={styles.title}>{name}</Text>
              <Text style={styles.body}>{description}</Text>
              <View style={styles.artistContainer}>
                <FastImageView
                  source={Utility.getMinifiedImage(artistImage, 24, 24)}
                  style={styles.artistImage}
                />
                <Text style={styles.body}>{artistName}</Text>
              </View>
              <View style={styles.statusContainer}>
                <Text style={styles.statusText}>{dateString}</Text>
                <this.viewDetails
                  isUserSubscribed={isUserSubscribed}
                  isLive={isLive}
                />
              </View>
            </View>
          </View>
          {this.isLive && (
            <View style={styles.liveContainer}>
              <Text style={styles.liveText}>LIVE</Text>
            </View>
          )}
        </View>
        <FoxyAlert
          isVisible={isVisible}
          hideSecondButton
          alertBoxTitle={'CONGRATS'}
          alertMessage={'Routine subscribed successfully'}
          firstButtonTitle={'View my routine'}
          firstButtonOnPress={this.viewAllRoutines}
          onTapOutside={this.viewAllRoutines}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          showJson
          animation={animations.tick_animation}
          animationStyle={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginBottom: 12,
          }}
        />
      </ScaleAnimate>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      createRoutineParticipation,
      setPhoneNumberModalInfo,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
  membership_cohort: state.todayDeals.membership_cohort,
  authToken: state.UserAccountInfo.authToken,
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(RoutineListWithDescriptionCard),
);

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    marginLeft: 12,
    marginRight: 12,
    flexDirection: 'column',
    resizeMode: 'contain',
    justifyContent: 'space-evenly',
    marginBottom: 6,
    marginTop: 6,
  },
  imageContainer: {
    width: '100%',
    height: imageHeight,
    resizeMode: 'contain',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  detailsContainer: {
    // position: 'absolute',
    bottom: 0,
    backgroundColor: colors.white,
    width: '100%',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
  body: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
  },
  textContainer: {
    padding: 12,
    alignItems: 'flex-start',
  },
  artistContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 8,
  },
  artistImage: {
    width: 24,
    height: 24,
    borderRadius: 12,
    marginRight: 8,
    marginTop: 4,
    resizeMode: 'cover',
  },
  statusContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12,
    width: '100%',
  },
  statusText: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
  },
  viewDetailsContainer: {
    paddingVertical: 8,
    width: 156,
    height: 32,
    justifyContent: 'center',
    backgroundColor: colors.black,
    borderRadius: 18,
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewDetailsText: {
    color: colors.white,
    fontSize: size.h4,
    fontFamily: 'Roboto-Medium',
  },
  liveIndicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: colors.routine_card_pink,
    marginRight: 8,
  },
  inviteIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  liveContainer: {
    backgroundColor: colors.routine_card_pink,
    borderRadius: 2,
    paddingHorizontal: 12,
    paddingVertical: 2,
    marginLeft: 6,
    position: 'absolute',
    left: 4,
    top: 12,
  },
  liveText: {
    color: colors.white,
    fontSize: size.h4_5,
    fontFamily: 'Roboto-Medium',
  },
  countdownContainer: {
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 8,
    paddingVertical: 2,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.dark_translucent,
  },
  countdownText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  digitTxtStyle: {
    color: colors.white,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    marginTop: 0,
    marginLeft: 2.1,
  },
  separatorStyle: {
    color: colors.black,
    marginBottom: 8,
  },
});
