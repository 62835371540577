import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
  Linking,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { styles as FeatureStyles } from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import {
  AnalyticsUtilty,
  EventType,
  EventParameterKey,
  EventParameterValue,
  AnalyticsManager,
} from '../../analytics';
import colors from '../../theme/Colors';
import PrepaidOrderCountDown from '../orders/PrepaidOrderCountDown';
import CountDown from '../../helpers/Counter';
import InViewPort from '../../utils/InViewPort';
import { connect } from 'react-redux';
import HTMLView from 'react-native-htmlview';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
class OfferFeatureCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }
  text = (props) => {
    const { text, style } = props;
    if (Utility.isBlank(text)) {
      return null;
    }
    return <Text style={style}>{text}</Text>;
  };

  header = (props) => {
    const { iconSource, title, isDismissable } = props;
    return (
      <View style={FeatureStyles.row}>
        <Image
          source={{
            uri: Utility.getSmallImageUrl(iconSource, 30, 30),
          }}
          style={{ height: 24, width: 24 }}
        ></Image>
        <this.text text={title} style={FeatureStyles.headerText} />
        <this.crossButton />
      </View>
    );
  };

  buttonContainer = (props) => {
    return (
      <TouchableWithoutFeedback
        style={FeatureStyles.buttonContainer}
        onPress={this.onPressFollowButton}
      >
        <View style={FeatureStyles.buttonContainer}>
          <this.text style={FeatureStyles.followButtonText} text='Follow' />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  onButtonPress = () => {
    this.props.navigation.push('FreeProductScreen');
  };

  onPressFollowButton = () => {
    const {
      itemData,
      listData,
      index = 0,
      listIndex,
      previousScreen = '',
      itemData: { id = '', title = '', type = '', slug = '' } = {},
      listData: {
        id: listId = '',
        name: listName = '',
        display: listDisplay = '',
        content: listContent = '',
        slug: listSlug = '',
      } = {},
    } = this.props;
    if (itemData.destination.includes('youtube')) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        id,
        type,
        title,
        index,
        listId,
        listDisplay,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
      );
    } else if (itemData.destination.includes('instagram')) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.id,
        itemData.type,
        itemData.title,
        0,
        listData.id,
        listData.display,
        listData.name,
        listData.display_order,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
      );
    }

    if (itemData.destination.includes('contacts')) {
      this.removeCard();
    } else {
      Linking.openURL(itemData.destination)
        .then((response) => {
          this.removeCard();
        })
        .catch((error) => {});
    }
  };

  checkVisible = (visible) => {
    const { checkVisible } = this.props;
    if (typeof checkVisible === 'function') {
      checkVisible(visible);
    }
  };

  removeCard = () => {
    const { removeFollowCard, itemData } = this.props;
    if (itemData.destination.includes('youtube')) {
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.DYNAMIC_CARD_DISMISS,
        {
          [EventParameterKey.TYPE]: EventParameterValue.MEDIUM.YOUTUBE,
        },
      );
    } else if (itemData.destination.includes('instagram')) {
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.DYNAMIC_CARD_DISMISS,
        {
          [EventParameterKey.TYPE]: EventParameterValue.MEDIUM.INSTAGRAM,
        },
      );
    }
    removeFollowCard(itemData.id);
    Utility.currentActiveFollowCard.id = undefined;
  };

  crossButton = () => {
    const { itemData } = this.props;
    if (!itemData.dismissable) {
      return null;
    }
    return (
      <TouchableWithoutFeedback
        onPress={this.removeCard}
        onPressIn={this.removeCard}
        hitSlop={Utility.getHitSlop()}
        style={{
          position: 'absolute',
          top: 10,
          right: 12,
          width: 22,
          height: 22,
        }}
      >
        <View
          style={{
            position: 'absolute',

            right: 3,
            width: 22,
            height: 22,
          }}
        >
          <Image
            source={images.cameraScreenIcons.popupClose}
            style={{ height: 22, width: 22 }}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  render() {
    const { itemData, itemData: { arguments: { timer_text = '' } = {} } = {} } =
      this.props;
    if (Utility.isBlank(itemData)) {
      return null;
    }
    if (Utility.isBlank(timer_text)) {
      return null;
    }
    const {
      itemData: {
        body,
        cta_text,
        title,
        image_url,
        arguments: { timer_interval = '', sub_body = '' },
      } = {},
      isCardDesign,
      isSearchDesign,
      checkVisible,
      cartOffers,
      userCreatedAt,
      showFreeProductScreen,
    } = this.props;

    if (Utility.isBlank(userCreatedAt)) {
      return null;
    }

    if (
      Utility.isPresent(cartOffers) &&
      !cartOffers.new_user_offer_applicable
    ) {
      return null;
    }

    let tnc =
      Utility.isPresent(itemData.offer) && Utility.isPresent(itemData.offer.tnc)
        ? itemData.offer.tnc
        : '';

    const title1 = title.split(' ')[0];
    const title2 = title.split(' ')[1];

    if (isSearchDesign) {
      return (
        <TouchableOpacity
          style={styles.searchFeatureCardContainer}
          onPress={this.onButtonPress}
        >
          <LinearGradient
            colors={['#EFF7FF', '#EFF7FF']}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 1 }}
            style={styles.searchCard}
          >
            <View
              style={{
                flexDirection: 'row',
                height: '100%',
              }}
            >
              <LinearGradient
                colors={['#CFE5F8', '#EFF7FF']}
                start={{ x: 0, y: 1 }}
                end={{ x: 1, y: 1 }}
                style={styles.searchContentContainer}
              >
                <View style={styles.searchLeftBlock}>
                  <View style={styles.searchTextContainer}>
                    <Text style={styles.searchFirstTitle}>{title1}</Text>
                    <Text style={styles.searchSecondTitle}>{title2}</Text>
                  </View>
                  <View>
                    <Text style={styles.searchSubBodyText}>{sub_body}</Text>
                  </View>
                  <View>
                    <CountDown
                      size={10}
                      until={Utility.calculateTimeDifference(
                        userCreatedAt,
                        timer_interval,
                      )}
                      endsAt={timer_interval}
                      // onFinish={() => alert('Finished')}
                      digitStyle={{
                        backgroundColor: '#000',
                        borderRadius: 2,
                      }}
                      digitTxtStyle={{
                        color: '#fff',
                        fontSize: 14,
                        fontFamily: 'Roboto-Regular',
                      }}
                      timeLabelStyle={{ color: 'red', fontWeight: 'bold' }}
                      separatorStyle={{ color: '#000000', marginVertical: 2 }}
                      timeToShow={['H', 'M', 'S']}
                      timeLabels={{ m: null, s: null }}
                      showSeparator
                    />
                  </View>
                </View>
              </LinearGradient>
              <Image
                source={{
                  uri: Utility.getMinifiedImage(image_url, 300, 300),
                }}
                style={styles.searchImageContainer}
              />
            </View>
          </LinearGradient>
        </TouchableOpacity>
      );
    }

    if (isCardDesign) {
      return (
        <TouchableOpacity
          style={{
            flexDirection: 'column',
            paddingLeft: 3,
            elevation: 3,
          }}
          onPress={this.onButtonPress}
        >
          <LinearGradient
            colors={['#EFF7FF', '#EFF7FF']}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 1 }}
            style={styles.productDetailFeatureCardContainer}
          >
            <View style={styles.productDetailFeatureCard}>
              <LinearGradient
                colors={['#CFE5F8', '#EFF7FF']}
                start={{ x: 0, y: 1 }}
                end={{ x: 1, y: 1 }}
                style={styles.productDetailContentContainer}
              >
                <View style={styles.productDetailLeft}>
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.productDetailFirstText}>{title1}</Text>
                    <Text style={styles.productDetailSecondText}>{title2}</Text>
                  </View>
                  <View
                    style={{
                      width: '100%',
                    }}
                  >
                    <Text style={styles.productDetailSubBody}>{sub_body}</Text>
                  </View>
                  <View>
                    <CountDown
                      size={10}
                      until={Utility.calculateTimeDifference(
                        userCreatedAt,
                        timer_interval,
                      )}
                      endsAt={timer_interval}
                      // onFinish={() => alert('Finished')}
                      digitStyle={{
                        backgroundColor: '#000',
                        marginLeft: 0,
                        borderRadius: 2,
                      }}
                      digitTxtStyle={{
                        color: '#fff',
                        fontSize: 14,
                      }}
                      timeLabelStyle={{ color: 'red', fontWeight: 'bold' }}
                      separatorStyle={{ color: '#000000' }}
                      timeToShow={['H', 'M', 'S']}
                      timeLabels={{ m: null, s: null }}
                      showSeparator
                    />
                  </View>
                </View>
              </LinearGradient>
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Image
                  source={{
                    uri: Utility.getMinifiedImage(image_url, 120, 80),
                  }}
                  style={styles.productDetailImageContainer}
                />
              </View>
            </View>
          </LinearGradient>
        </TouchableOpacity>
      );
    }

    if (this.props.inFreeProducts) {
      return (
        <View style={styles.freeProductScreenContainer}>
          <View style={styles.freeProductScreenCard}>
            <View style={styles.freeProductContentContainer}>
              <View style={styles.freeProductLeftBlock}>
                <View
                  style={{
                    flexDirection: 'row',
                    overflow: 'visible',
                  }}
                >
                  <Text style={styles.freeProductScreenFirstText}>
                    {title1}
                  </Text>
                  <Text style={styles.freeProductScreenSecondText}>
                    {title2}
                  </Text>
                </View>

                <View style={styles.offerEndsInContainer}>
                  <Text style={{ color: colors.foxyBlack }}>{timer_text}</Text>

                  <CountDown
                    size={14}
                    until={Utility.calculateTimeDifference(
                      userCreatedAt,
                      timer_interval,
                    )}
                    endsAt={timer_interval}
                    // onFinish={() => alert('Finished')}
                    digitStyle={{
                      backgroundColor: '#FFF',
                      marginLeft: 0,
                    }}
                    digitTxtStyle={{
                      color: '#000000',
                      fontSize: 16,
                      fontFamily: 'Roboto-Regular',
                    }}
                    timeLabelStyle={{ color: 'red', fontWeight: 'bold' }}
                    separatorStyle={{ color: '#000000' }}
                    timeToShow={['H', 'M', 'S']}
                    timeLabels={{ m: null, s: null }}
                    showSeparator
                  />
                </View>
                <View
                  style={{
                    width: '90%',
                  }}
                >
                  <HTMLView
                    style={{
                      borderRadius: 4,
                      overFlow: 'hidden',
                    }}
                    value={`<div>${tnc}</div>`}
                    stylesheet={{
                      a: {
                        color: colors.foxyPink,
                      },
                      p: { color: colors.foxyBlack, fontSize: 12 },
                      li: { color: colors.foxyBlack, fontSize: 12 },
                      ol: { color: colors.foxyBlack, fontSize: 12 },
                      ul: { color: colors.foxyBlack, fontSize: 12 },
                      div: { color: colors.foxyBlack },
                    }}
                  />
                </View>
              </View>
            </View>
            <View style={styles.freeProductScreenImageContainer}>
              <Image
                source={{
                  uri: Utility.getMinifiedImage(image_url, 300, 300),
                }}
                style={styles.freeProductScreenImage}
              />
            </View>
          </View>
        </View>
      );
    }

    return (
      <TouchableWithoutFeedback onPress={this.onButtonPress}>
        <View style={styles.mainFeatureCardContainer}>
          <View style={styles.freeProductScreenCard}>
            <View style={styles.freeProductContentContainer}>
              <View style={styles.mainFeatureCardLeftBlock}>
                <View
                  style={{
                    flexDirection: 'row',
                    overflow: 'visible',
                  }}
                >
                  <Text style={styles.freeProductScreenFirstText}>
                    {title1}
                  </Text>
                  <Text style={styles.freeProductScreenSecondText}>
                    {title2}
                  </Text>
                </View>
                <View
                  style={{
                    width: '60%',
                  }}
                >
                  <Text style={styles.freeProductScreenBody}>{body}</Text>
                </View>
                <View
                  style={{
                    width: '80%',
                  }}
                >
                  <Text style={{ color: colors.foxyBlack }}>{timer_text}</Text>
                  <InViewPort onChange={this.checkVisible}>
                    <CountDown
                      size={14}
                      until={Utility.calculateTimeDifference(
                        userCreatedAt,
                        timer_interval,
                      )}
                      endsAt={timer_interval}
                      digitStyle={{
                        backgroundColor: '#FFF',
                        marginLeft: 0,
                      }}
                      digitTxtStyle={{
                        color: '#000000',
                        fontSize: 16,
                        fontFamily: 'Roboto-Regular',
                      }}
                      timeLabelStyle={{ color: 'red', fontWeight: 'bold' }}
                      separatorStyle={{ color: '#000000' }}
                      timeToShow={['H', 'M', 'S']}
                      timeLabels={{ m: null, s: null }}
                      showSeparator
                    />
                  </InViewPort>
                </View>

                <View style={styles.viewProductButtonContainer}>
                  <Text
                    style={{
                      fontSize: 15,
                      color: colors.white,
                      alignSelf: 'center',
                      fontFamily: 'Roboto-Bold',
                    }}
                  >
                    {cta_text}
                  </Text>
                  <Image
                    source={images.whiteRightArrow}
                    style={styles.rightArrowContainer}
                  />
                </View>
              </View>
            </View>
            <View style={styles.mainImageContainer}>
              <Image
                source={{
                  uri: Utility.getMinifiedImage(image_url, 300, 300),
                }}
                style={styles.mainImage}
              />
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  searchFeatureCardContainer: {
    flexDirection: 'column',
    paddingLeft: 3,
    marginHorizontal: 7,
    elevation: 3,
  },
  searchCard: {
    width: '100%',
    height: 117,
    flex: 1,
    marginTop: 8,
    overflow: 'hidden',
    backgroundColor: colors.white,
    marginHorizontal: 20,
    alignSelf: 'center',
    borderRadius: 5,
  },
  searchContentContainer: {
    flex: 2,
    backgroundColor: colors.white,
    height: '100%',
    borderRadius: 2,
  },
  searchLeftBlock: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    paddingLeft: 16,
    paddingVertical: 8,
  },
  searchTextContainer: {
    flexDirection: 'row',
    overflow: 'visible',
  },
  searchFirstTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 26,
    color: colors.foxyBlack,
  },
  searchSecondTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 26,
    color: '#9E3B52',
  },
  searchSubBodyText: {
    fontSize: 14,

    color: '#173143',
    opacity: 0.7,
    fontFamily: 'Roboto-Regular',
  },
  searchImageContainer: {
    width: 100,
    height: 117,
    position: 'absolute',
    right: 0,
    bottom: 2,
    resizeMode: 'contain',
  },
  productDetailFeatureCardContainer: {
    width: 280,
    height: 40,
    flex: 1,
    // backgroundColor: '#FFDFDF',
    borderRadius: 4,
    margin: 5,
    elevation: 3,
  },
  productDetailFeatureCard: {
    flexDirection: 'row',
    height: '100%',
    flex: 1,
  },
  productDetailContentContainer: {
    flex: 2,
    // backgroundColor: '#FFDFDF',
    height: '100%',
    width: '100%',
  },
  productDetailLeft: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    width: '100%',
    paddingLeft: 12,
    paddingVertical: 8,
  },
  productDetailFirstText: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
  },
  productDetailSecondText: {
    fontSize: 18,
    color: '#9E3B52',
    fontFamily: 'Roboto-Bold',
  },
  productDetailSubBody: {
    fontSize: 12,

    color: '#173143',
    width: '100%',
  },
  productDetailImageContainer: {
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  freeProductScreenContainer: {
    width: '100%',
    height: 200,
    flex: 1,
    marginTop: 8,
    marginBottom: 8,
    overflow: 'hidden',
    backgroundColor: colors.background,
  },
  freeProductScreenCard: {
    flexDirection: 'row',
    height: '100%',
    paddingHorizontal: 20,
    marginRight: 10,
  },
  freeProductContentContainer: {
    flex: 2,
    backgroundColor: colors.background,
    height: '100%',
  },
  freeProductLeftBlock: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
  },
  freeProductScreenFirstText: {
    fontSize: 30,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
  },
  freeProductScreenSecondText: {
    fontSize: 30,
    color: '#9E3B52',
    fontFamily: 'Roboto-Bold',
  },
  freeProductScreenBody: {
    fontSize: 14,

    color: '#173143',
  },
  offerEndsInContainer: {
    width: '80%',
  },
  freeProductScreenImageContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: 'visible',
  },
  freeProductScreenImage: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flex: 1,
    alignSelf: 'center',
    width: Utility.getScreenWidth() / 2.5,
    height: '100%',
    overflow: 'visible',
  },
  mainFeatureCardContainer: {
    width: '100%',
    height: Utility.getScreenHeight() / 3,
    marginTop: 8,
    overflow: 'hidden',
    backgroundColor: colors.background,
  },
  mainFeatureCardLeftBlock: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    paddingBottom: 30,
  },
  viewProductButtonContainer: {
    backgroundColor: colors.black,
    width: '70%',
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    flexDirection: 'row',
    padding: 4,
  },
  rightArrowContainer: {
    width: 17.5,
    height: 17.5,
    resizeMode: 'contain',
    marginLeft: 5,
  },
  mainImageContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: 'visible',
  },
  mainImage: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flex: 1,
    alignSelf: 'center',
    width: Utility.getScreenWidth() / 2.5,
    height: '100%',
    overflow: 'visible',
  },
});

function mapStateToProps(state) {
  return {
    cartOffers: state.cart.cartOffers,
    userCreatedAt: state.UserAccountInfo.app_installed_at,
  };
}

export default withNavigation(connect(mapStateToProps, null)(OfferFeatureCard));
