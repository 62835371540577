import { put, takeLatest } from 'redux-saga/effects';
import {
  STORE_DATA_RECEIVED,
  FETCH_STORE,
  UPDATE_LIST_DATA,
  MERGE_LIST_DATA,
  SAVE_BAG,
} from '../actions/ActionTypes';

import { API_DOMAIN, STORE_URL, URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';

import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import { EXIT_STORE_MODE } from '../actions/RetailStoreActions';

export function* fetchStoreAsync(action) {
  const { callback } = action;
  try {
    const response = yield fetch(`${STORE_URL}?show_special_offer=true`, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    // FIXME: Need to add timer here
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
      // yield put({ type: STORE_DATA_RECEIVED, data: json });
      // yield put({ type: UPDATE_LIST_DATA, data: json });
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

function* exitStoreMode() {
  try {
    const response = yield fetch(`${URL.REMOVE_ALL_USNS}`, {
      method: 'POST',
      headers: yield getApiHeaders(),
    });

    // No need to store data, Because once you exit store mode. App hits cart items api again.
  } catch (error) {
    console.log(`StoreSaga:L-50 ${error}`);
  }
}

// Our watcher Saga:
export default function* watchFetchStore() {
  yield takeLatest(FETCH_STORE, fetchStoreAsync);
  yield takeLatest(EXIT_STORE_MODE, exitStoreMode);
}
