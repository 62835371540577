import {
  createNavigationContainerRef,
  CommonActions,
  StackActions,
} from '@react-navigation/native';
import Utility from '../utils/Utility';
import { getAllTabsRoutes } from '../utils/NavigationUtility';

export const navigationRef = createNavigationContainerRef();

// function setTopLevelNavigator(navigatorRef) {
//   console.log('setting navigator: ', navigatorRef);
//   navigator = navigatorRef || {};
// }

function push(routeName, params) {
  if (Utility.isBlank(navigationRef)) return;
  if (getAllTabsRoutes().includes(routeName)) {
    navigationRef.dispatch(
      StackActions.push('TabNavigator', {
        screen: routeName,
        params: params,
      }),
    );
  } else {
    navigationRef.dispatch(StackActions.push(routeName, params));
  }
}

function replace(routeName, params) {
  if (Utility.isBlank(navigationRef)) return;
  navigationRef.dispatch(StackActions.replace(routeName, params));
}

function navigate(routeName, params) {
  if (Utility.isBlank(navigationRef)) return;
  navigationRef.dispatch(
    CommonActions.navigate({
      name: routeName,
      params,
    }),
  );
}

function renderOnboarding(params = {}) {
  if (Utility.isBlank(navigationRef)) return;
  navigationRef.dispatch(
    CommonActions.navigate({
      name: 'Onboarding',
      params,
    }),
  );
}

function goBack() {
  if (Utility.isBlank(navigationRef)) return;
  navigationRef.goBack();
}

function deeplinkHandledNavigation(routeName, params = {}) {
  if (Utility.isBlank(navigationRef)) return;
  const currentRouteOptions = navigationRef.getCurrentOptions() || {};
  const { presentation } = currentRouteOptions;
  // in case the route to be pushed is not in the stack and the top most route is a modal.
  if (presentation === 'transparentModal') {
    goBack();
  }
  // in case the route to be pushed is already in the stack but has a different slug.
  const currentRoute = navigationRef.getCurrentRoute() || {};
  const { params: { slug: currentRouteSlug = '' } = {} } = currentRoute;
  const { slug: toBePushedRouteSlug = '' } = params;
  if (
    currentRoute.name === routeName &&
    currentRouteSlug !== toBePushedRouteSlug
  ) {
    push(routeName, params);
  } else {
    navigate(routeName, params);
  }
}

function unmountOnboarding() {
  if (Utility.isBlank(navigationRef)) return;
  const currentRoute = navigationRef.getCurrentRoute() || {};
  if (currentRoute.name === 'Onboarding') {
    goBack();
  }
}

export default {
  push,
  replace,
  navigationRef,
  navigate,
  renderOnboarding,
  goBack,
  deeplinkHandledNavigation,
  unmountOnboarding,
};
