import React, { Component } from 'react';
import {
  View,
  Text,
  BackHandler,
  Alert,
  ActivityIndicator,
  NativeModules,
  FlatList,
  Linking,
  StyleSheet,
} from 'react-native';
import Config from '../../../libraries/ReactNativeConfig';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPaymentCurrentStatus,
  sendPaymentRequest,
} from '../../../actions/PaymentActions';
import {
  getCartItems,
  isAppReviewSubmitted,
  saveLocalNotificationData,
  setCartNotification,
} from '../../../actions/ActionTypes';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import {
  PaymentFail,
  PaymentSuccess,
  PaymentValidate,
  HoldOn,
  PaymentVerifying,
  OpenIntent,
  Address,
  DeliveryStatus,
  CartPricing,
  FailureActions,
  ValidationActions,
  CartProducts,
  IntentPaymentVerifying,
} from './PaymentStatusElements';
import StaticNavigationHeader from '../../shared/StaticNavigationHeader';
import colors from '../../../theme/Colors';
import NotificationManager from '../../../utils/NotificationsManager';
import {
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../../analytics';
import { RNPayuPayment } from '../../../lib/RNPayuPayment';
import NetworkStats from '../../../utils/NetworkStat';
import {
  APP_STORE_LINK,
  CART_NOTIFICATION_STATES,
  NOTIFICATION_ACTIONS,
  NOTIFICATION_IDENTIFIERS,
  NOTIFICATION_TYPE,
  ORDER_PAGE_URL,
  PAYMENT_METHODS_URL,
  PLAY_STORE_LINK,
  TRACKING_PARAMS,
} from '../../../config/Constants';
import AppReviewModal from '../../modals/AppReviewModal';
import images from '../../../theme/Images';
import { setCongratsModalForEdge } from '../../../actions/FoxyEdgeAction';
import checkoutWithRazorpay from '../RazorpayCheckout';
import AppConfig from '../../../config/AppConfig';

export class PaymentsWaiting extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  headers = {
    initiated: 'Processing Your Order',
    pending: 'Processing Your Order',
    success: 'Order confirmation',
  };

  component = {
    waiting: PaymentVerifying,
    waiting_actions: OpenIntent,
    validation: HoldOn,
    success: PaymentSuccess,
    failure: PaymentFail,
    validation_failure: PaymentValidate,
    failure_actions: FailureActions,
    intent_waiting: IntentPaymentVerifying,
    // retry: Address,
    address: Address,
    pricing: CartPricing,
    products: CartProducts,
    delivery_status: DeliveryStatus,
    validation_actions: ValidationActions,
  };

  sectionHeader = {
    address: 'Shipping Address',
    pricing: 'Payment Details',
    products: 'Products in this Purchase',
    delivery_status: 'Arriving',
  };

  constructor(props) {
    super(props);
    const { navigation, route } = this.props;
    this.paymentStatus = route.params?.paymentStatus ?? {};
    this.orderPayload = route.params?.orderPayload ?? {};
    this.extraData = route.params?.extraData ?? {};
    this.paymentMethodDetails = route.params?.paymentMethodDetails ?? {};
    this.status = null;
    this.state = {
      paymentCurrentStatus: this.paymentStatus,
      timerEnd: false,
      header: 'Processing Your Order',
      showLoader: false,
      showAppReviewModal: false,
    };
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.currentRating = -1;
    this.razorpayEnabled = Config.PAYMENT_GATEWAY === 'RazorPay';
    if (this.paymentStatus?.payment_method === 'blinkbyemtel') {
      this.razorpayEnabled = false;
    }

    const { cartItems, cartPricing, UtoP: upgradeToPrepaid } = this.props;
    if (this.razorpayEnabled) {
      checkoutWithRazorpay(
        this.paymentStatus,
        this.extraData,
        this.paymentMethodDetails,
        this.cancelPayment,
        this.orderPayload,
        cartItems,
        cartPricing,
        upgradeToPrepaid,
      );
    }
  }

  componentDidMount() {
    const { method, save = true } = this.extraData;
    const { cartPricing, UtoP, cartItems } = this.props;
    const meta = {
      extraData: this.extraData,
      cartItems,
      UtoP,
      payuParams: this.paymentStatus,
      cartPricing,
      networkInfo: NetworkStats.networkDetails,
    };

    this.startPaymentStatusWatcher();
    if (method === 'upi') {
      AnalyticsUtilty.firePaymentInitiateUpi(meta);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { method, save = true } = this.extraData;
    const { saveLocalNotificationData } = this.props;
    const {
      cartPricing,
      UtoP,
      cartItems,
      isContactLess,
      optForPlasticFreePackaging,
      authToken,
      openInAppReview,
      setCongratsModalForEdge,
      membership_cohort,
      setCartNotification,
    } = this.props;
    const meta = {
      extraData: this.extraData,
      cartItems,
      UtoP,
      payuParams: this.paymentStatus,
      cartPricing,
      networkInfo: NetworkStats.networkDetails,
    };

    const { paymentCurrentStatus } = this.state;
    const {
      paymentMethod,
      paymentMethodPayload: { virtual_bank_account_id = '' } = {},
    } = this.orderPayload;
    const {
      txn_status: currentTxnStatus = '', // pending, success, failure
      txn_state: currentTxnState = '', // pending, in progress, money deducted, retry payment, timeout, success, failure
      txn_amount = '',
      txn_id = '',
      order_id = '',
    } = paymentCurrentStatus;
    const metaUpi = {
      orderResponse: { order_id },
      cartPricing,
      isContactLess,
      optForPlasticFreePackaging,
      cartItems,
      authToken,
      payuParams: { txn_id },
      membershipCohort: membership_cohort,
    };
    const numberOfCartItems = cartItems.length;
    const { txn_status: prevTxnStatus = '', txn_state: prevTxnState = '' } =
      prevState.paymentCurrentStatus;
    if (prevTxnStatus !== 'failure' && currentTxnStatus === 'failure') {
      const link = Utility.appendUtmParamsWithUrl(
        PAYMENT_METHODS_URL,
        TRACKING_PARAMS.NOTIFICATION.PAYMENT_FAILURE,
      );
      const payload = {
        title: 'Oh no! your payment failed :(',
        description: `We are usually bearing good news. But sadly your order of ${numberOfCartItems} item(s) worth INR ${txn_amount} was not processed. Don't worry we have your cart saved `,
        deepLink: link,
        primary_cta: NOTIFICATION_ACTIONS.TRY_AGAIN.cta,
        primary_destination: link,
        secondary_cta: NOTIFICATION_ACTIONS.DISMISS.cta,
        secondary_destination: NOTIFICATION_ACTIONS.DISMISS.action,
        type: NOTIFICATION_TYPE.PAYMENT_FAILED,
        isLocal: true,
        receivedTime: new Date(),
        isClicked: false,
      };
      setCartNotification(CART_NOTIFICATION_STATES.paymentDone);
      saveLocalNotificationData(payload);

      /**
       * FIXME: As of now we dont want to show this in android.
       * Remove this if block once IOS live activities are live
       */
      if (Utility.isIOS()) {
        NotificationManager.createNotification(
          payload,
          NOTIFICATION_IDENTIFIERS.PAYMENT_FAILED,
        );
      }
      AnalyticsUtilty.firePaymentFailure(meta);

      clearInterval(this.status);
    }

    if (prevTxnStatus !== 'success' && currentTxnStatus === 'success') {
      const link = Utility.appendUtmParamsWithUrl(
        ORDER_PAGE_URL,
        TRACKING_PARAMS.NOTIFICATION.PAYMENT_SUCCESS,
      );

      setCartNotification(CART_NOTIFICATION_STATES.paymentDone);

      AnalyticsUtilty.firePaymentSuccess(meta);

      /**
       * As per discussed with Aman, this is the right place where we get confirmation of upi order success
       * */
      this.openInAppReview();

      const loyaltyPlan = cartItems.filter(
        (cartItem) => cartItem?.loyalty_plan?.type === 'loyalty_plan',
      );
      if (Utility.isPresent(loyaltyPlan)) {
        setCongratsModalForEdge(true);
      }

      try {
        AnalyticsUtilty.fireOrderConfirmUpi(metaUpi);
      } catch (e) {}

      clearInterval(this.status);
    }
    if (
      prevTxnState !== 'validation_failure' &&
      currentTxnState === 'validation_failure'
    ) {
      AnalyticsUtilty.firePaymentFailure(meta);

      const link = Utility.appendUtmParamsWithUrl(
        PAYMENT_METHODS_URL,
        TRACKING_PARAMS.NOTIFICATION.PAYMENT_FAILURE,
      );

      const payload = {
        title: 'Oh no! your payment failed :(',
        description: `We are usually bearing good news. But sadly your order of ${numberOfCartItems} item(s) worth INR ${txn_amount} was not processed. Don't worry we have your cart saved `,
        deepLink: link,
        primary_cta: NOTIFICATION_ACTIONS.TRY_AGAIN.cta,
        primary_destination: link,
        secondary_cta: NOTIFICATION_ACTIONS.DISMISS.cta,
        secondary_destination: NOTIFICATION_ACTIONS.DISMISS.action,
        type: NOTIFICATION_TYPE.PAYMENT_FAILED,
      };

      /**
       * FIXME: As of now we dont want to show this in android.
       * Remove this if block once IOS live activities are live
       */
      if (Utility.isIOS()) {
        NotificationManager.createNotification(
          payload,
          NOTIFICATION_IDENTIFIERS.PAYMENT_FAILED,
        );
      }
      clearInterval(this.status);
    }
    if (prevTxnStatus !== 'pending' && currentTxnStatus === 'pending') {
      if (Utility.isAndroid()) {
        const payload = {
          title: `${Config.APP_NAME} Order Update`,
          description: `Your payment for order ${numberOfCartItems} item(s) worth INR ${txn_amount} is in progress`,
          primary_cta: NOTIFICATION_ACTIONS.CHECK_STATUS.cta,
          primary_destination: NOTIFICATION_ACTIONS.CHECK_STATUS.action,
          secondary_cta: NOTIFICATION_ACTIONS.DISMISS.cta,
          secondary_destination: NOTIFICATION_ACTIONS.DISMISS.action,
          type: NOTIFICATION_TYPE.PAYMENT_IN_PROGESS,
        };
        /**
         * FIXME: As of now we dont want to show this in android.
         * Remove this if block once IOS live activities are live
         */
        if (Utility.isIOS()) {
          NotificationManager.createNotification(
            payload,
            NOTIFICATION_IDENTIFIERS.PAYMENT_IN_PROGESS,
          );
        }
      }
    }
    const payment_method = paymentMethod === 'intent' ? 'intent' : 'upi';
    if (prevTxnStatus !== currentTxnStatus) {
      AnalyticsManager.logEvent(EventType.payment.PAYMENT_RESPONSE, {
        [EventParameterKey.TYPE]: currentTxnStatus,
        [EventParameterKey.PAYMENT_STATE]: currentTxnState,
        [EventParameterKey.PAYMENT_METHOD]: payment_method,
        [EventParameterKey.PAYMENT_ACCOUNT]: virtual_bank_account_id,
        [EventParameterKey.TXN_ID]: txn_id,
        [EventParameterKey.OFFER_ID]: order_id,
        [EventParameterKey.PAYMENT_VALUE]: txn_amount,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.status);
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  cancelPayment = () => {
    this.changePaymentstate('timeout');
  };

  stopHeadless = () => {
    clearTimeout(this.backgroundNotificationCountdownTimer);
    NotificationManager.stopHeadless();
  };

  clearCart = () => {
    const { getCartItems } = this.props;
    getCartItems(() => {});
  };

  clearTimer = () => {
    clearInterval(this.status);
  };

  changePaymentstate = (action) => {
    const {
      paymentCurrentStatus: {
        txn_id: txnId = '',
        order_id,
        txn_amount,
        txn_status: currentTxnStatus = '', // pending, success, failure
        txn_state: currentTxnState = '',
      },
    } = this.state;
    const {
      paymentMethod,
      paymentMethodPayload: { virtual_bank_account_id = '' } = {},
    } = this.orderPayload;
    const { navigation, getPaymentCurrentStatus } = this.props;
    this.showLoader(true);
    clearInterval(this.status);

    this.startPaymentStatusWatcher();

    AnalyticsManager.logEvent(EventType.payment.PAYMENT_ACTION, {
      [EventParameterKey.TYPE]: action,
      [EventParameterKey.PAYMENT_STATE]: currentTxnState,
      [EventParameterKey.PAYMENT_METHOD]:
        paymentMethod === 'intent' ? 'intent' : 'upi',
      [EventParameterKey.PAYMENT_ACCOUNT]: virtual_bank_account_id,
      [EventParameterKey.TXN_ID]: txnId,
      [EventParameterKey.ORDER_ID]: order_id,
      [EventParameterKey.PAYMENT_VALUE]: txn_amount,
    });

    getPaymentCurrentStatus(txnId, action, (req, res) => {
      const { next } = res;
      this.showLoader(false);
      const header =
        this.headers[`${res?.txn_status}`] || 'Processing Your Order ';
      if (action === 'retry') {
        this.retry();
      }
      if (Utility.isBlank(next)) {
        return;
      }
      this.setState({
        paymentCurrentStatus: res,
        header,
      });
      if (action === 'change_method') {
        navigation.replace('Payments');
      }
    });
  };

  retry = () => {
    const { sendPaymentRequest } = this.props;
    const { order_id = '' } = this.paymentStatus;
    const payload = { ...this.orderPayload, order_id };
    sendPaymentRequest(payload, (req, res) => {
      this.showLoader(false);
      const header =
        this.headers[`${res?.txn_status}`] || 'Processing Your Order ';
      this.setState(
        {
          paymentCurrentStatus: res,
          header,
        },
        () => this.retryIntent(payload, res),
      );
    });
  };

  retryIntent = (payuPayload, res) => {
    if (this.razorpayEnabled) {
      checkoutWithRazorpay(
        res,
        this.extraData,
        this.paymentMethodDetails,
        this.cancelPayment,
      );
      return;
    }
    if (res.payment_method === 'intent') {
      this.setupPayu(res.payu_params, () => {
        if (Utility.isAndroid()) {
          RNPayuPayment.payWithIntent(
            payuPayload.paymentMethodPayload.package_name,
          );
        } else {
          RNPayuPayment.payWithIntentIOS(
            payuPayload.paymentMethodPayload.package_name,
          );
        }
      });
    }
  };

  setupPayu = (payuParams, callback) => {
    const {
      key,
      txnId,
      amount,
      firstName,
      email,
      environment,
      productInfo,
      surl,
      furl,
      userCredentials,
      phoneNumber,
      hashes: {
        paymentHash,
        saveUserCardHash,
        deleteUserCardHash,
        paymentRelatedDetailsHash,
        VASForMobileSDKHash,
      },
    } = payuParams;
    const payParams = {
      key,
      txnId,
      amount,
      productInfo,
      firstName,
      email,
      environment,
      surl,
      furl,
      userCredentials,
      phoneNumber,
      paymentHash,
      saveUserCardHash,
      deleteUserCardHash,
      paymentRelatedDetailsHash,
      VASForMobileSDKHash,
    };

    if (Utility.isAndroid()) {
      RNPayuPayment.setPayuParamsForIntent(payParams)
        .then((res) => {
          console.log('success payu intent init');
        })
        .catch((e) => {
          console.log('setup payu param error for upi intent');
        });

      RNPayuPayment.setPaymentParams(payParams)
        .then((res) => {
          callback(true);
        })
        .catch((e) => {
          callback(false);
        });
    } else {
      this.setPaymentParamsForIOSIntent(payParams, callback);
    }
  };

  setPaymentParamsForIOSIntent = (payParams, callback) => {
    RNPayuPayment.setPaymentParamsForIOSIntent(payParams)
      .then((res) => {
        callback(true);
      })
      .catch((e) => {
        console.log('ios intent param setup error', e);
        // callback(false);
      });
  };

  startPaymentStatusWatcher = () => {
    NotificationManager.startHeadless();
    this.getPaymentStatus();
    this.status = setInterval(this.getPaymentStatus, 5000);
  };

  getPaymentStatus = () => {
    const { getPaymentCurrentStatus } = this.props;
    const {
      timerEnd,
      paymentCurrentStatus: { txn_id: txnId = '' },
    } = this.state;

    const { paymentMethod } = this.orderPayload;

    if (timerEnd) {
      clearInterval(this.status);
      return;
    }
    const verifyString =
      this.orderPayload.paymentMethod === 'intent' ? 'verify_intent' : 'verify';
    getPaymentCurrentStatus(txnId, verifyString, (req, res) => {
      this.showLoader(false);
      if (!req) {
        return;
      }
      const header =
        this.headers[`${res?.txn_status}`] || 'Processing Your Order ';
      this.setState({
        paymentCurrentStatus: res,
        header,
      });
    });
  };

  showLoader = (isLoaderVisible) => {
    this.setState({
      showLoader: isLoaderVisible,
    });
  };

  showBackAlert = () => {
    const { navigation } = this.props;
    const { paymentCurrentStatus } = this.state;
    Alert.alert('', 'Are you sure you want to cancel the transaction?', [
      {
        text: 'YES',
        onPress: () => {
          this.changePaymentstate('cancel');
          navigation.goBack();
        },
      },
      {
        text: 'NO',
        onPress: () => {},
        style: 'cancel',
      },
    ]);
  };

  navigateToOrders = () => {
    const { navigation } = this.props;
    navigation.navigate('OrdersHistory');
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    const { paymentCurrentStatus: { txn_status = '' } = {}, header } =
      this.state;

    if (['failure', 'success', 'validation_failure'].includes(txn_status)) {
      if (header === this.headers.success) {
        navigation.navigate('Feed');
        this.clearCart();
      } else {
        navigation.goBack();
      }
    } else {
      this.showBackAlert();
    }

    return true;
  };

  navigateToFeed = () => {
    const { navigation } = this.props;
    navigation.navigate('Feed');
  };

  openInAppReview = () => {
    const { appReviewSubmitted } = this.props;
    if (appReviewSubmitted) {
      return;
    }

    this.setState({
      showAppReviewModal: true,
    });
    AnalyticsManager.logEvent(EventType.order.MODAL_VIEW, {
      modal_name: EventParameterValue.MODAL_NAME.APP_REVIEW,
    });
  };

  onCancelAppReviewModal = (dismissedByUser) => {
    this.setState({
      showAppReviewModal: false,
    });
    if (dismissedByUser) {
      AnalyticsManager.logEvent(EventType.order.MODAL_CLOSE, {
        modal_name: EventParameterValue.MODAL_NAME.APP_REVIEW,
      });
    }
  };

  onFinishRating = (rating) => {
    this.currentRating = rating;
  };

  onSubmitRating = () => {
    const { isAppReviewSubmitted } = this.props;
    AnalyticsManager.logEvent(EventType.order.APP_REVIEW, {
      rating: this.currentRating,
    });
    if (this.currentRating > 3) {
      const appLink = Utility.isAndroid() ? PLAY_STORE_LINK : APP_STORE_LINK;
      setTimeout(() => {
        Linking.openURL(appLink);
      }, 800);
    }
    isAppReviewSubmitted(true);
    this.onCancelAppReviewModal(false);
  };

  renderItem = ({ item, index }) => {
    const { details } = this.extraData;
    const { paymentCurrentStatus } = this.state;
    const LayoutComponent = this.component[`${item.screen}`];
    const secHeader = this.sectionHeader[`${item.screen}`];
    const {
      paymentMethodPayload: { virtual_bank_account_id = '' } = {},
      paymentMethodPayload,
    } = this.orderPayload;
    if (!LayoutComponent) {
      return null;
    }
    return (
      <View>
        {!!secHeader && (
          <Text
            style={{
              color: colors.foxyBlack,
              fontFamily: 'Roboto-Medium',
              fontSize: 14,
              marginTop: 21,
              marginLeft: 16,
              marginBottom: 12,
            }}
          >
            {secHeader}
          </Text>
        )}
        <View
          style={{
            backgroundColor: colors.white,
            flex: 1,
            paddingHorizontal: item.screen !== 'pricing' ? 16 : 0,
          }}
        >
          <LayoutComponent
            changePaymentstate={this.changePaymentstate}
            paymentDetails={item}
            paymentCurrentStatus={paymentCurrentStatus}
            virtual_bank_account_id={virtual_bank_account_id}
            details={details}
            navigateToOrders={this.navigateToOrders}
            clearTimer={this.clearTimer}
            clearCart={this.clearCart}
            navigateToFeed={this.navigateToFeed}
          />
        </View>
      </View>
    );
  };

  keyExtractor = (_, index) => `${index.toString()}`;

  listFooterComponent = () => {
    return <View style={styles.footerContainer} />;
  };

  render() {
    const {
      paymentCurrentStatus: { next = [] } = {},
      header,
      showLoader,
      showAppReviewModal,
    } = this.state;
    return (
      <>
        <StaticNavigationHeader
          title={header}
          onBackPress={this.onHardwareBackKeyPress}
        />
        <View style={styles.container}>
          <FlatList
            data={next}
            keyExtractor={this.keyExtractor}
            renderItem={this.renderItem}
            extraData={{ ...this.state, ...this.props }}
            style={styles.flatListStyle}
            showsVerticalScrollIndicator={false}
            bounces={false}
            ListFooterComponent={this.listFooterComponent}
          />
          {showLoader && (
            <>
              <View style={styles.activityIndicatorContainer}>
                <ActivityIndicator animating color="red" size="large" />
              </View>
            </>
          )}

          <AppReviewModal
            isVisible={showAppReviewModal}
            onCancel={this.onCancelAppReviewModal}
            onFinishRating={this.onFinishRating}
            onSubmitRating={this.onSubmitRating}
          />
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartPricing: state.bag.cartPricing,
  cartItems: state.bag.cartItems,
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
  paytmDetails: state.UserAccountInfo.paytmDetails,
  isNewPaymentJourney: state.bag.isNewPaymentJourney,
  UtoP: state.bag.UtoP,
  isContactLess: state.bag.isContactLess,
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  appReviewSubmitted: state.UserAccountInfo.appReviewSubmitted,
  membership_cohort: state.todayDeals.membership_cohort,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getPaymentCurrentStatus,
      sendPaymentRequest,
      getCartItems,
      isAppReviewSubmitted,
      setCongratsModalForEdge,
      saveLocalNotificationData,
      setCartNotification,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PaymentsWaiting),
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },

  flatListStyle: {
    backgroundColor: colors.background,
    width: Utility.getScreenWidth(),
    flex: 1,
  },

  footerContainer: {
    width: Utility.getScreenWidth(),
    height: 200,
    backgroundColor: colors.background,
  },

  activityIndicatorContainer: {
    flex: 1,
    position: 'absolute',
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    backgroundColor: colors.black,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    opacity: 0.5,
    zIndex: 1,
  },
});
