// Dependencies
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';

// Components
import {
  fetchProduct,
  updateProductData,
  fetchProductReviews,
  addToRecentlyViewed,
  updateLastVisitedProductId,
} from '../../actions/ActionTypes';
import { LAYOUT } from '../../config/Constants';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import WithNavigation from '../../utils/WithNavigation';
import { addProductIdInProductViews } from '../../actions/UserInteractionsActions';
import colors from '../../theme/Colors';
import ProductSummary from '../../components/Product/ProductSummary';
import images from '../../theme/Images';
import { ProductDetailStyles } from '../../components/Product/styles';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';

class ProductDetailModal extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = props;
    this.layout = props.layout || (route.params?.display ?? LAYOUT.SCREEN);
    this.shouldRenderDetailPage = this.layout === 'screen';
    this.variants_details = route.params?.variants_details ?? {};
    this.force_apply_coupon = route.params?.force_apply_coupon ?? '';
    this.offer_id = route.params?.offer_id ?? '';
    this.listId = route.params?.listId ?? '';

    this.cartConditionFn = (props) => props.cart === true;
    this.selectedVariantItem = {};
    this.singleStockedVariant = {};
    this.fromScan = false;
    this.previousScreen = route.params?.previousScreen;
    this.state = {
      productData: {},
      showShimmer: false,
      productPositiveIngredients: {},
      productNegativeIngredients: {},
      isPageLoading: true,
    };
  }

  componentDidMount() {
    const { itemData = {}, route } = this.props;
    if (Utility.isBlank(itemData)) {
      const slugString = route.params?.slug;
      this.fetchProductData(slugString);
    }
  }

  fetchProductCallback = (success, data, status) => {
    this.setState({
      showShimmer: false,
      isPageLoading: false,
    });

    if (success) {
      this.setState({
        productData: data,
      });
    }
  };

  fetchProductData = (slugString) => {
    const { itemData = {}, fetchProduct } = this.props;
    AnalyticsUtility.recordTime(
      {
        screen_name: SCREEN_CONSTANTS.PRODUCT_DETAIL,
        ...Utility.setRecordTimeEventMeta(itemData, 'product'),
      },
      this.fetchProductCallback,
      fetchProduct,
      slugString,
    );
  };

  closeModal = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  crossButton = () => {
    return (
      <TouchableOpacity
        hitSlop={Utility.getHitSlop()}
        onPress={this.closeModal}
        style={ProductDetailStyles.crossButtonContainer}
      >
        <Image style={ProductDetailStyles.crossButton} source={images.cross} />
      </TouchableOpacity>
    );
  };

  render() {
    const {
      itemData = {},
      baseProps,
      previousScreen,
      refreshOfferStrip,
      maxFreeItemsToSelect,
      showToast,
      campaignId,
      onItemAddToCartFromCollab,
      refreshOffersDetailsPageDiscountStrip,
      boostedOffers,
      route,
    } = this.props;

    const {
      productData,
      showShimmer,
      productPositiveIngredients,
      productNegativeIngredients,
      isPageLoading,
    } = this.state;

    this.filteredProps = _.omit(baseProps, ['cart']);
    const data = Utility.isBlank(productData) ? itemData : productData;
    const { brand: { certificate_image_url = '' } = {} } = data;

    return (
      <>
        <TouchableWithoutFeedback onPress={this.closeModal}>
          <View style={styles.container} />
        </TouchableWithoutFeedback>
        <View style={styles.modal}>
          <ProductSummary
            listId={this.listId}
            certificateImageUrl={certificate_image_url}
            itemData={data}
            productData={itemData}
            selectedVariantItem={this.selectedVariantItem}
            layout={this.layout}
            showShimmer={showShimmer}
            refreshOfferStrip={refreshOfferStrip}
            previousScreen={SCREEN_CONSTANTS.PRODUCT_SUMMARY}
            maxFreeItemsToSelect={maxFreeItemsToSelect}
            showToast={showToast}
            fromScan={this.fromScan}
            campaignId={campaignId}
            onItemAddToCartFromCollab={onItemAddToCartFromCollab}
            refreshOffersDetailsPageDiscountStrip={
              refreshOffersDetailsPageDiscountStrip
            }
            singleStockedVariant={this.singleStockedVariant}
            navigation={this.props.navigation}
            productPositiveIngredients={productPositiveIngredients}
            productNegativeIngredients={productNegativeIngredients}
            variantItem={this.variantItem}
            isPageLoading={isPageLoading}
            boostedOffers={boostedOffers}
            isReplacement={route?.params?.isReplacement}
            productName={route?.params?.name || ''}
            slug={route.params?.slug}
            force_apply_coupon={this.force_apply_coupon}
            offer_id={this.offer_id}
          />
          <this.crossButton />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.translucent,
  },
  modal: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    position: 'absolute',
    bottom: 0,
    height: '70%',
    paddingTop: 12.0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
});

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  todayDeals: state.todayDeals,
  boostedOffers: state.boostedOffers,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchProduct,
      updateProductData,
      fetchProductReviews,
      addProductIdInProductViews,
      addToRecentlyViewed,

      updateLastVisitedProductId,
    },
    dispatch,
  ),
});

export default WithNavigation(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetailModal),
);
