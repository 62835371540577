import React, { PureComponent } from 'react';
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  Image,
  Text,
  TouchableOpacity,
} from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';

export default class AddressCard extends PureComponent {
  render() {
    const {
      locationIcon,
      locationType,
      pincode,
      isDefault,
      onAddressSetDefault,
      onEditAddress,
      address,
      showDeleteAlert,
      onAddressCardTap,
      isSelected,
    } = this.props;
    let { addressLine1 = '', addressLine2 = '' } = this.props;

    const cardTap =
      onAddressCardTap !== undefined ? onAddressCardTap : onEditAddress;

    const selectedColor = '#4285F41A';

    const backgroundColor = isSelected ? selectedColor : colors.white;

    if (addressLine1) {
      addressLine1 += ', ';
    }
    if (addressLine2) {
      addressLine2 += ' - ';
      addressLine2 = addressLine2?.replace(', India', '');
    }

    return (
      <View>
        <View style={[styles.addressContainer, { backgroundColor }]}>
          <TouchableWithoutFeedback onPress={cardTap}>
            <View style={styles.row}>
              <View style={styles.addressCardInnerContainer}>
                <Image style={styles.iconContainer} source={locationIcon} />
                <View style={styles.addressLineContainer}>
                  <Text style={styles.addressTypeHead}>{locationType}</Text>
                  <Text style={styles.addressTypeSubtitle} numberOfLines={2}>
                    {`${addressLine1}${addressLine2}${pincode}`}
                  </Text>
                </View>
              </View>
              <View style={styles.editButtonContainer}>
                <TouchableOpacity
                  onPress={onEditAddress}
                  hitSlop={Utility.getHitSlop()}
                >
                  <Image style={styles.editIcon} source={images.edit} />
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
        <View style={styles.separator} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  addressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: colors.borderDark,
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 12,
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
  addressTypeHead: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.cta.lightBlue,
  },
  addressTypeSubtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    width: Utility.getScreenWidth() - 150,
    color: colors.foxyBlack,
  },
  separator: {
    height: 1,
    backgroundColor: colors.border,
    marginHorizontal: 12,
  },
  addressCardInnerContainer: { flex: 1.5, flexDirection: 'row' },
  iconContainer: { marginTop: 2, height: 18, width: 18, resizeMode: 'contain' },
  addressLineContainer: { marginLeft: 10 },
  editButtonContainer: {
    marginRight: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  editIcon: { height: 18, width: 18, resizeMode: 'contain' },
});
