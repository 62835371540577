import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import images from '../../../theme/Images';
import Utility, { openUrlInBrowser } from '../../../utils/Utility';
import FastImageView from '../../FastImageView';

class RoutineFeatureCard extends PureComponent {
  onPress = () => {
    const { cardDetails: { destination = '' } = {} } = this.props;
    try {
      openUrlInBrowser(destination);
    } catch (e) {}
  };

  render() {
    const {
      cardDetails: {
        title = '',
        body = '',
        cta_text = '',
        cta_color,
        image_url = '',
        background_image_url,
        arguments: {
          points = '',
          hide_for_authenticated = false,
          hide_for_unauthenticated = false,
        } = {},
      } = {},
      isUserSubscribed,
      onPress,
      authToken = '',
    } = this.props;
    const backgroundImage = Utility.isBlank(background_image_url)
      ? images.routines.feature_card_bg
      : background_image_url;

    const hideRoutineCard =
      (Utility.isPresent(authToken) && hide_for_authenticated) ||
      (Utility.isBlank(authToken) && hide_for_unauthenticated);

    if (hideRoutineCard) {
      return null;
    }

    const showActionButton = !isUserSubscribed;
    const showPoints = isUserSubscribed && Utility.isPresent(points);

    return (
      <TouchableOpacity
        style={styles.featureCard}
        onPress={onPress}
        disabled={!showActionButton}
      >
        <FastImageView
          source={Utility.getMinifiedImage(backgroundImage)}
          style={styles.backgroundImage}
        />
        <View style={styles.container}>
          <View style={styles.contentContainer}>
            <Text style={styles.title} numberOfLines={2} ellipsizeMode='tail'>
              {title}
            </Text>
            <Text
              style={styles.subtitle}
              numberOfLines={2}
              ellipsizeMode='tail'
            >
              {body}
            </Text>
            {showActionButton && (
              <TouchableOpacity
                style={styles.buttonContainer}
                onPress={onPress}
              >
                <Text style={styles.buttonText}>{cta_text}</Text>
              </TouchableOpacity>
            )}
            {showPoints && (
              <Text style={styles.pointsText}>{points} points</Text>
            )}
          </View>
          <FastImageView
            source={Utility.getMinifiedImage(image_url, 134, 134)}
            style={styles.featureCardImage}
          />
        </View>
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.UserAccountInfo.authToken,
});

export default connect(mapStateToProps, null)(RoutineFeatureCard);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 14,
    paddingHorizontal: 16,
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
  },
  featureCard: {
    width: Utility.getScreenWidth() - 60,
    marginRight: 12,
    marginVertical: 6,
  },
  backgroundImage: {
    flex: 1,
    borderRadius: 16,
    height: 160,
    resizeMode: 'cover',
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  title: {
    color: colors.white,
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
  },
  subtitle: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
  },
  buttonText: {
    color: colors.white,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
  },
  buttonContainer: {
    paddingVertical: 4,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 14,
    backgroundColor: colors.black,
    borderRadius: 16,
  },
  featureCardImage: {
    width: 134,
    height: 134,
    resizeMode: 'contain',
  },
  pointsText: {
    marginTop: 14,
    color: colors.white,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
  },
});
