import { View, Text, Image } from 'react-native';
import React from 'react';
import StyleSheet from 'react-native-media-query';
import { useRemoveParams } from '../../lib/hooksUtil';
import { isDesktop } from '../../utils/BooleanUtility';
import { StaticNavigationHeader } from '../../components/shared';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import DownloadAppButtons from '../../components/webFooter/DownloadAppButtons';

export default function TheEdge() {
  useRemoveParams(['slug', 'destination']);
  return (
    <>
      <StaticNavigationHeader title="Edge" />
      <View style={styles.container}>
        <Text style={styles.title} dataSet={{ media: ids.title }}>Be a member of</Text>
        <Image
          source={{ uri: images.foxyEdgeLogo }}
          style={styles.logo}
          dataSet={{ media: ids.logo }}
        />
        <Image
          source={{ uri: images.edgeFeatures }}
          style={styles.features}
          dataSet={{ media: ids.features }}
        />
        <Text style={styles.info} dataSet={{ media: ids.info }}>
          Weekly vouchers, over the top discounts for Edge customers, birthday gift,
           welcome gift, free product trials with every buy.. the list goes on!
        </Text>
        <DownloadAppButtons width={137.7} height={37.4} />
      </View>
    </>
  );
}

const { ids, styles } = StyleSheet.create({
  container: {
    paddingHorizontal: 26,
    flex: 1,
    alignItems: 'center',
    backgroundImage: 'url("https://cdn3.foxy.in/media/app_asset/image/942/edgebackground.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontWeight: 200,
    fontSize: 18,
    lineHeight: 21,
    textAlign: 'center',
    color: '#525252',
    marginTop: 40,
    '@media (min-width: 768px)': {
      marginTop: 30,
    },
  },
  logo: {
    marginTop: 10,
    width: 167,
    height: 80,
    '@media (min-width: 768px)': {
      marginTop: 8,
      width: 142,
      height: 68,
    },
  },
  features: {
    marginTop: 24,
    width: 316,
    height: 222,
    '@media (min-width: 768px)': {
      marginTop: 16,
      width: 444.5,
      height: 312,
    },
  },
  info: {
    fontFamily: 'Roboto-Regular',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 23,
    color: colors.black,
    marginTop: 20,
    marginBottom: 26,
    textAlign: 'center',
    '@media (min-width: 768px)': {
      fontSize: 20,
    },
  },
});
