import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Image,
} from 'react-native';
import React from 'react';
import styles from './styles';
import FastImageView from '../../../components/FastImageView';
import images from '../../../theme/Images';
import Video from '../../../libraries/ReactNativeVideo';

const UploadMediaContent = ({
  onUploadMediaPress,
  onUploadMediaPressAppend,
  deleteImage,
  navigation,
  media = [],
  appendImageCardStyle,
}) => {
  const SelectedImages = () => {
    const renderEachImage = (image) => {
      const onPress = () => {
        deleteImage(media.indexOf(image));
      };

      const Thumbnail = () => {
        if (String(image?.mime).includes('image')) {
          return (
            <FastImageView source={image.path} style={styles.selectedMedia} />
          );
        }

        let player;
        const assignRef = (ref) => {
          player = ref;
        };
        const seekTo0 = () => {
          player.seek(0);
        };
        return (
          <>
            <Video
              style={styles.selectedMedia}
              source={{ uri: image.path }}
              paused
              resizeMode='cover'
              ref={assignRef}
              onLoad={seekTo0}
            />
            <TouchableOpacity style={styles.videoPauseIconStyle}>
              <FastImageView
                source={images.video_pause_button}
                style={styles.videoPauseIcon}
              />
            </TouchableOpacity>
          </>
        );
      };

      return (
        <View style={styles.selectedMediaContainer}>
          <Thumbnail />
          <TouchableOpacity onPress={onPress} style={styles.crossIconContainer}>
            <FastImageView
              source={images.white_cross}
              style={styles.crossIcon}
            />
          </TouchableOpacity>
        </View>
      );
    };

    const RenderAppendButton = () => {
      if (media.length > 7) {
        return <View style={styles.emptyPlaceHolder} />;
      }
      return (
        <TouchableOpacity
          style={appendImageCardStyle}
          onPress={onUploadMediaPressAppend}
        >
          <FastImageView
            source={images.upload_image_camera_icon}
            style={styles.imageStyle}
          />
        </TouchableOpacity>
      );
    };

    return (
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.scrollViewContainer}
      >
        <RenderAppendButton />
        <View style={styles.selectedMediaRailContainer}>
          {media.map(renderEachImage)}
        </View>
      </ScrollView>
    );
  };

  return <SelectedImages />;
};

export default UploadMediaContent;
