import React, { Component } from 'react';
import { View } from 'react-native';
import Swiper from '../../../libraries/ReactNativeSwiper';
import PropTypes from 'prop-types';
import styles from './styles';
import Banner from '../../../containers/banner/Banner';
import FeatureCard from '../../feature/FeatureCard';
import { LAYOUT, BANNER_TIMEOUT } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
import { withEither } from '../../../lib/Monads';
import Offer from '../../../containers/offer/Offer';
import RatingCard from '../../testimonialCard/RatingCard';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';

class OverflowBanner extends Component {
  static Components = {
    banner: Banner,
    offer: Offer,
    feature: FeatureCard,
    rating: RatingCard,
  };

  static getComponentHeight() {
    return (
      (1 / 3) * (Utility.getScreenWidth() - 24) + 8 * 2
    );
  }

  constructor(props) {
    super(props);

    let data = this.props.item;

    if (this.props.content === 'testimonial' && Utility.isBlank(data)) {
      data = [
        {
          display_order: null,
          type: 'testimonial',
          id: 353,
          title: 'I never get to eat my favourite cake.',
          body: 'Stop binge and stress eating and save both your body and skin',
          cta_text: 'BUST IT',
          cta_color: '#AC54C340',
          destination: '',
          external_link:
            'https://www.dermstore.com/blog/how-stress-affects-the-skin/',
          background_color: '',
          data_api: '',
          image_url: 'https://cdn3.foxy.in/media/feature/image/353/16.jpg',
          image_webp_url:
            'https://cdn3.foxy.in/media/feature/image/353/16.webp',
          display: 'large_banner',
          dismissable: false,
          max_dismissal_count: null,
          arguments: null,
        },
      ];
    }
    if (!Utility.isBlank(data)) {
      this.itemData = data.filter((item) => !!item);
    }
  }

  // isMultipleBannerTrue = () => this.itemData.length > 0;
  // conditionViewportAwareSwiper = withEither(
  //   this.isMultipleBannerTrue,
  //   this.ViewportAwareSwiper,
  // )(Swiper);
  autoplay = true;

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const {
      id,
      navigation,
      allVideosId,
      previousScreen,
      listData,
      listName,
      listContent,
    } = this.props;

    const ContainerComponent = OverflowBanner.Components[item.type];
    if (item === undefined || ContainerComponent === undefined) {
      return null;
    }
    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <ContainerComponent
          key={item.id}
          id={item.id}
          layout={LAYOUT.PAGER}
          itemData={item}
          type={item.type}
          navigation={navigation}
          listId={id}
          index={index}
          listData={listData}
          listName={listName}
          listContent={listContent}
          listIndex={this.props.index}
          allVideosId={allVideosId}
          previousScreen={previousScreen}
          refreshOfferStrip={this.props.refreshOfferStrip}
        />
      </ErrorBoundaryComponent>
    );
  };

  saveSwiperRef = (ref) => {
    this.swiperRef = ref;
  };

  startAutoplay = () => {
    if (this.itemData.length > 0) {
      this.swiperRef.autoplay = true;
    }
  };

  stopAutoplay = () => {
    this.swiperRef.autoplay = false;
  };

  render() {
    const { display, content } = this.props;

    if (Utility.isBlank(this.itemData)) {
      return null;
    }
    if (this.itemData.length === 0) {
      this.autoplay = false;
    }
    const { listData: { objects = [] } = {} } = this.props;
    const dotStyle =
      objects[0]?.type === 'feature' ? styles.dotStyleFeature : styles.dotStyle;

    const dotComponentStyle =
      display === 'pagerBottom' ? styles.dotStyleBottomPager : dotStyle;
    const dot = <View style={dotComponentStyle} />;
    const activeDot = <View style={[dotStyle, styles.activeDot]} />;

    const activeBottomDot = <View style={styles.dotStyleBottom} />;
    let imageSize = {};
    if (Utility.isPresent(objects)) {
      //TODO: This is only works for Feature cards of the type LargeBanner
      if (objects[0]?.type === 'feature') {
        imageSize = styles.pageContainerFeature;
      } else {
        imageSize = styles.pageContainer;
      }
    }

    const paginationStyle =
      display === 'pagerBottom'
        ? styles.bottomPaginationStyle
        : styles.paginationStyle;

    const activeDotView =
      display === 'pagerBottom' ? activeBottomDot : activeDot;
    return (
      <View style={imageSize}>
        <Swiper
          ref={this.saveSwiperRef}
          autoplayTimeout={BANNER_TIMEOUT}
          autoplay={this.autoplay}
          dot={dot}
          activeDot={activeDotView}
          paginationStyle={paginationStyle}
        >
          {this.itemData.map((item, index) => this.getComponent(item, index))}
        </Swiper>
      </View>
    );
  }
}

OverflowBanner.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
  }),
};
export default OverflowBanner;
