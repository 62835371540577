import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Keyboard,
  BackHandler,
  StyleSheet,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OTPInputView from '@twotalltotems/react-native-otp-input'
import PropTypes from 'prop-types';
import _ from 'lodash';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';

import { AnalyticsManager } from '../../analytics';
import { PaymentStyles } from './styles';
import {
  verifyPaytmOtp,
  addMoneyToPaytm,
  sendPaytmOtp,
} from '../../actions/PaytmActions';
import StaticNavigationHeader from '../shared/StaticNavigationHeader';
import { AddressStyles } from './styles';
import { styles } from '../onboarding/Styles/OTPVerificationStyles';
import FoxyShadowButton from '../../lib/FoxyShadowButton';

class PaytmVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cellStyle: styles.paytmCellStyle,
      otp: '',
      error: '',
      state_token: '',
      verificationInProgress: false,
    };
    this.paymentStatus = null;
    const { route } = props;
    this.mobileNumber = route.params?.mobileNumber;
    this.createOrder = route.params?.createOrder;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    this.sendPaytmOtp();
    this.otpView.focusField(0);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  sendPaytmOtp = () => {
    const { sendPaytmOtp } = this.props;
    sendPaytmOtp(this.mobileNumber, (req, res) => {
      this.setState({
        state_token: res.state_token,
      });
    });
  };

  ResendPaymentRequest = () => {
    return (
      <View
        style={{
          marginTop: 16,
          paddingHorizontal: 16,
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'center',
          alignContent: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto-Regular',
            fontSize: 14,
            fontStyle: 'normal',
            letterSpacing: 0,
            color: colors.foxyBlack,
          }}
        >
          Haven’t received the request yet ?
        </Text>
        <TouchableOpacity onPress={this.createOrderAndPay}>
          <Text
            style={{
              fontFamily: 'Roboto-Regular',
              fontSize: 14,
              fontStyle: 'normal',
              letterSpacing: 0,
              color: colors.foxyBlue,
              marginLeft: 4,
            }}
          >
            Resend
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  goBack = () => {
    const { hidePaytmVerifyScreen } = this.props;
    hidePaytmVerifyScreen();
    this.setState({
      otp: '',
      error: '',
      state_token: '',
    });
  };

  togglePaytmVerifyingStatus = (isVerifying) => {
    this.setState({
      verifyingPaytm: isVerifying,
    });
  };

  header = () => (
    <View style={AddressStyles.header}>
      <TouchableOpacity hitSlop={Utility.getHitSlop()} onPress={this.goBack}>
        <Image
          source={images.navigationWhiteBackIcon}
          style={AddressStyles.backArrow}
        />
      </TouchableOpacity>
      <Text style={AddressStyles.addressHeaderText}>Link Paytm</Text>
    </View>
  );

  resendOtp = () => {
    AnalyticsManager.logEvent('paytm_otp_resend');
    this.sendPaytmOtp();
  };

  resendOtpContainer = () => {
    return (
      <View style={[styles.contactInfoContainer, { marginTop: 8 }]}>
        <Text style={styles.contactNumber}>{`Didnt receive OTP? `} </Text>
        <TouchableOpacity onPress={this.resendOtp}>
          <Text style={[styles.contactNumber, { color: colors.dodgerBlue }]}>
            {`RESEND CODE`}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  contactInfo = () => {
    const { paytmMobileNumber } = this.props;

    return (
      <View style={[styles.contactInfoContainer, { marginBottom: 8 }]}>
        <Text style={styles.contactNumber}>Enter OTP received on </Text>
        <Text style={[styles.contactNumber, { color: colors.foxyBlack }]}>
          {` ${this.mobileNumber}`}
        </Text>
      </View>
    );
  };

  verificationInProgressPaytm = (isVerifying) => {
    this.setState({
      verificationInProgress: isVerifying,
    });
  };

  onFulfill = (code) => {
    AnalyticsManager.logEvent('paytm_otp_input_complete');

    this.setState({
      otp: code,
      error: '',
    });
  };

  onFirstIndex = (isFirstIndex) => {
    if (isFirstIndex) {
      AnalyticsManager.logEvent('paytm_otp_input_start');
      this.setState({
        cellStyle: styles.cellStyle,
        error: '',
      });
    }
  };

  otpInputBox = () => {
    const { cellStyle, currentOtp } = this.state;

    const otp = currentOtp;

    return (
      <View style={styles.otpInputBoxContainer}>
        <OTPInputView
          ref={(ref) => {
            this.otpView = ref;
          }}
          style={{ width: '100%' }}
          pinCount={6}
          code={otp}
          onFirstIndex={this.onFirstIndex}
          autoFocusOnLoad={false}
          codeInputFieldStyle={cellStyle}
          onCodeFilled={(code) => {
            this.onFulfill(code);
          }}
        />
      </View>
    );
  };

  createOrderAndPayWithPaytm = () => {
    this.verificationInProgressPaytm(false);

    const paymentPayload = {
      paymentGateway: 'paytm',
      paymentMethod: 'paytm',
      isSavedPaymentMethod: true,
      paymentMethodPayload: {},
      payuPayload: {},
      extraData: {
        method: 'paytm',
      },
    };
    this.createOrder(paymentPayload);
  };

  createOrderAndAddMoney = () => {
    const { addMoneyToPaytm, showToast, navigation } = this.props;

    addMoneyToPaytm((success, response) => {
      this.verificationInProgressPaytm(false);
      if (success) {
        navigation.replace('PaytmWebView', {
          paytmPayload: response,
          createOrderAndPayWithPaytm: this.createOrderAndPayWithPaytm,
        });
      } else {
        showToast('Something went wrong');
        AnalyticsManager.logEvent('paytm_add_money_fail');
      }
    });
  };

  validateOtpAndCreateOrder = () => {
    const { verifyPaytmOtp } = this.props;
    const { otp, state_token } = this.state;
    Keyboard.dismiss();
    this.verificationInProgressPaytm(true);
    AnalyticsManager.logEvent('paytm_verify_click');

    verifyPaytmOtp(otp, state_token, this.mobileNumber, (success, response) => {
      console.log(success, response);
      if (success) {
        if (response.status === 'success') {
          if (!!response.sufficient_balance) {
            this.createOrderAndPayWithPaytm();
          } else {
            this.createOrderAndAddMoney();
          }
        } else {
          AnalyticsManager.logEvent('paytm_otp_wrong');
          this.setState({
            error: 'Please enter a valid OTP',
          });
          this.otpView.focusField(5);
          this.verificationInProgressPaytm(false);
        }
      } else {
        AnalyticsManager.logEvent('paytm_otp_wrong');
        this.setState({
          error: 'Please enter a valid OTP',
        });
        this.otpView.focusField(5);
        this.verificationInProgressPaytm(false);
      }
    });
  };

  otpContainer = (props) => {
    return (
      <View style={styles.otpContainer}>
        <this.otpInputBox {...props} />
      </View>
    );
  };

  errorContainer = () => {
    const { error } = this.state;
    if (Utility.isBlank(error)) {
      return null;
    }

    return (
      <Text
        style={{
          color: colors.foxyRed,
          fontSize: 12,
          fontFamily: 'Roboto-Regular',
        }}
      >
        {error}
      </Text>
    );
  };

  otpVerificationContainer = () => {
    const { verificationInProgress } = this.state;
    return (
      <View style={paytmStyles.container}>
        <this.contactInfo />
        <this.otpContainer />
        <this.errorContainer />
        <this.resendOtpContainer />

        <FoxyShadowButton
          width={Utility.getScreenWidth() - 34}
          title={'Verify and pay'}
          onPress={this.validateOtpAndCreateOrder}
          backgroundColor={colors.primaryActionBackgroundColor}
          style={paytmStyles.payButton}
          firstColor={colors.linearGradientGreenFirst}
          secondColor={colors.linearGradientGreenSecond}
          Loading={verificationInProgress}
        />
      </View>
    );
  };

  render() {
    return (
      <>
        <StaticNavigationHeader title="Link PayTm" />
        <ScrollView style={AddressStyles.container}>
          <this.otpVerificationContainer />
        </ScrollView>
      </>
    );
  }
}

const paytmStyles = StyleSheet.create({
  container: {
    padding: 16,
  },
  payButton: { marginTop: 18 },
});

PaytmVerification.defaultProps = {
  state_token: '',
  hidePaytmVerifyScreen: () => {},
  paytmMobileNumber: '',
  resentPaytmOtp: () => {},
  createOrder: () => {},
  showActivityLoader: () => {},
  hideActivityLoader: () => {},
  addMoneyToPaytm: () => {},
  viewPaytmWebViewScreen: () => {},
  showToast: () => {},
  verifyPaytmOtp: () => {},
};

PaytmVerification.propTypes = {
  state_token: PropTypes.string,
  hidePaytmVerifyScreen: PropTypes.func,
  paytmMobileNumber: PropTypes.string,
  resentPaytmOtp: PropTypes.func,
  createOrder: PropTypes.func,
  showActivityLoader: PropTypes.func,
  hideActivityLoader: PropTypes.func,
  addMoneyToPaytm: PropTypes.func,
  viewPaytmWebViewScreen: PropTypes.func,
  showToast: PropTypes.func,
  verifyPaytmOtp: PropTypes.func,
};

const mapStateToProps = function (state) {
  return {
    mobileNumber: state.UserAccountInfo.mobileNumber,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      verifyPaytmOtp,
      addMoneyToPaytm,
      sendPaytmOtp,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(PaytmVerification),
);
