import React, { useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Modal from 'react-native-modal';
import _ from 'lodash';
import Toast from 'react-native-easy-toast';
import withNavigation from '../../utils/WithNavigation';
import { ProductDetailStyles } from './styles';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import CouponCodePill from '../cart/CouponCodePill';
import { AnalyticsManager, EventType } from '../../analytics';
import images from '../../theme/Images';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import size from '../../theme/Fonts';
import ModalContainer from '../modals/ModalContainer';
// Import other necessary dependencies

const ClubbableModal = (props) => {
  const toastRef = useRef(null);
  const { navigation, route } = props;
  const {
    params: {
      notClubbableCouponCode = '',
      applyOffer = () => {},
      notClubbableOfferId = '',
      notClubbableWith = {},
    } = {},
  } = route;
  const OfferInfo = ({ coupon, description }) => {
    if (Utility.isBlank(description)) {
      return null;
    }
    return (
      <View style={clubbableModalStyles.offersInfoContainer}>
        <CouponCodePill
          couponCode={coupon}
          style={clubbableModalStyles.couponCodePillStyle}
        />
        <Text style={clubbableModalStyles.offersDescription}>
          {description}
        </Text>

        <View style={clubbableModalStyles.couponCodeLeftCutClipper} />
        <View style={clubbableModalStyles.couponCodeRightCutClipper} />
      </View>
    );
  };

  const primaryButtonClick = () => {
    goBack();
    applyOffer(notClubbableCouponCode, notClubbableOfferId);
  };

  const goBack = () => {
    navigation.goBack();
  };

  const CrossButton = () => {
    return (
      <TouchableOpacity
        style={clubbableModalStyles.crossButtonContainer}
        onPress={goBack}
        hitSlop={Utility.getHitSlop('likeButton')}
      >
        <Image source={images.cross} style={clubbableModalStyles.crossButton} />
      </TouchableOpacity>
    );
  };

  if (!notClubbableWith || notClubbableWith.length === 0) {
    return null;
  }

  const length = notClubbableWith?.length;
  let buttonText = `Remove ${length} offers and apply ${notClubbableCouponCode}`;
  let headingText = `${notClubbableCouponCode} can not be applied with following offers`;
  if (length === 1) {
    buttonText = `Apply ${notClubbableCouponCode}`;
    headingText = `Apply ${notClubbableCouponCode}?`;
  }

  return (
    <ModalContainer>
      <View style={clubbableModalStyles.modalContainer}>
        <ScrollView scrollEventThrottle={16}>
          <View style={clubbableModalStyles.headingContainer}>
            <Text style={clubbableModalStyles.offerName}>{headingText}</Text>
            <CrossButton />
          </View>
          <Text style={clubbableModalStyles.descriptionContainer}>
            This will remove the following offers in your cart :
          </Text>
          <View style={clubbableModalStyles.offersContainer}>
            {_.map(notClubbableWith, (couponCode) => {
              return (
                <OfferInfo
                  coupon={couponCode.coupon_code}
                  description={couponCode.message}
                />
              );
            })}
          </View>

          <View style={clubbableModalStyles.actionButtonsContainer}>
            <DebouncedTouchableOpacity
              {...props}
              style={clubbableModalStyles.primaryButtonStyle}
              onPress={primaryButtonClick}
            >
              <Text style={clubbableModalStyles.primaryButtonTextStyle}>
                {buttonText}
              </Text>
            </DebouncedTouchableOpacity>
          </View>
        </ScrollView>
      </View>
      <Toast ref={toastRef} />
    </ModalContainer>
  );
};

export default withNavigation(ClubbableModal);

const clubbableModalStyles = StyleSheet.create({
  modalContainer: {
    width: Utility.getScreenWidth(),
    borderRadius: 6,
    backgroundColor: 'white',
    marginBottom: -20,
    paddingBottom: 20,
  },
  offersContainer: { marginVertical: 8 },
  actionButtonsContainer: {
    marginHorizontal: 12,
    marginTop: 10,
    width: Utility.getScreenWidth() - 24,
  },
  firstButtonStyle: {
    marginBottom: 8,
    borderColor: colors.black,
    borderWidth: 1,
    borderBottomWidth: 1.5,
  },
  firstButtonTextStyle: {
    color: colors.foxyBlack,

    fontSize: 14,
  },
  secondaryButtonTextStyle: {
    fontSize: 14,
  },
  offersInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
    backgroundColor: '#F2FBF7',
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginHorizontal: 16,
    height: 40,
  },
  couponCodePillStyle: {
    marginRight: 4,
    paddingHorizontal: 4,
    padding: 2,
    borderWidth: 1,
    backgroundColor: '#01B46012',
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    borderColor: '#01B560',
  },
  offersDescription: {
    flex: 4,
    paddingHorizontal: 4,
    color: colors.foxyBlack,
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
  },
  couponCodeRightCutClipper: {
    height: 17,
    width: 17,
    position: 'absolute',
    backgroundColor: colors.white,
    bottom: '40%',
    right: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
    borderColor: '#fff',
  },
  couponCodeLeftCutClipper: {
    height: 17,
    width: 17,
    position: 'absolute',
    backgroundColor: colors.white,
    bottom: '40%',
    left: -10,
    borderRadius: 10,
    overflow: 'hidden',
    zIndex: 10,
    borderWidth: 4,
    borderColor: '#fff',
  },
  cross: { width: 15, height: 15, resizeMode: 'contain' },
  crossContainer: {
    flex: 1,
    // alignSelf: 'flex-start',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  crossButtonContainer: {
    // position: 'absolute',
    // top: 0,
    // right: 0,
    backgroundColor: colors.borderDark,
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 11,
    padding: 7,
    marginRight: 12,
    marginTop: 12,
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12,
  },
  crossButton: { tintColor: colors.black },
  primaryButtonStyle: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginVertical: 8,
    width: Utility.getScreenWidth() - 24,
    // borderWidth: 1,
    // borderBottomWidth: 1.2,
    borderColor: colors.black,
    backgroundColor: colors.black,
  },
  primaryButtonTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
  },

  offerName: {
    fontSize: size.h2,
    fontFamily: 'Roboto-Bold',

    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 12,
    width: '75%',
  },

  descriptionContainer: {
    paddingLeft: 15,
    paddingRight: 15,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    fontSize: size.h3,
    marginTop: 12,
  },
});
