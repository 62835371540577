// Dependencies
import _ from 'lodash';
import React, { Component } from 'react';
import {
  View,
  Text,
  PixelRatio,
  StyleSheet,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ScaleAnimate from '../shared/ScaleAnimate';

// Components
import { COLLECTION } from '../../config/LayoutConstants/CollectionConfig';
import CollectionCardStyle from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { getCardDimentions } from '../../utils/ListCardUtility';
import { fetchTagList, setSearchResultClicks } from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import { CARDSTYLE, getNumberOfLines } from '../../utils/ListCardUtility';
import Card from '../../lib/Card';
import {
  AnalyticsUtilty,
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../analytics';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import FastImageView from '../FastImageView';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { isDesktop, isPresent, isWeb } from '../../utils/BooleanUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
class ColouredCollectionCard extends Component {
  static cardLayoutStyle = {
    vertical: {
      cardElevation: 2,
      cardMaxElevation: 2,
      cornerRadius: 4,
    },
  };

  constructor(props) {
    super(props);
    this.item = this.props.itemData.objects;
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceCollectionCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );

    this.x = { x: 0, y: 0 };
    this.y = { x: 0, y: 1 };
  }

  collectionDescription = (props) => {
    const { display, itemData, layout, size } = props;

    if (Utility.isBlank(itemData)) {
      return null;
    }

    // todo_vishwender: if horizontal, no of lines = 3
    const noOfLines = getNumberOfLines(layout, display);
    let containerStyle = CollectionCardStyle.colouredCardTextContainer;
    const textColor = !Utility.isBlank(itemData.text_color)
      ? itemData.text_color
      : colors.foxyBlack;
    let textStyle = [
      CollectionCardStyle.colouredCardTextStyle,
      { color: textColor },
    ];
    const smallCollectionCard = layout === 'grid' && size === 'small';
    const cardName = smallCollectionCard
      ? itemData.name
      : _.capitalize(itemData.name);
    if (isPresent(layout)) {
      if (layout === 'grid' && display === 'rectangularGrid') {
        containerStyle = CARDSTYLE.CARD[layout][`${display}TextContainer`];
        textStyle = [
          CARDSTYLE.CARD[layout][`${display}CardTextStyle`],
          { color: textColor },
        ];
      } else {
        containerStyle = CARDSTYLE.CARD[layout][`${size}TextContainer`];
        textStyle = [
          CARDSTYLE.CARD[layout][`${size}CardTextStyle`],
          { color: textColor },
        ];
      }
    }
    return (
      <View style={containerStyle}>
        <Text numberOfLines={noOfLines} style={textStyle}>
          {cardName}
        </Text>
      </View>
    );
  };

  SeparatorStyle = (props) => {
    const { layout } = props;
    if (layout === 'list') {
      return <View style={CollectionCardStyle.listSeparatorStyle} />;
    }
    return null;
  };

  navigate = () => {
    const {
      navigation,
      itemData = {},
      index,
      id,
      layout,
      listIndex,
      previousScreen,
      search,
      searchQuery,
      elementItemCounts,
      type,
      setSearchResultClicks,
      brandId = '',
      extraEventParameters = {},
      itemData: {
        all_products_slug: allProductSlug = '',
        navigate_to_all_products: navigateToAllProducts = false,
        id: itemId = '',
        type: itemType = '',
        name: itemName = '',
        display_order: itemDisplayOrder = '',
        slug: itemSlug = '',
        list_type: listItemType = '',
      } = {},
      listData = {},
      listData: {
        id: listId = '',
        slug: listSlug = '',
        name: listName = '',
        type: listType = '',
        content: listContent = '',
        display_order: listDisplayOrder = '',
      },
    } = this.props;
    const { MORE_ITEMS, CATEGORY, SEARCH } = SCREEN_CONSTANTS;

    //FIXME: this section should be a common function which will accept props, and calculated event meta itself
    if (previousScreen === SEARCH) {
      const data = {
        query: searchQuery,
        type,
        id,
      };
      setSearchResultClicks(data);
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;

      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };
      this.debounceCollectionCardClick(meta);
    }
    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemId,
        itemType,
        itemName,
        itemDisplayOrder,
        listId,
        listType,
        listName,
        listIndex ?? listDisplayOrder,
        '',
        '',
        listContent,
        '',
        itemSlug,
        listSlug,
        extraEventParameters,
      );
    }

    let slug = itemSlug;
    let navigationScreen = itemData.type === 'list' ? MORE_ITEMS : CATEGORY;
    if (isWeb() && slug.includes('categories') && slug.includes('brands')) {
      navigationScreen = 'Brand';
    }
    if (slug.charAt(0) !== '/') {
      slug = '/'.concat(slug);
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: navigationScreen,
      params: {
        id: itemData.id,
        displayCount: itemData?.objects?.length,
        heading: itemData.name,
        item: itemData,
        navigation,
        title: itemData.name,
        slug,
        previousScreen,
      },
    });
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  navigateToMedia = () => {
    const { navigation, itemData = {}, fetchTagList } = this.props;
    const index = 0;
    const relatedContentSlug = itemData.slug?.replace(
      '.json',
      '/related-content.json',
    );

    fetchTagList(relatedContentSlug, 0, (success, data) => {
      if (success) {
        navigation.push('ContentModal', {
          listId: null,
          index: 0,
          slug: relatedContentSlug,
          listData: data,
          itemData: data.objects[0],
        });
      }
    });
  };

  getStyles = ({ layout, size = 'defaultSize' }) => {
    const { display, listData, previousScreen } = this.props;
    let layoutSize = size;

    if (Utility.isBlank(layoutSize)) {
      layoutSize = 'defaultSize';
    }

    let cardType = layout;
    if (layout === 'grid' && display === 'rectangularGrid') {
      cardType = 'rectangularGrid';
    } else if (layout === 'gridRail') {
      cardType = 'colouredRectangleRail';
    } else if (layout === 'rail') {
      cardType = 'gridSquareRail';
    } else if (layout === 'grid' || size === 'small') {
      cardType = layout;
    } else {
      cardType = 'gridSquareRail';
    }

    let isCategoryFullScreenGridCard = false;
    let { viewwidth, viewheight, imagewidth, imageheight } =
      COLLECTION.CARD[cardType][layoutSize];
    if (
      Utility.isPresent(listData) &&
      layout === 'grid' &&
      display !== 'rectangularGrid'
    ) {
      isCategoryFullScreenGridCard = this.props.type === 'product_category';
      const noOfColumns = listData.columns || 2;
      [viewwidth, viewheight, imagewidth, imageheight] = getCardDimentions(
        isDesktop() ? noOfColumns * 2 : noOfColumns,
        'collectionCard',
        size,
      );
    }

    const cardDimensions = {
      height: viewheight,
      width: viewwidth,
    };
    let imageStyle = {
      height: !isCategoryFullScreenGridCard ? imageheight : 120,
      width: imagewidth,
      borderRadius: 4,
    };
    let cardStyle = [
      CollectionCardStyle.colouredCardContainer,
      {
        height: viewheight,
        width: viewwidth,
        overflow: 'hidden',
      },
    ];
    if (display === 'rectangularGrid' || layout === 'gridRail') {
      cardStyle = [
        CollectionCardStyle.colouredCardContainerHorizontal,
        {
          height: viewheight,
          width: viewwidth,
          overflow: 'hidden',
        },
      ];
    }

    return {
      cardStyle,
      imageStyle,
    };
  };

  cardImage = () => {
    const { layout, display, itemData: collectionData = {} } = this.props;
    const { cardStyle, imageStyle } = this.getStyles(this.props);
    const devicePixelRatio = PixelRatio.get();
    let colouredCardImageStyle = CollectionCardStyle.colouredCardImageStyle;

    if (collectionData === undefined) {
      return null;
    }

    if (layout === 'gridRail') {
      colouredCardImageStyle =
        CollectionCardStyle.colouredCardRectangleSmallImageStyle;
    } else if (layout === 'grid' && display === 'rectangularGrid') {
      colouredCardImageStyle =
        CollectionCardStyle.colouredCardRectangleDefaultSizeImageStyle;
    }
    const cardImage = Utility.getMinifiedImage(
      collectionData.transparent_image_url,
      imageStyle.height * devicePixelRatio,
      imageStyle.width * devicePixelRatio,
    );

    return (
      <FastImageView
        resizeMode="contain"
        source={cardImage}
        height={imageStyle.height}
        width={imageStyle.width}
        style={colouredCardImageStyle}
      />
    );
  };

  render() {
    const {
      orientation,
      display,
      itemData: collectionData = {},
      layout,
      size,
    } = this.props;
    const { cardStyle, imageStyle } = this.getStyles(this.props);
    if (collectionData === undefined) {
      return null;
    }

    // const cardImage = Utility.getMinifiedImage(
    //   collectionData.image_url,
    //   imageStyle.width,
    //   imageStyle.height,
    // );

    const bgColor = !Utility.isBlank(collectionData.card_background_color)
      ? collectionData.card_background_color
      : colors.white;
    const gradientArray = [
      Utility.addAlpha(bgColor, 0.15),
      Utility.addAlpha(bgColor, 0.25),
    ];

    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <Card
          {...ColouredCollectionCard.cardLayoutStyle[orientation]}
          style={cardStyle}
        >
          <LinearGradient
            colors={gradientArray}
            style={styles.container}
            start={this.x}
            end={this.y}
          />
          <this.cardImage />
          <this.collectionDescription
            itemData={collectionData}
            layout={layout}
            display={display}
            size={size}
          />
        </Card>
        <this.SeparatorStyle layout={layout} />
      </ScaleAnimate>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchTagList, setSearchResultClicks }, dispatch),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(ColouredCollectionCard),
);

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
  },
});
