import { takeEvery, call } from 'redux-saga/effects';
import { SHOW_SEARCH_SUGGESTIONS } from '../actions/UploadPostActions';

import { URL } from '../config/Constants';
import Utility from '../utils/Utility';

import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

const fetchSuggestions = (searchText, headers) => {
  const url = `${URL.AUTO_SUGGEST}${searchText}&send_store_slug=true`;
  const response = fetch(url, {
    method: 'GET',
    headers: headers,
  });
  return response;
};

function* getSearchSuggestions(action) {
  try {
    const headers = yield getApiHeaders();
    const response = yield call(fetchSuggestions, action.searchText, headers);
    if (response.status === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json.suggestions);
      }
    } else {
      // console.log(`Error Message: ${JSON.stringify(json)}`);
    }
  } catch (error) {
    // console.log(`Error in getting response ${error}`);
  }
}

export default function* watchGetSearchSuggestionSaga() {
  yield takeEvery(SHOW_SEARCH_SUGGESTIONS, getSearchSuggestions);
}
