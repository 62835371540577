import { takeEvery, call, put, select } from 'redux-saga/effects';

import {
  SEARCH_PRODUCT,
  updateProductSearchListId,
} from '../actions/UploadPostActions';
import { toggleProductSearchingStatus } from '../actions/LoginActions';
import { URL } from '../config/Constants';
import {
  STORE_DATA_RECEIVED,
  FETCH_STORE,
  UPDATE_LIST_DATA,
} from '../actions/ActionTypes';

import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import Utility from '../utils/Utility';
const fetchProducts = (searchTerm1, searchTerm2, searchTerm3, headers) => {
  const url = `${URL.SEARCH_PRODUCT}${encodeURI(searchTerm1)},${encodeURI(
    searchTerm2,
  )},${encodeURI(searchTerm3)}`;
  const response = fetch(url, {
    method: 'GET',
    headers: headers,
  });
  return response;
};

function* searchProduct(action) {
  try {
    const headers = getApiHeaders();
    const response = yield call(
      fetchProducts,
      action.searchTerms[0],
      action.searchTerms[1] || '',
      action.searchTerms[2] || '',
      headers,
    );
    if (response.status === 200) {
      const json = yield convertJsonFromResponse(response);
      // yield put({ type: UPDATE_LIST_DATA, data: json });
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(toggleProductSearchingStatus(false));
      action.callback(json.id);
    }
  } catch (error) {
    // console.log(`Error in getting response ${error}`);
  }
}

export default function* watchSearchProductSaga() {
  yield takeEvery(SEARCH_PRODUCT, searchProduct);
}
