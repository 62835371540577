import { takeEvery, call, select, put, takeLatest } from 'redux-saga/effects';
import { NativeModules } from 'react-native';
import {
  VERIFY_OTP,
  verifyOtpSuccess,
  verifyOtpFailure,
  userAuthenticated,
  SEND_OTP_OVER_CALL,
  SEND_OTP_OVER_WHATSAPP,
  saveUserState,
  setAppInstallSource,
  isRequestedFromHintPrompt,
  insertMobileNumber,
  updateMobileNumber,
  setPreviousAssociatedAccount,
  RESTORE_PREVIOUS_ACCOUNT,
  showProfileShimmer,
} from '../actions/LoginActions';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  AnalyticsUtilty,
} from '../analytics';
import {
  getAllowedAttributes,
  getMyAttributes,
} from './ProfilePicUploadUrlSaga';
import { getMasterAttributesList } from '../actions/FacialAnalysisActions';

import {
  URL,
  CURRENT_GUEST_STATE,
  CURRENCY,
  PERMISSION_STATUS,
} from '../config/Constants';
import {
  POST_DEVICE_INFORMATION,
  postDeviceInformation,
  saveBagItems,
  getCartItems,
  getBoostedOffers,
  getUserTodayDeals,
  saveMembershipCohort,
  getMyProfileDetails,
  userRegisteredOnCt,
} from '../actions/ActionTypes';
import Utility from '../utils/Utility';
import {
  getApiHeaders,
  convertJsonFromResponse,
  hasPublicId,
} from './GeneratorUtil';
import BGNetworkTaskManager from '../utils/BGNetworkTaskManager';
import Session from '../utils/Sessions';
import { getCurrentNotificationStatus } from '../utils/NotificationUtils';
import {
  checkInstallDateTimeFrame,
  checkLastOrderStatus,
} from '../utils/PersonalizationUtility';
import Config from 'react-native-config';
import AppConfig from '../config/AppConfig';

const fetchAuthToken = (mobileNumber, callingCode, otp, headers) => {
  const enableInternationalChoice = AppConfig.getBooleanValue(
    Config.ENABLE_INTERNATIONAL_CHOICE,
  );

  const user = {
    phone_number: `${mobileNumber}`,
    otp: `${otp}`,
    ...enableInternationalChoice && { country_code: `${callingCode}` },
  };

  const response = fetch(URL.VERIFY_OTP, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      user,
    }),
  });
  return response;
};

const getOtpOnCall = (mobileNumber, headers) => {
  const response = fetch(URL.SEND_OTP_ON_CALL, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      user: {
        phone_number: `${mobileNumber}`,
      },
    }),
  });
  return response;
};

function* verifyOtp(action) {
  try {
    const userAccountInfo = yield select((state) => state.UserAccountInfo);
    const { mobileNumber, callingCode, appInstalledSource } = userAccountInfo;
    let headers = yield getApiHeaders();
    const response = yield call(
      fetchAuthToken,
      mobileNumber,
      callingCode,
      action.otp,
      headers,
    );
    if (response.status === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        const errorText = 'Something went wrong';
        action.callback(errorText);
        yield put(verifyOtpFailure(errorText));
        return;
      }

      yield put(verifyOtpSuccess(json.user));

      if (yield hasPublicId()) {
        AnalyticsManager.setUserAlias();
      }

      if (json.user.new_user) {
        AnalyticsManager.logFirebaseEvent(
          EventType.googleRemarketingEvents.SIGN_UP,
          {
            [EventParameterKey.METHOD]: 'Mobile Number',
          },
        );

        const fbMeta = {
          [EventParameterKey.FB.EVENT_PARAM_REGISTRATION_METHOD]:
            'Mobile Number',
        };

        AnalyticsManager.logFBStandardEvent(
          EventType.FB.EVENT_NAME_COMPLETED_REGISTRATION,
          null,
          AnalyticsUtilty.addCurrencyToFBEventMetaData(fbMeta),
        );
      }

      // CleverTapAnalytics.setUserLogin(json.user.phone_number);
      yield put(userRegisteredOnCt(true));
      // CleverTapAnalytics.setUserProfile(json.user?.profile);

      if (Utility.isIOS()) {
        NativeModules.DeviceDetails.persistUserPhoneNumber(
          json.user.phone_number,
        );
      }

      if (Utility.isPresent(json?.user?.guest_token)) {
        AnalyticsUtilty.setGuestTokenAsProperties(json?.user?.guest_token);
      }
      if (json?.user?.last_order_date) {
        checkLastOrderStatus(json?.user?.last_order_date);
      }
      if (Utility.isPresent(json?.user?.app_installed_at)) {
        checkInstallDateTimeFrame(json?.user?.app_installed_at);
      }
      /**
       * json.user.lead && appInstalledSource === 'organic'
       * User installed app from any source expect artist_Def_deeplink but at time of
       * verification we detected that its a lead user.
       * in this case treat him as fb_artist_def_link like user
       *
       */
      if (json.user.lead && appInstalledSource === 'organic') {
        yield put(setAppInstallSource('fb_artist_def_link'));
      }
      yield getMyAttributes('', true, false, true);
      yield put(userAuthenticated(true));

      /**
       * To fetch all cart information including coupons applied of user after otp verification.
       * Previously it was just cart items nothing else.
       */
      yield put(
        saveMembershipCohort({
          membership_cohort: json.user.membership_cohort,
        }),
      );
      yield put(getCartItems(() => {}, true));
      yield put(getUserTodayDeals());
      yield put(getBoostedOffers());
      yield put(isRequestedFromHintPrompt(false));
      yield put(saveUserState(CURRENT_GUEST_STATE.FULLY_REGISTERED)); // TODO:Remove this code once skip functionality is enabled
      //TODO: Code may generate race condition with home page api hit.

      if (Utility.isAndroid()) {
        NativeModules.UserPreferences.saveAuthToken(
          `${json?.user?.auth_token}`,
        );
      }

      if (!Utility.isBlank(json?.user?.auth_token)) {
        BGNetworkTaskManager.saveAuthToken(json.user.auth_token);
      }

      Utility.openOtpModalFromProfile = false;
      // action.callback(null);

      AnalyticsManager.setUserProperty('authenticated', 'true');

      AnalyticsManager.setUserProperty('is_artist', `${json.user.is_artist}`);
      AnalyticsManager.setUserProperty(
        'artist_level',
        `${json.user.artist_level}`,
      );
      AnalyticsManager.setUserProperty(
        'published_media_count',
        `${json.user.published_videos_count}`,
      );
      const countryCode = `${json.user.country_code}`;
      let contactNumber = `${mobileNumber}`
      if (AppConfig.getBooleanValue(Config.ENABLE_INTERNATIONAL_CHOICE)) {
        contactNumber = `+${countryCode}${mobileNumber}`
      }
      AnalyticsManager.setGeneralUserAttributes({
        name: `${json.user.profile.name}`,
        email: `${json.user.profile.email}`,
        contactNumber,
      });

      const { action: actionAfterLogin = () => {}, params = [] } =
        Utility.furtherAction;

      if (Utility.isPresent(actionAfterLogin)) {
        yield put(actionAfterLogin(...params));
      }

      action.callback(true, null, json.user.authorized);

      Utility.furtherAction = {
        action: null,
        params: [],
        phone_number: '',
        isActionAsync: false,
      };
    } else {
      AnalyticsManager.setUserProperty('authenticated', 'false');
      Utility.furtherAction = {
        action: null,
        params: [],
        phone_number: '',
        isActionAsync: false,
      };

      let error = 'Something went wrong';
      if (response.status < 500) {
        const json = yield convertJsonFromResponse(response);
        if (Utility.isPresent(json)) {
          error = json.errors[0]?.message;
        }
      }
      yield put(verifyOtpFailure(error));
      console.log(`Error Message: ${JSON.stringify(error)}`);
      action.callback(false, error);
    }
    yield postDeviceInformationAfterLogin();
  } catch (error) {
    Utility.furtherAction = {
      action: null,
      params: [],
      phone_number: '',
      isActionAsync: false,
    };
    yield put(showProfileShimmer(false));
    const errorText = 'Something went wrong';
    AnalyticsManager.setUserProperty('authenticated', 'false');
    action.callback(false, errorText);
    yield put(verifyOtpFailure(errorText));
    console.log(`Error in getting auth token ${error}`);
  }
}

function* sendOtpOnCall(action) {
  const { callback } = action;
  try {
    const mobileNumber = yield select(
      (state) => state.UserAccountInfo.mobileNumber,
    );
    let headers = yield getApiHeaders();
    const response = yield call(getOtpOnCall, mobileNumber, headers);
    if (response.status >= 200 && response.status < 300) {
      callback(true);
      return;
    }
    callback(false);
  } catch (error) {
    callback(false);
  }
}

function* sendOtpOnWhatsapp(action) {
  const { callback } = action;
  try {
    const mobileNumber = yield select(
      (state) => state.UserAccountInfo.mobileNumber,
    );
    let headers = yield getApiHeaders();
    const response = yield call(getOtpOnWhatsapp, mobileNumber, headers);
    console.log('Response code ', response.status);
    if (response.status >= 200 && response.status < 300) {
      callback(true);
      return;
    }
    callback(false);
  } catch (error) {
    callback(false);
  }
}

const getOtpOnWhatsapp = (mobileNumber, headers) => {
  const response = fetch(URL.SEND_OTP_ON_WHATSAPP, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      user: {
        phone_number: `${mobileNumber}`,
      },
      via: 'whatsapp',
    }),
  });
  return response;
};

function* sendDeviceInformation(action) {
  const { device_information, callback } = action;

  yield put(getMasterAttributesList());
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const guestAuthToken = yield select(
      (state) => state.UserAccountInfo.guestProfile.guestAuthToken,
    );
    const mobile = yield select((state) => state.UserAccountInfo.mobile);
    const postObject = {
      guest_token: guestAuthToken,
      device: Utility.jsonParser(device_information),
    };

    if (Utility.isPresent(authToken)) {
      postObject.auth_token = authToken;
    }
    if (Utility.isPresent(mobile)) {
      postObject.user = { phone_number: mobile };
    }

    postObject.device['notifications_status'] = getCurrentNotificationStatus();

    const response = yield fetch(URL.DEVICES, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postObject),
    });

    if (!callback) return null;

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      if (json.user) {
        Session.previousAccountData = json.user;
        yield put(setPreviousAssociatedAccount(true));
      }
    }
  } catch (error) {
    callback(false);
    console.log(`Error while Sending Device Information ${error}`);
  }
}

function* restorePreviousAccount() {
  try {
    const userAccountInfo = yield select((state) => state.UserAccountInfo);
    yield put(setPreviousAssociatedAccount(false));
    if (Utility.isBlank(Session.previousAccountData)) {
      return;
    }
    const userData = Session.previousAccountData;

    yield put(verifyOtpSuccess(userData));

    if (yield hasPublicId()) {
      AnalyticsManager.setUserAlias();
    }

    if (userData?.phone_number) {
      yield put(
        updateMobileNumber(`+${userData.country_code}${userData.phone_number}`),
      );
    }

    if (Utility.isPresent(userData?.user?.guest_token)) {
      AnalyticsUtilty.setGuestTokenAsProperties(userData?.user?.guest_token);
    }

    // CleverTapAnalytics.setUserLogin(userData.phone_number);
    yield put(userRegisteredOnCt(true));
    // CleverTapAnalytics.setUserProfile(userData.user?.profile);

    yield getMyAttributes('', true, false, true);
    yield put(saveUserState(CURRENT_GUEST_STATE.FULLY_REGISTERED));
    /**
     * To fetch all cart information including coupons applied of user after otp verification.
     * Previously it was not present here.
     */
    yield put(getCartItems());
    yield put(getUserTodayDeals());
    yield put(getBoostedOffers());

    if (Utility.isAndroid()) {
      NativeModules.UserPreferences.saveAuthToken(`${userData.auth_token}`);
    }

    if (!Utility.isBlank(userData.auth_token)) {
      BGNetworkTaskManager.saveAuthToken(userData.auth_token);
    }

    AnalyticsManager.setUserProperty('authenticated', 'true');

    AnalyticsManager.setUserProperty('is_artist', `${userData.is_artist}`);
    AnalyticsManager.setUserProperty(
      'artist_level',
      `${userData.artist_level}`,
    );
    AnalyticsManager.setUserProperty(
      'published_media_count',
      `${userData.published_videos_count}`,
    );

    AnalyticsManager.setGeneralUserAttributes({
      name: `${userData?.profile?.name}`,
      email: `${userData?.profile?.email}`,
      contactNumber: `+${userData?.country_code}${userData?.phone_number}`,
    });
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: `VerifyOtpSaga:restorePreviousAccount() ${error}`,
    });
  }
}

export function* postDeviceInformationAfterLogin() {
  const deviceInformation = yield select(
    (state) => state.UserAccountInfo.device_information,
  );
  yield put(postDeviceInformation(JSON.stringify(deviceInformation)));
}

export default function* watchVerifyOtpSaga() {
  yield takeEvery(VERIFY_OTP, verifyOtp);
  yield takeLatest(SEND_OTP_OVER_CALL, sendOtpOnCall);
  yield takeLatest(SEND_OTP_OVER_WHATSAPP, sendOtpOnWhatsapp);
  yield takeLatest(POST_DEVICE_INFORMATION, sendDeviceInformation);
  yield takeLatest(RESTORE_PREVIOUS_ACCOUNT, restorePreviousAccount);
}
