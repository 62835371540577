import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  playlistStyle,
  verticalPlaylistCardHeight,
  verticalPlaylistCardWidth,
} from './Style';
import Utility from '../../utils/Utility';
import AudioHandler from '../../utils/AudioHandler';
import VerticalMediaImage from './VerticalMediaImage';
import VerticalMediaBgImage from './VerticalMediaBgImage';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import {
  EventType,
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
} from '../../analytics';
import Video from '../../libraries/ReactNativeVideo';
import _ from 'lodash';
import { withMaybe } from '../../lib/Monads';
import Card from '../../lib/Card';
import MediaComponent from './MediaComponent';
import {
  MAX_LINES,
  ContentPlatforms,
  VIDEO_DISTRIBUTION_URL,
  VIDEO_CDN_URL,
  ROUTINE_ACTIVITY_TYPES,
} from '../../config/Constants';
import withNavigation from '../../utils/WithNavigation';
import MediaCardStyles from './styles';
import images from '../../theme/Images';
import ScaleAnimate from '../shared/ScaleAnimate';
import FastImageView from '../FastImageView';
import ProductRatingAndMoreInfo from '../Product/ProductRatingAndMoreInfo';
import { isWeb } from '../../utils/BooleanUtility';

class VerticalSingleMediaCard extends PureComponent {
  static VideoComponents = {
    foxy: Video,
  };

  volumeListener = null;

  constructor(props) {
    super(props);

    const {
      item = {},
      goToNextPage,
      index,
      iteratorIndex,
      cardWidth,
      extraEventParameters = {},
      previousScreen = '',
    } = props;
    const {
      artist: { name: artistName = '', id: artistId } = {},
      entity: { name: entityName = '', id: entityId, type: entityType } = {},
      metadata: {
        contentPlatform = 'foxy',
        title: contentTitle,
        id: videoId,
        duration,
      } = {},
    } = item;

    this.state = {
      shouldPlay: false, //index === iteratorIndex,
      isBuffering: true,
      muted: AudioHandler.isMuted,
      screenInFocus: true,
    };

    this.videoMetadata = {
      [EventParameterKey.VIDEO_ID]: videoId,
      [EventParameterKey.VIDEO_TITLE]: contentTitle,
      [EventParameterKey.VIDEO_DURATION]: duration,
      [EventParameterKey.VIDEO_TYPE]: _.capitalize(contentPlatform),
      [EventParameterKey.ENTITY_TYPE]: entityType,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.ENTITY_ID]: entityId,
      [EventParameterKey.SOURCE]: 'vertical_card',
      [EventParameterKey.SCREEN_NAME]: previousScreen,
      ...extraEventParameters,
    };

    this.isCurrentIndex = index === iteratorIndex;

    const imageWidth = (item.metadata && item.metadata.width) || 560;
    const imageHeight = (item.metadata && item.metadata.height) || 375;

    this.mediaHeight = (imageHeight / imageWidth) * cardWidth;
    this.mediaStyle = {
      width: '100%',
      height: this.mediaHeight,
    };
    this.showBgImage = verticalPlaylistCardHeight > this.mediaHeight + 10;
    this.isCardTappedForFirstTime = false;
    this.currentTime = 0;
    this.focusListener = null;
    this.didBlurListener = null;

    this.debouncedFullScreenButton = _.debounce(
      this.onFullScreenButtonTap,
      3000,
      {
        leading: true,
        trailing: false,
      },
    );
    this.debouncedCard = _.debounce(this.cardTapped, 3000, {
      leading: true,
      trailing: false,
    });
  }

  componentDidMount() {
    if (this.props.onRef != null) {
      this.props.onRef(this);
    }

    // console.log('componentDidMount: ', this.props.iteratorIndex)

    const { navigation } = this.props;
    this.focusListener = navigation.addListener('focus', () => {
      this.setState({
        screenInFocus: true,
      });
    });

    this.didBlurListener = navigation.addListener('blur', () => {
      this.setState({
        screenInFocus: false,
      });
    });
  }

  unmute = () => {
    const { muted } = this.state;
    this.setState((prevState) => ({
      muted: !muted,
    }));
  };

  toggleMutedState = () => {
    const { iteratorIndex } = this.props;
    if (!this.isCurrentIndex) {
      return;
    }
    if (AudioHandler.isMuted) {
      AudioHandler.changeAudioSessionCategoryToAmbient();
    } else {
      AudioHandler.changeAudioSessionCategoryToPlayBack();
    }
    AudioHandler.isMuted = !AudioHandler.isMuted;
    this.setState((prevState) => ({
      muted: !prevState.muted,
    }));
  };

  componentWillUnmount() {
    this.focusListener();
    this.didBlurListener();
    // console.log('componentWillUnmount: ', this.props.iteratorIndex)
  }

  onNextButtonTapped = () => {
    const { goToNextPage, iteratorIndex, totalNumberOfVideos } = this.props;
    const isLastVideo = iteratorIndex === totalNumberOfVideos - 1;
    if (isLastVideo) {
      this.pause();
      this.currentTime = 0;
    }

    goToNextPage();
  };

  artistVerifiedCondition = (props) => !props.verified;
  verifiedTickWithCondition = withMaybe(this.artistVerifiedCondition)(Image);

  isFoxyVideoCondition = () => {
    const { item = {} } = this.props;
    const { metadata: { contentPlatform } = {} } = item;
    if (contentPlatform === ContentPlatforms.foxy) {
      return true;
    } else {
      return false;
    }
  };

  renderMiddleControlsCondition = () => {
    return this.isFoxyVideoCondition ? <this.renderMiddleControls /> : null;
  };

  navigateToMedia = () => {
    const {
      navigation,
      item,
      id,
      display,
      listName,
      listIndex,
      previousScreen,
      listData,
      listContent,
      iteratorIndex = 0,
      extraEventParameters = {},
      listData: { display: listDisplay = '', slug: listSlug = '' },
    } = this.props;
    const itemData = item;
    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData?.metadata?.id,
        itemData?.type,
        itemData?.metadata?.title,
        iteratorIndex,
        id,
        listDisplay,
        listName,
        listIndex,
        '',
        '',
        listContent,
        '',
        itemData?.slug,
        listSlug,
        extraEventParameters,
      );
    }
    navigation.push('ContentModal', {
      itemData,
      listId: id,
      index: iteratorIndex,
      listData,
      seekToTimeOnLoad: this.currentTime,
      previousScreen,
    });
  };

  MediaViews = (props) => {
    const { views } = props;
    if (views === '0' || views === 0) {
      return null;
    }
    return (
      <View style={MediaCardStyles.viewsContainer}>
        <View style={MediaCardStyles.cardDotStyle} />
        <Text
          style={[MediaCardStyles.cardViewCount, { color: colors.white }]}
        >{`${views}`}</Text>
        <Text
          style={[
            MediaCardStyles.cardViewCount,
            { color: colors.white, marginLeft: 2 },
          ]}
        >
          views
        </Text>
      </View>
    );
  };

  reportBuffering = (isBuffering) => {
    this.setState({ isBuffering });
  };

  renderMiddleControls = (props) => {
    const style = playlistStyle;
    const { isBuffering } = this.state;
    if (isBuffering) {
      return (
        <View style={style.verticalCardMiddleControlsContainer}>
          {Utility.getActivityIndicatorSmall(style.verticalCardMiddleControls)}
        </View>
      );
    }
    return null;
  };

  playButton = () => {
    const style = playlistStyle;
    const { item = {} } = this.props;
    const { metadata: { contentPlatform } = {} } = item;
    let buttonStyle = style.verticalCardMiddleControls;
    if (contentPlatform !== ContentPlatforms.foxy) {
      buttonStyle = style.verticalCardMiddleControlsLarge;
    }
    return (
      <View style={style.verticalCardMiddleControlsContainer}>
        <FastImageView source={images.playCircle} style={buttonStyle} />
      </View>
    );
  };

  pause = () => {
    this.isCurrentIndex = false;
    this.setState({
      shouldPlay: false,
    });
  };

  play = () => {
    this.isCurrentIndex = true;
    this.setState({
      shouldPlay: true,
    });
  };

  onFullScreenButtonTap = () => {
    AnalyticsManager.logEvent(EventType.verticalVideoCard.VERTICAL_VIDEO_CARD, {
      ...this.videoMetadata,
      [EventParameterKey.CLICK]: 'full_screen',
    });
    this.navigateToMedia();
    // this.togglePlayStatus();
  };

  onTapMuteButton = () => {
    AnalyticsManager.logEvent(EventType.verticalVideoCard.VERTICAL_VIDEO_CARD, {
      ...this.videoMetadata,
      [EventParameterKey.CLICK]: AudioHandler.isMuted ? 'mute' : 'unmute',
    });
    this.toggleMutedState();
  };

  muteButton = () => {
    const style = playlistStyle;
    return (
      <TouchableWithoutFeedback
        hitSlop={Utility.getHitSlop()}
        onPress={this.onTapMuteButton}
      >
        <FastImageView
          source={
            AudioHandler.isMuted
              ? images.volumeMuteButton
              : images.volumeUnmuteButton
          }
          style={style.verticalPlaylistCardMuteButton}
        />
      </TouchableWithoutFeedback>
    );
  };

  fullScreenButton = () => {
    const style = playlistStyle;
    return (
      <TouchableWithoutFeedback onPress={this.debouncedFullScreenButton}>
        <View style={style.verticalPlaylistCardFullScreenButtonContainer}>
          <FastImageView
            source={images.fullScreenMaximize}
            style={style.verticalPlaylistCardFullScreenButton}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  controlButtons = () => {
    const style = playlistStyle;
    const { item = {} } = this.props;
    const { isBuffering } = this.state;
    const { metadata: { contentPlatform = '' } = {} } = item;
    if (isBuffering) {
      return null;
    }
    return (
      <View style={style.verticalCardFullScreenButtonContainer}>
        {contentPlatform === ContentPlatforms.foxy ? <this.muteButton /> : null}
        <this.fullScreenButton />
      </View>
    );
  };

  renderMedia = (props) => {
    const { shouldPlay, isBuffering, screenInFocus } = this.state;

    if (!shouldPlay) {
      return null;
    }

    const {
      item = {},
      navigation,
      iteratorIndex,
      totalNumberOfVideos,
    } = this.props;

    const {
      image_url: imageUrl,
      metadata: { contentPlatform, videoUrl, id: videoId, duration } = {},
    } = item;
    if (contentPlatform !== ContentPlatforms.foxy) {
      return null;
    }

    const styles = playlistStyle;
    const play = shouldPlay && screenInFocus;

    const mixWithOthers = AudioHandler.isMuted ? 'mix' : 'inherit';

    const url = videoUrl.replace(VIDEO_DISTRIBUTION_URL, VIDEO_CDN_URL);

    return (
      <View style={styles.verticalCardMediaContainer}>
        <MediaComponent
          // for seek to value
          // ref={this.saveMediaComponentRef}
          videoId={videoId}
          videoUrl={url}
          contentPlatform={contentPlatform}
          duration={duration}
          videoMetadata={this.videoMetadata}
          playerStyle={styles.verticalPlaylistPlayerStyle}
          playerContainerStyle={this.mediaStyle}
          paused={!play}
          imageUrl={imageUrl}
          currentPage={play}
          reportBuffering={this.reportBuffering}
          setCurrentTime={this.setCurrentTime}
          onLoadVideo={this.onLoadVideo}
          onNextButtonTapped={this.onNextButtonTapped}
          onNextButtonClick={this.onNextButtonClick}
          // videoView={videoView}
          // userEngagement={user_engagement}
          navigation={navigation}
          muted={AudioHandler.isMuted}
          isVerticalCard={true}
          mixWithOthers={mixWithOthers}
          seekToTimeOnLoad={this.currentTime}
          isLastVideo={iteratorIndex === totalNumberOfVideos - 1}
        />
      </View>
    );
  };

  setCurrentTime = (time) => {
    this.currentTime = time;
  };

  onLoadVideo = () => {};

  onNextButtonClick = () => {};

  MediaDescription = (props) => {
    const { artistName, artistImage, title, verified, rating } = props;
    const styles = MediaCardStyles;
    const descriptionStyle = styles.verticalCardMediaDescriptionContainer;
    const gradientArray = ['rgba(0, 0, 0, 0.75)', 'rgba(0, 0, 0, 0)'];
    const marginTop = 0;
    return (
      <View style={descriptionStyle}>
        <LinearGradient
          colors={gradientArray}
          style={styles.verticalPlaylistCardGradientStyle}
        >
          <View style={styles.verticalCardArtistContainer}>
            <FastImageView
              resizeMode="cover"
              source={artistImage}
              style={styles.verticalCardArtistMiniAvatar}
            />
            <View style={styles.verticalPlaylistCardArtistDetails}>
              <Text
                numberOfLines={MAX_LINES.single}
                style={styles.verticalPlaylistCardTitle}
              >
                {title}
              </Text>
              <Text
                numberOfLines={MAX_LINES.single}
                style={styles.verticalPlaylistCardArtistName}
              >
                {artistName}
              </Text>
              <View style={styles.ratingStars}>
                <ProductRatingAndMoreInfo
                  // onRatingTap={this.scrollToRatings}
                  rating={rating}
                  // renderCertificateButton={this.certificateButton}
                  // id={1234}
                  // sku_id={1234}
                  disableRatingText
                />
              </View>
              {/* <Text
              numberOfLines={MAX_LINES.single}
              style={styles.verticalPlaylistCardArtistRating}
            >
              4.5
            </Text> */}
            </View>
            {/* <this.verifiedTickWithCondition
              verified={verified}
              source={images.verifiedTick}
              style={MediaCardStyles.verifiedTick}
            /> */}
          </View>
        </LinearGradient>
      </View>
    );
  };

  cardTapped = () => {
    const { shouldPlay } = this.state;
    const {
      item = {},
      onItemPress = () => {},
      routineActivityId = '',
    } = this.props;
    const { metadata: { contentPlatform } = {} } = item;
    this.isCardTappedForFirstTime = true;
    AnalyticsManager.logEvent(EventType.verticalVideoCard.VERTICAL_VIDEO_CARD, {
      ...this.videoMetadata,
      [EventParameterKey.CLICK]: 'card',
    });
    onItemPress(routineActivityId, ROUTINE_ACTIVITY_TYPES.VIDEO);
    // if (this.isCurrentIndex) {
    this.navigateToMedia();
    // if (contentPlatform !== ContentPlatforms.foxy) {
    //   this.navigateToMedia();
    //   return;
    // }
    // if (shouldPlay) {
    //   if (this.currentTime == 0) {
    //     this.setState({isBuffering: true})
    //   } else {
    //     this.toggleMutedState();
    //   }
    // } else {
    //   this.togglePlayStatus()
    // }
    // }
  };

  togglePlayStatus = () => {
    this.setState((prevState) => ({
      shouldPlay: !prevState.shouldPlay,
    }));
  };

  render() {
    const style = playlistStyle;
    const { item = {}, cardWidth } = this.props;
    const { metadata: { contentPlatform, title } = {}, rating = 0 } = item;
    const { entity: { name: entityName = '', image_url: imageUrl } = {} } =
      item;
    const { isBuffering, shouldPlay, screenInFocus } = this.state;

    const entityImage = Utility.isBlank(imageUrl)
      ? images.profilePlaceholder
      : { uri: Utility.getMinifiedImage(imageUrl, 120, 120) };

    const play = shouldPlay && screenInFocus;
    const cardStyle =
      cardWidth < verticalPlaylistCardWidth
        ? style.smallVerticalRailItemCard
        : style.verticalRailItemCard;
    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedCard}>
        <Card style={cardStyle}>
          <VerticalMediaBgImage
            showBgImage={this.showBgImage || !this.isCurrentIndex}
            imageUrl={item.image_url}
            cardWidth={cardWidth}
          />
          <this.renderMedia />
          <VerticalMediaImage
            isBuffering={
              isBuffering || contentPlatform !== ContentPlatforms.foxy
            }
            showImage={!shouldPlay}
            imageUrl={item.image_url}
            cardWidth={cardWidth}
          />
          <this.MediaDescription
            artistName={entityName}
            artistImage={entityImage}
            title={title}
            // verified={verified}
            rating={rating}
          />
          {play && contentPlatform === ContentPlatforms.foxy ? null : (
            <this.playButton />
          )}
          <this.renderMiddleControlsCondition />
          <this.controlButtons />
        </Card>
      </ScaleAnimate>
    );
  }
}

export default withNavigation(React.memo(VerticalSingleMediaCard));
