import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ScrollView,
  BackHandler,
  StyleSheet,
} from 'react-native';
import { TabBar, TabView } from 'react-native-tab-view';
import _ from 'lodash';
import { connect } from 'react-redux';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import BottomSheet from '../onboarding/BottomSheet';
import ContentCheckBox from './filter-modal-content/ContentCheckBox';
import ContentQuickFilters from './filter-modal-content/ContentQuickFilters';
import ActionButton from '../shared/ActionButton';
import ContentFoxyMatch from './filter-modal-content/ContentFoxyMatch';
import FilterAnalyticsEvents from './FiltersAnalyticsEvents';
import Config from '../../libraries/ReactNativeConfig';
import FilterUtils from './FilterUtils';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';

import withNavigation from '../../utils/WithNavigation';
import { isDesktop } from '../../utils/BooleanUtility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';

class SortingFilteringModal extends PureComponent {
  constructor(props) {
    super(props);

    const {
      route: {
        params: {
          listId = '',
          listName = '',
          selectedFilters,
          listData = {},
          listSlug = '',
          filterModel = [],
        } = {},
      } = {},
    } = this.props;

    this.state = {
      index: 0,
      routes: filterModel,
      foxyMatchRoute: [],
      localSelectedFilters: selectedFilters || {},
    };
    this.appliedFilters = [];
    for (let key in selectedFilters) {
      this.appliedFilters = [...this.appliedFilters, ...selectedFilters[key]];
    }
    const {
      list: {
        id: filterListId = '',
        name: filterListName = '',
        slug: filterListSlug = '',
      },
    } = FilterUtils.getList(listData?.list || listData);
    this.tempFilterValues = []; // to be use while reseting the fiters
    this.resetMethods = [];
    this.pushFoxyMatchRoute = [];
    this.memoizeLabelTitleColor = {};
    this.memoizeModalHeight = {};
    this.buttonWidth =
      (Utility.getScreenWidth() - (12 - Utility.spacingBetweenItems) * 2 - 16) /
      2;
    this.listName = filterListName || listName;
    this.listId = filterListId || listId;
    this.listSlug = filterListSlug || listSlug;
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount = () => {
    const {
      route = {},
      facialAnalysis: { facialProperties },
    } = this.props;
    const { foxyMatchRoute } = this.state;
    const {
      params: {
        filterModel,
        listSlug = '',
        screenName = '',
        entityName = '',
      } = {},
    } = route;
    let filtersWithKey = _.map(filterModel, (element, index) => ({
      ...element,
      key: `${element.display}_${index}`,
    }));

    const indexOfFoxyMatch = _.findIndex(
      filtersWithKey,
      (element) => element.display === 'foxy_match',
    );
    if (indexOfFoxyMatch !== -1) {
      const commonUserAttribs = Utility.getCommonUserAttributes(
        facialProperties,
        filtersWithKey[indexOfFoxyMatch].criteria,
      );

      if (Utility.isBlank(commonUserAttribs)) {
        filtersWithKey = _.filter(
          filtersWithKey,
          (element) => element.display !== 'foxy_match',
        );
      }
    }

    const isFoxyMatchPresent = Utility.isPresent(
      filtersWithKey.filter((filter) => filter.display === 'foxy_match'),
    );
    this.tempFilterValues = filtersWithKey;
    if (isFoxyMatchPresent) {
      const lastRoute = filtersWithKey.pop();
      this.pushFoxyMatchRoute.push(lastRoute);
    }
    this.setState({
      routes: filtersWithKey,
      foxyMatchRoute: this.pushFoxyMatchRoute,
    });

    const visibleTabsOnModal = filterModel.map((element) => element.title);
    FilterAnalyticsEvents.logFilterModalOpenEvent(
      visibleTabsOnModal,
      { id: this.listId, name: this.listName, slug: this.listSlug },
      screenName,
      entityName,
    );
  };

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  // To update tab badge at while selecting chips
  groupSelectedFilter = (item) => {
    const groupBySelectedFilters = _.groupBy(
      item,
      (element) => element.criteria_name,
    );
    this.setState({
      localSelectedFilters: groupBySelectedFilters,
    });
    // return groupBySelectedFilters;
  };

  addResetFunction = (resetFunction) => {
    this.resetMethods.push(resetFunction);
  };

  onPressResetButton = () => {
    const {
      route: {
        params: { listSlug = '', entityName = '', screenName = '' } = {},
      } = {},
    } = this.props;
    this.appliedFilters = [];
    this.setState({
      localSelectedFilters: {},
    });
    this.resetMethods.forEach((element) => {
      element();
    });

    FilterAnalyticsEvents.logFilterResetEvent('modal', screenName, entityName, {
      id: this.listId,
      name: this.listName,
      slug: this.listSlug,
    });
  };

  onPressFilterApply = () => {
    const { route: { params: { onFilterApplyPress } = {} } = {} } = this.props;
    onFilterApplyPress(this.appliedFilters);
    this.closeModal();
  };

  replaceExistingSortCriteriaWithLatest = (criteria, item) => {
    let isSortByOrUserAttributesPresent = false;
    let sortByOrUserAttributesIndex = -1;
    let isUserAttributes = false;

    if (Utility.isBlank(this.appliedFilters)) {
      this.appliedFilters.push({
        criteria_name: criteria,
        value: item.value,
        name: item.name,
      });
    } else {
      this.appliedFilters.forEach((element, index) => {
        if (element.criteria_name === criteria) {
          if (criteria === 'user_attribute_allowed_value_id' || 'uaavs') {
            isUserAttributes = true;
          }
          isSortByOrUserAttributesPresent = true;
          sortByOrUserAttributesIndex = index;
        }
        // if (element.criteria_name === 'sort_by' || element.criteria_name === 'user_attribute_allowed_value_id') {
        //   if (Utility.isPresent(this.appliedFilters[index])) {
        //     this.appliedFilters[index] = { criteria_name: criteria, value: item.value, name: item.name };
        //   } else {
        //     this.appliedFilters.push({ criteria_name: criteria, value: item.value, name: item.name });
        //   }
        // }
      });

      if (isSortByOrUserAttributesPresent) {
        this.appliedFilters[sortByOrUserAttributesIndex] = {
          criteria_name: criteria,
          value: item.value,
          name: item.name,
        };

        if (isUserAttributes && Utility.isBlank(item.value)) {
          this.appliedFilters = this.appliedFilters.filter(
            (element, index) => index !== sortByOrUserAttributesIndex,
          );
        }
      } else {
        this.appliedFilters.push({
          criteria_name: criteria,
          value: item.value,
          name: item.name,
        });
      }
    }
  };

  onFilterSelect = (criteria, item) => {
    switch (criteria) {
      case 'sort_by':
      case 'user_attribute_allowed_value_id':
      case 'uaavs':
        this.replaceExistingSortCriteriaWithLatest(criteria, item);
        break;
      default:
        const currentFilter = this.appliedFilters.filter(
          (element) =>
            element.criteria_name === criteria && element.value === item.value,
        );
        if (Utility.isBlank(currentFilter)) {
          this.appliedFilters.push({
            criteria_name: criteria,
            value: item.value,
            name: item.name,
          });
        } else {
          this.appliedFilters = _.reject(
            this.appliedFilters,
            (element) =>
              element.criteria_name === criteria &&
              element.value === item.value,
          );
        }
        this.groupSelectedFilter(this.appliedFilters);
        break;
    }
  };

  renderFoxyMatchTab = (props) => {
    const { route, jumpTo } = props;
    const {
      route: {
        params: { entityName = '', screenName = '', selectedFilters } = {},
      } = {},
    } = this.props;
    const {
      facialAnalysis: { facialProperties },
    } = this.props;

    const commonUserAttribs = Utility.getCommonUserAttributes(
      facialProperties,
      route.criteria,
    );
    if (Utility.isBlank(commonUserAttribs)) {
      return null;
    }
    return (
      <ContentFoxyMatch
        headingText={route.title}
        jumpTo={jumpTo}
        onFilterSelect={_.memoize(
          (item) => this.onFilterSelect(route.criteria_name, item),
          (item) => [item.value, route.criteria_name],
        )}
        selectedFilters={selectedFilters[route.criteria_name]}
        facialAttributes={commonUserAttribs}
        screenName={screenName}
        entityName={entityName}
        listName={this.listName}
        listId={this.listId}
        listSlug={this.listSlug}
      />
    );
  };

  renderTabViews = ({ route, jumpTo }) => {
    const {
      route: {
        params: {
          selectedFilters,
          onPressHideOosCheckBox,
          hideOosProduct,
          entityName = '',
          screenName = '',
          hideBrandFilter = false,
        } = {},
      } = {},
    } = this.props;

    switch (route.display) {
      case 'radio':
        return (
          <View style={styles.radioMargin}>
            <ContentCheckBox
              listName={this.listName}
              listId={this.listId}
              listSlug={this.listSlug}
              criteria_name={route.criteria_name}
              jumpTo={jumpTo}
              options={route.criteria}
              onFilterSelect={_.memoize(
                (item) => this.onFilterSelect(route.criteria_name, item),
                (item) => [item.value, route.criteria_name],
              )}
              selectedFilters={selectedFilters[route.criteria_name]}
              applyResetFunction={this.addResetFunction}
              onPressHideOosCheckBox={onPressHideOosCheckBox}
              isHideOosProduct={hideOosProduct}
              screenName={screenName}
              entityName={entityName}
            />
          </View>
        );
      case 'chips':
        return (
          <ScrollView style={styles.chipsScrollViewStyle}>
            {route?.filters?.map((pills, index) => (
              <ContentQuickFilters
                listName={this.listName}
                listId={this.listId}
                listSlug={this.listSlug}
                criteria_name={route.criteria_name}
                jumpTo={jumpTo}
                options={pills.criteria}
                onFilterSelect={_.memoize(
                  (item) => this.onFilterSelect(pills.criteria_name, item),
                  (item) => [item.value, pills.criteria_name],
                )}
                headingText={pills.title}
                selectedFilters={selectedFilters[pills.criteria_name]}
                applyResetFunction={this.addResetFunction}
                index={index}
                screenName={screenName}
                entityName={entityName}
                title={route.title}
                hideBrandFilter={hideBrandFilter}
              />
            ))}
          </ScrollView>
        );
      default:
        return null;
    }
  };

  tabBadge = (props) => {
    const { criteria_name, display, focused } = props;
    const { localSelectedFilters } = this.state;
    let count = '';
    // if (Utility.isBlank(localSelectedFilters)) {
    //   count = selectedFilters && selectedFilters[criteria_name] ? selectedFilters[criteria_name].length : 0;
    // } else {
    count =
      localSelectedFilters && localSelectedFilters[criteria_name]
        ? localSelectedFilters[criteria_name].length
        : 0;
    // }
    if (display !== 'chips' || count == 0) return null;

    const badgeWrapperStyle = focused
      ? styles.badgeWrapperHighlighted
      : styles.badgeWrapper;
    return (
      <View style={styles.badgeContainer}>
        <View style={badgeWrapperStyle}>
          <Text style={styles.badgeText}>{count}</Text>
        </View>
      </View>
    );
  };

  memoizedLabelTitleColor = (color) => {
    if (!this.memoizeLabelTitleColor[color]) {
      this.memoizeLabelTitleColor[color] = [
        styles.labelTitleStyle,
        { color: color },
      ];
    }
    return this.memoizeLabelTitleColor[color];
  };

  _renderLabel = ({ route, focused }) => {
    if (route.display === 'foxy_match') return null;
    const labelTitleColor = focused ? colors.foxyBlack : colors.subtitle;
    return (
      <View style={styles.labelContainer}>
        <Text style={this.memoizedLabelTitleColor(labelTitleColor)}>
          {route.title}
        </Text>
        <this.tabBadge
          criteria_name={route.criteria_name}
          display={route.display}
          focused={focused}
        />
      </View>
    );
  };

  renderTabBar = (props) => (
    <TabBar
      {...props}
      bounces
      renderLabel={this._renderLabel}
      scrollEnabled
      indicatorStyle={styles.indicatorStyle}
      style={styles.tabBarStyle}
      tabStyle={styles.tabStyle}
      pressColor="transparent"
    />
  );

  tabView = () => {
    const { index, routes } = this.state;
    return (
      <TabView
        navigationState={{ index, routes }}
        renderScene={this.renderTabViews}
        onIndexChange={this.onIndexChange}
        renderTabBar={this.renderTabBar}
        style={styles.tabBarShadow}
      />
    );
  };

  onIndexChange = (index) => {
    const {
      route: { params: { entityName = '', screenName = '' } = {} } = {},
    } = this.props;
    this.setState({ index });
    AnalyticsManager.logEvent(EventType.discoveryEvents.FILTER_ACTION, {
      [EventParameterKey.FILTER_SECTION]: index == 1 ? 'Filters' : 'Sort',
      [EventParameterKey.LIST_ID]: this.listId,
      [EventParameterKey.LIST_NAME]: this.listName,
      [EventParameterKey.LIST_SLUG]: this.listSlug,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.FILTER_INDEX]: index,
    });
  };

  memoizedModalHeight = (height) => {
    if (!this.memoizeModalHeight[height]) {
      this.memoizeModalHeight[height] = [
        styles.bottomSheetContainer,
        { height },
      ];
    }
    return this.memoizeModalHeight[height];
  };

  buttonContainer = () => {
    const secondaryButtonColor = Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.foxyBlack;

    const strokeButtonUnderlayColor =
      Config.FILTER_MODAL_STROKE_BUTTON_UNDERLAY_COLOR ||
      colors.foxyShadowStrokeButtonUnderlay;

    const filledButtonUnderlayColor =
      Config.FILTER_MODAL_FILLED_BUTTON_UNDERLAY_COLOR ||
      colors.foxyShadowButtonUnderlayColor;

    const primaryButtonContainerStyle = [
      styles.primaryButtonContainer,
      isDesktop() && { flex: 1, marginRight: 12 },
    ];

    const secondaryButtonContainerStyle = [
      styles.secondaryButtonContainer,
      isDesktop() && { flex: 1, marginLeft: 12 },
    ];

    const rowButtonsContainer = [
      styles.rowButtonsContainer,
      isDesktop() && { width: '100%', marginLeft: 0, paddingHorizontal: 24 },
    ];

    const buttonWidth = isDesktop() ? '99.5%' : this.buttonWidth - 4;

    return (
      <View style={rowButtonsContainer}>
        <FoxyShadowButton
          width={buttonWidth}
          title={'Reset'}
          onPress={this.onPressResetButton}
          style={primaryButtonContainerStyle}
          textStyle={styles.primaryButtonText}
          firstColor={colors.transparent}
          secondColor={colors.transparent}
          underlayColor={strokeButtonUnderlayColor}
          actionIcon={images.history}
          border={styles.border}
          showActionIcon
          actionIconStyle={styles.resetButtonIcon}
          underlayWidth={buttonWidth}
        />
        <FoxyShadowButton
          width={buttonWidth}
          title={'Apply'}
          onPress={this.onPressFilterApply}
          style={secondaryButtonContainerStyle}
          textStyle={styles.secondaryButtonText}
          firstColor={secondaryButtonColor}
          secondColor={secondaryButtonColor}
          underlayColor={filledButtonUnderlayColor}
        />
      </View>
    );
  };

  closeModal = () => {
    const { navigation } = this.props;
    navigation.pop();
  };

  render() {
    const {
      route: { params: { selectedFilters, showFoxyMatchModal } = {} } = {},
    } = this.props;
    const { foxyMatchRoute } = this.state;
    const modalHeight = !showFoxyMatchModal ? 600 : 350;
    return (
      <>
        <DebouncedTouchableOpacity
          style={styles.touchableContainer}
          onPress={this.closeModal}
        />

        <View style={styles.modalContainer} pointerEvents='box-none'>
          <View style={this.memoizedModalHeight(modalHeight)}>
            {!showFoxyMatchModal && <this.tabView />}
            {showFoxyMatchModal &&
              foxyMatchRoute.map((route) => {
                return <this.renderFoxyMatchTab route={route} />;
              })}
            <this.buttonContainer />
          </View>
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
});

export default withNavigation(
  connect(mapStateToProps, null)(SortingFilteringModal),
);

const styles = StyleSheet.create({
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    justifyContent: 'flex-end',
    bottom: 0,
    width: '100%',
    backgroundColor: colors.translucent,
  },
  bottomSheetContainer: {
    position: 'absolute',
    bottom: 0,
    height: 600,
    width: '100%',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: colors.white,
    paddingBottom: Utility.isIOS() ? Utility.bottomInset : 8,
  },
  row: {
    flexDirection: 'row',
    alignContent: 'center',
    paddingBottom: Utility.isIOS() ? Utility.bottomInset + 8 : 0,
  },
  rowContainer: {
    flex: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    borderColor: colors.black,
    width: 172,
    height: 46,
  },
  rowButtonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  resetButton: {
    height: 20,
    width: 20,
    marginTop: 1,
    marginLeft: 8,
    alignItems: 'center',
  },
  resetButtonText: {
    fontFamily: 'Roboto-Medium',
    marginLeft: 10,
    fontSize: size.h2,
    color: Config.FILTER_MODAL_RESET_BUTTON,
  },

  tabBarStyle: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: colors.white,
    paddingBottom: 0,
    height: 48,
    shadowOpacity: 0,
    elevation: 0,
  },
  indicatorStyle: {
    backgroundColor: Config.FILTER_MODAL_INDICATOR_COLOR,
  },

  badgeContainer: {
    // position:'absolute',
    left: 6,
    bottom: 3,
  },
  badgeWrapper: {
    height: 14,
    width: 14,
    backgroundColor: colors.subtitle,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginTop: 18,
  },
  badgeWrapperHighlighted: {
    height: 14,
    width: 14,
    marginTop: 18,
    backgroundColor: Config.FILTER_MODAL_BADGE_COLOR,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 9,
    color: colors.white,
  },
  tabBarShadow: {
    elevation: 0,
    shadowOpacity: 0,
  },
  tabStyle: {
    width: isDesktop()
      ? Utility.getScreenWidth() / 1.5
      : Utility.getScreenWidth() / 2,
    alignContent: 'center',
  },
  buttonWidth: {
    width: 170,
  },
  rowButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: Utility.getScreenWidth() - 24,
    marginLeft: 12,
  },
  primaryButtonContainer: {
    marginTop: 12,
    width:
      (Utility.getScreenWidth() - (12 - Utility.spacingBetweenItems) * 2 - 16) /
      2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  secondaryButtonText: { borderRadius: 8 },
  secondaryButtonContainer: {
    marginTop: 12,
    alignItems: 'center',
    width:
      (Utility.getScreenWidth() - (12 - Utility.spacingBetweenItems) * 2 - 16) /
      2,
  },
  primaryButtonText: { color: colors.foxyBlack },
  border: {
    borderWidth: 1,
    borderColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.foxyBlack,
    borderStyle: 'solid',
    borderRadius: 8,
  },
  labelContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chipsScrollViewStyle: {
    marginTop: 22,
  },
  radioMargin: {
    marginTop: 22,
  },
  resetButtonIcon: {
    width: 16,
    height: 20,
    resizeMode: 'contain',
    marginRight: 6,
    overflow: 'visible',
  },
  labelTitleStyle: {
    fontSize: size.h2,
    textAlign: 'center',
    fontFamily: 'Roboto-Medium',
    paddingTop: 12,
    color: colors.foxyBlack,
  },
});
