import StyleSheet from 'react-native-media-query';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';

export const { ids, styles } = StyleSheet.create({
  fullWidthShimmer: {
    width: Utility.getScreenWidth(),
    flex: 1,
    height: '99%',
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 8,
    paddingBottom: 6,
  },
  contentContainerStyle: {},
  scrollViewContentContainer: {
    flex: 1,
  },
  // flatListContainer: { paddingRight: 12 },
  flatList: {
    left: 0,
    marginBottom: 30,
  },
  verticalListContainer: {
    backgroundColor: colors.white,
    width: Utility.getScreenWidth() * 0.25,
  },
  tabSelected: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingVertical: 12,
    paddingHorizontal: 12,
    backgroundColor: '#F4F5F7',
    flex: 1,
  },
  tabUnselected: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingVertical: 12,
    paddingHorizontal: 12,
    backgroundColor: colors.white,
    flex: 1,
  },
  imageForYou: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
  tabImage: {
    height: 40,
    width: 40,
    borderRadius: 0,
  },
  tabTitleContainer: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabTitleStyle: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
  },
  selectedTabTitleStyle: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
  },
  listContentStyle: {
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth() * 0.75,
    justifyContent: 'flex-end',
  },
  scrollViewStyle: {
    width: '100%',
  },
  container: {
    flexDirection: 'row',
    width: Utility.getScreenWidth(),
    justifyContent: 'space-between',
    backgroundColor: colors.background,
    flex: 1,
    alignSelf: 'center',
  },
  listContainer: {
    paddingVertical: 16,
    height: '100%',
  },
  headerContainer: {
    paddingTop: 16,
  },
  headerText: {
    color: colors.black,
    fontSize: 20,
    fontFamily: 'Roboto-Bold',
    alignSelf: 'flex-start',
    left: 0,
  },
  shopAllButtonStyle: {
    color: colors.black,
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  arrowImage: {
    width: 24,
    height: 14,
    resizeMode: 'contain',
    tintColor: colors.black,
    alignSelf: 'center',
  },
  imageContainer: {
    paddingTop: 5,
    paddingLeft: 5,
  },
  footerStyle: {
    height: 230,
    width: Utility.getScreenWidth() * 0.75,
  },
  indicatorStyle: {
    width: 2,
    height: 28,
    position: 'absolute',
    left: -12,
    alignSelf: 'center',
    alignContent: 'center',
  },
  headerRadius: {
    position: 'absolute',
    height: 8,
    width: Utility.getScreenWidth() * 0.25,
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    alignSelf: 'center',
    borderBottomRightRadius: 40,
    top: -4,
  },
  bottomRadius: {
    position: 'absolute',
    height: 8,
    width: Utility.getScreenWidth() * 0.25,
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    alignSelf: 'center',
    borderTopRightRadius: 20,
    bottom: 0,
  },
});
