import 'setimmediate';
import { AppRegistry } from 'react-native';
import TagManager from 'react-gtm-module';
import App from './App';
import './index.css';
import { setListRefHelper } from './containers/List/listRefHelper';
import { getListComponent, getListComponentHeight } from './containers/List/ListHelper';
import { getVerticalComponent, getVerticalListOffsets } from './components/layout/Vertical/VerticalHelper';
import Config from './libraries/ReactNativeConfig';

console.tron = {};
console.tron.log = console.log;

setListRefHelper({
  getListComponentRef: getListComponent,
  getListComponentHeightRef: getListComponentHeight,
  getVerticalComponentRef: getVerticalComponent,
  getVerticalListOffsetsRef: getVerticalListOffsets,
});

const appName = Config.APP_NAME;
const tagManagerArgs = {
  gtmId: Config.GTM_ID,
  dataLayerName: 'webDataLayer',
};

TagManager.initialize(tagManagerArgs);

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  // Mount the react-native app in the "root" div of index.html
  rootTag: document.getElementById('root'),
});
