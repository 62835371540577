// Dependencies
import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  //AsyncStorage,
} from 'react-native';
import LinearGradient from '../../libraries/ReactNativeLinearGradient';
import { ProductDetailStyles, ProductCardStyles } from './styles';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  requestProduct,
  editProfile,
  setNotificationModalDisplayTime,
} from '../../actions/ActionTypes';
import AddEmailModal from './AddEmailModal';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import images from '../../theme/Images';
import smartLock from 'react-native-smartlock';
import NotificationModal from '../../utils/Notification/NotificationModal';
import { NOTIFICATION_REQUEST_MODAL_TYPE } from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { debounce } from 'lodash';
import Config from '../../libraries/ReactNativeConfig';
import {
  AnalyticsManager,
  EventParameterKey,
} from '../../analytics';
import AsyncStorage from '@react-native-async-storage/async-storage';

class RequestProductButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailModalVisible: false,
      canDisplayNotificationRequestModal: false,
      showNotificationPrompt: false,
    };
    this.debouncedRequestProductAPI = debounce(this.hitRequestProductAPI, 500, {
      leading: true,
      trailing: false,
    });
  }

  checkForEmail = () => {
    try {
      smartLock
        .getCredentials()
        .then((credentials) => {
          this.saveEmailOnServer(credentials.id);
        })
        .catch((err) => {
          console.log('error', err);
          this.toggleEmailModal();
        });
    } catch (error) {
      console.log('error', error);
      this.toggleEmailModal();
    }
  };

  saveEmailOnServer = (email) => {
    const { editProfile, skuId } = this.props;
    const isValid = Utility.validateEmail(email);
    if (!isValid) {
      return;
    }
    const user = {};
    user.email = email;

    editProfile(user, {}, (callbackData, success) => {
      if (success) {
        const { email, skuId, stocked_status } = this.props;
        AnalyticsManager.logEvent(EventParameterKey.ITEM_ACTION_NEW, {
          [EventParameterKey.TYPE]: 'hint_request_email',
          [EventParameterKey.SKU_ID]: skuId,
          [EventParameterKey.EMAIL_PRESENT]: Utility.isPresent(email),
          [EventParameterKey.STOCKED_STATUS]: stocked_status,
        });
        this.requestProduct(skuId);
      } else {
        //failure case show modal to get email from user
        this.toggleEmailModal();
      }
    });
  };

  buttonTapped = () => {
    this.displayNotificationRequestModal();
  };

  displayNotificationRequestModal = () => {
    const {
      lastNotificationModalDisplayTime,
      setNotificationModalDisplayTime,
      initial_app_opened_at,
    } = this.props;
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      'outOfStock',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          const currentDate = new Date();
          setNotificationModalDisplayTime(currentDate.getTime());
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  showNotificationModal = (show, showNotification) => {
    this.setState({
      canDisplayNotificationRequestModal: show,
      showNotificationPrompt: showNotification,
    });
  };

  hitRequestProductAPI = () => {
    const { email, skuId, stocked_status } = this.props;
    AnalyticsManager.logEvent(EventParameterKey.ITEM_ACTION_NEW, {
      [EventParameterKey.TYPE]: 'notify_me',
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.EMAIL_PRESENT]: Utility.isPresent(email),
      [EventParameterKey.STOCKED_STATUS]: stocked_status,
    });
    if (Utility.isBlank(email)) {
      if (Utility.isAndroid()) {
        //Try to get email from hint prompt first
        this.checkForEmail(skuId);
        return;
      }
      //check if email exist and then proceed or show email modal
      this.toggleEmailModal();
      return;
    }
    this.storeData(skuId);
    this.requestProduct(skuId);
  };

  storeData = async (skuId) => {
    if (Utility.isBlank(skuId)) return;
    try {
      await AsyncStorage.setItem(`${skuId}`, 'true');
    } catch (error) {
      console.log('Error saving data');
      // Error saving data
    }
  };

  requestProduct = (skuId) => {
    const { requestProduct, showToast = () => {}, unstocked } = this.props;
    const askForReviewToast = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.ask_for_review_toast_message,
    );

    const stockedOutToast = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.out_of_stock_toast_message,
    );

    const toastMessage = unstocked ? askForReviewToast : stockedOutToast;
    showToast(toastMessage, 1500);
    requestProduct(skuId, (success) => {
      if (!success) {
        this.displayNotificationRequestModal();
      }
    });
  };

  buttonDetails = (addToCartButtonStyle, disabled, buttonText) => {
    return (
      <TouchableOpacity
        style={[
          addToCartButtonStyle,
          { backgroundColor: disabled ? colors.disabled : colors.green },
        ]}
        onPress={this.buttonTapped}
        disabled={disabled}
      >
        <Text style={ProductDetailStyles.addToBagText}>{buttonText}</Text>
      </TouchableOpacity>
    );
  };

  toggleEmailModal = () => {
    this.setState((prevState) => ({
      isEmailModalVisible: !prevState.isEmailModalVisible,
    }));
  };

  render() {
    const {
      skuId,
      priority,
      addToCartLayout,
      layout,
      theme = 'light',
      unstocked,
      variantModal,
      extraButtonStyles,
    } = this.props;
    const addToCartButtonStyle = variantModal
      ? ProductDetailStyles.addToBagViewVariantModal
      : ProductDetailStyles.addToBagView;
    const {
      isEmailModalVisible,
      canDisplayNotificationRequestModal,
      showNotificationPrompt,
    } = this.state;
    const disabled = priority === 'Discontinued';
    let buttonText = 'Notify when available';
    let notificationRequestModalType =
      NOTIFICATION_REQUEST_MODAL_TYPE.PRODUCT_OUT_OF_STOCK;
    if (disabled) {
      buttonText = 'Discontinuend';
      notificationRequestModalType =
        NOTIFICATION_REQUEST_MODAL_TYPE.PRODUCT_OUT_OF_STOCK;
    }
    if (unstocked) {
      buttonText = 'Ask for review';
    }
    if (addToCartLayout === PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD) {
      const styleCart = ProductCardStyles[`${layout}CartStyle`];
      const imageSource = images.addedToCart[theme][false];
      return (
        <TouchableOpacity onPress={this.hitRequestProductAPI}>
          <View style={styleCart}>
            <Image
              source={imageSource}
              style={ProductCardStyles[`${layout}AddToCartImage`]}
            />
          </View>
        </TouchableOpacity>
      );
    }

    const gradientColors = [
      Config.LINEAR_GRADIENT_PRIMARY ?? '#51CB8C',
      Config.LINEAR_GRADIENT_SECONDARY ?? '#03B460',
    ];
    return (
      <>
        <TouchableOpacity
          onPress={this.debouncedRequestProductAPI}
          hitSlop={Utility.getHitSlop()}
        >
          <LinearGradient
            style={[
              variantModal
                ? ProductDetailStyles.bagContainerVariantModalSimilar
                : ProductDetailStyles.bagContainerSimilar,
              extraButtonStyles,
            ]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={gradientColors}
          >
            <View style={ProductDetailStyles.addToBagViewVariantModal}>
              <Text style={ProductDetailStyles.addToBagText}>{buttonText}</Text>
            </View>
          </LinearGradient>
        </TouchableOpacity>
        {canDisplayNotificationRequestModal ? (
          <NotificationModal
            isNotificationModalVisible={canDisplayNotificationRequestModal}
            showNotificationModal={this.showNotificationModal}
            showNotificationPrompt={showNotificationPrompt}
            type={notificationRequestModalType}
            showAnimation
          />
        ) : (
          <AddEmailModal
            isVisible={isEmailModalVisible}
            hideEmailModal={this.toggleEmailModal}
            furtherAction={this.hitRequestProductAPI}
            {...this.props}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  email: state.UserAccountInfo.profile.email,
  remoteConfigData:
    state.UserAccountInfo.remote_configs &&
    state.UserAccountInfo.remote_configs.data
      ? state.UserAccountInfo.remote_configs.data
      : {},
  lastNotificationModalDisplayTime:
    state.UserAccountInfo.lastNotificationModalDisplayTime,
  initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { requestProduct, editProfile, setNotificationModalDisplayTime },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestProductButton);
