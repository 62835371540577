import React, { PureComponent } from 'react';
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styles } from './styles';
import _ from 'lodash';
import List from '../../../containers/List/List';
import FastImageView from '../../FastImageView';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import { AnalyticsUtilty } from '../../../analytics';
import { fetchList } from '../../../actions/ActionTypes';
import images from '../../../theme/Images';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import ErrorBoundary from '../../shared/ErrorBoundary';
import { ScreenPlaceholder } from '../../shared';
import colors from '../../../theme/Colors';

class PageWithSideBar extends PureComponent {
  constructor(props) {
    super(props);
    const { item } = this.props;

    this.key = 1;
    this.index = 1;

    this.state = {
      selectedOptionData: item[0],
      items: item,
      serverError: false,
      connectionError: false,
      refreshing: false,
      selectedIndex: 0,
    };
    this.fnAutoListPagination = null;
    this.extraStyle = {
      width: (Utility.getScreenWidth() - 32) * 0.75,
      color: colors.black,
    };
  }

  componentDidMount() {
    this.getObjects(this.index);
  }

  fireEvent = (item, index) => {
    const {
      previousScreen,
      listIndex,
      listData: {
        name: listName = '',
        display: listDisplay = '',
        content: listContent = '',
        slug: listSlug = '',
        id: listId = '',
      },
    } = this.props;
    const { id = '', name = '', type = '', slug = '' } = item;
    AnalyticsUtilty.fireItemClickEvent(
      previousScreen,
      id,
      type,
      name,
      index,
      listId,
      listDisplay,
      listName,
      listIndex,
      '',
      '',
      listContent,
      '',
      slug,
      listSlug,
    );
  };

  keyExtractor = (_, index) => index.toString();

  getObjects = (index) => {
    const { fetchList } = this.props;
    const { items, selectedIndex } = this.state;
    if (index === 0 || index >= items.length) {
      return;
    }
    Promise.all([
      fetchList({ slug: items[index]?.slug }, 0, (success, response) => {
        const newObject = [...items];

        newObject[index] = response;

        this.setState(
          {
            items: newObject,
            refreshing: false,
            selectedOptionData: newObject[selectedIndex],
            serverError: response.status === 'server_error',
            connectionError: response.status === 'connection_error',
          },
          () => {
            this.getObjects(++this.index);
          },
        );
      }),
    ]);
  };

  renderItem = ({ item = {}, index }) => {
    const { selectedIndex } = this.state;
    const isSelected = index === selectedIndex;
    const tabStyle = isSelected ? styles.tabSelected : styles.tabUnselected;
    const tabTitleStyle = isSelected
      ? styles.selectedTabTitleStyle
      : styles.tabTitleStyle;
    return (
      <TouchableOpacity
        onPress={_.memoize(
          () => this.onTabPress(item, index),
          () => [index],
        )}
        style={tabStyle}
      >
        {isSelected && index !== 0 && <View style={styles.headerRadius} />}
        <View style={styles.tabTitleContainer}>
          {isSelected && (
            <FastImageView
              source={images.tabIndicator}
              style={styles.indicatorStyle}
            />
          )}
          <Text
            style={tabTitleStyle}
            allowFontScaling={false}
            numberOfLines={2}
          >
            {item.name}
          </Text>
        </View>
        {isSelected && <View style={styles.bottomRadius} />}
      </TouchableOpacity>
    );
  };

  setListPaginatedCallApiRef = (fnAutoListPagination) => {
    this.fnAutoListPagination = fnAutoListPagination;
  };

  onTabPress = (item, index) => {
    const { selectedIndex } = this.state;
    const { items } = this.state;
    if (selectedIndex === index) return null;

    this.setState({
      selectedIndex: index,
    });
    setTimeout(() => {
      this.setState({
        refreshing: true,
      });
    }, 0);
    const newItems = [...items] || [];
    const { objects: listObjects = [] } = newItems[index];
    const lastListIndex = listObjects.length - 1;
    if (Utility.isBlank(listObjects[lastListIndex])) return null;
    const productList = newItems[index]?.objects[lastListIndex]?.objects;
    // To enhance performance,it is necessary to slice the product list data.
    const slicedProductList = productList?.slice(0, 10);
    newItems[index].objects[lastListIndex].objects = slicedProductList;
    setTimeout(() => {
      this.setState({
        selectedOptionData: newItems[index] || {},
        refreshing: false,
      });
      this.scrollToTop();
    }, 0);
    this.fireEvent(item, index);
  };

  renderVerticalTabsList = () => {
    const { items } = this.state;
    return (
      <View style={styles.verticalListContainer}>
        <FlatList
          data={items}
          renderItem={this.renderItem}
          keyExtractor={this.keyExtractor}
          style={styles.flatList}
          extraData={this.state}
        />
      </View>
    );
  };

  setListRef = (ref) => {
    this.flatListRef = ref;
  };

  renderListContent = () => {
    const { navigation, listIndex, parentListsData = {} } = this.props;
    const { refreshing, selectedOptionData } = this.state;
    const {
      selectedOptionData: {
        objects,
        paginate_last_item: paginateLastItem = false,
      } = {},
    } = this.state;
    if (refreshing || Utility.isBlank(objects)) {
      return (
        <ScreenPlaceholder previousScreen={SCREEN_CONSTANTS.VERTICAL_TABBED} />
      );
    }
    const totalItems = objects?.length - 1;
    return (
      <View style={styles.listContentStyle}>
        <List
          itemData={selectedOptionData}
          index={listIndex}
          navigation={navigation}
          previousScreen={SCREEN_CONSTANTS.CATEGORY_BROWSING}
          sectionListFooter={this.renderFooter}
          setListPaginatedCallApiRef={this.setListPaginatedCallApiRef}
          sectionListHeader={this.renderHeader}
          paginateLastItem={paginateLastItem}
          totalItemsInList={totalItems}
          disableRefresh
          setListRef={this.setListRef}
          extraStyleParameter={this.extraStyle}
          setListRef={this.props?.setListRef}
        />
      </View>
    );
  };

  renderHeader = () => {
    return <View style={styles.headerContainer} />;
  };

  scrollToTop = () => {
    this.flatListRef?.scrollToOffset({ animated: true, offset: 0 });
  };

  renderFooter = () => {
    return <View style={styles.footerStyle} />;
  };

  render() {
    const { items, serverError, connectionError } = this.state;
    if (Utility.isBlank(items)) {
      return (
        <ScreenPlaceholder
          previousScreen={SCREEN_CONSTANTS.CATEGORY_BROWSING}
        />
      );
    }
    return (
      <ErrorBoundary
        showServerError={serverError}
        connectionError={connectionError}
        hideHeader
      >
        <View style={styles.container}>
          <this.renderVerticalTabsList />
          <this.renderListContent />
        </View>
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchList,
    },
    dispatch,
  ),
});

export default withNavigation(connect(null, mapDispatchToProps)(PageWithSideBar));
