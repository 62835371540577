import React, { Component } from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  Keyboard,
  Image,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';
import OTPInputView from '@twotalltotems/react-native-otp-input';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { ids, styles } from './Styles/OTPVerificationStyles';
import images from '../../theme/Images';
import colors, { Theme } from '../../theme/Colors';
import size from '../../theme/Fonts';
import {
  SCREEN,
  ANDROID,
  LOGIN_MODAL_STATE,
  LOGIN_MODAL_MESSAGES,
  COUNTRY_CODE,
} from '../../config/Constants';
import Utility from '../../utils/Utility';
import {
  AnalyticsManager,
  EventType,
  EventParameterValue,
  EventParameterKey,
} from '../../analytics';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import { showProfileShimmer } from '../../actions/LoginActions';
import NavigationService from '../../navigator/NavigationService';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { withEither } from '../../lib/Monads';
import { isDesktop, isPresent } from '../../utils/BooleanUtility';

export default class OTPVerification extends Component {
  constructor(props) {
    super(props);
    console.log('componentDidMount: ', this.props);
    this.state = {
      continueButtonColor: colors.disabled,
      continueButtonUnderlay: colors.disabled,
      warning: false,
      otpTimer: parseInt(Config.OTP_RESEND_TIMER_COUNT),
      borderColor: Theme.light.borderColor,
      activeBorderColor: colors.foxyBlue,
      borderWidth: 1,
      safeAreaBottomInset: 0,
      errors: null,
      isVerifying: false,
      currentOtp: '',
      cellStyle: styles.cellStyle,
      disableOtpOverCall: false,
      callTimer: 45,
      hideModal: false,
    };
    this.otpRegex = /^\d{4}$/;
    this.codeInput = React.createRef();
    this.otp = '';

    this.otpDelayedEventSent = false;
    this.otpOverCallTimer = null;
    this.enableInternationalChoice = AppConfig.getBooleanValue(
      Config.ENABLE_INTERNATIONAL_CHOICE,
    );
  }

  componentDidMount() {
    this.startInterval();
    setTimeout(() => {
      this.otpView && this.otpView.focusField(0);
    }, 750);

    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
      [EventParameterKey.MODAL_NAME]:
        EventParameterValue.MODAL_NAME.OTP_VERIFICATION,
    });
    showProfileShimmer(true);
    this.sendOtpIfMobileNumberPresent();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { screenProps: { getCurrentOtp: oldOtp = '' } = {} } = this.props;

    const { screenProps: { getCurrentOtp: newOtp = '' } = {} } = nextProps;

    if (
      (Utility.isBlank(oldOtp) && Utility.isPresent(newOtp)) ||
      (Utility.isPresent(oldOtp) && oldOtp !== newOtp)
    ) {
      this.setState({
        currentOtp: newOtp,
      });

      // if we want previous code then we shall uncomment this code
      // // this.codeInput.current.fillOtp(nextProps.screenProps.getCurrentOtp);
      // this.onFulfill(nextProps.screenProps.getCurrentOtp, true);

      this.otpView && this.otpView.blurAllFields();
    }
  }

  componentDidUpdate(prevProps) {
    const { screenProps } = this.props;
    const { otpTimer } = this.state;
    if (
      Utility.isPresent(screenProps?.userAccountInfo.authToken) &&
      Utility.isBlank(prevProps.screenProps?.userAccountInfo.authToken)
    ) {
      this.goBack();
      Keyboard.dismiss();
      // setTimeout(
      //   () => NavigationService.navigate(SCREEN.PROFILE_DETAILS, screenProps),
      //   0,
      // );
    }
    if (otpTimer === 0) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    const {
      screenProps: {
        storeCurrentOtp = () => {}
      },
    } = this.props;
    storeCurrentOtp('');
  }

  sendOtpIfMobileNumberPresent = () => {
    const {
      screenProps: {
        otpRequestedManually = true,
        userAccountInfo: { mobileNumber = '' } = {},
        onResendOtp,
      },
    } = this.props;
    if (!otpRequestedManually && Utility.isPresent(mobileNumber)) {
      onResendOtp();
    }
  };

  goBack = () => {
    const { screenProps } = this.props;
    this.setState({
      hideModal: true,
    });
    setTimeout(() => {
      screenProps.goBack();
    }, 20);
  };

  onSkip = () => {
    const { screenProps } = this.props;
    this.setState({
      hideModal: true,
    });
    setTimeout(() => {
      screenProps.onSkip();
    }, 20);
  };

  onPressOutside = () => {
    if (AppConfig.getBooleanValue(Config.ENABLE_MANDATORY_LOGIN)) {
      return;
    }
    const { screenProps } = this.props;
    this.setState({
      hideModal: true,
    });
    setTimeout(() => {
      screenProps.onPressOutside();
    }, 20);
  };

  startInterval = () => {
    const { otpTimer } = this.state;
    if (otpTimer === 0) return;
    this.interval = setInterval(() => {
      this.setState((prevState) => ({ otpTimer: prevState.otpTimer - 1 }));
    }, 1000);
  };

  removeSafeAreaBottomInset = () => {
    if (Utility.isIOS()) {
      this.setState({
        safeAreaBottomInset: -(Utility.bottomInset - 15),
      });
    }
  };

  showSafeAreaBottomInset = () => {
    if (Utility.isIOS()) {
      this.setState({ safeAreaBottomInset: 0 });
    }
  };

  onFulfill = (code) => {
    const { screenProps } = this.props;
    const { currentOtp } = this.state;
    let method = EventParameterValue.INPUT_METHOD.MANUAL;
    if (
      Utility.isPresent(currentOtp) &&
      Utility.isPresent(code) &&
      currentOtp === code
    ) {
      method = EventParameterValue.INPUT_METHOD.AUTO_READ;
    }
    AnalyticsManager.logEvent(EventType.onboardingEvent.OTP_FILL, {
      [EventParameterKey.INPUT_METHOD]: method,
    });
    if (this.otpRegex.test(code)) {
      this.setState({
        warning: false,
        continueButtonColor: colors.enabled,
        continueButtonUnderlay: colors.enabledPress,
        borderColor: Theme.light.verifiedColor,
        borderWidth: 2,
        isVerifying: true,
        cellStyle: styles.cellStyleGreen,
      });

      // problem area for the continue button hangs,.
      screenProps?.onVerifyOtp(code, (success, showError, authorized) => {
        if (success) {
          const { screenProps: { furtherAction = () => {} } = {} } = this.props;
          furtherAction();
          if (!authorized) {
            if (this.enableInternationalChoice) {
              screenProps.navigateToInviteCenter();
              return;
            }
            screenProps.navigateToRegisterWebView();
          }
          return;
        }
        this.setState({
          errors: showError,
          isVerifying: false,
          cellStyle: styles.cellStyleRed,
        });
        if (Utility.isBlank(showError)) {
          AnalyticsManager.logEvent(EventType.onboardingEvent.ACCOUNT_LOGIN);
          AnalyticsManager.logFirebaseEvent(
            EventType.googleRemarketingEvents.LOGIN,
            {
              [EventParameterKey.METHOD]: method,
              method: Utility.isPresent(Utility.furtherAction.phone_number)
                ? 'automatic input box'
                : 'manual',
            },
          );

          const fbMeta = {
            [EventParameterKey.FB.EVENT_PARAM_REGISTRATION_METHOD]: method,
          };

          AnalyticsManager.logFBStandardEvent(
            EventType.FB.EVENT_NAME_COMPLETED_REGISTRATION,
            null,
            AnalyticsUtility.addCurrencyToFBEventMetaData(fbMeta),
          );
          Platform.select({
            ios: null,
            android: screenProps?.unregisterSmsReceiver(),
            web: null,
          });
        } else {
          AnalyticsManager.logEvent(EventType.onboardingEvent.OTP_WRONG);
        }
      });
    } else {
      this.setState({
        warning: true,
        borderColor: Theme.light.errorColor,
        borderWidth: 2,
      });
      // Unused function, because currently we are not using foxy confirmation box
      // this.clearOTP();
    }
  };

  clearOTP = () => {
    this.codeInput.current.clear();
  };

  changeToLoginScreen = () => {
    AnalyticsManager.logEvent(EventType.onboardingEvent.PHONE_NUMBER_CHANGE);
    const { navigation, screenProps } = this.props;
    // navigation.navigate(SCREEN.LOGIN_INFO, {
    //   focusOnKeyboard: true,
    //   changePhoneNumber: true,
    //   screenProps,
    // });
    screenProps.onTapChangePhoneNumber();
  };

  onResend = () => {
    const { screenProps } = this.props;
    this.setState(
      { otpTimer: parseInt(Config.OTP_RESEND_TIMER_COUNT) },
      this.startInterval,
    );
    screenProps?.onResendOtp();
    AnalyticsManager.logEvent(EventType.onboardingEvent.OTP_REREQUEST, {
      medium: EventParameterValue.MEDIUM.SMS,
    });
  };

  onPressSkipButton = () => {
    const { screenProps, navigation } = this.props;
    if (Utility.isBlank(screenProps?.userAccountInfo?.loginModalState)) {
      screenProps?.loginModalState(LOGIN_MODAL_STATE.USER_SKIPPED);
    }

    AnalyticsManager.logEvent(EventType.onboardingEvent.ONBOARDING_SKIP, {
      [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.OTP_SCREEN,
    });
    this.onSkip();
  };

  hideSkipFromPayWithFoxy = () => Utility.hideLoginSkipButtonForSalon;

  skipButton = () => {
    if (AppConfig.getBooleanValue(Config.ENABLE_MANDATORY_LOGIN)) {
      return null;
    }
    const { screenProps } = this.props;
    const orderStatus = screenProps?.orderStatus;

    if (orderStatus || this.hideSkipFromPayWithFoxy()) return null;
    return (
      <TouchableOpacity onPress={this.onPressSkipButton}>
        <Text style={styles.skipButton}>Skip</Text>
      </TouchableOpacity>
    );
  };

  titleContainer = () => {
    return (
      <View style={styles.titleRow}>
        <Text style={styles.title}>Verify Phone Number</Text>
        <this.skipButton />
      </View>
    );
  };

  contactInfo = () => {
    const {
      screenProps: {
        userAccountInfo: {
          mobileNumber,
          callingCode = AppConfig.callingCode,
        } = {},
      },
    } = this.props;
    let phoneNumber = '';
    if (!this.enableInternationalChoice) {
      phoneNumber = mobileNumber?.startsWith(COUNTRY_CODE)
        ? Utility.insertCharAtPosition(
            COUNTRY_CODE.length || 3,
            mobileNumber,
            '-',
          )
        : mobileNumber;
    } else {
      phoneNumber = `+${callingCode}-${mobileNumber}`;
    }

    return (
      <View style={styles.contactInfoContainer}>
        <Text style={styles.contactNumber}>OTP sent to</Text>
        <Text style={[styles.contactNumber, { color: colors.foxyBlack }]}>
          {` '${phoneNumber}'`}
        </Text>
        <TouchableOpacity onPress={this.changeToLoginScreen}>
          <Text
            style={styles.editContactText}
            testID='change-number'
            accessibilityLabel='change-number'
          >
            Change
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  startOtpOnCallTimer = () => {
    this.callTimer = setInterval(() => {
      this.setState((prevState) => ({ callTimer: prevState.callTimer - 1 }));
    }, 1000);
  };

  otpContainer = (props) => {
    const { warning } = this.state;
    return (
      <View style={styles.otpContainer} dataSet={{ media: ids.otpContainer }}>
        <this.otpInputBox {...props} />
      </View>
    );
  };

  clearOTPError = () => {
    this.setState({
      errors: null,
    });
  };

  onFirstIndex = (isFirstIndex) => {
    if (isFirstIndex) {
      this.setState({
        // ...this.state,
        cellStyle: styles.cellStyle,
        errors: null,
      });
    }
  };

  resetDigits = () => {};

  otpInputBox = (props) => {
    const { errors, cellStyle, currentOtp } = this.state;

    const otp = currentOtp;

    return (
      <View style={styles.otpInputBoxContainer}>
        <OTPInputView
          ref={(ref) => {
            this.otpView = ref;
          }}
          style={{ width: isDesktop() ? '100%' : '90%' }}
          pinCount={4}
          code={otp}
          onFirstIndex={this.onFirstIndex}
          autoFocusOnLoad={!Utility.isAndroid()}
          codeInputFieldStyle={cellStyle}
          onCodeFilled={this.onFulfill}
        />
      </View>
    );
  };

  incorrectOTPWarning = () => {
    const { errors = '' } = this.state;
    if (isPresent(errors)) {
      this.otpView && this.otpView.resetDigits();
      return <Text style={styles.warning}>{errors}</Text>;
    }
    return <View style={styles.warningPlaceholder} />;
  };

  resendOtpContainer = () => (
    <View style={styles.resendOtpContainer}>
      <this.resendTextMessage />
      <this.resendThroughWhatsapp />
    </View>
  );

  requestOtpOverWhatsapp = () => {
    const { screenProps } = this.props;
    const { disableOtpOverCall } = this.state;

    AnalyticsManager.logEvent(EventType.onboardingEvent.OTP_REREQUEST, {
      medium: EventParameterValue.MEDIUM.WHATSAPP,
    });
    if (!disableOtpOverCall) {
      this.setState({
        disableOtpOverCall: true,
      });

      this.startOtpOnCallTimer();

      screenProps?.sendOtpOverWhatsapp((response) => {
        if (response) {
          this.otpOverCallTimer = setTimeout(() => {
            clearInterval(this.callTimer);
            this.setState({
              disableOtpOverCall: false,
              callTimer: 30,
            });
          }, 30000);
        } else {
          clearInterval(this.callTimer);
          this.setState({
            disableOtpOverCall: false,
            callTimer: 30,
          });
        }
      });
    }
  };

  resendTextMessage = () => {
    if (Config.OTP_RESEND_MESSAGE !== 'sms') return null;
    const { otpTimer } = this.state;
    const timer = otpTimer < 10 ? `0${otpTimer}` : `${otpTimer}`;
    let disableButton = true;
    let resend = `00:${timer}`;
    let resendTextColor = colors.subtitle;
    if (otpTimer === 0) {
      if (!this.otpDelayedEventSent) {
        AnalyticsManager.logEvent(EventType.onboardingEvent.OTP_DELAYED);
      }
      this.otpDelayedEventSent = true;

      resend = 'Resend';
      resendTextColor = Theme.light.highlight;

      disableButton = false;
    }

    return (
      <View style={styles.resendTextContainer}>
        <Text style={styles.notGettingCodeText}>
          Didn&apos;t receive the code?
        </Text>
        <TouchableOpacity onPress={this.onResend} disabled={disableButton}>
          <Text
            style={[styles.resend, { color: resendTextColor, marginLeft: 10 }]}
          >
            {resend}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  resendThroughWhatsapp = () => {
    const { otpTimer, disableOtpOverCall } = this.state;

    if (Config.OTP_RESEND_MESSAGE !== 'whatsapp') return null;

    // const timer = otpTimer < 10 ? `0${otpTimer}` : `${otpTimer}`;

    let whatsappText = 'Resend via Whatsapp';
    let whatsappIcon = images.whatsappIcon.disabled;
    let disableButton = true;
    // if (otpTimer === 0) {
    if (!this.otpDelayedEventSent) {
      AnalyticsManager.logEvent(EventType.onboardingEvent.OTP_DELAYED);
    }
    this.otpDelayedEventSent = true;

    whatsappText = !disableOtpOverCall
      ? 'Resend via Whatsapp'
      : 'Sent over Whatsapp';

    const whatsappTextStyle = !disableOtpOverCall
      ? styles.disableResendWhatsappTextStyle
      : styles.resendWhatsappTextStyle;
    whatsappIcon = !disableOtpOverCall
      ? images.whatsappIcon.enabled
      : images.whatsappIcon.disabled;
    disableButton = false;
    // }

    return (
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginTop: 10,
        }}
      >
        <TouchableOpacity
          style={styles.whatsappOptionContainer}
          disabled={disableButton}
          onPress={this.requestOtpOverWhatsapp}
          hitSlop={Utility.getHitSlop()}
        >
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.whatsappIcon}>
              <Image source={whatsappIcon} />
            </View>
            <Text style={whatsappTextStyle}>{whatsappText}</Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  otpOverCall = () => {
    return (
      <TouchableOpacity style={styles.whatsappOptionContainer}>
        <View style={styles.whatsappIcon}>
          <Image source={images.whatsappIcon.enabled} />
        </View>
        <Text style={{ fontSize: size.h3, color: colors.foxyBlack }}>
          Send on WhatsApp
        </Text>
      </TouchableOpacity>
    );
  };

  otpVerificationContainer = () => {
    const {
      continueButtonColor,
      continueButtonUnderlay,
      isVerifying,
      errors,
      warning,
      hideModal,
    } = this.state;
    const { screenProps } = this.props;

    return (
      <SafeAreaView
        forceInset={{ bottom: 'always' }}
        style={{
          height: Utility.getScreenHeight(),
          backgroundColor: hideModal ? 'transparent' : 'rgba(0, 0, 0, 0.2)',
        }}
      >
        <TouchableWithoutFeedback
          style={styles.modal}
          onPress={this.onPressOutside}
        >
          <View style={styles.touchableContainer} />
        </TouchableWithoutFeedback>
        <View style={styles.content} dataSet={{ media: ids.content }}>
          <View pointerEvents="box-none" dataSet={{ media: ids.wrapperContainer }}>
            <this.titleContainer {...this.props} />
            <this.contactInfo />
            <this.otpContainer />
            {warning && (
              <Text style={styles.warning}>Please enter a 4-digit OTP.</Text>
            )}
            <this.incorrectOTPWarning />
            <this.resendOtpContainer />

            <View style={styles.buttonStyle} dataSet={{ media: ids.buttonStyle }}>
              <FoxyShadowButton
                title="Continue"
                backgroundColor={continueButtonColor}
                // underlayColor={continueButtonUnderlay}
                Loading={isVerifying}
                width={isDesktop() ? '100%' : Utility.getScreenWidth() - 48}
              />
            </View>
          </View>
        </View>
      </SafeAreaView>
    );
  };

  customKeyboardAvoid = withEither(Utility.isIOS, KeyboardAvoidingView)(View);

  render() {
    return (
      <this.customKeyboardAvoid
        behavior='position'
        style={styles.keyboardAvoidContainerStyle}
        keyboardVerticalOffset={-12}
        dataSet={{ media: ids.keyboardAvoidContainerStyle }}
      >
        <this.otpVerificationContainer />
      </this.customKeyboardAvoid>
    );
  }
}
