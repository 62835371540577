import React, { PureComponent } from 'react';
import { View, Text, TouchableOpacity, Image, Clipboard } from 'react-native';
import LinearGradient from '../../../libraries/ReactNativeLinearGradient';
import { connect } from 'react-redux';
import _, { range } from 'lodash';
import InviteToClaimModal from '../InviteToClaimModal';
import styles from './styles';
import {
  INVITE_STATUS,
  STATUS,
} from '../../../containers/inviteCenter/Constants';
import RemoteConfig from '../../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../../config/Constants';
import withNavigation from '../../../utils/WithNavigation';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../../analytics';
import images from '../../../theme/Images';
import ConditionalContactImageOrInitials from '../../Contacts/ConditionalContactImageOrInitials';
import ContactsUtility from '../../../utils/ContactsUtility';
import SlimOfferCoupon from '../../prompts/SlimOfferCoupon';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';

const { COMPLETED, INITIALIZED } = STATUS;
const { ACCEPTED } = INVITE_STATUS;

const startLinearGradient = { x: 0, y: 1 };
const endLinearGradient = { x: 0, y: 1 };

class InviteFriendCard extends PureComponent {
  constructor(props) {
    super(props);
    const { status } = props;
    this.stringConstants = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.group_deals_contacts_strings),
    )?.group_deal_card_product_page;

    this.state = {
      isModalVisible: false,
    };
    this.gradientColors =
      status === COMPLETED
        ? [colors.slimCouponBackgroundColor, colors.slimCouponBackgroundColor]
        : [colors.pink.light, colors.pink.ultraLight];
  }

  componentDidMount() {
    this.logProductElementViewOrClickEvent();
  }

  isInviteAccepted = (invite) => invite.status === ACCEPTED;

  getNumberOfPendingInvitesToUnlock = () => {
    const { groupSize, invitees } = this.props;
    let numOfAcceptedInvites = _.filter(invitees, this.isInviteAccepted).length;
    numOfAcceptedInvites = numOfAcceptedInvites > 0 ? numOfAcceptedInvites : 0;

    return groupSize - numOfAcceptedInvites;
  };

  logProductElementViewOrClickEvent = (isClickEvent, ctaType) => {
    const { groupPrice, skuId, mrp, status, groupSize } = this.props;
    if (Utility.isBlank(groupPrice)) return;

    let event = EventType.product.PRODUCT_ELEMENT_VIEW;
    const meta = {
      [EventParameterKey.TYPE]: 'group_deal_card',
      [EventParameterKey.SKU_ID]: skuId,
      [EventParameterKey.STATE]: status,
      [EventParameterKey.GROUP_DEAL_PRICE]: groupPrice,
      [EventParameterKey.MRP]: mrp,
      [EventParameterKey.GROUP_DEAL_SIZE]: groupSize,
    };
    if (isClickEvent) {
      event = EventType.product.PRODUCT_ELEMENT_CLICK;
      meta[EventParameterKey.CTA] = ctaType;
    }
    AnalyticsManager.logEvent(event, meta);
  };

  avatarMe = ({ selfieImageUrl }) => {
    const image = selfieImageUrl
      ? { uri: Utility.getMinifiedImage(selfieImageUrl) }
      : images.for_you_placeholder;

    return (
      <View style={styles.avatarContainer}>
        <Image source={image} style={styles.avatarContainer} />
      </View>
    );
  };

  renderEmptyAvatar = (index) => {
    const style = [
      styles.avatarContainer,
      { backgroundColor: colors.contactsInitialsColor[index].bgColor },
    ];

    return (
      <View style={style}>
        <Image source={images.addUser} style={styles.icon} />
      </View>
    );
  };

  mapEmptyAvatars = () => {
    const { groupSize } = this.props;
    const indexArray = [...range(0, Math.min(4, groupSize))];
    return indexArray.map(this.renderEmptyAvatar);
  };

  emptyStateAvatars = () => {
    return <View style={styles.rowStyle}>{this.mapEmptyAvatars()}</View>;
  };

  avatar = ({ contactData, index, invitees }) => {
    const backgroundColorIndex = index % colors.contactsInitialsColor.length;
    const avatarStyle =
      invitees.length > 4
        ? styles[`stackedAvatarBackground${backgroundColorIndex}`]
        : styles[`avatarBackground${backgroundColorIndex}`];
    const textStyle = styles[`textColor${backgroundColorIndex}`];

    return (
      <ConditionalContactImageOrInitials
        item={contactData}
        imageStyle={styles.imageStyle}
        initialsStyle={textStyle}
        backgroundStyle={avatarStyle}
      />
    );
  };

  mapInviteToAvatar = (invite, index) => {
    const { contacts, invitees } = this.props;
    const contactData =
      ContactsUtility.getThumbnailOrContactsInitialsFromContacts(
        contacts,
        invite.phone_number,
        invite.name,
        index,
      );
    return (
      <this.avatar
        contactData={contactData}
        index={index}
        invitees={invitees}
      />
    );
  };

  stackedAvatars = ({ invitees = [] }) => {
    if (invitees.length === 0) return <this.emptyStateAvatars />;
    return (
      <View style={styles.rowStyle}>
        {invitees.slice(0, 8).map(this.mapInviteToAvatar)}
      </View>
    );
  };

  avatarContainer = ({ selfieImageUrl, invitees, status }) => {
    if (status === COMPLETED) return null;
    return (
      <View style={styles.rowStyle}>
        <this.avatarMe selfieImageUrl={selfieImageUrl} />
        <this.stackedAvatars invitees={invitees} />
      </View>
    );
  };

  UnlockedCoupon = () => {
    const { couponCode } = this.props;
    const couponOb = { status: 'uninitialized', coupon_code: couponCode };
    return (
      <View style={styles.couponContainer}>
        <SlimOfferCoupon
          coupon={couponCode}
          description={'Use coupon to buy this group deal '}
          couponCode={couponOb}
          onCtaPress={this.copyCoupon}
          showCopyButton
          clipperColorLeft={colors.slimCouponBackgroundColor}
          clipperColorRight={colors.slimCouponBackgroundColor}
          borderRadius={4}
        />
      </View>
    );
  };

  buttonContainer = ({ status }) => {
    if (status === COMPLETED) return <this.UnlockedCoupon />;
    const { button_text } = this.stringConstants[status];
    return (
      <View style={styles.buttonContainer}>
        <Text style={styles.buttonText}>{button_text}</Text>
      </View>
    );
  };

  navigateToInviteCenter = () => {
    const { navigation } = this.props;
    this.logProductElementViewOrClickEvent(
      true,
      EventParameterValue.GROUP_DEALS_REFERRALS.INVITE_CENTRE,
    );
    navigation.navigate('InviteCenter', {});
  };

  openModal = () => {
    this.logProductElementViewOrClickEvent(
      true,
      EventParameterValue.GROUP_DEALS_REFERRALS.GROUP_DEAL_MODAL,
    );
    this.setModalVisibility(true);
  };

  setModalVisibility = (isModalVisible) => {
    this.setState({ isModalVisible });
  };

  AvatarsAndButton = (props) => {
    const { isModalVisible } = this.state;
    const {
      productData = {},
      status,
      selfieImageUrl,
      invitees = [],
      groupPrice,
      groupSize,
    } = props;

    const updatedProductData = { ...productData };
    if (Utility.isPresent(productData) && updatedProductData.sku) {
      updatedProductData.sku.required_group_size = groupSize;
      updatedProductData.sku.group_buying_price = groupPrice;
    }

    return (
      <View>
        <View style={styles.row}>
          <this.avatarContainer
            status={status}
            invitees={invitees}
            selfieImageUrl={selfieImageUrl}
          />
          <this.buttonContainer status={status} />
        </View>
        <InviteToClaimModal
          isModalVisible={isModalVisible}
          setModalVisible={this.setModalVisibility}
          productData={updatedProductData}
          previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
          referralModalType={'GROUP_DEAL_MODAL'}
        />
      </View>
    );
  };

  copyCoupon = () => {
    const { showToast = () => {}, couponCode } = this.props;
    if (!couponCode || !showToast) return;
    this.logProductElementViewOrClickEvent(
      true,
      EventParameterValue.GROUP_DEALS_REFERRALS.COPY_COUPON,
    );
    Clipboard.setString(couponCode);
    showToast('Coupon copied successfully!');
  };

  composeHeadingsAndAction = () => {
    const { groupPrice, status, groupSize } = this.props;
    let { heading: title, subheading } = this.stringConstants[status];
    let onPress = this.openModal;
    title = title.replace('$groupPrice', `₹${groupPrice}`);
    switch (status) {
      case COMPLETED:
        onPress = this.copyCoupon;
        break;
      case INITIALIZED:
        onPress = this.navigateToInviteCenter;
        subheading = subheading.replace(
          '$numPendingInvitesToUnlock',
          this.getNumberOfPendingInvitesToUnlock(),
        );
        break;
      default:
        subheading = subheading.replace('$required_group_size', `${groupSize}`);
    }
    return { title, subheading, onPress };
  };

  render() {
    const {
      onInviteFriendCardLayoutMeasurement = () => {},
      groupPrice,
      productData,
      status,
      invitees,
      selfieImageUrl,
    } = this.props;

    if (Utility.isBlank(groupPrice)) {
      return null;
    }
    const { title, subheading, onPress } = this.composeHeadingsAndAction();

    return (
      <TouchableOpacity
        onPress={onPress}
        onLayout={onInviteFriendCardLayoutMeasurement}
        style={styles.margin}
      >
        <LinearGradient
          colors={this.gradientColors}
          start={startLinearGradient}
          end={endLinearGradient}
        >
          <View style={styles.container}>
            <Text style={styles.title}>{title} </Text>
            <Text style={styles.subtitle}>{subheading}</Text>
            <this.AvatarsAndButton {...this.props} />
          </View>
        </LinearGradient>
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => ({
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
  contacts: state.contactsData.contacts,
});

export default withNavigation(connect(mapStateToProps, null)(InviteFriendCard));
