import React, { useEffect } from 'react';
import { Linking, Platform, View, Text } from 'react-native';
import {
  SafeAreaProvider,
  useSafeAreaInsets,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import AnalyticsManager from './analytics/AnalyticsManager';
import EventType from './analytics/AnalyticsEventType';
import EventParameterKey from './analytics/EventParameterKey';
import { enableFreeze } from 'react-native-screens';
import AppNavigator from './navigator/AppNavigator';
import configureStore from './reducer';
import Utility from './utils/Utility';

import { messaging } from './libraries/Firebase';
import './libraries/initializeFirebase';

import RemoteConfig from './utils/RemoteConfig';
import withCodePush from './codepush';

import Session from './utils/Sessions';
import { isNative } from './utils/BooleanUtility';

import { setStoreRef } from './store/StoreUtility';
import NotificationManager from './utils/NotificationsManager';

export const store = configureStore();
setStoreRef(store);
const persistor = persistStore(store);
const prefix = Platform.OS === 'android' ? 'foxy://foxy/' : 'foxy://';

enableFreeze(true);

const App = () => {
  const insets = useSafeAreaInsets();
  useEffect(() => {
    RemoteConfig.initRemoteConfig(NotificationManager.createNotification);

    Utility.safeAreaInsets(insets);
    Utility.getDeviceAPILevel();
    Utility.setImmersiveModeOff();
    saveAppStartTimeStamp();
    checkPermission();

    Linking.getInitialURL()
      .then((url) => {
        Session.setInitialAppDeeplink(url);
      })
      .catch((err) => {
        if (isNative()) {
          AnalyticsManager.logEvent(
            EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
            {
              [EventParameterKey.SOURCE]: `App.js:getInitialUrl:L-50 ${err}`,
            },
          );
        }
      });
  }, []);

  const getFcmToken = () => {
    if (isNative()) {
      messaging().subscribeToTopic('ekanek-notifications');
    }
  };

  const saveAppStartTimeStamp = () => {
    try {
      Utility.appLoadJs = new Date();
      if (isNative()) {
        AnalyticsManager.logEvent(EventType.appLifeCycleEvents.IMPRESSION, {
          [EventParameterKey.APP_STATE]: 'app_load_js',
          [EventParameterKey.TIME_STAMP]: Utility.appLoadJs,
        });
      }
    } catch (e) {
      if (isNative()) {
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            [EventParameterKey.SOURCE]: 'App.js: saveAppStartTimeStamp',
          },
        );
      }
    }
  };

  const requestPermission = async () => {
    try {
      if (isNative()) {
        await messaging().requestPermission();
      }
      // User has authorised
    } catch (error) {
      // User has rejected permissions
    }
  };

  const checkPermission = async () => {
    if (isNative()) {
      const enabled = await messaging().hasPermission();
      if (enabled) {
        getFcmToken();
      } else {
        requestPermission();
      }
    }
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppNavigator uriPrefix={prefix} />
      </PersistGate>
    </Provider>
  );
};

const FoxyApp = withCodePush(App);

export default () => {
  /**
   * @initialWindowMetrics: ref PR: https://github.com/ekanek/foxy-app/pull/2209 for details
   */
  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <FoxyApp />
    </SafeAreaProvider>
  );
};
