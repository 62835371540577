import { Text, FlatList, StyleSheet, View } from 'react-native';
import React, { useState } from 'react';
import _ from 'lodash';
import { useNavigation } from '@react-navigation/native';
import Config from '../../libraries/ReactNativeConfig';
import applyOffer from '../../hoc/applyOffer';
import { useApiCall } from '../../lib/hooksUtil';
import { AnalyticsManager } from '../../analytics';
import OfferPrompt from '../prompts/OfferPrompt';
import colors from '../../theme/Colors';
import { removeOffer } from '../../actions/ActionTypes';
import OfferShimmer from '../shared/OfferShimmer';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import NavigationService from '../../navigator/NavigationService';
import { API_DOMAIN } from '../../config/Constants';
import { isBlank, isDesktop, isPresent } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';
import { navigateToScreen } from '../../utils/NavigationUtility';

const navigateToOfferDetail = (item, navigation) => {
  const { url = '' } = item;

  if (isBlank(item)) {
    return;
  }

  AnalyticsManager.logEvent('prompt_action_click', {
    offer_id: item.offer_id,
    coupon_code: item.coupon_code,
    prompt_location: 'product_details',
    cta_text: isPresent(item.cta) ? item.cta : 'VIEW PRODUCTS',
    prompt_status: item.status,
  });

  if (isPresent(url)) {
    if (url === Config.HTTPS_DOMAIN_NAME) {
      navigation.navigate('Feed');
    }

    const promptData = {
      type: 'list',
      previousScreen: 'product_Detail',
      id: item.offer_id,
    };

    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      url,
      (route, slug, path, extra) => {
        navigateToScreen({
          navigation,
          type: 'push',
          screen: route,
          params: { slug, extra, promptData },
        });
      },
    );
  }
};

const GetComponent = ({
  item,
  data = [],
  error,
  isLoading,
  changeCouponState,
  navigation,
}) => {
  if (isBlank(item) || isBlank(data)) return null;

  let width = isDesktop() ? (PRODUCT_RIGHT_CONTAINER_WIDTH / 2) - 24 : 280;
  if (data.length === 1) {
    width = isDesktop() ? PRODUCT_RIGHT_CONTAINER_WIDTH - 24 : getScreenWidth() - 24;
  }
  return (
    <OfferPrompt
      promptData={item}
      changeCouponState={changeCouponState}
      removeCoupon={changeCouponState}
      navigateToOfferDetail={(item) => navigateToOfferDetail(item, navigation)}
      previousScreen={'product_Detail'}
      style={_.memoize(
        () => {
          return [
            styles.offerPrompt,
            { width },
          ];
        },
        () => [width],
      )()}
      errors={error}
      isValidOnSku
      componentWidth={width - 20}
      loading={isLoading}
    />
  );
};

const OfferDiscountStrip = (props) => {
  const {
    isLoading,
    applyCartCoupon = () => {},
    id,
    productPage: { offer = {} } = {},
    stocked_status,
    isPageLoading,
    error,
    successfullyAppliedCoupon,
  } = props;

  if (stocked_status !== 'in_stock') {
    return null;
  }

  if (isPageLoading) {
    return <OfferShimmer horizontal />;
  }

  const apiUrl = `${API_DOMAIN}/api/v1/products/${id}/prompts?has_group_deal=false&send_flash_deal_offer=true`;
  const navigation = useNavigation();

  const { data, isLoading: dataLoading } = useApiCall(
    apiUrl,
    {},
    successfullyAppliedCoupon,
  );

  const [isApplyingCoupon, setisApplyingCoupon] = useState(false);

  if (isBlank(data)) {
    return <OfferShimmer horizontal />;
  }

  if (isBlank(data) && !dataLoading) {
    return null;
  }

  const changeCouponState = (coupon, applied) => {
    setisApplyingCoupon(true);
    if (!applied) {
      applyCartCoupon(coupon, () => {
        setisApplyingCoupon(false);
      });
    }
    if (applied) {
      removeOffer(coupon, () => {
        setisApplyingCoupon(false);
      });
    }
  };

  const { heading = '', subheading = '' } = offer;

  return (
    <>
      <Text style={styles.heading} ellipsizeMode='tail' numberOfLines={1}>
        {heading}
      </Text>
      { isPresent(subheading) && (
        <Text
          style={styles.subheading}
          allowFontScaling={false}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {subheading}
        </Text>
      )}

      <FlatList
        data={data}
        extraData={data}
        scrollEnabled
        horizontal={!isDesktop()}
        numColumns={isDesktop() ? 2 : 0}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => `${index}_grid`}
        renderItem={({ item, index }) => (
          <GetComponent
            item={item}
            index={index}
            data={data}
            error={error}
            applyCouponCode={applyCartCoupon}
            isLoading={isLoading}
            changeCouponState={changeCouponState}
            isApplyingCoupon={isApplyingCoupon}
            navigation={navigation}
          />
        )}
      />
    </>
  );
};

const styles = StyleSheet.create({
  offerPrompt: {
    height: 150,
    marginLeft: 12,
    marginRight: 12,
    elevation: 5,
    marginTop: 8,
  },
  heading: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    marginHorizontal: 12,
    marginTop: 32,
    marginBottom: 4,
  },
  subheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#979BAA',
    marginBottom: 12,
    marginLeft: 12,
  },
});

export default applyOffer(OfferDiscountStrip);
