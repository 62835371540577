// Dependencies
import React, { Component, Fragment } from 'react';
import {
  Animated,
  ImageBackground,
  View,
  Image,
  Text,
  findNodeHandle,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { State } from 'react-native-gesture-handler';
import Orientation from 'react-native-orientation';
import Share from '../../libraries/ReactNativeShare';
import Toast from 'react-native-easy-toast';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import BrandStyles from './styles';
import { withMaybe } from '../../lib/Monads';
import CustomNavigationHeader from '../Product/CustomNavigationHeader';
import DockedHeader from '../../utils/DockedHeader';
import Images from '../../theme/Images';
import colors from '../../theme/Colors';
import ShareModal from '../Product/ShareModal';
import { Header } from '../header';
import { List } from '../../containers/List';
import {
  ORIENTATION,
  LAYOUT,
  NOTIFICATION_REQUEST_MODAL_TYPE,
  TRACKING_PARAMS,
} from '../../config/Constants';
import { StaticNavigationHeader } from '../shared';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AnimatedLikeButton from '../shared/AnimatedLikeButton';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import FullScreenProductDescription from '../Product/FullScreenProductDescription';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FoxyPersonalisedItems from '../../containers/FoxyPersonalisedItems';
import _ from 'lodash';
import { setNotificationModalDisplayTime } from '../../actions/ActionTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NotificationModal from '../../utils/Notification/NotificationModal';
import { firebase } from '../../libraries/Firebase';
import ErrorBoundary from '../shared/ErrorBoundary';
import { isDesktop, isNative, isWeb } from '../../utils/BooleanUtility';
import { goBackWithFallbackHome } from '../../utils/NavigationUtility';
import WebListTitle from '../../containers/List/WebListTitle';
import WebFooter from '../webFooter/WebFooter';

class BrandDetail extends Component {
  constructor(props) {
    super(props);
    this.toggleCartVisibility =
      props.route.params?.toggleCartVisibility ?? (() => {});
    this.viewedItemsInList = [];
    this.trace = firebase.perf().newTrace(SCREEN_CONSTANTS.BRAND_DETAILS);
    this.trace.start();
    this.backAction = this.backAction.bind(this);
  }

  state = {
    liked: false,
    scrollY: new Animated.Value(0),
    offerApplied: '',
    viewRef: null,
    isShareModalVisible: false,
    isRichDescriptionVisible: false,
    canDisplayNotificationRequestModal: false,
    showNotificationPrompt: false,
  };

  Components = {
    list: List,
  };

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.onDidFocus();
    });
    if (isNative()) {
      Orientation.lockToPortrait();
    }
    this.displayNotificationRequestModal();
  }

  doubleTapRef = React.createRef();

  componentDidUpdate(prevProps) {
    const { brandData, brandData: { objects = {} } = {} } = this.props;
    const { brandData: { brandObject = {} } = {} } = prevProps;
    if (
      Utility.isPresent(brandData) &&
      Utility.isPresent(objects) &&
      Utility.isBlank(brandObject)
    ) {
      this.trace.stop();
      AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
        [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.BRAND_DETAILS,
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  backAction = () => {
    const { navigation, resetStatusBar } = this.props;
    const { isRichDescriptionVisible } = this.state;
    console.log('GOBACK_BRAND_DETAIL', resetStatusBar);
    if (resetStatusBar && !isRichDescriptionVisible) {
      resetStatusBar();
    } else if (isRichDescriptionVisible) {
      this.setState({ isRichDescriptionVisible: false });
      return true;
    }
    navigation.goBack();
    return true;
  };

  onProductImageDoubleTap = (event) => {
    if (event.nativeEvent.state === State.ACTIVE) {
      this.doubleTapped();
    }
  };

  imageLoaded = () => {
    this.setState({ viewRef: findNodeHandle(this.backgroundImage) });
  };

  goBack = () => {
    const { navigation } = this.props;
    goBackWithFallbackHome(navigation);
  };

  toggleLike = () => {
    this.setState({
      liked: !this.state.liked,
    });
  };

  listConditionFn = (props) => props.content === 'list';
  customNavigationHeader = withMaybe(this.listConditionFn)(
    CustomNavigationHeader,
  );

  getComponent(item, index) {
    const ContainerComponent = this.Components[item.type];
    if (ContainerComponent === null || ContainerComponent === undefined) {
      return null;
    }
    const {
      brandData: {
        list: { paginate_last_item = false, objects = [] },
      },
    } = this.props;
    let paginateLastItem = false;
    if (objects[objects.length - 1] === item && paginate_last_item) {
      paginateLastItem = paginate_last_item;
      console.log('its true', item, objects[objects.length - 1]);
    }

    return (
      <>
        <Header
          item={item}
          navigation={this.props.navigation}
          layout={item.display}
          previousScreen={SCREEN_CONSTANTS.BRAND_DETAILS}
          index={index}
        />
        <ContainerComponent
          navigation={this.props.navigation}
          itemData={item}
          id={item.id}
          listName={item.name}
          listContent={item.content}
          type={item.type}
          size={item.size}
          orientation={ORIENTATION.VERTICAL}
          display={item.display}
          toggleCartVisibility={this.toggleCartVisibility}
          showToast={this.showToast}
          index={index}
          previousScreen={SCREEN_CONSTANTS.BRAND_DETAILS}
          stickyPosition={this.props.stickyPosition}
          selectedFilters={this.props.selectedFilters}
          onFiltersPress={this.props.onFiltersPress}
        />
      </>
    );
  }

  toggleFollow = () => {
    this.setState({
      followed: !this.state.followed,
    });
  };

  doubleTapped = () => {
    this.likeButtonRef.updateLikeOnDoubleTap();
  };

  brandBanner = (props) => {
    const styles = BrandStyles;
    const { brandData, isLoading } = this.props;
    const { name, short_description = '' } = brandData;
    const { uri } = props;
    const GradientArray = [
      'transparent',
      'transparent',
      'transparent',
      'rgba(0,0,0,0.9)',
    ];

    const heroDescription =
      " is a fresh take on skincare. One that's founded on the understanding that everyone is unique. Through our range of advanced skincare products, we address lifestyle needs that are often overlooked.";
    if (Utility.isBlank(uri) && Utility.isBlank(short_description)) {
      return null;
    }
    return (
      <View style={styles.brandBannerContainer}>
        {Utility.isPresent(uri) && (
          <ImageBackground //Brand logo
            source={{
              uri: Utility.getMinifiedImage(
                uri,
                isDesktop() ? 1080 : styles.bannerImage.width,
                isDesktop() ? 360 : styles.bannerImage.height,
              ),
            }}
            ref={(img) => {
              this.backgroundImage = img;
            }}
            style={styles.bannerImage}
            resizeMode="contain"
          />
        )}
        <View //Brand Text Container
          style={styles.brandDescStyle}
        >
          {Utility.isPresent(short_description) ? (
            <Text //Brand Hero Description Text
              style={styles.brandShortDescription}
            >
              {short_description}
            </Text>
          ) : null}
          <View // Buttons Container
            style={styles.descButtonContainer}
          >
            {isNative() && <this.renderShareButton />}
            <this.renderLikeButton />
            <this.renderKnowMoreButton />
          </View>
        </View>
      </View>
    );
  };

  renderLikeButton = () => {
    const { brandData } = this.props;
    const styles = BrandStyles;
    return (
      <View style={{ flex: 1 }}>
        <AnimatedLikeButton
          ref={(ref) => {
            this.likeButtonRef = ref;
          }}
          id={brandData.id}
          type={brandData.type}
          slug={brandData.slug}
          layout={LAYOUT.SCREEN}
          content={brandData.content}
          onLike={this.onLike}
          itemName={brandData.name}
          screenName={SCREEN_CONSTANTS.BRAND_DETAILS}
        />
      </View>
    );
  };

  logAnalyticsEvent = (...meta) => {
    const [
      screen_name = '',
      id,
      name = '',
      contentType,
      medium = '',
      skuId = '',
    ] = meta;

    AnalyticsManager.logEvent(EventType.miscAppEvents.ITEM_SHARE, {
      [EventParameterKey.SCREEN_NAME]: screen_name,
      [EventParameterKey.ITEM_ID]: id,
      [EventParameterKey.ITEM_NAME]: name,
      [EventParameterKey.ITEM_TYPE]: contentType,
      [EventParameterKey.MEDIUM]: medium,
    });
    AnalyticsManager.logFirebaseEvent(EventType.googleRemarketingEvents.SHARE, {
      [EventParameterKey.CONTENT_TYPE]: contentType,
      [EventParameterKey.ID]: Utility.isPresent(skuId) ? skuId : id,
    });
  };

  renderKnowMoreButton = () => {
    const {
      brandData: { description },
    } = this.props;
    const styles = BrandStyles;
    if (Utility.isBlank(description)) return <View style={{ flex: 8 }} />;
    return (
      <View style={styles.knowMoreButtonContainer}>
        <TouchableOpacity
          onPress={this.showRichDescriptionVisibility}
          style={{ width: 100, flexDirection: 'row', alignItems: 'center' }}
        >
          <Text
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              color: colors.havelockBlue,
              fontFamily: 'Roboto-Medium',
            }}
          >
            Know More
          </Text>
          <Image
            source={Images.chevronRight}
            style={{
              height: 10,
              width: 10,
              top: 1,
              tintColor: colors.havelockBlue,
            }}
          />
        </TouchableOpacity>
      </View>
    );
  };

  renderShareButton = () => {
    const styles = BrandStyles;
    return (
      <View style={styles.shareButtonStyle}>
        <TouchableOpacity onPress={this.onSocialShareOptionTapped}>
          <Image
            testID="brand-share-icon"
            source={Images.ic_share_product}
            style={{
              width: 20,
              height: 20,
              resizeMode: 'contain',
            }}
          />
        </TouchableOpacity>
      </View>
    );
  };

  showShareModal = () => {
    this.setState({
      isShareModalVisible: true,
    });
  };

  hideShareModal = () => {
    this.setState({
      isShareModalVisible: false,
    });
  };

  onSearchIconTap = () => {
    const {
      navigation,
      brandData: { name = '' },
    } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.ITEM_TYPE]: 'search_icon',
      [EventParameterKey.SOURCE]: SCREEN_CONSTANTS.BRAND_DETAILS,
      [EventParameterKey.ENTITY_NAME]: name,
    });
    navigation.push('Search', {});
  };

  renderList = (props) => {
    const {
      navigation,
      getQuickFiltersListRef,
      stickyPosition,
      selectedFilters,
      onFiltersPress,
      onPressResetButton,
      setListPaginatedCallApiRef,
      brandData,
      filtersEnabled,
      filteredSlug,
      onRefresh,
      hideOosProduct,
    } = this.props;
    // const { itemData } = props;
    if (Utility.isBlank(brandData?.list)) {
      return null;
    }

    const paginateLastItem = brandData.list.paginate_last_item;
    const totalItems = brandData.list?.objects.length - 1;
    return (
      <List
        itemData={brandData.list}
        navigation={navigation}
        brandId={brandData.id}
        search
        getQuickFiltersListRef={getQuickFiltersListRef}
        stickyPosition={stickyPosition}
        selectedFilters={selectedFilters}
        onFiltersPress={onFiltersPress}
        onPressResetButton={onPressResetButton}
        // abc={() => {}}
        setListPaginatedCallApiRef={setListPaginatedCallApiRef}
        verticalListHeader={this.brandHeader}
        paginateLastItem={paginateLastItem}
        totalItemsInList={totalItems}
        filtersEnabled={filtersEnabled}
        filteredSlug={filteredSlug}
        onRefresh={onRefresh}
        previousScreen={SCREEN_CONSTANTS.BRAND_DETAILS}
        extraEventParameters={{
          [EventParameterKey.ENTITY_NAME]: brandData.name,
        }}
        hideOosProduct={hideOosProduct}
      />
    );

    // return (
    //   <FlatList
    //     data={itemData.list.objects}
    //     renderItem={({ item, index }) => this.getComponent(item, index)}
    //     keyExtractor={(item) => `${item.type}_${item.id}`}
    //     style={{ backgroundColor: colors.background, marginBottom: 20 }}
    //   />
    // );
  };

  placeholderCondition = (props) =>
    Utility.isBlank(props.itemData.objects) && props.isLoading;

  // listOrPlaceholder = withEither(
  //   this.placeholderCondition,
  //   ScreenPlaceholder,
  // )(this.renderList);

  onDidFocus = () => {
    const { brandData, previousScreen } = this.props;
    const { id, name } = brandData;
    AnalyticsManager.logEvent(EventType.discoveryEvents.BRAND_VIEW, {
      [EventParameterKey.BRAND_ID]: id,
      [EventParameterKey.BRAND_NAME]: name,
    });
    AnalyticsManager.setCurrentScreen(previousScreen);
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  showRichDescriptionVisibility = () => {
    this.setState({ isRichDescriptionVisible: true });
  };

  hideRichDescriptionVisibility = () => {
    this.setState({ isRichDescriptionVisible: false });
  };

  fullScreenBrandDescription = () => {
    const {
      brandData: { description },
    } = this.props;

    const styles = BrandStyles;

    if (Utility.isBlank(description)) return null;
    return (
      <View style={styles.fullScreenRichDescriptionStyle}>
        <FullScreenProductDescription
          description={description}
          // howTo={how_to}
          // ingredients={ingredients}
          // metrologicalInfo={metrological_info}
          // onCloseDueToScroll={() =>
          // this.closeFullScreenProductDescription('continued scroll')
          // }
        />
      </View>
    );
  };

  onSocialShareOptionTapped = (medium) => {
    const { brandData } = this.props;
    const {
      list,
      share_url: shareUrl,
      name: brandName,
      id: brandId = '',
      slug: brandSlug = '',
      content: brandContent = '',
    } = brandData;
    const { slug, type, id, name, image_url: imageUrl, content } = list;

    let sharedUrl = shareUrl; // to change url when triggered from offer detail page
    let title = name;
    let message = `Check out top product and offers of ${brandName} on ${Config.APP_NAME}, the best platform for all your beauty needs`;
    AnalyticsManager.logEvent(EventType.miscAppEvents.ITEM_SHARE, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.BRAND_DETAILS,
      [EventParameterKey.ITEM_ID]: brandId,
      [EventParameterKey.ENTITY_NAME]: brandName,
      [EventParameterKey.ITEM_TYPE]: 'brand',
    });
    const linkOptions = {
      title,
      imageUrl: Utility.getMinifiedImage(imageUrl, 200, 200),
      description: '',
      trackingParams: TRACKING_PARAMS.SHARE.BRAND,
    };
    const getDynamicLink = DynamicLinkManager.getShortenUrl(
      sharedUrl,
      linkOptions,
    );
    getDynamicLink
      .then((link) => {
        console.log('My Link ', link);
        const options = {
          title: Config.APP_NAME,
          message,
          url: link,
        };
        Share.open(options)
          .then((res) => {})
          .catch((err) => {});
      })
      .catch((error) => {
        console.log('Error ', error);
      });
  };

  // renderEmptyState = () => {
  //   const { brandData, isLoading } = this.props;
  //   return (
  //     <View>
  //       <this.brandBanner
  //         uri={brandData.banner_image_url || brandData.image_url}
  //       />
  //       {/* <this.listOrPlaceholder itemData={brandData} isLoading={isLoading} /> */}
  //       <this.renderList />
  //     </View>
  //   );
  // };

  brandHeader = () => {
    const { brandData = {}, isLoading } = this.props;
    const {
      name = '',
      slug: brandSlug,
      show_personalised_details: showPersonalisedDetails,
      banner_image_url = '',
      image_url = '',
    } = brandData;

    return (
      <>
        <this.brandBanner uri={banner_image_url || image_url} />
        {showPersonalisedDetails && (
          <FoxyPersonalisedItems
            slug={brandSlug}
            subheading={`${name} products which you shouldn’t miss`}
            previousScreen={SCREEN_CONSTANTS.BRAND_DETAILS}
            extraEventParameters={{
              entityName: name,
            }}
          />
        )}
      </>
    );
  };

  // setScrollYToNavigationHeader = ({ nativeEvent }) => {
  //   const {
  //     contentOffset: { y },
  //   } = nativeEvent;
  //   this.setState({
  //     scrollY: new Animated.Value(y),
  //   });
  // };

  howNotificationModal = (show, showNotification) => {
    this.setState({
      canDisplayNotificationRequestModal: show,
      showNotificationPrompt: showNotification,
    });
  };

  displayNotificationRequestModal = () => {
    const {
      initial_app_opened_at,
      lastNotificationModalDisplayTime,
      setNotificationModalDisplayTime,
    } = this.props;
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      SCREEN_CONSTANTS.BRAND,
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(true, showNotificationPrompt);
          const currentDate = new Date();
          setNotificationModalDisplayTime(currentDate.getTime());
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  showNotificationModal = (show, showNotification) => {
    this.setState({
      canDisplayNotificationRequestModal: show,
      showNotificationPrompt: showNotification,
    });
  };

  render() {
    const { brandData, isLoading, resetStatusBar, serverError } = this.props;

    const {
      scrollY,
      isShareModalVisible,
      isRichDescriptionVisible,
      canDisplayNotificationRequestModal,
      showNotificationPrompt,
      isPageLoading,
    } = this.state;

    const { list, share_url: shareUrl } = brandData;
    if (Utility.isBlank(list)) {
      return (
        <View style={BrandStyles.errorStateContainer}>
          <StaticNavigationHeader name={''} />
          <ErrorBoundary
            showServerError={serverError && !isLoading}
            pageNotFound={!isPageLoading}
            hideHeader
          />
        </View>
      );
    }
    // if (Utility.isBlank(list)) {
    //   return this.renderEmptyState();
    // }
    const { name = '' } = brandData;
    const { slug, type, id, image_url: imageUrl } = list;
    const showSearchButton = true;
    const ContainerComponent = isWeb() ? ScrollView : Fragment;
    return (
      <>
        {!isDesktop() && (
          <CustomNavigationHeader
            ref={(ref) => {
              this.navigationRefs = ref;
            }}
            scrollY={scrollY}
            slug={slug}
            goBack={this.goBack}
            content={type}
            type={type}
            id={id}
            screenName={SCREEN_CONSTANTS.BRAND_DETAILS}
            shareUrl={shareUrl}
            name={name}
            image={imageUrl}
            showCartIcon
            showSearchButton={showSearchButton}
            onSearchIconTap={this.onSearchIconTap}
            onPressCart={this.toggleCartVisibility}
            hideRichDescriptionVisibility={this.hideRichDescriptionVisibility}
            isRichDescriptionVisible={isRichDescriptionVisible}
            resetStatusBar={resetStatusBar}
            showShareButton={false}
            utmCampaign={'brand'}
          >
            <DockedHeader
              name={isRichDescriptionVisible ? `About ${name}` : name}
            />
          </CustomNavigationHeader>
        )}
        {isWeb() && <WebListTitle title={isRichDescriptionVisible ? `About ${name}` : name} />}

        <ContainerComponent>
          <this.renderList />
          <WebFooter />
        </ContainerComponent>

        <Toast
          style={{ position: 'absolute', bottom: 30 }}
          ref={(ref) => {
            this.toast = ref;
          }}
        />
        {isShareModalVisible && (
          <ShareModal
            isShareModalVisible={isShareModalVisible}
            hideShareModal={this.hideShareModal}
            onSocialShareOptionTapped={this.onSocialShareOptionTapped}
            image={imageUrl}
            isProduct="false"
            name={name}
          />
        )}
        {isRichDescriptionVisible && <this.fullScreenBrandDescription />}
        <NotificationModal
          isNotificationModalVisible={canDisplayNotificationRequestModal}
          showNotificationModal={this.showNotificationModal}
          showNotificationPrompt={showNotificationPrompt}
          type={NOTIFICATION_REQUEST_MODAL_TYPE.BRAND_PAGE}
          showAnimation
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lastNotificationModalDisplayTime:
    state.UserAccountInfo.lastNotificationModalDisplayTime,
  initial_app_opened_at: state.UserAccountInfo.initial_app_opened_at,
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setNotificationModalDisplayTime,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(BrandDetail),
);
