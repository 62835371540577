import { takeEvery, call } from 'redux-saga/effects';
import { URL } from '../config/Constants';
import { DELETE_USER } from '../actions/ActionTypes';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import Utility from '../utils/Utility';

const deleteRequest = (headers) => {
  const url = `${URL.DELETE_USER}${headers['x-auth-token']}`;
  const response = fetch(url, {
    method: 'DELETE',
    headers,
  });
  return response;
};

function* deleteUser(action) {
  const { callback } = action;
  try {
    const headers = yield getApiHeaders();
    const response = yield call(deleteRequest, headers);
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
    console.log(`Error in getting response ${error}`);
  }
}

export default function* watchDeleteUserSaga() {
  yield takeEvery(DELETE_USER, deleteUser);
}
