import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import { AnalyticsManager } from '../../analytics';
import { CartStyles } from '../../components/cart/styles';
import VerticalRailShimmer from '../../components/shared/VerticalRailShimmer';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import RemoteConfig from '../../utils/RemoteConfig';
import Utility from '../../utils/Utility';
import { List } from '../List';
import SlimRailShimmer from '../../components/shared/SlimRailShimmer';

class RecommendedList extends PureComponent {
  constructor(props) {
    super(props);
    this.cartBuilder = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.cart_builder),
    );
  }

  componentDidUpdate(prevProps) {
    const {
      showRecommendedList,
      recommendedCartItems,
      previousScreen = SCREEN_CONSTANTS.CART,
    } = this.props;

    if (!prevProps.showRecommendedList && !!showRecommendedList) {
      AnalyticsManager.logEvent('list_item_view', {
        item_type: 'products',
        screen_name: previousScreen,
        list_type: 'cart_builder',
      });
      Utility.fireListRenderedEvent(
        recommendedCartItems,
        previousScreen,
        'product',
      );
    }
  }

  listHeader = ({ itemsTotal, discount, isFreeDelivery }) => {
    console.log('items total', itemsTotal, discount);
    let title = this.cartBuilder.aboveMinValueListTitle;
    let subtitle = this.cartBuilder.aboveMinValueListSubtitle;
    const cartTotal = itemsTotal - discount;
    if (cartTotal < this.cartBuilder.free_delivery_above && !isFreeDelivery) {
      title = this.cartBuilder.belowMinValuListTitle.replace(
        '${value}',
        this.cartBuilder.free_delivery_above - cartTotal,
      );
      subtitle = this.cartBuilder.belowMinValueListSubtitle;
    }
    return (
      <View style={CartStyles.listHeaderContainer}>
        <Text style={CartStyles.listHeaderTitle}>{title}</Text>
        <Text style={CartStyles.listHeaderSubtitle}>{subtitle}</Text>
      </View>
    );
  };

  render() {
    const {
      itemsTotal = 0,
      discount = 0,
      isFreeDelivery = false,
      recommendedCartItems,
      navigation,
      showRecommendedList,
      showRecommendedListShimmer,
      isShowHeader = false,
      listBackGroundColor = recommendedCartItems.background_color,
      listCartItemStyle = CartStyles.listContainer,
      previousScreen = SCREEN_CONSTANTS.CART,
    } = this.props;
    if (showRecommendedListShimmer && Utility.isBlank(recommendedCartItems)) {
      return <SlimRailShimmer />;
    }
    if (!showRecommendedList) {
      return null;
    }
    return (
      <View
        style={[
          listCartItemStyle,
          { backgroundColor: listBackGroundColor },
        ]}
      >
        {isShowHeader && (
          <this.listHeader
            itemsTotal={itemsTotal}
            discount={discount}
            isFreeDelivery={isFreeDelivery}
          />
        )}
        <List
          itemData={recommendedCartItems}
          navigation={navigation}
          previousScreen={previousScreen}
        />
      </View>
    );
  }
}

export default RecommendedList;
