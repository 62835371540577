import {
  View,
  TouchableOpacity,
  Linking,
  Image,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import React from 'react';
import images from '../../theme/Images';
import { EXTERNAL_LINKS } from '../../config/Constants';

export default function DownloadAppButtons({
  width,
  height,
  containerStyle,
  onPress = () => {},
}) {
  const onAppStorePress = () => {
    onPress();
    Linking.openURL(EXTERNAL_LINKS.appleStore);
  };

  const onPlayStorePress = () => {
    onPress();
    Linking.openURL(EXTERNAL_LINKS.googlePaly);
  };

  return (
    <View style={[styles.container, containerStyle]} dataSet={{ media: ids.container }}>
      <TouchableOpacity
        onPress={onAppStorePress}
        style={styles.leftButton}
      >
        <Image
          source={{ uri: images.desktop.appleStoreFooterIcon }}
          style={[styles.image, width && { width, height }]}
          dataSet={{ media: ids.image }}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={onPlayStorePress}>
        <Image
          source={{ uri: images.desktop.googlePlayFooterIcon }}
          style={[styles.image, width && { width, height }]}
          dataSet={{ media: ids.image }}
        />
      </TouchableOpacity>
    </View>
  );
}

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: 20,
    '@media (min-width: 768px)': {
      marginBottom: 30,
    },
  },
  leftButton: {
    marginRight: 5,
  },
  image: {
    width: 87,
    height: 23,
    ':hover': {
      opacity: 0.7,
    },
    '@media (min-width: 768px)': {
      width: 164,
      height: 44,
    },
  },
});
