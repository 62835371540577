/* eslint-disable no-unused-expressions */
import { takeLatest, put, select, call } from 'redux-saga/effects';
import {
  GET_ARTIST_TYPES,
  GET_BRANDS_FOR_ARTIST,
  GET_INTERESTS_FOR_ARTIST,
  GET_SERVICES_FOR_ARTISTS,
  ADD_ADDRESS,
  GET_CURRENT_LOCATION_VIA_MAP,
  SEARCH_PLACE_BY_TEXT,
  SEARCH_SALON_NEARBY,
  GET_SELECTED_YOUTUBE_CHANNEL_ID,
  GET_GOOGLE_PROFILE,
  NEAR_BY_ACADEMIES,
  NEAR_BY_STORE,
  SAVE_GOOGLE_DATA,
  ARTIST_ONBOARDING_COMPLETED,
  storeYoutubeSubscriberCount,
  artistResponseSuccess,
  refreshMyProfile,
  GET_YOUTUBE_VIEWS_ANALYTICS,
  SAVE_SALON,
  SAVE_FREELANCER_PROFILE,
  SAVE_BEAUTY_ADVISORY_PROFILE,
  SAVE_TRAINER_PROFILE,
  SAVE_STUDENT_PROFILE,
  saveFreelancerProfileLocal,
  UNLINK_YOUTUBE_PROFILE,
  RESET_YOUTUBE_ACCOUNT_LOCAL,
  INSTAGRAM_POST_COMMENTS,
  GET_SELECTED_YOUTUBE_CHANNEL_ID_PROFILE,
} from '../actions/ActionTypes';

import { URL, googleMapsApiKey } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';

export function* getArtistTypesAsync(action) {
  const url = URL.ARTIST_TYPE;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    const statusCode = response.status;
    if (statusCode === 200) {
      if (Utility.isPresent(json)) {
        action.callback(json);
      }
    }
  } catch (error) {
    console.log('artist type error', error);
  }
}

export function* getCurrLocationViaMap(action) {
  const { latitute, longitute, callback } = action;
  const url = `${URL.GOOGLE_GEOCODE_API}${latitute},${longitute}&key=${googleMapsApiKey}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (Utility.isBlank(json)) {
      return;
    }
    if (!json.error_message) {
      callback(false, json.results[0].formatted_address);
    } else {
      callback(true, json.error_message);
    }
  } catch (error) {}
}

export function* getBrandsAsync(action) {
  const { page } = action.data;
  const { selectedArtistTypes } = action.data;
  let url = `${URL.BRANDS}${page}`;
  if (!Utility.isBlank(selectedArtistTypes) && selectedArtistTypes.length > 0) {
    selectedArtistTypes.map((item) => {
      url += `&artist_types[]=${item}`;
    });
  }
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json);
      }
    }
  } catch (error) {}
}

export function* getLocationViaText(action) {
  const jsonData = [];
  const { text } = action;
  const url = `${URL.GOOGLE_PLACES_AUTOCOMPLETE}${action.text}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json);
      }
    }
  } catch (error) {}
}

export function* getInterestsAsync(action) {
  const url = URL.GET_INTERESTS;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json);
      }
    }
  } catch (error) {}
}

export function* getServicesAsync(action) {
  const url = URL.GET_SERVICES;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json);
      }
    }
  } catch (error) {}
}

export function* getSalonsNearBy(action) {
  const { GET_SALON_NEARBY } = URL;
  try {
    const url = `${GET_SALON_NEARBY}${action.searchQuery}&lat=${action.lat}&long=${action.longitude}`;

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json);
      }
    }
  } catch (error) {
    console.log('Error in get Salon nearby');
  }
}
export function* getGoogleProfile(action) {
  const { callback } = action;
  const url = `${URL.googleProfile}${action.accessToken}`;

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (Utility.isPresent(json)) {
      action.callback(json);
    }
  } catch (error) {}
}

export function* getYoubeChannelIdByAccessToken(action) {
  const { callback } = action;
  const url = `${URL.youtube}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `${action.tokenType} ${action.accessToken}`,
      },
    });
    const json = yield convertJsonFromResponse(response);
    if (Utility.isPresent(json)) {
      if (callback) {
        callback(json);
      }
      yield call(sendYoutubeFollowersCountToServer, json);
    }
  } catch (error) {
    console.log('Error in send youtube ');
  }
}

export function* getYoubeChannelIdByAccessTokenProfile(action) {
  const { callback } = action;
  const url = `${URL.youtube}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `${action.tokenType} ${action.accessToken}`,
      },
    });
    const json = yield convertJsonFromResponse(response);
    if (Utility.isPresent(json)) {
      if (callback) {
        callback(json);
      }
      yield call(sendYoutubeFollowersCountToServer, json);
    }
  } catch (error) {
    console.log('Error in send youtube ');
  }
}

export function* nearByStore(action) {
  const { NEAR_BY_STORE } = URL;
  try {
    const url = `${NEAR_BY_STORE}${action.searchQuery}&lat=${action.lat}&long=${action.longitude}`;

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json);
      }
    }
  } catch (error) {
    console.log('Get Store nearby error', error);
  }
}

export function* nearByAcademies(action) {
  const { NEAR_BY_ACADEMIES } = URL;
  try {
    const url = `${NEAR_BY_ACADEMIES}${action.searchQuery}&lat=${action.lat}&long=${action.longitude}`;

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        action.callback(json);
      }
    }
  } catch (error) {
    console.log('Error in near by academies ', error);
  }
}

export function* saveGoogleData(action) {
  const { data, callback, youtubeObject } = action;
  const url = URL.SAVE_YOUTUBE_DATA;
  const mobileNumber = yield select(
    (state) => state.UserAccountInfo.mobileNumber,
  );
  const callingCode = yield select(
    (state) => state.UserAccountInfo.callingCode,
  );
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: `${authToken}`,
        user: {
          country_code: `${callingCode}`,
          phone_number: `${mobileNumber}`,
        },
        google_profile: {
          ...data,
        },
        youtube_channel: youtubeObject,
      }),
    });

    const statusCode = response.status;
    if (statusCode === 200) {
      action.callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    console.log('artist type error', error);
  }
}

export function* postArtistResponseAsync(action) {
  yield put(refreshMyProfile());
  // const url = URL.SUBMIT_ARTIST_RESPONSE;
  // const { data } = action;
  // const authToken = yield select(state => state.UserAccountInfo.authToken);

  // try {
  //   const response = yield fetch(url, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  //     body: JSON.stringify({
  //       auth_token: authToken,
  //       artist: data,
  //     }),
  //   });

  //   const statusCode = response.status;
  //   if (statusCode >= 200 && statusCode < 300) {
  //     yield put(artistResponseSuccess());
  //     yield put(refreshMyProfile());
  //   } else {
  //     // action.callback(false);
  //   }
  // } catch (error) {
  //   console.log('artist response  error', error);
  // }
}

export function* sendYoutubeFollowersCountToServer(youtubeChannelObject) {
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    let bannerImageUrl = '';
    let featuredChannelsUrls = [];

    if (
      youtubeChannelObject.items[0].brandingSettings &&
      youtubeChannelObject.items[0].brandingSettings.image
    ) {
      bannerImageUrl =
        youtubeChannelObject.items[0].brandingSettings.image.bannerImageUrl;
    }
    if (
      youtubeChannelObject.items[0].brandingSettings &&
      youtubeChannelObject.items[0].brandingSettings.channel &&
      youtubeChannelObject.items[0].brandingSettings.channel
        .featuredChannelsUrls &&
      youtubeChannelObject.items[0].brandingSettings.channel
        .featuredChannelsUrls.length > 0
    ) {
      featuredChannelsUrls =
        youtubeChannelObject.items[0].brandingSettings.channel
          .featuredChannelsUrls;
    }
    const youtubeObject = {
      youtube_channel_id: youtubeChannelObject.items[0].id,
      title: youtubeChannelObject.items[0].snippet.title,
      subscriber_count:
        youtubeChannelObject.items[0].statistics.subscriberCount,
      video_count: youtubeChannelObject.items[0].statistics.videoCount,
      view_count: youtubeChannelObject.items[0].statistics.viewCount,
      youtube_channel_image:
        youtubeChannelObject.items[0].snippet.thumbnails.high.url,
      banner_image_url: bannerImageUrl,
      featured_channels_urls: featuredChannelsUrls,
      description: youtubeChannelObject.items[0].snippet.description,
      customurl: '',
      image_thumbnail_url:
        youtubeChannelObject.items[0].snippet.thumbnails.high.url,
      country: '',
    };
    yield put(storeYoutubeSubscriberCount(youtubeObject));

    const url = `${URL.SAVE_YOUTUBE_SUBSCRIBERS_COUNT}${youtubeChannelObject.items[0].id}`;
    const postObject = {
      method: 'PATCH',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        youtube_channel: youtubeObject,
      }),
    };
    const response = yield fetch(url, postObject);
    if (response.status >= 200 && response.status < 300) {
      // Success
    } else {
      // Failure
    }
  } catch (error) {}
}

export function* getYoutubeViewAnalytics(action) {
  const {
    dateObject: { startDate, endDate },
    callback,
  } = action;
  try {
    const getRefreshToken = yield select(
      (state) => state.UserAccountInfo.youtube_profile.refreshToken,
    );
    const latestAccessToken = yield Utility.refreshAccessToken(getRefreshToken);
    const currentSubscriberCount = yield select(
      (state) => state.UserAccountInfo.youtube_profile.subscriber_count,
    );

    const url = `${URL.YOUTUBE_ANALYTICS}startDate=${startDate}&endDate=${endDate}`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `${latestAccessToken.tokenType} ${latestAccessToken.accessToken}`,
      },
    });

    if (response.status === 200) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      const processedData = yield Utility.getYoutubeAnalyticsFormattedStats(
        json,
        currentSubscriberCount,
      );
      callback(processedData);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* saveSalon(action) {
  const { salonId } = action;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const payloadObject = {
      auth_token: authToken,
      artist: { salon_id: salonId },
    };
    const response = yield fetch(URL.SAVE_SALON, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payloadObject),
    });
  } catch (error) {
    console.log('Error while saving Salon ID');
  }
}
export function* saveFreelancerProfile(action) {
  const { payload } = action;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    let bridalTime = '';
    if (
      !Utility.isBlank(payload.bridal_travel_time) &&
      payload.bridal_travel_time.time
    ) {
      bridalTime = payload.bridal_travel_time.time;
    }

    const payloadObject = {
      auth_token: authToken,
      artist: {
        freelancer_profile: {
          latitute: `${payload.latitude}`,
          longitute: `${payload.longitude}`,
          name: `${payload.name}`,
          regular_travel_time: `${payload.regular_travel_time.time}`,
          bridal_travel_time: `${bridalTime}`,
        },
      },
    };

    const result = yield fetch(URL.SAVE_FREELANCER_PROFILE, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payloadObject),
    });

    yield put(
      saveFreelancerProfileLocal({
        data: payloadObject.artist.freelancer_profile,
      }),
    );
  } catch (error) {}
}
export function* saveBeautyAdvisoryProfile(action) {
  const { storeId } = action;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const payloadObject = {
      auth_token: authToken,
      artist: {
        beauty_advisor_profile: {
          beauty_store_id: storeId,
        },
      },
    };

    const response = yield fetch(URL.SAVE_BEAUTY_ADVISORY_PROFILE, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payloadObject),
    });
  } catch (error) {
    console.log('Error while saving dats ');
  }
}
export function* saveTrainerProfile(action) {
  const { academyId } = action;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const payloadObject = {
      auth_token: authToken,
      artist: {
        trainer_profile: {
          academy_id: academyId,
        },
      },
    };

    const response = fetch(URL.SAVE_TRAINER_PROFILE, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payloadObject),
    });
  } catch (error) {}
}

export function* saveStudentProfile(action) {
  const { academyId } = action;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const payloadObject = {
      auth_token: authToken,
      artist: {
        student_profile: {
          academy_id: academyId,
        },
      },
    };

    const response = fetch(URL.SAVE_STUDENT_PROFILE, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payloadObject),
    });
  } catch (error) {}
}

export function* unlinkYoutubeProfile(action) {
  const { callback } = action;

  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(URL.UNLINK_GOOGLE_PROFILE, {
      method: 'DELETE',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
      }),
    });
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: RESET_YOUTUBE_ACCOUNT_LOCAL,
        youtube_profile_empty_object: Utility.getEmptyYoutubeProfileObject(),
      });
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {}
}

export function* getInstagramPostComment(action) {
  const { callback, postUrl } = action;
  try {
    const url = `${postUrl}?__a=1`;

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(json);
    } else {
      callback(false);
    }
  } catch (error) {}
}

export default function* watchFetchArtistOnboarding() {
  yield takeLatest(GET_ARTIST_TYPES, getArtistTypesAsync);
  yield takeLatest(GET_BRANDS_FOR_ARTIST, getBrandsAsync);
  yield takeLatest(GET_INTERESTS_FOR_ARTIST, getInterestsAsync);
  yield takeLatest(GET_SERVICES_FOR_ARTISTS, getServicesAsync);
  yield takeLatest(GET_CURRENT_LOCATION_VIA_MAP, getCurrLocationViaMap);
  yield takeLatest(SEARCH_PLACE_BY_TEXT, getLocationViaText);
  yield takeLatest(
    GET_SELECTED_YOUTUBE_CHANNEL_ID,
    getYoubeChannelIdByAccessToken,
  );
  yield takeLatest(GET_GOOGLE_PROFILE, getGoogleProfile);
  yield takeLatest(NEAR_BY_ACADEMIES, nearByAcademies);
  yield takeLatest(NEAR_BY_STORE, nearByStore);
  yield takeLatest(SAVE_GOOGLE_DATA, saveGoogleData);
  yield takeLatest(ARTIST_ONBOARDING_COMPLETED, postArtistResponseAsync);
  yield takeLatest(GET_YOUTUBE_VIEWS_ANALYTICS, getYoutubeViewAnalytics);
  yield takeLatest(SAVE_SALON, saveSalon);
  yield takeLatest(SAVE_FREELANCER_PROFILE, saveFreelancerProfile);
  yield takeLatest(SAVE_BEAUTY_ADVISORY_PROFILE, saveBeautyAdvisoryProfile);
  yield takeLatest(SAVE_TRAINER_PROFILE, saveTrainerProfile);
  yield takeLatest(SAVE_STUDENT_PROFILE, saveStudentProfile);
  yield takeLatest(UNLINK_YOUTUBE_PROFILE, unlinkYoutubeProfile);
  yield takeLatest(INSTAGRAM_POST_COMMENTS, getInstagramPostComment);
  yield takeLatest(
    GET_SELECTED_YOUTUBE_CHANNEL_ID_PROFILE,
    getYoubeChannelIdByAccessTokenProfile,
  );
}
