import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { Rating } from 'react-native-rating-element';
import Config from '../../libraries/ReactNativeConfig';
import { useNavigation } from '@react-navigation/native';
import colors from '../../theme/Colors';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { ProductDetailStyles } from './styles';
import images from '../../theme/Images';
import { jsonParser } from '../../utils/JsonUtility';
import { getHitSlop } from '../../utils/LayoutUtility';
import { isPresent } from '../../utils/BooleanUtility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';

const CertificateButton = ({ data, navigation }) => {
  if (jsonParser(Config.DISABLE_ORIGINAL_CERTIFICATE_FROM_PRODUCT_DETAIL)) {
    return null;
  }

  const navigateToCertificateModal = () => {
    navigation.push('CertificateModal', {
      data,
      heading: '100% original products',
      subheading:
        'All products on Foxy are sourced directly from brand and/or their authorized resellers and are 100% original',
    });
  };

  return (
    <DebouncedTouchableOpacity
      onPress={navigateToCertificateModal}
      style={ProductDetailStyles.certificateButton}
      hitSlop={getHitSlop()}
    >
      <Image
        source={images.originalProductLogo}
        style={ProductDetailStyles.originalProductLogo}
      />
      <Text
        allowFontScaling={false}
        style={ProductDetailStyles.certificateButtonText}
      >
        100% Original
      </Text>
    </DebouncedTouchableOpacity>
  );
};

const ProductRatingAndMoreInfo = ({
  onRatingTap = () => {},
  rating = 0,
  id,
  sku_id,
  disableRatingText = false,
  ratingSize,
  marginBetweenRatingIcon,
  entityName,
  previousScreen = '',
  data,
  show,
}) => {
  const navigation = useNavigation();
  if (rating === 0) {
    return null;
  }
  const size = disableRatingText ? 10 : 20;

  const onIconTap = () => {
    AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
      [EventParameterKey.SKU_ID]: sku_id,
      [EventParameterKey.PRODUCT_ID]: id,
      [EventParameterKey.ELEMENT_NAME]: 'rating',
      [EventParameterKey.RATING]: rating,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: previousScreen,
    });
    onRatingTap();
  };

  return (
    <View
      style={disableRatingText ? styles.disableContainer : styles.container}
    >
      <View style={styles.innerContainer}>
        <Rating
          rated={parseFloat(rating)}
          totalCount={5}
          size={isPresent(ratingSize) ? ratingSize : size}
          onIconTap={onIconTap}
          marginBetweenRatingIcon={
            isPresent(marginBetweenRatingIcon) ? marginBetweenRatingIcon : 3
          }
          direction='row'
          type='custom' // default is always to "icon"
          selectedIconImage={images.filled_star}
          emptyIconImage={images.empty_star}
        />
        {!disableRatingText && <Text style={styles.ratingText}>{rating}</Text>}
      </View>

      {show && <CertificateButton navigation={navigation} data={data} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingTop: 12,
    paddingBottom: 12,
    paddingHorizontal: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disableContainer: {
    backgroundColor: 'transparent',
    paddingTop: 2,
    paddingHorizontal: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratingText: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    marginLeft: 8,
  },
});

export default React.memo(ProductRatingAndMoreInfo);
