import {
  Text,
  View,
  TouchableOpacity,
  Linking,
  StyleSheet,
} from 'react-native';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCancelReason } from '../../../actions/ActionTypes';
import { heldOrder } from '../../../actions/OrderActions';
import withNavigation from '../../../utils/WithNavigation';
import { StaticNavigationHeader } from '../../../components/shared';
import FastImageView from '../../../components/FastImageView';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import images from '../../../theme/Images';
import AddEmailModal from '../faqRequestCallBack/AddEmailModal';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';

class CancelOrder extends PureComponent {
  constructor(props) {
    super(props);
    const { navigation, route } = this.props;
    this.headerTitle = route.params?.headerTitle;
    this.cancelReasonId = route.params?.cancelReasonId;
    this.state = {
      isAddEmailModalVisible: false,
    };
    this.orderId = route.params?.orderId;
    this.entityType = route.params?.entityType;
  }

  hideAddEmailModalVisible = () => {
    this.setState({
      isAddEmailModalVisible: false,
    });
  };

  onPressAddEmail = (emailId) => {
    const { userEmail, navigation, heldOrder } = this.props;
    this.setState({
      isAddEmailModalVisible: false,
    });
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.ENTITY_ID]: this.orderId,
      [EventParameterKey.ENTITY_TYPE]: this.entityType,
      [EventParameterKey.PAGE]: 'cancel_order',
      [EventParameterKey.CTA]: 'add_my_email',
    });
    const data = {
      entity_id: this.orderId,
      cancellation_reason: this.cancelReasonId,
    };

    heldOrder(data, (success) => {
      if (success) {
        AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
          source: 'held_order',
        });
        navigation.navigate('ConfirmRequestPage', {
          heading: 'We are bummed about this! 😔',
          subHeading:
            'Your order has been put on hold. Our customer care will call you soon to confirm the cancellation of items.',
        });
      } else {
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            source: 'held_order',
          },
        );
      }
    });
  };

  onPressPrimaryActionButton = () => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.ENTITY_ID]: this.orderId,
      [EventParameterKey.ENTITY_TYPE]: this.entityType,
      [EventParameterKey.PAGE]: 'cancel_order',
      [EventParameterKey.CTA]: 'no_i_changed_my_mind',
    });
    navigation.navigate('OrderDetails', { id: this.orderId });
  };

  onPressSecondaryActionButton = () => {
    const { userEmail, navigation, heldOrder } = this.props;
    if (Utility.isBlank(userEmail)) {
      this.setState({
        isAddEmailModalVisible: true,
      });
      return;
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.ENTITY_ID]: this.orderId,
      [EventParameterKey.ENTITY_TYPE]: this.entityType,
      [EventParameterKey.PAGE]: 'cancel_order',
      [EventParameterKey.CTA]: 'yes_cancel',
    });
    const data = {
      entity_id: this.orderId,
      cancellation_reason: this.cancelReasonId,
    };

    heldOrder(data, (success) => {
      if (success) {
        AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
          source: 'held_order',
        });
        navigation.navigate('ConfirmRequestPage', {
          headerTitle: 'Cancel Order',
          heading: 'We are bummed about this! 😔',
          subHeading:
            'Your order has been put on hold. Our customer care will call you soon to confirm the cancellation of items.',
        });
      } else {
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
          {
            source: 'held_order',
          },
        );
      }
    });
  };

  render() {
    const { isAddEmailModalVisible } = this.state;
    return (
      <View>
        <StaticNavigationHeader
          onBackPress={this.onPressActionButton}
          title={'cancel Order'}
        />
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <FastImageView
              source={images.cancel_order_image}
              style={styles.imageStyle}
              resizeMode='contain'
            />
            <Text style={styles.boldText}>
              Are you sure you want to cancel? 😔
            </Text>
            <Text style={styles.subHeading}>
              We are really sad to see you go.
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 24}
              title={'No, I changed my mind'}
              textStyle={{ color: colors.white }}
              onPress={this.onPressPrimaryActionButton}
              style={styles.button}
              firstColor={colors.black}
              secondColor={colors.black}
              underlayColor={colors.foxyBlack}
            />
          </View>
          <View style={styles.buttonContainer}>
            <FoxyShadowButton
              width={Utility.getScreenWidth() - 24}
              title={'Yes, cancel'}
              textStyle={{ color: colors.black }}
              onPress={this.onPressSecondaryActionButton}
              style={styles.button}
              firstColor={colors.white}
              secondColor={colors.white}
              underlayColor={colors.lightGrey}
            />
          </View>
          <AddEmailModal
            onDismiss={this.hideAddEmailModalVisible}
            isVisible={isAddEmailModalVisible}
            onPressAddEmail={this.onPressAddEmail}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth(),
    height: 373,
    backgroundColor: colors.white,
  },
  subContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  imageStyle: {
    width: Utility.getScreenWidth() - 48,
    height: 120,
    marginTop: 24,
  },
  boldText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginTop: 30,
  },
  subHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    paddingTop: 8,
    alignContent: 'center',
  },
  buttonContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingTop: 12,
  },
  button: {
    height: 48,
    borderWidth: 1,
    borderColor: colors.black,
    borderStyle: 'solid',
  },

  contactUsContainer: {
    height: 130,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    marginTop: 12,
  },
  subContainerStyle: {
    marginHorizontal: 20,
    marginTop: 24,
    marginBottom: 14,
  },
  subHeadingStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 4,
  },
  blueTextButton: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.cta.lightBlue,
    marginTop: 8,
    right: 3,
  },
  boldTextStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
});

const mapStateToProps = (store) => {
  return {
    userEmail: store.UserAccountInfo.profile.email,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      heldOrder,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CancelOrder),
);
