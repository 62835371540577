import { takeEvery, call, put, takeLatest, select } from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
import {
  UPDATE_POST,
  CREATE_POST,
  addPost,
  ADD_POST_TO_LOCAL_STATE,
} from '../actions/UploadPostActions';
import { updateCurrentPostID } from '../actions/LoginActions';
import { URL } from '../config/Constants';

const postsSchema = new schema.Entity('posts', {}, { idAttribute: 'id' });

const createPostObject = (media) => {
  const newPost = {
    id: media,
    mediaLocation: media,
    products: {},
    caption: '',
    thumbnailLocation: media,
    categories: {
      Skin: false,
      Body: false,
      Hair: false,
    },
    videoTypes: {
      'Product Review': false,
      'How-to': false,
      'Style-Guide': false,
      Service: false,
    },
    hashtags: [],
    socialMedia: {
      YouTube: false,
      Instagram: false,
      Twitter: false,
      'Share on Whatsapp': false,
    },
    editVideo: false,
    editPublishingSettings: false,
    status: 'pending',
  };
  return newPost;
};

function* createNewPost(action) {
  try {
    const newPost = yield call(createPostObject, action.media);
    const normalizedPost = yield normalize(newPost, postsSchema);

    yield put(addPost(normalizedPost.entities.posts));
  } catch (error) {
    console.log(`Error in getting Interests ${error}`);
  }
}

function* addPostToLocalState(action) {
  const { postObject, callback } = action;
  try {
    yield put(addPost(postObject));
    callback(true);
  } catch (error) {
    callback(false);
  }
}

function* updatePost(action) {
  const { title, media_category_id, callback, postId } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const object = {
      auth_token: authToken,
      medium: {
        title,
        media_category_id,
      },
    };
    const url = `${URL.UPDATE_POST}${postId}`;

    const response = yield fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(object),
    });

    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    console.log('Error in Update post');
    callback(false);
  }
}

export default function* watchCreatePostSaga() {
  yield takeEvery(CREATE_POST, createNewPost);
  yield takeLatest(ADD_POST_TO_LOCAL_STATE, addPostToLocalState);
  yield takeLatest(UPDATE_POST, updatePost);
}
